import { Component, OnInit } from '@angular/core';
import {
  cacheBlockSize,
  defaultColDef,
  generateColumnDefs,
  generateColumnDefsWithRowSelection,
  paginationPageSize,
  purchaseRequestColumns,
} from '../ag-grid/gridOptions';

import { NgxSpinnerService } from 'ngx-spinner';
import { ProcurementService } from 'src/services/procurement.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { PmsAddEditPurchaseRequestComponent } from './pms-add-edit-purchase-request/pms-add-edit-purchase-request.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { SASTokenParser } from 'src/common/util/util';
import { ShowGRNSummaryRenderer } from '../ag-grid/show-grn-summary-renderer.component';
import { CClaim } from 'src/common/claims';
@Component({
  selector: 'pms-view-purchase-request',
  templateUrl: './pms-view-purchase-request.component.html',
  styleUrls: ['./pms-view-purchase-request.component.scss'],
})
export class PmsViewPurchaseRequestComponent implements OnInit {
  selectedStatuses = [];
  statuses = [];
  columns = purchaseRequestColumns;
  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  defaultColDef = defaultColDef;
  public gridColumnDefs = generateColumnDefsWithRowSelection(this.columns);

  public gridOptions: any;
  private gridApi;
  frameworkComponents = {};
  dataSource = [];
  cClaim = CClaim;
  selectedRows: any = [];
  rowSelection = 'single';
  filtersForm: FormGroup;
  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public toastr: NgxToasterService,
    private procurementService: ProcurementService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.procurementService.getStatusLookup().subscribe((res: any) => {
      this.statuses = res;
    });
    this.initializeFilterForm();
    this.fetchData();
  }
  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }
  initializeFilterForm() {
    this.filtersForm = this.formBuilder.group({
      prTitle: [''],
      statusDescription: [''],
    });
    this.filtersForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((res: any) => {
        this.fetchData();
      });
  }
  addEditPurchaseRequest(isEdit = false) {
    if ((isEdit && this.selectedRows.length === 1) || !isEdit) {
      const confirmationDialogRef = this.dialog.open(
        PmsAddEditPurchaseRequestComponent,
        {
          width: '1000px',
          // data: { id: id }
          data: {
            selectedRow: this.gridApi.getSelectedRows()[0],
            isEdit: isEdit,
          },
        }
      );
      confirmationDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.fetchData();
        }
      });
    }
  }
  updatePurchaseRequestStatus(status) {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '500px',
        data: {
          headerName: `Status Change`,
          confirmationMessage: `Are you sure you want to ${
            status === 'approved' ? 'Approve' : 'Reject'
          } purchase Request?`,
          status: status,
        },
      }
    );
    confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinner.show();
        let filterStatus = this.statuses.find((status) => {
          return status.statusDescription.toLowerCase() === result.status;
        });
        const selectedRow = this.gridApi.getSelectedRows()[0];
        const payload = {
          prStatus: filterStatus.statusId,
          prId: _.get(selectedRow, 'prId', 0),
        };
        this.procurementService
          .UpdatePurchaseRequestUpdateStatus(payload)
          .subscribe(
            (res) => {
              if (res) {
                this.fetchData();
                this.spinner.hide();
                this.toastr.success(
                  'Success',
                  `Purchase Request status change successfully.`
                );
                this.fetchData();
              }
            },
            (err) => {
              this.toastr.error('Error', `System failed to update status.`);
              this.spinner.hide();
            }
          );
      }
    });
  }
  clearFilter() {
    this.initializeFilterForm();
    this.fetchData();
  }
  fetchData() {
    this.spinner.show();
    this.dataSource = [];
    this.procurementService
      .getPurchaseRequestsListing(
        this.filtersForm.get('prTitle').value,
        this.filtersForm.get('statusDescription').value
      )
      .subscribe(
        (res: any) => {
          this.dataSource = res;
          this.dataSource.forEach((element) => {
            element.document &&
              (element.documentURL = `https://${element.document.storagePath}/${
                element.document.containerName
              }/${element.document.documentName}.${
                element.document.documentExtension
              }${SASTokenParser(element.document.sasToken)}`);
            element.requestItems.forEach((item) => {
              element.estimatePrice += item.estimatePrice * item.quantity;
            });
          });
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  cellClicked(event) {
    if (
      event &&
      event.value &&
      event.colDef &&
      event.colDef.field &&
      event.colDef.field == 'prTitle'
    ) {
      this.addEditPurchaseRequest(true);
    }
  }
  selectionAllChange() {
    if (this.selectedStatuses.indexOf('all') > -1) {
      this.selectedStatuses = ['all', ...this.statuses];
    } else if (this.selectedStatuses.indexOf('all') == -1) {
      this.selectedStatuses = [];
    }
    this.fetchData();
  }
  selectionStatusChange(allS: any) {
    if (
      this.selectedStatuses.indexOf('all') > -1 &&
      this.selectedStatuses.length < this.statuses.length + 1
    ) {
      this.selectedStatuses.splice(this.selectedStatuses.indexOf('all'), 1);
      allS.deselect();
    } else if (
      this.selectedStatuses.indexOf('all') == -1 &&
      this.selectedStatuses.length == this.statuses.length
    ) {
      this.selectedStatuses = ['all', ...this.statuses];
    }
    this.fetchData();
  }
  get isRowSelected() {
    if (
      this.gridApi &&
      this.gridApi.getSelectedRows() &&
      this.gridApi.getSelectedRows().length > 0
    )
      return false;
    else return true;
  }
}
