import { Component, EventEmitter, OnInit } from '@angular/core';
import { FileUploadService } from 'src/services/file-upload.service';

@Component({
  selector: 'app-file-data-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.scss']
})
export class FileManagementComponent implements OnInit {

  showDataModule = false;
  fileObj: any;

  newVersionSaved = new EventEmitter();

  constructor(private fileUploadService: FileUploadService) { }

  ngOnInit(): void {
    this.fileUploadService.fileDeleted
    .subscribe((res) => {
      if (this.showDataModule && this.fileObj) {
        let hideData = false;
        if (res.deleteAll) hideData = true;
        else if (res.fileName && this.fileObj.name==res.fileName) {
          if (res.versionNo) {
            this.fileObj.uu_id==res.versionNo && (hideData = true);
          }
          else
            hideData = true
        }
        if (hideData) {
          this.showDataModule = false;
          this.fileObj = null;
        }
      }
    });
  }

  loadDataModule(e) {
    this.showDataModule = false;
    this.fileObj = e;
    setTimeout(() => {
      this.showDataModule = true;
    }, 300);
  }

  updateFilesModule(e) {
    if (!e) {
      this.showDataModule = false;
      this.fileObj = null;
    } else {
      this.fileObj.uu_id = e.versionNo;
      this.newVersionSaved.next(e);
    }
  }

}
