<h1 mat-dialog-title>{{editMode ? 'Update' : 'Add'}} Vendor</h1>
<form mat-dialog-content [formGroup]="addEditForm">
    <div class="row m-0">
        <div class="col-md-4">
            <strong>Vendor Name*</strong>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter name" matInput formControlName="vendorName">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <strong>Email*</strong>
            <mat-form-field appearance="fill">
                <input type="email" placeholder="Enter email" matInput formControlName="vendorEmail">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <strong>CNIC*</strong>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter CNIC" matInput formControlName="cnicNumber">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <strong>Phone*</strong>
            <mat-form-field appearance="fill">
                <input type="tel" placeholder="Enter phone #" matInput formControlName="vendorPhone">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <strong>Mobile*</strong>
            <mat-form-field appearance="fill">
                <input type="tel" placeholder="Enter mobile #" matInput formControlName="vendorMobile">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <strong>NTN #*</strong>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter NTN" matInput formControlName="ntnNumber">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <strong>Address*</strong>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter address" matInput formControlName="vendorAddress">
            </mat-form-field>
        </div>
        <div class="col-md-6 d-flex align-items-center justify-content-center">
            <mat-slide-toggle formControlName="isTaxExempted">Tax Exempted?
            </mat-slide-toggle>
        </div>
        <div class="col-md-6">
            <strong>Exemption Reason*</strong>
            <mat-form-field appearance="fill">
                <textarea rows="3" type="text" placeholder="Enter reason" matInput formControlName="reason"></textarea>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <strong>Vendor Description*</strong>
            <mat-form-field appearance="fill">
                <textarea rows="3" type="text" placeholder="Enter description" matInput formControlName="vendorDiscription"></textarea>
            </mat-form-field>
        </div>
    </div>
</form>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="accent" (click)="onSaveClick()" [disabled]="addEditForm.invalid">{{editMode ? 'Update' : 'Save'}}</button>
</div>
