import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { CHILD_ITEMS, INVALID_TEAM, MASTER_ITEMS, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { ItemManagerService } from 'src/services/Item-Management-Services';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { UsersService } from 'src/services/User-services/user-services';

@Component({
  selector: 'app-product-filters',
  templateUrl: './product-filters.component.html',
  styleUrls: ['./product-filters.component.css']
})
export class ProductFiltersComponent implements OnInit, OnChanges {

  @Output() filterEvent = new EventEmitter<any>();
  @Input() sourcePage?: string;
  @Input() hardcodedFilters?: any;
  @Input() isDialog?: boolean = false;

  userTeam: any = '';
  isRetailerTeam: boolean = false;
  disabledAccountSelection: boolean = false;

  releaseFromDate = new FormControl('');
  releaseToDate = new FormControl('');
  isReleaseDateRangeOpen = false;
  productTitleName = '';
  accountIds: any = '';
  accountList = [];

  screenPreference: any;
  screenFilters: any;

  constructor(
    private itemManagerService: ItemManagerService,
    private usersService: UsersService,
    private userPreferenceService: UserPreferenceService) { }

  ngOnInit() {
    !this.isDialog && this.getUserSecreenPrefereence();
    this.initiateValues();
    this.setRoleBaseAccess();
  }

  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(this.sourcePage === 'Product' ? MASTER_ITEMS : CHILD_ITEMS, this.sourcePage === 'Product' ? MASTER_ITEMS : CHILD_ITEMS);
    this.screenFilters = this.screenPreference.screenFilters ? JSON.parse(this.screenPreference.screenFilters) : null;
    if (this.screenFilters) {
      this.productTitleName = this.screenFilters[this.sourcePage === 'Product' ? 'ProductTitleName' : 'ItemDescription'];
      this.releaseFromDate.setValue(this.screenFilters.ReleaseFromDate ? new Date(this.screenFilters.ReleaseFromDate).toDateString() : null);
      this.releaseToDate.setValue(this.screenFilters.ReleaseToDate ? new Date(this.screenFilters.ReleaseToDate).toDateString() : null);
      this.accountIds = this.screenFilters.AccountIds ? this.screenFilters.AccountIds.split(',').map(a => Number(a)) : this.screenFilters.AccountIds;
    }
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.accountIds = [this.usersService.getTeamRoleAccount()];
        this.disabledAccountSelection = true;
        this.isRetailerTeam = false;
        this.emitFilterEvent();
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        this.emitFilterEvent();
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  ngOnChanges() {
    if (this.hardcodedFilters) {
      this.accountIds = this.hardcodedFilters.AccountIds.split(',').map(a => Number(a));
      this.disabledAccountSelection = true;
    }
  }

  initiateValues() {
    this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
      this.accountList = accountList;
    });
  }

  emitFilterEvent() {
    let fromDateValue = this.releaseFromDate.value;
    let toDateValue = this.releaseToDate.value;
    fromDateValue = fromDateValue ? moment(fromDateValue).format('MM/DD/YYYY') : '';
    toDateValue = toDateValue ? moment(toDateValue).format('MM/DD/YYYY') : '';
    const eventBody = {
      ...fromDateValue && { ReleaseFromDate: fromDateValue },
      ...toDateValue && { ReleaseToDate: toDateValue },
      ...this.productTitleName && { [this.sourcePage === 'Product' ? 'ProductTitleName' : 'ItemDescription']: this.productTitleName },
      ...(this.accountIds && this.accountIds.length) && { AccountIds: this.accountIds.join(',') }
    };
    if (!this.isDialog) {
      this.screenPreference.screenFilters = eventBody;
      this.updateScreenPreference();
    }
    this.filterEvent.emit(eventBody);
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertFilterPreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  clearValues() {
    this.productTitleName = '';
    this.releaseFromDate.reset();
    this.releaseToDate.reset();
    this.accountIds = this.disabledAccountSelection ? this.accountIds : '';
    this.emitFilterEvent();
  }

  applyReleaseDate() {
    this.isReleaseDateRangeOpen = !this.isReleaseDateRangeOpen;
    this.emitFilterEvent();
  }

}
