import { TreeviewItem } from 'ngx-treeview';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

// DIM_GEO:
// CONSUMER_REGION
// CONSUMER_SUB_REGION
// CONSUMER_GEOGRAPHY
// CREDITED_SUB_REGION
// CREDITED_SALES_LOCATION

// DIM_PARTNER:
// CONSUMER_TOP_PARENT

// DIM_DATE:
// FISCAL_YEAR
// FISCAL_QUARTER_NAME
// FISCAL_MONTH_NAME
// FSICAL_WEEK_NAME
// DATE

// RMI_PARTNER_GROUP:
// RMI_PARTNER_GROUP



export enum FileFormat {
  PPTX = 0,
  PDF = 1,
  PNG = 2,
  IMAGE = 3,
  XLSX = 4,
  DOCX = 5,
  CSV = 6,
  XML = 7,
  MHTML = 8,
  ACCESSIBLEPDF = 9,
};

export interface PercentageSeriesInput {
  daxData: any[],
  orderByKey: string,
  seriesKey: string,
  valueKey: string
}

export const generateTitleDiv = (page) => {
  return `<div class="title-bar p-2 d-flex position-relative align-items-center side-nav-bar">
            <h4 class="m-0 ml-4 w-100">${page.pageTitle}</h4>
          </div>`;
}

export const NO_IMAGE = 'NO_IMAGE';

export const undefinedImagePath = '../assets/empower/pitch-factory/images/data_unavailable.png';

export const CreateSideNavTreeList = (sideNavList) => {

  // pageCategory
  // navTitle
  const levelList = [
    { keyField: 'pageCategory', valueField: 'pageCategory', levelIndex: 1 },
    { keyField: 'reportPageId', valueField: 'navTitle', levelIndex: 2 }
  ];

  const landingPage = _.find(sideNavList, a => a.landingPage);
  const dashboardNode = new TreeviewItem({
    text: landingPage.navTitle,
    value: landingPage.reportPageId,
    checked: landingPage.active,
    collapsed: true,
    disabled: landingPage.landingPage
  });


  sideNavList = _.filter(sideNavList, a => !a.landingPage);
  const selectedValues = _.map(_.filter(sideNavList, a => a.active), a => a.reportPageId);
  const uniqByLevelList = _.uniqBy(sideNavList, a => a[levelList[0].keyField]);
  const treeList = addChildren(uniqByLevelList, sideNavList, [], levelList, levelList[0]);

  let tree = [];
  tree.push(dashboardNode);
  treeList.forEach((obj) => {
    let data = formTreeView(obj, selectedValues);
    tree.push(data);

  });
  return tree;
}

export const generateUniqueId = (parts = 2): string => {
  const stringArr = [];
  for (let i = 0; i < parts; i++) {
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('_');
};

export const CreateLocationTreeList = (locationList, selectedValues: any = []) => {

  // consumeR_REGION
  // consumeR_SUB_REGION
  // consumeR_GEOGRAPHY
  // crediteD_SUB_REGION
  // crediteD_SALES_LOCATION

  const levelList = [
    { keyField: 'consumeR_REGION', valueField: 'consumeR_REGION', levelIndex: 1 },
    // { keyField: 'consumeR_SUB_REGION', valueField: 'consumeR_SUB_REGION', levelIndex: 2 },
    // { keyField: 'consumeR_GEOGRAPHY', valueField: 'consumeR_GEOGRAPHY', levelIndex: 3 },
    // { keyField: 'crediteD_SUB_REGION', valueField: 'crediteD_SUB_REGION', levelIndex: 4 },
    { keyField: 'crediteD_SALES_LOCATION', valueField: 'crediteD_SALES_LOCATION', levelIndex: 2 },
  ];

  const uniqByLevelList = _.uniqBy(locationList, a => a[levelList[0].keyField]);

  const treeList = addChildren(uniqByLevelList, locationList, [], levelList, levelList[0]);

  let tree = [];
  treeList.forEach((obj) => {
    let data = formTreeView(obj, selectedValues);
    tree.push(data);

  });
  return tree;
};

export const CreatePartnerTreeList = (partnerList, selectedValues: any = []) => {

  // consumer_Top_Parent
  // distributor_Top_Parent

  const levelList = [
    { keyField: 'consumer_Top_Parent', valueField: 'consumer_Top_Parent', levelIndex: 1 },
    // { keyField: 'distributor_Top_Parent', valueField: 'distributor_Top_Parent', levelIndex: 2 }
  ];

  const uniqByLevelList = _.uniqBy(partnerList, a => a[levelList[0].keyField]);

  const treeList = addChildren(uniqByLevelList, partnerList, [], levelList, levelList[0]);

  let tree = [];
  treeList.forEach((obj) => {
    let data = formTreeView(obj, selectedValues);
    tree.push(data);

  });
  return tree;
};

export const CreatePartnerGroupTreeList = (partnerGroupList, selectedValues: any = []) => {

  // rmI_PARTNER_GROUP

  const levelList = [
    { keyField: 'rmI_PARTNER_GROUP', valueField: 'rmI_PARTNER_GROUP', levelIndex: 1 },
  ];

  const uniqByLevelList = _.uniqBy(partnerGroupList, a => a[levelList[0].keyField]);

  const treeList = addChildren(uniqByLevelList, partnerGroupList, [], levelList, levelList[0]);

  let tree = [];
  treeList.forEach((obj) => {
    let data = formTreeView(obj, selectedValues);
    tree.push(data);

  });
  return tree;
};

export const CreateCalendarList = (timeframeList, selectedValues: any = []) => {

  // fiscaL_YEAR
  // fiscaL_QUARTER_NAME
  // fiscaL_MONTH_NAME
  // fiscaL_WEEK_NAME
  // date

  const levelList = [
    { keyField: 'fiscaL_YEAR', valueField: 'fiscaL_YEAR', levelIndex: 1 },
    { keyField: 'fiscaL_QUARTER_NAME', valueField: 'fiscaL_QUARTER_NAME', levelIndex: 2 },
    { keyField: 'fiscaL_MONTH_NAME', valueField: 'fiscaL_MONTH_NAME', levelIndex: 3 },
    { keyField: 'fiscaL_WEEK_NAME', valueField: 'fiscaL_WEEK_NAME', levelIndex: 4 },
    { keyField: 'date', valueField: 'date', levelIndex: 5 },
  ];

  const uniqByLevelList = _.uniqBy(timeframeList, a => a[levelList[0].keyField]);

  const treeList = addChildren(uniqByLevelList, timeframeList, [], levelList, levelList[0]);

  let tree = [];
  treeList.forEach((obj) => {
    let data = formTreeView(obj, selectedValues);
    tree.push(data);

  });
  return tree;
};

function addChildren(levelDataList, dataList, ChildrenTreeList, levelList, level) {
  const childrenList: any = [];
  for (let i = 0; i < levelDataList.length; i++) {
    let node;
    node = levelDataList[i];
    let map: any = {
      value: node[level.keyField],
      text: node[level.valueField]
    };

    const levelFilteredList = _.filter(dataList, a => a[level.keyField] === node[level.keyField]);
    if (level.levelIndex < _.maxBy(levelList, a => a.levelIndex).levelIndex) {
      const nextLevel = _.find(levelList, a => a.levelIndex == (level.levelIndex + 1));
      const uniqByLevelList = _.uniqBy(levelFilteredList, a => a[nextLevel.keyField]);
      map.children = addChildren(uniqByLevelList, levelFilteredList, [], levelList, nextLevel);
    }

    const levelValueExist = _.find(ChildrenTreeList, a => a.value == node[level.keyField]);
    if (!levelValueExist) {
      childrenList.push(map);
    };
  }

  return childrenList;
}

// create nth nested object of tree view items
const formTreeView = (obj, selectedValues: any = []) => {
  return new TreeviewItem({
    text: obj.text,
    value: obj.value,
    collapsed: true,
    checked: selectedValues.includes(obj.value),
    children: obj.children && obj.children.length && obj.children.map((el) => {
      return formTreeView(el, selectedValues);
    })
  });
}

export const GetCategoriesData = (seriesInput: PercentageSeriesInput) => {
  const categories = _.map(
    _.orderBy(_.uniqBy(seriesInput.daxData, a => a[seriesInput.orderByKey]),
      [seriesInput.orderByKey], ['asc']),
    b => {
      return { label: b[seriesInput.orderByKey] }
    });
  return categories;
}

export const GetPercentageSeriesData = (seriesInput: PercentageSeriesInput) => {
  const dataSeries: any = [];
  const seriesGroup = _.groupBy(seriesInput.daxData, a => a[seriesInput.seriesKey]);
  _.keys(seriesGroup).forEach(series => {
    const orderedValues = _.orderBy(seriesGroup[series], [seriesInput.orderByKey], ['asc']);
    dataSeries.push({
      seriesname: series,
      data: _.map(orderedValues, a => { return { value: a[seriesInput.valueKey] } })
    });
  });
  return dataSeries;
}

export const ReduceListByProps = (data, props: any = {}) => {
  const result = data.reduce((r, e) => {
    Object.entries(e).forEach(([k, v]: any) => {
      if (props.groupBy == k) {
        if (!r[v]) r[v] = {};
        r[v][e[props.countBy]] = (r[v][e[props.countBy]] || 0) + 1;
      }
    });
    return r;
  }, {});
  return result;
}

export const ReduceListByPropsForGrid = (data, props: any = {}) => {
  const result = data.reduce((r, e) => {
    Object.entries(e).forEach(([k, v]: any) => {
      if (props.groupBy == k) {
        if (!r[v]) r[v] = {};
        if (!r[v][e[props.groupBySecondLevel]]) r[v][e[props.groupBySecondLevel]] = {}
        r[v][e[props.groupBySecondLevel]][e[props.countByParam]] = (r[v][e[props.groupBySecondLevel]][e[props.countByParam]] || 0) + 1;
      }
    });
    return r;
  }, {});
  return result;
}

export const GetDropdownSettingsObject = (key, value) => {
  return {
    singleSelection: true,
    idField: key,
    textField: value,
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true
  } as IDropdownSettings;
};

