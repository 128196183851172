import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlotlyHTMLElement } from 'plotly.js';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { USER_INFO } from 'src/common/keys';
import { colorCodes } from 'src/modules/clustering-forecast/constants/graphColors';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import { LocalstorageService } from 'src/services/localstorage.service';

@Component({
  selector: 'run-tab',
  templateUrl: './run.component.html',
  styleUrls: ['./run.component.scss']
})
export class RunComponent implements OnInit, AfterViewInit, OnChanges {

  scatterPlotRef: PlotlyHTMLElement;
  @Input() resizeGraphSubject: Subject<boolean>;
  @Input() dataObj: any;
  @Input() versionNo: any;

  fileName = '';
  metrices = [];
  runIndex = 1;
  clusteringLabels = [];
  perClusterCount: any;
  objectToCluster = '';
  summaryStatsMetricVariables = [];
  clusterStatistics: any;

  @ViewChild("scatterPlotDiv", { static: false }) scatterPlotDiv: ElementRef;

  customers = [1, 2, 3, 4, 5, 6, 7];
  scatterPlot = {
    data: [],
    layout: {
      autosize: true,
      xaxis: {
        title: {
          text: '',
        }
      },
      yaxis: {
        title: {
          text: '',
        }
      }
    }
  };

  xAxis: any;
  yAxis: any;

  userObj = {} as any;
  graphPlottedFirstTime = false;

  colorCodes = colorCodes;

  constructor(private changeDetectionRef: ChangeDetectorRef, private clusteringForecastService: ClusteringForecastService,
    private plotlyRef: PlotlyService, private spinner: NgxSpinnerService, private storage: LocalstorageService) { }

  ngOnInit(): void {
    this.userObj = this.storage.get(USER_INFO);

    // for window resize handling (graph responsiveness)
    fromEvent(window, 'resize').pipe(
      debounceTime(250))
      .subscribe(() => this.refreshGraph());

    this.resizeGraphSubject
      .pipe(debounceTime(250))
      .subscribe(() => this.refreshGraph());
  }

  ngOnChanges(): void {
  }

  ngAfterViewInit() {

    this.metrices = JSON.parse(JSON.stringify(this.dataObj.metrices));
    this.fileName = JSON.parse(JSON.stringify(this.dataObj.fileName));
    this.runIndex = JSON.parse(JSON.stringify(this.dataObj.runIndex));
    this.clusteringLabels = JSON.parse(JSON.stringify(this.dataObj.clusteringLabels));
    this.perClusterCount = JSON.parse(JSON.stringify(this.dataObj.perClusterCount));
    this.objectToCluster = JSON.parse(JSON.stringify(this.dataObj.objectToCluster));
    this.summaryStatsMetricVariables = JSON.parse(JSON.stringify(this.dataObj.summaryStatsMetricVariables));
    this.clusterStatistics = JSON.parse(JSON.stringify(this.dataObj.clusterStatistics));

    console.log(this.summaryStatsMetricVariables);

    this.xAxis = this.metrices[0].id;
    this.yAxis = this.metrices[1] ? this.metrices[1].id : this.metrices[0].id;
    this.fetchScatterPlotData();
  }

  plotGraph() {
    this.scatterPlot.layout
      .xaxis.title = this.xAxis;
    this.scatterPlot.layout
      .yaxis.title = this.yAxis;

    this.plotlyRef.newPlot(
      this.scatterPlotDiv.nativeElement,
      this.scatterPlot.data,
      this.scatterPlot.layout
    ).then((res) => {
      this.graphPlottedFirstTime = true;
      this.scatterPlotRef = res;
    });
  }

  downloadChart() {
    (this.scatterPlotDiv.nativeElement.getElementsByClassName('modebar-btn')[0] as HTMLElement).click();
  }

  axisChanged() {
    this.scatterPlot.layout
      .xaxis.title = this.xAxis;
    this.scatterPlot.layout
      .yaxis.title = this.yAxis;

    this.fetchScatterPlotData();
  }

  refreshGraph() {
    this.plotlyRef.update(this.scatterPlotDiv.nativeElement, this.scatterPlot.data, this.scatterPlot.layout);
  }

  fetchScatterPlotData() {
    const obj = {
      user_id: this.userObj.userId,
      file_name: this.fileName,
      x_variable: this.xAxis,
      y_variable: this.yAxis,
      run: `${this.runIndex}`,
      version_no: this.versionNo
    };
  const formData = new FormData();
    formData.append('scatter_plot', JSON.stringify(obj));
    this.spinner.show();
    this.clusteringForecastService.fetchScatterPlotData(formData)
      .subscribe((res: any) => {
        const graphData = {};
        res.payload.Cluster_Id.forEach((element, index) => {
          !graphData[element] && (graphData[element] = { x: [], y: [], mode: 'markers', marker: {size: 10, color: this.colorCodes[(element-1)%20]}});
          graphData[element].x.push(res.payload.x[index]);
          graphData[element].y.push(res.payload.y[index]);
          graphData[element].name = '';
          graphData[element].showlegend = true;
          this.clusteringLabels.forEach(clusterElement => {
            if (clusterElement.label_id == element)
              graphData[element].name = clusterElement.label_name;
          });
        });
        const keys = Object.keys(graphData);
        const values = Object.values(graphData);
        this.scatterPlot.data = values;
        this.graphPlottedFirstTime ? this.refreshGraph() : this.plotGraph()
        this.spinner.hide();
      },
        err => {
          this.spinner.hide();
        });

  }

}
