import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';

export interface PeriodicElement {
  name?: string;
  action?: string;
  createdBy?: number;
  createdOn?: Date;
  journeyId?: number;
  journeyName?: string;
  originalGroupId?: string;
  originalReportId?: string;
  originalReportName?: string;
}

@Component({
  selector: 'app-journey-shared-with-me',
  templateUrl: './journey-shared-with-me.component.html',
  styleUrls: ['./journey-shared-with-me.component.scss'],
})
export class JourneySharedWithMeComponent implements OnInit {
  displayedColumns: string[] = ['journeyId', 'name', 'createdOn', 'action'];
  dataSource = []; //ELEMENT_DATA;
  journeys: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: any,
    public userService: UsersService,
    public dialogRef: MatDialogRef<any>,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService
  ) {}

  ngOnInit() {
    this.fetchSharedWithMe();
  }

  private fetchSharedWithMe() {
    this.configService
      .getJourneyCollaboration(null, this.storage.get(USER_ID), null)
      .subscribe((res) => {
        this.journeys = res;
        console.log('Shared with Me', res);
        res.map((item) => {
          item.journey.createdOn = `${new Date(
            item?.journey?.createdOn
          ).toLocaleDateString()} - ${new Date(
            item?.journey?.createdOn
          ).toLocaleTimeString()}`;
        });
        this.dataSource = [...this.journeys];
      });
  }

  viewReport(element: any, collaborationId: number) {
    console.log('element', element);
    if (element?.metaData) {
      const data = JSON.parse(element.metaData);
      data.isPowerBiReport = true;
      data.createdBy = element.createdBy;
      data.createdOn = element.createdOn;
      data.journeyId = element.journeyId;
      data.journeyName = element.journeyName;
      data.originalGroupId = element.originalGroupId;
      data.originalReportId = element.originalReportId;
      data.originalReportName = element.originalReportName;
      data.metaData = element.metaData;
      this.configService.shareJourney.emit(data);
      this.dialogRef.close();

      if (collaborationId > 0) {
        this.configService
          .UpdateJourneyViewedAt({ Id: collaborationId })
          .subscribe((res: any) => {
            console.log('journey-view notification updated');
          });
      }
    }
    console.log('selected Journey', element);
  }
}
