<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="transparent"
  style="height: 100%"
>
  <div class="loader-inner line-scale-pulse-out">
    <!-- <div></div>
    <div></div>
    <div></div> -->
    <div></div>
    <div></div>
    <img src="../assets/images/a-log.png" alt="" />
  </div>
</ngx-spinner>

<router-outlet></router-outlet>
<ng-template #confirmTemp>
  <div mat-dialog-content>
    <p>
      Updates have taken place on the Algo solution.  Please click "OK" below to make sure you have the best Algo experience.
    </p>
  </div>
  <div
    class="action-panel text-right btn-wrapper"
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <div style="display: flex">
      <button
        style="margin-left: 0px"
        mat-raised-button
        class="btn btn-primary m-right"
        color="warn"
        (click)="reload()"
      >
      OK
      </button>
    </div>
  </div>
</ng-template>
