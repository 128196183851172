import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OutlierService } from 'src/services/forecast-services/outlier-service';

// <a (click)="viewChartHandler()">View Chart</a>

@Component({
  selector: 'outlier-line-chart-cell-renderer',
  template: `<div class="chart-cell" *ngIf="params.data.saleValues === 'Original'" (click)="viewChartHandler()">
    <fusioncharts
      [chartConfig]=chartConfig
      [dataSource]="dataSource">
    </fusioncharts>
</div>`,
})
export class OutlierLineChartCellRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  dataSource: any = {
    chart: {
      theme: "fusion",
      showXAxisLine: 0,
      showYAxisValues: 0,
      showValues: 0,
      chartLeftMargin: "0",
      chartTopMargin: "0",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      canvasPadding: "0",
      drawAnchors: "0",
      divLineAlpha: "0"
    },
    data: []
  }

  chartConfig: Object = {
    width: '200',
    height: '80',
    type: 'line',
    dataFormat: 'json',
  };

  constructor(public outlierService: OutlierService) {
  }

  agInit(params: any): void {
    this.params = params;
    this.updateChartData();
  }

  refresh(params?: any): boolean {
    this.updateChartData();
    return true;
  }

  updateChartData() {
    const weekList = this.params.colDef.weekList;
    const chartData = [];
    weekList.forEach(week => {
      chartData.push({ value: this.params.data[`${week.columnField}_adjusted`] });
    });
    this.dataSource.data = chartData;
  }

  viewChartHandler() {
    this.outlierService.setOutlierManagerGridActionSubject(this.params.data)
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
