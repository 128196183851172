<div class="mt-3 mb-2">
  <div class="container-fluid empower-insight-dashboard">

    <div class="row">
      <div class="info-box m-2">
        <h5>Domain</h5>
        <h1>{{countsData['originCount'] || '0'}}</h1>
      </div>
      <div class="info-box m-2">
        <h5>Teams</h5>
        <h1>{{countsData['teamsCount'] || '0'}}</h1>
      </div>
      <div class="info-box m-2">
        <h5>User</h5>
        <h1>{{countsData['userCount'] || '0'}}</h1>
      </div>
      <div class="info-box m-2">
        <h5>Categories</h5>
        <h1>{{countsData['categoryCount'] || '0'}}</h1>
      </div>
      <div class="info-box m-2">
        <h5>Reports</h5>
        <h1>{{countsData['reportCount'] || '0'}}</h1>
      </div>
      <div class="info-box m-2">
        <h5>Menus</h5>
        <h1>{{countsData['menuCount'] || '0'}}</h1>
      </div>
      <div class="date-box m-2">
        <h3>{{lastRefreshTimestamp | date:"MM/dd/yyyy h:mm:ss a" }}</h3>
        <p><small>Last Refresh Timestamp (PST)</small></p>
      </div>
    </div>

    <div class="row">

      <div class="col-2">
        <div class="ng-date-range form-group">
          <input type="text" class="w-100" ngxDaterangepickerMd [(ngModel)]="selectedDates"
            [showCustomRangeLabel]="true" (change)="dateRangeChange()" [alwaysShowCalendars]="true"
            [ranges]="dateRanges" [linkedCalendars]="true" [showClearButton]="true" placeholder="Select please..." />
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Domain" [settings]="domainControlSettings"
            [data]="domainList" [(ngModel)]="selectedDomains" (onSelect)="filterChange()" (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Team" [settings]="teamControlSettings" [data]="teamList"
            [(ngModel)]="selectedTeams" (onSelect)="filterChange()" (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Designation" [settings]="roleControlSettings"
            [data]="roleList" [(ngModel)]="selectedRoles" (onSelect)="filterChange()" (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Email" [settings]="emailControlSettings"
            [data]="emailList" [(ngModel)]="selectedEmails" (onSelect)="filterChange()" (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Name" [settings]="nameControlSettings" [data]="nameList"
            [(ngModel)]="selectedNames" (onSelect)="filterChange()" (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Country" [settings]="countryControlSettings"
            [data]="countryList" [(ngModel)]="selectedCountries" (onSelect)="filterChange()"
            (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Partner" [settings]="partnerControlSettings"
            [data]="partnerList" [(ngModel)]="selectedPartners" (onSelect)="filterChange()"
            (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Report Grouping"
            [settings]="reportGroupingControlSettings" [data]="reportGroupingList" [(ngModel)]="selectedReportGroups"
            (onSelect)="filterChange()" (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group multi-select d-flex w-100">
          <ng-multiselect-dropdown class="h-100" placeholder="Reports" [settings]="reportControlSettings"
            [data]="reportList" [(ngModel)]="selectedReports" (onSelect)="filterChange()" (onDeSelect)="filterChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-2">
        <button mat-raised-button color="accent" (click)="clearFilters()">Clear Filters</button>
      </div>
    </div>

    <div class="row m-2">
      <div class="col-4 empower-chart-section chart-section">
        <h4>Visit Count by Menu</h4>
        <fusion-ms-bar-chart height="450" [dataSeries]="menuCountSeries" [xaxisCatgoryList]="menuCountxAxis"
          xAxisName="Menu Name" yAxisName="Visit Count">
        </fusion-ms-bar-chart>
      </div>
      <div class="col-4 empower-chart-section chart-section">
        <h4>Visit Count by User</h4>
        <fusion-ms-bar-chart height="450" [dataSeries]="userCountSeries" [xaxisCatgoryList]="userCountxAxis"
          xAxisName="User Name" yAxisName="Visit Count">
        </fusion-ms-bar-chart>
      </div>
      <div class="col-4 empower-chart-section chart-section">
        <h4>Visit Count by Report</h4>
        <fusion-doughnut-chart height="450" [dataSeries]="reportCountSeries">
        </fusion-doughnut-chart>
      </div>
    </div>

    <div class="row m-2">
      <div class="col-6 empower-chart-section chart-section">
        <h4>User Count by Activity Date</h4>
        <fusion-ms-line-scrollable-chart height="450" [xaxisCatgoryList]="activityxAxisLables"
          [dataSeries]="userActivitySeries" xAxisName="Activity Date" yAxisName="User Count">
        </fusion-ms-line-scrollable-chart>
      </div>
      <div class="col-6 empower-chart-section chart-section">
        <h4>Menu Count by Activity Date</h4>
        <fusion-ms-line-scrollable-chart height="450" [xaxisCatgoryList]="activityxAxisLables"
          [dataSeries]="menuActivitySeries" xAxisName="Activity Date" yAxisName="Menu Count">
        </fusion-ms-line-scrollable-chart>
      </div>
    </div>

    <div class="row">
      <div class="col-12 empower-chart-section chart-section">
        <h4>Daily Trend by Reports and Exports</h4>
        <fusion-ms-combination-scrollable-chart height="450" [xaxisCatgoryList]="activityxAxisLables"
          [dataset]="reportExportTrendSeries?.dataset" [lineset]="reportExportTrendSeries?.lineset"
          xAxisName="Activity Date" yAxisName="Count of MenuName" sNumberSuffix="" sYAxisName="Count of Exports">
        </fusion-ms-combination-scrollable-chart>
      </div>
    </div>

    <div class="all-show-grid mt-3">
      <ag-grid-angular class="forecast-grid ag-theme-material" suppressRowClickSelection groupHeaders
        toolPanelSuppressGroups [defaultColDef]="defaultColDef" toolPanelSuppressValues [columnDefs]="gridColumnDefs"
        [pagination]="true" [rowData]="gridData" [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="cacheBlockSize" (gridReady)="onGridReady($event)" #insightsDashboad>
      </ag-grid-angular>
    </div>

  </div>

</div>

<!-- <h1>Component Works</h1> -->