import { Injectable } from '@angular/core';
import { EMPOWER_REPORT_GAMING, EMPOWER_REPORT_MODERN_LIFE } from 'src/common/keys';
import { EmpowerGamingService } from './empower-gaming.service';
import { EmpowerModernLifeService } from './empower-modern-life.service';

@Injectable()
export class EmpowerFactoryService {

  constructor(private modernLifeService: EmpowerModernLifeService, private gamingService: EmpowerGamingService) {
  }

  GetDataSerivce(serivceName) {
    let dataSerivceObject: any;
    switch (serivceName) {
      case EMPOWER_REPORT_MODERN_LIFE: {
        dataSerivceObject = this.modernLifeService;
        break;
      }
      case EMPOWER_REPORT_GAMING: {
        dataSerivceObject = this.gamingService;
      }
    }
    return dataSerivceObject;
  }


}

