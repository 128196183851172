import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { generateTitleDiv, undefinedImagePath } from 'src/modules/empower-module/helper';
import { EmpowerGamingService } from 'src/services/empower-services/empower-gaming.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';

@Component({
  selector: 'empower-xbox-customer-vs-market',
  templateUrl: './empower-xbox-customer-vs-market.component.html',
  styleUrls: ['./empower-xbox-customer-vs-market.component.scss']
})
export class EmpowerXboxCustomerVsMarketComponent implements OnInit {

  @Input() data?: any;
  _unsubscribeAll = new Subject();
undefinedImagePath = '';

  partnerChartCaption: string = 'Partner Revenue Growth %';
  marketChartCaption: string = 'Market Revenue Growth %';

  totalRevPartnerFirst: number;
  totalRevPartnerSecond: number;
  totalRevPartnerThird: number;
  totalRevPartnerForth: number;

  totalRevMarketFirst: number;
  totalRevMarketSecond: number;
  totalRevMarketThird: number;
  totalRevMarketForth: number;

  yearlyGamesIncrease: number;
  yearlyConsoleIncrease: number;
  yearlyAccessoriesIncrease: number;
  yearlySubscriptionsIncrease: number;

  pageComment: string;
  innerHtml = '';

  constructor(
    public empowerGamingService: EmpowerGamingService, private empowerExportService: EmpowerExportService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);
      this.innerHtml = generateTitleDiv(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const daxReponse = this.empowerGamingService.getPagesState(pageId);
    this.pageComment = this.empowerGamingService.getPageCommentState(pageId);
    if (daxReponse && daxReponse.length) {
      this.loadVisuals(daxReponse);
    } else {
      this.executePageDaxQuery();
    }
  }

  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.empowerGamingService.getDashboardState()
    };
    this.spinner.show();
    this.empowerGamingService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        this.emitExportEvent(true);
      }
      this.spinner.hide();
    },
      err => {
       this.undefinedImagePath = undefinedImagePath;
        this.spinner.hide();
      });
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }

  setPageState(pageId, daxData) {
    this.empowerGamingService.setPagesState(pageId, daxData);
  }

  loadVisuals(daxData) {
    this.emitExportEvent(true);
    this.load1stVisual(daxData[8]);
    this.load2ndVisual(daxData[9]);
    this.load3rdVisual(daxData[10]);
    this.load4thVisual(daxData[11]);
    this.load5thVisual(daxData[0]);
    this.load6thVisual(daxData[1]);
    this.load7thVisual(daxData[2]);
    this.load8thVisual(daxData[3]);
    this.load9thVisual(daxData[4]);
    this.load10thVisual(daxData[5]);
    this.load11thVisual(daxData[6]);
    this.load12thVisual(daxData[7]);
  }

  load1stVisual(visualData) {
    this.yearlyGamesIncrease = _.get(visualData.daxResponse, ['0', '[Total Yearly Revenue Opportunity if you close the gap with Market Growth]'], 0);
  }

  load2ndVisual(visualData) {
    this.yearlyConsoleIncrease = _.get(visualData.daxResponse, ['0', '[Total Yearly Revenue Opportunity if you close the gap with Market Growth]'], 0);
  }

  load3rdVisual(visualData) {
    this.yearlyAccessoriesIncrease = _.get(visualData.daxResponse, ['0', '[Total Yearly Revenue Opportunity if you close the gap with Market Growth]'], 0);
  }

  load4thVisual(visualData) {
    this.yearlySubscriptionsIncrease = _.get(visualData.daxResponse, ['0', '[Total Yearly Revenue Opportunity if you close the gap with Market Growth]'], 0);
  }

  load5thVisual(visualData) {
    this.totalRevPartnerFirst = _.get(visualData.daxResponse, ['0', '[Total_Rev__]'], 0);
  }

  load6thVisual(visualData) {
    this.totalRevPartnerSecond = _.get(visualData.daxResponse, ['0', '[Total_Rev__]'], 0);
  }

  load7thVisual(visualData) {
    this.totalRevPartnerThird = _.get(visualData.daxResponse, ['0', '[Total_Rev__]'], 0);
  }

  load8thVisual(visualData) {
    this.totalRevPartnerForth = _.get(visualData.daxResponse, ['0', '[Total_Rev__]'], 0);
  }

  load9thVisual(visualData) {
    this.totalRevMarketFirst = _.get(visualData.daxResponse, ['0', '[Market Growth %]'], 0);
  }

  load10thVisual(visualData) {
    this.totalRevMarketSecond = _.get(visualData.daxResponse, ['0', '[Market Growth %]'], 0);
  }

  load11thVisual(visualData) {
    this.totalRevMarketThird = _.get(visualData.daxResponse, ['0', '[Market Growth %]'], 0);
  }

  load12thVisual(visualData) {
    this.totalRevMarketForth = _.get(visualData.daxResponse, ['0', '[Market Growth %]'], 0);
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(6, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.empowerGamingService.setPageCommentState(pageId, comment);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
