import { Component, OnInit } from '@angular/core';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ItemHierarchyManagerService } from 'src/services/setup-services/item-hierarchy-manager.service';
import * as _ from 'lodash';
import { of, Observable } from 'rxjs';
import { getItemHierarchyHiddenColDefs, getItemHierarchyColDefs } from '../ag-grid/grid-options';
import {  GridApi, ColumnApi, GridOptions, ColDef } from 'ag-grid-enterprise';
import { defaultColDef } from '../../hierarchy-setup/calendar-setup/ag-grid/grid-options';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ItemHierarchyManagerEditorComponent } from './item-hierarchy-manager-editor/item-hierarchy-manager-editor.component';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-item-hierarchy-manager',
  templateUrl: './item-hierarchy-manager.component.html',
  styleUrls: ['./item-hierarchy-manager.component.scss']
})
export class ItemHierarchyManagerComponent implements OnInit {
  itemHierarchyManagerLevel: any[] = [];
  itemHierarchyList: any = [];
  filteredItemHierarchyList: Observable<any[]>;
  itemHierarchyRowResponse: any[] = [];

  public gridOptions: GridOptions = {
    frameworkComponents: {
      nameRenderer: NameRendererComponent,
      deleteButtonRenderer: ButtonRendererComponent
    },
  };

  public defaultColDef = defaultColDef;

  public hiddenGridApi: GridApi;
  public hiddenColApi: ColumnApi;
  hiddenGridData: any = []
  public columnHiddenDefs = [];

  public gridApi: GridApi;
  public colApi: ColumnApi;
  gridData: any = [];
  itemGridColumnDefs: ColDef[] = [];


  constructor(private itemHierarchyManagerService: ItemHierarchyManagerService,
    public spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialog: MatDialog,
    public toastr: NgxToasterService,

  ) { }

  ngOnInit() {
    this.getItemHierarchyLevelList();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.colApi;
  }

  onHiddenGridReady(params) {
    this.hiddenGridApi = params.api;
  }

  getItemHierarchyLevelList() {
    this.spinner.show();
    this.itemHierarchyManagerService.GetProductHierarchyList().pipe(finalize(() => this.spinner.hide())).subscribe(response => {
      this.getItemHierarchyList();
      if (response) {
        this.itemHierarchyManagerLevel = response.productMetadataList;
        this.columnHiddenDefs = getItemHierarchyHiddenColDefs({ ...this.itemHierarchyManagerLevel });
        this.itemGridColumnDefs = getItemHierarchyColDefs(this.itemHierarchyManagerLevel, this);
      }
    },
      err => {
        this.toastr.error('Error', 'System failed to Get Product Hierarchy Level List.');
      });
  }

  getItemHierarchyList() {
    this.spinner.show();
    this.itemHierarchyManagerService.GetItemHierarchyList().pipe(finalize(() => this.spinner.hide())).subscribe((response: any) => {
      this.gridData = response;
    },
      err => {
        this.toastr.error('Error', 'System failed to Get Product Hierarchy.');
      });
  }

  onExportData() {
    this.hiddenGridData = this.itemHierarchyRowResponse;
    this.hiddenGridApi && this.hiddenGridApi.setRowData(this.hiddenGridData);
    setTimeout(() => {
      this.exportDataTemplate();
    }, 200);
  }

  importNewItemWeeklyData() {
    (document.getElementById('upload-new-item-approval-promotion-data') as any).value = '';
    document.getElementById('upload-new-item-approval-promotion-data').click();
  }

  exportDataTemplate() {
    const csvExportParams = gridDataExportParams({
      fileName: 'ItemHierarchyManager',
      colDefs: this.columnHiddenDefs,
      isExportTemplate: true,
      allColumns: true
    });
    this.hiddenGridApi.exportDataAsCsv(csvExportParams);
  }

  handleExport(event) {
    event.stopPropagation();
  }

  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [files] = fileInput.target.files;
      formDataReader.append('file', files)
      this.uploadImportedCsvFile(formDataReader)
    }
  }

  uploadImportedCsvFile(importDataFile) {
    this.itemHierarchyManagerService.ImportItemHierarchyManagerList(importDataFile).subscribe(
      (res: any) => {
        if (res) {
          const code = _.get(res, '0.messageCode', 400);
          const message = _.get(res, '0.messageText', 'Item Hierarchy Manager Imported Successfully.');
          if (code === 200) {
            this.getItemHierarchyList();
            this.toastr.success('Success', `${message}`);
          } else {
            res.forEach(element => {
              this.toastr.error('Error', `${element.messageText}`);
            });
          }
        }
      },
      (err: any) => {
        this.toastr.error('Error', 'Item Hierarchy Manager Import Failed.');
      });
  }

  addEditItemHierarchyRow(isEdit: boolean = false, rowData: any = {}) {

    const dialogRef = this.dialog.open(ItemHierarchyManagerEditorComponent, {
      panelClass: 'editor-dialog',
      width: '800px',
      minHeight: '250px',
      disableClose: true,
      data: {
        isEdit: isEdit,
        rowData: rowData,
        itemHierarchyManagerLevel: _.cloneDeep(this.itemHierarchyManagerLevel),
        gridData: this.gridData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getItemHierarchyList();
      }
    });
  }

  navigateToHierarchySetup(row: any) {
    this.addEditItemHierarchyRow(true, row.rowData);
  }

  deleteItemHierarchyManager(row: any) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to delete data?' }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.itemHierarchyManagerService.DeleteItemHierarchyRow(row.rowData.productHierarchyRelationId).pipe(finalize(() => this.spinner.hide())).subscribe(res => {
          if (res) {
            this.getItemHierarchyList();
            this.toastr.success('Success', 'Item Hierarchy Deleted successfully.');
          } else {
            this.toastr.error('Error', 'System failed to delete Location hierarchy.');
          }
        },
          err => {
            this.toastr.error('Error', 'System failed to delete item hierarchy.');
          });
      }
    });
  }

}
