<div class="m-0 pb-4 mt-3">
  <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
  <div class="d-flex mt-2" *ngIf="!undefinedImagePath">
    <div class="col-8">
      <p class="font-18">Partner: | {{ empowerModernLifeService.getDashboardPartner() }}</p>
      <app-image-upload-viewer [imageUrl]="imageUrl" orientation="square" (emitSelectedFile)="imageChange($event)"
        (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
    <div class="col-4 empower-chart-section">
      <h4>Revenue opportunity taking 1% refresh from FY20 Baseline</h4>
      <div class="number-sectoion mb-2">
        <p><strong>{{revenueBaseline | currency }}</strong></p>
      </div>
    </div>
  </div>
  <app-image-upload-viewer *ngIf="undefinedImagePath" [imageUrl]="undefinedImagePath"
      orientation="square" (imageLoaded)="emitExportEvent($event)">
  </app-image-upload-viewer>
</div>
<div class="row m-0">
  <div class="col-12 mt-3">
    <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
  </div>
  <div class="col-12">
    <app-report-page-footer></app-report-page-footer>
  </div>
</div>