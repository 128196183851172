<div class="row product-grid-filter-row">
  <div class="product-filter-field">
    <mat-form-field appearance="fill">
      <input matInput type="search" (keydown.enter)="emitFilterEvent()" placeholder="Title" [(ngModel)]="productTitleName">
      <mat-icon class="search-action-icon" (click)="emitFilterEvent()" matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="product-filter-field">
    <mat-form-field appearance="fill" >
      <mat-select multiple placeholder="Make" [disabled]="disabledAccountSelection" [(ngModel)]="makes" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let make of makeList"  [value]="make">{{make}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="product-filter-field">
    <mat-form-field appearance="fill" >
      <mat-select multiple placeholder="Model" [disabled]="disabledAccountSelection" [(ngModel)]="models" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let model of modelList"  [value]="model">{{model}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="product-filter-field">
    <mat-form-field appearance="fill" >
      <mat-select multiple placeholder="Year" [disabled]="disabledAccountSelection" [(ngModel)]="years" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let year of yearList"  [value]="year">{{year}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="product-filter-field">
    <mat-form-field appearance="fill" >
      <mat-select multiple placeholder="Brand" [disabled]="disabledAccountSelection" [(ngModel)]="brands" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let brand of brandList"  [value]="brand">{{brand}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="product-filter-clear-btn">
    <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
  </div>
</div>