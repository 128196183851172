<br>

<div class="label-value">
  <label><strong> {{clientInfo.clientName}}</strong></label>
</div>

<mat-horizontal-stepper>
  <mat-step label="Calendar Setup">
    <app-calendar-setup></app-calendar-setup>
  </mat-step>

  <mat-step label="Product Hierarchy Setup">
    <app-product-hierarchy></app-product-hierarchy>
  </mat-step>

  <mat-step label="Location Hierarchy Setup">
    <app-location-hierarchy></app-location-hierarchy>
  </mat-step>
</mat-horizontal-stepper>
