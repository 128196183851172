<div id="fb-forecast">
  <div id="item-manager" class="item-wrapper insight-wrapper">
    <div class="item-search-wrapper">
      <form action="" class="form-fields show-search"></form>
      <div class="item-search-btn">
        <button class="btn btn-primary" (click)="openDialog({})">
          New Channel
        </button>
      </div>
    </div>
    <div class="items-wrapper-grid product-wrapper">
      <h3 class="title">Account Setting</h3>
      <div class="insight-gallery-banner">
        <p>All Accounts</p>
      </div>
      <ng-container *ngFor="let item of treeViewList">
        <ng-container *ngIf="item.children && item.children.length > 0">
          <ng-container
            [ngTemplateOutlet]="menutemplate"
            [ngTemplateOutletContext]="{ item: item }"
          >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!item.children || item.children.length === 0">
          <mat-nav-list class="single-link child-links d-flex">
            <a mat-list-item>
              {{ getHeading(item) }} <span>{{ item.name }}</span>
            </a>
            <a
              *ngIf="item.isChannel || item.isRegion || item.isCountry"
              (click)="
                $event.stopPropagation(); item.isEdit = false; openDialog(item)
              "
              title="jj1"
            >
              <mat-icon>add_circle_outline</mat-icon>
            </a>
            <a
              *ngIf="item.isChannel || item.isRegion || item.isCountry"
              (click)="$event.stopPropagation(); openEditDialog(item)"
            >
              <mat-icon>create</mat-icon>
            </a>
            <span
              (click)="$event.stopPropagation(); openConfirmationDialog(item)"
              class="delete-icon"
              role="img"
              aria-hidden="true"
            >
              <i class="icon-bin" style="font-size: 30px"></i
            ></span>
          </mat-nav-list>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #menutemplate let-item="item">
    <ng-container *ngIf="item && item?.children && item?.children.length > 0">
      <mat-expansion-panel>
        <mat-expansion-panel-header
          class="disable_ripple"
          collapsedHeight="50px"
          expandedHeight="50px"
        >
          <mat-panel-title
            >{{ getHeading(item) }} &nbsp; <span>{{ item.name }}</span>
          </mat-panel-title>
          <mat-panel-description>
            <a
              *ngIf="item.isChannel || item.isRegion || item.isCountry"
              (click)="
                $event.stopPropagation(); item.isEdit = false; openDialog(item)
              "
              title="jj"
            >
              <mat-icon>add_circle_outline</mat-icon>
            </a>
            <a
              *ngIf="
                item.isChannel ||
                item.isRegion ||
                item.isCountry ||
                item.isDistributor
              "
              (click)="$event.stopPropagation(); openEditDialog(item)"
            >
              <mat-icon>create</mat-icon>
            </a>
            <span
              (click)="$event.stopPropagation(); openConfirmationDialog(item)"
              class="delete-icon"
              role="img"
              aria-hidden="true"
            >
              <i class="icon-bin" style="font-size: 30px"></i
            ></span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let child of item?.children">
          <ng-container *ngIf="child.children && child.children.length > 0">
            <ng-container
              [ngTemplateOutlet]="menutemplate"
              [ngTemplateOutletContext]="{ item: child }"
            >
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!child.children || child.children.length === 0">
            <mat-nav-list
              [class.distributor]="child.isDistributor"
              [class.region]="child.isRegion"
              [class.country]="child.isCountry"
              class="single-link d-flex"
            >
              <a mat-list-item>
                {{ getHeading(child) }} <span>{{ child.name }}</span>
              </a>
              <a
                *ngIf="child.isChannel || child.isRegion || child.isCountry"
                (click)="
                  $event.stopPropagation();
                  child.isEdit = false;
                  openDialog(child)
                "
              >
                <mat-icon>add_circle_outline</mat-icon>
              </a>
              <a
                *ngIf="
                  child.isChannel ||
                  child.isRegion ||
                  child.isCountry ||
                  child.isDistributor
                "
                (click)="
                  $event.stopPropagation();
                  child.isEdit = true;
                  openEditDialog(child)
                "
              >
                <mat-icon>create</mat-icon>
              </a>
              <span
                (click)="
                  $event.stopPropagation(); openConfirmationDialog(child)
                "
                class="delete-icon"
                role="img"
                aria-hidden="true"
              >
                <i class="icon-bin" style="font-size: 30px"></i
              ></span>
            </mat-nav-list>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
    <ng-container
      *ngIf="!item.children || item.children.length === 0"
      class="product-wrapper"
    >
      <mat-nav-list
        [class.distributor]="item.isDistributor"
        [class.region]="item.isRegion"
        [class.country]="item.isCountry"
        class="single-link d-flex"
      >
        <a mat-list-item>
          {{ getHeading(item) }} <span>{{ item.name }}</span>
        </a>
        <a
          *ngIf="item.isChannel || item.isRegion || item.isCountry"
          (click)="
            $event.stopPropagation(); item.isEdit = false; openDialog(item)
          "
        >
          <mat-icon>add_circle_outline</mat-icon>
        </a>
        <a
          *ngIf="
            item.isChannel ||
            item.isRegion ||
            item.isCountry ||
            item.isDiisributor
          "
          (click)="$event.stopPropagation(); openEditDialog(item)"
        >
          <mat-icon>create</mat-icon>
        </a>
        <span
          (click)="$event.stopPropagation(); openConfirmationDialog(item)"
          class="delete-icon"
          role="img"
          aria-hidden="true"
        >
          <i class="icon-bin" style="font-size: 30px"></i
        ></span>
      </mat-nav-list>
    </ng-container>
  </ng-template>

  <ng-template #channelTemplate let-data>
    <div mat-dialog-content>
      <form class="item-wrapper">
        <div class="form-group">
          <label>Channel Name</label>
          <input
            [(ngModel)]="data.channelName"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
          />
        </div>
        <div class="action-btns text-right">
          <button
            mat-raised-button
            class="btn btn-primary m-left"
            color="primary"
            (click)="onSubmitChannel(data)"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #regionTemplate let-data>
    <div mat-dialog-content>
      <form class="item-wrapper">
        <div class="form-group">
          <label>Region Name</label>
          <input
            [(ngModel)]="data.continentName"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
          />
        </div>
        <div class="action-btns text-right">
          <button
            mat-raised-button
            class="btn btn-primary m-left"
            color="primary"
            (click)="onSubmitContinent(data)"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #countryTemplate let-data>
    <div mat-dialog-content>
      <form class="item-wrapper">
        <div class="form-group">
          <label>Country Name</label>
          <input
            [(ngModel)]="data.countryName"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
          />
        </div>
        <div class="action-btns text-right">
          <button
            mat-raised-button
            class="btn btn-primary m-left"
            color="primary"
            (click)="onSubmitCountry(data)"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #distributorTemplate let-data>
    <div mat-dialog-content>
      <form class="item-wrapper">
        <div class="form-group">
          <label>Distributor Name</label>
          <input
            [(ngModel)]="data.distributorName"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
          />
        </div>
        <div class="form-group">
          <label>Disti Salesforce ID</label>
          <input
            [(ngModel)]="data.distributor_sfid"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
            type="text"
          />
        </div>
        <div class="form-group">
          <label>Disti Lead Time</label>
          <input
            [(ngModel)]="data.leadTimeA"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
            type="number"
          />
        </div>
        <div class="form-group">
          <label>Target WOS</label>
          <input
            [(ngModel)]="data.targetWOS"
            [ngModelOptions]="{ standalone: true }"
            placeholder=""
            type="number"
          />
        </div>
        <div class="action-btns text-right">
          <button
            mat-raised-button
            class="btn btn-primary m-left"
            color="primary"
            (click)="onSubmitDistributor(data)"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
