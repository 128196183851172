import { ColDef } from 'ag-grid-enterprise';
import * as moment from 'moment';
import * as _ from 'lodash';

export const paginationPageSize = 50;
export const cacheBlockSize = 50;
export const rowSelection = 'single';

const dateRenderer = (params) => {
  return `${params.value ? (new Date(params.value)).toLocaleDateString() : ''}`;
}

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  filterParams: { newRowsAction: 'keep' }
};

export const promotionGridColDefs: ColDef[] = [
  {
    headerName: 'New Item Promotion Name',
    field: 'promotionItemName',
    cellRenderer: 'promotionItemEditRenderer',
    minWidth: 250
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    type: 'date',
    cellRenderer: dateRenderer,
    minWidth: 150
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    type: 'date',
    cellRenderer: dateRenderer,
    minWidth: 150
  },
  {
    headerName: 'Product Title Release Date',
    field: 'releaseDate',
    type: 'date',
    cellRenderer: dateRenderer,
    minWidth: 250
  },
  {
    headerName: 'Account',
    field: 'accountName',
    minWidth: 150
  }
];


export const promotionDetailGridColDefs = (isApprovalPage = false, isRetailerTeam = false) => {

  const promotionItemDetailColDefs: ColDef[] = [
    ...isApprovalPage ? [] : [{
      headerName: 'Select',
      field: 'select',
      width: 150,
      suppressSizeToFit: true,
      checkboxSelection: true,
      hide: isApprovalPage
    }],
    ...isApprovalPage ? [{
      headerName: 'Promotion Item Name',
      field: 'promotionItemName',
      rowGroup: isApprovalPage,
      lockVisible: true,
      hide: true,
    }] : [],
    ...isApprovalPage ? [] : [{
      headerName: 'Status',
      field: 'actionName',
      width: 150
    }],
    {
      headerName: 'Notes',
      field: 'notes',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      }
    },
    {
      headerName: 'Internal SKU ID',
      field: 'internalSKUId'
    },
    {
      headerName: 'Item Description',
      field: 'itemDescription'
    },
    {
      headerName: 'Release Date',
      field: 'releaseDate',
      type: 'date',
      cellRenderer: dateRenderer
    },
    {
      headerName: 'Account',
      field: 'accountName'
    },
    {
      headerName: 'Class Code',
      field: 'classCode'
    },
    {
      headerName: 'Class Name',
      field: 'className'
    },
    {
      headerName: 'Subclass Code',
      field: 'subClassCode'
    },
    {
      headerName: 'Subclass Name',
      field: 'subClassName'
    },
    {
      headerName: 'Regular Retail Price',
      field: 'regRetailPrice',
      editable: (params) => {
        if (isRetailerTeam && isApprovalPage && params.data.actionName !== 'Deleted') {
          return true;
        } else {
          return false;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    ...isRetailerTeam ? [{
      headerName: 'Promo Price',
      field: 'promoPrice',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor',
      cellClassRules: {
        'invalid-cell-value': (params) => {
          const promoPrice = _.get(params, 'value', 0);
          const regRetailPrice = _.get(params, 'data.regRetailPrice', 0);
          return promoPrice > regRetailPrice;
        }
      }
    }] : [],
    ...isApprovalPage ? [{
      headerName: 'Fixture',
      field: 'fixtureName'
    }] : [],
    {
      headerName: 'UPC',
      field: 'upc'
    },
    {
      headerName: 'B&M Promotion Week 1 Forecast (Vendor)',
      width: 340,
      field: 'promotionWeek1ForecastVendor',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Dot Com Week 1 Forecast (Vendor)',
      width: 300,
      field: 'dotcomForecastVendor',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'On Ad Week Forecast (Vendor)',
      width: 270,
      field: 'onAdWeekForecastVendor',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'B&M Promotion Week 1 Forecast (Best Buy)',
      width: 340,
      field: 'bAndMPromotionWeek1Forecast',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Dot Com Week 1 Forecast (Best Buy)',
      width: 300,
      field: 'dotComWeek1Forecast',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Total Week 1 Forecast (Best Buy)',
      width: 270,
      field: 'totalWeek1ForecastBestBuy'
    },
    ...isApprovalPage ? [{
      headerName: 'ROQ (Best Buy)',
      field: 'roqBestBuy',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    }] : [],
    ...isApprovalPage ? [{
      headerName: 'Dot Com ROQ (Best Buy)',
      width: 250,
      field: 'roqDotComBestBuy',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam) {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    }] : [],
    {
      headerName: 'Retek Cost',
      field: 'retekCost'
    },
    {
      headerName: 'Base Cost',
      field: 'baseCost',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor',
      cellClassRules: {
        'invalid-cell-value': (params) => {
          const baseCost = _.get(params, 'value', 0);
          const regRetailPrice = _.get(params, 'data.regRetailPrice', 0);
          return baseCost > regRetailPrice;
        }
      }
    },
    {
      headerName: 'Base Cost Effective Date',
      field: 'baseCostEffectiveDate',
      type: 'date',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellRenderer: dateRenderer,
      cellEditor: 'datePicker'
    },
    {
      headerName: 'Margin Credit',
      field: 'marginCredit',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellEditor: 'numericCellEditor'
    },
    {
      headerName: 'Margin Credit Start Date',
      field: 'marginCreditStartDate',
      type: 'date',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellRenderer: dateRenderer,
      cellEditor: 'datePicker'
    },
    {
      headerName: 'Margin Credit End Date',
      field: 'marginCreditEndDate',
      type: 'date',
      editable: (params) => {
        if (params.data.actionName === 'Deleted') {
          return false;
        } else if (!isRetailerTeam && (params.data.actionName === 'Accepted' || params.data.actionName === 'Rejected')) {
          return false;
        } else {
          return true;
        }
      },
      cellRenderer: dateRenderer,
      cellEditor: 'datePicker'
    },
    {
      headerName: 'Accrual %',
      field: 'accrualPercentage'
    },
    ...isRetailerTeam ? [{
      headerName: 'Operating Gross Margin',
      field: 'operatingGrossMargin'
    }] : [],
    ...isRetailerTeam ? [{
      headerName: 'Operating Gross Margin %',
      field: 'operatingGrossMarginPercentage'
    }] : [],
    ...isRetailerTeam ? [{
      headerName: 'Adjusted Gross Margin',
      field: 'adjustedGrossMargin'
    }] : [],
    ...isRetailerTeam ? [{
      headerName: 'Adjusted Gross Margin %',
      field: 'adjustedGrossMarginPercentage'
    }] : [],
    {
      headerName: 'Created By',
      field: 'createdByName'
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      type: 'date',
      cellRenderer: dateRenderer
    }
  ];
  return promotionItemDetailColDefs;
}

export const approvalForecastExportGridColDefs = (isRetailerTeam = false) => {
  const approvalGridColDefs: any = [
    {
      headerName: 'Promotion Item Name',
      field: 'promotionItemName'
    },
    {
      headerName: 'Internal SKU ID',
      field: 'internalSKUId'
    },
    {
      headerName: 'Item Description',
      field: 'itemDescription'
    },
    {
      headerName: 'Release Date',
      field: 'releaseDate'
    },
    {
      headerName: 'Account',
      field: 'accountName'
    },
    {
      headerName: 'UPC',
      field: 'upc'
    }
  ];
  for (let weekIndex = 1; weekIndex <= 8; weekIndex++) {
    approvalGridColDefs.push({
      headerName: `Week${weekIndex}`,
      field: `week${weekIndex}`,
      children: [
        ...isRetailerTeam ? [{
          headerName: 'Promo Price',
          field: `promoPriceWeek${weekIndex}`
        }] : [],
        {
          headerName: 'Margin Credit',
          field: `marginCreditWeek${weekIndex}`
        },
        {
          headerName: 'B&M Promo Week Forecast',
          field: `bAndMPromoForecastWeek${weekIndex}`
        },
        {
          headerName: 'Dotcom Week Forecast',
          field: `dotComForecastWeek${weekIndex}`
        },
        {
          headerName: 'On Ad',
          field: `onAdWeek${weekIndex}`
        }
      ]
    });
  }
  return approvalGridColDefs;
}

export const approvalForecastGridColDefs = (startDate, isRetailerTeam = false) => {
  const approvalGridColDefs: any = [
    {
      headerName: 'Promotion Item Name',
      field: 'PromotionItemName',
      rowGroup: true,
      lockVisible: true,
      hide: true,
    },
    {
      headerName: 'Internal SKU ID',
      headerTooltip: 'Internal SKU ID',
      field: 'InternalSKUId',
      pinned: 'left',
      width: 120
    },
    {
      headerName: 'Item Description',
      headerTooltip: 'Item Description',
      field: 'ItemDescription',
      pinned: 'left',
      width: 145
    },
    {
      headerName: 'Release Date',
      headerTooltip: 'Release Date',
      field: 'ReleaseDate',
      pinned: 'left',
      type: 'date',
      cellRenderer: dateRenderer,
      width: 125
    },
    {
      headerName: 'Account',
      headerTooltip: 'Account',
      field: 'AccountName',
      pinned: 'left',
      width: 95
    },
    {
      headerName: 'UPC',
      headerTooltip: 'UPC',
      field: 'UPC',
      pinned: 'left',
      width: 90
    }
  ];
  for (let weekIndex = 1; weekIndex <= 12; weekIndex++) {
    const weekDate = addWeeks(new Date(startDate), (weekIndex - 1));
    approvalGridColDefs.push({
      headerName: moment(weekDate).format('MM/DD/YYYY'),
      field: `week${weekIndex}`,
      marryChildren: true,
      headerClass: 'ag-column-group-class',
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      children: [
        ...isRetailerTeam ? [{
          headerName: 'Promo Price',
          field: `PromoPrice${moment(weekDate).format('YYYY-MM-DD')}`,
          editable: (params) => {
            if (params.data.ActionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor',
          headerClass: 'ag-column-group-first-header',
          cellStyle: { borderLeft: '2px solid lightgray' }
        }] : [],
        {
          headerName: 'Margin Credit',
          field: `MarginCredit${moment(weekDate).format('YYYY-MM-DD')}`,
          editable: (params) => {
            if (params.data.ActionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor',
          cellStyle: { borderLeft: isRetailerTeam ? 'none' : '2px solid lightgray' },
          headerClass: isRetailerTeam ? '' : 'ag-column-group-first-header',
        },
        {
          headerName: 'B&M Promo Week Forecast',
          width: 240,
          field: `BAndMPromoForecast${moment(weekDate).format('YYYY-MM-DD')}`,
          editable: (params) => {
            if (params.data.ActionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor'
        },
        {
          headerName: 'Dotcom Week Forecast',
          width: 220,
          field: `DotComForecast${moment(weekDate).format('YYYY-MM-DD')}`,
          editable: (params) => {
            if (params.data.ActionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'numericCellEditor'
        },
        {
          headerName: 'On Ad',
          field: `OnAd${moment(weekDate).format('YYYY-MM-DD')}`,
          editable: (params) => {
            if (params.data.ActionName === 'Deleted') {
              return false;
            } else if (!isRetailerTeam) {
              return false;
            } else {
              return true;
            }
          },
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: extractValues(yesNoMapping)
          },
          filterParams: {
            valueFormatter: function (params) {
              return lookupValue(yesNoMapping, params.value);
            },
          },
          valueFormatter: params => {
            if (params.value == null) return null;
            else return lookupValue(yesNoMapping, params.value);
          }
        }
      ]
    });
  }
  return approvalGridColDefs;
}

const yesNoMapping = {
  0: 'No',
  1: 'Yes'
};

const lookupValue = (mappings, key) => {
  return mappings[Number(key)];
}

const extractValues = (mappings) => {
  return (Object.keys(mappings)).map(Number);
}

const addWeeks = (dt, n) => {
  return new Date(dt.setDate(dt.getDate() + (n * 7)));
}
