import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataModuleService } from 'src/services/data-module.service';
import { conditionsDropdownData } from '../../constants/dropdowns';
import * as _ from 'lodash';

@Component({
  selector: 'app-manage-exceptions',
  templateUrl: './manage-exceptions.component.html',
  styleUrls: ['./manage-exceptions.component.scss']
})
export class ManageExceptionsComponent implements OnInit {

  columns = [];
  selectedColumn = '';
  selectedFormArray: FormArray;
  exceptionsForm: FormGroup;

  conditionsDropdownData = conditionsDropdownData;

  constructor(public dialogRef: MatDialogRef<ManageExceptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private toastrService: ToastrService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private dataModuleService: DataModuleService) { }

  ngOnInit(): void {
    this.columns = this.data.columns;
    this.selectedColumn = this.columns[0].field;
    this.initializeForm();
    this.fetchExceptionRules();
  }

  fetchExceptionRules() {
    this.spinner.show();
    this.dataModuleService.fetchExceptionRules(this.data.fileName, this.data.userId, this.data.versionNo)
      .subscribe((res: any) => {
        if (res.payload && res.payload.length) {
          const colKeys = [];
          this.columns.forEach(element => {
            colKeys.push(element.field)
          });
          res.payload[0].rule && res.payload[0].rule.forEach(element => {
            if (colKeys.indexOf(element.column) > -1) {
              const selectedFormArrayTemp = (this.exceptionsForm.get(element.column).get('rules')) as FormArray;
              element.rules.forEach(r => {
                selectedFormArrayTemp.push(this.addNewException());
                selectedFormArrayTemp.controls[selectedFormArrayTemp.length - 1].patchValue(r);
              });
              selectedFormArrayTemp.controls.forEach((fg: FormGroup) => {
                if (fg.get('gate').value) {
                  fg.get('filter_right').enable({ emitEvent: false });
                  if (fg.get('filter_right').value &&
                    [this.conditionsDropdownData[this.conditionsDropdownData.length - 1].id, this.conditionsDropdownData[this.conditionsDropdownData.length - 2].id].indexOf(fg.get('filter_right').value) == -1) {
                    fg.get('value_right').enable({ emitEvent: false });
                  } else {
                    fg.get('value_right').disable({ emitEvent: false });
                  }
                }
                if (fg.get('filter_left').value &&
                  [this.conditionsDropdownData[this.conditionsDropdownData.length - 1].id, this.conditionsDropdownData[this.conditionsDropdownData.length - 2].id].indexOf(fg.get('filter_left').value) == -1) {
                  fg.get('value_left').enable({ emitEvent: false });
                } else {
                  fg.get('value_left').disable({ emitEvent: false });
                }
              });
            }
          });
        }
        this.spinner.hide();
      },
        err => {
          this.spinner.hide();
        });

  }

  initializeForm() {
    this.exceptionsForm = this.formBuilder.group({});
    this.columns.forEach(element => {
      this.exceptionsForm.addControl(element.field, new FormGroup(
        {
          rule_type: new FormControl('exception'),
          rules: new FormArray([])
        }
      ));
    });
    this.selectedColumnChanged();
  }

  selectedColumnChanged() {
    this.selectedFormArray = (this.exceptionsForm.get(this.selectedColumn).get('rules')) as FormArray;
    this.selectedFormArray.valueChanges
      .subscribe((res: any) => {
       this.enableDisableFields();
      });
  }

  enableDisableFields() {
    for (let i = 0; i < this.selectedFormArray.controls.length; i++) {
      if (this.selectedFormArray.controls[i].enabled) {
        const element = this.selectedFormArray.controls[i].value;
        if (element.gate) {
          this.selectedFormArray.controls[i].get('filter_right').enable({ emitEvent: false });
          if (this.selectedFormArray.controls[i].get('filter_right').value &&
            [this.conditionsDropdownData[this.conditionsDropdownData.length - 1].id, this.conditionsDropdownData[this.conditionsDropdownData.length - 2].id].indexOf(this.selectedFormArray.controls[i].get('filter_right').value) == -1) {
            this.selectedFormArray.controls[i].get('value_right').enable({ emitEvent: false });
          } else {
            this.selectedFormArray.controls[i].get('value_right').disable({ emitEvent: false });
          }
        }
        if (this.selectedFormArray.controls[i].get('filter_left').value &&
          [this.conditionsDropdownData[this.conditionsDropdownData.length - 1].id, this.conditionsDropdownData[this.conditionsDropdownData.length - 2].id].indexOf(this.selectedFormArray.controls[i].get('filter_left').value) == -1) {
          this.selectedFormArray.controls[i].get('value_left').enable({ emitEvent: false });
        } else {
          this.selectedFormArray.controls[i].get('value_left').disable({ emitEvent: false });
        }
      }
    }
  }

  addNewExceptionHandler() {
    this.selectedFormArray.push(this.addNewException());
  }

  removeException(index: number) {
    this.selectedFormArray.removeAt(index);
  }

  addNewException(): FormGroup {
    return new FormGroup({
      filter_left: new FormControl(this.conditionsDropdownData[0].id),
      value_left: new FormControl(null, Validators.required),
      gate: new FormControl(null),
      filter_right: new FormControl({ value: null, disabled: true }, Validators.required),
      value_right: new FormControl({ value: null, disabled: true }, Validators.required),
      is_exception: new FormControl(true)
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    const exceptionsKeys = Object.keys(this.exceptionsForm.value);
    const exceptionsValues = Object.values(this.exceptionsForm.value);
    const exceptionsArray = [];

    for(let index = 0; index <exceptionsKeys.length; index++) {
      const excObj = exceptionsValues[index] as any;
      excObj.column = exceptionsKeys[index];
      exceptionsArray.push(excObj);
      for(let i= excObj.rules.length-1; i>0; i--) {
        for (let j=0; j<i; j++) {
          const rulesAreSame = _.isEqual(excObj.rules[i], excObj.rules[j]);
          if (rulesAreSame) {
            this.toastrService.warning('Duplicate rules detected in column: ' +  exceptionsKeys[index]);
            return;
          }
        }
      }
    }

    const obj = {
      user_id: this.data.userId,
      file_name: this.data.fileName,
      rule_type: "exception",
      version_no: this.data.versionNo,
      rule: exceptionsArray
    };
    this.spinner.show();
    this.dataModuleService.saveExceptionRules(obj)
      .subscribe((res: any) => {
        this.toastrService.success('Exceptions saved successfully.')
        this.spinner.hide();
        this.dialogRef.close(true);
      },
        err => {
          this.toastrService.error('Failed to save exceptions.');
          this.spinner.hide();
        });
  }

}
