import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserLoginHistoryComponent } from '../user-login-history/user-login-history.component';
import { User } from 'src/models/User';
import { AuthenticationService } from 'src/services/Authentication-Services/authentication-services';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ConfirmationResetPasswordDialogComponent } from '../../confirmation-reset-password-dialog/confirmation-reset-password-dialog.component';

@Component({
  selector: 'app-create-update-user',
  templateUrl: './create-update-user.component.html',
  styleUrls: ['./create-update-user.component.css']
})
export class CreateUpdateUserComponent implements OnInit {

  public registerForm: FormGroup;
  public isPhoneNumberValid = true;
  public submitted = false;
  public phoneNumberRegex = /^([0-9]){9}$/;
  public emailMaxChars = 50;
  public id: number;
  public isEdit = false;
  public loading = false;
  public selectedUser: User;
  public roleList = [];
  public roleListUI = [];
  public isUserLocked: boolean
  files = [];
  @Input() data;
  teamList: any;
  teamListUI: any;
  minPasswordExpireDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public userService: UsersService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    public confirmationDialogRef: MatDialogRef<ConfirmationResetPasswordDialogComponent>,
    public authenticationService: AuthenticationService,
    public config: ConfigurationService
  ) {
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm; }
  ngOnInit() {
    this.getParams();
    this.initializeDropDowns();
    this.initializeForm();
    this.data = this.data ? JSON.parse(this.data) : null;
    if (this.data && this.data.rowData && this.data.rowData.userId) {
      this.id = this.data.rowData.userId;
      this.isEdit = true;
      this.getSlectedUser(this.id.toString());
    }
  }
  public getParams() {
    this.route.queryParams.subscribe(params => {
      if (params && params.id) {
        this.id = Number(atob(params.id));
        if (this.id) {
          this.isEdit = true;
          this.getSlectedUser(this.id.toString());
        }
      }
    });
  }
  public getSlectedUser = (id: string) => {
    this.spinner.show()
    this.userService.GetUserById(id).subscribe(res => {
      if (res) {
        this.selectedUser = res;
        this.isUserLocked = this.selectedUser.isUserLocked
        this.spinner.hide()
        this.populateForm(this.selectedUser);
      }

    }, error => {
      this.spinner.hide()
    });
  }
  public populateForm = (user: User) => {
    Object.keys(this.f.controls).forEach(key => {
      if (key) {
        if (user[key] && key.indexOf('Date') > -1) {
          this.f.controls[key].setValue(new Date(user[key]));
        }
        else {
          this.f.controls[key].setValue(user[key]);
        }
      }
    });
  }
  public initializeDropDowns = async () => {

    this.userService.GetUserRolesList().subscribe((res: any) => {
      this.roleList = res;
      this.roleListUI = JSON.parse(JSON.stringify(this.roleList));
    });
    this.userService.getTeams().subscribe((res: any) => {
      this.teamList = res;
      this.teamListUI = JSON.parse(JSON.stringify(this.teamList));
    });
  }
  initializeForm() {
    this.registerForm = this.formBuilder.group({
      userTypeId: [1, Validators.required],
      roles: [null, Validators.required],
      teams: [null],
      teamsList: [null],
      roleList: [null],
      firstName: ['', [Validators.required, Validators.maxLength(60)]],
      lastName: ['', [Validators.required, Validators.maxLength(60)]],
      email: ['', [Validators.required, Validators.email]],
      passwordExpireDate: [null],
      phoneNumber: [''],
      documentId: [null],
      documentPath: [null],
      identificationNumber: [''],
      createdByName: ['', []],
      modifiedByName: ['', []],
      modifiedDate: ['', []],
      loginDate: ['', []],
      active: [true],
      userId: [0],
    });

  }
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.roleId === c2.roleId : c1 === c2;
  }
  compareTeamFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.teamId === c2.teamId : c1 === c2;
  }
  Cancel_Click() {
    // this.router.navigate(['/Users']);
  }
  onPhoneNumberChanged = () => {
    const value = this.registerForm.controls['phoneNumber'].value;
    this.isPhoneNumberValid = value.match(this.phoneNumberRegex);
  }
  onSubmit() {
    this.submitted = true;
    if (this.f.valid) {
      this.loading = true;
      const model = {
        ...this.f.value,
      };
      this.spinner.show();
      if (this.isEdit) {
        this.userService.UpdateUser(model).subscribe(res => {
          this.spinner.hide();
          this.toastr.success('', 'User updated successfully');
          // this.router.navigate(['/Users']);
          this.loading = false;
          this.submitted = false;
        }, error => {
          this.toastr.error('Error', 'Something went wrong user not updated successfully');
          this.spinner.hide();
          this.loading = false;
        });
      } else {
        this.userService.CreateUser(model).subscribe(res => {
          this.spinner.hide();
          this.toastr.success('', 'User created successfully');
          // this.router.navigate(['/Users']);
          this.loading = false;
          this.submitted = false;
        }, error => {
          this.toastr.error('Error', 'Something went wrong user not created successfully');
          this.spinner.hide();
          this.loading = false;
        });
      }
    }
  }
  openForgetPasswordModal() {
    this.confirmationDialogRef = this.dialog.open(ConfirmationResetPasswordDialogComponent, {
      width: '500px',
      data: { email: this.registerForm.controls['email'].value }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authenticationService.forgotPassword(result.email)
          .subscribe(
            data => {
              this.spinner.hide();
              this.toastr.success('', "We've sent a password reset link to your email address.");
            },
            error => {
              this.spinner.hide();
              this.toastr.error('Error', 'Email not sent please enter valid email');
            }
          );
      }
    });

  }
  openLoginhistoryModal() {
    this.dialog.open(UserLoginHistoryComponent, {
      width: '700px',
      data: { userLoginActivity: this.selectedUser.userLoginActivity }
    });
  }
  lockUnlockUser = () => {
    const model = {
      ...this.f.value,
      isUserLocked: !this.isUserLocked
    };
    this.spinner.show();
    this.userService.UpdateUserLock(model).subscribe(res => {
      this.spinner.hide();
      this.toastr.success('Admin Settings', `User ${this.isUserLocked ? 'Unlocked' : 'Locked'} successfully.`)
      this.getSlectedUser(this.id.toString());
    }, error => {
      this.spinner.hide();
    })
  }
  public deleteImage = () => {
    this.selectedUser.documentPath = null;
    this.selectedUser.documentId = null;
    this.f.controls.documentPath.setValue(null)
    this.f.controls.documentId.setValue(null)
  }
  public openImageDialog = (template: TemplateRef<any>) => {
    this.dialogRef = this.dialog.open(template, { width: '500px' });
  }
  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
    this.files.forEach(file => {
      this.getFile(file)
    })
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  public uploadFile = (file, fileName) => {
    this.spinner.show();
    this.config.DocumentAdd({ file, fileName }).subscribe(res => {
      this.registerForm.controls.documentId.setValue(res.documentId);
      this.registerForm.controls.documentPath.setValue(res.documentPath);
      this.dialogRef.close();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
  addTeam() {
    if (this.f.controls.teamsList.value) {
      const selectedUser = this.f.controls.teamsList.value;
      const value = this.f.controls.teams.value ? this.f.controls.teams.value : [];
      value.push(selectedUser);
      const ids = value.map(el => el.teamName);
      this.f.controls.teams.setValue(value);
      this.teamList = this.teamListUI.filter(row => !ids.includes(row.teamName));
    }

  }
  addRole() {
    if (this.f.controls.roleList.value) {
      const selectedUser = this.f.controls.roleList.value;
      const value = this.f.controls.roles.value ? this.f.controls.roles.value : [];
      value.push(selectedUser);
      const ids = value.map(el => el.roleName);
      this.f.controls.roles.setValue(value);
      this.roleList = this.roleListUI.filter(row => !ids.includes(row.roleName));
    }

  }
  removeTeam(team) {
    let value = this.f.controls.teams.value;
    value = value.filter(u => u.teamName !== team.teamName);
    this.f.controls.teams.setValue(value);
  }
  removeRole(team) {
    let value = this.f.controls.roles.value;
    value = value.filter(u => u.roleName !== team.roleName);
    this.f.controls.roles.setValue(value);
  }
  private getFile(file: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadFile((reader.result as any).split(',')[1], file.name);
    };
    return reader;
  }
}
