<div class="dialog-close-btn">
  <button mat-icon-button (click)="onNoClick()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>

<fusioncharts *ngIf="loadChartData"
    [chartConfig]=chartConfig
    [dataSource]=dataSource >
</fusioncharts>