import { USER_ID } from '../../common/keys';

export class ItemUPC {
  itemManagerUpcId!: number;
  itemManagerId!: number;
  upcCode!: string;
  [key: string]: any;
  constructor(itemUPC?: ItemUPC) {
    if (itemUPC) {
      this.itemManagerUpcId = itemUPC.itemManagerUpcId || 0;
      this.itemManagerId = itemUPC.itemManagerId || 0;
      this.upcCode = itemUPC.upcCode;
    }
  }
}
export class Item {
  itemManagerId!: number;
  documentId!: number;
  documentPath!: string;
  itemManagerUPC!: ItemUPC[];
  itemModelNumber!: number;
  brandId!: number;
  productGroupId!: number;
  productFamilyId!: number;
  parentProductId!: number;
  description!: string;
  productStatus: string;
  productCategory: string;
  sku: string;
  msrp!: number;
  cost!: number;
  color!: string;
  capacity!: number;
  screenSize!: number;
  palletQuantity!: number;
  casePackQuantity!: number;
  leadTimeI!: number;
  createdById!: number;
  createdByName!: string;
  createdDate!: Date;
  modifiedById!: number;
  modifiedByName!: string;
  modifiedDate!: Date;
  active!: boolean;
  searchText!: string;
  currentUserId!: number;
  accType: String;
  editPermission;
  [key: string]: any;
  constructor(item?: Item) {
    if (item) {
      this.itemManagerId = item.itemManagerId || 0;
      this.documentId = item.documentId || null;
      this.documentPath = item.documentPath || '';
      this.itemManagerUPC = item.itemManagerUPC || new Array(new ItemUPC());
      this.itemModelNumber = item.itemModelNumber || 0;
      this.brandId = item.brandId || null;
      this.productGroupId = item.productGroupId || null;
      this.productFamilyId = item.productFamilyId || null;
      this.parentProductId = item.parentProductId || null;
      this.description = item.description || '';
      this.msrp = item.msrp || 0;
      this.cost = item.cost || 0;
      this.color = item.color || null;
      this.capacity = item.capacity || null;
      this.screenSize = item.screenSize || 0;
      this.palletQuantity = item.palletQuantity || 0;
      this.casePackQuantity = item.casePackQuantity || 0;
      this.leadTimeI = item.leadTimeI || 0;
      this.currentUserId = JSON.parse(localStorage.getItem(USER_ID));
      this.productStatus = '';
      this.productCategory = '';
      this.sku = '';
      this.accType = '';
    }
  }
}
export class OrderList {
  batchId!: number;
  orderLineId!: number;
  orderStatusName!: string;
  orderDate!: Date;
  deliveryDate!: Date;
  orderSource!: string;
  purchaseOrder!: string;
  itemCode!: number;
  retailerSKU!: string;
  ean!: string;
  upc!: string;
  description!: string;
  releaseDate!: Date;
  locationCode!: string;
  shipTO!: string;
  state!: string;
  x_DOC_DC!: string;
  orderQty!: number;
  adjustedOrdQTY!: number;
  dos!: string;
  min!: number;
  max!: number;
  onHand!: string;
  inTransit!: string;
  onOrder!: string;
  effectiveInventory!: string;
  forecastWK1!: number;
  forecastWK2!: number;
  forecastWK3!: number;
  forecastWK4!: number;
  salesCW!: string;
  salesCW_1!: string;
  createdById!: number;
  createdDate!: Date;
  modifiedById!: number;
  modifiedDate!: Date;
  active!: boolean;
  searchText!: string;
  currentUserId!: number;
  accType: String;
  editPermission;
  upcCode!: string;
  [key: string]: any;
  constructor(orderList?: OrderList) {
    if (orderList) {
      this.batchId = orderList.batchId || 0;
      this.orderLineId = orderList.orderLineId || 0;
      this.orderStatusName = orderList.orderStatusName || '';
      this.orderDate = orderList.orderDate || null;
      this.deliveryDate = orderList.deliveryDate || null;
      this.orderSource = orderList.orderSource || '';
      this.purchaseOrder = orderList.purchaseOrder || '';
      this.itemCode = orderList.itemCode || 0;
      this.retailerSKU = orderList.retailerSKU || '';
      this.ean = orderList.ean || '';
      this.upc = orderList.upc || '';
      this.description = orderList.description || '';
      this.locationCode = orderList.locationCode || '';
      this.shipTO = orderList.shipTO || '';
      this.state = orderList.state || '';
      this.x_DOC_DC = orderList.x_DOC_DC || '';
      this.adjustedOrdQTY = orderList.adjustedOrdQTY || 0;
      this.dos = orderList.dos || '';
      this.min = orderList.min || 0;
      this.max = orderList.max || 0;
      this.onHand = orderList.onHand || '';
      this.inTransit = orderList.inTransit || '';
      this.onOrder = orderList.onOrder || '';
      this.effectiveInventory = orderList.effectiveInventory || '';
      this.forecastWK1 = orderList.forecastWK1 || 0;
      this.forecastWK2 = orderList.forecastWK2 || 0;
      this.forecastWK3 = orderList.forecastWK3 || 0;
      this.forecastWK4 = orderList.forecastWK4 || 0;
      this.salesCW = orderList.salesCW || '';
      this.salesCW_1 = orderList.salesCW_1 || '';
      this.createdById = orderList.createdById || 0;
      this.createdDate = orderList.createdDate || null;
      this.upcCode = orderList.upcCode || '';
      this.modifiedById = orderList.modifiedById || 0;
      this.modifiedDate = orderList.modifiedDate || null;
      this.active = orderList.active || true;
      this.currentUserId = JSON.parse(localStorage.getItem(USER_ID));
    }
  }
}
export class ItemAccount {
  itemAccountId!: number;
  accountName!: string;
  countryName!: string;
  countryId!: number;
  regionName!: string;
  regionId!: number;
  accountTypeName!: string;
  accountTypeId!: number;
  channelId!: number;
  casepackQuant: string;
  palletQuan: string;
  editPermission;
  constructor(itemAccount?: ItemAccount) {
    if (itemAccount) {
      this.itemAccountId = itemAccount.itemAccountId || 0;
      this.accountName = itemAccount.accountName || '';
      this.countryName = itemAccount.countryName || '';
      this.countryId = itemAccount.countryId || 0;
      this.regionName = itemAccount.regionName || '';
      this.regionId = itemAccount.regionId || 0;
      this.channelId = itemAccount.channelId || 0;
      this.accountTypeName = itemAccount.accountTypeName || '';
      this.accountTypeId = itemAccount.accountTypeId || 0;
    } else {
      this.itemAccountId = 0;
      this.accountName = '';
      this.countryName = '';
      this.countryId = 0;
      this.channelId = 0;
      this.regionName = '';
      this.regionId = 0;
      this.accountTypeName = '';
      this.accountTypeId = 0;
    }
  }
}
export class AccountSetting {
  accountSettingId!: number;
  itemManagerId!: number;
  channelId!: number;
  channelName!: string;
  regionId!: number;
  regionName!: string;
  countryId!: number;
  countryName!: string;
  accountId!: number;
  accountName!: string;
  foreCastStartDate!: Date;
  foreCastEndDate!: Date;
  lastBidTime!: Date;
  targetWOS!: number;
  leadTimeA!: number;
  orderFrequencyId!: number;
  customerId!: number;
  roundToCasepacks!: boolean;
  roundToPallets!: boolean;
  createdById!: number;
  createdByName!: string;
  createdDate!: Date;
  modifiedById!: number;
  modifiedByName!: string;
  modifiedDate!: Date;
  active!: boolean;
  searchText!: string;
  currentUserId!: number;
  [key: string]: any;
  constructor(account?: AccountSetting) {
    if (account) {
      this.accountSettingId = account.accountSettingId || 0;
      this.itemManagerId = account.itemManagerId || null;
      this.channelId = account.channelId || null;
      this.channelName = account.channelName || '';
      this.regionId = account.regionId || null;
      this.regionName = account.regionName || '';
      this.countryId = account.countryId || null;
      this.countryName = account.countryName || '';
      this.accountId = account.accountId || null;
      this.accountName = account.accountName || '';
      this.foreCastStartDate = account.foreCastStartDate || null;
      this.foreCastEndDate = account.foreCastEndDate || null;
      this.lastBidTime = account.lastBidTime || null;
      this.targetWOS = account.targetWOS || null;
      this.leadTimeA = account.leadTimeA || null;
      this.orderFrequencyId = account.orderFrequencyId || null;
      this.customerId = account.customerId || null;
      this.roundToCasepacks = account.roundToCasepacks || false;
      this.roundToPallets = account.roundToPallets || false;
    }
  }
}
export class ForecastSetting {
  launchCurveId: number;
  seasonalityIndexId: number;
  seasonalityPatternAccountId: number;
  seasonalityPatternProductId: number;
  nipActive: boolean;
}
