export class ChannelMessaging {
  public channelId: number;
  public channelName: string;
  public createdDate: Date;
  public createdById: number;
  public active: boolean;
  public modifiedDate: Date;
  public modifiedById: number;
  public messageCount: number;
  public UsersInChannel?: UsersInChannel[];

  constructor() {
    this.channelId = 0;
    this.channelName = '';
    this.createdDate = null;
    this.createdById = 0;
    this.active = true;
    this.modifiedDate = null;
    this.modifiedById = 0;
    this.messageCount=0;
    this.UsersInChannel = [];
  }
}

export class UsersInChannel {
  public usersInChannelId: number;
  public channelId: number;
  public userId: number;
  public createdDate: Date;
  public createdbyId: 0;
  public modifiedById: 0;
  public modifiedDate: Date;
  public active: boolean;
  public userFullName: string;
  public delete: boolean;
}

export class MessageModel {
  public channelMessageId: number;
  public message: string;
  public channelId: number;
  public parentMessageId: number;
  public createdById: number;
  public createdDate: '';
  public delete: boolean;
  public active: boolean;
  public modifiedById: number;
  public modifiedDate: '';
  public deletedDate: '';
  public deletedById: number;
  public isRead: boolean;
  constructor() {
    this.channelMessageId = 0;
    this.message = '';
    this.channelId = 0;
    this.parentMessageId = 0;
    this.createdById = 0;
    this.createdDate = '';
    this.delete = false;
    this.active = false;
    this.modifiedById = 0;
    this.modifiedDate = '';
    this.deletedDate = '';
    this.deletedById = 0;
    this.isRead = false;
  }
}
