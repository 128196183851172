<h1 mat-dialog-title>{{ !data.isEdit ? "Add Finance" : "Update Finance" }}</h1>
<div mat-dialog-content>
  <mat-form-field class="example-full-width">
    <input [(ngModel)]="data.finance.customerName" matInput placeholder="Enter Customer Name" />
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <input [(ngModel)]="data.finance.effectiveStartDate" matInput [matDatepicker]="picker2"
      placeholder="Effective Start Date" />
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <input [(ngModel)]="data.finance.effectiveEndDate" matInput [matDatepicker]="picker1"
      placeholder="Effective End Date" />
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="data.finance.accrual" type="number" matInput placeholder="Accrual" />
  </mat-form-field>
</div>
<div class="action-panel text-right">
  <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="saveAndClose()">
    Save
  </button>
</div>
