<div class="d-flex justify-content-between m-3">
    <h5>{{graphTitle}}</h5>
    <div>
        <button mat-raised-button color="accent" class="ml-2" (click)="downloadAsJPG()">Download Chart
        </button>
    </div>
</div>
<div class="d-flex w-100 align-items-center justify-content-center">
    <div style="height: 70vh;" class="d-flex align-items-center justify-content-center w-75" #outputGraphDiv>
    </div>
</div>

<hr class="theme-hr" />

<div class="d-flex m-3 align-items-center">
    <h5 class="mb-3 mr-3">Spider Plot</h5>
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Variables</mat-label>
            <mat-select multiple (selectionChange)="variablesSelectionChanged()" [(ngModel)]="spiderVariables">
                <mat-option [disabled]="spiderVariables.length >= 12 && !spiderVariables.includes(item.id)"
                    *ngFor="let item of metrices" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="d-flex w-100 align-items-center justify-content-center">
    <div class="d-flex align-items-center justify-content-center w-75" #radarChart>
    </div>
</div>

<hr class="theme-hr" />

<cluster-membership-summary *ngIf="fileName" (summaryStatsMetricVariables)="summaryStatsMetricVariables=$event"
    [versionNo]="versionNo"
    [summaryStats]="clusterStatistics" [clusteringLabels]="clusteringLabels" [fileName]="fileName" [metrices]="metrices"
    [perClusterCount]="perClusterCount" [isOutputTab]="true" [isAdjusted]="isAdjusted"></cluster-membership-summary>

<cluster-column *ngIf="clusteringLabels.length" (adjustmentSaved)="adjustmentSaved($event)" [versionNo]="versionNo"
    [objectToCluster]="objectToCluster" [fileName]="fileName" [clusteringLabels]="clusteringLabels" [isOutputTab]="true"
    [isAdjusted]="isAdjusted" (disableSaveRun)="disableSaveRun($event)"></cluster-column>

<div class="d-flex justify-content-end p-2">
    <button mat-raised-button color="accent" [disabled]="disableSaveRunCheck" (click)="saveRun()">SAVE RUN
    </button>
</div>
