<div class="row product-grid-filter-row">
  <div class="product-filter-field">
    <mat-form-field appearance="fill">
      <input matInput type="search" (keydown.enter)="emitFilterEvent()" placeholder="{{ sourcePage === 'Product' ? 'Product Title Name' : 'Item Description' }}" [(ngModel)]="productTitleName">
      <mat-icon class="search-action-icon" (click)="emitFilterEvent()" matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="product-filter-field">
    <mat-form-field appearance="fill">
      <input matInput placeholder="Release From Date" (dateChange)="emitFilterEvent()" [matDatepicker]="fromDate" [formControl]="releaseFromDate">
      <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
      <mat-datepicker #fromDate></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="product-filter-field">
    <mat-form-field appearance="fill">
      <input matInput placeholder="Release To Date" (dateChange)="emitFilterEvent()" [matDatepicker]="toDate" [formControl]="releaseToDate">
      <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
      <mat-datepicker #toDate></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="product-filter-field">
    <mat-form-field appearance="fill" >
      <mat-select multiple placeholder="Account" [disabled]="disabledAccountSelection" [(ngModel)]="accountIds" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let account of accountList"  [value]="account.accountId">{{account.accountName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="product-filter-clear-btn">
    <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
  </div>
</div>