import { OnInit, AfterViewInit, Component, OnDestroy, Input, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TreeviewItem } from 'ngx-treeview';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  CreateCalendarList,
  CreateLocationTreeList,
  CreatePartnerTreeList,
  CreateSideNavTreeList
} from 'src/modules/empower-module/helper';
import { EmpowerGamingService } from 'src/services/empower-services/empower-gaming.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { EmpowerService } from 'src/services/empower-services/empower.service';


@Component({
  selector: 'empower-xbox-dashboard',
  templateUrl: './empower-xbox-dashboard.component.html',
  styleUrls: ['./empower-xbox-dashboard.component.scss']
})
export class EmpowerXboxDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data?: any;

  @ViewChild('locationTree', { static: true }) locationTree: any;
  @ViewChild('partnerTree', { static: true }) partnerTree: any;
  @ViewChild('calendarTree', { static: true }) calendarTree: any;
  @ViewChild('pagesTree', { static: true }) pagesTree: any;

  config = {
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    height: 220,
    maxHeight: 220
  };

  geoLocationList: any = [];
  rmiPartnerList: any = [];
  dateLookupList: any = [];

  pagesList = [];

  locationTreeView: TreeviewItem[] = [];
  partnerTreeView: TreeviewItem[] = [];
  timeframeTreeView: TreeviewItem[] = [];
  sideNavMenuTreeView: TreeviewItem[] = [];

  mappedLocations: any = [];
  mappedPartners: any = [];
  mappedDates: any = [];

  selectedPages: any = [];
  selectedLocation: any = [];
  selectedPartner: any = [];
  selectedDates: any = [];

  _unsubscribeAll = new Subject();

  exportType = 'pdf';

  constructor(
    public empowerGamingService: EmpowerGamingService, private empowerExportService: EmpowerExportService,
    private empowerService: EmpowerService,
    private spinner: NgxSpinnerService,
    private toastr: NgxToasterService) {
  }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);
    }
  }

  ngAfterViewInit(): void {
    this.initLookupData();
  }

  initLookupData() {
    this.getDashboardState();
    this.getEmpoweredDateLookup();
    this.getEmpoweredGeoLookup();
    this.getEmpoweredSideNavList();
    this.getDIMPartnerList();
  }

  getDashboardState() {
    const modernLifeDashboardState = this.empowerGamingService.getDashboardState();
    this.mappedLocations = modernLifeDashboardState.countryName;
    this.mappedPartners = modernLifeDashboardState.consumerTopParent;
    this.mappedDates = modernLifeDashboardState.calendarIds;
  }

  getEmpoweredSideNavList() {
    this.pagesList = this.empowerGamingService.xboxSideNavList.value;
    this.sideNavMenuTreeView = CreateSideNavTreeList(this.pagesList);
  }

  getEmpoweredDateLookup() {
    this.dateLookupList = this.empowerService.timeframeTreeViewList.value;
    this.dateLookupList.length && (this.timeframeTreeView = CreateCalendarList(this.dateLookupList, this.mappedDates));
    this.dateLookupList.length == 0 && this.empowerService.GetEmpoweredDateLookup()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(dateLookupList => {
        this.dateLookupList = dateLookupList;
        this.empowerService.setTimeframeTreeViewList(this.dateLookupList);
        this.timeframeTreeView = CreateCalendarList(this.dateLookupList, this.mappedDates);
      });
  }

  getEmpoweredGeoLookup() {
    this.geoLocationList = this.empowerService.locationTreeList.value;
    this.geoLocationList.length && (this.locationTreeView = CreateLocationTreeList(this.geoLocationList, this.mappedLocations));
    this.geoLocationList.length == 0 && this.empowerService.GetEmpoweredGeoLookup()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(geoLocationList => {
        this.geoLocationList = geoLocationList;
        this.empowerService.setLocationTreeList(this.geoLocationList);
        this.locationTreeView = CreateLocationTreeList(this.geoLocationList, this.mappedLocations);
      });
  }

  getDIMPartnerList() {
    this.rmiPartnerList = this.empowerService.partnerTreeViewList.value;
    this.rmiPartnerList.length && (this.filterDIMPartner());
    this.rmiPartnerList.length == 0 && this.empowerService.GetDIMPartnerList().subscribe(rmiPartnerList => {
      this.rmiPartnerList = rmiPartnerList;
      this.empowerService.setPartnerTreeViewList(this.rmiPartnerList);
      this.filterDIMPartner();
    });
  }

  filterDIMPartner() {
    this.selectedPartner = [];
    const rmiPartnerList = _.filter(this.rmiPartnerList, a => this.selectedLocation.length ? this.selectedLocation.includes(a.countryName) : true);
    this.partnerTreeView = CreatePartnerTreeList(rmiPartnerList, this.mappedPartners);
  }

  locationChange(event) {
    this.selectedLocation = event;
    event.length > 1 && this.toastr.warning('Warning', 'Please select only one location.');
    this.filterDIMPartner();
    this.saveDashboardState();
  }

  partnerChange(event) {
    this.selectedPartner = event;
    event.length > 1 && this.toastr.warning('Warning', 'Please select only one partner.');
    this.saveDashboardState();
  }

  timelineChange(event) {
    this.selectedDates = event;
    this.saveDashboardState();
  }

  sideNavMenuChange(event) {
    this.selectedPages = event;
  }

  applyPageFilters() {
    this.pagesList.forEach(page => {
      if (this.selectedPages.includes(page.reportPageId) || !this.selectedPages.length) {
        page.active = true;
      } else {
        page.active = false;
      }
    });
    this.empowerGamingService.xboxSetSideNavList(this.pagesList);
  }

  clearPageState() {
    this.empowerGamingService.clearPagesState();
  }

  clearFilters() {
    this.clearLocationTree();
    this.clearPartnerTree();
    this.clearCalendarTree();
    this.setPagesTree(true);
  }

  clearLocationTree() {
    this.locationTree.allItem.checked = false;
    this.locationTree.onAllCheckedChange();
  }

  clearPartnerTree() {
    this.partnerTree.allItem.checked = false;
    this.partnerTree.onAllCheckedChange();
  }

  clearCalendarTree() {
    this.calendarTree.allItem.checked = false;
    this.calendarTree.onAllCheckedChange();
  }

  setPagesTree(showAll: boolean = true) {
    this.pagesTree.allItem.checked = showAll;
    this.pagesTree.onAllCheckedChange();
    setTimeout(() => {
      this.applyPageFilters();
    }, 100);
  }

  saveDashboardState() {
    const modernLifeDashboardState = {
      countryName: this.selectedLocation,
      consumerTopParent: this.selectedPartner,
      calendarIds: this.selectedDates,
    };
    this.empowerGamingService.setDashboardState(modernLifeDashboardState);
    this.clearPageState();
  }

  exportEvent() {
    const isDashboardFiltersValid = this.empowerGamingService.isDashboardStateValid();
    if (!isDashboardFiltersValid) {
      this.toastr.error('Error', 'Please selector dashboard filters correctly.');
    } else {
      const props = {
        sessionId: this.data.sessionId,
        reportId: this.data.reportId,
        reportTitle: this.data.reportTitle,
        ...this.empowerGamingService.GetDashboardFilterPayload()
      };
      this.empowerExportService.exportEvent(this.exportType, 'gaming', props);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
