<!-- Main Content area -->
<div class="container-fluid">
  <div class="user-page">
    <div class="row" style="margin-top: 15px;">
      <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
        <div class="btn-show">
          <mat-icon (click)="openModal()"> <i class="material-icons">
              add_circle
            </i></mat-icon>
        </div>
      </div>
      <div class="col-md-3">
        <form action="" class="form-fields show-search">
          <div class="form-group">
            <mat-form-field>
              <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-9 d-none d-sm-none d-md-block tablet-icon">
        <div class="btn-show">
          <button mat-icon-button color="primary" (click)="openModal()">
            <i class="material-icons">
              add_circle
            </i>
          </button>

        </div>
      </div>
    </div>
  </div>

  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
      [rowData]="currencyList" (gridReady)="onGridReady($event)"  #grid></ag-grid-angular>
  </div>


</div>

<ng-template #currencyForm let-data>
  <mat-form-field>
    <input matInput placeholder="Enter Currency Name" [(ngModel)]="data.currencyName"
      [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  <mat-error *ngIf="submitted && !data.currencyName">
    *Required Field
  </mat-error>
  <perfect-scrollbar>
    <div class="currency-rate">
      <div class="row" *ngFor="let currency of data?.currencyRates || []">
        <div class="col-md-4 currency-label">
          <label>{{currency.monthName}}{{currency.yearName}}</label>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <input matInput placeholder="Enter Rate" [(ngModel)]="currency.rate" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>
    </div>

  </perfect-scrollbar>

  <div class="action-panel pull-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onSubmit(data)">Save</button>
    <button mat-raised-button class="btn btn-secondary m-left" color="secondary"
      (click)="dialogRef.close()">Cancel</button>
  </div>
</ng-template>
