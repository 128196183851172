
import { formatDate } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { DropdownRendererComponent } from 'src/common/dropdown-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-suggest-admin',
  templateUrl: './suggest-admin.component.html',
  styleUrls: ['./suggest-admin.component.css']
})
export class SuggestAdminComponent implements OnInit {


  public registerForm: FormGroup;
  public submitted = false;
  public isEdit = false;
  public columnDefs = [
    {
      headerName: 'Date',
      field: 'created_at',
      cellRenderer: params => {
        return `<span>${formatDate(params.value, 'MM/dd/yyyy HH:mm', 'en')}</span>`
      },
      width: 150
    },
    {
      headerName: 'Ticket #',
      field: 'id',
      width: 120,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openModal.bind(this),
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      width: 350
    },
    {
      headerName: 'Status',
      field: 'suggestionStatusName',
      width: 120
    },
    {
      headerName: 'Action',
      field: 'defaultStatus',
      width: 200,
      cellRenderer: 'dropdownRenderer',
      cellRendererParams: (params) => {
        const items = ['Approve', 'Reject'];
        const obj = {
          isDropDown: true,
          screen: 'Suggest',
          items: items, // pass the field value here
          onChange: this.updateStatus.bind(this),
        }
        return obj;
      },
    },
  ];
  public columnDefsInner = [
    {
      headerName: 'Date',
      field: 'created_at',
      cellRenderer: params => {
        return `<span>${formatDate(params.value, 'MM/dd/yyyy HH:mm', 'en')}</span>`
      },
      width: 150
    },
    {
      headerName: 'Status',
      field: 'suggestionStatusName',
      width: 120
    },
    {
      headerName: 'Note',
      field: 'notes',
      width: 350
    },
  ];
  private gridApi: any;
  public getRowHeight;
  public headerHeight;
  public suggestList = [];
  public statusList;
  public statusSelectedList = [];
  public gridOptions: any;
  public gridOptionsInner: any;

  @ViewChild('suggest') templateRef: TemplateRef<any>;
  // public  date = { begin: new Date(2018, 7, 5), end: new Date(2018, 7, 25) };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public dialogRef: MatDialogRef<any>,
    public configurationService: ConfigurationService) {
    this.gridOptions = {
      frameworkComponents: {
        'toggleRenderer': ToggleButtonRendererComponent,
        'nameRenderer': NameRendererComponent,
        'dropdownRenderer': DropdownRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.gridOptionsInner = {
      frameworkComponents: {
        'toggleRenderer': ToggleButtonRendererComponent,
        'nameRenderer': NameRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.headerHeight = 40;
    this.gridOptions.getRowHeight = function (params) {
      return 40;
    };
  }

  ngOnInit() {
    this.populateList();
    this.initializeForm();
  }
  public populateList = async () => {
    this.statusList = await this.configurationService.SuggestionStatusGetList({ clientId: 1 }).toPromise().catch(error => throwError(error));
    this.configurationService.SuggestAdminGetAll({ clientId: 1 }).subscribe((res: any) => {
      this.suggestList = res;
      this.suggestList = this.suggestList.map(row => ({
        ...row,
        defaultStatus: 'Approve/Reject',
        suggestionStatusName: this.statusList.find(el => row.suggestion_statusId === el.suggestionStatusId).suggestionStatusName
      }))
    });
  }
  initializeForm() {
    this.registerForm = this.formBuilder.group({
      startDate: [],
      endDate: [],
      selectedStatus: []
    });
  }
  populateForm(event): void {
    if (event && event.data) {
      this.isEdit = true;
      const dataToPopulate = event.data;
      Object.keys(this.registerForm.controls).forEach(key => {
        if (dataToPopulate[key]) {
          this.registerForm.controls[key].setValue(dataToPopulate[key]);
        }
      });
    }
  }
  public onSubmit(data) {
    // const model = {
    //   ...data
    // };
    this.spinner.show();
    this.configurationService.SuggestAdminUpdate(data).subscribe(res => {
      this.spinner.hide();
      this.populateList();
      // setTimeout(() => {
      //   data.suggestLogs.push(data);
      //   this.gridOptionsInner.api.setRowData(data.suggestLogs);
      // }, 1);
      this.dialogRef.close();
    }, error => {
      this.spinner.hide();
      this.dialogRef.close();
    });
  }
  Cancel_Click() {
    this.submitted = false;
    this.isEdit = false;
    this.initializeForm();
    this.gridOptions.api.setRowData(this.suggestList);
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onGridInnerReady(params) {
    this.gridApi = params.api;
  }
  filterByDate = () => {
    let suggestList = this.suggestList;
    if (this.registerForm.controls['startDate'].value && this.registerForm.controls['endDate'].value) {
      suggestList = this.suggestList.filter(row => {
        return new Date(row.created_at) >= this.registerForm.controls['startDate'].value && new Date(row.created_at) <= this.registerForm.controls['endDate'].value
      });
    }
    if (this.registerForm.controls['selectedStatus'].value) {
      suggestList = this.suggestList.filter(row => {
        return row.suggestionStatusName === this.registerForm.controls['selectedStatus'].value.suggestionStatusName;
      });
    }
    this.gridOptions.api.setRowData(suggestList);

  }
  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  openModal(data) {
    if (data && data.rowData) {
      this.isEdit = true;
      const model = {
        id: data.rowData.id
      }
      this.configurationService.SuggestAdminGet(model).subscribe(res => {
        res.suggestionStatusName = this.statusList.find(el => res.suggestion_statusId === el.suggestionStatusId).suggestionStatusName;
        res.suggestLogs = res.suggestLogs.map(row => ({
          ...row,
          suggestionStatusName: this.statusList.find(el => row.suggestion_statusId === el.suggestionStatusId).suggestionStatusName
        }));
        this.dialogRef = this.dialog.open(this.templateRef, { width: '800px', data: res || {} });
        if (this.dialogRef) {
          this.dialogRef.afterClosed().subscribe(res => {
            // this.gridOptionsInner.api.setRowData([]);
          });
        }
      });

    }
  }
  public updateStatus(row) {
    const stat = this.statusList.find(el => el.suggestionStatusName.startsWith(row.event.value));
    row.rowData.data.suggestion_statusId = stat ? stat.suggestionStatusId : row.rowData.data.suggestion_statusId;
    this.spinner.show();
    this.configurationService.SuggestAdminUpdateStatus(row.rowData.data).subscribe(res => {
      this.spinner.hide();
      this.populateList();
    }, error => {
      this.spinner.hide();
    });
  }
}
