<div *ngIf="!isOutputTab" class="d-flex justify-content-end align-items-center w-100 p-2">
  <button mat-raised-button class="mx-2" color="accent" (click)="downloadData()">Download Table
  </button>
</div>
<div class="cluster-columns-main-div">
  <div *ngFor="let items of clusteringDataSource; let j=index" class="cluster-columns-item">
    <ng-container *ngIf="items">
      <!-- <h6 class="mt-3" style="margin-bottom: 2.7rem;" *ngIf="!isOutputTab"
        (click)="isOutputTab?editableFields[items.id]=true:null">{{clusteringLabels[j].label_name}}</h6> -->
      <mat-form-field appearance="outline">
        <!-- <mat-label>Cluster Name</mat-label> -->
        <input matInput placeholder="Cluster name" [disabled]="!isOutputTab" [readonly]="!isOutputTab" (change)="nameChanged=true; disableSaveRun.emit(true);" [(ngModel)]="clusteringLabels[j].label_name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <input matInput placeholder="Search" [(ngModel)]="items.search" (change)="searchSubject.next({col: j, value: items.search})">
        <mat-icon matSuffix style="cursor: default;">search</mat-icon>
      </mat-form-field>
      <div class="inner-items"
        infiniteScroll
        [scrollWindow]="false"
        [infiniteScrollDistance]="0.5"
        [infiniteScrollThrottle]="100"
        (scrolled)="onScroll(items, j)"
        (dragover)="onDragOver($event)" (drop)="onDropColumn(j)">
        <ng-container *ngFor="let item of items.data; let i =index;">
          <div class="customer-item-cls" [ngClass]="i%2==0?'':'dark-clr'" *ngIf="!item.hideItem"
            [draggable]="isOutputTab" (dragstart)="onDragStart(i, j)" (dragend)="onDragEnd($event)" (mouseup)="onDragEnd($event)" (dragover)="onDragOver($event)" (drop)="onDrop(i, j, $event)">
              {{item[objectToCluster]}}
          </div>
        </ng-container>
        <h6 class="p-4 text-center" *ngIf="items.data.length==0 || items.data.length==items.hiddenItems">No data to show</h6>
      </div>
    </ng-container>
  </div>
</div>
<div class="d-flex justify-content-start p-2" *ngIf="isOutputTab">
    <button mat-raised-button color="accent" [disabled]="dragDropObj.row_ids.length == 0 && !nameChanged" (click)="adjustMembership()">APPLY CHANGES</button>
    <button mat-stroked-button color="accent" class="ml-2" [disabled]="!isAdjusted" (click)="resetToAlgo()">RESET TO ALGO</button>
</div>
