import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';

@Component({
  selector: 'forecast-manager-renderer',
  template: `<a *ngIf="params.data.status==='Succeeded'" class="product-title-link" (click)="btnEditClickHandler()">{{params.value}}</a>
  <span *ngIf="params.data.status!=='Succeeded'">{{params.value}}</span>`,
})
export class ForecastManagerEditRenderComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private forecastManagerService: ForecastManagerService,) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnEditClickHandler() {
    this.forecastManagerService.setForecastGridActionCellSubject({ forecastData: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
