import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'fusion-ms-combination-scrollable-chart',
  templateUrl: './ms-combination-scrollable-chart.component.html',
  styleUrls: ['./ms-combination-scrollable-chart.component.scss']
})
export class FusionMsCombinationScrollableChartComponent implements OnInit {
  @Input() chartCaption?: string;
  @Input() xaxisCatgoryList: string[];
  @Input() dataset: any[];
  @Input() lineset: any[];
  @Input() height?: string = '200px';
  @Input() showvalues?: string = '1';
  @Input() numberprefix?: string = '';
  @Input() sNumberSuffix?: string = '%';
  @Input() xAxisName?: string = '';
  @Input() yAxisName?: string = '';
  @Input() sYAxisName?: string = '';

  /*

    Category Sample Object 
    {
      label: 'abc'
    }

    Data Series Sample Object
    {
      seriesname: "Original",
      color: "#ff0000",
      data: []
    }

    Data Series Data Sample Object 
    {
      value: 123
    }
  */

  dataSource: any = {
    chart: {
      caption: "",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100",
      chartLeftMargin: "0",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      canvasPadding: "0",
      showBorder: "1",
      borderColor: "#8D009D",
      borderThickness: "2",
      borderAlpha: "20",
      plotHighlightEffect: "fadeout",
      legendPosition: "top-left",
      legendNumRows: "1",
      showSecondaryLimits: "1",
      showDivLineSecondaryValue: "1",
      // legendPosition: "absolute",
      // legendXPosition: "40",
      // legendYPosition: "5",
      showvalues: this.showvalues,
      numberprefix: this.numberprefix,
      sNumberSuffix: this.sNumberSuffix
    },
    categories: [
      {
        category: []
      }
    ],
    dataset: [],
    lineset: []
  };

  chartConfig: Object = {
    type: 'scrollmsstackedcolumn2dlinedy',
    dataFormat: 'json',
  };


  constructor() {

  }

  ngOnInit() {
    this.initChartDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initChartDataSource();
  }

  initChartDataSource() {
    this.dataSource.chart.caption = this.chartCaption;
    this.dataSource.chart.showvalues = this.showvalues;
    this.dataSource.chart.numberprefix = this.numberprefix;
    this.dataSource.chart.sNumberSuffix = this.sNumberSuffix;
    this.dataSource.chart.xAxisName = this.xAxisName;
    this.dataSource.chart.yAxisName = this.yAxisName;
    this.dataSource.chart.sYAxisName = this.sYAxisName;
    this.dataSource.categories[0].category = this.xaxisCatgoryList;
    this.dataSource.dataset = [{dataset: this.dataset}];
    this.dataSource.lineset = this.lineset;
  }

}
