<div class="container-fluid promo-manager-page">

  <div class="row product-grid-filter-row mt-10">
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()" placeholder="Promotion Name"
          [(ngModel)]="promoName">
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()" placeholder="Promotion Event"
          [(ngModel)]="promotionEventName">
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <div class="form-group multi-select d-flex w-100" style="height:47px !important">
        <ng-multiselect-dropdown class="h-100" [placeholder]="'Planning Month(s)'" [settings]="monthsDropdownSettings"
          [data]="planningMonthList" [(ngModel)]="selectedPlanningMonths" (onSelect)="selectMonthsChange()"
          (onDeSelect)="selectMonthsChange()">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <mat-select placeholder="Planning Info" [(ngModel)]="promoInfo" (selectionChange)="applyFilterEvent()">
          <mat-option *ngFor="let info of promoInfoList" [value]="info.promoInfoId">{{info.promoInfoName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <mat-select placeholder="Status" [(ngModel)]="status" (selectionChange)="applyFilterEvent()">
          <mat-option *ngFor="let status of statusList" [value]="status.statusId">{{status.statusName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <div class="form-group multi-select d-flex w-100" style="height:47px !important">
        <ng-multiselect-dropdown class="h-100" placeholder= "{{_dataService.getDataById(1)}}" [settings]="accountsDropdownSettings"
          [data]="accountList" [(ngModel)]="selectedAccounts" (onSelect)="accountsChange()"
          (onDeSelect)="accountsChange()">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="product-filter-clear-btn mr-2">
      <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
    </div>
  </div>

  <div class="grid-action-btn-bar align-items-center">
    <div class="seasonality-label">
      <mat-slide-toggle [(ngModel)]="isDepromo" (change)="openDePromoView($event)">De-Promo
      </mat-slide-toggle>
    </div>
    <ng-container *ngIf="azpipeline && isDepromo">
      <button matTooltipPosition="right" matTooltip="Data Sync Status {{azpipeline.status}}, &nbsp;Last Sync {{azpipeline.lastUpdated| date: 'dd/MM/yyyy HH-mm'}} &nbsp;" mat-icon-button class="mx-2 sync-btn" [ngClass]="setSyncButtonClassFunction(azpipeline.status)"
        (click)="triggerPromoManagerPipeline()">
        <i class="material-icons">
          sync
        </i>
      </button>
    </ng-container>
    <!-- <span *ngIf="azpipeline" class="sync-msg ml-2">Data Sync Status {{azpipeline.status}}
      &nbsp;<br>Last Sync
      {{azpipeline.lastUpdated| date: "dd/MM/yyyy HH-mm"}} &nbsp; </span> -->
    <button matTooltip="Show/Hide Columns" class="grid-bar-icon mx-2" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>

    <div class="grid-actions inline-flex">
      <div *ngIf="selectedRows.length" class="row-info">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{totalRows}}</strong>
        </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{totalRows}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>
    <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
      (click)="exportPromoGridData()">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>

    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="importExportMenu">Export/Import</button>
    <mat-menu #importExportMenu="matMenu">
      <button mat-menu-item (click)="exportDataTemplate()">Export Template</button>
      <button mat-menu-item (click)="importDataByTemplate()">Import
        <input class="hidden" type="file" id="upload-promotion-planner-data" name="upload-promotion-planner-data"
          accept=".csv" (change)="validateUploadedCSV($event)">
      </button>
    </mat-menu>

    <button class="add-icon-btn ml-10" (click)="openPromotionSetupDetail()" matTooltip="Add Promotion" mat-icon-button
      color="primary" type="button">
      <i class="material-icons">
        add_circleg
      </i>
    </button>

    <button mat-flat-button *ngIf="isGridDataUpdated" color="accent" class="ml-10"
      (click)="saveGridData()">Save</button>

  </div>

  <div class="all-show-grid forecast-engine-ag-grid">

    <ag-grid-angular #promoGrid id="promo-data-grid" class="ag-theme-material promo-manager-grid"
      [columnDefs]="columnDefs" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef"
      [frameworkComponents]="frameworkComponents" [components]="components" [rowSelection]="rowSelection"
      [animateRows]="true" [rowModelType]="rowModelType" [serverSideStoreType]="serverSideStoreType" [pagination]="true"
      [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" [suppressRowClickSelection]="true"
      (selectionChanged)="rowSelectionChanged($event)" (gridReady)="onGridReady($event)"
      (cellValueChanged)="depromoGridDataUpdated($event)">
    </ag-grid-angular>

    <div style="width: 0; height: 0;">
      <ag-grid-angular #promoDataExportGridHidden class="ag-theme-material hide-grid" [gridOptions]="gridOptions"
        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="gridData"
        (gridReady)="onHiddenGridReady($event)" >
      </ag-grid-angular>
    </div>

  </div>


</div>
