export const colorCodes = [
    '#E6194B',
    '#3CB44B',
    '#FFE119',
    '#4363D8',
    '#F58231',
    '#911EB4',
    '#42D4F4',
    '#F032E6',
    '#BFEF45',
    '#FABED4',
    '#469990',
    '#DCBEFF',
    '#9A6324',
    '#000000',
    '#800000',
    '#AAFFC3',
    '#ABDBE3',
    '#FFD8B1',
    '#000075',
    '#A9A9A9',
    '#00FF17',
    '#CFB407'
]