import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { generateTitleDiv, NO_IMAGE, undefinedImagePath } from 'src/modules/empower-module/helper';
import * as _ from 'lodash';
import { GetCategoriesData, GetPercentageSeriesData, PercentageSeriesInput } from 'src/modules/empower-module/helper';

@Component({
  selector: 'empower-m365-mix-vs-market',
  templateUrl: './m365-mix-vs-market.component.html',
  styleUrls: ['./m365-mix-vs-market.component.scss']
})
export class EmpowerM365MixVsMarketComponent implements OnInit {
  @Input() data?: any;

  xaxisCategroyM365MixFirst: any = [];
  xaxisCategroyFamilyMixFirst: any = [];
  xaxisCategroyDigitalMixFirst: any = [];
  xaxisCategroyM365MixSecond: any = [];
  xaxisCategroyFamilyMixSecond: any = [];
  xaxisCategroyDigitalMixSecond: any = [];

  dataSeriesM365MixFirst: any = [];
  dataSeriesFamilyMixFirst: any = [];
  dataSeriesDigitalMixFirst: any = [];
  dataSeriesM365MixSecond: any = [];
  dataSeriesFamilyMixSecond: any = [];
  dataSeriesDigitalMixSecond: any = [];

  innerHtml = '';
  pageComment: string;

  constructor(
    public empowerModernLifeService: EmpowerModernLifeService, private empowerExportService: EmpowerExportService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);

      this.innerHtml = generateTitleDiv(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const daxReponse = this.empowerModernLifeService.getPagesState(pageId);
    this.pageComment = this.empowerModernLifeService.getPageCommentState(pageId);
    if (daxReponse && daxReponse.length) {
      this.loadVisuals(daxReponse);
    } else {
      this.executePageDaxQuery();
    }
  }

  _unsubscribeAll = new Subject();
  undefinedImagePath = '';
  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.empowerModernLifeService.getDashboardState()
    };
    this.spinner.show();
    this.empowerModernLifeService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        this.emitExportEvent(true);
      }
      this.spinner.hide();
    },
      err => {
        this.undefinedImagePath = undefinedImagePath;
        this.spinner.hide();
      });
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }

  setPageState(pageId, daxData) {
    this.empowerModernLifeService.setPagesState(pageId, daxData);
  }

  loadVisuals(daxData) {
    this.loadM356FirstVisualDataChart(daxData[0]);
    this.loadFamilyFirstVisualDataChart(daxData[1]);
    this.loadDigitalFirstVisualDataChart(daxData[2]);
    this.loadM356SecondVisualDataChart(daxData[3]);
    this.loadFamilySecondVisualDataChart(daxData[4]);
    this.loadDigitalSecondVisualDataChart(daxData[5]);
    this.emitExportEvent(true);
  }

  loadM356FirstVisualDataChart(visualData) {
    const seriesInput: PercentageSeriesInput = {
      daxData: visualData.daxResponse,
      orderByKey: 'dIM_DATE_COMP[FISCAL_YEAR]',
      seriesKey: 'dIM_PRODUCT_O2[REPORTING_PRODUCT_DIVISION]',
      valueKey: '[STLicsQty___Comp_Date]'
    };
    this.xaxisCategroyM365MixFirst = GetCategoriesData(seriesInput);
    this.dataSeriesM365MixFirst = GetPercentageSeriesData(seriesInput);
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(15, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

  loadFamilyFirstVisualDataChart(visualData) {
    const seriesInput: PercentageSeriesInput = {
      daxData: visualData.daxResponse,
      orderByKey: 'dIM_DATE_COMP[FISCAL_YEAR]',
      seriesKey: 'dIM_PRODUCT_O2[PRODUCT_FAMILY]',
      valueKey: '[STLicsQty___Comp_Date]'
    };
    this.xaxisCategroyFamilyMixFirst = GetCategoriesData(seriesInput);
    this.dataSeriesFamilyMixFirst = GetPercentageSeriesData(seriesInput);
  }

  loadDigitalFirstVisualDataChart(visualData) {
    const seriesInput: PercentageSeriesInput = {
      daxData: visualData.daxResponse,
      orderByKey: 'dIM_DATE_COMP[FISCAL_YEAR]',
      seriesKey: 'dIM_PRODUCT_O2[PRODUCT_FAMILY]',
      valueKey: '[STLicsQty___Comp_Date]'
    };
    this.xaxisCategroyDigitalMixFirst = GetCategoriesData(seriesInput);
    this.dataSeriesDigitalMixFirst = GetPercentageSeriesData(seriesInput);
  }

  loadM356SecondVisualDataChart(visualData) {
    const seriesInput: PercentageSeriesInput = {
      daxData: visualData.daxResponse,
      orderByKey: 'dIM_DATE_COMP[FISCAL_YEAR]',
      seriesKey: 'dIM_PRODUCT_O2[REPORTING_PRODUCT_DIVISION]',
      valueKey: '[STLicsQty___Comp_Date]'
    };
    this.xaxisCategroyM365MixSecond = GetCategoriesData(seriesInput);
    this.dataSeriesM365MixSecond = GetPercentageSeriesData(seriesInput);
  }

  loadFamilySecondVisualDataChart(visualData) {
    const seriesInput: PercentageSeriesInput = {
      daxData: visualData.daxResponse,
      orderByKey: 'dIM_DATE_COMP[FISCAL_YEAR]',
      seriesKey: 'dIM_PRODUCT_O2[PRODUCT_FAMILY]',
      valueKey: '[STLicsQty___Comp_Date]'
    };
    this.xaxisCategroyFamilyMixSecond = GetCategoriesData(seriesInput);
    this.dataSeriesFamilyMixSecond = GetPercentageSeriesData(seriesInput);
  }

  loadDigitalSecondVisualDataChart(visualData) {
    const seriesInput: PercentageSeriesInput = {
      daxData: visualData.daxResponse,
      orderByKey: 'dIM_DATE_COMP[FISCAL_YEAR]',
      seriesKey: 'dIM_PRODUCT_O2[PRODUCT_FAMILY]',
      valueKey: '[STLicsQty___Comp_Date]'
    };
    this.xaxisCategroyDigitalMixSecond = GetCategoriesData(seriesInput);
    this.dataSeriesDigitalMixSecond = GetPercentageSeriesData(seriesInput);
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.empowerModernLifeService.setPageCommentState(pageId, comment);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
