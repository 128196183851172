import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CLIENT_INFO } from 'src/common/keys';
import { LocalstorageService } from 'src/services/localstorage.service';
@Component({
  selector: 'app-hierarchy-setup',
  templateUrl: './hierarchy-setup.component.html',
  styleUrls: ['./hierarchy-setup.component.scss']
})
export class HierarchySetupComponent implements OnInit {
  calendarType: '';
  calendarSetup: FormGroup;
  clientInfo: any;
  public calendarData = [{
    key: 1,
    value: 'Gregorian',
  },
  {
    key: 2,
    value: 'NRF',
  },
  {
    key: 3,
    value: 'Custom'
  }];
  public startMonth = [
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
  ];
  public endMonth = [
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
  ];
  public weekStartDay = [
    { key: 1, value: 'Monday' },
    { key: 2, value: 'Tuesday' },
    { key: 3, value: 'Wednesday' },
    { key: 4, value: 'Thursday' },
    { key: 5, value: 'Friday' },
    { key: 6, value: 'Saturday' },
    { key: 7, value: 'Sunday' },
  ];
  public weekEndDay = [
    { key: 1, value: 'Monday' },
    { key: 2, value: 'Tuesday' },
    { key: 3, value: 'Wednesday' },
    { key: 4, value: 'Thursday' },
    { key: 5, value: 'Friday' },
    { key: 6, value: 'Saturday' },
    { key: 7, value: 'Sunday' },
  ];

  constructor(private formBuilder: FormBuilder,
    public storage: LocalstorageService,
    ) { }

  ngOnInit(): void {
    this.clientInfo = this.storage.get(CLIENT_INFO);
    this.initializeForm();
  }



  initializeForm() {
    this.calendarSetup = this.formBuilder.group({
      calendarType: new FormControl('', [Validators.required]),
      fyStartMonth: new FormControl('', [Validators.required]),
      fyEndMonth: new FormControl('', [Validators.required]),
      weekStartDay: new FormControl('', [Validators.required]),
      weekEndDay: new FormControl('', [Validators.required]),
      fyStartYear: new FormControl('', [Validators.required]),
      fyEndYear: new FormControl('', [Validators.required]),
    });
  }

  selectMonth() {

  }
  selectWeekDays() {
  }

  createCalendarSetup() {
  }
  fyStartYearChanged() { }
  fyEndYearChanged() { }

}
