<div class="row m-0" style="overflow: hidden;">
  <ng-container *ngFor="let item of data?.reportPageVisuals">

    <ng-container *ngIf="item.visualType=='graph'">
      <div [ngClass]="'col-md-'+item.columnSize" class="mt-3 empower-chart-section">
        <p *ngIf="item.showMarketLabel" class="font-18">Market: | {{ dataService.getDashboardLocation() }}</p>
        <p *ngIf="item.showPartnerLabel" class="font-18">Partner: | {{ dataService.getDashboardPartner() }}</p>
        <h4>{{item?.visualTitle}}</h4>

        <fusion-percentage-horizental-bar-chart *ngIf="item?.subType=='stackedbar2d'" [height]="item?.height"
          [xaxisCatgoryList]="item?.xaxisCatgoryList" [dataSeries]="item?.dataSeries" [yAxisName]="item?.yAxisName">
        </fusion-percentage-horizental-bar-chart>

        <fusion-ms-line-chart *ngIf="item?.subType=='msline'"
          [height]="item?.height"
          [xaxisCatgoryList]="item?.xaxisCatgoryList"
          [dataSeries]="item?.dataSeries">
        </fusion-ms-line-chart>

        <fusion-gage-chart *ngIf="item?.subType=='angulargauge'"
          [height]="item?.height" [dialValue]="item?.dialValue">
        </fusion-gage-chart>

        <fusion-ms-combination-scrollable-chart
          *ngIf="item?.subType=='combination-scrollable-chart'"
          [height]="item?.height"
          [xaxisCatgoryList]="item?.xaxisCatgoryList"
          [dataset]="item?.dataset" [lineset]="item?.lineset"
          [xAxisName]="item?.xAxisName"
          [yAxisName]="item?.yAxisName"
          [sNumberSuffix]="item?.sNumberSuffix"
          [sYAxisName]="item?.sYAxisName">
        </fusion-ms-combination-scrollable-chart>

        <fusion-ms-combination-chart-2y *ngIf="item?.subType=='mscombidy2d'"
          [height]="item?.height"
          [xaxisCatgoryList]="item?.xaxisCatgoryList"
          [dataSeries]="item?.dataSeries">
        </fusion-ms-combination-chart-2y>

        <fusion-ms-column-chart *ngIf="item?.subType=='mscolumn2d'"
          [height]="item?.height"
          [xaxisCatgoryList]="item?.xaxisCatgoryList"
          [dataSeries]="item?.dataSeries"
          [xAxisName]="item?.xAxisName"
          [yAxisName]="item?.yAxisName"
          [numberSuffix]="item?.numberSuffix">
        </fusion-ms-column-chart>

      </div>
    </ng-container>

    <ng-container *ngIf="item.visualType=='image'">
      <div [ngClass]="'col-md-'+item.columnSize" class="justify-content-center">
        <app-image-upload-viewer [imageUrl]="item.imageUrl" orientation="square"
          (emitSelectedFile)="imageChange(item, $event)" (imageLoaded)="emitExportEvent($event)">
        </app-image-upload-viewer>
      </div>
    </ng-container>

    <ng-container *ngIf="item.visualType=='numberbox'">
      <div [ngClass]="'col-md-'+item.columnSize" class="justify-content-center">
        <div class="number-sectoion">
          <h4 *ngIf="item.visualTitle">{{ item.visualTitle }}</h4>
          <p><strong>{{ item.numberValue | currency }}</strong></p>
          <p *ngIf="item.visualSubtitle"><small>{{ item.visualSubtitle }}</small></p>
        </div>
      </div>
    </ng-container>

  </ng-container>
</div>
<div class="row m-0">
  <div class="col-12">
    <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
  </div>
  <div class="col-12">
    <app-report-page-footer></app-report-page-footer>
  </div>
</div>