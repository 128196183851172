import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privilege-error',
  templateUrl: './privilege-error.component.html',
  styleUrls: ['./privilege-error.component.css']
})
export class PrivilegeErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('app-privilege-error');
  }

}
