import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgxCaptureService } from 'ngx-capture';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { BotService } from 'src/services/bot.service';
import { UsersService } from 'src/services/User-services/user-services';
import { USER_ID } from 'src/common/keys';
import { JourneyViewComponent } from 'src/modules/reporting/features/context-log-journey/journey-view/journey-view.component';

@Component({
  selector: 'app-main', templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
  public noteList = [];
  public tempNoteList = [];
  public takeNote = false;
  public isEdit = false;
  public noteName = '';
  public content = '';
  public currentNote = {};
  public search = false;
  public loading = false;
  public show = false;
  public notificationList = [];
  public userList = [];
  public date = Date.now();
  public subscription: Subscription;
  @ViewChild('mainContent') db: ElementRef<any>;
  unreadJourneyCount: number = 0;
  constructor(
    public captureService: NgxCaptureService,
    public configService: ConfigurationService,
    public storage: LocalstorageService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public botService: BotService,
    public userService: UsersService) {
  }
  ngOnInit() {
    this.checkForJourneys();
    this.userService.GetAllUsers({ userTypeId: 1 }).subscribe(res => {
      this.userList = (res as any).data;
    });
    this.subscription = this.botService.notificationSubject$.subscribe(res => {
      if (res) {
        res = {
          ...res,
          user: this.userList.find(user => user.email === res.email)
        }
        this.notificationList.unshift(res);
        // if (this.subscription) {
        //   this.subscription.unsubscribe();
        // }
      }
    });
  }
  onOpenedChange = (e: boolean) => {
    const mainContent = document.getElementById('rightSidenav');
    if (mainContent) {
      mainContent.className = e ? 'hide-sidenav' : 'show-sidenav';
    }
    this.getNoteList();
  }
  openMenu = (item) => {
    item.name = item.name ? item.name : item.menu;
    this.configService.menuRendererSubject.next(item);
    this.show = false;
  }
  getNoteList = () => {
    const params = {
      userId: this.storage.get(USER_ID)
    };
    this.configService.NoteGetList(params).subscribe(res => {
      this.noteList = res;
      this.tempNoteList = res;
    });
  }
  addNewNote = () => {
    setTimeout(() => {
      this.isEdit = false;
      this.takeNote = true;
      this.noteName = '';
      this.content = '';
    }, 1);
  }
  public editNote = (item) => {
    setTimeout(() => {
      this.takeNote = true;
      this.isEdit = true;
      this.noteName = item.noteName;
      this.content = item.content;
      this.currentNote = item;
    }, 1);
  }
  deleteNote(item): void {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: item.noteName }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const params = {
          ...item,
          active: false
        };
        this.configService.NoteActivate(params).subscribe(res => {
          this.noteList = this.noteList.filter(note => note.noteId !== params.noteId);
        });
      }
    });
  }
  toggleAlert() {
    this.show = !this.show;
  }
  public takeScreenshot = () => {
    this.loading = true;
    this.configService.takeScreenshot.next(this.db);
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }
  addNotes = () => {
    if (this.isEdit) {
      this.updateNote(this.currentNote);
    } else {
      const model = {
        noteName: this.noteName,
        content: this.content,
        noteId: 0,
        userId: this.storage.get(USER_ID),
      };
      this.configService.NoteAdd(model).subscribe(res => {
        this.getNoteList();
        this.takeNote = false;
      });
    }
  }
  updateNote = (model) => {
    const requestVM = {
      ...model,
      noteName: this.noteName,
      content: this.content,
      userId: this.storage.get(USER_ID),
    };
    this.configService.NoteUpdate(requestVM).subscribe(res => {
      this.getNoteList();
      this.takeNote = false;
    });
  }
  showButton = () => {
    if (this.takeNote) {
      this.takeNote = false;
    }
  }
  public findNotes = (value) => {
    this.noteList = this.tempNoteList.filter(note => (note.noteName.toLowerCase().indexOf(value.toLowerCase()) > -1 || note.content.toLowerCase().indexOf(value.toLowerCase()) > -1))
  }
  onJourneyClick($event) {
    this.configService.loadModule('ReportingModule');
    setTimeout(() => {
      const dialogRef = this.dialog.open(JourneyViewComponent, {
        restoreFocus: false,
        width: '1400px',
      });
    }, 200);
  }
  checkForJourneys() {
    this.configService.GetNotViewedJourneys(this.storage.get(USER_ID)).subscribe((count: number) => {
      // console.log('Unread Notifications', count);
      this.unreadJourneyCount = count;
    });
  }
}

