import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {  GridApi, ColumnApi,  ColumnState } from 'ag-grid-enterprise';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DemoMasterTitleDialogComponent } from '../../dialogs/master-title-dialog/master-title-dialog.component';
import {
  masterGridColDefs,
  paginationPageSize,
  cacheBlockSize,
  rowSelection,
  defaultColDef
} from '../ag-grid/gridOptions';
import { MasterItemEditRenderer } from '../ag-grid/master-item-edit-renderer.component';
import { MasterItemActionCellRenderer } from '../../product-manager/ag-grid/master-item-action-cell-renderer.component';
import { downloadFromBase64, downloadFromLink, getFilterModel, getSortModel } from '../../util/util';
import { INVALID_TEAM, MASTER_ITEMS, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from '../../../../../common/keys';
import { MasterProductService } from '../../../../../services/Item-Management-Services';
import { NgxToasterService } from '../../../../../services/ngx-toaster.service';
import { UsersService } from '../../../../../services/User-services/user-services';
import { ConfirmationDialogComponent } from '../../../../../common/confirmation-dialog/confirmation-dialog.component';
import { AGColumnDialogComponent } from '../../../../../common/ag-column-dialog/ag-column-dialog.component';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-demo-master-item-list',
  templateUrl: './master-item-list.component.html',
  styleUrls: ['./master-item-list.component.css']
})
export class DemoMasterItemListComponent implements OnInit, OnDestroy {

  @Input() isDialoag?: boolean = false;

  INVALID_TEAM = INVALID_TEAM;

  rowModelType = 'serverSide';
  serverSideStoreType: any = 'partial';
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = rowSelection;
  public gridColumnDefs = masterGridColDefs(false);
  public defaultColDef = defaultColDef;

  private gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: any;
  public gridColumnState: ColumnState[] = [];
  gridData: [];
  frameworkComponents = {
    masterItemEditRenderer: MasterItemEditRenderer,
    masterItemActionCellRenderer: MasterItemActionCellRenderer
  };

  public actionSubscription: Subscription;
  selectedMasterRow: any;

  userTeam: any = '';
  isRetailerTeam: boolean = false;

  filterEvent: any = {};

  screenPreference: any;
  public _hubConnecton: HubConnection;
  constructor(
    public dialog: MatDialog,
    private masterItemService: MasterProductService,
    public toastr: NgxToasterService,
    private usersService: UsersService,
    private userPreferenceService: UserPreferenceService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit() {
    this._hubConnecton = new HubConnectionBuilder().withUrl("https://localhost:44346/send").build();
    this._hubConnecton
      .start()
      .then(() => console.log('Connection started!'))
      .catch(err => console.log('Error while establishing connection :('));

    this._hubConnecton.on('BroadcastMessage', (type: string, payload: string) => {

    });


   this._hubConnecton.on("BroadcastMessage",(sometext)=>{
     console.log(sometext);
   })

    this.getUserSecreenPrefereence();
    this.setRoleBaseAccess();
    this.actionSubscription = this.masterItemService.getMasterGridActionCellSubject().subscribe(res => {
      if (res) {
        this.performGridAction(res);
      }
    });
  }

  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(MASTER_ITEMS, MASTER_ITEMS);
    this.gridColumnState = this.screenPreference.columnState ? JSON.parse(this.screenPreference.columnState) : [];
  }

  performGridAction(actionObject) {
    switch (actionObject.action) {
      case 'edit': {
        this.openProductDialog(true, actionObject.actionData);
        break;
      }
      case 'delete': {
        this.openDeleteDialog(actionObject.actionData);
        break;
      }
    }
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }

    this.gridColumnDefs = masterGridColDefs(this.isRetailerTeam);
    this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
  }

  createGridDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody = {
          ...this.filterEvent,
          offSet: params.request.startRow ? params.request.startRow : 0,
          pageSize: paginationPageSize,
        };
        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);

        this.masterItemService
          .GetAllMasterItems(requestBody)
          .pipe(
            tap(() => this.gridApi.hideOverlay())
          )
          .subscribe((response: any) => {
            params.successCallback(response.data, response.totalRecord);
            this.deselectNode();
            response.data.length == 0 && this.gridApi.showNoRowsOverlay();
            setTimeout(() => {
              this.gridApi.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
            }, 100);
          });
      },
    };
    this.gridApi && this.gridApi.setServerSideDatasource(datasource);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.createGridDataSource();
    this.applyColumnState();
  }

  applyColumnState() {
    if (this.colApi && this.gridColumnState && this.gridColumnState.length) {
      setTimeout(() => {
        this.colApi.applyColumnState({ state: this.gridColumnState });
      }, 100);
    }
  }

  onExportGridData() {
    const filterModel = this.gridApi.getFilterModel();
    const sortModel = this.colApi.getColumnState();
    const requestBody = {
      ...this.filterEvent,
      pageSize: -1,
    };
    requestBody.filterModel = getFilterModel(filterModel);
    requestBody.sortModel = getSortModel(sortModel);

    this.masterItemService
      .GetAllMasterItems(requestBody)
      .subscribe((response: any) => {
        downloadFromBase64(_.get(response, 'data.0.fileBase64'), 'MasterItems.csv');
      });
  }

  exportMasterGridTemplate() {
    this.masterItemService.ExportMasterDataTemplate().subscribe((res: any) => {
      downloadFromLink(res, 'MasterItemTemplate');
    });
  }

  importMasterData() {
    (document.getElementById('upload-master-data') as any).value = '';
    document.getElementById('upload-master-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const reader = new FileReader();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const masterImportDataFile = reader.result as any;
        /*
          Files Validations Will go here
        */
        this.uploadImportedCsvFile(masterImportDataFile);
      }
    }
  }

  uploadImportedCsvFile(masterDataFile) {
    this.masterItemService.ImportMasterDataTemplate({
      fileBase64: masterDataFile
    }).subscribe(
      (res: any) => {
        this.gridApi.refreshServerSideStore({ purge: false });
        this.toastr.success('Success', 'Master Items Imported Successfully.');
      },
      (err: any) => {
        this.toastr.error('Error', 'Master Items Import Failed.');
      });
  }

  masterGridRowSelectionChanged(params) {
    this.selectedMasterRow = params.api.getSelectedRows()[0];
    this.masterItemService.selectedMasterItem = this.selectedMasterRow;
  }

  onGridStateChange(e) {
    this.gridColumnState = this.colApi.getColumnState();
    this.screenPreference.columnState = this.gridColumnState;
    this.updateScreenPreference();
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertColumnStatePreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  /**
   * Select the first row as default...
   */
  public onRowDataChanged(): void {
    if (this.gridApi) {
      this.gridApi.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    }
  }

  openProductDialog(isEdit = false, data = {}): void {
      const dialogRef = this.dialog.open(DemoMasterTitleDialogComponent, {
        data: {
          isEdit: isEdit,
          formData: {
            ...data
          }
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridApi.refreshServerSideStore({ purge: true });
        }
        this.masterItemService.masterItemDialogRef = null;
      });
  }

  openColumnDialog() {
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select', 'cellAction']
      },
      width: '700px'
    });
  }

  openDeleteDialog(actionData) {
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: actionData.productTitleName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteChildItem(actionData);
      }
    });
  }

  deleteChildItem(actionData) {
    this.masterItemService.DeleteMasterItem(actionData.masterItemId).subscribe((res: any) => {
      this.toastr.success('Success', 'Master Item Deleted Successfully.');
      this.gridApi.refreshServerSideStore({ purge: true });
    }, err => {
      this.toastr.error('Error', 'Master Item Deletion Failed.');
    });
  }

  applyFilters(event) {
    this.filterEvent = event;
    this.createGridDataSource();
  }

  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  ngOnDestroy() {
    console.log('On Destroy - ', this.gridColumnState);
    this.actionSubscription.unsubscribe();
  }

}
