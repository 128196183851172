<div class="master-item-page">

  <app-product-filters [isDialog]="true" [sourcePage]="'Product'" (filterEvent)="applyFilters($event)"></app-product-filters>

  <div class="row">
    <div class="col-md-1 d-block d-sm-block d-md-none mobile-icon">
      <div class="btn-show">
        <button matTooltip="Add Master Item" mat-icon-button color="primary" (click)="openProductDialog()">
          <i class="material-icons">
            add_circle
          </i>
        </button>
      </div>
    </div>
    <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
      <div class="btn-show">
        <button matTooltip="Add Master Item" mat-icon-button color="primary" (click)="openProductDialog()">
          <i class="material-icons">
            add_circle
          </i>
        </button>
      </div>
    </div>
  </div>
  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material"
      [suppressMenuHide]="true"
      groupHeaders
      toolPanelSuppressGroups
      toolPanelSuppressValues
      [frameworkComponents]="frameworkComponents"
      [rowSelection]="rowSelection"
      (selectionChanged)="masterGridRowSelectionChanged($event)"
      [gridOptions]="gridOptions"
      [columnDefs]="gridColumnDefs"
      [defaultColDef]="defaultColDef"
      [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize"
      (gridReady)="onGridReady($event)"
       #masterGrid>
    </ag-grid-angular>
  </div>

  <div mat-dialog-actions style="justify-content: flex-end;">
    <button mat-button mat-flat-button color="accent" (click)="onDoneClick()">Done</button>
  </div>
</div>
