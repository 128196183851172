<div class="item-wrapper admin-wrapper">
  <mat-tab-group [@.disabled]="true">
    <mat-tab>
      <ng-template mat-tab-label>Roles</ng-template>
      <ng-template matTabContent>
        <app-roles></app-roles>
      </ng-template>

    </mat-tab>
    <mat-tab label="Teams">
      <ng-template matTabContent>
        <app-teams></app-teams>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>Users</ng-template>
      <ng-template matTabContent>
        <app-users></app-users>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab>
      <ng-template mat-tab-label>Account Settings</ng-template>
      <ng-template matTabContent>
        <app-facebook-account-settings></app-facebook-account-settings>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>Product Settings</ng-template>
      <ng-template matTabContent>
        <app-facebook-product-settings></app-facebook-product-settings>
      </ng-template>
    </mat-tab> -->

    <mat-tab>
      <ng-template mat-tab-label>Menu Configuration</ng-template>
      <ng-template matTabContent>
        <app-algo-menu></app-algo-menu>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="userObj.isSuperUser">
      <ng-template mat-tab-label>Client Configuration</ng-template>
      <ng-template matTabContent>
        <app-clients></app-clients>
      </ng-template>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>White Hierarchy Setup</ng-template>
      <ng-template matTabContent>
       <app-hierarchy-setup-white-labeling></app-hierarchy-setup-white-labeling>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>Hierarchy Setup</ng-template>
      <ng-template matTabContent>
       <app-hierarchy-setup></app-hierarchy-setup>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>Hierarchy Manager</ng-template>
      <ng-template matTabContent>
       <app-hierarchy-manager></app-hierarchy-manager>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
</div>
