<div id="fb-forecast">
  <div id="item-manager" class="item-wrapper insight-wrapper">
    <div class="item-search-wrapper">
      <form action="" class="form-fields show-search">
      </form>
      <div class="item-search-btn">
        <button class="btn btn-primary" (click)="openDialog({})">New Brand</button>
      </div>
    </div>
    <div class="items-wrapper-grid product-wrapper">
      <h3 class="title">Product Setting</h3>
      <div class="insight-gallery-banner">
        <p>All Products</p>
      </div>
      <ng-container *ngFor="let item of treeViewList">
        <ng-container *ngIf="item.children && item.children.length > 0;">
          <ng-container [ngTemplateOutlet]="menutemplate" [ngTemplateOutletContext]="{item:item}">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!item.children || item.children.length === 0">
          <mat-nav-list class="single-link child-links d-flex">
            <a mat-list-item>
              {{getHeading(item)}} <span>{{item.name}}</span>
            </a>
            <a *ngIf="!item.isProduct&& !item.isParentProduct"
              (click)="$event.stopPropagation();item.isEdit = false;openDialog(item)">
              <mat-icon>add_circle_outline</mat-icon>
            </a>
            <a (click)="$event.stopPropagation();openEditDialog(item)">
              <mat-icon>create</mat-icon>
            </a>
            <span (click)="$event.stopPropagation();openConfirmationDialog(item)" class="delete-icon" role="img"
              aria-hidden="true">
              <i class="icon-bin" style="font-size: 26px;"></i></span>
          </mat-nav-list>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #menutemplate let-item="item" class="product-wrapper">
    <ng-container *ngIf="item && item?.children && item?.children.length > 0">
      <mat-expansion-panel>
        <mat-expansion-panel-header class="disable_ripple" collapsedHeight="50px" expandedHeight="50px">
          <mat-panel-title> {{getHeading(item)}} &nbsp;<span>{{item.name}}</span>
          </mat-panel-title>
          <mat-panel-description>
            <a *ngIf="!item.isProduct && !item.isParentProduct"
              (click)="$event.stopPropagation();item.isEdit = false;openDialog(item)">
              <mat-icon>add_circle_outline</mat-icon>
            </a>
            <a *ngIf="!item.isProduct" (click)="$event.stopPropagation();openEditDialog(item)">
              <mat-icon>create</mat-icon>
            </a>
            <span (click)="$event.stopPropagation();openConfirmationDialog(item)" class="delete-icon" role="img"
              aria-hidden="true">
              <i class="icon-bin" style="font-size: 30px;"></i></span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let child of item?.children">
          <ng-container *ngIf="child.children && child.children.length > 0;">
            <ng-container [ngTemplateOutlet]="menutemplate" [ngTemplateOutletContext]="{item:child}">
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!child.children || child.children.length === 0">
            <mat-nav-list [class.product-group]="child.isProductGroup" [class.product-family]="child.isProductFamily"
              class="single-link  d-flex">
              <a mat-list-item>
                {{getHeading(child)}} <span>{{child.name}}</span>
              </a>
              <a *ngIf="!child.isProduct && !child.isParentProduct"
                (click)="$event.stopPropagation();item.isEdit = false;openDialog(child)">
                <mat-icon>add_circle_outline</mat-icon>
              </a>
              <a *ngIf="!child.isProduct" (click)="$event.stopPropagation();item.isEdit = true;openEditDialog(child)">
                <mat-icon>create</mat-icon>
              </a>
              <span (click)="$event.stopPropagation();openConfirmationDialog(child)"
                class="delete-icon" role="img" aria-hidden="true">
                <i class="icon-bin" style="font-size: 30px;"></i></span>
            </mat-nav-list>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
    <ng-container *ngIf="!item.children || item.children.length === 0">
      <mat-nav-list [class.product-group]="item.isProductGroup" [class.product-family]="item.isProductFamily"
        class="single-link d-flex">
        <a mat-list-item>
          {{getHeading(item)}} <span>{{item.name}}</span>
        </a>
        <a *ngIf="!item.isProduct && !item.isParentProduct"
          (click)="$event.stopPropagation();item.isEdit = false;openDialog(item)">
          <mat-icon>add_circle_outline</mat-icon>
        </a>
        <a *ngIf="!item.isProduct" (click)="$event.stopPropagation();openEditDialog(item)">
          <mat-icon>create</mat-icon>
        </a>
        <span (click)="$event.stopPropagation();openConfirmationDialog(item)" class="delete-icon" role="img"
          aria-hidden="true">
          <i class="icon-bin" style="font-size: 28px;"></i></span>
      </mat-nav-list>
    </ng-container>
  </ng-template>

  <ng-template #brandTemplate let-data>
    <div mat-dialog-content>
      <form class="item-wrapper">
        <div class="form-group">
          <label>Brand Name</label>
          <input [(ngModel)]="data.brandName" [ngModelOptions]="{standalone:true}" placeholder="">
        </div>
        <div class="action-btns text-right">
          <button mat-raised-button class="btn btn-primary m-left" color="primary"
            (click)="onSubmitBrand(data)">Save</button>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #productGroupTemplate let-data>
    <div mat-dialog-content>
      <form class="item-wrapper">
        <div class="form-group">
          <label>Product Group Name</label>
          <input [(ngModel)]="data.productGroupName" [ngModelOptions]="{standalone:true}" placeholder="">
        </div>
        <div class="action-btns text-right">
          <button mat-raised-button class="btn btn-primary m-left" color="primary"
            (click)="onSubmitProductGroup(data)">Save</button>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #productFamilyTemplate let-data>
    <div mat-dialog-content>
      <form class="item-wrapper">
        <div class="form-group">
          <label>Product Family Name</label>
          <input [(ngModel)]="data.productFamilyName" [ngModelOptions]="{standalone:true}" placeholder="">
        </div>
        <div class="action-btns text-right">
          <button mat-raised-button class="btn btn-primary m-left" color="primary"
            (click)="onSubmitProductFamily(data)">Save</button>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #parentProductTemplate let-data>
    <div mat-dialog-content>
      <form class="item-wrapper">
        <div class="form-group">
          <label>Parent Product Name</label>
          <input [(ngModel)]="data.parentProductName" [ngModelOptions]="{standalone:true}" placeholder="">
        </div>
        <div class="action-btns text-right">
          <button mat-raised-button class="btn btn-primary m-left" color="primary"
            (click)="onSubmitParentProduct(data)">Save</button>
        </div>
      </form>
    </div>
  </ng-template>
