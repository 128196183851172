import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NO_IMAGE } from 'src/modules/empower-module/helper';


@Component({
  selector: 'app-image-upload-viewer',
  templateUrl: './image-upload-viewer.component.html',
  styleUrls: ['./image-upload-viewer.component.scss']
})
export class ImageUploadViewerComponent implements OnInit {

  @Input() imageUrl?: string;
  @Input() width?: string = '100%';
  @Input() height?: string = '100%';
  @Input() orientation: string;
  @Output() emitSelectedFile?: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageLoaded = new EventEmitter<boolean>();
  NO_IMAGE = NO_IMAGE;

  constructor(
  ) { }

  ngOnInit() { }

  uploadFile(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result as string;
        this.emitSelectedFile.emit(this.imageUrl);
      }
    }
  }

  onImageLoad() {
    this.imageLoaded.emit(true);
  }

  removeImage() {
    this.imageUrl = this.NO_IMAGE;
    this.emitSelectedFile.emit(this.imageUrl);
  }

  onError() {
    this.imageLoaded.emit(false); 
  }

}
