<div class="layout-wraper">
  <app-nav></app-nav>
  <div class="page-container" [style.padding-left]="!hidePanels ? '70px' : '0px'">
    <div class="sideBar-wrapper">
      <div *ngIf="!hidePanels" class="sideBar">
        <img src="assets/images/algo.png" alt="algo" class="logo-img">
        <ul>
          <li [class.active]="!showChatScreen" (click)="showChatScreen = false">
            <img style="max-width: 35px;" src="assets/images/dashboard.png">
          </li>
          <li *ngIf="multiChatUser.length > 0" [class.active]="showChatScreen" (click)="showChatScreen = true">
            <img style="height: 35px;width: 35px;" src="assets/images/chat.png">
          </li>
        </ul>
        <div class="company-holder-col-footer">
          <div class="cv-tos-holder">
            <a href="https://algo.com/terms-of-use/" target="_blank" class="white-text">TOS</a> | <a
              href="https://algo.com/privacy-statement/" target="_blank" class="white-text">Privacy</a>
          </div>
        </div>
      </div>
      <mat-sidenav-container [hasBackdrop]="false" autosize>
        <mat-sidenav class="main-sidenav" (openedChange)="onOpenedChange($event)" #sidenav
          [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'side' : 'side'"
          [opened]="!(isHandset$ | async)">
          <perfect-scrollbar>
            <div class="company-user-name">
              <button mat-button [matMenuTriggerFor]="menu"><span class="icon-user"></span>
                <p>{{userInfo?.firstName}} {{userInfo?.lastName}}</p> <span class="material-icons">
                  arrow_right
                </span>
              </button>
              <!-- users log out data -->
              <mat-menu #menu="matMenu" class="user-logout">
                <mat-menu></mat-menu>
                <div class="form-group">
                  <mat-select style="
                  padding: 10px 15px; font-size: 14px;" [(ngModel)]="selectedLanguage"
                    (ngModelChange)="setSelectedLanguage()">
                    <mat-option *ngFor="let item of ['en', 'de', 'zh']" [value]="item">
                      {{item}}
                    </mat-option>
                  </mat-select>
                </div>
                <!-- <a mat-menu-item>Setting & Administration</a>
                <a mat-menu-item>View Profile</a> -->
                <a mat-menu-item>Help</a>
                <a mat-menu-item
                  (click)="addElementToDashboard({menuId: 999,selector: 'app-reset-password',name:'Change Password',displayName:'Change Password',module: 'Auth' })">Change
                  Password</a>

                <a mat-list-item class="mat-menu-item">
                  <mat-slide-toggle (change)="changeBodyClass()">
                    {{document.body.className == 'dark-theme' ? 'Light' : 'Dark'}}</mat-slide-toggle>
                </a>
                <div class="logout">
                  <a (click)="logOut()">Logout</a>
                </div>
              </mat-menu>

            </div>
            <!-- menu data display  -->
            <mat-expansion-panel *ngIf="!showChatScreen" (opened)="panelOpenState = true"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header class="disable_ripple" collapsedHeight="75px" expandedHeight="75px">
                <mat-panel-title> Favorites
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list>
                <ng-container>
                  <a *ngFor="let favourite of favourites" mat-list-item class="favorites"
                    (click)="openFavouritesInDashboard(favourite)">
                    <div class="arrow">
                      <i class="icon-more-data"></i>
                      <p>
                        {{capitalizeFirstLetter(favourite.displayName)}}
                      </p>
                    </div>
                    <div class="fav-icon">
                      <mat-icon><i class="material-icons" (click)="deleteFromFavourites(favourite, $event)">clear</i>
                      </mat-icon>
                      <mat-icon><i class="material-icons" (click)="openDialog(favourite, $event)">edit</i>
                      </mat-icon>
                    </div>
                  </a>
                </ng-container>

              </mat-nav-list>
            </mat-expansion-panel>
            <!-- dynamic display data  -->
            <ng-container *ngIf="!showChatScreen">
              <ng-container *ngFor="let item of leftMenuList">
                <ng-container *ngIf="item.children && item.children.length > 0;">
                  <ng-container [ngTemplateOutlet]="menutemplate" [ngTemplateOutletContext]="{item:item, depth:1}">
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="item && (!item.children || item.children.length === 0)">
                  <mat-nav-list class="single-link">
                    <a mat-list-item (click)="addElementToDashboard(item)">
                      {{getName(item)}}
                    </a>
                  </mat-nav-list>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="showChatScreen">
              <app-channels-menu></app-channels-menu>
            </ng-container>


          </perfect-scrollbar>

        </mat-sidenav>
        <mat-sidenav-content>
          <mat-toolbar>
            <mat-toolbar-row>
              <button mat-icon-button (click)="toggle(sidenav)" class="toggleBtn">
              </button>
              <div class="right-user" [style.right]="sidenav.opened ? '310px' : '70px'">
                <img *ngIf="environment.headerLogoURL" [src]="headerLogoUrl" class="microsoft" alt="microsoft">
              </div>
            </mat-toolbar-row>
          </mat-toolbar>
          <app-main></app-main>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>

  </div>
</div>
<ng-template #dialogRef let-data>
  <h1 mat-dialog-title>{{capitalizeFirstLetter(data.menuItemName)}}</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput [(ngModel)]="data.displayName" placeholder="Enter Favorite Name" maxlength="20">
    </mat-form-field>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="onUpdate(data)">Save</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="matDialogRef.close()">Cancel</button>
  </div>
</ng-template>
<ng-template #menutemplate let-item="item" let-depth="depth">
  <ng-container *ngIf="item && item?.children && item?.children.length > 0">
    <mat-expansion-panel [ngStyle]="{'padding-left': (depth * 15) + 'px'}">
      <mat-expansion-panel-header class="disable_ripple layout-exp-panel" collapsedHeight="75px" expandedHeight="75px">
        <mat-panel-title> {{getName(item)}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let child of item?.children">
        <ng-container [ngTemplateOutlet]="menutemplate" [ngTemplateOutletContext]="{item:child, depth:depth+1}">
        </ng-container>
      </ng-container>
      <ng-container>
        <ng-container *ngFor="let child of item?.children">
          <mat-nav-list *ngIf="child && !child.children" [ngStyle]="{'padding-left': (depth * 15) + 'px'}">
            <a mat-list-item (click)="addElementToDashboard(child)">
              <div class="arrow">
                <!-- <i class="icon-more-data"></i> -->
                {{getName(child)}}
              </div>
            </a>
          </mat-nav-list>
        </ng-container>

      </ng-container>
    </mat-expansion-panel>
  </ng-container>
</ng-template>
<ng-template #groupDialog>
  <h1 mat-dialog-title>Create Group</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput placeholder="Search by name, email, or user group" maxlength="20">
    </mat-form-field>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary"
      (click)="matDialogRef.close()">Save</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="matDialogRef.close()">Cancel</button>
  </div>
</ng-template>
<ng-template #chatDialog>
  <h1 mat-dialog-title>Add People</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput placeholder="Search by name, email, or user group" maxlength="20">
    </mat-form-field>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary"
      (click)="matDialogRef.close()">Save</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn" (click)="matDialogRef.close()">Cancel</button>
  </div>
</ng-template>
