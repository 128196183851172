import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DemoService } from 'src/services/demo-services/demo-service';

@Component({
  selector: 'app-demo-product-filters',
  templateUrl: './product-filters.component.html',
  styleUrls: ['./product-filters.component.css']
})
export class DemoProductFiltersComponent implements OnInit, OnChanges {

  @Output() filterEvent = new EventEmitter<any>();
  @Input() sourcePage?: string;
  @Input() hardcodedFilters?: any;
  @Input() isDialog?: boolean = false;

  userTeam: any = '';
  isRetailerTeam: boolean = false;
  disabledAccountSelection: boolean = false;

  releaseFromDate = new FormControl('');
  releaseToDate = new FormControl('');
  isReleaseDateRangeOpen = false;
  productTitleName = '';
  makes: any = [];
  models: any = [];
  years: any = [];
  brands: any = [];

  makeList: any = [];
  modelList: any = [];
  yearList: any = [];
  brandList: any = [];

  screenPreference: any;
  screenFilters: any;

  constructor(public demoService: DemoService) { }

  ngOnInit() {
    this.initiateValues();
  }

  ngOnChanges() {
  }

  initiateValues() {
    this.demoService.GetItemMakeList().subscribe(makeList => {
      this.makeList = makeList;
    });
    this.demoService.GetItemModelList().subscribe(modelList => {
      this.modelList = modelList;
    });
    this.demoService.GetItemYearList().subscribe(yearList => {
      this.yearList = yearList;
    });
    this.demoService.GetItemBrandList().subscribe(brandList => {
      this.brandList = brandList;
    });
  }

  emitFilterEvent() {
    const eventBody = {
      ...this.productTitleName && { Title: this.productTitleName },
      ...this.makes.length && { Makes: this.makes },
      ...this.models.length && { Models: this.models },
      ...this.years.length && { Years: this.years },
      ...this.brands.length && { Brands: this.brands },
    };
    this.filterEvent.emit(eventBody);
  }

  clearValues() {
    this.productTitleName = '';
    this.makes = [];
    this.models = [];
    this.years = [];
    this.brands = [];
    this.emitFilterEvent();
  }

}
