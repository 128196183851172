import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { generateTitleDiv, GetCategoriesData, GetPercentageSeriesData, NO_IMAGE, PercentageSeriesInput, undefinedImagePath } from '../../helper';
import * as _ from 'lodash';
import { EmpowerFactoryService } from 'src/services/empower-services/factory.serivce';
import { getImagePath, getStaticImagePath } from 'src/common/util/util';

@Component({
  selector: 'empower-dynamic-component',
  templateUrl: './empower-dynamic-component.component.html',
  styleUrls: ['./empower-dynamic-component.component.scss']
})
export class EmpowerDynamicComponent implements OnInit, OnDestroy {

  @Input() data?: any;
  pageComment: string;

  dataService: any;

  _unsubscribeAll = new Subject<any>();

  constructor(
    private factoryService: EmpowerFactoryService,
    private spinner: NgxSpinnerService,
    private empowerExportService: EmpowerExportService) { }

  ngOnInit(): void {
    if (this.data) {
      this.data = JSON.parse(this.data);
      console.log(this.data);
      this.dataService = this.factoryService.GetDataSerivce(_.get(this.data, 'serviceName'));
      this.checkPageState(this.data.pageId)
    }
  }

  checkPageState(pageId) {
    switch (this.data.pageType) {
      case 'data': {
        const daxReponse = this.dataService.getPagesState(pageId);
        this.pageComment = this.dataService.getPageCommentState(pageId);
        if (daxReponse && daxReponse.length) {
          this.loadVisuals(daxReponse);
        } else {
          this.executePageDaxQuery();
        }
        break;
      }
      case 'static': {
        this.loadVisuals();
        break;
      }
      default: {
        this.loadVisuals();
        break;
      }
    }

  }

  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.dataService.getDashboardState()
    };
    this.spinner.show();
    this.dataService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      this.spinner.hide();
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        // this.emitExportEvent(true);
      }
    },
      err => {
        this.spinner.hide();
      });
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }


  loadVisuals(daxReponse: any = []) {
    this.data.reportPageVisuals.forEach((visual, index) => {
      const visualDetail = visual.reportPageVisualDetails;
      switch (visual.subType) {
        case 'stackedbar2d': {
          this.loadHorizontalBarChartVisual(visual, visualDetail, daxReponse[index]);
          break;
        }
        case 'msline': {
          this.loadLineChartVisual(visual, visualDetail, daxReponse[index]);
          break;
        }
        case 'mscombidy2d': {
          this.loadCombidy2dChart(visual, visualDetail, daxReponse[index]);
          break;
        }
        case 'mscolumn2d': {
          this.loadMscolumn2dChartVisual(visual, visualDetail, daxReponse[index]);
          break;
        }
        case 'angulargauge': {
          this.loadGageChart(visual, visualDetail, daxReponse[index]);
          break;
        }
        case 'numberbox': {
          this.loadNumberBox(visual, visualDetail, daxReponse[index]);
          break;
        }
        case 'image': {
          this.loadImageVisual(visual);
          break;
        }
      }
    });
    console.log(this.data);
  }

  loadImageVisual(visual) {
    const imageUrl = this.dataService.getPageImgState(this.data.pageId);
    if (imageUrl) {
      visual.imageUrl = imageUrl;
    }
    if (visual.imageUrl == NO_IMAGE) this.emitExportEvent(null);
    switch (_.get(visual, 'reportPageVisualDocument.documentTypeCode', NO_IMAGE)) {
      case 'STIMG': {
        visual.imageUrl = getStaticImagePath(visual.reportPageVisualDocument);
        break;
      }
      case 'IMG': {
        visual.imageUrl = getImagePath(visual.reportPageVisualDocument);
        break;
      }
      case NO_IMAGE: {
        visual.imageUrl = NO_IMAGE;
        break;
      }
      default: {
        visual.imageUrl = getImagePath(visual.reportPageVisualDocument);
        break;
      }
    }
  }

  setPageState(pageId, daxData) {
    this.dataService.setPagesState(pageId, daxData);
  }

  loadHorizontalBarChartVisual(visual, visualDetail, visualData) {
    const seriesInput: PercentageSeriesInput = {
      daxData: visualData.daxResponse,
      orderByKey: visualDetail[0].orderByKey,
      seriesKey: visualDetail[0].seriesName,
      valueKey: visualDetail[0].seriesDataKey
    };
    visual.xaxisCatgoryList = GetCategoriesData(seriesInput);
    visual.dataSeries = GetPercentageSeriesData(seriesInput);
  }

  loadLineChartVisual(visual, visualDetail, visualData) {
    const dataList = _.orderBy(visualData.daxResponse, [visualDetail[0].orderByKey], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a[visualDetail[0].xAxisLabelskey] } });
    const seriesList: any = [];
    visualDetail.forEach(serie => {
      seriesList.push({
        seriesname: serie.seriesName,
        data: _.map(dataList, a => { return { value: a[serie.seriesDataKey] } })
      })
    });
    visual.xaxisCatgoryList = categoryList;
    visual.dataSeries = seriesList;
  }

  loadMscolumn2dChartVisual(visual, visualDetail, visualData) {
    const dataList = _.orderBy(visualData.daxResponse, [visualDetail[0].orderByKey], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a[visualDetail[0].xAxisLabelskey] } });
    const seriesList: any = [];
    visualDetail.forEach(serie => {
      seriesList.push({
        seriesname: serie.seriesName,
        data: _.map(dataList, a => { return { value: a[serie.seriesDataKey] } })
      })
    });
    visual.xaxisCatgoryList = categoryList;
    visual.dataSeries = seriesList;
  }

  loadCombidy2dChart(visual, visualDetail, visualData) {
    const dataList = _.orderBy(visualData.daxResponse, [visualDetail[0].orderByKey], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a[visualDetail[0].xAxisLabelskey] } });

    visual.xaxisCatgoryList = categoryList;
    visual.dataSeries = [];

    visualDetail.forEach(serie => {
      const chartData = _.map(dataList, a => { return { value: a[serie.seriesDataKey] } });
      const chartObj = {
        seriesName: serie.seriesName,
        data: chartData,
        ...serie.renderAs && { renderas: serie.renderAs },
        ...serie.parentYAxis && { parentyaxis: serie.parentYAxis }
      } as any;
      visual.dataSeries.push(chartObj);
    });
  }

  loadGageChart(visual, visualDetail, visualData) {
    visual.dialValue = _.get(visualData.daxResponse, ['0', visualDetail[0].seriesDataKey], 0);
  }

  loadNumberBox(visual, visualDetail, visualData) {
    visual.numberValue = _.get(visualData.daxResponse, ['0', visualDetail[0].seriesDataKey], 0);
  }

  imageChange(item, imageUrl) {
    item.imageUrl = imageUrl;
    this.dataService.setPageImgState(this.data.pageId, imageUrl);
  }

  emitExportEvent(resolvedState) {
    if (this.data && this.data.export) {
      this.empowerExportService.setExportComponentSubject(2, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.dataService.setPageCommentState(pageId, comment);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
