import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';


@Component({
  selector: 'app-seasonality-chart-dialog',
  templateUrl: './seasonality-chart-dialog.component.html',
  styleUrls: ['./seasonality-chart-dialog.component.css']
})
export class SeasonalityChartDialogComponent implements OnInit {

  SKIP_KEYS: any = [];
  dataSource: any = {
    chart: {
      caption: "Seasonality Graph",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100"
    },
    categories: [
      {
        category: []
      }
    ],
    dataset: [
      {
        seriesname: "Original",
        color: "#ff0000",
        data: []
      },
      {
        seriesname: "Adjusted",
        color: "#0000ff",
        data: []
      }
    ]
  };
  chartConfig: Object = {
    type: 'msline',
    dataFormat: 'json',
    width: '900',
    height: '500',
  };

  constructor(
    public dialogRef: MatDialogRef<SeasonalityChartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.createChartData();
  }

  createChartData() {
    const chartData = _.get(this.data.chartData, 'saleData', {});
    const weekList = this.getWeekList(chartData);
    const weekLabels = weekList.map(week => {
      return { label: `${moment(week.weekStartDate).format('MM/DD/YYYY')}-W${week.weekNumber.toString().slice(-2)}` }
    });

    const orgChartData = {
      seriesname: 'Original',
      data: []
    };

    const adjChartData = {
      seriesname: 'Adjusted',
      data: []
    };

    weekList.forEach(week => {
      /*
        0: original value
        1: adjusted value
      */
      const value = _.get(chartData, `${week.columnField}`, '').split('_')
      orgChartData.data.push({ value: value[0] });
      adjChartData.data.push({ value: value[1] });
    });

    this.dataSource.categories[0].category = weekLabels;
    this.dataSource.dataset = [orgChartData, adjChartData];
  }

  getWeekList(data) {
    const weekMetaList = _.keys(_.omit(data), this.SKIP_KEYS);
    let weekList: any = [];
    /*
     Split this string "2021#202101#2021-01-03" on hash(#)
     0 - Report Year
     1 - Week Number
     2 - Week Start Date
   */
    weekMetaList.forEach(week => {
      const weekLables = week.split('#');
      weekList.push({
        columnField: week,
        year: weekLables[0],
        weekNumber: Number(weekLables[1]),
        weekStartDate: weekLables[2]
      });
    });
    weekList = _.orderBy(weekList, ['weekNumber'], ['asc']);

    return weekList;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
