import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_INFO } from 'src/common/keys';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ManageTableColumnsComponent } from '../dialogs/manage-table-columns/manage-table-columns.component';
import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import { KeyValue } from '@angular/common';

@Component({
  selector: 'cluster-membership-summary',
  templateUrl: './cluster-membership-summary.component.html',
  styleUrls: ['./cluster-membership-summary.component.scss']
})
export class ClusterMembershipSummaryComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() summaryStats: any;
  @Input() perClusterCount: any;
  @Input() isOutputTab = false;
  @Input() metrices = [];
  @Input() fileName = '';
  @Input() runIndex = 'RUN_0';
  @Input() clusteringLabels: [];
  @Input() isAdjusted?: boolean = false;
  @Input() versionNo: any;
  @Output() summaryStatsMetricVariables = new EventEmitter<any>();

  perClusterCountBackup: any;

  displayedColumns: string[] = [];
  groupedColumns: string[] = [];
  dataSource = [];

  userObj = {} as any;

  constructor(private dialog: MatDialog, private storage: LocalstorageService, private changeDetectionRef: ChangeDetectorRef,
    private clusteringService: ClusteringForecastService, private spinner: NgxSpinnerService) {
    this.userObj = this.storage.get(USER_INFO);
    }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.patchData();
  }

  ngOnChanges() {
  }

  patchData() {
    if (this.metrices.length == 0) return;
    this.fetchSummaryStatisticForTable(this.metrices);
    this.isOutputTab && this.addAdjustMembershipListener();
    this.perClusterCountBackup = JSON.stringify(this.perClusterCount);
  }

  addAdjustMembershipListener() {
    this.clusteringService.adjustMembershipSubject
      .subscribe((res: any) => {
        if (res) {
          this.isAdjusted = true;
          this.perClusterCount = res.per_cluster_count;
          this.clusteringLabels = res.clusteringLabels;
          this.reorderTableColumns(res.per_metric_colm_statistics);
          this.changeDetectionRef.detectChanges();
        } else {
          this.isAdjusted = false;
          this.perClusterCount = JSON.parse(this.perClusterCountBackup);
          this.fetchSummaryStatisticForTable(this.metrices);
          this.changeDetectionRef.detectChanges();
        }
      });
  }

  fetchSummaryStatisticForTable(metricVariables) {
    const metricesIds = [];
    metricVariables.forEach(element => {
      metricesIds.push(element.id);
    });
    const obj = {
      file_name: this.fileName,
      user_id: this.userObj.userId,
      matrix_columns: metricesIds,
      run_tab_index: this.isAdjusted ? 'Cluster_Id_adj' : `${this.runIndex}`,
      version_no: this.versionNo
    };
    this.spinner.show();
    this.clusteringService.fetchSummaryStatisticForTable(obj)
      .subscribe((res: any) => {
        this.perClusterCount = res.payload.result.data.per_cluster_count;
        this.reorderTableColumns(res.payload.result.data.per_metric_colm_statistics);
        this.isOutputTab && this.clusteringService.summaryStatsChangeSubject.next(metricesIds);
        this.spinner.hide();
        this.summaryStatsMetricVariables.next(metricVariables);
      },
        err => {
          this.spinner.hide();
        });
  }

  reorderTableColumns(data) {
    this.displayedColumns = ['Cluster'];
    this.groupedColumns = ['ClusterCol'];
    this.dataSource = [];

    data.forEach((element) => {
      const key = Object.keys(element)[0];
      const value = Object.values(element)[0] as any;
      let label = '';
      this.metrices.forEach(element => {
        if (element.id == key) {
          label = element.name;
        }
      });
      this.groupedColumns.push(label);
      this.displayedColumns.push(label + '_min');
      this.displayedColumns.push(label + '_mean');
      this.displayedColumns.push(label + '_max');
      value.forEach((valueItem, index) => {
        !this.dataSource[index] && (this.dataSource[index] = []);
        this.clusteringLabels.forEach((element: any) => {
          if (element.label_id == valueItem._id) {
            this.dataSource[index].ClusterName = element.label_name;
          }
        });
        this.dataSource[index]._id = valueItem._id;
        this.dataSource[index][label + '_min'] = valueItem.min;
        this.dataSource[index][label + '_max'] = valueItem.max;
        this.dataSource[index][label + '_mean'] = valueItem.avg;
      });
    });
  }

  openColumnDialog() {
    const columnsDialogRef = this.dialog.open(ManageTableColumnsComponent, {
      width: '650px',
      data: {
        checkedValues: this.groupedColumns.slice(1, this.groupedColumns.length),
        metrices: this.metrices,
        isSettingsTab: false
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        const metrices = [];
        this.metrices.forEach(element => {
          if (result.indexOf(element.id) > -1) {
            metrices.push(element);
          }
        });
        this.fetchSummaryStatisticForTable(metrices);
      }
    });
  }

  downloadStatsTable() {
    this.exportAsXLSX(this.dataSource, 'Summary Table')
  }

  downloadClusterMembership() {
    const data = [] as any;
    data[0] = {};
    const keys = Object.keys(this.perClusterCount);
    const values = Object.values(this.perClusterCount);
    keys.forEach((element, index) => {
      data[0]['Cluster '+ element] = values[index]; 
    });
    data[0]['Total Cases'] = this.summaryStats.total_cases;
    data[0]['Valid Cases'] = this.summaryStats.valid_cases;
    data[0]['Missing Cases'] = this.summaryStats.missing_cases;
    data[0]['Duplicate Cases'] = this.summaryStats.duplicate_cases;
    this.exportAsXLSX(data, 'Summary Stats');
  }

  // DOWNLOAD FILE IMPLEMENTATION : EXCEL FILE
  private exportAsXLSX(data: any[], name: string): void {
    const workbookData = [
      {
        workSheet: 'Tab1',
        rows: data
      }
    ];
    this.exportAsExcelFile(workbookData, name);
  }

  private async exportAsExcelFile(workbookData: any[], excelFileName: string) {
    const workbook = new ExcelJS.Workbook();

    workbookData.forEach(({ workSheet, rows }) => {
      const sheet = workbook.addWorksheet(workSheet);
      const uniqueHeaders = [
        ...new Set(
          rows.reduce((prev, next) => [...prev, ...Object.keys(next)], [])
        )
      ];
      sheet.columns = uniqueHeaders.map(x => ({ header: x, key: x }));

      rows.forEach((jsonRow, i) => {
        let cellValues = { ...jsonRow };

        uniqueHeaders.forEach((header: any, j) => {
          if (Array.isArray(jsonRow[header])) {
            cellValues[header] = "";
          }
        });
        sheet.addRow(cellValues);
        uniqueHeaders.forEach((header: any, j) => {
          if (Array.isArray(jsonRow[header])) {
            const jsonDropdown = jsonRow[header];
            sheet.getCell(
              this.getSpreadSheetCellNumber(i + 1, j)
            ).dataValidation = {
              type: "list",
              formulae: [`"${jsonDropdown.join(",")}"`]
            };
          }
        });
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, excelFileName);
  }

  private getSpreadSheetCellNumber(row, column) {
    let result = "";

    // Get spreadsheet column letter
    let n = column;
    while (n >= 0) {
      result = String.fromCharCode((n % 26) + 65) + result;
      n = Math.floor(n / 26) - 1;
    }

    // Get spreadsheet row number
    result += `${row + 1}`;

    return result;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  // EXPORT CODE ENDED
}
