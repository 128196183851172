import * as moment from 'moment';

export const yesNoRenderer = (params) => {
  return `${params.value == 1 ? 'Yes' : params.value == 0 ? 'No' : ''}`;
}

export const dateRenderer = (params) => {
  return `${params.value ? moment(params.value).format('MM/DD/YYYY') : ''}`;
}

export const dateTimeRenderer = (params) => {
  return `${params.value ? moment.utc(params.value).local().format('MM/DD/YYYY hh:mm:ss A') : ''}`;
}

export const statusRenderer = (params) => {
  return `${params.value ? 'Active' : 'In-Active'}`;
}
