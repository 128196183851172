<!-- <div style="margin-top: 20px">
  <button (click)="saveReport($event)">Save</button>
</div> -->
<div class="dynamic-report-wrapper">
  <div class="container-fluid header">
    <div class="row">
      <div class="col-md-4 d-flex align-items-center">
        <h2>{{ journeyName }}</h2>
      </div>
      <div class="col-md-8" *ngIf="reportLoaded">
        <div class="report-btn-wrapper">
          <button *ngIf="reportMode === 'create'" (click)="createJourneyMode($event)" mat-flat-button="" color="accent"
            class="mat-focus-indicator ml-10 mat-flat-button mat-button-base mat-accent" style="float: right">
            <span class="mat-button-wrapper">Create Journey</span><span matripple=""
              class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span>
          </button>
          <button *ngIf="reportMode === 'create'" (click)="getAllJourneys()" mat-flat-button="" color="accent"
            class="mat-focus-indicator ml-10 mat-flat-button mat-button-base mat-accent" style="float: right">
            <span class="mat-button-wrapper">Add to Existing Journey</span><span matripple=""
              class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span>
          </button>

          <ng-container *ngIf="reportMode === 'save'">
            <button mat-flat-button="" color="accent"
              class="mat-focus-indicator ml-10 mat-flat-button mat-button-base mat-accent" style="float: right"
              [matMenuTriggerFor]="savingMenu">
              Save/SaveAs
            </button>
            <mat-menu #savingMenu="matMenu">
              <button mat-menu-item (click)="saveJourney()">Save</button>
              <button mat-menu-item (click)="saveAsJourney()">Save As
              </button>
            </mat-menu>
          </ng-container>

          <button *ngIf="reportMode === 'save' && this.journeyId" (click)="postComment($event)" mat-flat-button=""
            color="accent" class="mat-focus-indicator ml-10 mat-flat-button mat-button-base mat-accent"
            style="float: right">
            <span class="mat-button-wrapper">Chat</span><span matripple=""
              class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span>
          </button>

          <button *ngIf="reportMode === 'save' && this.journeyId" (click)="shareJourney($event)" mat-flat-button=""
            color="accent" class="mat-focus-indicator ml-10 mat-flat-button mat-button-base mat-accent"
            style="float: right">
            <span class="mat-button-wrapper">Share</span><span matripple=""
              class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span>
          </button>

          <button *ngIf="reportMode === 'save'" (click)="getAllJourneys()" mat-flat-button="" color="accent"
            class="mat-focus-indicator ml-10 mat-flat-button mat-button-base mat-accent" style="float: right">
            <span class="mat-button-wrapper">Add to Existing Journey</span><span matripple=""
              class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span>
          </button>

          <button *ngIf="reportMode === 'save' && selectedIndex!==0" (click)="viewComments()" mat-flat-button=""
            color="accent" class="mat-focus-indicator ml-10 mat-flat-button mat-button-base mat-accent"
            style="float: right">
            <span class="mat-button-wrapper">View Comments</span><span matripple=""
              class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span>
          </button>

        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div [ngClass]="{'col-12': !commentSection, 'col-9' : commentSection }">
        <mat-tab-group [selectedIndex]="selectedIndex" *ngIf="this.reportMode === 'save'"
          (selectedIndexChange)="selectedIndexChange($event)">
          <mat-tab [label]="tab.name" *ngFor="let tab of stages">
            <ng-template mat-tab-label>
              <label matTooltip="{{tab.name}}" matTooltipPosition="above">{{ tab.name }}</label>
              <div *ngIf="isOwner()" class="example-tab-icon">
                <mat-icon *ngIf="tab.name !== 'Default'" (click)="removeContextLog(tab, $event)">close</mat-icon>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
        <div class="embedded-report">
          <button *ngIf="isBotFiltersApplied" class="clear-filter-btn" mat-raised-button color="accent"
            (click)="clearReportFilter()">
            Clear Filters
          </button>
          <div [id]="embedReportContainerId"></div>
        </div>
      </div>
      <div *ngIf="commentSection" class="col-3 comment-section">
        <h5 class="label">Comments
          <mat-icon class="close-btn" role="button" (click)="commentSection=false">close</mat-icon>
        </h5>

        <div class="comment-history" *ngIf="comments.length">
          <div *ngFor="let comment of comments">
            <div class="comment-body">
              <div *ngIf="isMyComment(comment)" class="bot-wrapper">
                <div class="right-chat">
                  <div class="right-icon">
                    <mat-icon fontSet="material-icons-outlined">
                      account_circle
                    </mat-icon>
                    <p class="mb-0" matTooltip="{{comment?.fullName}}">{{comment?.fullName}}</p>
                    <small>{{comment.createdAt | date:'short'}}</small>
                  </div>
                  <div class="container darker">
                    <p> {{comment.comment}}</p>
                  </div>
                </div>
              </div>
              <div *ngIf="!isMyComment(comment)" class="bot-wrapper">
                <div class="d-flex align-items-center">
                  <div class="right-icon d-flex flex-column align-items-center mr-2">
                    <mat-icon fontSet="material-icons-outlined">
                      account_circle
                    </mat-icon>
                    <p class="mb-0" matTooltip="{{comment?.fullName}}">{{comment?.fullName}}</p>
                    <small>{{comment.createdAt | date:'short'}}</small>
                  </div>
                  <div class="container response-back">
                    <p> {{comment.comment}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="comment-input w-100">
          <form action="" class="w-100">
            <mat-form-field class="w-100" floatLabel="never">
              <textarea [style.font-size]="'14px'" matInput placeholder="Comment . . ." aria-label="Search" rows="2"
                cdkAutosizeMinRows="2" cdkAutosizeMaxRows="3" [formControl]="comment" [mention]="userList"
                [mentionConfig]="mentionConfig"></textarea>
              <!-- (keydown.enter)="onKeydown($event)" (keyup.enter)="comment?.value && saveComment()" -->
            </mat-form-field>
          </form>
          <mat-icon class="cursor" color="accent" matSuffix (click)="comment?.value && saveComment()">send
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
