<div class="container-fluid mt-10">
  <form class="row m-0" [formGroup]="pmsForm">
    <div class="col-md-4">
      <strong>PR Title*</strong>
      <mat-form-field appearance="fill">
        <input type="text" placeholder="Enter title" matInput formControlName="prTitle">
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <strong>Location*</strong>
      <mat-form-field appearance="fill">
        <mat-select placeholder="Select Location" formControlName="locationId">
          <mat-option *ngFor="let location of locationList" [value]="location.locationId">{{ location.locationName
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <strong>Priority*</strong>
      <mat-form-field appearance="fill">
        <mat-select placeholder="Select priority" formControlName="requirementTypeId">
          <mat-option *ngFor="let requirement of requirementTypeList" [value]="requirement.requirementTypeId">{{
            requirement.requirementTypeDescription }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <strong>Type*</strong>
      <mat-form-field appearance="fill">
        <mat-select placeholder="Select type" formControlName="purchaseTypeId">
          <mat-option *ngFor="let purchaseType of purchaseTypeList" [value]="purchaseType.purchaseTypeId">{{
            purchaseType.purchaseDescription }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div formArrayName="items" class="w-100">
      <div class="row m-0">
        <div class="col-2">
          <strong>Name*</strong>
        </div>
        <div class="col-2">
          <strong>Quantity*</strong>
        </div>
        <div class="col-2">
          <strong>Price*</strong>
        </div>
        <div class="col-3">
          <strong>Specifications*</strong>
        </div>
        <div class="col-2">
          <strong>Reason*</strong>
        </div>
      </div>
      <div class="row m-0" *ngFor="let item of itemsArray.controls; let i=index" [formGroupName]="i">
        <div class="col-2">
          <mat-form-field appearance="fill">
            <mat-select placeholder="Select Name" formControlName="itemId">
              <mat-option *ngFor="let item of itemList" [value]="item.itemId">{{ item.itemName }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="fill">
            <input type="number" placeholder="Enter quantity" matInput formControlName="quantity">
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="fill">
            <input type="number" placeholder="Enter price" matInput formControlName="estimatedAmount">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="fill">
            <input type="text" placeholder="Enter specifications" matInput formControlName="itemSpecifications">
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="fill">
            <input type="text" placeholder="Enter reason" matInput formControlName="reason">
          </mat-form-field>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon color="warn" (click)="removeItem(i)" class="cursor-pointer"
            matTooltip="Remove Item">delete</mat-icon>
        </div>
      </div>
    </div>
  </form>
  <div class="d-flex justify-content-between align-items-start m-2">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center" *ngIf="!data.isEdit">
        <button mat-stroked-button color="accent" (click)="uploadFileBtn()">Choose File</button>
        <small class="mx-2">{{uploadedFile.name || 'No File Choosen'}}</small>
        <input class="hidden" type="file" id="{{'pms-create-pr-btn'+uniqueId}}" (change)="onFileSelect($event)">
      </div>
      <!-- <small class="m-1">Upload a copy of document explaining purchase reason</small>  -->
    </div>
    <div class="d-flex align-items-center">
      <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
      <button *ngIf="!data.isEdit" class="mx-2" mat-stroked-button color="accent" (click)="addItem()">Add More
        Items</button>
      <button *ngIf="!data.isEdit" mat-raised-button color="accent" (click)="submit()"
        [disabled]="pmsForm.invalid">Submit</button>
    </div>
  </div>
</div>
