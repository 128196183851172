import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';



@Component({
  selector: 'app-outlier-chart-dialog',
  templateUrl: './outlier-chart-dialog.component.html',
  styleUrls: ['./outlier-chart-dialog.component.css']
})
export class OutlierChartDialogComponent implements OnInit {

  dataSource: any = {
    chart: {
      caption: "",
      showhovereffect: "1",
      drawcrossline: "1",
      plottooltext: "<b>$seriesName : </b>$dataValue",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100"
    },
    categories: [
      {
        category: []
      }
    ],
    dataset: []
  };
  chartConfig: Object = {
    width: '900',
    height: '500',
    type: 'msline',
    dataFormat: 'json',
  };

  loadChartData: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<OutlierChartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.createChartData();
  }

  createChartData() {
    const chartData = this.data.chartData;
    const weekList = this.data.weekList.map(week => {
      return { label: `${moment(week.weeKStartDate).format('MM/DD/YYYY')}-W${week.weekOfYear}` }
    });

    const orgChartData = {
      seriesname: 'Original',
      initiallyHidden: 1,
      data: []
    };

    const adjChartData = {
      seriesname: 'Adjusted',
      data: []
    };
    const lowerSTDChartData = {
      seriesname: 'Lower STD',
      initiallyHidden: 1,
      data: []
    };
    const upperSTDChartData = {
      seriesname: 'Upper STD',
      initiallyHidden: 1,
      data: []
    };

    this.data.weekList.forEach(week => {
      orgChartData.data.push({ value: chartData[`${week.columnField}`] });
      adjChartData.data.push({ value: chartData[`${week.columnField}_adjusted`] });
      lowerSTDChartData.data.push({ value: chartData[`${week.columnField}_lower`] });
      upperSTDChartData.data.push({ value: chartData[`${week.columnField}_upper`] });
    });

    // const chartSeriesData = [];
    // chartData.forEach(series => {
    //   const dataValues = [];
    //   this.data.weekList.forEach(week => {
    //     dataValues.push({
    //       value: series[week.columnField]
    //     });
    //   });
    //   chartSeriesData.push({
    //     seriesname: series.saleValues,
    //     data: dataValues
    //   });
    // });

    this.dataSource.categories[0].category = weekList;
    this.dataSource.dataset = [orgChartData, adjChartData, lowerSTDChartData, upperSTDChartData];
    this.loadChartData = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
