import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ProcurementService } from 'src/services/procurement.service';

@Component({
  selector: 'pms-evaluation-vendor',
  templateUrl: './pms-evaluation-vendor.component.html',
  styleUrls: ['./pms-evaluation-vendor.component.scss'],
})
export class PmsEvaluationVendorComponent implements OnInit {
  vendorsList = [];
  vendorHistory = {
    evaulationDate:'',
    qualityDiscription: '',
    deliveryTimeDiscription: '',
    afterSaleServiceDiscription: '',
    warrantyCoverDiscription: '',
    creditTermsDiscription: '',
    orderCormormationDiscription: '',
    overAllEvaluationDiscription: '',
    otherDetail: '',
  };
  showVendorHistory = false;
  vendorSelected = false;
  selectedVenorId: number;
  evaluationForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private procurementService: ProcurementService
  ) {}

  ngOnInit(): void {
    this.fetchLookups();
  }

  fetchLookups() {
    this.procurementService
      .getVendorsListing('', '', '')
      .subscribe((res: any) => {
        this.vendorsList = res;
        this.initializeForm();
      });
  }
  initializeForm() {
    this.evaluationForm = this.formBuilder.group({
      vendorId: new FormControl('', Validators.required),
      quality: new FormControl('', Validators.required),
      deliveryTime: new FormControl('', Validators.required),
      afterSaleService: new FormControl('', Validators.required),
      warrantyCover: new FormControl('', Validators.required),
      creditTerms: new FormControl('', Validators.required),
      orderCormormation: new FormControl('', Validators.required),
      overAllEvaluation: new FormControl('', Validators.required),
      otherDetail: new FormControl('', Validators.required),
    });
  }
  vendorSelectionChange(vendorId) {
    this.selectedVenorId = vendorId;
    if (vendorId) {
      this.vendorSelected = true;
      this.showVendorHistory=false;
    }
  }
  showHistory() {
    this.procurementService
      .getVendorEvaluation(this.selectedVenorId)
      .subscribe((res: any) => {
        this.vendorHistory= res;
      });

    this.showVendorHistory = true;
  }

  onSubmit() {
    this.spinner.show();
    if (this.evaluationForm.valid) {
      const payload = this.evaluationForm.value;
      this.procurementService
        .addVendorEvalution(payload)
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe((data) => {
          this.toastrService.success('Evaluation saved successfully.');
          this.evaluationForm.reset();
        });
    }
  }
}
