<div class="container-fluid promo-manager-page">

  <div class="row product-grid-filter-row mt-10">
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <input matInput type="search" (keydown.enter)="applyFilterEvent()" placeholder="Seasonality Name"
          [(ngModel)]="seasonalityName">
        <mat-icon class="search-action-icon" (click)="applyFilterEvent()" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>

    <div class="product-filter-field">
      <div class="form-group multi-select d-flex w-100 multiselect-dropdown">
        <ng-multiselect-dropdown class="h-100" [placeholder]="'Seasonality Level'" [settings]="levelDropdownSettings"
          [data]="seasonalityLevelList" [(ngModel)]="selectedLevels" (onSelect)="levelChange()"
          (onDeSelect)="levelChange()">
        </ng-multiselect-dropdown>
      </div>
    </div>

    <div class="product-filter-field">
      <div class="form-group multi-select d-flex w-100 multiselect-dropdown">
        <ng-multiselect-dropdown class="h-100" [placeholder]="'Start Month'" [settings]="monthsDropdownSettings"
          [data]="seasonalityMonthList" [(ngModel)]="seasonalityStartMonth" (onSelect)="startMonthsChange()"
          (onDeSelect)="startMonthsChange()">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="product-filter-field">
      <div class="form-group multi-select d-flex w-100 multiselect-dropdown">
        <ng-multiselect-dropdown class="h-100" [placeholder]="'End Month'" [settings]="monthsDropdownSettings"
          [data]="seasonalityMonthList" [(ngModel)]="seasonalityEndMonth" (onSelect)="endMonthsChange()"
          (onDeSelect)="endMonthsChange()">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="product-filter-field">
      <mat-form-field appearance="fill">
        <mat-select placeholder="Status" [(ngModel)]="status" (selectionChange)="applyFilterEvent()">
          <mat-option *ngFor="let status of statusList" [value]="status.statusId">{{status.statusName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-field">
      <div class="form-group multi-select d-flex w-100 multiselect-dropdown">
        <ng-multiselect-dropdown class="h-100" placeholder="{{_dataService.getDataById(1)}}"
          [settings]="accountsDropdownSettings" [data]="accountList" [(ngModel)]="selectedAccounts"
          (onSelect)="accountsChange()" (onDeSelect)="accountsChange()">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="product-filter-clear-btn mr-2">
      <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
    </div>
  </div>

  <div class="grid-action-btn-bar">
    <div class="seasonality-label">
      <mat-label><strong>Seasonality List</strong></mat-label>
    </div>
    <button style="margin-left:8px" matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>
    <div class="grid-actions inline-flex">
      <div *ngIf="selectedRows.length" class="row-info">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{totalRows.length}}</strong>
        </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{totalRows.length}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>


    <div class="seasonality-label">
      <mat-slide-toggle [(ngModel)]="isAnomalous" (change)="applyFilterEvent()">Show Only Anomalous
      </mat-slide-toggle>
    </div>

    <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
      (click)="onExportGridData()">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>

    <button mat-flat-button color="accent" class="ml-10" (click)="openPendingSeasonalityDialog()">Pending
      Seasonalities</button>

    <button mat-flat-button color="accent" class="ml-10" [matMenuTriggerFor]="menu">Action</button>
    <mat-menu #menu="matMenu" style="justify-content: center;">
      <button mat-menu-item (click)="changeSeasonalityStatus(true)">Accept</button>
      <button mat-menu-item (click)="changeSeasonalityStatus(false)">Reject</button>
      <button mat-menu-item (click)="copySeasonality()">Copy</button>
      <button mat-menu-item (click)="deleteSeasonality()">Delete</button>
      <button mat-menu-item (click)="exportSeasonalityTemplate()">Export</button>
      <button mat-menu-item (click)="importSeasonality()">Import
        <input class="hidden" type="file" id="upload-custom-seasonality-data" name="upload-custom-seasonality-data"
          accept=".csv" (change)="validateUploadedCSV($event)">
      </button>

    </mat-menu>

    <button class="add-icon-btn ml-10" (click)="openSeasonalitySetupDetail()" matTooltip="Add Seasonality"
      mat-icon-button color="primary" type="button">
      <i class="material-icons">
        add_circle
      </i>
    </button>

  </div>

  <div class="all-show-grid forecast-engine-ag-grid">
    <ag-grid-angular #seasonalityGrid id="seasonality-data-grid" class="ag-theme-material seasonality-manager-grid"
       [columnDefs]="columnDefs" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef"
      [frameworkComponents]="frameworkComponents" [rowSelection]="rowSelection" [animateRows]="true"
      [rowModelType]="rowModelType" [serverSideStoreType]="serverSideStoreType" [pagination]="true" [suppressRowClickSelection]="true"
      [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
      (selectionChanged)="rowSelectionChanged($event)" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>


</div>
