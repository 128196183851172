<div class="example-container share-journey-table" tabindex="0">

  <table mat-table [dataSource]="dataSource" class="w-100">

    <!-- JourneyId Column -->
    <ng-container matColumnDef="journeyId">
      <th mat-header-cell *matHeaderCellDef> Journey Id </th>
      <td mat-cell *matCellDef="let element"> {{element?.journey?.journeyId}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element?.journey?.journeyName}} </td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef> Created On </th>
      <td mat-cell *matCellDef="let element"> {{element.journey.createdOn}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon style="cursor: pointer;" (click)="viewReport(element.journey, element.id)">visibility</mat-icon>
       </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  </div>
