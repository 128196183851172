
<mat-form-field class="w-100">
  <mat-label>Search here</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Search here" #input>
</mat-form-field>

<div class="example-container log-journey-table" tabindex="0">

  <table mat-table [dataSource]="dataSource" class="w-100" matSort>

    <!-- Time Column -->
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
      <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
    </ng-container>

    <!-- JourneyId Column -->
    <ng-container matColumnDef="journeyId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.journeyId}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="journeyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Journey </th>
      <td mat-cell *matCellDef="let element"> {{element.journeyName}} </td>
    </ng-container>


    <!-- User Column -->
    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
      <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="actionPerformed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let element"> {{element.actionPerformed}} </td>
    </ng-container>

    <!-- Product Column -->
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product </th>
      <td mat-cell *matCellDef="let element"> {{element.product}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.actionPerformed !=='Delete Journey'">
          <mat-icon *ngIf="element.journey.metaData" (click)="openJourney(element)" style="cursor: pointer;">visibility</mat-icon>
          <mat-icon *ngIf="!element.journey.metaData" style="cursor: pointer;" title="Journey has been deleted">visibility_off</mat-icon>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of journey logs"></mat-paginator>