import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { generateTitleDiv, undefinedImagePath } from 'src/modules/empower-module/helper';
import { EmpowerGamingService } from 'src/services/empower-services/empower-gaming.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';

@Component({
  selector: 'empower-partner-performance-in-cat-revenue',
  templateUrl: './empower-partner-performance-in-cat-revenue.component.html',
  styleUrls: ['./empower-partner-performance-in-cat-revenue.component.scss']
})
export class EmpowerPartnerPerformanceInCatRevenueComponent implements OnInit {

  @Input() data?: any;
  _unsubscribeAll = new Subject();
undefinedImagePath = '';

  xaxisCatgoryFirstList: any = [];
  xaxisCatgorySecondList: any = [];
  xaxisCatgoryThirdList: any = [];
  xaxisCatgoryForthList: any = [];
  dataSeriesFirst: any = [];
  dataSeriesSecond: any = [];
  dataSeriesThird: any = [];
  dataSeriesForth: any = [];

  pageComment: string;
  innerHtml = '';

  constructor(
    public empowerGamingService: EmpowerGamingService, private empowerExportService: EmpowerExportService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);
      this.innerHtml = generateTitleDiv(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const daxReponse = this.empowerGamingService.getPagesState(pageId);
    this.pageComment = this.empowerGamingService.getPageCommentState(pageId);
    if (daxReponse && daxReponse.length) {
      this.loadVisuals(daxReponse);
    } else {
      this.executePageDaxQuery();
    }
  }

  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.empowerGamingService.getDashboardState()
    };
    this.spinner.show();
    this.empowerGamingService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        this.emitExportEvent(true);
      }
      this.spinner.hide();
    },
      err => {
       this.undefinedImagePath = undefinedImagePath;
        this.spinner.hide();
      });
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }

  setPageState(pageId, daxData) {
    this.empowerGamingService.setPagesState(pageId, daxData);
  }

  loadVisuals(daxData) {
    this.emitExportEvent(true);
    this.loadFirstVisualData(daxData[0]);
    this.loadSecondVisualData(daxData[1]);
    this.loadThirdVisualData(daxData[2]);
    this.loadForthVisualData(daxData[3]);
  }

  loadFirstVisualData(visualData) {
    const dataList = _.orderBy(visualData.daxResponse, ['dIM_DATE[FISCAL_MONTH_NUMBER]'], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a['dIM_DATE[FISCAL_MONTH_NAME]'] } });
    const tySeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue]'] } });
    const lySeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue_LY]'] } });
    this.xaxisCatgoryFirstList = categoryList;
    this.dataSeriesFirst = [
      {
        seriesname: 'Total Revenue',
        data: tySeriesData
      },
      {
        seriesname: 'Total Revenue LY',
        data: lySeriesData,
        renderas: "line",
      }
    ];
  }

  loadSecondVisualData(visualData) {
    const dataList = _.orderBy(visualData.daxResponse, ['dIM_DATE[FISCAL_MONTH_NUMBER]'], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a['dIM_DATE[FISCAL_MONTH_NAME]'] } });
    const tySeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue]'] } });
    const lySeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue_LY]'] } });
    this.xaxisCatgorySecondList = categoryList;
    this.dataSeriesSecond = [
      {
        seriesname: 'Total Revenue',
        data: tySeriesData
      },
      {
        seriesname: 'Total Revenue LY',
        data: lySeriesData,
        renderas: "line",
      }
    ];
  }

  loadThirdVisualData(visualData) {
    const dataList = _.orderBy(visualData.daxResponse, ['dIM_DATE[FISCAL_MONTH_NUMBER]'], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a['dIM_DATE[FISCAL_MONTH_NAME]'] } });
    const tySeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue]'] } });
    const lySeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue_LY]'] } });
    this.xaxisCatgoryThirdList = categoryList;
    this.dataSeriesThird = [
      {
        seriesname: 'Total Revenue',
        data: tySeriesData
      },
      {
        seriesname: 'Total Revenue LY',
        data: lySeriesData,
        renderas: "line",
      }
    ];
  }

  loadForthVisualData(visualData) {
    const dataList = _.orderBy(visualData.daxResponse, ['dIM_DATE[FISCAL_MONTH_NUMBER]'], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a['dIM_DATE[FISCAL_MONTH_NAME]'] } });
    const tySeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue]'] } });
    const lySeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue_LY]'] } });
    this.xaxisCatgoryForthList = categoryList;
    this.dataSeriesForth = [
      {
        seriesname: 'Total Revenue',
        data: tySeriesData
      },
      {
        seriesname: 'Total Revenue LY',
        data: lySeriesData,
        renderas: "line",
      }
    ];
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(4, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.empowerGamingService.setPageCommentState(pageId, comment);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
