import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ChildItemService, ItemManagerService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { INVALID_TEAM, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { MasterGridDialogComponent } from '../master-grid-dialog/master-grid-dialog.component';


@Component({
  selector: 'app-item-dialog',
  templateUrl: './item-dialog.component.html',
  styleUrls: ['./item-dialog.component.css']
})
export class ItemDialogComponent implements OnInit {
  @ViewChild('itemFile')
  itemFile: ElementRef;

  isEditMode: boolean = false;
  classList = [];
  subClassList = [];
  ratingList = [];
  accountList = [];

  userTeam: any = '';
  isRetailerTeam: boolean = false;
  disabledAccountSelection: boolean = false;

  selectedMasterRow: any;
  imageSrc: string;
  itemForm = new FormGroup({
    itemDescription: new FormControl(''),
    subClassId: new FormControl('', Validators.required),
    productTitleId: new FormControl(''),
    upc: new FormControl('', [Validators.required, Validators.pattern(/^\d{12}$/)]),
    retekCost: new FormControl('', Validators.required),
    accountId: new FormControl(''),
    regRetailPrice: new FormControl(null),
    releaseDate: new FormControl('', Validators.required),
    ratingId: new FormControl(''),
    msrp: new FormControl(''),
    classId: new FormControl('', Validators.required),
    digitalCode: new FormControl(null),
    expirationDate: new FormControl(null),
    filePath: new FormControl(''),
    externalItemId: new FormControl(null),
    subClassName: new FormControl(''),
    subClassCode: new FormControl('')
  });

  constructor(public dialogRef: MatDialogRef<ItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private childItemService: ChildItemService,
    private itemManagerService: ItemManagerService,
    public toastr: NgxToasterService,
    private usersService: UsersService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit() {
    this.initiateValues();
    this.applyAsyncValidators();
    this.setRoleBaseAccess();
  }

  get upc() { return this.itemForm.get('upc'); }

  get getSubclassList() {
    if (this.itemForm && this.subClassList.length) {
      const classId = this.itemForm.get('classId').value;
      const subClassId = this.itemForm.get('subClassId').value;
      if (classId) {
        const subClassList = _.filter(this.subClassList, a => a.classId === classId);
        if (subClassId) {
          if (!_.find(subClassList, a => a.subClassId === subClassId)) {
            this.itemForm.get('subClassId').setValue(null);
          }
        }
        return subClassList;
      } else {
        return this.subClassList;
      }
    } else {
      return this.subClassList;
    }
  }

  applyAsyncValidators() {
    setTimeout(() => {
      this.itemForm.controls['upc'].setAsyncValidators([this.childItemService.upcValidator()]);
    }, 0);
  }

  initiateValues() {
    this.itemManagerService.getClassListSubject().subscribe((classList: any) => {
      this.classList = classList;
    });
    this.itemManagerService.getSubClassListSubject().subscribe((subClassList: any) => {
      this.subClassList = subClassList;
    });
    this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
      this.accountList = accountList;
    });
    this.itemManagerService.getRatingListSubject().subscribe((ratingList: any) => {
      this.ratingList = ratingList;
    });
    if (this.data.isEdit) {
      this.isEditMode = this.data.isEdit;
      this.itemForm.patchValue(this.data.formData);
      this.itemForm.get('subClassId').setValue(null);
      this.imageSrc = _.get(this.data.formData, 'document.documentPath');
    } else if (this.data.selectedMasterRow) {
      this.selectedMasterRow = this.data.selectedMasterRow;
      this.setFormValues();
    }
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        const accountId = this.usersService.getTeamRoleAccount();
        this.itemForm.get('accountId').setValue(accountId.toString());
        this.disabledAccountSelection = true;
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  onClassChange() {
    const value = this.itemForm.get('classId').value;
    this.itemForm.get('classId').setValue(value);
    this.setItemTitleName();
  }

  onSubClassChange() {
    const subClassId = this.itemForm.get('subClassId').value;
    this.itemForm.get('subClassId').setValue(subClassId);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openRemoveDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: this.itemForm.value.itemDescription }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeChildItem();
      }
    });
  }

  removeChildItem() {
    this.childItemService.DeleteChildItem(this.data.formData.childItemId).subscribe((res: any) => {
      this.toastr.success('Success', 'Child Item Deleted Successfully.');
      this.dialogRef.close({ isDeleted: true, record: { childItemId: this.data.formData.childItemId } });
    }, err => {
      this.toastr.error('Error', 'Child Item Deletion Failed.');
      console.log(err);
    });
  }

  onFileChange(event) {
    if (this.data.isEdit) {
      this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: { confirmationMessage: `Are you sure want to update Child Item image?` }
      });

      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.fileReader(event, true);
        }
      });
    } else {
      this.fileReader(event);
    }
  }

  fileReader(event, uploadFile = false) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.itemForm.patchValue({
          filePath: (reader.result as any)
        });
        uploadFile && this.updateChildItemImage();
      }
    }
  }

  updateChildItemImage() {
    const body = {
      ...this.data.formData,
      ...this.itemForm.value
    }
    this.childItemService.UpdateChildItemImage(body).subscribe((res: any) => {
      this.toastr.success('Success', 'Item image updated successfully.');
    }, err => {
      this.toastr.error('Error', 'Item image update failed.');
    });
  }

  clearItemImage() {
    if (this.data.isEdit) {
      if (_.get(this.data.formData, 'document.documentPath')) {
        this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: { headerName: this.itemForm.value.itemDescription }
        });

        this.confirmationDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetFile();
            this.removeChildItemImage();
          }
        });
      }
    } else {
      this.resetFile();
    }
  }

  resetFile() {
    this.imageSrc = '';
    this.itemForm.patchValue({
      filePath: ''
    });
    this.itemFile.nativeElement.value = "";
  }

  removeChildItemImage() {
    const body = {
      ...this.data.formData,
      ...this.itemForm.value
    }
    this.childItemService.UpdateChildItemImage(body).subscribe((res: any) => {
      this.toastr.success('Success', 'Item image removed successfully.');
      this.resetFile();
    }, err => {
      this.toastr.error('Error', 'Item image removal failed.');
    });
  }

  openMasterGrid() {
    const dialogRef = this.dialog.open(MasterGridDialogComponent, {
      data: {
        isDialoag: true,
        isMasterPage: this.data.isMasterPage
      },
      width: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.selectedMasterRow) {
          this.selectedMasterRow = result.selectedMasterRow;
          this.setFormValues();
        }
      }
    }, err => {
      console.log(err);
    });
  }

  setFormValues() {
    this.setItemTitleName();
    this.itemForm.patchValue(this.selectedMasterRow);
  }

  setItemTitleName() {
    let productTitleName = _.get(this.selectedMasterRow, 'productTitleName', '');
    const classId = this.itemForm.get('classId').value;
    let itemTitleName = '';
    if (classId) {
      const className = this.classList.find(a => a.classId == classId).className;
      itemTitleName = `${className} ${productTitleName}`;
    } else {
      itemTitleName = `${productTitleName}`;
    }
    this.itemForm.get('itemDescription').setValue(itemTitleName);
  }



  onSubmit() {
    const subClassId = this.itemForm.get('subClassId').value;
    const classId = this.itemForm.get('classId').value;
    const ratingId = this.itemForm.get('ratingId').value;
    const selectedSubClass = this.subClassList.find(a => a.subClassId == subClassId);
    if (!classId) {
      this.itemForm.get('classId').setValue(selectedSubClass.classId);
    }
    // const selectedClass = this.classList.find(a => a.classId === classId || selectedSubClass.classId);
    const selectedClass = _.find(this.classList, a => a.classId === classId || (selectedSubClass && selectedSubClass.classId));
    if (this.data.isEdit) {
      const updatedItem = {
        ...this.data.formData,
        ...this.itemForm.value,
        subClassName: selectedSubClass ? selectedSubClass.subClassName : '',
        subClassCode: selectedSubClass ? selectedSubClass.subClassCode : '',
        className: selectedClass ? selectedClass.className : '',
        classCode: selectedClass ? selectedClass.classCode : '',
        ratingName: ratingId ? this.ratingList.find(a => a.ratingId === ratingId).ratingName : ''
      };
      this.childItemService.UpdateChildItem(updatedItem).subscribe((res: any) => {
        this.dialogRef.close({ isEdit: this.data.isEdit, record: updatedItem });
        this.toastr.success('Success', 'Item Updated Successfully.');
      }, err => {
        this.toastr.error('Error', 'Item Update Failed.');
        console.log(err);
      });
    } else {
      this.childItemService.CreateChildItem({ ...this.selectedMasterRow, ...this.itemForm.value }).subscribe((res: any) => {
        const newItem = {
          ...this.itemForm.value,
          ...res,
          subClassName: selectedSubClass ? selectedSubClass.subClassName : '',
          subClassCode: selectedSubClass ? selectedSubClass.subClassCode : '',
          className: selectedClass ? selectedClass.className : '',
          classCode: selectedClass ? selectedClass.classCode : '',
          ratingName: ratingId ? this.ratingList.find(a => a.ratingId === ratingId).ratingName : ''
        };
        this.dialogRef.close({ isEdit: this.data.isEdit, record: newItem });
        this.toastr.success('Success', 'Item Added Successfully.');
      }, err => {
        this.toastr.error('Error', 'Item Insert Failed.');
        console.log(err);
      });
    }
  }

}
