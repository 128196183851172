import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'fusion-ms-line-area-chart',
  templateUrl: './ms-line-area-chart.component.html',
  styleUrls: ['./ms-line-area-chart.component.scss']
})
export class FusionMSLineAreaChartComponent implements OnInit, OnChanges {
  @Input() chartCaption?: string;
  @Input() xaxisCatgoryList: string[];
  @Input() dataSeries: any[];
  @Input() height?: string = '200px';
  @Input() xAxisName?: string = '';
  @Input() numberprefix?: string = '';

  /*

    Category Sample Object 
    {
      label: 'abc'
    }

    Data Series Sample Object
    {
      seriesname: "Original",
      color: "#ff0000",
      data: []
    }

    Data Series Data Sample Object 
    {
      value: 123
    }
  */

  dataSource: any = {
    chart: {
      caption: "",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100",
      chartLeftMargin: "0",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      canvasPadding: "0",
      showBorder: "1",
      borderColor: "#8D009D",
      borderThickness: "2",
      borderAlpha: "20",
      plotHighlightEffect: "fadeout",
      legendPosition: "top-left",
      // legendPosition: "absolute",
      // legendXPosition: "40",
      // legendYPosition: "5",
    },
    categories: [
      {
        category: []
      }
    ],
    dataset: []
  };

  chartConfig: Object = {
    type: 'mssplinearea',
    dataFormat: 'json',
  };


  constructor() {

  }

  ngOnInit() {
    this.initChartDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initChartDataSource();
  }

  initChartDataSource() {
    this.dataSource.chart.caption = this.chartCaption;
    this.dataSource.chart.xAxisName = this.xAxisName;
    this.dataSource.chart.numberprefix = this.numberprefix;
    this.dataSource.categories[0].category = this.xaxisCatgoryList;
    this.dataSource.dataset = this.dataSeries;
  }

}
