import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClusteringForecastService {

  summaryStatsChangeSubject = new Subject();
  adjustMembershipSubject = new Subject();

  constructor(private httpClient: HttpClient) { }

  // SETTINGS TAB
  fetchAlreadyUploadedFiles(userId, page = 1) {
    return this.httpClient.get(`${environment.ClusterAPI}/fetch-user-files/?user_id=${userId}&page=${page}`);
  }

  fetchFileDataById(fileName, userId, limit = 10, page = 1, matrixFaultData = false) {
    return this.httpClient.get(`${environment.ClusterAPI}/fetch-user-data/?user_id=${userId}&file_name=${fileName}&limit=${limit}&page=${page}&matrix_fault_data=${matrixFaultData}`);
  }

  getSASToken() {
    return this.httpClient.get(`${environment.ClusterAPI}/fetch-sas-token/`)
      .pipe(map((response: any) => {
        return response.payload;
      }));
  }

  uploadFileForProcessing(obj: FormData) {
    return this.httpClient.post(`${environment.ClusterAPI}/user-data-upload/`, obj);
  }

  updateFileData(obj: FormData) {
    return this.httpClient.put(`${environment.ClusterAPI}/fetch-user-data/`, obj);
  }

  clearData(fileId: number) {
    return this.httpClient.delete(`${environment.ClusterAPI}/file-processing/?fileId=${fileId}`);
  }

  runClustering(obj: FormData) {
    return this.httpClient.post(`${environment.ClusterAPI}/run-data-clustering/`, obj);
  }

  deleteAllFiles(obj = {}) {
    return this.httpClient.post(`${environment.ClusterAPI}/delete-all-user-data-files/`, obj);
  }

  deleteFile(obj = {}) {
    return this.httpClient.post(`${environment.ClusterAPI}/delete-clustering-file/`, obj);
  }

  // OUTPUT TAB
  fetchSummaryStatisticForTable(obj = {}) {
    return this.httpClient.get(`${environment.ClusterAPI}/summary-statistics-data/?data=${JSON.stringify(obj)}`);
  }

  // file_name: this.fileName,
  // user_id: this.userObj.userId,
  // cluster_id: clusterId,
  // run_tab_index: this.runIndex == 'RUN_0' ? "Cluster_Id_adj" : this.runIndex,
  // search_field: this.objectToCluster,
  // search_value: search,
  // version_no: this.versionNo

  fetchClusterColumnData(obj) {
    return this.httpClient.post(`${environment.ClusterAPI}/fetch-vertical-clusters/`, obj);
  }

  adjustMembership(obj: FormData) {
    return this.httpClient.post(`${environment.ClusterAPI}/adjustment-membership/`, obj);
  }

  saveRun(obj = {}) {
    return this.httpClient.post(`${environment.ClusterAPI}/save-data-run/`, obj);
  }

  // RUN TAB
  fetchScatterPlotData(obj: FormData) {
    return this.httpClient.post(`${environment.ClusterAPI}/scatter-plot/`, obj);
  }

  downloadClusterColumnsData(obj: FormData) {
    return this.httpClient.post(`${environment.ClusterAPI}/download-clusterdata-file/`, obj);
  }

  // COMPARISON TAB
  fetchSpiderChartData(obj: {}) {
    return this.httpClient.get(`${environment.ClusterAPI}/spider-chart/?data=${JSON.stringify(obj)}`);
  }

  // Update Run Tab Name
  updateRunTabMetadata(tabObj: {}) {
    return this.httpClient.post(`${environment.ClusterAPI}/update-run-tab/`, tabObj);
  }

  // Delete Run Tab Name
  deleteRun(tabObj: {}) {
    return this.httpClient.post(`${environment.ClusterAPI}/delete-a-cluster-run/`, tabObj);
  }

  // File progress status
  checkFileStatus(userId) {
    return this.httpClient.get(`${environment.ClusterAPI}/data-module-clustering-progress/?user_id=${userId}`);
  }

  // Fetch file details
  fetchFileDetails(userId, fileName) {
    return this.httpClient.get(`${environment.ClusterAPI}/get-single-file/?user_id=${userId}&file_name=${fileName}`);
  }

  // GET FAULTY COLUMNS
  getFaultyColumns(userId, fileName, versionNo) {
    return this.httpClient.get(`${environment.ClusterAPI}/get-faulty-columns/?user_id=${userId}&file_name=${fileName}&version_no=${versionNo}`);
  }

  resetToAlgo(obj = {}) {
    return this.httpClient.post(`${environment.ClusterAPI}/reset-to-algo/`, obj);
  }
}
