import {  ColumnApi, GridApi, GridOptions, ServerSideStoreType } from 'ag-grid-enterprise';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { ConfigurationService } from 'src/services/configuration.service';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';
import { getFilterModel, getSortModel } from 'src/common/util/util';
import { SharedDataService } from 'src/services/shared-data.service';
import { defaultColDef, getPendingSeasonalityColDefs } from '../../ag-grid/grid-options';
import { SeasonalitySetupComponent } from '../../seasonality-setup/seasonality-setup.component';
import { EditSeasonalityComponent } from '../../edit-seasonality/edit-seasonality.component';

@Component({
  selector: 'app-pending-seasonality',
  templateUrl: './pending-seasonality.component.html',
  styleUrls: ['./pending-seasonality.component.css']
})
export class PendingSeasonalityComponent implements OnInit, AfterViewInit, OnDestroy {
  paginationPageSize = 20;
  cacheBlockSize = 20;


  rowModelType = 'serverSide';
  serverSideStoreType: any = 'partial';
  public gridApi: GridApi;
  bottomHiddenGridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  rowSelection = 'multiple';
  public defaultColDef :any;
  public columnDefs = getPendingSeasonalityColDefs({}, this);
  selectedRows: any = [];
  totalRows: number = 0;

  bottomDataGridOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: defaultColDef,
    rowHeight: 40,
    isRowSelectable: function (rowNode) {
      return rowNode.data ? rowNode.data.saleValues === 'Adjusted' : false;
    },
    rowClassRules: {
      'adjusted-row': `data.saleValues === 'Adjusted'`
    },
    tooltipShowDelay: 100,
  };
  groupDisplayType = 'multipleColumns';

  public actionSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PendingSeasonalityComponent>,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public seasonalityService: SeasonalityService,
    public forecastLookupService: ForecastLookupService,
    public confirmationDialogRef: MatDialogRef<SeasonalitySetupComponent>,
    public _dataService: SharedDataService,
  ) { }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  ngOnInit() {
    this.listenSubscriptions();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createDataSource();
    }, 10);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createDataSource() {
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody: any = {
          // 0 base index of page number
          offSet: params.request.startRow || 0,
          pageSize: this.paginationPageSize
        };

        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);
        
        requestBody.offSet == 0 && this.deselectNode();

        this.seasonalityService
          .GetPendingSeasonalityList(requestBody)
          .pipe(
            tap(() => this.gridApi.hideOverlay())
          )
          .subscribe((response: any) => {
            params.successCallback(response.data, response.totalRecord);
            this.totalRows = response.totalRecord;
            response.totalRecord == 0 && this.gridApi.showNoRowsOverlay();
          });
      },
    };
    this.gridApi.setServerSideDatasource(datasource);
  }

  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  listenSubscriptions() {
    this.actionSubscription = this.seasonalityService.getSeasonalityGridActionCellSubject().subscribe(data => {
      if (data) {
        const seasonalityData = {
          isEdit: true,
          isCopyOperation: false,
          seasonalityData: data.seasonalityData
        };
        this.openSeasonalityEditView(seasonalityData);
      }
    });
  }

  openSeasonalityEditView(data) {
    const editSeasonalityComponent = {
      menuId: '454',
      name: 'Seasonality Editor',
      selector: 'app-edit-seasonality',
      displayName: 'Seasonality Editor',
      module: 'ForecastEnginModule',
      data: data,
      component: EditSeasonalityComponent,
    }
    this.configurationService.menuRendererSubject.next(editSeasonalityComponent);
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  clearGridFilters() {
    this.gridApi.setFilterModel(null);
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select'],
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getGridData() {
    const gridData = _.map(_.get(this.gridApi, 'rowModel.nodeManager.rowNodes', []), a => a && a.data);
    return gridData.filter(item => item);
  }



  onExportGridData() {
    if (!this.totalRows) return;
    this.spinner.show();
    const sortModel = this.colApi.getColumnState();
    const filterModel = this.gridApi.getFilterModel();
    const requestBody: any = {
      // 0 base index of page number
      offSet: 0,
      pageSize: this.totalRows
    };
    requestBody.filterModel = getFilterModel(filterModel);
    requestBody.sortModel = getSortModel(sortModel);
    this.seasonalityService.GetPendingSeasonalityList(requestBody)
      .subscribe((response: any) => {
        const gridData = response;
        this.exportAsCSV(gridData);
        this.spinner.hide();
      },
        err => {
          this.spinner.hide();
        }
      );
  }
exportAsCSV(gridData){
  this.bottomHiddenGridApi.setRowData(gridData.data);
  const csvExportParams = gridDataExportParams({
    fileName: 'Pending-Seasonalities',
    colDefs: this.columnDefs,
    allColumns: false,
    specialCharactersFields: ['seasonalityItems'],
    columnKeys: _.map(this.columnDefs, a => a.field),

  });
  this.bottomHiddenGridApi.exportDataAsCsv(csvExportParams);
  // this.gridApi.exportDataAsCsv(csvExportParams);

}


  openSeasonalitySetupDetail(dataList: any = []) {
    if (dataList.length > 0 && dataList.length <= 100) {
      const data = {
        isSeasonalityPendingOperation: true,
        seasonalityData: dataList
      };
      this.openSeasonalitySetupDetailDialog(data);
    } else {
      this.toastr.warning('Warning', 'Please select combinations between 1 to 100.');
    }
  }

  openSeasonalitySetupDetailDialog(data = {}): void {
    // tslint:disable-next-line: no-use-before-declare

    this.confirmationDialogRef = this.dialog.open(SeasonalitySetupComponent, {
      width: '1200px',
      disableClose: true,
      data: data
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }


  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }
  onBottomHiddenGridReady(params) {
    this.bottomHiddenGridApi = params.api;
  }

  ngOnDestroy(): void {
    this.actionSubscription.unsubscribe();
  }

}
