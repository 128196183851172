export const getNumericCellEditor = (isClusteringGrid = false) => {

  function NumericCellEditor() { }
  NumericCellEditor.prototype.init = function (params) {
    this.focusAfterAttached = params.cellStartedEdit;
    this.eInput = document.createElement('input');
    // this.eInput.type = 'number';
    this.eInput.style.width = '100%';
    this.eInput.style.height = '100%';
    this.eInput.value = params.value || null;
    this.eInput.onkeydown = (e) => {
      if (e.keyCode == 9) return; // TAB PRESSED
      if (e.keyCode == 8 ||
        e.keyCode == 9 ||
        e.keyCode == 13 ||
        e.keyCode == 46 ||
        e.keyCode == 110 ||
        e.keyCode == 190 ||
        (e.keyCode >= 37 && e.keyCode <= 40) ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (((e.keyCode == 110 || e.keyCode == 190) && this.eInput.value && this.eInput.value.includes('.'))
          || e.shiftKey) {
          e.stopPropagation();
          e.preventDefault();
        }
      } else {
        if ((e.keyCode == 109 || e.keyCode == 189) && this.eInput.value.length == 0 ) {
        }
        else {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    };
  };
  NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
  };
  NumericCellEditor.prototype.afterGuiAttached = function () {
    if (this.focusAfterAttached) {
      this.eInput.focus();
      this.eInput.select();
    }
  };
  NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
  };
  NumericCellEditor.prototype.isCancelAfterEnd = function () { };
  NumericCellEditor.prototype.getValue = function () {
    if (isClusteringGrid) return this.eInput.value || null;
    else return this.eInput.value ? Number(this.eInput.value) : null;
  };
  NumericCellEditor.prototype.focusIn = function () {
    const eInput = this.getGui();
    eInput.focus();
    eInput.select();
  };
  NumericCellEditor.prototype.focusOut = function () {
  };
  return NumericCellEditor;
}