<div class="dialog-close-btn">
  <button mat-icon-button (click)="dialogRef.close(isPartialSave)">
    <i class="material-icons">
      close
    </i>
  </button>
</div>
<div class="container-fluid promo-manager-detail-page">
  <div class="d-flex">
    <mat-label><strong> Seasonality Setup </strong></mat-label>
    <div class="alert alert-info ml-3" *ngIf="checkWeekDiff">
      No. of Weeks: {{getWeeksDiff}}
    </div>
  </div>
  <div class="item-wrapper m-3">
    <form class="promo-form" [formGroup]="seasonalityForm">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="seasonalityType">Seasonality Type *</label>
            <mat-radio-group class="inline-flex radio-btn-grp w-100" labelPosition="before" name="seasonalityType"
              formControlName="seasonalityType" (change)="seasonalityTypeChange($event)">
              <mat-radio-button value="static">Static</mat-radio-button>
              <mat-radio-button value="rolling">Rolling</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="creationType">Creation Type *</label>
            <mat-radio-group class="inline-flex radio-btn-grp w-100" labelPosition="before" name="creationType"
              formControlName="creationType">
              <mat-radio-button value="aggregate">Aggregate</mat-radio-button>
              <mat-radio-button value="standalone">Standalone</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="seasonalityLevelId">Seasonality Level</label>
            <mat-select name="seasonalityLevelId" formControlName="seasonalityLevelId"
              (selectionChange)="setHierarchiesBySeasonalityLevel()">
              <mat-option *ngFor="let seasonalityLevel of seasonalityLevelList"
                [value]="seasonalityLevel.seasonalityLevelId">
                {{seasonalityLevel.seasonalityLevelDescription}}
              </mat-option>
            </mat-select>
          </div>
          <div
            *ngIf="seasonalityForm.controls['seasonalityLevelId'].invalid  && (seasonalityForm.controls['seasonalityLevelId'].dirty || seasonalityForm.controls['seasonalityLevelId'].touched)"
            class="alert alert-danger">
            Please select the Seasonality Level.
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="namePrefix">Name Prefix</label>
            <input matInput type="text" name="namePrefix" formControlName="namePrefix" />
          </div>
          <div
            *ngIf="seasonalityForm.controls['namePrefix'].invalid  && (seasonalityForm.controls['namePrefix'].dirty || seasonalityForm.controls['namePrefix'].touched)"
            class="alert alert-danger">
            Please enter the Prefix Name.
          </div>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="seasonalityType.value === 'static'">
          <div class="col-6">
            <div class="form-group">
              <label for="startWeekDate">Start Week*</label>
              <mat-form-field>
                <input readonly matInput [matDatepickerFilter]="weekDayFilter" [matDatepicker]="startDatePicker"
                  name="startWeekDate" formControlName="startWeekDate" (dateChange)="startDateChange($event)"
                  [max]="startDateMax">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              *ngIf="seasonalityForm.controls['startWeekDate'].invalid  && (seasonalityForm.controls['startWeekDate'].dirty || seasonalityForm.controls['startWeekDate'].touched)"
              class="alert alert-danger">
              Please select the Start Week Date.
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="endWeekDate">End Week*</label>
              <mat-form-field>
                <input readonly matInput [matDatepickerFilter]="weekDayFilter" [matDatepicker]="endDatePicker"
                  name="endWeekDate" formControlName="endWeekDate" (dateChange)="endDateChange($event)"
                  [max]="endDateMax" [min]="endDateMin">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              *ngIf="!isValidWeeks || (seasonalityForm.controls['endWeekDate'].invalid  && (seasonalityForm.controls['endWeekDate'].dirty || seasonalityForm.controls['endWeekDate'].touched))"
              class="alert alert-danger">
              End date is required and should be minimum 52 and maximum 156 weeks older from start date.
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="seasonalityType.value!== 'static'">
          <div class="col-6">
            <div class="form-group" *ngIf="seasonalityType.value!== 'static'">
              <label for="rollingWeek">Rolling Weeks</label>
              <mat-form-field>
                <input matInput type="number" name="rollingWeek" formControlName="rollingWeek" />
              </mat-form-field>
            </div>
            <div *ngIf="seasonalityForm.controls['rollingWeek'].invalid" class="alert alert-danger">
              Rolling week should be minimun 52 and maximum 156 weeks older.
            </div>
          </div>
        </ng-container>
      </div>
      <!-- START NEW Product Hierarchy Grid Selection  -->
      <div class="row">
        <div class="col-12">
          <table class="seasonality-tbl">
            <thead *ngIf="seasonalityHierarchyList.length">
              <tr>
                <th>{{_dataService.getDataById(1)}}</th>
                <th *ngIf="validateSeasonalityLevelGridControls('brand')">{{_dataService.getDataById(2)}}</th>
                <th *ngIf="validateSeasonalityLevelGridControls('productGroup')">{{_dataService.getDataById(3)}}</th>
                <th *ngIf="validateSeasonalityLevelGridControls('sku')">{{_dataService.getDataById(5)}}</th>
                <th *ngIf="validateSeasonalityLevelGridControls('sku')">{{_dataService.getDataById(4)}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody (scroll)="removeAutocompleteFocus()">
              <tr *ngFor="let item of seasonalityHierarchyList; let i = index">
                <td class="w-10">
                  <input [matAutocomplete]="mapretailerauto" [(ngModel)]="item.retailer"
                    [ngClass]="{'error-field': !validSeasonalityHierarchyList[i].retailer }"
                    [ngModelOptions]="{standalone: true}" (input)="_filterRetailerLookupItems($event, i)"
                    (focus)="onRetailerFocus($event, i)" [title]="item.retailer ? item.retailer : ''" />

                  <mat-autocomplete [displayWith]="displayRetailerFn" #mapretailerauto="matAutocomplete"
                    (optionSelected)="retailerChange($event.option.value, i)">
                    <mat-option *ngFor="let item of filteredRetailerLookupItems | async" [value]="item">
                      {{item.retailer}}
                    </mat-option>
                  </mat-autocomplete>
                </td>

                <td class="w-10" *ngIf="validateSeasonalityLevelGridControls('brand')">
                  <input [(ngModel)]="item.brand" [ngModelOptions]="{standalone: true}"
                    [ngClass]="{'error-field': !validSeasonalityHierarchyList[i].brand }"
                    [matAutocomplete]="mapbrandauto" (input)="_filterBrandLookupItems($event, i)"
                    (focus)="onBrandFocus($event, i)" [title]="item.brand ? item.brand : ''" />
                  <mat-autocomplete [displayWith]="displayBrandFn" #mapbrandauto="matAutocomplete"
                    (optionSelected)="brandChange($event.option.value, i)">
                    <mat-option *ngFor="let item of filteredBrandLookupItems | async" [value]="item">
                      {{item.brand}}
                    </mat-option>
                  </mat-autocomplete>
                </td>

                <td class="w-10" *ngIf="validateSeasonalityLevelGridControls('productGroup')">
                  <input [(ngModel)]="item.productGroup" [ngModelOptions]="{standalone: true}"
                    [matAutocomplete]="mapproductGroupauto"
                    [ngClass]="{'error-field': !validSeasonalityHierarchyList[i].productGroup }"
                    (input)="_filterProductGroupLookupItems($event, i)" (focus)="onProductGroupFocus($event, i)"
                    [title]="item.productGroup ? item.productGroup : ''" />
                  <mat-autocomplete [displayWith]="displayProductGroupFn" #mapproductGroupauto="matAutocomplete"
                    (optionSelected)="productGroupChange($event.option.value, i)">
                    <mat-option *ngFor="let item of filteredProductGroupLookupItems | async" [value]="item">
                      {{item.productGroup}}
                    </mat-option>
                  </mat-autocomplete>
                </td>

                <td class="w-20" *ngIf="validateSeasonalityLevelGridControls('sku')">
                  <input [(ngModel)]="item.productDescription" [ngModelOptions]="{standalone: true}"
                    [matAutocomplete]="mapproductDescriptionauto"
                    [ngClass]="{'error-field': !validSeasonalityHierarchyList[i].productDescription }"
                    (input)="_filterProductDescriptionLookupItems($event, i)"
                    (focus)="onProductDescriptionFocus($event, i)"
                    [title]="item.productDescription ? item.productDescription : ''" />

                  <mat-autocomplete [displayWith]="displayProductDescriptionFn"
                    #mapproductDescriptionauto="matAutocomplete"
                    (optionSelected)="productDescriptionChange($event.option.value, i)">
                    <mat-option *ngFor="let item of filteredProductDescriptionLookupItems | async" [value]="item">
                      {{item.productDescription}}
                    </mat-option>
                  </mat-autocomplete>
                </td>

                <td class="w-10" *ngIf="validateSeasonalityLevelGridControls('sku')">
                  <input [(ngModel)]="item.sku" [ngModelOptions]="{standalone: true}" [matAutocomplete]="mapskuauto"
                    (input)="_filterLookupSkus($event, i)"
                    [ngClass]="{'error-field': !validSeasonalityHierarchyList[i].sku }" (focus)="onSKUFocus($event, i)"
                    [title]="item.sku ? item.sku : ''" />
                  <mat-autocomplete [displayWith]="displaySKUFn" #mapskuauto="matAutocomplete"
                    (optionSelected)="skuChange($event.option.value,i)">
                    <mat-option *ngFor="let item of filteredLookupSkus | async" [value]="item">
                      {{item.sku}}
                    </mat-option>
                  </mat-autocomplete>
                </td>

                <td class="w-10">
                  <button mat-icon-button class="delete-btn" (click)="deleteHierarchyItem(item, i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>

            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div class="plus-map-item-btn">
                    <button mat-mini-fab (click)="addNewHierarchy()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- END NEW Product Hierarchy Grid Selection  -->

      <div class="create-button">
        <button mat-flat-button color="warn" class="ml-10" (click)="createSeasonality()">{{data.seasonalityId ? 'Copy' :
          'Create'}}</button>
        <mat-slide-toggle class="promo-toggle-btn ml-10 mt-five" name="active" formControlName="active">Active
        </mat-slide-toggle>
      </div>
    </form>
  </div>
</div>