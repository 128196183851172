import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GridApi, ColumnApi, ColumnState } from 'ag-grid-enterprise';
import * as moment from 'moment';
import * as _ from 'lodash';
import {
    paginationPageSize,
    cacheBlockSize,
    promotionPlannerItemDetailGridColDefs,
    promotionPlannerWeeklyForecastColDefs,
    defaultColDef
} from './../../ag-grid/gridOptions';

import { getDatePicker } from 'src/common/ag-date-picker';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { gridDataExportParams, downloadFromLink, getUniqueId, registerHorizontalScroll, onCellKeyDown, overlayLoadingTemplate } from '../../../util/util';
import { PromotionPlannerGridDialogComponent } from '../../../dialogs/promotion-planner-grid-dialog/promotion-planner-grid-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { ItemManagerService, PromotionPlannerService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { INVALID_TEAM, PROMOTION_PLANNER_DETAIL, PROMOTION_PLANNER_FORECAST, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { ItemGridDialogComponent } from '../../../dialogs/item-grid-dialog/item-grid-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';

@Component({
    selector: 'app-promotion-planner-detail',
    templateUrl: './promotion-planner-detail.component.html',
    styleUrls: ['./promotion-planner-detail.component.css']
})
export class PromotionPlannerDetailComponent implements OnInit, OnDestroy {

    @Input() data?: any;
    formData: any;
    copyPromotionDetailItems: any = [];
    isEditMode: boolean = false;
    isCopyMode: boolean = false;
    isPromotionSaved: boolean = false;
    isItemDetailGridDataUpdated: boolean = false;
    isForecastDataLoaded: boolean = false;
    isWeeklyForecastGridDataUpdated: boolean = false;
    public gridColumnStateItemGrid: ColumnState[] = [];
    public gridColumnStateForecastGrid: ColumnState[] = [];

    detailPanelOpenState: boolean = true;
    itemDetailGridPanelOpenState: boolean = true;

    prvSelectedAccountIds: any = [];
    prvSelectedClassIds: any = [];

    public defaultColDef = defaultColDef;
    paginationPageSize = paginationPageSize;
    cacheBlockSize = cacheBlockSize;
    rowSelection = 'multiple';
    editType = 'fullRow';
    public itemDetailGridColumnDefs = promotionPlannerItemDetailGridColDefs();
    public weeklyForecastGridColumnDefs = promotionPlannerWeeklyForecastColDefs();

    components = {
        numericCellEditor: getNumericCellEditor(),
        datePicker: getDatePicker()
    }

    public itemDetailGridApi: GridApi;
    public itemDetailColApi: ColumnApi;
    public itemDetailGridOptions: any;
    public itemDetailGridData: any = [];
    itemsDetailsSelectedRows: any = [];
    itemDetailGridUpdatedRows: any = [];

    public weeklyForecastGridApi: GridApi;
    public weeklyForecastColApi: ColumnApi;
    public weeklyForecastGridOptions: any;
    public weeklyForecastGridData: any = [];
    weeklyForecastGridUpdatedRows: any = [];
    forecastGridDataOrgState: any = [];

    acceptedItemsCount: number = 0;
    actionsList: any = [];
    accountList: any = [];
    fixtureList: any = [];
    classList: any = [];

    userTeam: any = '';
    isRetailerTeam: boolean = false;
    disabledAccountSelection: boolean = false;

    formCopyOmitKeys = ['promotionItemName', 'planningMonth', 'startDate', 'endDate'];
    orderByValueList = ['Accepted', 'Rejected', 'Modified', 'Pitched', 'Deleted'];
    immovableStatus = ['Rejected', 'Deleted'];

    selectedFixtureObject: any;
    planogramRow: number = 0;
    planogramColumn: number = 0;
    acceptedPositionedItems: any = [];
    acceptedUnAssignedItems: any = [];

    promotionPlannerDetailForm = new FormGroup({
        promotionItemPlannerName: new FormControl('', Validators.required),
        active: new FormControl(true, Validators.required),
        planningMonth: new FormControl('', Validators.required),
        startDate: new FormControl('', [Validators.required]),
        endDate: new FormControl('', [Validators.required]),
        accountIds: new FormControl('', [Validators.required]),
        fixtureId: new FormControl('', [Validators.required]),
        classIds: new FormControl('', [Validators.required]),
        comments: new FormControl('')
    });


    promotionItemDetailGridId = 'promotionItemDetailGrid';
    promotionWeeklyForecastGridId = 'promotionWeeklyForecastGrid';

    promotionWeeklyForecastGridMutationRef: MutationObserver;
    promotionItemDetailGridMutationRef: MutationObserver;

    onCellKeyDown = onCellKeyDown;

    itemGridPreference: any;
    forecastGridPreference: any;

    dataFirstTimeLoaded = false;

    overlayLoadingTemplate = overlayLoadingTemplate;
    month: any;

    constructor(
        public dialog: MatDialog,
        public configurationService: ConfigurationService,
        public promotionPlannerService: PromotionPlannerService,
        public toastr: NgxToasterService,
        private itemManagerService: ItemManagerService,
        private usersService: UsersService,
        private userPreferenceService: UserPreferenceService,
        public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>
    ) {
        const uniqueId = getUniqueId();
        this.promotionItemDetailGridId = this.promotionItemDetailGridId + uniqueId;
        this.promotionWeeklyForecastGridId = this.promotionWeeklyForecastGridId + uniqueId;
    }

    ngOnInit() {
        this.getUserSecreenPrefereence();
        this.setRoleBaseAccess();
        this.getFormContorlsData();
        this.addCustomValidators();
        this.initiateEditModeData();
        this.initiateValues();
    }

    getUserSecreenPrefereence() {
        this.itemGridPreference = this.userPreferenceService.getPreferenceByKeys(PROMOTION_PLANNER_DETAIL, PROMOTION_PLANNER_DETAIL);
        this.forecastGridPreference = this.userPreferenceService.getPreferenceByKeys(PROMOTION_PLANNER_FORECAST, PROMOTION_PLANNER_FORECAST);
        this.gridColumnStateItemGrid = this.itemGridPreference.columnState ? JSON.parse(this.itemGridPreference.columnState) : [];
        this.gridColumnStateForecastGrid = this.forecastGridPreference.columnState ? JSON.parse(this.forecastGridPreference.columnState) : [];
    }

    onTabChanged(e) {
        if (!this.promotionWeeklyForecastGridMutationRef) {
            setTimeout(() => {
                this.promotionWeeklyForecastGridMutationRef = registerHorizontalScroll(this.promotionWeeklyForecastGridId);
            }, 500);
        }
        if (!this.promotionItemDetailGridMutationRef) {
            setTimeout(() => {
                this.promotionItemDetailGridMutationRef = registerHorizontalScroll(this.promotionItemDetailGridId);
            }, 500);
        }
    }

    get getStatusLabel() {
        const control = this.promotionPlannerDetailForm.get('active');
        if (control) {
            return control.value ? 'Active' : 'Inactive';
        } else {
            return 'Inactive';
        }
    }

    getFormContorlsData() {
        this.itemManagerService.GetAllClasses().toPromise();
        this.itemManagerService.GetAllFixtures().toPromise();
    }

    initiateValues() {
        this.itemManagerService.getActionsListSubject().subscribe((actionsList: any) => {
            this.actionsList = this.itemManagerService.FilterActionByScreen(actionsList, 'PPD');
        });
        this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
            this.accountList = accountList;
        });
        this.itemManagerService.getFixtureListSubject().subscribe((fixtureList: any) => {
            this.fixtureList = fixtureList;
        });
        this.itemManagerService.getClassListSubject().subscribe((classList: any) => {
            this.classList = classList;
        });
    }

    setRoleBaseAccess() {
        this.userTeam = this.usersService.checkLoginUserTeam();
        switch (this.userTeam) {
            case VENDOR_TEAM_CODE: {
                const accountIds = this.usersService.getTeamRoleAccount();
                this.promotionPlannerDetailForm.get('accountIds').setValue(([accountIds] as any));
                this.disabledAccountSelection = true;
                this.isRetailerTeam = false;
                break;
            }
            case RETAILER_TEAM_CODE: {
                this.isRetailerTeam = true;
                this.itemDetailGridColumnDefs = promotionPlannerItemDetailGridColDefs(this.isRetailerTeam);
                this.itemDetailGridApi && this.itemDetailGridApi.setColumnDefs(this.itemDetailGridColumnDefs);
                break;
            }
            case INVALID_TEAM: {
                console.log('Valid Team Not assigned');
                break;
            }
        }
    }

    initiateEditModeData() {
        const data = JSON.parse(this.data ? this.data : {});
        this.isEditMode = data.isEdit;
        this.formData = data.formData;
        if (this.isEditMode) {
            this.prvSelectedAccountIds = this.formData.accountIds;
            this.prvSelectedClassIds = this.formData.classIds;
            this.formData.planningMonth = moment(this.formData.planningMonth).format('MMM YY');
            this.promotionPlannerDetailForm.patchValue(this.formData);
            setTimeout(() => {
                this.getPromotionDetailItemsById();
                this.getPromotionDetailWeeklyForcastById(true);
            }, 500);
        } else if (this.formData && this.formData.isCopyMode) {
            this.isCopyMode = this.formData.isCopyMode;
            this.copyPromotionDetailItems = this.formData.promotionDetailItems;
            this.formData = this.formData.formData;
            this.prvSelectedAccountIds = this.formData.accountIds;
            this.prvSelectedClassIds = this.formData.classIds;
            this.isCopyMode = this.copyPromotionDetailItems.length ? true : false;
            this.itemDetailGridData = this.copyPromotionDetailItems;
            this.promotionPlannerDetailForm.patchValue(this.formData);
        }
    }

    getPromotionDetailItemsById() {
        const filterBody = {
            PromotionItemPlannerId: this.formData.promotionItemPlannerId,
            ...!this.isRetailerTeam && { AccountIds: this.usersService.getTeamRoleAccount() }
        };
        this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
        this.promotionPlannerService.FilterPromotionPlannerDetailItemList(filterBody).subscribe(res => {
            this.itemDetailGridData = _.sortBy(_.orderBy(res, ['promotionItemPlannerDetailId'], ['asc']), [a => this.orderByValueList.indexOf(a.actionName), 'position']);
            this.setFixtureObject();
            this.dataFirstTimeLoaded = true;
            if (this.itemDetailGridApi) {
                this.itemDetailGridApi.setRowData(this.itemDetailGridData);
                this.itemDetailGridApi.hideOverlay();
            }
            this.getAcceptedItemCount();
            this.applyColumnStateForecastGrid();
            this.applyColumnStateItemGrid();
        }, err => {
            this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
        });
    }

    setFixtureObject() {
        const fixtureId = this.promotionPlannerDetailForm.get('fixtureId').value;
        this.selectedFixtureObject = _.find(this.fixtureList, a => a.fixtureId == fixtureId);
    }

    getAcceptedItemCount() {
        const acceptedItems = _.filter(this.itemDetailGridData, a => a.actionName === 'Accepted');
        this.acceptedItemsCount = acceptedItems.length;
    }

    getPromotionDetailWeeklyForcastById(setColDef = false) {
        const filterBody = {
            PromotionItemPlannerId: this.formData.promotionItemPlannerId,
            ...!this.isRetailerTeam && { AccountIds: this.usersService.getTeamRoleAccount() }
        };
        this.weeklyForecastGridApi && this.weeklyForecastGridApi.showLoadingOverlay();
        this.promotionPlannerService.FilterPromotionPlannerItemDetailWeeklyForecastList(filterBody).subscribe(res => {

            this.weeklyForecastGridData = _.sortBy(_.orderBy(res, ['PromotionItemPlannerDetailId'], ['asc']), [a => this.orderByValueList.indexOf(a.actionName), 'Position']);
            this.forecastGridDataOrgState = _.cloneDeep(res);
            setColDef && this.setForecastGridColumnDefinitions();
            if (this.weeklyForecastGridApi) {
                this.weeklyForecastGridApi.setRowData(this.weeklyForecastGridData);
                this.weeklyForecastGridApi.hideOverlay();
            }
            this.applyColumnStateForecastGrid();
            this.applyColumnStateItemGrid();
        },
            err => {
                this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
            });
    }

    setForecastGridColumnDefinitions() {
        if (this.weeklyForecastGridData.length) {
            const weekDates = this.getForecastWeekDatesList();
            this.weeklyForecastGridColumnDefs = promotionPlannerWeeklyForecastColDefs(weekDates, this.isRetailerTeam);
        }
        this.weeklyForecastGridApi && this.weeklyForecastGridApi.setColumnDefs(this.weeklyForecastGridColumnDefs);
    }

    getForecastWeekDatesList() {
        /*
        In Case Week Date Property Missing from Forecast Data
        */
        const weekDates =
            _.orderBy(
                _.compact(
                    _.map(
                        _.keys(_.first(this.weeklyForecastGridData)),
                        (a: any) => {
                            if (a.includes('PromoPrice')) {
                                return a.substring(10);
                            }
                        })));

        return weekDates;
    }

    addCustomValidators() {
        setTimeout(() => {
            this.promotionPlannerDetailForm.controls['startDate'].setValidators([Validators.required, this.startDateValidator]);
            this.promotionPlannerDetailForm.controls['endDate'].setValidators([Validators.required, this.endDateValidator]);
        }, 0);
    }

    startDateValidator(control: FormControl) {
        const startDate = moment(control.value).format('YYYY-MM-DD');
        const currentDate = moment(new Date()).format('YYYY-MM-DD');
        if (startDate < currentDate) {
            return {
                startDate: 'Start Date should be greater then current date.'
            };
        }
        return null;
    }

    endDateValidator(control: FormControl) {
        let startDate = control.parent.controls['startDate'].value;
        const endDate = control.value;
        if (startDate) {
            startDate = new Date(startDate);
            if (endDate < startDate) {
                return {
                    endDate: 'Start Date should be less then End Date.'
                };
            }
            return null;
        } else {
            return null;
        }
    }

    startDateChanged() {
        const startDate = moment(this.promotionPlannerDetailForm.get('startDate').value).format('yyyy-MM-DD');
        this.itemManagerService.GetPlanningMonthByStartDate(startDate).subscribe((planningMonth) => {
            this.month = _.first(planningMonth);
            this.month && this.promotionPlannerDetailForm.get('planningMonth').setValue(this.month.monthName);
        });
    }

    accountIdsChange() {
        const currentSelectedAccountIds = this.promotionPlannerDetailForm.get('accountIds').value;
        if (this.isEditMode || this.isPromotionSaved || this.isCopyMode) {
            const unCheckedAccountIds = _.difference(this.prvSelectedAccountIds, currentSelectedAccountIds);
            if (unCheckedAccountIds.length) {
                const count = this.getPromtionPlannerDetailtemsCountByListAttribute('accountId', unCheckedAccountIds);
                if (count) {
                    this.promotionPlannerDetailForm.get('accountIds').setValue(this.prvSelectedAccountIds);
                    this.toastr.warning('Warning', 'Promotion Items exist for this account');
                }
            }
        }
        this.prvSelectedAccountIds = this.promotionPlannerDetailForm.get('accountIds').value;
    }

    classIdsChange() {
        const currentSelectedClassIds = this.promotionPlannerDetailForm.get('classIds').value;
        if (this.isEditMode || this.isPromotionSaved || this.isCopyMode) {
            const unCheckedClassIds = _.difference(this.prvSelectedClassIds, currentSelectedClassIds);
            if (unCheckedClassIds.length) {
                const count = this.getPromtionPlannerDetailtemsCountByListAttribute('classId', unCheckedClassIds);
                if (count) {
                    this.promotionPlannerDetailForm.get('classIds').setValue(this.prvSelectedClassIds);
                    this.toastr.warning('Warning', 'Promotion Items exist for this class');
                }
            }
        }
        this.prvSelectedClassIds = this.promotionPlannerDetailForm.get('classIds').value;
    }

    getPromtionPlannerDetailtemsCountByListAttribute(attribute, valueList) {
        if (this.itemDetailGridData.length) {
            return _.filter(this.itemDetailGridData, a => valueList.includes(a[attribute]) && a.actionName !== 'Deleted').length;
        } else return 0;
    }

    addNewPromotion(event) {
        event.stopPropagation();
        this.openPromotionItemView();
    }

    openPromotionItemView(data = null) {
        const promotionDetailComponent = {
            menuId: '399',
            name: 'Promotion Planner',
            selector: 'app-promotion-planner-detail',
            displayName: 'Promotion Planner',
            module: 'ItemManagerModule',
            data: {
                isEdit: false,
                formData: data
            },
            component: PromotionPlannerDetailComponent
        }
        this.configurationService.menuRendererSubject.next(promotionDetailComponent);
    }

    toggleChange(event) {
        this.detailPanelOpenState = true;
    }

    copyPromotion(event) {
        event.stopPropagation();
        const formData = _.omit(this.promotionPlannerDetailForm.value, this.formCopyOmitKeys);
        const promotionDetailItems = _.map(_.filter(this.itemDetailGridData, a => !this.immovableStatus.includes(a.actionName)),
            b => {
                const omitProbs = _.omit(b, ['promotionItemPlannerDetailId', 'promotionItemPlannerId', 'actionId']);
                omitProbs.actionName = 'Pitched';
                return omitProbs;
            });
        const copyData = {
            isCopyMode: true,
            formData,
            promotionDetailItems
        }
        this.openPromotionItemView(copyData);
    }

    checkActivePromtions() {
        this.detailPanelOpenState = true;
        if (this.promotionPlannerDetailForm.valid) {
          if(this.formData === undefined || !this.formData.startDate){
            this.promotionPlannerDetailForm.get('planningMonth').setValue(this.month.startDate);
          }else {
            this.promotionPlannerDetailForm.get('planningMonth').setValue(this.formData.startDate);

          }
            moment(this.promotionPlannerDetailForm.get('startDate').value).format('yyyy-MM-DD');
            const body = {
                ...(this.isEditMode || this.isPromotionSaved) && { PromotionItemPlannerId: this.formData.promotionItemPlannerId },
                StartDate: moment(_.get(this.promotionPlannerDetailForm.value, 'startDate')).format('yyyy-MM-DD'),
                EndDate: moment(_.get(this.promotionPlannerDetailForm.value, 'endDate')).format('yyyy-MM-DD'),
                FixtureId: _.get(this.promotionPlannerDetailForm.value, 'fixtureId'),
                Active: _.get(this.promotionPlannerDetailForm.value, 'active'),
                PromotionItemPlannerName: _.get(this.promotionPlannerDetailForm.value, 'promotionItemPlannerName')
            };
            this.promotionPlannerService.GetActivePromotionByFixtureAndDateRange(body).subscribe(isActive => {

                if (isActive) {
                    this.toastr.warning('Warning', 'Active promotion already exist for given date range and fixture.');
                } else {
                    this.savePromotionPlannerItem();
                }
            }, (error: any) => {
                this.toastr.warning('Warning', 'Promotion Name Must Be Unique.');
            });
        }
    }

    savePromotionPlannerItem() {
        if (this.isEditMode || this.isPromotionSaved) {
            const updatedItem = {
                ...this.formData,
                ...this.promotionPlannerDetailForm.value,
                ...{
                    startDate: moment(_.get(this.promotionPlannerDetailForm.value, 'startDate')).format('YYYY-MM-DD'),
                    endDate: moment(_.get(this.promotionPlannerDetailForm.value, 'endDate')).format('YYYY-MM-DD'),
                }
            };
            this.promotionPlannerService.UpdatePromotionPlannerItem(updatedItem).subscribe((res: any) => {
                this.promotionPlannerDetailForm.get('planningMonth').setValue(this.formData.planningMonth);
                this.toastr.success('Success', 'Promotion Details Updated Successfully.');
                this.detailPanelOpenState = false;
                this.promotionPlannerService.setPromotionPlannerUpsertSubject(updatedItem);
                this.setFixtureObject();
            }, err => {
                const error = _.get(err, 'error.error', '');
                if (error.includes('accepted items')) {
                    this.toastr.error('Error', 'Promotion has too many accepted items.');
                } else {
                    this.toastr.error('Error', 'Promotion Details Update Failed.');
                }
                console.log(err);
            });
        } else if (this.isCopyMode) {
            this.promotionPlannerService.CreatePromotionPlannerItem({
                ...this.promotionPlannerDetailForm.value,
                ...{
                    startDate: moment(_.get(this.promotionPlannerDetailForm.value, 'startDate')).format('YYYY-MM-DD'),
                    endDate: moment(_.get(this.promotionPlannerDetailForm.value, 'endDate')).format('YYYY-MM-DD'),
                }
            }).subscribe((res: any) => {
                this.formData = res;
                this.promotionPlannerDetailForm.get('planningMonth').setValue(this.month.monthName);
                this.promotionPlannerService.setPromotionPlannerUpsertSubject(this.formData);
                this.isPromotionSaved = true;
                this.toastr.success('Success', 'Promotion Created Successfully.');
                const newPromotionItems = _.map(this.itemDetailGridData, item => {
                    item['promotionItemPlannerId'] = this.formData.promotionItemPlannerId;
                    return item;
                });
                this.bulkAddPromotionPlannerDetailItems(newPromotionItems);

            }, err => {
                this.toastr.error('Error', 'Promotion Creation Failed.');
                console.log(err);
            });
        } else {
            this.promotionPlannerService.CreatePromotionPlannerItem({
                ...this.promotionPlannerDetailForm.value,
                ...{
                    startDate: moment(_.get(this.promotionPlannerDetailForm.value, 'startDate')).format('YYYY-MM-DD'),
                    endDate: moment(_.get(this.promotionPlannerDetailForm.value, 'endDate')).format('YYYY-MM-DD'),
                }
            }).subscribe((res: any) => {
                this.promotionPlannerDetailForm.get('planningMonth').setValue(this.month.monthName);
                this.formData = res;
                this.promotionPlannerService.setPromotionPlannerUpsertSubject(this.formData);
                this.isPromotionSaved = true;
                this.toastr.success('Success', 'Promotion Created Successfully.');
            }, err => {
                this.toastr.error('Error', 'Promotion Creation Failed.');
                console.log(err);
            });
        }

    }

    handleExport(event) {
        event.stopPropagation();
    }

    onItemDetailGridReady(params) {
        this.itemDetailGridApi = params.api;
        this.itemDetailColApi = params.columnApi;

        setTimeout(() => {
            if (!this.promotionItemDetailGridMutationRef)
                this.promotionItemDetailGridMutationRef = registerHorizontalScroll(this.promotionItemDetailGridId);
        }, 500);

        this.applyColumnStateItemGrid();
    }

    itemDetailGridRowSelectionChanged(params) {
        this.itemsDetailsSelectedRows = params.api.getSelectedRows();
    }

    isItemDetailGridRowSelectable(params) {
        return params.data ? params.data.actionName !== 'Deleted' : false;
    }

    itemDetailGridDataUpdated(params) {
        if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
            this.isItemDetailGridDataUpdated = true;
            const prvUpdatedRowStateIndex = _.findIndex(this.itemDetailGridUpdatedRows, a => a.promotionItemPlannerDetailId === params.data.promotionItemPlannerDetailId);
            if (prvUpdatedRowStateIndex < 0) {
                this.itemDetailGridUpdatedRows.push(params.data);
            } else {
                this.itemDetailGridUpdatedRows[prvUpdatedRowStateIndex] = params.data;
            }
        }
    }

    saveUpdatedItemDetailGridData(event: any) {
        event.stopPropagation();
        this.itemDetailGridApi.stopEditing();
        setTimeout(() => {
            const isDuplicatePositions = this.hasDuplicatePositionsWithFacings(this.itemDetailGridUpdatedRows);
            if (!isDuplicatePositions) {
                if (this.itemDetailGridUpdatedRows.length) {
                    this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
                    this.weeklyForecastGridApi && this.weeklyForecastGridApi.showLoadingOverlay();
                    this.promotionPlannerService.BulkUpdatePromotionPlannerDetailItems({ Data: this.itemDetailGridUpdatedRows }).subscribe((res: any) => {

                        if (res && res.length) {
                            if (_.get(res[0], 'messageRecords.length', false)) {
                                this.toastr.warning('Error', `${res[0].messageRecords.map(a => a.messageText).join('<br />')}`);
                                this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
                                this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
                            } else {
                                this.toastr.success('Success', 'Promotion Details Updated Successfully.');
                                this.itemDetailGridData = _.map(this.itemDetailGridData, (item) => {
                                    const updateItem = _.find(res, { 'promotionItemPlannerDetailId': item.promotionItemPlannerDetailId })
                                    return updateItem ? { ...item, ...updateItem, ...{ document: item.document } } : item;
                                });

                                this.itemDetailGridData = _.sortBy(_.orderBy(this.itemDetailGridData, ['promotionItemPlannerDetailId'], ['asc']), [a => this.orderByValueList.indexOf(a.actionName), 'position']);
                                this.itemDetailGridApi.setRowData(this.itemDetailGridData);
                                this.itemDetailGridUpdatedRows = [];
                                this.isItemDetailGridDataUpdated = false;
                                this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
                                this.getPromotionDetailWeeklyForcastById();
                            }
                        } else {
                            this.toastr.error('Error', 'Promotion Details Update Failed.');
                            this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
                            this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
                        }


                    }, err => {
                        this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
                        this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
                        this.toastr.error('Error', 'Promotion Details Update Failed.');
                        console.log(err);
                    });
                }
            } else {
                this.toastr.error('Error', 'Position values can not be repeated.');
            }
        }, 100);
    }

    hasDuplicatePositionsWithFacings(itemDetailGridUpdatedRows) {
        const currentPositionWithFacings = _.filter(
            _.map(this.itemDetailGridData, a => {
                const isUpdated = _.find(itemDetailGridUpdatedRows, b => b.promotionItemPlannerDetailId === a.promotionItemPlannerDetailId);
                return isUpdated ? { position: isUpdated.position, numberOfFacings: isUpdated.numberOfFacings } : (a.actionName !== 'Deleted' ? { position: a.position, numberOfFacings: a.numberOfFacings } : null);
            }), b => b && b.position);
        const actualPositionsUpdated: any = [];
        currentPositionWithFacings.forEach(itemPosition => {
            const facingIndex = itemPosition.numberOfFacings || 1;
            for (let position = itemPosition.position; position < (itemPosition.position + facingIndex); position++) {
                actualPositionsUpdated.push(position);
            }
        });
        const dup = _(actualPositionsUpdated)
            .countBy()
            .reduce((acc, val, key) => val > 1 ? acc.concat(key) : acc, [])
            .map(_.toNumber)
        return (dup && dup.length) ? true : false;
    }

    openItemDetailColumnDialog(event) {
        event.stopPropagation();
        this.dialog.open(AGColumnDialogComponent, {
            data: {
                colApi: this.itemDetailColApi,
                skipHeaders: ['select']
            },
            width: '800px'
        });
    }

    onExportItemDetailGridData(event) {
        event.stopPropagation();
        const csvExportParams = gridDataExportParams({
            fileName: 'ItemsPromotionsList',
            colDefs: this.itemDetailGridColumnDefs,
            allColumns: false,
            columnKeys: _.map(this.itemDetailGridColumnDefs, a => a.field)
        });
        this.itemDetailGridApi.exportDataAsCsv(csvExportParams);
    }

    exportPromotionDetailDataTemplate() {
        this.promotionPlannerService.ExportPromotionPlannerDetailDataTemplate().subscribe((res: any) => {
            downloadFromLink(res, 'PromotionPlannerDetailTemplate');
        });
    }

    importPromotionDetailDataByTemplate() {
        (document.getElementById('upload-promotion-planner-detail-data') as any).value = '';
        document.getElementById('upload-promotion-planner-detail-data').click();
    }

    validatePromotionDetailUploadedCSV(fileInput: any) {
        const reader = new FileReader();
        if (fileInput.target.files && fileInput.target.files.length) {
            const [file] = fileInput.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
                const importDataFile = reader.result as any;
                /*
                  Files Validations Will go here
                */
                this.uploadImportedCsvFile(importDataFile);
            }
        }
    }

    uploadImportedCsvFile(importDataFile) {
        this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
        this.weeklyForecastGridApi && this.weeklyForecastGridApi.showLoadingOverlay();
        this.promotionPlannerService.ImportPromotionPlannerDetailTemplate({
            fileBase64: importDataFile,
            promotionItemPlannerId: this.formData.promotionItemPlannerId
        }).subscribe(
            (res: any) => {
                if (res && res.length) {
                    const successMessages = [];
                    const errorMessages = [];
                    const warningMessages = [];
                    const infoMessages = [];
                    res.forEach(element => {

                        switch (element.messageType) {
                            case 'Success':
                                successMessages.push(element.messageText);
                                break;
                            case 'Warning':
                                warningMessages.push(element.messageText);
                                break;
                            case 'Info':
                                infoMessages.push(element.messageText);
                                break;
                            case 'Error':
                                errorMessages.push(element.messageText);
                                break;
                            default:
                                break;
                        }
                    });

                    if (errorMessages.length)
                        this.toastr.error('Error', `${errorMessages.map(a => a).join('<br />')}`);
                    if (successMessages.length)
                        this.toastr.success('Success', `${successMessages.map(a => a).join('<br />')}`);
                    if (warningMessages.length)
                        this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
                    if (infoMessages.length)
                        this.toastr.info('Info', `${infoMessages.map(a => a).join('<br />')}`);

                    this.getPromotionDetailItemsById();
                    this.getPromotionDetailWeeklyForcastById();
                } else {
                    this.toastr.warning('Warning', 'Promotion Data Import Failed.');
                    this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
                    this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
                }
            },
            (err: any) => {
                this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
                this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
                this.toastr.error('Error', 'Promotion Data Import Failed.');
            });
    }

    deleteItems() {
        const deleteAction = _.find(this.actionsList, a => a.actionName === 'Deleted');
        this.performActionOnDetailItems(deleteAction);
    }

    performActionOnDetailItems(action) {
        switch (action) {
            case 'Add': {
                this.addPromotionDetailItems(action);
                break;
            }
            case 'Copy': {
                this.copyMovePromotionDetailItems(action)
                break;
            }
            case 'Move': {
                this.copyMovePromotionDetailItems(action)
                break;
            }
            default: {
                this.openPromotionItemsActionDialog(action);
                break;
            }
        }
    }

    addPromotionDetailItems(action) {
        const accountIds = (this.promotionPlannerDetailForm.get('accountIds').value) as any;
        const classIds = (this.promotionPlannerDetailForm.get('classIds').value) as any;
        const dialogRef = this.dialog.open(ItemGridDialogComponent, {
            data: {
                isMultiRowSelection: true,
                currentChildItems: _.map(this.itemDetailGridData, a => {
                    return { childItemId: a.childItemId, actionName: a.actionName };
                }),
                filterEvent: {
                    ...accountIds && { AccountIds: accountIds.join(',') },
                    ...classIds && { ClassIds: classIds.join(',') }
                },
                promotionItemPlannerId: this.formData.promotionItemPlannerId
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.isUnchecked) {
                } else if (result.selectedItems && result.selectedItems.length) {
                    const newPromotionItems = _.map(result.selectedItems, item => {
                        item['promotionItemPlannerId'] = this.formData.promotionItemPlannerId;
                        return item;
                    });
                    this.bulkAddPromotionPlannerDetailItems(newPromotionItems);
                }
                this.itemDetailGridApi.setRowData(this.itemDetailGridData);
            }
        });
    }

    bulkAddPromotionPlannerDetailItems(newPromotionItems) {
        this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
        this.weeklyForecastGridApi && this.weeklyForecastGridApi.showLoadingOverlay();
        this.promotionPlannerService.BulkAddPromotionPlannerDetailItems({ Data: newPromotionItems }).subscribe((res: any) => {
            this.toastr.success('Success', 'Promotion Details Updated Successfully.');
            this.isCopyMode = false;
            this.getPromotionDetailItemsById();
            this.getPromotionDetailWeeklyForcastById(true);
        }, err => {
            this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
            this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
            this.toastr.error('Error', 'Promotion Details Update Failed.');
            console.log(err);
        });
    }

    copyMovePromotionDetailItems(action) {
        if (this.itemsDetailsSelectedRows.length) {
            const isMoveable = _.find(this.itemsDetailsSelectedRows, a => this.immovableStatus.includes(a.actionName));
            if (!isMoveable) {
                const dialogRef = this.dialog.open(PromotionPlannerGridDialogComponent, {
                    data: {}
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result && result.selectedItems) {
                        if (action === 'Copy') {
                            this.copyPromotionPlannerDetailItems(result.selectedItems);
                        } else {
                            this.movePromotionPlannerDetailItems(result.selectedItems);
                        }
                    }
                });
            } else {
                this.toastr.warning('warning', 'Rejected items can not be moved or copied.');
            }

        } else {
            this.toastr.info('info', 'Please select promotion detail items first.');
        }
    }

    copyPromotionPlannerDetailItems(selectedPromotionPlannerItems) {
        const newPromotionItems = [];
        selectedPromotionPlannerItems.forEach(promotion => {
            this.itemsDetailsSelectedRows.forEach(item => {
                newPromotionItems.push({
                    ...item,
                    promotionItemPlannerId: promotion.promotionItemPlannerId
                })
            });
        });
        this.promotionPlannerService.BulkAddPromotionPlannerDetailItemsTransactional({ Data: newPromotionItems }).subscribe((res: any) => {
            this.toastr.success('Success', 'Promotion detail items copied successfully.');
        }, err => {
            this.toastr.error('Error', 'Promotion detail items copying failed.');
            console.log(err);
        });
    }

    movePromotionPlannerDetailItems(selectedItems) {
        this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
        this.weeklyForecastGridApi && this.weeklyForecastGridApi.showLoadingOverlay();
        this.promotionPlannerService.MovePromotionPlannerDetailItems({
            srcPromotionDetailItems: this.itemsDetailsSelectedRows,
            targetPromotion: selectedItems
        }).subscribe((res: any) => {
            this.toastr.success('Success', 'Promotion detail items moved successfully.');
            const selectedItemDetailKeys = _.map(this.itemsDetailsSelectedRows, a => a.promotionItemPlannerDetailId);
            const filterdData = _.filter(this.itemDetailGridData, a => !selectedItemDetailKeys.includes(a.promotionItemPlannerDetailId));
            this.itemDetailGridData = filterdData;
            this.itemDetailGridApi.setRowData(this.itemDetailGridData);
            this.itemsDetailsSelectedRows = [];
            this.getPromotionDetailWeeklyForcastById(true);
            this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
        }, err => {
            this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
            this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
            this.toastr.error('Error', 'Promotion detail items moving failed.');
            console.log(err);
        });
    }

    openPromotionItemsActionDialog(action: any): void {
        // tslint:disable-next-line: no-use-before-declare
        if (this.itemsDetailsSelectedRows.length) {
            this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '500px',
                data: {
                    headerName: `Change Status to '${action.actionName}'`,
                    confirmationMessage: `Are you sure you want to change the status to ${action.actionName}?`
                }
            });

            this.confirmationDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.handlePromotionDetailItemsActions(action);
                }
            });
        } else {
            this.toastr.info('info', 'Please select promotion detail items first.');
        }
    }

    handlePromotionDetailItemsActions(action) {
        this.itemsDetailsSelectedRows.forEach(item => {
            item.actionId = action.actionId;
            item.actionName = action.actionName;
        });
        this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
        this.weeklyForecastGridApi && this.weeklyForecastGridApi.showLoadingOverlay();
        this.promotionPlannerService.BulkUpdatePromotionPlannerDetailItems({ Data: this.itemsDetailsSelectedRows }).subscribe((res: any) => {
            if (res && res.length) {
                let successMessagesCount = 0;
                const warningMessages = [];
                res.forEach(element => {
                    if (_.get(element, 'messageRecords.length', false)) {
                        switch (element.messageRecords[0].messageType) {
                            case 'Warning':
                                warningMessages.push(element.messageRecords[0].messageText);
                                break;
                            case 'Error':
                                warningMessages.push(element.messageRecords[0].messageText);
                                break;
                            default:
                                break;
                        }
                    } else {
                        successMessagesCount++;
                    }
                });
                if (warningMessages.length) {
                    this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
                }
                if (successMessagesCount > 0) {
                    const successMessage = `Promotion detail item${successMessagesCount > 1 ? 's' : ''} updated successfully.`;
                    this.toastr.success('Success', `${successMessage}`);
                }
            }
            this.itemsDetailsSelectedRows = [];
            this.getPromotionDetailItemsById();
            this.getPromotionDetailWeeklyForcastById();
        }, err => {
            this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
            this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
            this.toastr.error('Error', 'Promotion Details Update Failed.');
            console.log(err);
        });
    }

    saveChangedItemPositions(itemDetailsList) {

        const isDuplicatePositions = this.hasDuplicatePositionsWithFacings(itemDetailsList);
        if (!isDuplicatePositions) {
            this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
            this.weeklyForecastGridApi && this.weeklyForecastGridApi.showLoadingOverlay();
            this.promotionPlannerService.BulkUpdatePromotionPlannerDetailItems({ Data: itemDetailsList }).subscribe((res: any) => {
                if (res && res.length) {
                    let successMessagesCount = 0;
                    const warningMessages = [];
                    res.forEach(element => {
                        if (_.get(element, 'messageRecords.length', false)) {
                            switch (element.messageRecords[0].messageType) {
                                case 'Warning':
                                    warningMessages.push(element.messageRecords[0].messageText);
                                    break;
                                case 'Error':
                                    warningMessages.push(element.messageRecords[0].messageText);
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            successMessagesCount++;
                        }
                    });
                    if (warningMessages.length) {
                        this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
                    }
                    if (successMessagesCount > 0) {
                        const successMessage = `Promotion detail item${successMessagesCount > 1 ? 's' : ''} updated successfully.`;
                        this.toastr.success('Success', `${successMessage}`);
                    }
                }
                this.getPromotionDetailItemsById();
                this.getPromotionDetailWeeklyForcastById();
            }, err => {
                this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
                this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
                this.toastr.error('Error', 'Promotion Details Update Failed.');
                console.log(err);
            });
        } else {
            this.toastr.error('Error', 'Position values can not be repeated.');
        }
    }

    onItemGridStateChange(e) {
        this.gridColumnStateItemGrid = this.itemDetailColApi.getColumnState();
        this.itemGridPreference.columnState = this.gridColumnStateItemGrid;
        this.updateScreenPreference(this.itemGridPreference);
    }

    applyColumnStateItemGrid() {
        if (this.itemDetailColApi && this.gridColumnStateItemGrid && this.gridColumnStateItemGrid.length) {
            setTimeout(() => {
                this.itemDetailColApi.applyColumnState({ state: this.gridColumnStateItemGrid });
            }, 100);
        }
    }

    onForecastGridStateChange(e) {
        this.gridColumnStateForecastGrid = this.weeklyForecastColApi.getColumnState();
        this.forecastGridPreference.columnState = this.gridColumnStateForecastGrid;
        this.updateScreenPreference(this.forecastGridPreference);
    }

    applyColumnStateForecastGrid() {
        if (this.weeklyForecastColApi && this.gridColumnStateForecastGrid && this.gridColumnStateForecastGrid.length) {
            setTimeout(() => {
                this.weeklyForecastColApi.applyColumnState({ state: this.gridColumnStateForecastGrid });
            }, 100);
        }
    }

    updateScreenPreference(preferenceObject) {
        this.promotionWeeklyForecastGridMutationRef = registerHorizontalScroll(this.promotionWeeklyForecastGridId);
        this.promotionItemDetailGridMutationRef = registerHorizontalScroll(this.promotionItemDetailGridId);
        this.userPreferenceService.upsertColumnStatePreference(preferenceObject).subscribe(res => {
            console.log(res);
        });
    }

    onWeeklyForecastGridReady(params) {
        this.weeklyForecastGridApi = params.api;
        this.weeklyForecastColApi = params.columnApi;
        setTimeout(() => {
            if (!this.promotionWeeklyForecastGridMutationRef)
                this.promotionWeeklyForecastGridMutationRef = registerHorizontalScroll(this.promotionWeeklyForecastGridId);
        }, 500);
        this.applyColumnStateForecastGrid();
    }

    setWeelyForecastGridColSize() {
        setTimeout(() => {
            this.weeklyForecastGridApi.sizeColumnsToFit();
        }, 0);
    }

    openWeeklyForecastColumnDialog(event) {
        event.stopPropagation();
        this.dialog.open(AGColumnDialogComponent, {
            data: {
                colApi: this.weeklyForecastColApi
            },
            width: '800px'
        });
    }

    weeklyForecastGridDataUpdated(params) {
        if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
            this.isWeeklyForecastGridDataUpdated = true;
            const prvUpdatedRowStateIndex = _.findIndex(this.weeklyForecastGridUpdatedRows, a => a.PromotionItemPlannerDetailId === params.data.PromotionItemPlannerDetailId);
            if (prvUpdatedRowStateIndex < 0) {
                this.weeklyForecastGridUpdatedRows.push(params.data);
            } else {
                this.weeklyForecastGridUpdatedRows[prvUpdatedRowStateIndex] = params.data;
            }
        }
    }

    saveUpdatedWeeklyForecastGridData(event) {
        event.stopPropagation();
        this.weeklyForecastGridApi.stopEditing();
        setTimeout(() => {
            if (this.weeklyForecastGridUpdatedRows.length) {
                const updatedData = this.setWeeklyForecastUpdateDataStructure();
                this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
                this.weeklyForecastGridApi && this.weeklyForecastGridApi.showLoadingOverlay();
                this.promotionPlannerService.BulkUpdatePromotionPlannerItemWeeklyForecastList({ Data: updatedData }).subscribe((res: any) => {
                    this.toastr.success('Success', 'Promotion Details Updated Successfully.');
                    this.getPromotionDetailWeeklyForcastById();
                    this.getPromotionDetailItemsById();
                    this.weeklyForecastGridUpdatedRows = [];
                    this.isWeeklyForecastGridDataUpdated = false;
                }, err => {
                    this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
                    this.weeklyForecastGridApi && this.weeklyForecastGridApi.hideOverlay();
                    const error = _.get(err, 'error.error', '');
                    if (error.includes('exceeding limit')) {
                        this.toastr.error('Error', 'Promotion has too many accepted items.');
                    } else if (error.includes('duplicate Position')) {
                        this.toastr.error('Error', 'Position values can not be repeated.');
                    } else {
                        this.toastr.error('Error', 'Promotion Details Update Failed.');
                    }
                    console.log(err);
                });
            }
        }, 100);
    }

    setWeeklyForecastUpdateDataStructure() {
        const weekDates = this.getForecastWeekDatesList();
        const updatedRowsByWeeks = [];
        this.weeklyForecastGridUpdatedRows.forEach(row => {
            const forecastData = _.find(this.forecastGridDataOrgState, a => a.PromotionItemPlannerDetailId === row.PromotionItemPlannerDetailId);
            weekDates.forEach(week => {
                updatedRowsByWeeks.push({
                    PromotionItemPlannerDetailId: row.PromotionItemPlannerDetailId,
                    MarginCredit: (Number(forecastData[`MarginCredit${week}`]) !== Number(row[`MarginCredit${week}`])) ? row[`MarginCredit${week}`] : -1000,
                    PromoPrice: (Number(forecastData[`PromoPrice${week}`]) !== Number(row[`PromoPrice${week}`])) ? row[`PromoPrice${week}`] : -1000,
                    BAndMPromoForecast: (Number(forecastData[`BAndMPromoForecast${week}`]) !== Number(row[`BAndMPromoForecast${week}`])) ? row[`BAndMPromoForecast${week}`] : -1000,
                    DotComForecast: (Number(forecastData[`DotComForecast${week}`]) !== Number(row[`DotComForecast${week}`])) ? row[`DotComForecast${week}`] : -1000,
                    OnAd: Number(row[`OnAd${week}`]),
                    WeekStartDate: week,
                    NumberOfFacings: row.NumberOfFacings,
                    BAndMPromotionForecastBestBuy: row.BAndMPromotionForecastBestBuy,
                    DotcomForecastBestBuy: row.DotcomForecastBestBuy,
                    OnAdWeekForecastBestBuy: row.OnAdWeekForecastBestBuy
                });
            });
        });
        return updatedRowsByWeeks;
    }

    ngOnDestroy() {
        this.promotionWeeklyForecastGridMutationRef && this.promotionWeeklyForecastGridMutationRef.disconnect();
        this.promotionItemDetailGridMutationRef && this.promotionItemDetailGridMutationRef.disconnect();
    }

}
