export const paginationPageSize = 50;
export const cacheBlockSize = 50;
export const defaultColDef = {
    filter: false,
    sortable: false,
    minWidth: 130,
    resizable: true,
    suppressMenu: true
};

export const generateGridColumns = (itemsArray) => {
    const columnsArray = [
        {
            headerName: 'User Name',
            field: 'userName',
            pinned: 'left',
            width: 160
        }
    ] as any[];
    itemsArray.forEach(label => {
        const obj = {
            headerName: label.label,
            headerClass: 'ag-column-group-class',
            cellStyle: { textAlign: 'center', justifyContent: 'center' },
            children: [
                {
                    headerName: 'Most Visited Menu',
                    field: 'MostVisitedMenu_' + label.label,
                    width: 150
                },
                {
                    headerName: 'Count Of Menu Visited',
                    field: 'CountOfMenuVisited_' + label.label,
                    width: 170
                }
            ]
        }; 
        columnsArray.push(obj);
    });
    return columnsArray;
};
