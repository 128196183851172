import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from 'src/services/configuration.service';

export interface JourneyLog {
  createdDate: string;
  journeyId: number;
  journeyName: string;
  userName: string;
  actionPerformed: string;
  product: string;
}

@Component({
  selector: 'journey-logs',
  templateUrl: './journey-logs.component.html',
  styleUrls: ['./journey-logs.component.scss']
})

export class JourneyLogsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['createdDate', 'journeyId', 'journeyName', 'userName', 'actionPerformed', 'product', 'view'];
  dataSource: MatTableDataSource<JourneyLog>;
  journeyLogs: any = [];

  constructor(
    public dialogRef: MatDialogRef<any>,
    private configService: ConfigurationService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.fetchUserActivityLogs();
  }

  ngAfterViewInit(): void {
  }

  fetchUserActivityLogs() {
    this.spinner.show();
    this.configService
      .GetJourneyUserActivityLog()
      .subscribe((res: any[]) => {
        this.spinner.hide();
        this.journeyLogs = res;
        res.map((item) => {
          item.createdDate = `${new Date(
            item.createdDate
          ).toLocaleDateString()} - ${new Date(
            item.createdDate
          ).toLocaleTimeString()}`;
          item.journeyName = item?.journey?.journeyName
        });
        this.dataSource = new MatTableDataSource(this.journeyLogs);
        this.applyPagination();
      });
  }

  applyPagination() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openJourney(element: any) {
    element = element.journey;
    if (element?.metaData) {
      const data = JSON.parse(element.metaData);
      data.isPowerBiReport = true;
      data.createdBy = element.createdBy;
      data.createdOn = element.createdOn;
      data.journeyId = element.journeyId;
      data.journeyName = element.journeyName;
      data.originalGroupId = element.originalGroupId;
      data.originalReportId = element.originalReportId;
      data.originalReportName = element.originalReportName;

      this.configService.shareJourney.emit(data);
      this.dialogRef.close();
    }
  }


}
