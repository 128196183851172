<div class="row m-0 my-3 pb-3">
  <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
  <ng-container *ngIf="!undefinedImagePath">
    <h4 class="mx-2 w-100"><strong>How are you Performing to Market?</strong></h4>

    <div class="col-md-3">
      <h4>Games</h4>
    </div>
    <div class="col-md-3">
      <h4>Console</h4>
    </div>
    <div class="col-md-3">
      <h4>Accessories</h4>
    </div>
    <div class="col-md-3">
      <h4>Subscriptions</h4>
    </div>

    <div class="col-md-3">
      <div class="number-sectoion">
        <p><strong>{{ yearlyGamesIncrease | currency }}</strong></p>
        <p><small>Total Yearly Revenue Opportunity if you close the gap with Market
            Growth</small></p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="number-sectoion">
        <p><strong>{{ yearlyConsoleIncrease | currency }}</strong></p>
        <p><small>Total Yearly Revenue Oppertunity if you close the gap with Market
            Growth</small></p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="number-sectoion">
        <p><strong>{{ yearlyAccessoriesIncrease | currency }}</strong></p>
        <p><small>Total Yearly Revenue Oppertunity if you close the gap with Market
            Growth</small></p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="number-sectoion">
        <p><strong>{{ yearlySubscriptionsIncrease | currency }}</strong></p>
        <p><small>Total Yearly Revenue Oppertunity if you close the gap with Market
            Growth</small></p>
      </div>
    </div>

    <div class="w-100 m-2">
      <p class="font-18">Partner: | {{ empowerGamingService.getDashboardPartner() }}</p>
    </div>
    <div class="col-md-3 empower-chart-section">
      <fusion-gage-chart [dialValue]="totalRevPartnerFirst" [chartCaption]="partnerChartCaption"
        height="200"></fusion-gage-chart>
    </div>
    <div class="col-md-3 empower-chart-section">
      <fusion-gage-chart [dialValue]="totalRevPartnerSecond" [chartCaption]="partnerChartCaption"
        height="200"></fusion-gage-chart>
    </div>
    <div class="col-md-3 empower-chart-section">
      <fusion-gage-chart [dialValue]="totalRevPartnerThird" [chartCaption]="partnerChartCaption"
        height="200"></fusion-gage-chart>
    </div>
    <div class="col-md-3 empower-chart-section">
      <fusion-gage-chart [dialValue]="totalRevPartnerForth" [chartCaption]="partnerChartCaption"
        height="200"></fusion-gage-chart>
    </div>
    <div class="w-100 m-2">
      <p class="font-18">Market: | {{ empowerGamingService.getDashboardLocation() }}</p>
    </div>
    <div class="col-md-3 empower-chart-section">
      <fusion-gage-chart [dialValue]="totalRevMarketFirst" [chartCaption]="marketChartCaption"
        height="200"></fusion-gage-chart>
    </div>
    <div class="col-md-3 empower-chart-section">
      <fusion-gage-chart [dialValue]="totalRevMarketSecond" [chartCaption]="marketChartCaption"
        height="200"></fusion-gage-chart>
    </div>
    <div class="col-md-3 empower-chart-section">
      <fusion-gage-chart [dialValue]="totalRevMarketThird" [chartCaption]="marketChartCaption"
        height="200"></fusion-gage-chart>
    </div>
    <div class="col-md-3 empower-chart-section">
      <fusion-gage-chart [dialValue]="totalRevMarketForth" [chartCaption]="marketChartCaption"
        height="200"></fusion-gage-chart>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
    <app-image-upload-viewer [imageUrl]="undefinedImagePath" orientation="square"
      (imageLoaded)="emitExportEvent($event)">
    </app-image-upload-viewer>
  </div>
  <div class="col-12">
    <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
  </div>
  <div class="col-12">
    <app-report-page-footer></app-report-page-footer>
  </div>
</div>