<div id="fb-forecast" class="container-fluid">
  <div class="fb-forecast-accordion item-wrapper-panel">
    <div class="item-wrapper">
      <form [formGroup]="f">
        <div class="row">
          <div class="col-md-3">
            <img *ngIf="f.controls && f.controls.documentPath && !f.controls.documentPath.value"
              src="assets/images/thumbnail.png" alt="" class="img-fluid" />
            <img *ngIf="f.controls && f.controls.documentPath && f.controls.documentPath.value"
              [src]="f.controls.documentPath.value" alt="" class="img-fluid" />
            <ul>
              <li>
                <a (click)="openImageDialog(DocumentImport)" href="javascript:void(0)">Upload Image</a>
              </li>
              <li>
                <a (click)="deleteImage()" href="javascript:void(0)">Clear Image</a>
              </li>
            </ul>
            <div class="info">
              <div class="labels">
                <h4>Created By:</h4>
                <h4>{{f.controls && f.controls.createdByName && f.controls.createdByName.value || 'N/A' }}</h4>
              </div>
              <div class="labels">
                <h4>Created:</h4>
                <h4>{{ f.controls && f.controls.createdDate && f.controls.createdDate.value | date: "short" || 'N/A'}}
                </h4>
              </div>
              <div class="labels">
                <h4>Last Modified By:</h4>
                <h4>{{ f.controls && f.controls.modifiedByName && f.controls.modifiedByName.value || 'N/A'}}</h4>
              </div>
              <div class="labels">
                <h4>Last Modified:</h4>
                <h4>{{ f.controls && f.controls.modifiedDate && f.controls.modifiedDate.value | date: "short" || 'N/A'
                  }}</h4>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Username/Email</label>
                  <input type="text" id="item" formControlName="email" />
                </div>
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input type="text" id="item" formControlName="firstName" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Phone Number</label>
                  <input type="number" formControlName="phoneNumber" />
                </div>
                <div class="form-group">
                  <label for="">Last Name</label>
                  <input type="text" formControlName="lastName" />
                </div>
              </div>

              <div class="col-md-6" *ngIf="isEdit">
                <div class="form-group">
                  <label for="passwordExpireDate">Password Expire Date</label>
                  <mat-form-field>
                    <input matInput [matDatepicker]="passwordExpireDate" name="passwordExpireDate"
                      formControlName="passwordExpireDate" [min]="minPasswordExpireDate">
                    <mat-datepicker-toggle matSuffix [for]="passwordExpireDate"></mat-datepicker-toggle>
                    <mat-datepicker #passwordExpireDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row users-wrapper">
              <div class="col-md-6 select-arrow">
                <label for="teamName"
                  style="font-size: 12px; font-weight: bold; padding-bottom: 10px;">{{selectedUser?.firstName}}'s
                  Team</label>
                <div *ngFor="let team of (f.controls && f.controls.teams.value ) ; let i=index" class="form-group">
                  <label for="addTeam">Team Name</label>
                  <div class="user-data">
                    <span [id]="i">{{team.teamName}} </span>
                    <i class="icon-cross-slim" (click)="removeTeam(team)"></i>
                  </div>
                </div>
                <div class="form-group">
                  <label>Add Team</label>
                  <mat-select formControlName="teamsList" (selectionChange)="addTeam()">
                    <mat-option *ngFor="let team of (teamList || []); let i = index" [value]="team">{{
                      team.teamName}}</mat-option>
                  </mat-select>
                  <i class="icon-add"></i>
                </div>
              </div>
              <div class="col-md-6 select-arrow">
                <label for="roleName"
                  style="font-size: 12px; font-weight: bold; padding-bottom: 10px;">{{selectedUser?.firstName}}'s
                  Roles</label>
                <div *ngFor="let role of (f.controls.roles.value || []) ; let i=index" class="form-group">
                  <label for="addRole">Role Name</label>
                  <div class="user-data">
                    <span [id]="i">{{role.roleName}} </span>
                    <i class="icon-cross-slim" (click)="removeRole(role)"></i>
                  </div>
                </div>
                <div class="form-group">
                  <label>Add Role</label>
                  <mat-select formControlName="roleList" (selectionChange)="addRole()">
                    <mat-option *ngFor="let role of (roleList || []); let i = index" [value]="role">{{
                      role.roleName }}</mat-option>
                  </mat-select>
                  <i class="icon-add"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 btn-wrapper">
          <mat-slide-toggle formControlName="active">Active
          </mat-slide-toggle>
          <button class="btn btn-primary" (click)="lockUnlockUser()">{{isUserLocked ? 'Unlock' : 'Freeze'}}</button>
          <button class="btn btn-primary" (click)="onSubmit()">Save</button>
        </div>
      </form>
    </div>
  </div>

  <ng-template #DocumentImport let-data>
    <div mat-dialog-content>
      <form>
        <div>
          <ngx-dropzone (change)="onSelect($event)" [multiple]="false">
            <ngx-dropzone-label>Drop Image here!</ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label style="outline: none">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone>
        </div>
        <div class="action-panel text-right">
          <button mat-raised-button class="btn btn-secondary m-left" (click)="dialogRef.close()">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>