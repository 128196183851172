import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ClientPreferenceService } from 'src/services/client-services/client-preference.service';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';
import { OutlierService } from 'src/services/forecast-services/outlier-service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';



@Component({
  selector: 'app-outlier-commit-dialog',
  templateUrl: './outlier-commit-dialog.component.html',
  styleUrls: ['./outlier-commit-dialog.component.css']
})
export class OutlierCommitDialogComponent implements OnInit {


  commitWeekDate: any;
  minCommitWeek: Date;
  maxCommitWeek: Date;
  clientWeekStartDay: number = 0;

  constructor(
    public dialogRef: MatDialogRef<OutlierCommitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public forecastManagerService: ForecastManagerService,
    public clientPreferenceService: ClientPreferenceService,
    public outlierService: OutlierService,) {
  }

  ngOnInit() {
    this.loadClientPreference();
    this.initCalendarValues();
  }

  initCalendarValues() {
    this.maxCommitWeek = this.data.latestPosWeek;
    this.setMinCommitWeek(new Date(this.data.committedDate));
  }

  loadClientPreference() {
    this.clientWeekStartDay = this.clientPreferenceService.getClientWeekStartDay();
  }

  weekDayFilter = (date) => {
    return date && (date.day() === this.clientWeekStartDay);
  }

  setMinCommitWeek(date: Date) {
    this.minCommitWeek = new Date(date.setDate(date.getDate() + 7));
  }

  commitWeek(isAllCommit: boolean = false) {
    if (!isAllCommit && !this.commitWeekDate) {
      this.toastr.warning('Warning', 'Please select week to commit.');
    } else {
      this.commitWeekDate = isAllCommit ? this.maxCommitWeek : this.commitWeekDate;
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Commit Week`,
          confirmationMessage: `Are you sure you want to commit till ${moment(this.commitWeekDate).format('MM/DD/YYYY')}?`
        }
      });

      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          this.outlierService.CommitWeek({ commitWeek: this.commitWeekDate }).subscribe(res => {
            this.spinner.hide();
            this.toastr.success('Success', `Till week: ${moment(this.commitWeekDate).format('MM/DD/YYYY')} commited successfully.`);
            this.dialogRef.close(true);
          }, err => {
            this.toastr.error('Error', `System failed to commit till week: ${moment(this.commitWeekDate).format('MM/DD/YYYY')}.`);
            this.spinner.hide();
          });
        }
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
