import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { LayoutComponent } from './layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/Dashboard', pathMatch: 'full' },
      { path: 'Dashboard', component: DashboardComponent },
    ]
  },
];

export const LayoutRoutes = RouterModule.forChild(routes);
