<div class="dialog-close-btn">
  <button mat-icon-button (click)="onNoClick()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>

<div mat-dialog-content>
  <p><strong>Select the list items you want to be displayed.</strong></p>
  <div class="row">
    <ng-container *ngFor="let column of gridColumns">
      <div class="col-md-6">
        <section class="ag-column-checkbox-section">
          <mat-checkbox class="checkbox-margin" color="accent" labelPosition="before" 
          [disabled]="disabledColumns.includes(column.colId)"
          [(ngModel)]="columnCheckBox[column.colId]">{{
            column.colDef.headerName }}</mat-checkbox>
        </section>
      </div>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
  <button mat-button mat-flat-button color="accent" (click)="onSaveClick()">Save</button>
</div>