<!-- Filter Section Start -->
<div class="row approval-grid-filter-row">
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="Planning Month" [(ngModel)]="monthId" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let month of planningMonthList" [value]="month.monthId">{{month.monthName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="SKU Status" multiple [(ngModel)]="actionIds" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let action of actionsList" [value]="action.actionId">{{action.actionName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-field">
    <mat-form-field appearance="fill">
      <mat-select placeholder="Account" multiple [disabled]="disabledAccountSelection" [(ngModel)]="accountIds" (selectionChange)="emitFilterEvent()">
        <mat-option *ngFor="let account of accountList" [value]="account.accountId">{{account.accountName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="approval-filter-clear-btn">
    <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
  </div>
</div>
<!-- End Filter Section End -->