export class CollaborationVM {
  journeyCollaborations: JourneyCollaborations[] = [];
}

export class JourneyCollaborations {
  Id?: number;
  JourneyId: number;
  SharedBy: number;
  SharedWith: number;
  ViewedAt?: Date;
  CreatedAt?: Date;
  ClientConfigurationId: number;
  Instance: string;
}
