<mat-form-field appearance="fill" [ngClass]="jpCustomFormFieldClass">
  <mat-label>{{label}}</mat-label>
  <input matInput (click)="_openDatepickerOnClick(datepicker)" (keydown.arrowdown)="_openDatepickerOnClick(datepicker)"
    (keydown.enter)="_openDatepickerOnClick(datepicker)" [formControl]="_inputCtrl" [matDatepicker]="datepicker"
    [max]="_max" [min]="_min" readonly>
  <mat-icon class="search-action-icon" (click)="_openDatepickerOnClick(datepicker)" matSuffix>today</mat-icon>
</mat-form-field>
<mat-datepicker #datepicker (closed)="_takeFocusAway(datepicker)"
  (yearSelected)="_yearSelectedHandler($event,datepicker)" [touchUi]="touchUi" panelClass="jp-year-picker"
  startView="multi-year">
</mat-datepicker>