import { dateRenderer, dateTimeRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import * as _ from 'lodash';
import { tooltipcellRenderer } from "src/common/util/util";
import moment from "moment";

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  filterParams: {
    excelMode: 'windows',
  },
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getMyJourneyColDefs = (filterLookupModel: any = {}, componentSelf?: any) => {
  const colDefs: any = [
    {
      headerName: 'Journey Id',
      field: 'journeyId',
    },
    {
      headerName: 'Name',
      field: 'journeyName',
    },
    {
      headerName: 'Created On',
      field: 'createdOn',
      cellRenderer: dateTimeRenderer,
    },
    {
      headerName: 'Instance',
      field: 'instance',
    },
    {
      headerName: 'Tenant',
      field: 'tenant',
    },
    {
      headerName: 'Report',
      field: 'reportName',
    },
    {
      headerName: 'Opportunity Type',
      field: 'opportunityType',
    },
    {
      headerName: 'assignedValue',
      field: 'assignedValue',
    },
    {
      headerName: 'Actionable by',
      field: 'actionableBy',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Due by',
      field: 'dueBy',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Age',
    },
    {
      headerName: 'Action',
    }
  ];

  return colDefs;
}


