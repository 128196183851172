<div class="container-fluid promo-manager-detail-page">

  <div class="item-wrapper">
    <form class="promo-form" [formGroup]="promotionForm" (ngSubmit)="savePromo()">
      <div class="d-flex justify-content-between align-items-center m-2">

        <div class="d-flex">
          <div class="alert alert-info mr-3" *ngIf="checkWeekDiff">
            No. of Weeks: {{getWeeksDiff}}
          </div>
          <div class="alert alert-info" *ngIf="checkWeekDiff">
            No. of Days: {{getDaysDiff}}
          </div>
        </div>

        <div>
          <mat-slide-toggle class="promo-toggle-btn ml-10" name="status" formControlName="status">Active
          </mat-slide-toggle>

          <button *ngIf="!isOldPromo" type="submit" class="ml-10" mat-button mat-flat-button
            color="accent">Save</button>
          <button type="button" class="ml-10" mat-button mat-flat-button color="accent"
            (click)="copyPromotion($event)">Copy</button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">

          <div class="form-group">
            <label for="promotionEventName">Promo Event*</label>
            <mat-select name="promotionEventName" formControlName="promotionEventName"
              (selectionChange)="promoEventChange()">
              <mat-option *ngFor="let event of promoEventList" [value]="event.event">{{ event.event }}
              </mat-option>
            </mat-select>
          </div>

          <div class="form-group" *ngIf="promotionEventName.value==='Custom'">
            <label for="promoCustomEvent">Event Name*</label>
            <input name="promoCustomEvent" formControlName="promoCustomEvent" (keyup)="debouncedCustomInput()" />
          </div>

          <div class="form-group">
            <label for="promoKey">Promotion Key*</label>
            <input name="promoKey" formControlName="promoKey" />
          </div>

          <div *ngIf="promoKey.invalid && (promoKey.dirty || promoKey.touched)" class="alert alert-danger">
            Promotion key is required and must be a unique value.
          </div>

          <div class="form-group">
            <label for="promoName">Promotion Name*</label>
            <input name="promoName" formControlName="promoName" />
          </div>

          <div class="form-group">
            <label for="discount">Discount %</label>
            <input type="number" name="discount" formControlName="discount" (keyup)="debouncedDiscountInput()" />
          </div>

          <div class="form-group">
            <label for="startDate">Start Date*</label>
            <mat-form-field>
              <input matInput [matDatepicker]="startDatePicker" name="startDate" formControlName="startDate"
                (dateChange)="startDateChange($event)">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="form-group">
            <label for="endDate">End Date*</label>
            <mat-form-field>
              <input matInput [matDatepicker]="endDatePicker" name="endDate" formControlName="endDate"
                (dateChange)="endDateChange($event)" [min]="endDateMin">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="uplift-section">
            <div class="form-group">
              <label for="upliftType">Uplift *</label>
              <mat-radio-group class="inline-flex radio-btn-grp w-100" labelPosition="before" name="upliftType"
                formControlName="upliftType">
                <mat-radio-button value="historical">Historical</mat-radio-button>
                <mat-radio-button value="custom">Custom</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="form-group" *ngIf="upliftType.value==='historical'">
              <label for="selectPromoUplift">Select Promo</label>

              <input name="selectPromoUplift" [(ngModel)]="upliftPromo" [ngModelOptions]="{standalone: true}"
                [matAutocomplete]="upliftPromoAuto" (input)="_filterUpliftPromoItems($event)"
                [disabled]="upliftType.value==='custom' || promosDisabled" (focus)="onUpliftPromoFocus($event)"
                (focusout)="onUpliftPromoFocusOutEvent($event)" />

              <mat-autocomplete [displayWith]="displayPromoFn" #upliftPromoAuto="matAutocomplete"
                (optionSelected)="selectUpliftPromoChange($event.option.value)">
                <mat-option *ngFor="let promo of filteredPromoList | async" [value]="promo">
                  {{ promo.promoName +' '+ (promo.startDate | date: "dd/MM/yyyy") + ' - ' + (promo.endDate | date:
                  "dd/MM/yyyy") }}
                </mat-option>
              </mat-autocomplete>
            </div>

            <div class="form-group">
              <label for="upliftValue">Uplift %</label>
              <input type="number" [readonly]="upliftType.value==='historical'" name="upliftValue"
                formControlName="upliftValue" />
            </div>

          </div>

        </div>

        <div class="col-md-6">

          <div class="form-group">
            <div class="col-md-3">
              <label for="accounts">{{_dataService.getDataById(1)}}(s)*</label>
            </div>
            <div class="col-md-9">

            <app-treeview-account-dropdown class="w-100" [type]="'Channel'" [items]="channelDropdownList"
              (valueChange)="accountTreeValueChange($event)">
            </app-treeview-account-dropdown>
            </div>
          </div>

          <div class="form-group">
            <div class="col-md-3">
            <label>{{_dataService.getDataById(4)}}(s*)</label>
            </div>
            <div class="col-md-9">
            <app-treeview-item-hierarchy-dropdown class="w-100" [type]="'Item'" [items]="productList"
              (valueChange)="itemTreeValueChange($event)">
            </app-treeview-item-hierarchy-dropdown>
            </div>
          </div>

          <div class="form-group">
            <div class="col-md-3">
              <label for="regions">Region(s)*</label>
            </div>
            <div class="col-md-9">
              <mat-select multiple name="regions" formControlName="regions">
                <mat-option disabled *ngFor="let region of regionsList" [value]="region">{{ region }}</mat-option>
              </mat-select>
            </div>
          </div>

          <div class="form-group multi-select">
            <div class="col-md-3">
              <label for="countries">Country*</label>
            </div>
            <div class="col-md-9">
              <mat-select multiple name="countries" formControlName="countries">
                <mat-option disabled *ngFor="let country of countryList" [value]="country">{{ country }}</mat-option>
              </mat-select>
            </div>
          </div>


          <div class="form-group">
            <div class="col-md-3">
              <label for="promoInfo">Promo Info By</label>
            </div>
            <div class="col-md-9">
              <mat-select name="promoInfo" formControlName="promoInfo" (selectionChange)="promoInfoChange()">
                <mat-option value="retailer">Retailer</mat-option>
                <mat-option value="user">User</mat-option>
              </mat-select>
            </div>
          </div>

          <div class="decay-section">
            <div class="form-group">
              <div class="col-md-3">
                <label for="decayTrendType">Decay *</label>
              </div>
              <div class="col-md-9">
                <mat-radio-group class="inline-flex radio-btn-grp w-100" labelPosition="before" name="decayTrendType"
                  formControlName="decayTrendType">
                  <mat-radio-button value="historical" (change)="decayTrendTypeChange($event)">Historical
                  </mat-radio-button>
                  <mat-radio-button value="custom" (change)="decayTrendTypeChange($event)">Custom</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div class="form-group" *ngIf="decayTrendType.value==='historical'">
              <div class="col-md-3">

                <label for="selectPromoDecay">Select Promo</label>
              </div>

              <div class="col-md-9">

                <input name="selectPromoDecay" [(ngModel)]="decayedPromo" [ngModelOptions]="{standalone: true}"
                  [matAutocomplete]="decayPromoAuto" (input)="_filterDecayPromoItems($event)"
                  [disabled]="decayTrendType.value==='custom' || promosDisabled" (focus)="onDecayPromoFocus($event)"
                  (focusout)="onDecayPromoFocusOutEvent($event)" />

                <mat-autocomplete [displayWith]="displayPromoFn" #decayPromoAuto="matAutocomplete"
                  (optionSelected)="selectDecayPromoChange($event.option.value)">
                  <mat-option *ngFor="let promo of filteredPromoList | async" [value]="promo">
                    {{ promo.promoName +' '+ (promo.startDate | date: "dd/MM/yyyy") + ' - ' + (promo.endDate | date:
                    "dd/MM/yyyy") }}
                  </mat-option>
                </mat-autocomplete>
              </div>

            </div>

            <div class="trend-section">
              <label for="trend">Trend %</label>
              <div class="weeks-section" id="style-3">
                <ng-container *ngFor="let week of trendWeeks">
                  <div class="week-values">
                    <button type="button" class="ml-10" matTooltip="Remove Week" mat-icon-button
                      *ngIf="decayTrendType.value==='custom'  && !isOldPromo" (click)="removeWeek(week.weekIndex)">
                      <i class="material-icons">
                        remove_circle
                      </i>
                    </button>
                    <div class="week-index">{{'W-'+week.weekIndex}}</div>
                    <input [readonly]="decayTrendType.value==='historical' || isOldPromo"
                      [ngStyle]="{ 'background-color': decayTrendType.value==='historical' ? 'lightgrey' : 'rgba(255, 215, 0, 0.4)' }"
                      class="week-input" type="number" [(ngModel)]="week.trendValue"
                      [ngModelOptions]="{standalone: true}" />
                  </div>
                </ng-container>
              </div>
              <div class="trend-week-btn" *ngIf="decayTrendType.value==='custom' && !isOldPromo">
                <button type="button" class="ml-10" matTooltip="Add Week" mat-icon-button color="primary"
                  (click)="addWeek()">
                  <i class="material-icons">
                    add_circle
                  </i>
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="!isDecayTrendValid" class="alert alert-danger">
            Decay trend is not valid according to start and end date.
          </div>
        </div>

      </div>
    </form>
    <div class="d-flex justify-content-end mt-3">
      <button  mat-flat-button color="accent" (click)="applySearch()">Search</button>
    </div>
  </div>

  <div #promoHistoryGrid>
    <ng-container *ngIf="isPromoHistoryGridLoaded">
      <hr />
      <div class="grid-action-btn-bar">
        <div class="grid-actions inline-flex">
          <h4 class="promo-title my-auto">Promo History</h4>

          <button matTooltip="Show/Hide Columns ml-2" class="grid-bar-icon" mat-icon-button color="accent"
            (click)="openHistoryColumnDialog()">
            <i class="material-icons">
              ballot
            </i>
          </button>

          <div *ngIf="isHistoryFilteredApplied" class="row-info filtered-info">
            <span><strong>Filtered Rows:</strong> {{promoHistoryDetailGridData.length}} </span>
            <button mat-button color="accent" (click)="clearHistoryGridFilters()">Clear All Filters</button>
          </div>
        </div>

        <h6 class="grid-bar-icon my-auto">One Year Before and After</h6>
        <mat-slide-toggle  [disabled]="!this.promotionForm.get('endDate').value && this.promotionForm.get('startDate').value" class="promo-toggle-btn my-auto" name="status" [(ngModel)]="yearHistoryToggle"
          (change)="historyToggleChange($event)">
        </mat-slide-toggle>

        <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
          (click)="onExportHistoryGridData()">
          <i class="material-icons">
            insert_drive_file
          </i>
        </button>

        <button (click)="removeHistoryGrid()" class="import-export-button" mat-flat-button color="accent">
          Close
        </button>
      </div>

      <div class="all-show-grid forecast-engine-ag-grid">
        <ag-grid-angular #promoHistory id="promo-item-history-data-grid" class="ag-theme-material promo-skus-grid"
           [columnDefs]="columnHistoryDefs" [gridOptions]="gridOptions"
          [frameworkComponents]="frameworkComponents"[defaultColDef]="defaultColDef" [rowData]="promoHistoryDetailGridData"
           [animateRows]="true"[pagination]="true" [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
          (gridReady)="onHistoryGridReady($event)" >
        </ag-grid-angular>
      </div>
    </ng-container>
  </div>

  <hr />

  <ng-container *ngIf="isPromoSaved || isEdit || (isCopyOperation && promoItemDetailGridData.length)">
    <div class="grid-action-btn-bar">
      <div class="grid-actions inline-flex">
        <h4 class="promo-title">Item View</h4>
        <span [matBadge]="itemsCount" matBadgeOverlap="false" matBadgeColor="accent"></span>

        <div *ngIf="selectedRows.length" class="row-info ml-20">
          <spnan><strong>{{selectedRows.length}}</strong> rows selected out of
            <strong>{{promoItemDetailGridData.length}}</strong>
          </spnan>
          <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
        </div>
        <div *ngIf="isFilteredApplied" class="row-info filtered-info">
          <span><strong>Filtered Rows:</strong> {{promoItemDetailGridData.length}} </span>
          <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
        </div>
      </div>

      <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
        (click)="openColumnDialog()">
        <i class="material-icons">
          ballot
        </i>
      </button>

      <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
        (click)="onExportGridData()">
        <i class="material-icons">
          insert_drive_file
        </i>
      </button>

      <button *ngIf="!isOldPromo" class="import-export-button" mat-flat-button color="accent"
        [matMenuTriggerFor]="importExportMenu">Export/Import</button>
      <mat-menu #importExportMenu="matMenu">
        <button mat-menu-item (click)="exportDataTemplate()">Export Template</button>
        <button mat-menu-item (click)="importDataByTemplate()">Import
          <input class="hidden" type="file" id="upload-promotion-item-planner-data"
            name="upload-promotion-item-planner-data" accept=".csv" (change)="validateUploadedCSV($event)">
        </button>
      </mat-menu>

      <button *ngIf="!isOldPromo" class="import-export-button" mat-flat-button color="accent"
        [matMenuTriggerFor]="actionMenu">Actions</button>
      <mat-menu #actionMenu="matMenu">
        <!-- <button mat-menu-item (click)="openPromotionItemsSetupDialog()">Add</button> -->
        <button mat-menu-item (click)="deletePromotionItems()">Delete</button>
      </mat-menu>
    </div>
    <div class="all-show-grid forecast-engine-ag-grid">
      <ag-grid-angular #promoItemGrid id="promo-item-data-grid" class="ag-theme-material promo-skus-grid"
         [columnDefs]="columnDefs" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef"
        [rowData]="promoItemDetailGridData" [rowSelection]="rowSelection" [animateRows]="true" [pagination]="true"
        [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
        (selectionChanged)="rowSelectionChanged($event)" (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </ng-container>
</div>
