<div class="journey-modal-header">
  <h2>Create Journey</h2>
</div>

<form [formGroup]="journeyForm" (ngSubmit)="journeyForm.valid && save()">
  <div class="row">
    <div class="col-12">
      <mat-form-field appearance="fill">
        <mat-label>Journey Title</mat-label>
        <input id="journey" formControlName="journeyName" matInput />
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label>Actionable by</mat-label>
        <input matInput [matDatepicker]="actionableBy" formControlName="actionableBy"
          (dateChange)="actionableDateChange($event)">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="actionableBy"></mat-datepicker-toggle>
        <mat-datepicker #actionableBy></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label>Due By</mat-label>
        <input matInput [matDatepicker]="dueBy" formControlName="dueBy" [min]="dueDateMin">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dueBy"></mat-datepicker-toggle>
        <mat-datepicker #dueBy></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label>Opportunity Type</mat-label>
        <mat-select formControlName="journeyOppertunityTypeId" (selectionChange)="onOpportunityTypeChange()">
          <mat-option *ngFor="let opportunity of opportunityTypeList" [value]="opportunity.journeyOppertunityTypeId">
            {{opportunity.oppertunityTypeDescription}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label>Category</mat-label>
        <mat-select formControlName="journeyCategoryId">
          <mat-option *ngFor="let category of getCategoryList" [value]="category.journeyCategoryId">
            {{category.categoryDescription}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="fill">
        <mat-label>Assigned Value</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input type="number" matInput formControlName="assignedValue">
      </mat-form-field>
    </div>
  </div>


  <button type="submit" mat-raised-button color="accent" class="ml-2" style="float: right">Save</button>
  <button type="button" (click)="dialogRef.close()" mat-stroked-button color="accent"
    style="float: right">Cancel</button>

</form>