<div class="journey-modal-header">
  <h2>Open Journey</h2>
</div>

<form class="example-form">
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Journey Title</mat-label>
    <input type="text" placeholder="Journey Title" aria-label="Journey Title" matInput [formControl]="journey"
      [matAutocomplete]="auto" (focus)="onFocus($event)" (input)="_filter($event)"
      [title]="journey.value ? journey.value : ''">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
      (optionSelected)="optionSelected($event.option.value)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.journeyName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<button (click)="selectedJourney && saveToExistingJourney()" mat-raised-button color="accent" class="ml-1" style="float: right">Save</button>

<button (click)="dialogRef.close()" mat-stroked-button color="accent" style="float: right">Close</button>