import { Injectable, NgZone } from '@angular/core';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import jsPDF from 'jspdf';
import { toPng } from 'html-to-image';
import PptxGenJS from 'pptxgenjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { EmpowerModernLifeService } from './empower-modern-life.service';
import { EmpowerGamingService } from './empower-gaming.service';
import { LoggingService } from '../logging.service';
import { EMPOWER_MODULE, EMPOWER_REPORT_EXPORT, PDF, PPT } from 'src/common/logging-keys';
import _ from 'lodash';

@Injectable()
export class EmpowerExportService {

  exportSubjectSubscription: Subscription;
  pdfObj: jsPDF;
  pptxObj: PptxGenJS;
  resolvedScreens = [];
  exportType = ''; // pdf, ppt
  totalScreens = 0;
  resolvedScreenStatuses: {index: number, resolved: boolean}[] = [];

  uniqueId = 'exportDivForEmpower';

  // Subjects
  public exportComponentSubject: Subject<{ screen: number, resolved: boolean, pageIndex: number, pageTitle: string }> = new Subject();

  // Set Subject Values
  setExportComponentSubject = (screen: number, resolved: boolean, pageIndex: number, pageTitle: string) => { this.exportComponentSubject.next({ screen, resolved, pageIndex, pageTitle }); };

  constructor(
    private toastr: NgxToasterService,
    private spinner: NgxSpinnerService,
    private empowerGamingService: EmpowerGamingService,
    private zone: NgZone,
    private empowerModernLifeService: EmpowerModernLifeService,
    private loggingService: LoggingService
  ) {
  }

  async exportEvent(exportType: string, reportType: 'gaming' | 'ModernLife', props: any = {}) {
    if (this.totalScreens) {
      this.toastr.warning('Warning', 'Export is already in progress.');
      return;
    }
    this.exportType = exportType;
    let elementRef = document.getElementById(this.uniqueId);
    if (!elementRef) {
      elementRef = document.createElement('div');
      elementRef.setAttribute('id', this.uniqueId);
      elementRef.classList.add('empower-floating-export-window');
      document.body.appendChild(elementRef);
    }
    this.removeChildren(elementRef);
    const newDiv = document.createElement('div');
    elementRef.append(newDiv);
    newDiv.setAttribute('id', this.uniqueId + '_screens');
    newDiv.classList.add('screens-div');
    try {
      this.spinner.show();
      this.initializePptPdfObj();
      this.registerExportSubject(props);
      let i = 0;
      this.totalScreens = 0;
      let pagesList = [];
      this.resolvedScreenStatuses = [];
      if (reportType == 'gaming') pagesList = this.empowerGamingService.xboxSideNavList.value;
      else if (reportType == 'ModernLife') pagesList = this.empowerModernLifeService.modernSideNavList.value;
      pagesList.forEach((page, index) => {
        if (!page.landingPage && page.active && this.exportSubjectSubscription) {
          const newComponent = document.createElement(page.pageSelector);
          page.data = {
            reportId: page.reportKey,
            pageId: page.pageId,
            reportPageVisuals: page.reportPageVisuals,
            export: true,
            pageIndex: i,
            pageTitle: page.pageTitle
          };
          newComponent.setAttribute('data', JSON.stringify(page.data));
          newDiv.append(newComponent);
          i++;
          this.totalScreens++;
        }
      });
      this.appendProgressDivStep(true);
    } catch (err) {
      this.exportSubjectSubscription && this.removeExportSubjectSubscription();
      elementRef.remove();
    } finally {
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
    }
  }
  
  initializePptPdfObj() {
    if (this.exportType == 'pdf') {
      this.pdfObj = new jsPDF('l', 'px', [13 * 96, 11 * 96]);
    } else {
      this.pptxObj = new PptxGenJS();
      this.pptxObj.setLayout({ name: 'A3', width: 13, height: 11 });
    }
  }

  registerExportSubject(props: any = {}) {
    this.exportSubjectSubscription = this.exportComponentSubject
      .subscribe((response) => {
        
        if (this.resolvedScreens.indexOf(response.screen) == -1) {
          this.resolvedScreens.push(response.screen);
          this.resolvedScreens = [... new Set(this.resolvedScreens)];
          this.resolvedScreenStatuses.push({index: response.pageIndex, resolved: response.resolved});
          this.prepareForDownload(props);
        }
      });
  }

  prepareForDownload(props = {}) {
      const elementRef = document.getElementById(this.uniqueId + '_screens');
  
      if (this.resolvedScreenStatuses.length == elementRef.childElementCount) {
        this.resolvedScreenStatuses = _.sortBy(this.resolvedScreenStatuses, 'index');
        const i = 0;
        this.resolvedScreens = [];
        this.downloadingTheFile(i, props);
      } else {
        this.appendProgressDivStep(true);
      }
  }

  downloadingTheFile(index, props = {}) {
    this.resolvedScreens.push(index);
    if (this.resolvedScreens.length > this.resolvedScreenStatuses.length) {
      // save
      if (this.exportType == 'pdf') {
        console.log('Downloading pdf 1');
        const numberOfPages = this.pdfObj.getNumberOfPages();
        this.pdfObj.deletePage(numberOfPages);
        console.log('Downloading pdf 2');
        this.pdfObj.getNumberOfPages() == 0 && this.pdfObj.addPage();
        console.log('Downloading pdf 3');
        this.pdfObj.save('empower-reports');
        console.log('Downloading pdf 4');
        this.exportSubjectSubscription && this.removeExportSubjectSubscription();
        console.log('Downloading pdf 5');
      } else {
        this.pptxObj.save('empower-reports');
        this.exportSubjectSubscription && this.removeExportSubjectSubscription();
      }
      this.loggingService.logEvent(EMPOWER_MODULE, {
        ...props,
        eventName: EMPOWER_REPORT_EXPORT,
        eventType: this.exportType == 'ppt' ? PPT : PDF
      });
      return;
    }
    this.appendProgressDivStep(false);
    if (this.resolvedScreenStatuses[index].resolved) {
      // append
      const elementRef = document.getElementById(this.uniqueId + '_screens');
      const node = elementRef.childNodes[index] as HTMLElement;
      node && toPng(node.childNodes[0] as HTMLElement, {
        width: 13 * 96,
        height: 11 * 96,
        style: {
          objectFit: 'contain',
          objectPosition: 'top',
          margin: '10px',
        }
      })
      .then((dataUrl) => {
        if (this.exportType == 'pdf') {
          this.pdfObj.addImage(dataUrl, 'PNG', 0, 0, 13 * 96, 11 * 96);
          this.pdfObj.addPage();
        } else {
          const slide = this.pptxObj.addNewSlide();
          slide.addImage({ w: '13', h: '11', data: dataUrl }); 
        }
          index++;
          this.downloadingTheFile(index, props);
      });
    } else {
      index++;
      this.downloadingTheFile(index, props);
    }
    
  }

  appendProgressDivStep(preparing) {
    let percentage = Math.ceil(this.resolvedScreens.length / this.totalScreens * 100);
    const elementRef = document.getElementById(this.uniqueId);
    let progressDiv = document.getElementById(this.uniqueId + '_progress_div');
    progressDiv && progressDiv.remove();
    progressDiv = document.createElement('h4');
    progressDiv.setAttribute('id', this.uniqueId + '_progress_div');
    progressDiv.textContent = "Preparing to download. Please wait...  (" + percentage + '%)' ;
    !preparing && (percentage = Math.ceil(this.resolvedScreens.length / this.totalScreens * 100))
    !preparing && (progressDiv.textContent = "Downloading in progress...   (" + percentage + '%)');
    elementRef.prepend(progressDiv);
    this.resolvedScreens.sort((a, b) => a - b);
    console.log(this.resolvedScreens);
  }

  removeExportSubjectSubscription() {
    setTimeout(() => {
      this.pdfObj = null;
      this.pptxObj = null;
      this.resolvedScreens = [];
      this.resolvedScreenStatuses = [];
      this.totalScreens = 0;
      let elementRef = document.getElementById(this.uniqueId + '_screens');
      elementRef && this.removeChildren(elementRef);
      this.exportSubjectSubscription && this.exportSubjectSubscription.unsubscribe();
      elementRef = document.getElementById(this.uniqueId);
      elementRef && elementRef.remove();
    }, 1000);
  }

  removeChildren(elementRef) {
    var child = elementRef.lastElementChild;
    while (child) {
      elementRef.removeChild(child);
      child = elementRef.lastElementChild;
    }
  }

}
