import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ChildItemService, ItemManagerService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { INVALID_TEAM, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { DemoMasterGridDialogComponent } from '../master-grid-dialog/master-grid-dialog.component';
import { DemoService } from 'src/services/demo-services/demo-service';


@Component({
  selector: 'app-demo-item-dialog',
  templateUrl: './item-dialog.component.html',
  styleUrls: ['./item-dialog.component.css']
})
export class DemoItemDialogComponent implements OnInit {
  @ViewChild('itemFile')
  itemFile: ElementRef;

  isEditMode: boolean = false;
  makeList = [];
  modelList = [];
  yearList = [];
  brandList = [];
  classList: any = [];
  subClassList: any = [];


  imageSrc: string;
  itemForm = new FormGroup({
    Title: new FormControl('', Validators.required),
    Class: new FormControl('', Validators.required),
    UPC: new FormControl('', Validators.required),
    SubClass: new FormControl('', Validators.required),
    Make: new FormControl('', Validators.required),
    Retail: new FormControl('', Validators.required),
    Model: new FormControl('', Validators.required),
    Cost: new FormControl('', Validators.required),
    Brand: new FormControl('', Validators.required),
    ManufacturePartNumber: new FormControl('', Validators.required),
    Year: new FormControl('', Validators.required),
    itemDescription: new FormControl(''),
    filePath: new FormControl(''),
  });

  constructor(public dialogRef: MatDialogRef<DemoItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public toastr: NgxToasterService,
    public demoService: DemoService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit() {
    this.initiateValues();
  }

  get UPC() { return this.itemForm.get('UPC'); }


  initiateValues() {
    this.demoService.GetItemMakeList().subscribe(makeList => {
      this.makeList = makeList;
    });
    this.demoService.GetItemModelList().subscribe(modelList => {
      this.modelList = modelList;
    });
    this.demoService.GetItemYearList().subscribe(yearList => {
      this.yearList = yearList;
    });
    this.demoService.GetItemBrandList().subscribe(brandList => {
      this.brandList = brandList;
    });
    this.demoService.GetItemClassList().subscribe(classList => {
      this.classList = classList;
    });
    this.demoService.GetItemSubClassList().subscribe(subClassList => {
      this.subClassList = subClassList;
    });
    if (this.data.isEdit) {
      this.isEditMode = this.data.isEdit;
      this.itemForm.patchValue(this.data.formData);
      this.imageSrc = _.get(this.data.formData, 'document.documentPath');
    } 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openRemoveDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: this.itemForm.value.itemDescription }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeChildItem();
      }
    });
  }

  removeChildItem() {
 
  }

  onFileChange(event) {
    if (this.data.isEdit) {
      this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: { confirmationMessage: `Are you sure want to update Child Item image?` }
      });

      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.fileReader(event, true);
        }
      });
    } else {
      this.fileReader(event);
    }
  }

  fileReader(event, uploadFile = false) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.itemForm.patchValue({
          filePath: (reader.result as any)
        });
        uploadFile && this.updateChildItemImage();
      }
    }
  }

  updateChildItemImage() {
    const body = {
      ...this.data.formData,
      ...this.itemForm.value
    }
  }

  clearItemImage() {
    if (this.data.isEdit) {
      if (_.get(this.data.formData, 'document.documentPath')) {
        this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: { headerName: this.itemForm.value.itemDescription }
        });

        this.confirmationDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.resetFile();
            this.removeChildItemImage();
          }
        });
      }
    } else {
      this.resetFile();
    }
  }

  resetFile() {
    this.imageSrc = '';
    this.itemForm.patchValue({
      filePath: ''
    });
    this.itemFile.nativeElement.value = "";
  }

  removeChildItemImage() {
    const body = {
      ...this.data.formData,
      ...this.itemForm.value
    }
  }

  setFormValues() {
  }


  onSubmit() {

    if (this.data.isEdit) {

    } else {
    
    }
  }

}
