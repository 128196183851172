import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { LocalstorageService } from '../localstorage.service';
import { environment } from '../../environments/environment';
import { USER_PREFERNCE_KEY } from 'src/common/keys';


@Injectable()
export class UserPreferenceService {

  constructor(private http: HttpClient, public storage: LocalstorageService) {
  }

  getUserPreferencebyId() {
    return this.http.get<any>(`${environment.ScreenPreference}/getlist`)
      .pipe(map(userPreference => {
        this.storage.set(USER_PREFERNCE_KEY, JSON.stringify(userPreference));
        return userPreference;
      }));
  }

  getSamplePreferenceObject(screenPreferenceKey, gridKey) {
    return {
      screenPreferenceKey: screenPreferenceKey,
      gridKey: gridKey,
      columnState: null,
      screenPreferences: null,
      screenFilters: null
    };
  }

  getPreferenceByKeys(screenPreferenceKey, gridKey) {
    const userPreferences = JSON.parse(this.storage.get(USER_PREFERNCE_KEY));
    const preference = _.find(userPreferences, a => a.screenPreferenceKey == screenPreferenceKey && a.gridKey == gridKey);
    return preference ? preference : this.getSamplePreferenceObject(screenPreferenceKey, gridKey);
  }

  upsertColumnStatePreference(preferenceObject) {
    const userPreferences = JSON.parse(this.storage.get(USER_PREFERNCE_KEY));
    const preferenceIndex = _.findIndex(userPreferences, a => a.screenPreferenceKey == preferenceObject.screenPreferenceKey && a.gridKey == preferenceObject.gridKey);
    const preferenceBody = preferenceIndex < 0 ? preferenceObject : userPreferences[preferenceIndex];
    preferenceBody.columnState = JSON.stringify(preferenceObject.columnState);
    return this.http.put<any>(`${environment.ScreenPreference}/update`, preferenceBody)
      .pipe(map(response => {
        preferenceIndex < 0 ? userPreferences.push(preferenceBody) : userPreferences[preferenceIndex] = preferenceBody;
        this.storage.set(USER_PREFERNCE_KEY, JSON.stringify(userPreferences));
        return response;
      }));
  }

  upsertFilterPreference(preferenceObject) {
    const userPreferences = JSON.parse(this.storage.get(USER_PREFERNCE_KEY));
    const preferenceIndex = _.findIndex(userPreferences, a => a.screenPreferenceKey == preferenceObject.screenPreferenceKey && a.gridKey == preferenceObject.gridKey);
    const preferenceBody = preferenceIndex < 0 ? preferenceObject : userPreferences[preferenceIndex];
    preferenceBody.screenFilters = JSON.stringify(preferenceObject.screenFilters);
    return this.http.put<any>(`${environment.ScreenPreference}/update`, preferenceBody)
      .pipe(map(response => {
        preferenceIndex < 0 ? userPreferences.push(preferenceBody) : userPreferences[preferenceIndex] = preferenceBody;
        this.storage.set(USER_PREFERNCE_KEY, JSON.stringify(userPreferences));
        return response;
      }));
  }

}
