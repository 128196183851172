<div class="mt-3">
  <div class="row m-0 pb-4 mt-3">
    <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
    <ng-container *ngIf="!undefinedImagePath">
      <h4 class="mx-2 w-100"><strong>What is revenue opportunity if you close the gap with the market?</strong></h4>
      <div class="col-6 empower-chart-section">
        <h4>Surface Accessories</h4>
        <div class="number-sectoion mb-2">
          <p><strong>{{accessoriesYearlyIncrease | currency }}</strong></p>
          <p><small>Total Yearly Revenue Oppertunity if you close the gap with Market Growth</small></p>
        </div>
        <div>
          <p class="font-18">Partner: | {{ empowerModernLifeService.getDashboardPartner() }}</p>
          <fusion-gage-chart [dialValue]="totalAccRevPartner" [chartCaption]="partnerChartCaption" height="200"></fusion-gage-chart>
        </div>
        <div>
          <p class="font-18">Market: | {{ empowerModernLifeService.getDashboardLocation() }}</p>
          <fusion-gage-chart [dialValue]="totalAccRevMarket" [chartCaption]="marketChartCaption" height="200"></fusion-gage-chart>
        </div>
      </div>

      <div class="col-6 empower-chart-section">
        <h4>Surface Devices</h4>
        <div class="number-sectoion mb-2">
          <p><strong>{{devicesYearlyIncrease | currency }}</strong></p>
          <p><small>Total Yearly Revenue Oppertunity if you close the gap with Market Growth</small></p>
        </div>
        <div>
          <br />
          <br />
          <fusion-gage-chart [dialValue]="totalDevRevPartner" [chartCaption]="partnerChartCaption" height="200"></fusion-gage-chart>
        </div>
        <div>
          <br />
          <br />
          <fusion-gage-chart [dialValue]="totalDevRevMarket" [chartCaption]="marketChartCaption" height="200"></fusion-gage-chart>
        </div>
      </div>
    </ng-container>

        <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
      <app-image-upload-viewer [imageUrl]="undefinedImagePath"
          orientation="square" (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12">
      <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
    </div>
    <div class="col-12">
      <app-report-page-footer></app-report-page-footer>
    </div>
  </div>
</div>