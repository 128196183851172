<div [ngClass]="{'circle-img': orientation==='circle', 'square-img': orientation==='square'}" [style.width]="width"
  [style.height]="height" style="max-height: 900;">

  <input class="d-none" type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
    (change)="uploadFile($event)" />

  <ng-container *ngIf="imageUrl !==NO_IMAGE; else noImage">
    <div class="img-preview">
      <!-- <div id="imagePreview" [style.width.px]="width" [style.height.px]="height" [style.backgroundImage]="'url('+ imageUrl +')'">
        </div> -->
      <img (load)="onImageLoad()" (error)="onError()" [src]="imageUrl" [style.width.px]="width"
        [style.height.px]="height" style="max-height: 900;">
    </div>
    <div class="img-action-btn">
      <button mat-fab (click)="fileInput.click()" matTooltip="Upload Image">
        <mat-icon>upload</mat-icon>
      </button>
      <button mat-fab (click)="removeImage();" matTooltip="Remove Image">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </ng-container>
</div>

<ng-template #noImage>
  <div class="no-image-div">
    <button type="button" mat-raised-button color="accent" (click)="fileInput.click()">Upload Image</button>
  </div>
</ng-template>