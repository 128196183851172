<div class="mt-3">
  <div class="row m-0 pb-4 mt-3">
    <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
    <ng-container *ngIf="!undefinedImagePath">
      <h4 class="m-2 w-100"><strong>How to close the gap by increase attach?</strong></h4>
      <p class="m-2 font-18">Partner: | {{ empowerModernLifeService.getDashboardPartner() }}</p>
      <div class="col-12 empower-chart-section">
        <h4>Partner Accessories and PC MOM</h4>
        <fusion-ms-combination-chart-2y height="350" [xaxisCatgoryList]="xaxisCatgoryList" [dataSeries]="dataSeries" xAxisName="Month">
        </fusion-ms-combination-chart-2y>
      </div>
      <div class="col-6 number-title-bar p-0 m-2">
        <h4>Revenue Opportunity Yearly Attach by 1% Attach Increase</h4>
        <h1>${{yearlyIncrease | number}}</h1>
      </div>
      
    </ng-container>
    <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
      <app-image-upload-viewer [imageUrl]="undefinedImagePath"
          orientation="square" (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12">
      <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
    </div>
    <div class="col-12">
      <app-report-page-footer></app-report-page-footer>
    </div>
  </div>
</div>