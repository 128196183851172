import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { generateTitleDiv, NO_IMAGE, undefinedImagePath } from 'src/modules/empower-module/helper';

@Component({
  selector: 'empower-m365-rev-opper-vs-market',
  templateUrl: './m365-rev-opper-vs-market.component.html',
  styleUrls: ['./m365-rev-opper-vs-market.component.scss']
})
export class EmpowerM365RevOpperVsMarketComponent implements OnInit {
  @Input() data?: any;
  pageComment: string;
  totalRevPartner: number;
  totalRevMarket: number;
  yearlyIncrease: number = 0;
  innerHtml = '';
  constructor(
    public empowerModernLifeService: EmpowerModernLifeService, private empowerExportService: EmpowerExportService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);

      this.innerHtml = generateTitleDiv(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const daxReponse = this.empowerModernLifeService.getPagesState(pageId);
    this.pageComment = this.empowerModernLifeService.getPageCommentState(pageId);
    if (daxReponse && daxReponse.length) {
      this.loadVisuals(daxReponse);
    } else {
      this.executePageDaxQuery();
    }
  }

  _unsubscribeAll = new Subject();
  undefinedImagePath = '';
  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.empowerModernLifeService.getDashboardState()
    };
    this.spinner.show();
    this.empowerModernLifeService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        this.emitExportEvent(true);
      }
      this.spinner.hide();
    },
      err => {
        this.undefinedImagePath = undefinedImagePath;
        this.spinner.hide();
      });
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }

  setPageState(pageId, daxData) {
    this.empowerModernLifeService.setPagesState(pageId, daxData);
  }

  loadVisuals(daxData) {
    this.loadFirstVisualDataChart(daxData[0]);
    this.loadSecondVisualDataChart(daxData[1]);
    this.loadThirdVisualDataChart(daxData[2]);
    this.emitExportEvent(true);
  }

  loadFirstVisualDataChart(visualData) {
    this.totalRevPartner = _.get(visualData.daxResponse, ['0', '[Total_Rev__]'], 0);
  }

  loadSecondVisualDataChart(visualData) {
    this.totalRevMarket = _.get(visualData.daxResponse, ['0', '[Market Growth %]'], 0);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(13, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

  loadThirdVisualDataChart(visualData) {
    this.yearlyIncrease = _.get(visualData.daxResponse, ['0', '[Total Yearly Revenue Opportunity if you close the gap with Market Growth]'], 0);
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.empowerModernLifeService.setPageCommentState(pageId, comment);
  }

}
