<div class="dialog-close-btn">
  <button mat-icon-button (click)="onNoClick()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>

<div class="row item-wrapper">
  <div class="col-2">
    <div>
      <label for="seasonalityName">Seasonality Name</label>
      <div class="form-group">
        <mat-form-field>
          <input readonly matInput type="text" name="seasonalityName" [(ngModel)]="seasonalityData.seasonalityName" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div>
      <label for="seasonalityLevelName">Seasonality Level</label>
      <div class="form-group">
        <mat-form-field>
          <input matInput readonly type="text" name="seasonalityLevelName" [(ngModel)]="seasonalityData.seasonalityLevelName" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="col-2">
    <div>
      <label for="retailer">{{_dataService.getDataById(1)}}</label>
      <div class="form-group">
        <mat-form-field>
          <input matInput readonly type="text" name="retailer" [(ngModel)]="seasonalityData.retailer" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="col-2" *ngIf="validateSeasonalityLevelControls('brand')">
    <label for="brands">{{_dataService.getDataById(2)}}</label>
    <div class="form-group">
      <mat-select name="brands" [(ngModel)]="seasonalityData.brands" multiple>
        <mat-option *ngFor="let brand of brandList" [value]="brand" disabled>
          {{brand}}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="col-2" *ngIf="validateSeasonalityLevelControls('productGroup')">
    <label for="productGroups">{{_dataService.getDataById(3)}}</label>
    <div class="form-group">
      <mat-select name="productGroups" [(ngModel)]="seasonalityData.productGroups" multiple>
        <mat-option *ngFor="let productGroup of productGroupList" [value]="productGroup" disabled>
          {{productGroup}}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="col-2" *ngIf="validateSeasonalityLevelControls('skuDescription')">
    <label for="skuDescriptions">{{_dataService.getDataById(5)}}</label>
    <div class="form-group">
      <mat-select name="skuDescriptions" [(ngModel)]="seasonalityData.skuDescriptions" multiple>
        <mat-option *ngFor="let skuDescription of skuDescriptionList" [value]="skuDescription" disabled>
          {{skuDescription}}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="col-2" *ngIf="validateSeasonalityLevelControls('sku')">
    <label for="sku">{{_dataService.getDataById(4)}}</label>
    <div class="form-group">
      <mat-select name="sku" [(ngModel)]="seasonalityData.sku" multiple>
        <mat-option *ngFor="let sku of skuList" [value]="sku" disabled>
          {{sku}}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="col-2" *ngIf="seasonalityData.seasonalityType==='static'">
    <label for="endWeekDate">End Week*</label>
    <div class="form-group">
      <mat-form-field>
        <input readonly matInput [matDatepicker]="endDatePicker"
          name="endWeekDate" [(ngModel)]="seasonalityData.endWeekDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker" disabled></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-2" *ngIf="seasonalityData.seasonalityType==='static'">
    <label for="startWeekDate">Start Week*</label>
    <div class="form-group">
      <mat-form-field>
        <input readonly matInput [matDatepicker]="startDatePicker"
          name="startWeekDate" [(ngModel)]="seasonalityData.startWeekDate" >
        <mat-datepicker-toggle matSuffix [for]="startDatePicker" disabled></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-2" *ngIf="seasonalityData.seasonalityType === 'rolling'">
    <label for="rollingWeek">Rolling Weeks</label>
    <div class="form-group">
      <mat-form-field>
        <input matInput readonly type="number" name="rollingWeek" [(ngModel)]="seasonalityData.rollingWeek" />
      </mat-form-field>
    </div>
  </div>

</div>

<div *ngIf="loadChartData">
  <fusioncharts width="100%"  height="350" [chartConfig]=chartConfig [dataSource]=dataSource>
  </fusioncharts>
</div>

<hr />

<div class="all-show-grid" *ngIf="isGridLoaded">
  <ag-grid-angular #seasonalityWeeksGrid id="seasonality-weeks-data-grid"
    class="ag-theme-material seasonality-weeks-data-grid" [components]="components"
    [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowData]="gridData" [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)" (cellValueChanged)="gridDataUpdated($event)">
  </ag-grid-angular>
</div>
