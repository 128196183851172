<div class="example-container share-journey-table" tabindex="0">

  <table mat-table [dataSource]="dataSource" class="w-100">

    <ng-container matColumnDef="journeyId">
      <th mat-header-cell *matHeaderCellDef> Journey Id </th>
      <td mat-cell *matCellDef="let element"> {{element.journeyId}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.journeyName}} </td>
    </ng-container>

    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef> Created On </th>
      <td mat-cell *matCellDef="let element"> {{element.createdOn}} </td>
    </ng-container>


    <ng-container matColumnDef="instance">
      <th mat-header-cell *matHeaderCellDef> Instance </th>
      <td mat-cell *matCellDef="let element"> {{element.instance}} </td>
    </ng-container>


    <ng-container matColumnDef="tenant">
      <th mat-header-cell *matHeaderCellDef> Tenant </th>
      <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
    </ng-container>


    <ng-container matColumnDef="report">
      <th mat-header-cell *matHeaderCellDef> Report </th>
      <td mat-cell *matCellDef="let element"> {{element.originalReportName}} </td>
    </ng-container>

    <ng-container matColumnDef="opportunityType">
      <th mat-header-cell *matHeaderCellDef> Opportunity Type </th>
      <td mat-cell *matCellDef="let element"> {{element.oppertunityTypeDescription}} </td>
    </ng-container>


    <ng-container matColumnDef="assignedValue">
      <th mat-header-cell *matHeaderCellDef> Assigned Value </th>
      <td mat-cell *matCellDef="let element"> {{element.assignedValue | currency:'USD'}} </td>
    </ng-container>


    <ng-container matColumnDef="actionableBy">
      <th mat-header-cell *matHeaderCellDef> Actionable by </th>
      <td mat-cell *matCellDef="let element"> {{element.actionableBy}} </td>
    </ng-container>

    <ng-container matColumnDef="dueBy">
      <th mat-header-cell *matHeaderCellDef> Due by </th>
      <td mat-cell *matCellDef="let element"> {{element.dueBy}} </td>
    </ng-container>


    <ng-container matColumnDef="age">
      <th mat-header-cell *matHeaderCellDef> Age </th>
      <td mat-cell *matCellDef="let element"> {{calculateAge(element.createdOn)}} </td>
    </ng-container>


    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon style="cursor: pointer;" (click)="deleteJourney(element)">delete</mat-icon>
        <mat-icon style="cursor: pointer;" (click)="editJourney(element)">edit</mat-icon>
        <mat-icon style="cursor: pointer;" (click)="viewJourney(element)">visibility</mat-icon>
        <mat-icon style="cursor: pointer;" (click)="shareJourney(element)">share</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- <div class="all-show-grid my-journey-ag-grid">
    <ag-grid-angular #foreCastManagerGrid id="seasonality-data-grid" class="ag-theme-material forecast-manager-grid"
      [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowData]="gridData" [defaultColDef]="defaultColDef"
      [suppressRowClickSelection]="true" [frameworkComponents]="frameworkComponents" [animateRows]="true"
      [pagination]="true" [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div> -->

</div>