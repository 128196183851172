import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { ItemDialogComponent } from 'src/modules/item-manager/features';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class ChildItemService {

  // Variables
  childItemDialogRef: MatDialogRef<ItemDialogComponent>;

  // Subjects
  public childGridActionSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setChildGridActionSubject = (value: any) => { this.childGridActionSubject.next(value); };

  // Observables
  getChildGridActionSubject = () => this.childGridActionSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  CreateChildItem(masterItem: any) {
    return this.http.post<any>(`${environment.AppChildItem}/add`, masterItem)
      .pipe(map(data => {
        return data;
      }));
  }

  UpdateChildItem(masterItem: any) {
    return this.http.put(`${environment.AppChildItem}/update`, masterItem)
      .pipe(map(data => {
        return data;
      }));
  }

  DeleteChildItem(childItemId: string) {
    return this.http.delete(`${environment.AppChildItem}/delete?ChildItemId=${childItemId}`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetAllChildItems(requestBody) {
    return this.http.post(`${environment.AppChildItem}/getall`, requestBody)
      .pipe(map(childItems => {
        return childItems;
      }));
  }

  GetAllChildItemByMasterItemId(masterItemId) {
    return this.http.get(`${environment.AppChildItem}/getlist?MasterItemId=${masterItemId}`)
      .pipe(map(childItems => {
        return childItems;
      }));
  }

  GetFilterChildItemList(body) {
    const query = Object.keys(body).map(key => {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppChildItem}/getlist?${query}`)
      .pipe(map(childItems => {
        return childItems;
      }));
  }

  ExportChildDataTemplate() {
    return this.http.get(`${environment.AppChildItem}/getexporttemplate`)
      .pipe(map(res => {
        return res;
      }));
  }

  ImportChildDataTemplate(body) {
    return this.http.post(`${environment.AppChildItem}/import`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  UpdateChildItemImage(body) {
    return this.http.post(`${environment.AppChildItem}/uploadimage`, body)
    .pipe(map(data => {
      return data;
    }));
  }

  upcValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value.toString().length === 12) {
        return this.checkIfUPCExists(control.value).pipe(
          map(res => {
            // if res is true, username exists, return true
            return res ? { upcExists: true } : null;
            // NB: Return null if there is no error
          })
        );
      } else {
        return null;
      }
    };
  }

  checkIfUPCExists(upc: string): Observable<boolean> {
    return this.http.get(`${environment.AppChildItem}/validateupc?upc=${upc}`).pipe(map((res: boolean) => {
      return res;
    }));
  }

}
