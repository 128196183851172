import { Component, OnInit, Input } from '@angular/core';
import { generateTitleDiv, undefinedImagePath } from 'src/modules/empower-module/helper';
import { EmpowerGamingService } from 'src/services/empower-services/empower-gaming.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';


@Component({
  selector: 'empower-retail-demo-experienced',
  templateUrl: './empower-retail-demo-experienced.component.html',
  styleUrls: ['./empower-retail-demo-experienced.component.scss']
})
export class EmpowerRetailDemoExperiencedComponent implements OnInit {

  @Input() data?: any;
  imageUrl = "../assets/empower/xbox-pitch-factory/images/26-screen-02.png";

  innerHtml = '';
  constructor(public empowerGamingService: EmpowerGamingService, private empowerExportService: EmpowerExportService,) { }

  ngOnInit(): void {
    if (this.data) {
      this.data = JSON.parse(this.data);

      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const imageUrl = this.empowerGamingService.getPageImgState(pageId);
    if (imageUrl) {
      this.imageUrl = imageUrl;
    }
    this.innerHtml = generateTitleDiv(this.data);
  }

  imageChange(imageUrl) {
    this.empowerGamingService.setPageImgState(this.data.pageId, imageUrl);
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(26, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }


}
