export const clusterTechniques = [
  {
    value: 1,
    label: 'K-means clustering'
  },
  {
    value: 2,
    label: 'Hierarchical clustering'
  }
];

export const methods = [
  {
    value: 1,
    label: 'Nearest neighbor'
  },
  {
    value: 2,
    label: 'Furthest neighbor'
  },
  {
    value: 3,
    label: 'Centroid clustering'
  },
  {
    value: 4,
    label: 'Median clustering'
  },
  {
    value: 5,
    label: "Ward's method"
  },
];

export const intervals = [
  {
    value: 1,
    label: 'Euclidean distance'
  },
  {
    value: 2,
    label: 'Squared Euclidean distance'
  },
  {
    value: 3,
    label: 'Block'
  },
  {
    value: 4,
    label: 'Chebychev'
  },
  {
    value: 5,
    label: 'Cosine'
  },
  {
    value: 6,
    label: 'Minkowski'
  },
  {
    value: 7,
    label: 'Pearson correlation'
  }
];

export const transformations = [
  {
    value: 1,
    label: 'None'
  },
  {
    value: 2,
    label: 'Normalize'
  },
  {
    value: 3,
    label: 'Absolute values'
  },
  {
    value: 4,
    label: 'Rescale 0-1'
  }
];

export const dataScaling = [
  {
    value: 1,
    label: 'None'
  },
  {
    value: 2,
    label: 'Normalize'
  },
  {
    value: 3,
    label: 'Absolute values'
  },
  {
    value: 4,
    label: 'Rescale 0-1'
  }
];

export const matrices = [
  'Salary',
  'Age',
  'Weight',
  'Wage',
  'House',
  'Car',
  'Fuel',
  'AlgoAI'
];
