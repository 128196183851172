
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-login-history',
  templateUrl: './user-login-history.component.html',
  styleUrls: ['./user-login-history.component.css']
})
export class UserLoginHistoryComponent implements OnInit {

  gridApi: any;
  loginHistoryList = []
  columnDefs = [{
    headerName : "Login Time",
    field:"loginTime",
    valueFormatter: this.dateFormatter.bind(this)
  },
  {
    headerName : "Logout Time",
    field:"logoutTime",
    valueFormatter: this.dateFormatter.bind(this)
  },
  {
    headerName : "Login Attempt",
    field:"loginAttempt"
  }
]
  constructor(public dialogRef: MatDialogRef<UserLoginHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.loginHistoryList = this.data.userLoginActivity
    }

  ngOnInit(): void {
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }
  dateFormatter(params) {

    if (params.value)
      return new Date(params.value).toLocaleString()
    return ""
  }
}
