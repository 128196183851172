import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProcurementService } from 'src/services/procurement.service';
@Component({
  selector: 'app-pms-add-edit-location',
  templateUrl: './pms-add-edit-location.component.html',
  styleUrls: ['./pms-add-edit-location.component.scss'],
})
export class PmsAddEditLocationComponent implements OnInit {
  addEditForm: FormGroup;
  editMode = false;
  constructor(
    public dialogRef: MatDialogRef<PmsAddEditLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private procurementService: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService
  ) {}
  ngOnInit(): void {
    this.initializeForm();
    if (this.data.id) {
      this.editMode = true;
      this.fetchDataById(this.data.id);
    }
  }
  fetchDataById(id) {
    this.spinner.show();
    this.procurementService.getLocationById(id).subscribe(
      (res: any) => {
        this.addEditForm.patchValue(res);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  initializeForm() {
    this.addEditForm = this.formBuilder.group({
      locationId: 0,
      locationName: [null, Validators.required],
      currencyCode: [null, Validators.required],
    });
  }
  onSaveClick() {
    this.spinner.show();
    if (this.editMode) {
      this.procurementService.updateLocation(this.addEditForm.value).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toastrService.success('Location updated successfully.');
          this.dialogRef.close(true);
        },
        (err) => {
          this.toastrService.error('Failed to update the location.');
          this.spinner.hide();
        }
      );
    } else {
      this.procurementService.addLocation(this.addEditForm.value).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toastrService.success('Location added successfully.');
          this.dialogRef.close(true);
        },
        (err) => {
          this.toastrService.error('Failed to add location.');
          this.spinner.hide();
        }
      );
    }
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
