<div class="mb-4">
    <button type="button" mat-button mat-flat-button color="accent" [disabled]="!selection.hasValue() || loading"
        (click)="bulkUpdate()">Bulk update</button>
</div>
<div mat-dialog-content class="mat-elevation-z8">
    <table mat-table [dataSource]="tableData">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="weeks_old">
            <th mat-header-cell *matHeaderCellDef> Age </th>
            <td mat-cell *matCellDef="let element"> {{element.weeks_old}} </td>
        </ng-container>

        <ng-container matColumnDef="tw_forecast">
            <th mat-header-cell *matHeaderCellDef> TW Forecast </th>
            <td mat-cell *matCellDef="let element"> {{element.tw_forecast}} </td>
        </ng-container>

        <ng-container matColumnDef="on_hand">
            <th mat-header-cell *matHeaderCellDef> On Hand </th>
            <td mat-cell *matCellDef="let element"> {{element.on_hand}} </td>
        </ng-container>

        <ng-container matColumnDef="on_order">
            <th mat-header-cell *matHeaderCellDef> On Order </th>
            <td mat-cell *matCellDef="let element"> {{element.on_order}} </td>
        </ng-container>

        <ng-container matColumnDef="base_ca">
            <th mat-header-cell *matHeaderCellDef> Base CA </th>
            <td mat-cell *matCellDef="let element"> {{element.base_ca}} </td>
        </ng-container>

        <ng-container matColumnDef="calc_ca">
            <th mat-header-cell *matHeaderCellDef> Calc. CA </th>
            <td mat-cell *matCellDef="let element"> {{element.calc_ca}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element; let i = index"> <a (click)="singleItemEdit(element, i)">Edit</a> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>
</div>

<div mat-dialog-actions style="justify-content: flex-end;">
    <button type="button" mat-button mat-flat-button color="accent" [disabled]="loading" (click)="close()">Cancel</button>
    <button type="button" mat-button mat-flat-button color="accent" [disabled]="updatedIndices.length==0 || loading" (click)="updateButton()">{{loading?'Updating...':'Update'}}</button>
</div>