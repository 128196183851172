<br>

<!-- <div class="label-value">
  <label><strong> {{clientInfo.clientName}}</strong></label>
</div> -->

<mat-horizontal-stepper>
  <mat-step label="Item Hierarchy Manager ">
    <app-item-hierarchy-manager></app-item-hierarchy-manager>
  </mat-step>

  <mat-step label="Location Hierarchy Manager">
    <app-location-hierarchy-manager></app-location-hierarchy-manager>
  </mat-step>
  <!-- <mat-step label="Relation Setup">
  </mat-step> -->

</mat-horizontal-stepper>
