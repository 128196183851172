<h1 mat-dialog-title>{{editMode ? 'Update' : 'Add'}} Item</h1>
<form mat-dialog-content [formGroup]="addEditForm">
    <div class="row m-0">
        <div class="col-md-6">
            <strong>Item Name*</strong>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter name" matInput formControlName="itemName">
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <strong>Item Type*</strong>
            <mat-form-field appearance="fill">
                <mat-select placeholder="Select item type" formControlName="purchaseTypeId">
                    <mat-option value="{{item.id}}" *ngFor="let item of typesArray">{{item.title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="accent" (click)="onSaveClick()" [disabled]="addEditForm.invalid">{{editMode ? 'Update' : 'Save'}}</button>
</div>
