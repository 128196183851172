import { Component, OnInit } from '@angular/core';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as _ from 'lodash';
import { of, Observable } from 'rxjs';
import { getLocationHierarchyColDefs, getLocationHierarchyHiddenColDefs } from '../ag-grid/grid-options';
import {  GridApi, ColumnApi, GridOptions,  ColDef } from 'ag-grid-enterprise';
import { defaultColDef } from '../../hierarchy-setup/calendar-setup/ag-grid/grid-options';
import { LocationHierarchyManagerService } from 'src/services/setup-services/location-hierarchy-manager.service';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocationHierarchyManagerEditorComponent } from './location-hierarchy-manager-editor/location-hierarchy-manager-editor.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-location-hierarchy-manager',
  templateUrl: './location-hierarchy-manager.component.html',
  styleUrls: ['./location-hierarchy-manager.component.scss']
})
export class LocationHierarchyManagerComponent implements OnInit {
  locationHierarchyManagerLevel: any[] = [];
  locationHierarchyList: any = [];
  filteredLocationHierarchyList: Observable<any[]>;
  locationHierarchyRowResponse: any[] = [];

  public gridOptions: GridOptions = {
    frameworkComponents: {
      nameRenderer: NameRendererComponent,
      deleteButtonRenderer: ButtonRendererComponent
    },
  };

  public defaultColDef = defaultColDef;

  public hiddenGridApi: GridApi;
  public hiddenColApi: ColumnApi;
  hiddenGridData: any = []
  public columnHiddenDefs = [];

  public gridApi: GridApi;
  public colApi: ColumnApi;
  gridData: any = [];
  locationGridColumnDefs: ColDef[] = [];


  constructor(
    private locationHierarchyManagerService: LocationHierarchyManagerService,
    public spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialog: MatDialog,
    public toastr: NgxToasterService,

  ) { }

  ngOnInit() {
    this.getLocationHierarchyLevelList();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.colApi;
  }

  onHiddenGridReady(params) {
    this.hiddenGridApi = params.api;
  }

  getLocationHierarchyLevelList() {
    this.spinner.show();
    this.locationHierarchyManagerService.GetProductHierarchyList().pipe(finalize(() => this.spinner.hide()))
      .subscribe(response => {
        this.getLocationHierarchyList();
        if (response) {
          this.locationHierarchyManagerLevel = response.locationMetadataList;
          this.columnHiddenDefs = getLocationHierarchyHiddenColDefs({ ...this.locationHierarchyManagerLevel });
          this.locationGridColumnDefs = getLocationHierarchyColDefs(this.locationHierarchyManagerLevel, this);
        }
      },
        err => {
          this.toastr.error('Error', 'System failed to Get Location Hierarchy Level List.');
        });
  }

  getLocationHierarchyList() {
    this.spinner.show();
    this.locationHierarchyManagerService.GetLocationHierarchyList().pipe(finalize(() => this.spinner.hide())).subscribe((response: any) => {
      this.gridData = response;
    },
      err => {
        this.toastr.error('Error', 'System failed to Get Location Hierarchy.');
      });
  }

  onExportData() {
    this.hiddenGridData = this.locationHierarchyRowResponse;
    this.hiddenGridApi && this.hiddenGridApi.setRowData(this.hiddenGridData);
    setTimeout(() => {
      this.exportDataTemplate();
    }, 200);
  }

  importNewLocationWeeklyData() {
    (document.getElementById('upload-new-item-approval-promotion-data') as any).value = '';
    document.getElementById('upload-new-item-approval-promotion-data').click();
  }

  exportDataTemplate() {
    const csvExportParams = gridDataExportParams({
      fileName: 'LocationHierarchyManager',
      colDefs: this.columnHiddenDefs,
      isExportTemplate: true,
      allColumns: true
    });
    this.hiddenGridApi.exportDataAsCsv(csvExportParams);
  }

  handleExport(event) {
    event.stopPropagation();
  }

  validateUploadedCSV(fileInput: any) {
    const formDataReader = new FormData();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [files] = fileInput.target.files;
      formDataReader.append('file', files)
      this.uploadImportedCsvFile(formDataReader)
    }
  }

  uploadImportedCsvFile(importDataFile) {
    this.locationHierarchyManagerService.ImportLocationHierarchyManagerList(importDataFile).subscribe(
      (res: any) => {
        if (res) {
          const code = _.get(res, '0.messageCode', 400);
          const message = _.get(res, '0.messageText', 'Location Hierarchy Manager Imported Successfully.');
          if (code === 200) {
            this.getLocationHierarchyList();
            this.toastr.success('Success', `${message}`);
          } else {
            res.forEach(element => {
              this.toastr.error('Error', `${element.messageText}`);
            });
          }
        }
      },
      (err: any) => {
        this.toastr.error('Error', 'Location Hierarchy Manager Import Failed.');
      });
  }

  addEditLocationHierarchyRow(isEdit: boolean = false, rowData: any = {}) {

    const dialogRef = this.dialog.open(LocationHierarchyManagerEditorComponent, {
      panelClass: 'editor-dialog',
      width: '800px',
      minHeight: '250px',
      disableClose: true,
      data: {
        isEdit: isEdit,
        rowData: rowData,
        itemHierarchyManagerLevel: _.cloneDeep(this.locationHierarchyManagerLevel),
        gridData: this.gridData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getLocationHierarchyList();
      }
    });
  }

  navigateToHierarchySetup(row: any) {
    this.addEditLocationHierarchyRow(true, row.rowData);
  }

  deleteLocationHierarchyManager(row: any) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to delete data?' }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.locationHierarchyManagerService.DeleteLocationHierarchyRow(row.rowData.locationHierarchyRelationId).pipe(finalize(() => this.spinner.hide())).subscribe(res => {
          if (res) {
            this.getLocationHierarchyList();
            this.toastr.success('Success', 'Location Hierarchy Deleted successfully.');
          }else {
            this.toastr.error('Error', 'System failed to delete Location hierarchy.');
          }
        },
          err => {
            this.toastr.error('Error', 'System failed to delete Location hierarchy.');
          });
      }
    });
  }
}
