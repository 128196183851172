<div class="container mt-5">
  <form *ngIf="evaluationForm" [formGroup]="evaluationForm">

    <div class="row">
      <div class="form-group w-50">

        <label for="vendor">Vendor*</label>

        <mat-form-field appearance="fill">
          <mat-select placeholder="Select" formControlName="vendorId" (selectionChange)="vendorSelectionChange($event.value)">
            <mat-option *ngFor="let vendor of vendorsList" [value]="vendor.vendorId">{{ vendor.vendorName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </div>

    <div class="row form-group">
      <div class="mr-5 radioLabel">
        <label for="quality">Quality of product delivered*</label>
      </div>
      <div>
        <mat-radio-group class=" inline-flex radio-btn-grp w-100" labelPosition="after" name="quality"
          formControlName="quality">
          <mat-radio-button class="mx-1" value=1>Good</mat-radio-button>
          <mat-radio-button class="mx-1" value=2>Averge</mat-radio-button>
          <mat-radio-button class="mx-1" value=3>Poor</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row form-group">
      <div class="mr-5 radioLabel">
        <label for="deliveryTime">Product delivery time*</label>
      </div>
      <div>
        <mat-radio-group class=" inline-flex radio-btn-grp w-100" labelPosition="after" name="deliveryTime"
          formControlName="deliveryTime">
          <mat-radio-button class="mx-1" value=4>On Time</mat-radio-button>
          <mat-radio-button class="mx-1" value=5>Delayed</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row form-group">
      <div class="mr-5 radioLabel">
        <label for="afterSaleService">After Sales Services*</label>
      </div>
      <div>
        <mat-radio-group class=" inline-flex radio-btn-grp w-100" labelPosition="after" name="afterSaleService"
          formControlName="afterSaleService">
          <mat-radio-button class="mx-1" value=1>Good</mat-radio-button>
          <mat-radio-button class="mx-1" value=2>Averge</mat-radio-button>
          <mat-radio-button class="mx-1" value=3>Poor</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row form-group">
      <div class="mr-5 radioLabel">
        <label for="warrantyCover">Warranty Coverage*</label>
      </div>
      <div>
        <mat-radio-group class=" inline-flex radio-btn-grp w-100" labelPosition="after" name="warrantyCover"
          formControlName="warrantyCover">
          <mat-radio-button class="mx-1" value=5>Immidiate</mat-radio-button>
          <mat-radio-button class="mx-1" value=5>Delayed</mat-radio-button>
          <mat-radio-button class="mx-1" value=7>Never</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row form-group">
      <div class="mr-5 radioLabel">
        <label for="creditTerms">Credit terms*</label>
      </div>
      <div>
        <mat-radio-group class=" inline-flex radio-btn-grp w-100" labelPosition="after" name="creditTerms"
          formControlName="creditTerms">
          <mat-radio-button class="mx-1" value=8>Lenient</mat-radio-button>
          <mat-radio-button class="mx-1" value=9>Strict</mat-radio-button>
          <mat-radio-button class="mx-1" value=10>Not Credit</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row form-group">
      <div class="mr-5 radioLabel">
        <label for="orderCormormation">Conformance of order*</label>
      </div>
      <div>
        <mat-radio-group class=" inline-flex radio-btn-grp w-100" labelPosition="after" name="orderCormormation"
          formControlName="orderCormormation">
          <mat-radio-button class="mx-1" value=1>Good</mat-radio-button>
          <mat-radio-button class="mx-1" value=2>Averge</mat-radio-button>
          <mat-radio-button class="mx-1" value=3>Poor</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row form-group">
      <div class="mr-5 radioLabel">
        <label for="overAllEvaluation">Conformance of order*</label>
      </div>
      <div>
        <mat-radio-group class=" inline-flex radio-btn-grp w-100" labelPosition="after" name="overAllEvaluation"
          formControlName="overAllEvaluation">
          <mat-radio-button class="mx-1" value=11>New</mat-radio-button>
          <mat-radio-button class="mx-1" value=12>Satisfactory</mat-radio-button>
          <mat-radio-button class="mx-1" value=13>Not Satisfactory</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>


    <div class=" form-group w-50">
      <div class="mr-5 radioLabel">
        <label for="otherDetail">Other Details</label>
      </div>
      <mat-form-field appearance="fill">
        <textarea matInput cdkTextareaAutosize formControlName="otherDetail" #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="d-flex w-50 justify-content-end">
  <button  mat-raised-button color="accent" (click)="onSubmit()"
  [disabled]="evaluationForm?.invalid">Submit</button>
      </div>
  </form>
  <div class="col mt-5 w-50 align-items-center">

    <span *ngIf="this.vendorSelected" (click)="showHistory()" class="mb-2 linkBtn">View Previous Evaluation</span>

   <ng-container  *ngIf="this.showVendorHistory" >

    <div class="row mt-2 ">
      <div class="mr-5 radioLabel">
        <label for="deliveryTime">Last Evaluation Date:</label>
      </div>
      <div>
        <p>{{this.vendorHistory?.evaulationDate}}</p>
      </div>
    </div>

    <div class="row mt-3 ">
        <div class="mr-5 radioLabel">
          <label for="quality">Quality of product delivered:</label>
        </div>
        <div>
          <p>{{this.vendorHistory?.qualityDiscription}}</p>
        </div>
      </div>
      <div class="row mt-2 ">
        <div class="mr-5 radioLabel">
          <label for="deliveryTime">Product delivery time:</label>
        </div>
        <div>
          <p>{{this.vendorHistory?.deliveryTimeDiscription}}</p>
        </div>
      </div>
      <div class="row mt-2 ">
        <div class="mr-5 radioLabel">
          <label for="afterSaleService">After Sales Services:</label>
        </div>
        <div>
          <p>{{this.vendorHistory?.afterSaleServiceDiscription}}</p>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class="mr-5 radioLabel">
          <label for="warrantyCover">Warranty Coverage:</label>
        </div>
        <div>
          <p>{{this.vendorHistory?.warrantyCoverDiscription}}</p>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class="mr-5 radioLabel">
          <label for="creditTerms">Credit terms:</label>
        </div>
        <div>
          <p>{{this.vendorHistory?.creditTermsDiscription}}</p>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class="mr-5 radioLabel">
          <label for="orderCormormation">Conformance of order:</label>
        </div>
        <div>
          <p>{{this.vendorHistory?.orderCormormationDiscription}}</p>
        </div>
      </div>

      <div class="row mt-2 ">
        <div class="mr-5 radioLabel">
          <label for="overAllEvaluation">Conformance of order:</label>
        </div>
        <div>
          <p>{{this.vendorHistory?.overAllEvaluationDiscription}}</p>
        </div>
      </div>
      <div class=" row mt-2">
        <div class="mr-5 radioLabel">
          <label for="otherDetail">Other Details:</label>
        </div>
        <p>{{this.vendorHistory?.otherDetail}}</p>
      </div>
    </ng-container>

  </div>

</div>
