<!-- Main Content area -->
<div class="container">
  <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="row">
      <div class="col-md-4 form-group"></div>
    </div>
    <div class="row" style="justify-content: center;">
      <div class="col-md-5 col-width">
        <div class="row">
          <div class="col-12 form-group">
            <mat-form-field>
              <input matInput formControlName="Password" type="password" placeholder="Old Password" />
            </mat-form-field>
          </div>
          <div class="col-12 form-group">
            <mat-form-field>
              <input matInput formControlName="ConfirmPassword" type="password" placeholder="New Password" />
            </mat-form-field>
          </div>
          <div class="col-12 action-btns text-right">
            <button mat-raised-button class="btn btn-primary m-left" color="primary" type="submit">
              Update
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-width form-group" style="display: flex; margin-top: 10px; padding-left: 40px;">
        <div>
          <ul style="color: #5fa0a3">
            <li *ngIf="
              resetPasswordForm.controls['Password'].hasError(
                'lengthAppropriate'
              ) ||
              !submitted ||
              resetPasswordForm.controls['ConfirmPassword'].hasError(
                'lengthAppropriate'
              )
            ">
              Password created must be at least 8 characters
            </li>
            <li *ngIf="!submitted">Not be a perior or similar password</li>
            <li *ngIf="
              resetPasswordForm.controls['Password'].hasError('strong') ||
                resetPasswordForm.controls['ConfirmPassword'].hasError(
                  'strong'
                )!;
              submitted
            ">
              Contain at least each of the following
              <ul>
                <li>Upper Case</li>
                <li>Lower Case</li>
                <li>Number</li>
                <li>Special Character</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="col-md-4 form-group">
    <mat-form-field  floatLabel="never">
      <input matInput  formControlName="Password" type="password" onfocus="this.removeAttribute('readonly');" placeholder="New Password" >
    </mat-form-field>
  </div>

  <div class="col-md-4 form-group">
    <mat-form-field  floatLabel="never">
      <input matInput  formControlName="ConfirmPassword" type="password" onfocus="this.removeAttribute('readonly');" placeholder="Confirm Password" >
    </mat-form-field>
  </div> -->
    </div>
    <!-- <div class="row">
  <div class="col-md-4 form-group">
   <p>Password Instructions</p>
    <div style="color: cadetblue;"  >Minimum password length is 8 <br>
      Must contain one caps<br>
      Must contain one special character<br>
      Must contain one number<br>
    </div>
  </div>

</div> -->
    <!-- <div class="action-btns text-right">
  <button mat-raised-button class="btn btn-primary m-left" color="primary"
    type="submit">Update</button>
</div>   -->
  </form>
</div>
