import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  cacheBlockSize,
  defaultColDef,
  generateColumnDefs,
  locationColumns,
  paginationPageSize,
} from '../ag-grid/gridOptions';

import { NgxSpinnerService } from 'ngx-spinner';
import { ProcurementService } from 'src/services/procurement.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PmsAddEditLocationComponent } from './pms-add-edit-location/pms-add-edit-location.component';
import { element } from 'protractor';
@Component({
  selector: 'pms-locations',
  templateUrl: './pms-locations.component.html',
  styleUrls: ['./pms-locations.component.scss'],
})
export class PmsLocationsComponent implements OnInit {
  columns = locationColumns;
  filtersForm: FormGroup;
  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  defaultColDef = defaultColDef;
  public gridColumnDefs = generateColumnDefs(this.columns);

  public gridOptions: any;
  private gridApi;
  frameworkComponents = {};
  dataSource = [];
  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private procurementService: ProcurementService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.initializeFilterForm();
    this.fetchData();
  }
  clearFilter() {
    this.initializeFilterForm();
    this.fetchData();
  }
  initializeFilterForm() {
    this.filtersForm = this.formBuilder.group({
      locationName: [''],
      currencyCode: [''],
    });
    this.filtersForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((res: any) => {
        this.fetchData();
      });
  }
  fetchData() {
    this.spinner.show();
    this.dataSource = [];
    this.procurementService
      .getLocationsListing(
        this.filtersForm.get('locationName').value,
        this.filtersForm.get('currencyCode').value
      )
      .subscribe(
        (res: any) => {
          this.dataSource = res;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  addEditLocation(id = null) {
    const confirmationDialogRef = this.dialog.open(
      PmsAddEditLocationComponent,
      {
        width: '500px',
        data: { id: id },
      }
    );
    confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchData();
      }
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  cellClicked(event) {
    if (
      event &&
      event.value &&
      event.colDef &&
      event.colDef.field &&
      event.colDef.field == 'locationName'
    ) {
      const selectedRow = this.dataSource.find(
        (element) => element.locationName == event.value
      );
      this.addEditLocation(selectedRow.locationId);
    }
  }
}
