import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/services/User-services/user-services';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ImageRendererComponent } from 'src/common/image-renderer';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { AddEditClientComponent } from './add-edit-client/add-edit-client.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  clientsList = [];

  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public roleList: any;

  public user: any;
  headerHeight = 45;
  public rowHeight;
  public defaultColDef;

  public columnDefs = [
    {
      headerName: 'Client Name',
      field: 'clientName',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.openForm.bind(this),
      },
    },
    {
      headerName: 'Description',
      resizable: true,
      sortable: false,
      filter: false,
      suppressMenu: true,
      field: 'description',
    },
    // {
    //   headerName: 'Email',
    //   resizable: true,
    //   sortable: false,
    //   filter: false,
    //   suppressMenu: true,
    //   field: 'email',
    // },
    {
      headerName: 'Logo',
      resizable: false,
      sortable: false,
      filter: false,
      suppressMenu: true,
      field: 'logo',
      cellRenderer: 'imageRenderer',
    }
  ];

  constructor(private userService: UsersService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public dialog: MatDialog,
    public toastr: NgxToasterService) { }

  ngOnInit(): void {
    this.gridOptions = {
      frameworkComponents: {
        'nameRenderer': NameRendererComponent,
        'deleteButtonRenderer': ButtonRendererComponent,
        'imageRenderer': ImageRendererComponent
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    }
  }

  private getClientsList = () => {
    this.gridApi.showLoadingOverlay();
    this.userService.GetAllClients().subscribe((res: any) => {
      this.clientsList = res;
      this.gridApi.hideOverlay();
      if (this.clientsList.length == 0) this.gridApi.showNoRowsOverlay();
    },
    err => {
      this.gridApi.hideOverlay();
    });
  }

  openForm(obj = null) {
    const dialogRef = this.dialog.open(AddEditClientComponent, {
      width: '850px',
      data: obj ? obj.rowData : {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getClientsList();
      }
    });
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.rowHeight = 50;
    this.getClientsList();
  }

  exportExcel = () => {
    try {
      this.gridApi.exportDataAsExcel();
    }
    catch {
      this.gridApi.exportDataAsCsv();
    }
  }

}
