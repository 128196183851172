
export const getChildItems = () => {
    return [
        {
            "Item_ID": "1",
            "Make": "INFINITI",
            "Model": "QX50",
            "Year": "2010",
            "SKU": "140472020",
            "Title": "A/C Compressor Mopar R5056108AE",
            "Brand": "Mopar",
            "ManufacturePartNumber": "R5056108ae",
            "Class": "A/C Compressor",
            "SubClass": "A/C Clutch and Compressor",
            "Month": "Jan 22",
            "Promo_Name": "A/C",
            "Promo_Name_Group": "A/C - Jan 22",
            "Status": "EOL",
            "Cost": "590",
            "Retail": "651",
            "Gross_Margin": "61",
            "Gross_Margin_Percentage": "23",
            "Rev": "527780",
            "Initial_Order_Quantity": "887",
            "Start_Date": "9/29/22",
            "End_Date": "5/4/23",
            "Forecast": "811",
            "Submitted_By": "Factory C",
            "Submitted_Date": "6/4/22",
            "Algo_Recommended_EOL": "8/23/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "2",
            "Make": "Kia",
            "Model": "Seltos",
            "Year": "2000",
            "SKU": "179439622",
            "Title": "A/C Compressor and Clutch C-2143R Reman fits 2008 Mitsubishi Lancer 2.0L-L4",
            "Brand": "1-800-Radiator & AC/Reman",
            "ManufacturePartNumber": "C-2143r",
            "Class": "A/C Compressor and Clutch",
            "SubClass": "A/C Clutch and Compressor",
            "Month": "Jan 22",
            "Promo_Name": "A/C",
            "Promo_Name_Group": "A/C - Jan 22",
            "Status": "EOL",
            "Cost": "628",
            "Retail": "681",
            "Gross_Margin": "53",
            "Gross_Margin_Percentage": "12",
            "Rev": "28138",
            "Initial_Order_Quantity": "54",
            "Start_Date": "10/22/22",
            "End_Date": "6/8/23",
            "Forecast": "41",
            "Submitted_By": "Factory C",
            "Submitted_Date": "5/6/22",
            "Algo_Recommended_EOL": "8/6/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "3",
            "Make": "Chevrolet",
            "Model": "Silverado 3500 HD Double Cab",
            "Year": "2021",
            "SKU": "174859531",
            "Title": "A/C Compressor Clutch Ready-Rad 1353",
            "Brand": "Proliance Intl/Ready-Rad",
            "ManufacturePartNumber": "1353",
            "Class": "A/C Compressor Clutch",
            "SubClass": "A/C Clutch and Compressor",
            "Month": "Jan 22",
            "Promo_Name": "A/C",
            "Promo_Name_Group": "A/C - Jan 22",
            "Status": "EOL",
            "Cost": "612",
            "Retail": "677",
            "Gross_Margin": "65",
            "Gross_Margin_Percentage": "20",
            "Rev": "571395",
            "Initial_Order_Quantity": "908",
            "Start_Date": "8/31/22",
            "End_Date": "3/28/23",
            "Forecast": "844",
            "Submitted_By": "Factory C",
            "Submitted_Date": "7/8/22",
            "Algo_Recommended_EOL": "8/19/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "4",
            "Make": "Kia",
            "Model": "Sportage Plug-in Hybrid",
            "Year": "2002",
            "SKU": "24053036324",
            "Title": "Cooling Depot 11796R A/C Clutch and Compressor - A/C Compressor Kit",
            "Brand": "Cooling Depot",
            "ManufacturePartNumber": "11796r",
            "Class": "A/C Compressor Kit",
            "SubClass": "A/C Clutch and Compressor",
            "Month": "Jan 22",
            "Promo_Name": "A/C",
            "Promo_Name_Group": "A/C - Jan 22",
            "Status": "Pitched",
            "Cost": "588",
            "Retail": "684",
            "Gross_Margin": "96",
            "Gross_Margin_Percentage": "29",
            "Rev": "555648",
            "Initial_Order_Quantity": "875",
            "Start_Date": "10/10/22",
            "End_Date": "6/6/23",
            "Forecast": "812",
            "Submitted_By": "Factory C",
            "Submitted_Date": "7/18/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "5",
            "Make": "Toyota",
            "Model": "RAV4 Hybrid",
            "Year": "2022",
            "SKU": "243353035",
            "Title": "Cooling Depot 8525N A/C Clutch and Compressor - A/C Compressor Kit",
            "Brand": "Cooling Depot",
            "ManufacturePartNumber": "8525n",
            "Class": "A/C Compressor Kit",
            "SubClass": "A/C Clutch and Compressor",
            "Month": "Jan 22",
            "Promo_Name": "A/C",
            "Promo_Name_Group": "A/C - Jan 22",
            "Status": "Pitched",
            "Cost": "617",
            "Retail": "650",
            "Gross_Margin": "33",
            "Gross_Margin_Percentage": "14",
            "Rev": "45831",
            "Initial_Order_Quantity": "153",
            "Start_Date": "10/10/22",
            "End_Date": "4/27/23",
            "Forecast": "71",
            "Submitted_By": "Factory C",
            "Submitted_Date": "6/8/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "6",
            "Make": "Kia",
            "Model": "Seltos",
            "Year": "2010",
            "SKU": "230432803",
            "Title": "A/C Compressor Replacement Service Kit 1-800 Radiator EKIT4073",
            "Brand": "1-800 Radiator",
            "ManufacturePartNumber": "Ekit4073",
            "Class": "A/C Compressor Replacement Service Kit|A/C Compressor and Component Kit",
            "SubClass": "A/C Clutch and Compressor",
            "Month": "Jan 22",
            "Promo_Name": "A/C",
            "Promo_Name_Group": "A/C - Jan 22",
            "Status": "EOL",
            "Cost": "584",
            "Retail": "694",
            "Gross_Margin": "110",
            "Gross_Margin_Percentage": "18",
            "Rev": "239991",
            "Initial_Order_Quantity": "438",
            "Start_Date": "9/10/22",
            "End_Date": "6/6/23",
            "Forecast": "346",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/9/22",
            "Algo_Recommended_EOL": "8/13/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "7",
            "Make": "Toyota",
            "Model": "RAV4 Prime",
            "Year": "2006",
            "SKU": "5045205527",
            "Title": "CARQUEST/Worldpac WP1405126AIR Hoses and Pipes - A/C Refrigerant Liquid Hose",
            "Brand": "CARQUEST/WORLDPAC",
            "ManufacturePartNumber": "Wp-1405126-Air",
            "Class": "A/C Refrigerant Liquid Hose",
            "SubClass": "Hoses and Pipes",
            "Month": "Jan 22",
            "Promo_Name": "A/C",
            "Promo_Name_Group": "A/C - Jan 22",
            "Status": "EOL",
            "Cost": "571",
            "Retail": "685",
            "Gross_Margin": "114",
            "Gross_Margin_Percentage": "17",
            "Rev": "521104",
            "Initial_Order_Quantity": "796",
            "Start_Date": "9/23/22",
            "End_Date": "4/27/23",
            "Forecast": "761",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/6/22",
            "Algo_Recommended_EOL": "8/19/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "8",
            "Make": "Toyota",
            "Model": "RAV4",
            "Year": "2010",
            "SKU": "220173258",
            "Title": "Car Cover-GLS, 4 Door, Sedan Crafted2Fit Car Covers fits 95-96 VW Passat",
            "Brand": "Crafted2Fit Custom Car Covers",
            "ManufacturePartNumber": "C14937py",
            "Class": "Car Cover",
            "SubClass": "Exterior Accessories",
            "Month": "Feb 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Feb 22",
            "Status": "EOL",
            "Cost": "577",
            "Retail": "666",
            "Gross_Margin": "89",
            "Gross_Margin_Percentage": "18",
            "Rev": "71700",
            "Initial_Order_Quantity": "181",
            "Start_Date": "10/25/22",
            "End_Date": "6/16/23",
            "Forecast": "108",
            "Submitted_By": "Factory C",
            "Submitted_Date": "7/19/22",
            "Algo_Recommended_EOL": "8/25/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "9",
            "Make": "Toyota",
            "Model": "Prius Prime",
            "Year": "2015",
            "SKU": "26050966752",
            "Title": "Coverking CVC6SS87GM8094 Exterior Accessories - Car Cover",
            "Brand": "Coverking",
            "ManufacturePartNumber": "Cvc6ss87gm8094",
            "Class": "Car Cover",
            "SubClass": "Exterior Accessories",
            "Month": "Feb 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Feb 22",
            "Status": "EOL",
            "Cost": "637",
            "Retail": "668",
            "Gross_Margin": "31",
            "Gross_Margin_Percentage": "30",
            "Rev": "472779",
            "Initial_Order_Quantity": "793",
            "Start_Date": "9/27/22",
            "End_Date": "4/12/23",
            "Forecast": "708",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/4/22",
            "Algo_Recommended_EOL": "8/19/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "10",
            "Make": "Acura",
            "Model": "Integra",
            "Year": "2017",
            "SKU": "220038046",
            "Title": "Car Cover-Base, 2 Door, Sedan Crafted2Fit Car Covers fits 2000 Toyota Echo",
            "Brand": "Crafted2Fit Custom Car Covers",
            "ManufacturePartNumber": "C16118tf",
            "Class": "Car Cover",
            "SubClass": "Exterior Accessories",
            "Month": "Feb 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Feb 22",
            "Status": "Pitched",
            "Cost": "564",
            "Retail": "682",
            "Gross_Margin": "118",
            "Gross_Margin_Percentage": "11",
            "Rev": "617024",
            "Initial_Order_Quantity": "945",
            "Start_Date": "8/26/22",
            "End_Date": "4/18/23",
            "Forecast": "905",
            "Submitted_By": "Factory A",
            "Submitted_Date": "5/13/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "11",
            "Make": "Ram",
            "Model": "ProMaster City",
            "Year": "2014",
            "SKU": "250585035",
            "Title": "Covercraft 17660376 Dashboard - Dashboard Cover",
            "Brand": "Covercraft",
            "ManufacturePartNumber": "1766-03-76",
            "Class": "Dashboard Cover",
            "SubClass": "Dashboard",
            "Month": "Feb 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Feb 22",
            "Status": "Pitched",
            "Cost": "641",
            "Retail": "658",
            "Gross_Margin": "17",
            "Gross_Margin_Percentage": "26",
            "Rev": "46595",
            "Initial_Order_Quantity": "142",
            "Start_Date": "8/31/22",
            "End_Date": "5/24/23",
            "Forecast": "71",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/10/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "12",
            "Make": "Nissan",
            "Model": "Z",
            "Year": "2004",
            "SKU": "228175828",
            "Title": "Roof Rack-Sport Rugged Ridge 11703.11",
            "Brand": "Rugged Ridge",
            "ManufacturePartNumber": "11703.11",
            "Class": "Roof Rack",
            "SubClass": "Roof",
            "Month": "Feb 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Feb 22",
            "Status": "Pitched",
            "Cost": "626",
            "Retail": "670",
            "Gross_Margin": "44",
            "Gross_Margin_Percentage": "24",
            "Rev": "176331",
            "Initial_Order_Quantity": "330",
            "Start_Date": "9/13/22",
            "End_Date": "5/25/23",
            "Forecast": "263",
            "Submitted_By": "Factory A",
            "Submitted_Date": "5/9/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "13",
            "Make": "Toyota",
            "Model": "bZ4X",
            "Year": "2009",
            "SKU": "12037497293",
            "Title": "Seat Cushion Cover-Cover Front Custom Tailored Seat Covers fits 2001 VW Passat",
            "Brand": "Custom Tailored Seat Covers",
            "ManufacturePartNumber": "Vw303-97ks",
            "Class": "Seat Cushion Cover",
            "SubClass": "Seats",
            "Month": "Feb 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Feb 22",
            "Status": "Pitched",
            "Cost": "643",
            "Retail": "678",
            "Gross_Margin": "35",
            "Gross_Margin_Percentage": "25",
            "Rev": "5538",
            "Initial_Order_Quantity": "19",
            "Start_Date": "8/28/22",
            "End_Date": "4/1/23",
            "Forecast": "8",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/30/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "14",
            "Make": "Kia",
            "Model": "Soul",
            "Year": "2010",
            "SKU": "251578886",
            "Title": "Seat Cushion Cover-Coupe Front Coverking CSC2PD37VW9347 fits 16-18 VW Beetle",
            "Brand": "Coverking",
            "ManufacturePartNumber": "Csc2pd37vw9347",
            "Class": "Seat Cushion Cover",
            "SubClass": "Seats",
            "Month": "Feb 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Feb 22",
            "Status": "Pitched",
            "Cost": "636",
            "Retail": "699",
            "Gross_Margin": "63",
            "Gross_Margin_Percentage": "27",
            "Rev": "316048",
            "Initial_Order_Quantity": "459",
            "Start_Date": "8/28/22",
            "End_Date": "5/3/23",
            "Forecast": "452",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/21/22",
            "Algo_Recommended_EOL": "8/19/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "15",
            "Make": "Toyota",
            "Model": "Sienna",
            "Year": "2013",
            "SKU": "179616549",
            "Title": "Seat Cushion Cover-Cover Custom Tailored Seat Covers fits 2011 Kia Sedona",
            "Brand": "Custom Tailored Seat Covers",
            "ManufacturePartNumber": "Ka124-05rr",
            "Class": "Seat Cushion Cover",
            "SubClass": "Seats",
            "Month": "Feb 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Feb 22",
            "Status": "EOL",
            "Cost": "636",
            "Retail": "696",
            "Gross_Margin": "60",
            "Gross_Margin_Percentage": "24",
            "Rev": "391902",
            "Initial_Order_Quantity": "602",
            "Start_Date": "10/21/22",
            "End_Date": "5/15/23",
            "Forecast": "563",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/3/22",
            "Algo_Recommended_EOL": "8/22/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "16",
            "Make": "Chevrolet",
            "Model": "Bolt EUV",
            "Year": "2020",
            "SKU": "28037540449",
            "Title": "Seat Cushion Cover-Convertible Front FD483-31NA fits 2015 Ford Mustang",
            "Brand": "Custom Tailored Seat Covers",
            "ManufacturePartNumber": "Fd483-31na",
            "Class": "Seat Cushion Cover",
            "SubClass": "Seats",
            "Month": "Mar 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Mar 22",
            "Status": "EOL",
            "Cost": "564",
            "Retail": "666",
            "Gross_Margin": "102",
            "Gross_Margin_Percentage": "24",
            "Rev": "357859",
            "Initial_Order_Quantity": "600",
            "Start_Date": "9/9/22",
            "End_Date": "4/28/23",
            "Forecast": "537",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/8/22",
            "Algo_Recommended_EOL": "8/24/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "17",
            "Make": "Kia",
            "Model": "Niro Plug-in Hybrid",
            "Year": "2007",
            "SKU": "93777808",
            "Title": "WeatherTech 72038 Side Body - Side Window Deflector, Left",
            "Brand": "WeatherTech",
            "ManufacturePartNumber": "72038",
            "Class": "Side Window Deflector",
            "SubClass": "Side Body",
            "Month": "Mar 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Mar 22",
            "Status": "EOL",
            "Cost": "645",
            "Retail": "672",
            "Gross_Margin": "27",
            "Gross_Margin_Percentage": "15",
            "Rev": "98998",
            "Initial_Order_Quantity": "230",
            "Start_Date": "8/27/22",
            "End_Date": "5/7/23",
            "Forecast": "147",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/19/22",
            "Algo_Recommended_EOL": "8/21/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "18",
            "Make": "Toyota",
            "Model": "GR Supra",
            "Year": "2018",
            "SKU": "7037543472",
            "Title": "Tie Wrap Heatshield Products 351001",
            "Brand": "Heatshield Products",
            "ManufacturePartNumber": "351001",
            "Class": "Tie Wrap",
            "SubClass": "Hand Tools",
            "Month": "Mar 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Mar 22",
            "Status": "EOL",
            "Cost": "601",
            "Retail": "681",
            "Gross_Margin": "80",
            "Gross_Margin_Percentage": "11",
            "Rev": "530148",
            "Initial_Order_Quantity": "817",
            "Start_Date": "8/31/22",
            "End_Date": "4/15/23",
            "Forecast": "778",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/8/22",
            "Algo_Recommended_EOL": "8/15/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "19",
            "Make": "Hyundai",
            "Model": "Veloster",
            "Year": "2002",
            "SKU": "6046896184",
            "Title": "Truck Bed Side Rail-Locker Side Rails - 5ft 8in beds CHEVY OEM ACCESSORIES",
            "Brand": "Chevrolet OEM Accessories",
            "ManufacturePartNumber": "19353772",
            "Class": "Truck Bed Side Rail",
            "SubClass": "Truck Box",
            "Month": "Mar 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Mar 22",
            "Status": "EOL",
            "Cost": "619",
            "Retail": "678",
            "Gross_Margin": "59",
            "Gross_Margin_Percentage": "25",
            "Rev": "535429",
            "Initial_Order_Quantity": "818",
            "Start_Date": "9/4/22",
            "End_Date": "5/10/23",
            "Forecast": "790",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/20/22",
            "Algo_Recommended_EOL": "8/12/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "20",
            "Make": "Toyota",
            "Model": "GR Supra",
            "Year": "2014",
            "SKU": "191847795",
            "Title": "Window Shade-Limited Covercraft UV10886SV",
            "Brand": "Covercraft",
            "ManufacturePartNumber": "Uv10886sv",
            "Class": "Window Shade",
            "SubClass": "Glass, Windows and Related Components",
            "Month": "Mar 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Mar 22",
            "Status": "EOL",
            "Cost": "617",
            "Retail": "651",
            "Gross_Margin": "34",
            "Gross_Margin_Percentage": "10",
            "Rev": "522492",
            "Initial_Order_Quantity": "860",
            "Start_Date": "9/23/22",
            "End_Date": "3/30/23",
            "Forecast": "803",
            "Submitted_By": "Factory A",
            "Submitted_Date": "5/15/22",
            "Algo_Recommended_EOL": "8/25/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "21",
            "Make": "Toyota",
            "Model": "Corolla Cross Hybrid",
            "Year": "2002",
            "SKU": "24043824663",
            "Title": "CARQUEST/Trico 74101 - Windshield Wiper Arm",
            "Brand": "Carquest",
            "ManufacturePartNumber": "74-101",
            "Class": "Windshield Wiper Arm",
            "SubClass": "Windshield Wiper Arm, Blade, and Related Components",
            "Month": "Mar 22",
            "Promo_Name": "Accessories",
            "Promo_Name_Group": "Accessories - Mar 22",
            "Status": "EOL",
            "Cost": "626",
            "Retail": "698",
            "Gross_Margin": "72",
            "Gross_Margin_Percentage": "12",
            "Rev": "46187",
            "Initial_Order_Quantity": "33",
            "Start_Date": "8/27/22",
            "End_Date": "5/10/23",
            "Forecast": "66",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/4/22",
            "Algo_Recommended_EOL": "8/11/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "22",
            "Make": "Kia",
            "Model": "Sedona",
            "Year": "2014",
            "SKU": "217700921",
            "Title": "PB/Monroe 901781 Doors - Door Lift Support",
            "Brand": "Pep Boys",
            "ManufacturePartNumber": "901781",
            "Class": "Door Lift Support",
            "SubClass": "Doors",
            "Month": "Mar 22",
            "Promo_Name": "Body",
            "Promo_Name_Group": "Body - Mar 22",
            "Status": "EOL",
            "Cost": "586",
            "Retail": "661",
            "Gross_Margin": "75",
            "Gross_Margin_Percentage": "12",
            "Rev": "3860",
            "Initial_Order_Quantity": "43",
            "Start_Date": "8/30/22",
            "End_Date": "4/19/23",
            "Forecast": "6",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/17/22",
            "Algo_Recommended_EOL": "8/20/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "23",
            "Make": "Toyota",
            "Model": "Tacoma Access Cab",
            "Year": "2012",
            "SKU": "253408997",
            "Title": "Advance DLA1572 Door Lock Actuator",
            "Brand": "ADVANCE/Ignition & Engine Filters",
            "ManufacturePartNumber": "Dla1572",
            "Class": "Door Lock Actuator",
            "SubClass": "Doors",
            "Month": "Mar 22",
            "Promo_Name": "Body",
            "Promo_Name_Group": "Body - Mar 22",
            "Status": "EOL",
            "Cost": "582",
            "Retail": "689",
            "Gross_Margin": "107",
            "Gross_Margin_Percentage": "18",
            "Rev": "316344",
            "Initial_Order_Quantity": "552",
            "Start_Date": "10/19/22",
            "End_Date": "7/5/23",
            "Forecast": "459",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/9/22",
            "Algo_Recommended_EOL": "8/10/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "24",
            "Make": "Nissan",
            "Model": "Murano",
            "Year": "2019",
            "SKU": "243432053",
            "Title": "Auto Plus/ACDelco GM Original Equip 22610145 Switches - Door Window Switch",
            "Brand": "Auto Plus",
            "ManufacturePartNumber": "22610145",
            "Class": "Door Window Switch",
            "SubClass": "Switches",
            "Month": "Mar 22",
            "Promo_Name": "Body",
            "Promo_Name_Group": "Body - Mar 22",
            "Status": "EOL",
            "Cost": "569",
            "Retail": "693",
            "Gross_Margin": "124",
            "Gross_Margin_Percentage": "19",
            "Rev": "585731",
            "Initial_Order_Quantity": "872",
            "Start_Date": "10/25/22",
            "End_Date": "5/18/23",
            "Forecast": "845",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/17/22",
            "Algo_Recommended_EOL": "8/18/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "25",
            "Make": "Kia",
            "Model": "Sorento",
            "Year": "2021",
            "SKU": "237828521",
            "Title": "Trunk Lid Lift Support Mighty Lift F96700 fits 05-06 Mercedes SLR McLaren",
            "Brand": "Mighty Lift",
            "ManufacturePartNumber": "F96700",
            "Class": "Trunk Lid Lift Support",
            "SubClass": "Trunk Lid and Compartment",
            "Month": "Mar 22",
            "Promo_Name": "Body",
            "Promo_Name_Group": "Body - Mar 22",
            "Status": "EOL",
            "Cost": "579",
            "Retail": "695",
            "Gross_Margin": "116",
            "Gross_Margin_Percentage": "10",
            "Rev": "300815",
            "Initial_Order_Quantity": "473",
            "Start_Date": "10/15/22",
            "End_Date": "6/27/23",
            "Forecast": "433",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/18/22",
            "Algo_Recommended_EOL": "8/15/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "26",
            "Make": "Chevrolet",
            "Model": "Silverado 3500 HD Regular Cab",
            "Year": "2014",
            "SKU": "203091507",
            "Title": "Brake Drum-Silver Brand Rear Federated SB9551",
            "Brand": "Federated",
            "ManufacturePartNumber": "Sb9551",
            "Class": "Brake Drum",
            "SubClass": "Drums and Rotors",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "EOL",
            "Cost": "595",
            "Retail": "692",
            "Gross_Margin": "97",
            "Gross_Margin_Percentage": "20",
            "Rev": "386098",
            "Initial_Order_Quantity": "646",
            "Start_Date": "9/10/22",
            "End_Date": "5/9/23",
            "Forecast": "558",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/6/22",
            "Algo_Recommended_EOL": "8/22/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "27",
            "Make": "Honda",
            "Model": "Pilot",
            "Year": "2011",
            "SKU": "79047256",
            "Title": "Brake Hydraulic Hose Front-Left/Right Dorman H35016",
            "Brand": "Dorman/First Stop",
            "ManufacturePartNumber": "H35016",
            "Class": "Brake Hydraulic Hose",
            "SubClass": "Hoses and Pipes",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "Rejected",
            "Cost": "557",
            "Retail": "695",
            "Gross_Margin": "138",
            "Gross_Margin_Percentage": "10",
            "Rev": "302808",
            "Initial_Order_Quantity": "455",
            "Start_Date": "9/4/22",
            "End_Date": "3/17/23",
            "Forecast": "436",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/26/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "28",
            "Make": "Chevrolet",
            "Model": "Silverado 1500 Limited Crew Cab",
            "Year": "2020",
            "SKU": "253547913",
            "Title": "Advance 176-1668 Brake Hydraulic Hose",
            "Brand": "ADVANCE/Brake & Wheel Bearing",
            "ManufacturePartNumber": "176-1668",
            "Class": "Brake Hydraulic Hose",
            "SubClass": "Hoses and Pipes",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "Rejected",
            "Cost": "590",
            "Retail": "667",
            "Gross_Margin": "77",
            "Gross_Margin_Percentage": "13",
            "Rev": "280765",
            "Initial_Order_Quantity": "465",
            "Start_Date": "9/25/22",
            "End_Date": "5/21/23",
            "Forecast": "421",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/3/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "29",
            "Make": "Hyundai",
            "Model": "Tucson Hybrid",
            "Year": "2012",
            "SKU": "5037299937",
            "Title": "Disc Brake Caliper-Unloaded Caliper Front Left Auto Plus C1801S Reman",
            "Brand": "Auto Plus Calipers",
            "ManufacturePartNumber": "C1801s",
            "Class": "Disc Brake Caliper",
            "SubClass": "Brake Hydraulics",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "Rejected",
            "Cost": "650",
            "Retail": "660",
            "Gross_Margin": "10",
            "Gross_Margin_Percentage": "30",
            "Rev": "349902",
            "Initial_Order_Quantity": "546",
            "Start_Date": "9/29/22",
            "End_Date": "5/6/23",
            "Forecast": "530",
            "Submitted_By": "Factory A",
            "Submitted_Date": "5/26/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "30",
            "Make": "Kia",
            "Model": "Sedona",
            "Year": "2019",
            "SKU": "26043852268",
            "Title": "Auto Plus/ACDelco Professional/Gold 18FR1690 - Disc Brake Caliper, Rear Right",
            "Brand": "Auto Plus",
            "ManufacturePartNumber": "18fr1690",
            "Class": "Disc Brake Caliper",
            "SubClass": "Brake Hydraulics",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "Rejected",
            "Cost": "630",
            "Retail": "678",
            "Gross_Margin": "48",
            "Gross_Margin_Percentage": "22",
            "Rev": "432247",
            "Initial_Order_Quantity": "719",
            "Start_Date": "9/28/22",
            "End_Date": "5/6/23",
            "Forecast": "638",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/24/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "31",
            "Make": "Chevrolet",
            "Model": "Silverado 2500 HD Crew Cab",
            "Year": "2010",
            "SKU": "233957126",
            "Title": "Auto Plus/Wagner H5226 - Disc Brake Caliper Pin Boot Kit, Rear, Front",
            "Brand": "Auto Plus",
            "ManufacturePartNumber": "H5226",
            "Class": "Disc Brake Caliper Pin Boot Kit",
            "SubClass": "Service Kits",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "Rejected",
            "Cost": "551",
            "Retail": "670",
            "Gross_Margin": "119",
            "Gross_Margin_Percentage": "15",
            "Rev": "448419",
            "Initial_Order_Quantity": "749",
            "Start_Date": "9/19/22",
            "End_Date": "5/31/23",
            "Forecast": "669",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "7/28/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "32",
            "Make": "Nissan",
            "Model": "Rogue Sport",
            "Year": "2006",
            "SKU": "21037495238",
            "Title": "IAP Dura BK7093822 Disc Brake Pad and Rotor Kit, Rear",
            "Brand": "IAP/Dura International",
            "ManufacturePartNumber": "Bk7093822",
            "Class": "Disc Brake Pad and Rotor Kit",
            "SubClass": "Service Kits",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "EOL",
            "Cost": "625",
            "Retail": "695",
            "Gross_Margin": "70",
            "Gross_Margin_Percentage": "30",
            "Rev": "34116",
            "Initial_Order_Quantity": "101",
            "Start_Date": "10/3/22",
            "End_Date": "5/10/23",
            "Forecast": "49",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/19/22",
            "Algo_Recommended_EOL": "8/21/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "33",
            "Make": "Hyundai",
            "Model": "Kona",
            "Year": "2000",
            "SKU": "115050519",
            "Title": "Disc Brake Pad Set-Original Performance Ceramic Rear WD Express 520 13130 508",
            "Brand": "WD Express",
            "ManufacturePartNumber": "520 13130 508",
            "Class": "Disc Brake Pad Set",
            "SubClass": "Disc Pads and Brake Shoes",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "EOL",
            "Cost": "646",
            "Retail": "663",
            "Gross_Margin": "17",
            "Gross_Margin_Percentage": "20",
            "Rev": "4059",
            "Initial_Order_Quantity": "28",
            "Start_Date": "10/20/22",
            "End_Date": "5/11/23",
            "Forecast": "6",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/10/22",
            "Algo_Recommended_EOL": "8/19/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "34",
            "Make": "Kia",
            "Model": "Sorento Hybrid",
            "Year": "2001",
            "SKU": "231381284",
            "Title": "Mighty TS1086CH Disc Brake Pad Set, Rear",
            "Brand": "Mighty",
            "ManufacturePartNumber": "Ts1086ch",
            "Class": "Disc Brake Pad Set",
            "SubClass": "Disc Pads and Brake Shoes",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "EOL",
            "Cost": "551",
            "Retail": "650",
            "Gross_Margin": "99",
            "Gross_Margin_Percentage": "16",
            "Rev": "332946",
            "Initial_Order_Quantity": "549",
            "Start_Date": "10/19/22",
            "End_Date": "7/15/23",
            "Forecast": "512",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/8/22",
            "Algo_Recommended_EOL": "8/12/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "35",
            "Make": "Toyota",
            "Model": "Corolla",
            "Year": "2001",
            "SKU": "110282638",
            "Title": "Disc Brake Pad Set Satisfied Brake Products PR636C",
            "Brand": "Satisfied Brake Products",
            "ManufacturePartNumber": "Pr636c",
            "Class": "Disc Brake Pad Set",
            "SubClass": "Disc Pads and Brake Shoes",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "EOL",
            "Cost": "556",
            "Retail": "662",
            "Gross_Margin": "106",
            "Gross_Margin_Percentage": "15",
            "Rev": "28011",
            "Initial_Order_Quantity": "113",
            "Start_Date": "9/12/22",
            "End_Date": "5/28/23",
            "Forecast": "42",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/9/22",
            "Algo_Recommended_EOL": "8/19/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "36",
            "Make": "Ram",
            "Model": "2500 Regular Cab",
            "Year": "2003",
            "SKU": "236220495",
            "Title": "Federated/FDP Brakes MKD1795 - Disc Brake Pad Set, Rear",
            "Brand": "Federated/FDP Brakes",
            "ManufacturePartNumber": "Mkd1795",
            "Class": "Disc Brake Pad Set",
            "SubClass": "Disc Pads and Brake Shoes",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "EOL",
            "Cost": "595",
            "Retail": "669",
            "Gross_Margin": "74",
            "Gross_Margin_Percentage": "13",
            "Rev": "196710",
            "Initial_Order_Quantity": "394",
            "Start_Date": "10/24/22",
            "End_Date": "6/30/23",
            "Forecast": "294",
            "Submitted_By": "Factory C",
            "Submitted_Date": "5/22/22",
            "Algo_Recommended_EOL": "8/13/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "37",
            "Make": "Hyundai",
            "Model": "Santa Fe Plug-in Hybrid",
            "Year": "2007",
            "SKU": "84055072",
            "Title": "Inwood S1195",
            "Brand": "Inwood Automotive Products",
            "ManufacturePartNumber": "S1195",
            "Class": "Disc Brake Pad Set",
            "SubClass": "Disc Pads and Brake Shoes",
            "Month": "Apr 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Apr 22",
            "Status": "EOL",
            "Cost": "646",
            "Retail": "656",
            "Gross_Margin": "10",
            "Gross_Margin_Percentage": "23",
            "Rev": "97820",
            "Initial_Order_Quantity": "163",
            "Start_Date": "8/27/22",
            "End_Date": "5/5/23",
            "Forecast": "149",
            "Submitted_By": "Factory C",
            "Submitted_Date": "7/6/22",
            "Algo_Recommended_EOL": "8/16/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "38",
            "Make": "Chevrolet",
            "Model": "Silverado 1500 Limited Double Cab",
            "Year": "2012",
            "SKU": "117908198",
            "Title": "Auto Extra CXD699",
            "Brand": "Auto Extra",
            "ManufacturePartNumber": "Cxd699",
            "Class": "Disc Brake Pad Set",
            "SubClass": "Disc Pads and Brake Shoes",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "EOL",
            "Cost": "569",
            "Retail": "656",
            "Gross_Margin": "87",
            "Gross_Margin_Percentage": "26",
            "Rev": "411875",
            "Initial_Order_Quantity": "637",
            "Start_Date": "9/21/22",
            "End_Date": "5/27/23",
            "Forecast": "628",
            "Submitted_By": "Factory C",
            "Submitted_Date": "5/19/22",
            "Algo_Recommended_EOL": "8/7/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "39",
            "Make": "Toyota",
            "Model": "Tacoma Double Cab",
            "Year": "2013",
            "SKU": "193261676",
            "Title": "Disc Brake Pad Set-Disc Brake Pad Rear Ferodo FDB973",
            "Brand": "Ferodo America",
            "ManufacturePartNumber": "Fdb973",
            "Class": "Disc Brake Pad Set",
            "SubClass": "Disc Pads and Brake Shoes",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "Modified",
            "Cost": "638",
            "Retail": "684",
            "Gross_Margin": "46",
            "Gross_Margin_Percentage": "10",
            "Rev": "259773",
            "Initial_Order_Quantity": "448",
            "Start_Date": "9/16/22",
            "End_Date": "4/3/23",
            "Forecast": "380",
            "Submitted_By": "Factory C",
            "Submitted_Date": "5/19/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "40",
            "Make": "Ram",
            "Model": "1500 Quad Cab",
            "Year": "2000",
            "SKU": "23048194306",
            "Title": "R1 Concepts RRE02127D Drums and Rotors - Disc Brake Rotor, Front",
            "Brand": "R1 Concepts",
            "ManufacturePartNumber": "Rre02127d",
            "Class": "Disc Brake Rotor",
            "SubClass": "Drums and Rotors",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "Modified",
            "Cost": "626",
            "Retail": "699",
            "Gross_Margin": "73",
            "Gross_Margin_Percentage": "13",
            "Rev": "70687",
            "Initial_Order_Quantity": "149",
            "Start_Date": "9/23/22",
            "End_Date": "5/1/23",
            "Forecast": "101",
            "Submitted_By": "Factory C",
            "Submitted_Date": "6/1/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "41",
            "Make": "Nissan",
            "Model": "TITAN XD Crew Cab",
            "Year": "2004",
            "SKU": "253176445",
            "Title": "R1 Concepts EXZ48058R Disc Brake Rotor, Rear",
            "Brand": "R1 Concepts",
            "ManufacturePartNumber": "Exz48058r",
            "Class": "Disc Brake Rotor",
            "SubClass": "Drums and Rotors",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "Modified",
            "Cost": "562",
            "Retail": "655",
            "Gross_Margin": "93",
            "Gross_Margin_Percentage": "28",
            "Rev": "105915",
            "Initial_Order_Quantity": "183",
            "Start_Date": "10/7/22",
            "End_Date": "6/12/23",
            "Forecast": "162",
            "Submitted_By": "Factory C",
            "Submitted_Date": "6/26/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "42",
            "Make": "Chevrolet",
            "Model": "Silverado 1500 Limited Regular Cab",
            "Year": "2006",
            "SKU": "236186040",
            "Title": "Auto Plus/Beck/Arnley 0833515 Drums and Rotors - Disc Brake Rotor, Front",
            "Brand": "Auto Plus",
            "ManufacturePartNumber": "083-3515",
            "Class": "Disc Brake Rotor",
            "SubClass": "Drums and Rotors",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "Pitched",
            "Cost": "596",
            "Retail": "650",
            "Gross_Margin": "54",
            "Gross_Margin_Percentage": "29",
            "Rev": "64305",
            "Initial_Order_Quantity": "194",
            "Start_Date": "10/19/22",
            "End_Date": "7/16/23",
            "Forecast": "99",
            "Submitted_By": "Factory C",
            "Submitted_Date": "6/19/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "43",
            "Make": "Toyota",
            "Model": "Corolla Cross",
            "Year": "2007",
            "SKU": "28045221192",
            "Title": "Disc Brake Rotor CBK B34126",
            "Brand": "CBK",
            "ManufacturePartNumber": "B34126",
            "Class": "Disc Brake Rotor",
            "SubClass": "Drums and Rotors",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "Pitched",
            "Cost": "610",
            "Retail": "664",
            "Gross_Margin": "54",
            "Gross_Margin_Percentage": "16",
            "Rev": "585878",
            "Initial_Order_Quantity": "918",
            "Start_Date": "10/25/22",
            "End_Date": "5/16/23",
            "Forecast": "882",
            "Submitted_By": "Factory C",
            "Submitted_Date": "6/3/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "44",
            "Make": "Nissan",
            "Model": "NV200",
            "Year": "2012",
            "SKU": "140423607",
            "Title": "Professional's Choice 61991",
            "Brand": "Professional's Choice",
            "ManufacturePartNumber": "61991",
            "Class": "Disc Brake Rotor",
            "SubClass": "Drums and Rotors",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "Pitched",
            "Cost": "583",
            "Retail": "694",
            "Gross_Margin": "111",
            "Gross_Margin_Percentage": "22",
            "Rev": "141602",
            "Initial_Order_Quantity": "270",
            "Start_Date": "10/25/22",
            "End_Date": "6/21/23",
            "Forecast": "204",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/3/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "45",
            "Make": "Chevrolet",
            "Model": "Silverado 1500 Double Cab",
            "Year": "2014",
            "SKU": "174485901",
            "Title": "Parts Master 52-125514",
            "Brand": "Parts Master/Wagner",
            "ManufacturePartNumber": "52-125514",
            "Class": "Disc Brake Rotor",
            "SubClass": "Drums and Rotors",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "Pitched",
            "Cost": "578",
            "Retail": "660",
            "Gross_Margin": "82",
            "Gross_Margin_Percentage": "14",
            "Rev": "74823",
            "Initial_Order_Quantity": "194",
            "Start_Date": "9/1/22",
            "End_Date": "3/3/23",
            "Forecast": "113",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/15/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "46",
            "Make": "Toyota",
            "Model": "Mirai",
            "Year": "2006",
            "SKU": "189073203",
            "Title": "Disc Brake Rotor Rear SP Performance F06-3554-P",
            "Brand": "SP Performance",
            "ManufacturePartNumber": "F06-3554-P",
            "Class": "Disc Brake Rotor|Disc Brake Rotor Set",
            "SubClass": "Drums and Rotors",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "EOL",
            "Cost": "599",
            "Retail": "659",
            "Gross_Margin": "60",
            "Gross_Margin_Percentage": "13",
            "Rev": "556902",
            "Initial_Order_Quantity": "922",
            "Start_Date": "9/20/22",
            "End_Date": "5/16/23",
            "Forecast": "845",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/19/22",
            "Algo_Recommended_EOL": "8/21/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "47",
            "Make": "Acura",
            "Model": "NSX",
            "Year": "2009",
            "SKU": "192408311",
            "Title": "Wagner F112972",
            "Brand": "Wagner",
            "ManufacturePartNumber": "F112972",
            "Class": "Drum Brake Hardware Kit",
            "SubClass": "Service Kits",
            "Month": "May 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - May 22",
            "Status": "EOL",
            "Cost": "576",
            "Retail": "684",
            "Gross_Margin": "108",
            "Gross_Margin_Percentage": "22",
            "Rev": "108494",
            "Initial_Order_Quantity": "245",
            "Start_Date": "9/15/22",
            "End_Date": "5/9/23",
            "Forecast": "159",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/9/22",
            "Algo_Recommended_EOL": "8/13/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "48",
            "Make": "INFINITI",
            "Model": "QX55",
            "Year": "2000",
            "SKU": "76714906",
            "Title": "Drum Brake Wheel Cylinder Brakeware 34139",
            "Brand": "Brakeware",
            "ManufacturePartNumber": "34139",
            "Class": "Drum Brake Wheel Cylinder",
            "SubClass": "Brake Hydraulics",
            "Month": "Jun 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Jun 22",
            "Status": "EOL",
            "Cost": "599",
            "Retail": "700",
            "Gross_Margin": "101",
            "Gross_Margin_Percentage": "23",
            "Rev": "242555",
            "Initial_Order_Quantity": "421",
            "Start_Date": "10/7/22",
            "End_Date": "5/20/23",
            "Forecast": "347",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/21/22",
            "Algo_Recommended_EOL": "8/17/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "49",
            "Make": "Toyota",
            "Model": "Tundra CrewMax",
            "Year": "2013",
            "SKU": "173910915",
            "Title": "Federated 830",
            "Brand": "Federated/FDP Brakes",
            "ManufacturePartNumber": "830",
            "Class": "Parking Brake Shoe",
            "SubClass": "Disc Pads and Brake Shoes",
            "Month": "Jun 22",
            "Promo_Name": "Brakes",
            "Promo_Name_Group": "Brakes - Jun 22",
            "Status": "EOL",
            "Cost": "559",
            "Retail": "654",
            "Gross_Margin": "95",
            "Gross_Margin_Percentage": "15",
            "Rev": "545843",
            "Initial_Order_Quantity": "909",
            "Start_Date": "10/22/22",
            "End_Date": "6/17/23",
            "Forecast": "835",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/31/22",
            "Algo_Recommended_EOL": "8/13/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "50",
            "Make": "Chevrolet",
            "Model": "Silverado 2500 HD Regular Cab",
            "Year": "2016",
            "SKU": "21052117252",
            "Title": "CARQUEST/Wells Vehicle Electronics 5S8628 Sensors - ABS Wheel Speed Sensor, Rear",
            "Brand": "CARQUEST/Wells Vehilcle Electronics",
            "ManufacturePartNumber": "5s8628",
            "Class": "Abs Wheel SPEED Sensor",
            "SubClass": "Sensors",
            "Month": "Jun 22",
            "Promo_Name": "Electrical",
            "Promo_Name_Group": "Electrical - Jun 22",
            "Status": "EOL",
            "Cost": "582",
            "Retail": "668",
            "Gross_Margin": "86",
            "Gross_Margin_Percentage": "20",
            "Rev": "415002",
            "Initial_Order_Quantity": "662",
            "Start_Date": "8/27/22",
            "End_Date": "3/22/23",
            "Forecast": "621",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/20/22",
            "Algo_Recommended_EOL": "8/18/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "51",
            "Make": "Toyota",
            "Model": "Avalon",
            "Year": "2022",
            "SKU": "230550808",
            "Title": "Ignition Control Module Wells RB132",
            "Brand": "Wells",
            "ManufacturePartNumber": "Rb132",
            "Class": "Ignition Control Module",
            "SubClass": "Control Modules",
            "Month": "Jun 22",
            "Promo_Name": "Electrical",
            "Promo_Name_Group": "Electrical - Jun 22",
            "Status": "EOL",
            "Cost": "555",
            "Retail": "679",
            "Gross_Margin": "124",
            "Gross_Margin_Percentage": "26",
            "Rev": "378914",
            "Initial_Order_Quantity": "613",
            "Start_Date": "9/6/22",
            "End_Date": "5/18/23",
            "Forecast": "558",
            "Submitted_By": "Factory C",
            "Submitted_Date": "7/2/22",
            "Algo_Recommended_EOL": "8/14/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "52",
            "Make": "Ram",
            "Model": "1500 Classic Regular Cab",
            "Year": "2015",
            "SKU": "117909437",
            "Title": "Auto Extra 13728",
            "Brand": "Auto Extra",
            "ManufacturePartNumber": "13728",
            "Class": "Alternator",
            "SubClass": "Alternator / Generator and Related Components",
            "Month": "Jun 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Jun 22",
            "Status": "EOL",
            "Cost": "553",
            "Retail": "685",
            "Gross_Margin": "132",
            "Gross_Margin_Percentage": "13",
            "Rev": "45182",
            "Initial_Order_Quantity": "117",
            "Start_Date": "10/13/22",
            "End_Date": "6/7/23",
            "Forecast": "66",
            "Submitted_By": "Factory C",
            "Submitted_Date": "7/20/22",
            "Algo_Recommended_EOL": "8/6/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "53",
            "Make": "Chevrolet",
            "Model": "Corvette",
            "Year": "2016",
            "SKU": "191315643",
            "Title": "CARQUEST 71-7799",
            "Brand": "Carquest",
            "ManufacturePartNumber": "71-7799",
            "Class": "Alternator",
            "SubClass": "Alternator / Generator and Related Components",
            "Month": "Jun 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Jun 22",
            "Status": "EOL",
            "Cost": "602",
            "Retail": "690",
            "Gross_Margin": "88",
            "Gross_Margin_Percentage": "29",
            "Rev": "182671",
            "Initial_Order_Quantity": "332",
            "Start_Date": "10/23/22",
            "End_Date": "5/23/23",
            "Forecast": "265",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/11/22",
            "Algo_Recommended_EOL": "8/10/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "54",
            "Make": "Ram",
            "Model": "ProMaster City",
            "Year": "2019",
            "SKU": "16051629806",
            "Title": "Automatic Transmission Output Shaft ACDelco GM Original Equipment 8680936",
            "Brand": "ACDelco (OE)",
            "ManufacturePartNumber": "8680936",
            "Class": "Automatic Transmission Output Shaft",
            "SubClass": "Transmission Hard Parts",
            "Month": "Jun 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Jun 22",
            "Status": "EOL",
            "Cost": "565",
            "Retail": "694",
            "Gross_Margin": "129",
            "Gross_Margin_Percentage": "26",
            "Rev": "106073",
            "Initial_Order_Quantity": "232",
            "Start_Date": "10/3/22",
            "End_Date": "6/2/23",
            "Forecast": "153",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/23/22",
            "Algo_Recommended_EOL": "8/16/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "55",
            "Make": "INFINITI",
            "Model": "QX60",
            "Year": "2008",
            "SKU": "8050963739",
            "Title": "Auto Plus/ACDelco Professional/Gold - Automatic Transmission Output Shaft Seal",
            "Brand": "Auto Plus",
            "ManufacturePartNumber": "710540",
            "Class": "Automatic Transmission Output Shaft Seal",
            "SubClass": "Gaskets and Sealing Systems",
            "Month": "Jun 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Jun 22",
            "Status": "EOL",
            "Cost": "582",
            "Retail": "700",
            "Gross_Margin": "118",
            "Gross_Margin_Percentage": "26",
            "Rev": "336619",
            "Initial_Order_Quantity": "561",
            "Start_Date": "9/14/22",
            "End_Date": "3/13/23",
            "Forecast": "481",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/20/22",
            "Algo_Recommended_EOL": "8/10/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "56",
            "Make": "Chevrolet",
            "Model": "Camaro",
            "Year": "2020",
            "SKU": "239101883",
            "Title": "Auto Plus 84136380 Battery Cable",
            "Brand": "Auto Plus",
            "ManufacturePartNumber": "84136380",
            "Class": "Battery Cable",
            "SubClass": "Battery and Related Components",
            "Month": "Jun 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Jun 22",
            "Status": "Accepted",
            "Cost": "582",
            "Retail": "678",
            "Gross_Margin": "96",
            "Gross_Margin_Percentage": "25",
            "Rev": "579741",
            "Initial_Order_Quantity": "856",
            "Start_Date": "10/12/22",
            "End_Date": "5/31/23",
            "Forecast": "855",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/21/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "57",
            "Make": "Chevrolet",
            "Model": "Tahoe",
            "Year": "2004",
            "SKU": "27038211027",
            "Title": "Distributor Cap Prenco GB424",
            "Brand": "Prenco",
            "ManufacturePartNumber": "Gb424",
            "Class": "Distributor Cap",
            "SubClass": "Distributor and Magneto",
            "Month": "Jun 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Jun 22",
            "Status": "Accepted",
            "Cost": "628",
            "Retail": "668",
            "Gross_Margin": "40",
            "Gross_Margin_Percentage": "27",
            "Rev": "66633",
            "Initial_Order_Quantity": "112",
            "Start_Date": "9/1/22",
            "End_Date": "4/13/23",
            "Forecast": "100",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/7/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "58",
            "Make": "Hyundai",
            "Model": "Santa Fe",
            "Year": "2008",
            "SKU": "3045211567",
            "Title": "CARQUEST/Worldpac WP1875335MAH Bearings - Engine Connecting Rod Bearing",
            "Brand": "CARQUEST/WORLDPAC",
            "ManufacturePartNumber": "Wp-1875335-Mah",
            "Class": "Engine Connecting Rod Bearing",
            "SubClass": "Bearings",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "Accepted",
            "Cost": "583",
            "Retail": "654",
            "Gross_Margin": "71",
            "Gross_Margin_Percentage": "27",
            "Rev": "636622",
            "Initial_Order_Quantity": "975",
            "Start_Date": "9/7/22",
            "End_Date": "3/16/23",
            "Forecast": "973",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/22/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "59",
            "Make": "Chevrolet",
            "Model": "Silverado 1500 Regular Cab",
            "Year": "2016",
            "SKU": "3037548299",
            "Title": "Engine Connecting Rod Bearing Set DNJ RB844",
            "Brand": "DNJ Engine Components",
            "ManufacturePartNumber": "Rb844",
            "Class": "Engine Connecting Rod Bearing Set",
            "SubClass": "Cylinder Block Components",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "Accepted",
            "Cost": "619",
            "Retail": "655",
            "Gross_Margin": "36",
            "Gross_Margin_Percentage": "15",
            "Rev": "504756",
            "Initial_Order_Quantity": "774",
            "Start_Date": "9/24/22",
            "End_Date": "5/31/23",
            "Forecast": "771",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/9/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "60",
            "Make": "Hyundai",
            "Model": "Accent",
            "Year": "2002",
            "SKU": "15043907315",
            "Title": "Engine Control Module Blue Streak EM5902",
            "Brand": "BSE ORIGINAL",
            "ManufacturePartNumber": "Em5902",
            "Class": "Engine Control Module",
            "SubClass": "Control Modules",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "Accepted",
            "Cost": "555",
            "Retail": "672",
            "Gross_Margin": "117",
            "Gross_Margin_Percentage": "21",
            "Rev": "60894",
            "Initial_Order_Quantity": "154",
            "Start_Date": "10/15/22",
            "End_Date": "5/24/23",
            "Forecast": "91",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/14/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "61",
            "Make": "Nissan",
            "Model": "NV3500 HD Cargo",
            "Year": "2017",
            "SKU": "251704749",
            "Title": "Engine Coolant Temperature Sensor O'Reilly TX98",
            "Brand": "O'Reilly Auto Parts/STD",
            "ManufacturePartNumber": "Tx98",
            "Class": "Engine Coolant Temperature Sensor",
            "SubClass": "Sensors",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "Accepted",
            "Cost": "580",
            "Retail": "682",
            "Gross_Margin": "102",
            "Gross_Margin_Percentage": "12",
            "Rev": "186493",
            "Initial_Order_Quantity": "354",
            "Start_Date": "10/19/22",
            "End_Date": "5/11/23",
            "Forecast": "273",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/16/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "62",
            "Make": "INFINITI",
            "Model": "QX55",
            "Year": "2021",
            "SKU": "217705999",
            "Title": "Engine Coolant Thermostat-VIN: L Mighty 7206-195",
            "Brand": "Mighty",
            "ManufacturePartNumber": "7206-195",
            "Class": "Engine Coolant Thermostat",
            "SubClass": "Thermostat and Housing",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "EOL",
            "Cost": "605",
            "Retail": "692",
            "Gross_Margin": "87",
            "Gross_Margin_Percentage": "23",
            "Rev": "555701",
            "Initial_Order_Quantity": "851",
            "Start_Date": "9/13/22",
            "End_Date": "6/10/23",
            "Forecast": "803",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/6/22",
            "Algo_Recommended_EOL": "8/22/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "63",
            "Make": "Ram",
            "Model": "2500 Mega Cab",
            "Year": "2009",
            "SKU": "253360297",
            "Title": "Engine Oil Filter HENGST H331W",
            "Brand": "Hengst",
            "ManufacturePartNumber": "H331w",
            "Class": "Engine Oil Filter",
            "SubClass": "Filters",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "EOL",
            "Cost": "567",
            "Retail": "690",
            "Gross_Margin": "123",
            "Gross_Margin_Percentage": "21",
            "Rev": "473055",
            "Initial_Order_Quantity": "767",
            "Start_Date": "10/23/22",
            "End_Date": "6/28/23",
            "Forecast": "686",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/26/22",
            "Algo_Recommended_EOL": "8/16/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "64",
            "Make": "Chevrolet",
            "Model": "Spark",
            "Year": "2020",
            "SKU": "210414703",
            "Title": "Engine Oil Pressure Sensor 55573719 fits 14-15 Chevrolet Cruze 2.0L-L4",
            "Brand": "ACDelco (OE)",
            "ManufacturePartNumber": "55573719",
            "Class": "Engine Oil Pressure Sensor",
            "SubClass": "Sensors",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "EOL",
            "Cost": "616",
            "Retail": "700",
            "Gross_Margin": "84",
            "Gross_Margin_Percentage": "16",
            "Rev": "129261",
            "Initial_Order_Quantity": "222",
            "Start_Date": "9/1/22",
            "End_Date": "5/3/23",
            "Forecast": "185",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/11/22",
            "Algo_Recommended_EOL": "8/18/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "65",
            "Make": "Lexus",
            "Model": "UX",
            "Year": "2005",
            "SKU": "75299437",
            "Title": "Engine Rebuild Kit-Master Rebuild Kit Sealed Power 205-6219M",
            "Brand": "Sealed Power",
            "ManufacturePartNumber": "205-6219m",
            "Class": "Engine Re-Ring Kit|Engine Rebuild Kit",
            "SubClass": "Service Kits",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "EOL",
            "Cost": "598",
            "Retail": "681",
            "Gross_Margin": "83",
            "Gross_Margin_Percentage": "26",
            "Rev": "380365",
            "Initial_Order_Quantity": "630",
            "Start_Date": "8/28/22",
            "End_Date": "3/27/23",
            "Forecast": "559",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/21/22",
            "Algo_Recommended_EOL": "8/24/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "66",
            "Make": "Subaru",
            "Model": "Crosstrek",
            "Year": "2019",
            "SKU": "115027657",
            "Title": "Engine Timing Belt Kit with Water Pump-Component Kit Cadna TBV323A",
            "Brand": "Cadna",
            "ManufacturePartNumber": "Tbv323a",
            "Class": "Engine Timing Belt Kit with Water Pump",
            "SubClass": "ValveTrain Components",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "EOL",
            "Cost": "601",
            "Retail": "661",
            "Gross_Margin": "60",
            "Gross_Margin_Percentage": "18",
            "Rev": "292757",
            "Initial_Order_Quantity": "535",
            "Start_Date": "9/5/22",
            "End_Date": "5/6/23",
            "Forecast": "443",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/6/22",
            "Algo_Recommended_EOL": "8/19/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "67",
            "Make": "Toyota",
            "Model": "GR Supra",
            "Year": "2011",
            "SKU": "139944913",
            "Title": "Engine Timing Belt Tensioner Roller Autopart Intl 2008-56448",
            "Brand": "Autopart International",
            "ManufacturePartNumber": "2008-56448",
            "Class": "Engine Timing Belt Tensioner Roller",
            "SubClass": "ValveTrain Components",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "EOL",
            "Cost": "598",
            "Retail": "651",
            "Gross_Margin": "53",
            "Gross_Margin_Percentage": "15",
            "Rev": "205556",
            "Initial_Order_Quantity": "353",
            "Start_Date": "9/24/22",
            "End_Date": "5/23/23",
            "Forecast": "316",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/15/22",
            "Algo_Recommended_EOL": "8/21/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "68",
            "Make": "Lexus",
            "Model": "RZ",
            "Year": "2007",
            "SKU": "252914438",
            "Title": "Auto Extra 916-507",
            "Brand": "AutoExtra/Dorman (OE Solutions)",
            "ManufacturePartNumber": "916-507",
            "Class": "Engine Variable Valve Timing (Vvt) Sprocket",
            "SubClass": "ValveTrain Components",
            "Month": "July 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - July 22",
            "Status": "EOL",
            "Cost": "612",
            "Retail": "674",
            "Gross_Margin": "62",
            "Gross_Margin_Percentage": "16",
            "Rev": "83107",
            "Initial_Order_Quantity": "156",
            "Start_Date": "10/2/22",
            "End_Date": "5/7/23",
            "Forecast": "123",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/24/22",
            "Algo_Recommended_EOL": "8/16/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "69",
            "Make": "Ram",
            "Model": "ProMaster City",
            "Year": "2011",
            "SKU": "174952547",
            "Title": "Auto Extra FP1587",
            "Brand": "Auto Extra",
            "ManufacturePartNumber": "Fp1587",
            "Class": "Engine Water Pump",
            "SubClass": "Water Pump and Related Components",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "EOL",
            "Cost": "573",
            "Retail": "657",
            "Gross_Margin": "84",
            "Gross_Margin_Percentage": "13",
            "Rev": "436223",
            "Initial_Order_Quantity": "755",
            "Start_Date": "10/18/22",
            "End_Date": "5/16/23",
            "Forecast": "664",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/2/22",
            "Algo_Recommended_EOL": "8/13/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "70",
            "Make": "Nissan",
            "Model": "NV1500 Cargo",
            "Year": "2004",
            "SKU": "252861104",
            "Title": "Fuel Injection Pressure Regulator-Genuine Fuel Pressure Regulator NLA 9/12",
            "Brand": "ADVANCE/CARQUEST WORLDPAC",
            "ManufacturePartNumber": "C3000163780oes",
            "Class": "Fuel Injection Pressure Regulator",
            "SubClass": "Fuel Injection System and Related Components",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "EOL",
            "Cost": "638",
            "Retail": "650",
            "Gross_Margin": "12",
            "Gross_Margin_Percentage": "11",
            "Rev": "198470",
            "Initial_Order_Quantity": "335",
            "Start_Date": "10/20/22",
            "End_Date": "6/16/23",
            "Forecast": "305",
            "Submitted_By": "Factory A",
            "Submitted_Date": "5/4/22",
            "Algo_Recommended_EOL": "8/8/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "71",
            "Make": "MAZDA",
            "Model": "MX-5 Miata",
            "Year": "2021",
            "SKU": "252122848",
            "Title": "Auto Extra P76863M",
            "Brand": "Auto Extra",
            "ManufacturePartNumber": "P76863m",
            "Class": "Fuel Pump Module Assembly",
            "SubClass": "Fuel Pumps and Related Components",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "EOL",
            "Cost": "578",
            "Retail": "680",
            "Gross_Margin": "102",
            "Gross_Margin_Percentage": "19",
            "Rev": "610681",
            "Initial_Order_Quantity": "898",
            "Start_Date": "9/1/22",
            "End_Date": "5/16/23",
            "Forecast": "898",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "7/6/22",
            "Algo_Recommended_EOL": "8/14/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "72",
            "Make": "Toyota",
            "Model": "4Runner",
            "Year": "2018",
            "SKU": "13037527730",
            "Title": "Fuel Tank Selector Valve Advance FSV2A",
            "Brand": "Advance Auto Parts",
            "ManufacturePartNumber": "Fsv2a",
            "Class": "Fuel Tank Selector Valve",
            "SubClass": "Fuel Storage",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "EOL",
            "Cost": "558",
            "Retail": "688",
            "Gross_Margin": "130",
            "Gross_Margin_Percentage": "13",
            "Rev": "244797",
            "Initial_Order_Quantity": "451",
            "Start_Date": "10/14/22",
            "End_Date": "5/4/23",
            "Forecast": "356",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/6/22",
            "Algo_Recommended_EOL": "8/5/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "73",
            "Make": "Acura",
            "Model": "TLX",
            "Year": "2008",
            "SKU": "114981927",
            "Title": "HVAC Blower Motor Front Cooling Depot 35388",
            "Brand": "Cooling Depot",
            "ManufacturePartNumber": "35388",
            "Class": "HVAC Blower Motor",
            "SubClass": "Motors, CORE, Case and Related Components",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "EOL",
            "Cost": "568",
            "Retail": "698",
            "Gross_Margin": "130",
            "Gross_Margin_Percentage": "20",
            "Rev": "586440",
            "Initial_Order_Quantity": "878",
            "Start_Date": "9/27/22",
            "End_Date": "3/28/23",
            "Forecast": "840",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/15/22",
            "Algo_Recommended_EOL": "8/6/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "74",
            "Make": "Honda",
            "Model": "CR-V Hybrid",
            "Year": "2018",
            "SKU": "6039760750",
            "Title": "HVAC Blower Motor Santech Industries 26-13943",
            "Brand": "Santech Industries",
            "ManufacturePartNumber": "26-13943",
            "Class": "HVAC Blower Motor",
            "SubClass": "Motors, CORE, Case and Related Components",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "Rejected",
            "Cost": "641",
            "Retail": "662",
            "Gross_Margin": "21",
            "Gross_Margin_Percentage": "19",
            "Rev": "198199",
            "Initial_Order_Quantity": "361",
            "Start_Date": "10/1/22",
            "End_Date": "6/22/23",
            "Forecast": "299",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/2/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "75",
            "Make": "Chevrolet",
            "Model": "Equinox",
            "Year": "2007",
            "SKU": "6053539411",
            "Title": "Parts Master/Four Seasons 96571 - HVAC Heater Core",
            "Brand": "Parts Master",
            "ManufacturePartNumber": "96571",
            "Class": "HVAC Heater CORE",
            "SubClass": "Motors, CORE, Case and Related Components",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "Rejected",
            "Cost": "550",
            "Retail": "682",
            "Gross_Margin": "132",
            "Gross_Margin_Percentage": "29",
            "Rev": "136651",
            "Initial_Order_Quantity": "296",
            "Start_Date": "9/13/22",
            "End_Date": "5/12/23",
            "Forecast": "200",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "7/7/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "76",
            "Make": "Ram",
            "Model": "3500 Regular Cab",
            "Year": "2011",
            "SKU": "169014025",
            "Title": "HVAC Heater Core Original Equip Data 96190674",
            "Brand": "Original Equipment Data",
            "ManufacturePartNumber": "96190674",
            "Class": "HVAC Heater CORE",
            "SubClass": "Motors, CORE, Case and Related Components",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "Rejected",
            "Cost": "629",
            "Retail": "653",
            "Gross_Margin": "24",
            "Gross_Margin_Percentage": "28",
            "Rev": "146383",
            "Initial_Order_Quantity": "238",
            "Start_Date": "10/9/22",
            "End_Date": "4/19/23",
            "Forecast": "224",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/1/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "77",
            "Make": "Subaru",
            "Model": "Solterra",
            "Year": "2009",
            "SKU": "21037795760",
            "Title": "HVAC Heater Hose-GAS Gates 18104",
            "Brand": "Gates",
            "ManufacturePartNumber": "18104",
            "Class": "HVAC Heater Hose",
            "SubClass": "Hoses and Pipes",
            "Month": "Aug 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Aug 22",
            "Status": "Rejected",
            "Cost": "632",
            "Retail": "681",
            "Gross_Margin": "49",
            "Gross_Margin_Percentage": "10",
            "Rev": "516267",
            "Initial_Order_Quantity": "775",
            "Start_Date": "8/31/22",
            "End_Date": "5/3/23",
            "Forecast": "758",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "6/14/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "78",
            "Make": "Toyota",
            "Model": "Corolla Hybrid",
            "Year": "2011",
            "SKU": "174139573",
            "Title": "HVAC Heater Hose-Hose-Heater Original Equip Data 4B0 819 371 L",
            "Brand": "Original Equipment Data",
            "ManufacturePartNumber": "4b0 819 371L",
            "Class": "HVAC Heater Hose",
            "SubClass": "Hoses and Pipes",
            "Month": "Sept 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Sept 22",
            "Status": "Rejected",
            "Cost": "613",
            "Retail": "688",
            "Gross_Margin": "75",
            "Gross_Margin_Percentage": "10",
            "Rev": "65643",
            "Initial_Order_Quantity": "143",
            "Start_Date": "10/5/22",
            "End_Date": "6/10/23",
            "Forecast": "95",
            "Submitted_By": "Factory A",
            "Submitted_Date": "5/13/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "79",
            "Make": "Hyundai",
            "Model": "Kona",
            "Year": "2017",
            "SKU": "236523499",
            "Title": "CARQUEST Cooling 431337 Radiators, Coolers and Related Components - Radiator",
            "Brand": "Carquest",
            "ManufacturePartNumber": "431337",
            "Class": "Radiator",
            "SubClass": "Radiators, Coolers and Related Components",
            "Month": "Sept 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Sept 22",
            "Status": "Rejected",
            "Cost": "561",
            "Retail": "685",
            "Gross_Margin": "124",
            "Gross_Margin_Percentage": "14",
            "Rev": "379206",
            "Initial_Order_Quantity": "622",
            "Start_Date": "9/14/22",
            "End_Date": "3/29/23",
            "Forecast": "554",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/29/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "80",
            "Make": "Toyota",
            "Model": "Mirai",
            "Year": "2005",
            "SKU": "237938530",
            "Title": "Vaico V201290 Hoses and Pipes - Radiator Coolant Hose, Lower",
            "Brand": "Vaico",
            "ManufacturePartNumber": "V20-1290",
            "Class": "Radiator Coolant Hose",
            "SubClass": "Hoses and Pipes",
            "Month": "Sept 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Sept 22",
            "Status": "Modified",
            "Cost": "604",
            "Retail": "689",
            "Gross_Margin": "85",
            "Gross_Margin_Percentage": "19",
            "Rev": "156751",
            "Initial_Order_Quantity": "298",
            "Start_Date": "9/12/22",
            "End_Date": "3/27/23",
            "Forecast": "228",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/8/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "81",
            "Make": "Acura",
            "Model": "ILX",
            "Year": "2013",
            "SKU": "236069470",
            "Title": "CARQUEST 71869",
            "Brand": "Carquest",
            "ManufacturePartNumber": "71869",
            "Class": "Radiator Coolant Hose",
            "SubClass": "Hoses and Pipes",
            "Month": "Sept 22",
            "Promo_Name": "Engine",
            "Promo_Name_Group": "Engine - Sept 22",
            "Status": "Modified",
            "Cost": "646",
            "Retail": "671",
            "Gross_Margin": "25",
            "Gross_Margin_Percentage": "21",
            "Rev": "456499",
            "Initial_Order_Quantity": "704",
            "Start_Date": "8/28/22",
            "End_Date": "4/19/23",
            "Forecast": "680",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/7/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "82",
            "Make": "Toyota",
            "Model": "Tundra Double Cab",
            "Year": "2016",
            "SKU": "16037793799",
            "Title": "Steering Gear-Retail Eligible for Warranty Motorcraft STRV-70RM Reman",
            "Brand": "Motorcraft",
            "ManufacturePartNumber": "Strv-70rm",
            "Class": "Steering Gear",
            "SubClass": "Steering, Gear and Related Components",
            "Month": "Sept 22",
            "Promo_Name": "Steering System",
            "Promo_Name_Group": "Steering System - Sept 22",
            "Status": "Modified",
            "Cost": "640",
            "Retail": "674",
            "Gross_Margin": "34",
            "Gross_Margin_Percentage": "30",
            "Rev": "592075",
            "Initial_Order_Quantity": "962",
            "Start_Date": "10/6/22",
            "End_Date": "5/16/23",
            "Forecast": "878",
            "Submitted_By": "Factory A",
            "Submitted_Date": "6/15/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "83",
            "Make": "Toyota",
            "Model": "GR86",
            "Year": "2022",
            "SKU": "169459168",
            "Title": "Auto Extra RS5531",
            "Brand": "AutoExtra/Rancho Suspension/Monroe",
            "ManufacturePartNumber": "Rs5531",
            "Class": "Steering Stabilizer Bracket",
            "SubClass": "Brackets, Flanges and Hangers",
            "Month": "Sept 22",
            "Promo_Name": "Steering System",
            "Promo_Name_Group": "Steering System - Sept 22",
            "Status": "Modified",
            "Cost": "616",
            "Retail": "687",
            "Gross_Margin": "71",
            "Gross_Margin_Percentage": "22",
            "Rev": "457790",
            "Initial_Order_Quantity": "759",
            "Start_Date": "10/18/22",
            "End_Date": "7/11/23",
            "Forecast": "666",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/6/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "84",
            "Make": "Kia",
            "Model": "Niro EV",
            "Year": "2003",
            "SKU": "10048183383",
            "Title": "FVP SUZ7013 CV Components - CV Axle Assembly, Right, Left",
            "Brand": "FVP",
            "ManufacturePartNumber": "Suz7013",
            "Class": "Cv Axle Assembly",
            "SubClass": "Cv Components",
            "Month": "Sept 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Sept 22",
            "Status": "Modified",
            "Cost": "579",
            "Retail": "653",
            "Gross_Margin": "74",
            "Gross_Margin_Percentage": "21",
            "Rev": "295852",
            "Initial_Order_Quantity": "537",
            "Start_Date": "9/27/22",
            "End_Date": "5/1/23",
            "Forecast": "453",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/17/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "85",
            "Make": "Nissan",
            "Model": "LEAF",
            "Year": "2001",
            "SKU": "15037538531",
            "Title": "CV Axle Shaft-Assembly Front Left PEP BOYS Reman fits 04-06 Chrysler Pacifica",
            "Brand": "PB/Cardone",
            "ManufacturePartNumber": "60-3399",
            "Class": "Cv Axle Assembly|Cv Axle Shaft",
            "SubClass": "Cv Components",
            "Month": "Oct 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Oct 22",
            "Status": "Modified",
            "Cost": "637",
            "Retail": "672",
            "Gross_Margin": "35",
            "Gross_Margin_Percentage": "17",
            "Rev": "281463",
            "Initial_Order_Quantity": "513",
            "Start_Date": "10/25/22",
            "End_Date": "5/16/23",
            "Forecast": "419",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/25/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "86",
            "Make": "Ram",
            "Model": "ProMaster Cargo Van",
            "Year": "2009",
            "SKU": "75598283",
            "Title": "Suspension Ball Joint Front Lower McQuay-Norris FA2099",
            "Brand": "McQuay-Norris",
            "ManufacturePartNumber": "Fa2099",
            "Class": "Suspension Ball Joint",
            "SubClass": "Suspension, Springs and Related Components",
            "Month": "Oct 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Oct 22",
            "Status": "EOL",
            "Cost": "581",
            "Retail": "677",
            "Gross_Margin": "96",
            "Gross_Margin_Percentage": "30",
            "Rev": "546429",
            "Initial_Order_Quantity": "894",
            "Start_Date": "9/20/22",
            "End_Date": "3/19/23",
            "Forecast": "807",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/18/22",
            "Algo_Recommended_EOL": "8/8/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "87",
            "Make": "Nissan",
            "Model": "Frontier Crew Cab",
            "Year": "2003",
            "SKU": "15037514971",
            "Title": "Suspension Control Arm ACDelco 45D3492",
            "Brand": "ACDelco Air Conditioning Kits",
            "ManufacturePartNumber": "45d3492",
            "Class": "Suspension Control Arm",
            "SubClass": "Suspension, Springs and Related Components",
            "Month": "Oct 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Oct 22",
            "Status": "EOL",
            "Cost": "596",
            "Retail": "665",
            "Gross_Margin": "69",
            "Gross_Margin_Percentage": "15",
            "Rev": "186959",
            "Initial_Order_Quantity": "361",
            "Start_Date": "10/9/22",
            "End_Date": "4/20/23",
            "Forecast": "281",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/22/22",
            "Algo_Recommended_EOL": "8/15/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "88",
            "Make": "Toyota",
            "Model": "Camry",
            "Year": "2015",
            "SKU": "17037511881",
            "Title": "Suspension Control Arm and Ball Joint Assembly Front Right Lower Delphi TC5822",
            "Brand": "Delphi",
            "ManufacturePartNumber": "Tc5822",
            "Class": "Suspension Control Arm and Ball Joint Assembly",
            "SubClass": "Suspension, Springs and Related Components",
            "Month": "Oct 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Oct 22",
            "Status": "EOL",
            "Cost": "560",
            "Retail": "655",
            "Gross_Margin": "95",
            "Gross_Margin_Percentage": "25",
            "Rev": "239392",
            "Initial_Order_Quantity": "367",
            "Start_Date": "8/25/22",
            "End_Date": "4/3/23",
            "Forecast": "365",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/30/22",
            "Algo_Recommended_EOL": "8/24/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "89",
            "Make": "Chevrolet",
            "Model": "Express 3500 Cargo",
            "Year": "2017",
            "SKU": "21039753499",
            "Title": "Suspension Control Arm and Ball Joint Assembly-GL Front Right Lower PTC K8425",
            "Brand": "Power Train Components",
            "ManufacturePartNumber": "K8425",
            "Class": "Suspension Control Arm and Ball Joint Assembly",
            "SubClass": "Suspension, Springs and Related Components",
            "Month": "Oct 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Oct 22",
            "Status": "EOL",
            "Cost": "557",
            "Retail": "673",
            "Gross_Margin": "116",
            "Gross_Margin_Percentage": "13",
            "Rev": "539752",
            "Initial_Order_Quantity": "829",
            "Start_Date": "9/20/22",
            "End_Date": "6/16/23",
            "Forecast": "802",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/8/22",
            "Algo_Recommended_EOL": "8/14/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "90",
            "Make": "Toyota",
            "Model": "Prius Prime",
            "Year": "2022",
            "SKU": "252774911",
            "Title": "Advance CK620476 Suspension Control Arm and Ball Joint Assembly",
            "Brand": "ADVANCE/Chassis & Steering",
            "ManufacturePartNumber": "Ck620476",
            "Class": "Suspension Control Arm and Ball Joint Assembly",
            "SubClass": "Suspension, Springs and Related Components",
            "Month": "Noc 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Noc 22",
            "Status": "EOL",
            "Cost": "587",
            "Retail": "676",
            "Gross_Margin": "89",
            "Gross_Margin_Percentage": "23",
            "Rev": "370040",
            "Initial_Order_Quantity": "562",
            "Start_Date": "10/4/22",
            "End_Date": "5/3/23",
            "Forecast": "547",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/4/22",
            "Algo_Recommended_EOL": "8/8/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "91",
            "Make": "Toyota",
            "Model": "RAV4 Prime",
            "Year": "2003",
            "SKU": "6047043705",
            "Title": "ACDelco Professional/Gold 45G9319 - Suspension Control Arm Bushing, Inner",
            "Brand": "ACDelco|ACDelco/Gold",
            "ManufacturePartNumber": "45g9319",
            "Class": "Suspension Control Arm Bushing",
            "SubClass": "Bushings",
            "Month": "Noc 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Noc 22",
            "Status": "EOL",
            "Cost": "586",
            "Retail": "690",
            "Gross_Margin": "104",
            "Gross_Margin_Percentage": "10",
            "Rev": "548407",
            "Initial_Order_Quantity": "834",
            "Start_Date": "10/12/22",
            "End_Date": "7/7/23",
            "Forecast": "795",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/23/22",
            "Algo_Recommended_EOL": "8/9/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "92",
            "Make": "Chevrolet",
            "Model": "Silverado 3500 HD Regular Cab",
            "Year": "2013",
            "SKU": "140246532",
            "Title": "Suspension Stabilizer Bar Bushing Front Mevotech fits 06-07 Lincoln Mark LT",
            "Brand": "Mevotech",
            "ManufacturePartNumber": "Ms40833",
            "Class": "Suspension Stabilizer BAR Bushing",
            "SubClass": "Bushings",
            "Month": "Noc 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Noc 22",
            "Status": "EOL",
            "Cost": "574",
            "Retail": "676",
            "Gross_Margin": "102",
            "Gross_Margin_Percentage": "24",
            "Rev": "82080",
            "Initial_Order_Quantity": "192",
            "Start_Date": "9/5/22",
            "End_Date": "4/20/23",
            "Forecast": "121",
            "Submitted_By": "Factory B",
            "Submitted_Date": "7/19/22",
            "Algo_Recommended_EOL": "8/24/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "93",
            "Make": "Chevrolet",
            "Model": "Equinox EV",
            "Year": "2015",
            "SKU": "174185046",
            "Title": "Suspension Strut-Ultra Struts Rear Tru Star G56681",
            "Brand": "Tru Star",
            "ManufacturePartNumber": "G56681",
            "Class": "Suspension Strut",
            "SubClass": "Shocks and Struts",
            "Month": "Noc 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Noc 22",
            "Status": "EOL",
            "Cost": "563",
            "Retail": "657",
            "Gross_Margin": "94",
            "Gross_Margin_Percentage": "19",
            "Rev": "128663",
            "Initial_Order_Quantity": "269",
            "Start_Date": "9/11/22",
            "End_Date": "5/29/23",
            "Forecast": "196",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/14/22",
            "Algo_Recommended_EOL": "8/17/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "94",
            "Make": "Hyundai",
            "Model": "Ioniq Plug-in Hybrid",
            "Year": "2012",
            "SKU": "217768153",
            "Title": "PB/Prosteer MK90464 Suspension Strut Bumper, Front",
            "Brand": "Pep Boys",
            "ManufacturePartNumber": "Mk90464",
            "Class": "Suspension Strut Bumper",
            "SubClass": "Shocks and Struts",
            "Month": "Dec 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Dec 22",
            "Status": "EOL",
            "Cost": "627",
            "Retail": "676",
            "Gross_Margin": "49",
            "Gross_Margin_Percentage": "10",
            "Rev": "539674",
            "Initial_Order_Quantity": "811",
            "Start_Date": "8/26/22",
            "End_Date": "3/18/23",
            "Forecast": "798",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/28/22",
            "Algo_Recommended_EOL": "8/11/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "95",
            "Make": "Toyota",
            "Model": "Camry Hybrid",
            "Year": "2005",
            "SKU": "114961786",
            "Title": "Suspension Strut Cartridge Front FCS 36C367",
            "Brand": "FCS Auto Parts",
            "ManufacturePartNumber": "36c367",
            "Class": "Suspension Strut Cartridge",
            "SubClass": "Shocks and Struts",
            "Month": "Dec 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Dec 22",
            "Status": "EOL",
            "Cost": "567",
            "Retail": "679",
            "Gross_Margin": "112",
            "Gross_Margin_Percentage": "20",
            "Rev": "156629",
            "Initial_Order_Quantity": "290",
            "Start_Date": "9/27/22",
            "End_Date": "4/14/23",
            "Forecast": "231",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/19/22",
            "Algo_Recommended_EOL": "8/7/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "96",
            "Make": "Acura",
            "Model": "Integra",
            "Year": "2018",
            "SKU": "252436816",
            "Title": "Auto Extra MS60211",
            "Brand": "Auto Extra",
            "ManufacturePartNumber": "Ms60211",
            "Class": "Suspension Strut Mount",
            "SubClass": "Shocks and Struts",
            "Month": "Dec 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Dec 22",
            "Status": "EOL",
            "Cost": "578",
            "Retail": "696",
            "Gross_Margin": "118",
            "Gross_Margin_Percentage": "23",
            "Rev": "185930",
            "Initial_Order_Quantity": "308",
            "Start_Date": "9/29/22",
            "End_Date": "6/24/23",
            "Forecast": "267",
            "Submitted_By": "Factory B",
            "Submitted_Date": "6/8/22",
            "Algo_Recommended_EOL": "8/6/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "97",
            "Make": "Kia",
            "Model": "EV9",
            "Year": "2015",
            "SKU": "174973782",
            "Title": "Suspension Strut Assembly-Guardian Strut Assembly CARQUEST 801612",
            "Brand": "Carquest",
            "ManufacturePartNumber": "801612",
            "Class": "Suspension Strut|Suspension Strut Assembly",
            "SubClass": "Shocks and Struts",
            "Month": "Dec 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Dec 22",
            "Status": "EOL",
            "Cost": "551",
            "Retail": "674",
            "Gross_Margin": "123",
            "Gross_Margin_Percentage": "30",
            "Rev": "9568",
            "Initial_Order_Quantity": "89",
            "Start_Date": "10/21/22",
            "End_Date": "4/28/23",
            "Forecast": "14",
            "Submitted_By": "Factory C",
            "Submitted_Date": "5/15/22",
            "Algo_Recommended_EOL": "8/25/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "98",
            "Make": "Chevrolet",
            "Model": "Bolt EUV",
            "Year": "2005",
            "SKU": "218175543",
            "Title": "Auto Extra WH590360",
            "Brand": "Auto Extra",
            "ManufacturePartNumber": "Wh590360",
            "Class": "Wheel Bearing and Hub Assembly",
            "SubClass": "Hubs and Related Components",
            "Month": "Dec 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Dec 22",
            "Status": "EOL",
            "Cost": "586",
            "Retail": "669",
            "Gross_Margin": "83",
            "Gross_Margin_Percentage": "14",
            "Rev": "438729",
            "Initial_Order_Quantity": "741",
            "Start_Date": "10/7/22",
            "End_Date": "5/18/23",
            "Forecast": "656",
            "Submitted_By": "Factory C",
            "Submitted_Date": "6/23/22",
            "Algo_Recommended_EOL": "8/17/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "99",
            "Make": "MAZDA",
            "Model": "CX-50",
            "Year": "2001",
            "SKU": "190282000",
            "Title": "Wheel Lug Nut-Nut CARQUEST 98966.1",
            "Brand": "Carquest",
            "ManufacturePartNumber": "98966.1",
            "Class": "Wheel Lug Nut",
            "SubClass": "Hardware, Fasteners and Fittings",
            "Month": "Dec 22",
            "Promo_Name": "Suspension",
            "Promo_Name_Group": "Suspension - Dec 22",
            "Status": "EOL",
            "Cost": "561",
            "Retail": "698",
            "Gross_Margin": "137",
            "Gross_Margin_Percentage": "14",
            "Rev": "262570",
            "Initial_Order_Quantity": "400",
            "Start_Date": "9/13/22",
            "End_Date": "6/9/23",
            "Forecast": "376",
            "Submitted_By": "Factory C",
            "Submitted_Date": "6/30/22",
            "Algo_Recommended_EOL": "8/24/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "100",
            "Make": "Chevrolet",
            "Model": "Express 3500 Passenger",
            "Year": "2008",
            "SKU": "210396786",
            "Title": "Clutch Slave Cylinder Advance 900126 fits 99-07 Porsche 911",
            "Brand": "Advance Auto Parts",
            "ManufacturePartNumber": "900126",
            "Class": "Clutch Slave Cylinder",
            "SubClass": "Manual Transmission Components",
            "Month": "Dec 22",
            "Promo_Name": "Transmission",
            "Promo_Name_Group": "Transmission - Dec 22",
            "Status": "EOL",
            "Cost": "619",
            "Retail": "682",
            "Gross_Margin": "63",
            "Gross_Margin_Percentage": "23",
            "Rev": "612427",
            "Initial_Order_Quantity": "936",
            "Start_Date": "9/18/22",
            "End_Date": "4/27/23",
            "Forecast": "898",
            "Submitted_By": "Factory C",
            "Submitted_Date": "7/14/22",
            "Algo_Recommended_EOL": "8/10/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "101",
            "Make": "Chevrolet",
            "Model": "Silverado 2500 HD",
            "Year": "2007",
            "SKU": "A1R111086",
            "Title": "CARQUEST CBS1291 Reman Headlight Switch",
            "Brand": "Carquest",
            "ManufacturePartNumber": "25633",
            "Class": "Headlight Switch",
            "SubClass": "Headlights and Related Components",
            "Month": "Dec 22",
            "Promo_Name": "Headlights",
            "Promo_Name_Group": "Headlights - Dec 22",
            "Status": "Pitched",
            "Cost": "88",
            "Retail": "146",
            "Gross_Margin": "58",
            "Gross_Margin_Percentage": "40",
            "Rev": "9956",
            "Initial_Order_Quantity": "19",
            "Start_Date": "10/20/22",
            "End_Date": "5/24/23",
            "Forecast": "68",
            "Submitted_By": "Factory A",
            "Submitted_Date": "7/14/22",
            "Fulfillment_Type": "Dropship"
        },
        {
            "Item_ID": "102",
            "Make": "Chevrolet",
            "Model": "Silverado 2500 HD",
            "Year": "2007",
            "SKU": "AB1591942",
            "Title": "Headlight Assembly Front Left Hella 354226051",
            "Brand": "Hella",
            "ManufacturePartNumber": "25634",
            "Class": "Headlight Assembly",
            "SubClass": "Headlights and Related Components",
            "Month": "Dec 22",
            "Promo_Name": "Headlights",
            "Promo_Name_Group": "Headlights - Dec 22",
            "Status": "Pitched",
            "Cost": "97",
            "Retail": "161",
            "Gross_Margin": "64",
            "Gross_Margin_Percentage": "40",
            "Rev": "105399",
            "Initial_Order_Quantity": "705",
            "Start_Date": "10/20/22",
            "End_Date": "4/22/23",
            "Forecast": "654",
            "Submitted_By": "Factory B",
            "Submitted_Date": "5/7/22",
            "Fulfillment_Type": "Branded"
        },
        {
            "Item_ID": "103",
            "Make": "Chevrolet",
            "Model": "Silverado 2500 HD",
            "Year": "2007",
            "SKU": "KVC100177",
            "Title": "Keystone Automotive Operations GM2509114 - Headlight Bracket",
            "Brand": "Keystone",
            "ManufacturePartNumber": "25635",
            "Class": "Headlight Bracket",
            "SubClass": "Headlights and Related Components",
            "Month": "Dec 22",
            "Promo_Name": "Headlights",
            "Promo_Name_Group": "Headlights - Dec 22",
            "Status": "Pitched",
            "Cost": "114",
            "Retail": "190",
            "Gross_Margin": "76",
            "Gross_Margin_Percentage": "40",
            "Rev": "79096",
            "Initial_Order_Quantity": "508",
            "Start_Date": "9/4/22",
            "End_Date": "3/22/23",
            "Forecast": "416",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/11/22",
            "Fulfillment_Type": "Private Label"
        },
        {
            "Item_ID": "104",
            "Make": "Chevrolet",
            "Model": "Silverado 2500 HD",
            "Year": "2007",
            "SKU": "RB1591941",
            "Title": "O'Reilly 25-0656 Headlight Relay",
            "Brand": "O'Reilly",
            "ManufacturePartNumber": "25636",
            "Class": "Headlight Relay",
            "SubClass": "Headlights and Related Components",
            "Month": "Dec 22",
            "Promo_Name": "Headlights",
            "Promo_Name_Group": "Headlights - Dec 22",
            "Status": "Pitched",
            "Cost": "107",
            "Retail": "178",
            "Gross_Margin": "71",
            "Gross_Margin_Percentage": "40",
            "Rev": "104786",
            "Initial_Order_Quantity": "651",
            "Start_Date": "8/30/22",
            "End_Date": "5/18/23",
            "Forecast": "589",
            "Submitted_By": "Algo Recommendation",
            "Submitted_Date": "5/17/22",
            "Fulfillment_Type": "Dropship"
        }
    ];
}




