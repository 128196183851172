import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'fusion-gage-chart',
  templateUrl: './gage-chart.component.html',
  styleUrls: ['./gage-chart.component.scss']
})
export class FusionGageChartComponent implements OnInit, OnChanges {
  @Input() chartCaption?: string = "";
  @Input() dialValue: any;
  @Input() height?: string = '200';
  @Input() showvalues?: string = '1';
  @Input() numbersuffix?: string = '%';
  @Input() numberprefix?: string = '';


  /*
    Sample Color Range
    "colorRange": {
      "color": [{
        "minValue": "0",
        "maxValue": "50",
        "code": "#e44a00"
      }]
    }

    Sample Dials Value
    {
      dial: [{ value: "67" }]
    }
  */

  dataSource: any = {
    chart: {
      caption: "",
      theme: "fusion",
      lowerLimit: "0",
      upperLimit: "100",
      showValue: "1",
      chartLeftMargin: "0",
      chartTopMargin: "0",
      chartRightMargin: "0",
      chartBottomMargin: "0",
      canvasPadding: "0",
      showBorder: "1",
      borderColor: "#8D009D",
      borderThickness: "2",
      borderAlpha: "20",
      showvalues: this.showvalues,
      numbersuffix: this.numbersuffix,
    },
    colorRange: {
      color: [{
        minValue: "0",
        maxValue: "100",
        code: "#e44a00"
      }]
    },
    dials: {
      dial: []
    }
  };

  chartConfig: Object = {
    type: 'angulargauge',
    dataFormat: 'json',
  };

  hideChart = false;


  constructor() {

  }

  ngOnInit() {
    this.initChartDataSource();
     // for window resize handling (graph responsiveness)
     fromEvent(window, 'resize').pipe(
      debounceTime(250))
      .subscribe(() => this.showHideChart());
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initChartDataSource();
  }

  initChartDataSource() {
    this.dataSource.chart.caption = this.chartCaption;
    this.dataSource.dials.dial = [{ value: this.dialValue }];
  }

  showHideChart() {
    this.hideChart = true;
    setTimeout(() => {
      this.hideChart = false;
    }, 200);
  }

}
