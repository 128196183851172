import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { PlanogramService } from 'src/services/Item-Management-Services/planogram.service';
import { AddMovieComponent } from '../../dialogs/add-movie/add-movie.component';
import { BulkUpdatePlanogramComponent } from '../../dialogs/bulk-update-planogram/bulk-update-planogram.component';

@Component({
  selector: 'app-planogram-table',
  templateUrl: './planogram-table.component.html',
  styleUrls: ['./planogram-table.component.scss']
})
export class PlanogramTableComponent implements OnInit {


  tableData = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  displayedColumns: string[] = ['select', 'title', 'weeks_old', 'tw_forecast', 'on_hand', 'on_order', 'base_ca', 'calc_ca', 'action'];

  loading = false;
  updatedIndices = [];

  constructor(
    private dialogRef: MatDialogRef<PlanogramTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planogramService: PlanogramService,
    private toastrService: ToastrService,
    private dialog: MatDialog) {
    if (this.data.tableData) {
      this.tableData.data = [...this.tableData.data, ...this.data.tableData.FOSNR_B, ...this.data.tableData.FOSNR_D, ...this.data.tableData.FOSNR_C];
    }
  }

  ngOnInit(): void {
  }

  close(obj?: any) {
    this.dialogRef.close(obj || false);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableData.data.forEach(row => this.selection.select(row));
  }

  bulkUpdate() {
    const arrayOfSelectedItems = [];
    this.tableData.data.forEach(element => {
      this.selection.selected.forEach((selectedElement, index) => {
        if (element.id == selectedElement.id) {
          arrayOfSelectedItems.push(element);
        }
      });
    });
    const dialogRef = this.dialog.open(BulkUpdatePlanogramComponent, {
      data: {
        selectedData: JSON.parse(JSON.stringify(arrayOfSelectedItems))
      },
      disableClose: true,
      maxHeight: '90vh'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length) {
        for (let i = 0; i < result.length; i++) {
          for (let j = 0; j < this.tableData.data.length; j++) {
            if (this.tableData.data[j].id == result[i].id) {
              this.tableData.data[j].weeks_old = result[i].weeks_old;
              this.tableData.data[j].tw_forecast = result[i].tw_forecast;
              this.tableData.data[j].on_hand = result[i].on_hand;
              this.tableData.data[j].on_order = result[i].on_order;
              this.tableData.data[j].base_ca = result[i].base_ca;
              this.tableData.data[j].calc_ca = result[i].calc_ca;
              if (this.updatedIndices.indexOf(j) == -1)
                this.updatedIndices.push(j);
            }
          }
        }
      }
    });
  }

  singleItemEdit(item, index) {
    if (this.loading) return;
    const dialogRef = this.dialog.open(AddMovieComponent, {
      data: {
        isEdit: true,
        itemData: item,
        name: this.data.name,
        userId: this.data.userId || 1
      },
      disableClose: true,
      maxHeight: '90vh'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        item.weeks_old = result.weeks_old;
        item.tw_forecast = result.tw_forecast;
        item.on_hand = result.on_hand;
        item.on_order = result.on_order;
        item.base_ca = result.base_ca;
        item.calc_ca = result.calc_ca;
        this.planogramService.updatePlanogramItem.next(item);
        // if (this.updatedIndices.indexOf(index) == -1)
        //   this.updatedIndices.push(index);
      }
    });
  }

  updateButton() {
    this.loading = true;
    const updatePreviousWeekData = {
      updatedItems: [],
      updatedBy: this.data.userId || 1,
      name: this.data.name
    };
    this.updatedIndices.forEach(element => {
      updatePreviousWeekData.updatedItems.push(this.tableData.data[element]);
    });
    const formData: FormData = new FormData();
    formData.append('update_previous_week_data', JSON.stringify(updatePreviousWeekData));
    this.planogramService.bulkUpdatePlanogramData(formData)
    .subscribe((res: any) => {
      this.loading = false;
      this.toastrService.success('Data has been updated successfully.');
      this.close(this.tableData.data);
    },
    err => {
      this.loading = false;
      this.toastrService.error('Something went wrong.');
    });
  }

}
