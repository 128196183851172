import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import {  GridApi, ColumnApi, GridOptions, RowNode, ColumnState } from "ag-grid-enterprise";
import * as _ from 'lodash';
import {
  paginationPageSize,
  cacheBlockSize,
  promotionDetailGridColDefs,
  approvalForecastGridColDefs,
  defaultColDef
} from '../../ag-grid/gridOptions';
import { getUniqueId, gridDataExportParams, onCellKeyDown, overlayLoadingTemplate, registerHorizontalScroll } from '../../../util/util';
import { INVALID_TEAM, NEW_ITEM_PROMOTION_DETAIL_APROVAL, NEW_ITEM_PROMOTION_FORECAST_APROVAL, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { ItemManagerService, ItemPromotionService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { getDatePicker } from 'src/common/ag-date-picker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';

@Component({
  selector: 'app-item-approval-detail',
  templateUrl: './item-approval-detail.component.html',
  styleUrls: ['./item-approval-detail.component.css']
})
export class ItemApprovalDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  INVALID_TEAM = INVALID_TEAM;

  gridPanelOpenState: boolean = true;
  isItemDetailGridDataUpdated: boolean = false;
  isForecastGridDataUpdated: boolean = false;
  isForecastDataLoaded: boolean = false;

  public accountSubscription: Subscription;
  public actionSubscription: Subscription;
  public planninhMonthSubscription: Subscription;

  forecastGridUpdatedRows: any = [];

  public defaultColDef = defaultColDef;
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = 'multiple';
  editType = 'fullRow';


  components = {
    numericCellEditor: getNumericCellEditor(),
    datePicker: getDatePicker()
  }

  public itemDetailGridColumnDefs = promotionDetailGridColDefs(true);

  public itemDetailAutoGroupColumnDef = {
    headerName: 'Status',
    field: 'actionName',
    width: 250,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true }
  };

  public forecastGridAutoGroupColumnDef = {
    headerName: 'Status',
    headerTooltip: 'Status',
    field: 'ActionName',
    cellClass: 'ag-group-box-cell',
    width: 180,
    pinned: 'left',
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true }
  };

  public itemDetailGridApi: GridApi;
  public itemDetailColApi: ColumnApi;
  public itemDetailGridOptions: any;
  itemDetailGridData: any[] = [];
  itemDetailGridUpdatedRows: any = [];
  public gridColumnStateItemGrid: ColumnState[] = [];

  public forecastGridColumnDefs = approvalForecastGridColDefs(new Date(), true);
  public forecastGridApi: GridApi;
  public forecastColApi: ColumnApi;
  public forecastGridOptions: GridOptions = {};
  forecastGridData: any[] = [];
  forecastGridDataOrgState: any[] = [];
  public gridColumnStateForecastGrid: ColumnState[] = [];

  selectedApprovalDetailItems: any = [];
  accountList: any = [];
  actionsList: any = [];
  planningMonthList: any = [];
  selectedTab: number = 0;
  selectedPlanningMonth: any;
  filterEvent: any = {};

  userTeam: any = '';
  isRetailerTeam: boolean = false;

  itemDetailGridId = 'itemDetailGrid';
  approvalForecastGridId = 'approvalForecastGrid';

  itemDetailGridMutationRef: MutationObserver;
  approvalForecastGridMutationRef: MutationObserver;

  onCellKeyDown = onCellKeyDown;

  itemGridPreference: any;
  forecastGridPreference: any;
  overlayLoadingTemplate = overlayLoadingTemplate;

  itemApprovalsSubscription: Subscription;
  weeklyForecastSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public itemPromotionService: ItemPromotionService,
    private itemManagerService: ItemManagerService,
    public toastr: NgxToasterService,
    private usersService: UsersService,
    private userPreferenceService: UserPreferenceService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    const uniqueId = getUniqueId();
    this.itemDetailGridId = this.itemDetailGridId + uniqueId;
    this.approvalForecastGridId = this.approvalForecastGridId + uniqueId;
  }

  ngOnInit() {
    this.getUserSecreenPrefereence();
    this.getFilterContorlsData();
    this.initiateValues();
    this.setRoleBaseAccess();
  }

  getUserSecreenPrefereence() {
    this.itemGridPreference = this.userPreferenceService.getPreferenceByKeys(NEW_ITEM_PROMOTION_DETAIL_APROVAL, NEW_ITEM_PROMOTION_DETAIL_APROVAL);
    this.forecastGridPreference = this.userPreferenceService.getPreferenceByKeys(NEW_ITEM_PROMOTION_FORECAST_APROVAL, NEW_ITEM_PROMOTION_FORECAST_APROVAL);
    this.gridColumnStateItemGrid = this.itemGridPreference.columnState ? JSON.parse(this.itemGridPreference.columnState) : [];
    this.gridColumnStateForecastGrid = this.forecastGridPreference.columnState ? JSON.parse(this.forecastGridPreference.columnState) : [];
  }

  ngAfterViewInit() {
  }

  initiateValues() {
    this.accountSubscription = this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
      if (accountList && accountList.length) {
        this.accountList = accountList;
      }
    });
    this.actionSubscription = this.itemManagerService.getActionsListSubject().subscribe((actionsList: any) => {
      if (actionsList && actionsList.length) {
        this.actionsList = this.itemManagerService.FilterActionByScreen(actionsList);
      }
    });
    this.planninhMonthSubscription = this.itemManagerService.getPlanningMonthListSubject().subscribe((planningMonthList: any) => {
      if (planningMonthList && planningMonthList.length) {
        this.planningMonthList = planningMonthList;
      }
    });
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        this.itemDetailGridColumnDefs = promotionDetailGridColDefs(true, this.isRetailerTeam);
        this.itemDetailGridApi && this.itemDetailGridApi.setColumnDefs(this.itemDetailGridColumnDefs);
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  setTabIndex(tabEvent) {
    this.selectedTab = tabEvent.index;
    if (this.selectedTab === 1) {
      this.expandForecastRowGroups();
    }
    if (!this.approvalForecastGridMutationRef) {
      setTimeout(() => {
        this.approvalForecastGridMutationRef = registerHorizontalScroll(this.approvalForecastGridId);
      }, 500);
    }
    if (!this.itemDetailGridMutationRef) {
      setTimeout(() => {
        this.itemDetailGridMutationRef = registerHorizontalScroll(this.itemDetailGridId);
      }, 500);
    }
  }

  expandItemDetailRowGroups() {
    this.itemDetailGridApi.forEachNode(node => {
      node.setExpanded(true);
    });
  }

  expandForecastRowGroups() {
    this.forecastGridApi.forEachNode(node => {
      node.setExpanded(true);
    });
  }

  applyFilters(event) {
    this.filterEvent = event;
    this.selectedPlanningMonth = event['MonthId'];
    this.getFilterPromotionItemApprovalList(event);
    this.getFilterPromotionItemWeeklyForecastList(event, true);
  }

  getFilterPromotionItemApprovalList(event) {
    if (this.itemApprovalsSubscription) this.itemApprovalsSubscription.unsubscribe();
    this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
    this.itemApprovalsSubscription = this.itemPromotionService.FilterPromotionsDetailItemsApprovalList(event).subscribe((res: any) => {
      this.itemDetailGridData = _.orderBy(res, ['promotionItemDetailId'], ['desc']);
      setTimeout(() => {
        this.itemDetailGridApi.setRowData(this.itemDetailGridData);
        this.expandItemDetailRowGroups();
        this.applyColumnStateForecastGrid();
        this.applyColumnStateItemGrid();
        this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
        this.itemDetailGridData.length == 0 && this.itemDetailGridApi.showNoRowsOverlay();
      }, 0);
    }, err => {
      this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
    });
  }

  getFilterPromotionItemWeeklyForecastList(event, calculteColDef = false) {
    if (this.weeklyForecastSubscription) this.weeklyForecastSubscription.unsubscribe();
    this.forecastGridApi && this.forecastGridApi.showLoadingOverlay();
    this.weeklyForecastSubscription = this.itemPromotionService.FilterPromotionItemDetailWeeklyForecastList(event).subscribe((res: any) => {
      this.isForecastDataLoaded = true;
      this.forecastGridData = _.orderBy(res, ['PromotionItemDetailId'], ['desc']);
      this.forecastGridDataOrgState = _.cloneDeep(this.forecastGridData);
      setTimeout(() => {
        calculteColDef && this.setForecastGridColumnDefinitions();
        if (this.forecastGridApi) {
          this.forecastGridApi.setRowData(this.forecastGridData);
          this.expandForecastRowGroups();
          this.applyColumnStateForecastGrid();
          this.applyColumnStateItemGrid();
        }
        this.forecastGridApi && this.forecastGridApi.hideOverlay();
        this.forecastGridData.length == 0 && this.forecastGridApi.showNoRowsOverlay();
      }, 0);
    }, err => {
      this.forecastGridApi && this.forecastGridApi.hideOverlay();
    });
  }

  itemGridRowSelectionChanged(params) {
    this.selectedApprovalDetailItems = params.api.getSelectedRows();
  }

  openActionsDialog(action: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Change Status to '${action.actionName}'`,
        confirmationMessage: `Are you sure you want to change the status to ${action.actionName}?`
      }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.handleActions(action);
      }
    });
  }

  handleActions(action) {
    this.selectedApprovalDetailItems.forEach(item => {
      item.actionId = action.actionId;
      item.actionName = action.actionName;
    });
    this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
    this.forecastGridApi && this.forecastGridApi.showLoadingOverlay();
    this.itemPromotionService.BulkUpdatePromotionDetailItems({ Data: this.selectedApprovalDetailItems }).subscribe((res: any) => {
      this.toastr.success('Success', 'Promotion Details Updated Successfully.');
      this.selectedApprovalDetailItems = [];
      this.getFilterPromotionItemApprovalList(this.filterEvent);
      this.getFilterPromotionItemWeeklyForecastList(this.filterEvent);
    }, err => {
      this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
      this.forecastGridApi && this.forecastGridApi.hideOverlay();
      this.toastr.error('Error', 'Promotion Details Update Failed.');
      console.log(err);
    });
  }

  async getFilterContorlsData() {
    this.itemManagerService.GetAllAccounts().toPromise();
    this.itemManagerService.GetActionsList().toPromise();
    await this.itemManagerService.GetPlanningMonthList().toPromise();
  }

  onItemDetailGridReady(params) {
    this.itemDetailGridApi = params.api;
    this.itemDetailColApi = params.columnApi;
    this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
    // FOR HORIZONTAL SCROLL
    setTimeout(() => {
      if (!this.itemDetailGridMutationRef)
        this.itemDetailGridMutationRef = registerHorizontalScroll(this.itemDetailGridId);
    }, 500);
    this.applyColumnStateItemGrid();
  }

  openItemDetailGridColumnDialog() {
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.itemDetailColApi,
        skipHeaders: ['promotionItemName']
      },
      width: '850px'
    });
  }

  onExportItemDetailGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'NewItemPromotions',
      colDefs: this.itemDetailGridColumnDefs,
      allColumns: true
    });
    this.itemDetailGridApi.exportDataAsCsv(csvExportParams);
  }

  itemDetailGridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
      this.isItemDetailGridDataUpdated = true;
      const prvUpdatedRowStateIndex = _.findIndex(this.itemDetailGridUpdatedRows, a => a.promotionItemDetailId === params.data.promotionItemDetailId);
      if (prvUpdatedRowStateIndex < 0) {
        this.itemDetailGridUpdatedRows.push(params.data);
      } else {
        this.itemDetailGridUpdatedRows[prvUpdatedRowStateIndex] = params.data;
      }
    }
  }

  saveUpdatedItemDetailGridData(event) {
    event.stopPropagation();
    this.itemDetailGridApi.stopEditing();
    setTimeout(() => {
      if (this.itemDetailGridUpdatedRows.length) {
        this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
        this.forecastGridApi && this.forecastGridApi.showLoadingOverlay();
        this.itemPromotionService.BulkUpdatePromotionDetailItems({ Data: this.itemDetailGridUpdatedRows }).subscribe((res: any) => {
          this.toastr.success('Success', 'Promotion Details Updated Successfully.');
          this.itemDetailGridData = _.map(this.itemDetailGridData, (item) => {
            return _.merge(item, _.find(res, { 'promotionItemDetailId': item.promotionItemDetailId }));
          });
          this.itemDetailGridApi.setRowData(this.itemDetailGridData);
          setTimeout(() => {
            this.expandItemDetailRowGroups();
          }, 0);
          this.itemDetailGridUpdatedRows = [];
          this.isItemDetailGridDataUpdated = false;
          this.getFilterPromotionItemWeeklyForecastList(this.filterEvent);
          this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
          this.itemDetailGridData.length == 0 && this.itemDetailGridApi.showNoRowsOverlay();
        }, err => {
          this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
          this.forecastGridApi && this.forecastGridApi.hideOverlay();
          this.toastr.error('Error', 'Promotion Details Update Failed.');
        });
      }
    }, 100);
  }

  onItemGridStateChange(e) {
    this.gridColumnStateItemGrid = this.itemDetailColApi.getColumnState();
    this.itemGridPreference.columnState = this.gridColumnStateItemGrid;
    this.updateScreenPreference(this.itemGridPreference);
  }

  applyColumnStateItemGrid() {
    if (this.itemDetailColApi && this.gridColumnStateItemGrid && this.gridColumnStateItemGrid.length) {
      setTimeout(() => {
        this.itemDetailColApi.applyColumnState({ state: this.gridColumnStateItemGrid });
      }, 100);
    }
  }

  onForecastGridStateChange(e) {
    this.gridColumnStateForecastGrid = this.forecastColApi.getColumnState();
    this.forecastGridPreference.columnState = this.gridColumnStateForecastGrid;
    this.updateScreenPreference(this.forecastGridPreference);
  }

  updateScreenPreference(preferenceObject) {
    this.approvalForecastGridMutationRef = registerHorizontalScroll(this.approvalForecastGridId);
    this.itemDetailGridMutationRef = registerHorizontalScroll(this.itemDetailGridId);
    this.userPreferenceService.upsertColumnStatePreference(preferenceObject).subscribe(res => {
      console.log(res);
    });
  }

  applyColumnStateForecastGrid() {
    if (this.forecastColApi && this.gridColumnStateForecastGrid && this.gridColumnStateForecastGrid.length) {
      setTimeout(() => {
        this.forecastColApi.applyColumnState({ state: this.gridColumnStateForecastGrid });
      }, 100);
    }
  }

  onForecastGridReady(params) {
    this.forecastGridApi = params.api;
    this.forecastColApi = params.columnApi;
    // FOR HORIZONTAL SCROLL
    setTimeout(() => {
      if (!this.approvalForecastGridMutationRef)
        this.approvalForecastGridMutationRef = registerHorizontalScroll(this.approvalForecastGridId);
    }, 500);

    setTimeout(() => {
      (this.forecastGridApi as any).gridCore.gridOptions.getRowStyle = (params) => {
        const isLastChild = _.get(params, 'node.lastChild', false);
        const isLeafNode = !(_.get(params, 'node.group', false));
        return (isLastChild && isLeafNode) ? { borderBottom: '3px solid darkgrey' } : undefined;
      };
    }, 300);
    this.applyColumnStateForecastGrid();
  }

  getForecastWeekDatesList() {
    /*
    In Case Week Date Property Missing from Forecast Data
    */
    const weekDates =
      _.orderBy(
        _.compact(
          _.map(
            _.keys(_.first(this.forecastGridData)),
            (a: any) => {
              if (a.includes('PromoPrice')) {
                return a.substring(10);
              }
            })));

    return weekDates;
  }

  forecastGridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
      this.isForecastGridDataUpdated = true;
      const prvUpdatedRowStateIndex = _.findIndex(this.forecastGridUpdatedRows, a => a.PromotionItemDetailId === params.data.PromotionItemDetailId);
      if (prvUpdatedRowStateIndex < 0) {
        this.forecastGridUpdatedRows.push(params.data);
      } else {
        this.forecastGridUpdatedRows[prvUpdatedRowStateIndex] = params.data;
      }
    }
  }

  setWeeklyForecastUpdateDataStructure() {
    const weekDates = this.getForecastWeekDatesList();
    const updatedRowsByWeeks = [];
    this.forecastGridUpdatedRows.forEach(row => {
      const forecastData = _.find(this.forecastGridDataOrgState, a => a.PromotionItemDetailId === row.PromotionItemDetailId);
      weekDates.forEach(week => {
        updatedRowsByWeeks.push({
          PromotionItemDetailId: row.PromotionItemDetailId,
          PromoPrice: (Number(forecastData[`PromoPrice${week}`]) !== Number(row[`PromoPrice${week}`])) ? row[`PromoPrice${week}`] : -1000,
          MarginCredit: (Number(forecastData[`MarginCredit${week}`]) !== Number(row[`MarginCredit${week}`])) ? row[`MarginCredit${week}`] : -1000,
          BAndMPromoForecast: (Number(forecastData[`BAndMPromoForecast${week}`]) !== Number(row[`BAndMPromoForecast${week}`])) ? row[`BAndMPromoForecast${week}`] : -1000,
          DotComForecast: (Number(forecastData[`DotComForecast${week}`]) !== Number(row[`DotComForecast${week}`])) ? row[`DotComForecast${week}`] : -1000,
          OnAd: row[`OnAd${week}`],
          WeekStartDate: week
        });
      });
    });
    return updatedRowsByWeeks;
  }

  saveUpdatedForecastGridData(event) {
    event.stopPropagation();
    this.forecastGridApi.stopEditing();
    setTimeout(() => {
      if (this.forecastGridUpdatedRows.length) {
        const updatedData = this.setWeeklyForecastUpdateDataStructure();
        this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
        this.forecastGridApi && this.forecastGridApi.showLoadingOverlay();
        this.itemPromotionService.BulkUpdatePromotionItemWeeklyForecastList({ Data: updatedData }).subscribe((res: any) => {
          this.toastr.success('Success', 'Promotion Details Updated Successfully.');
          this.getFilterPromotionItemApprovalList(this.filterEvent);
          this.getFilterPromotionItemWeeklyForecastList(this.filterEvent);
          this.forecastGridUpdatedRows = [];
          this.isForecastGridDataUpdated = false;
        }, err => {
          this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
          this.forecastGridApi && this.forecastGridApi.hideOverlay();
          this.toastr.error('Error', 'Promotion Details Update Failed.');
        });
      }
    }, 100);
  }

  openForecastGridColumnDialog() {
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.forecastColApi
      },
      width: '850px'
    });
  }

  setForecastGridColumnDefinitions() {
    const planningMonth = _.find(this.planningMonthList, a => a.monthId === this.selectedPlanningMonth);
    this.forecastGridColumnDefs = approvalForecastGridColDefs(new Date(planningMonth.startDate), this.isRetailerTeam);
    if (this.forecastGridApi) {
      this.forecastGridApi.setColumnDefs([]);
      this.forecastGridApi.setColumnDefs(this.forecastGridColumnDefs);
    }
  }

  onExportForecastGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'NewItemsPromotionWeeklyForecast',
      colDefs: this.forecastGridColumnDefs,
      allColumns: true
    });
    this.forecastGridApi.exportDataAsCsv(csvExportParams);
  }

  exportDataTemplate() {
    const csvExportParams = gridDataExportParams({
      fileName: 'NewItemsPromotionWeeklyDataTemplate',
      colDefs: this.forecastGridColumnDefs,
      isExportTemplate: true,
      allColumns: true
    });
    this.forecastGridApi.exportDataAsCsv(csvExportParams);
  }

  handleExport(event) {
    event.stopPropagation();
  }

  exportNewItemWeeklyDataTemplate() {
    this.exportDataTemplate();
  }

  importNewItemWeeklyData() {
    (document.getElementById('upload-new-item-approval-promotion-data') as any).value = '';
    document.getElementById('upload-new-item-approval-promotion-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const reader = new FileReader();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const importDataFile = reader.result as any;
        /*
          Files Validations Will go here
        */
        this.uploadImportedCsvFile(importDataFile);
      }
    }
  }

  uploadImportedCsvFile(importDataFile) {
    this.itemDetailGridApi && this.itemDetailGridApi.showLoadingOverlay();
    this.forecastGridApi && this.forecastGridApi.showLoadingOverlay();
    this.itemPromotionService.ImportNewItemPromotionDataTemplate({
      fileBase64: importDataFile
    }).subscribe(
      (res: any) => {
        this.toastr.success('Success', 'Promotion Data Imported Successfully.');
        this.getFilterPromotionItemWeeklyForecastList(this.filterEvent);
        this.getFilterPromotionItemApprovalList(this.filterEvent);
      },
      (err: any) => {
        this.itemDetailGridApi && this.itemDetailGridApi.hideOverlay();
        this.forecastGridApi && this.forecastGridApi.hideOverlay();
        this.toastr.error('Error', 'Promotion Data Import Failed.');
      });
  }

  ngOnDestroy() {
    this.accountSubscription.unsubscribe();
    this.actionSubscription.unsubscribe();
    this.planninhMonthSubscription.unsubscribe();
    this.itemDetailGridMutationRef && this.itemDetailGridMutationRef.disconnect();
    this.approvalForecastGridMutationRef && this.approvalForecastGridMutationRef.disconnect();
  }

}

