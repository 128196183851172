export const paginationPageSize = 20;
export const cacheBlockSize = 20;
export const rowSelection = 'multiple';
export const defaultColDef = {
    filter: false,
    sortable: false,
    width: 80,
    resizable: false
};

export const generateGridColumns = (dataArray: any[], editable = true) => {
    const gridColumnDefs = [
        {
            headerName: '',
            field: '',
            checkboxSelection: true,
            headerCheckboxSelection: false,
            resizable: false,
            width: 80,
            pinned: 'left',
            cellStyle: params => {
                if (params.data.is_duplicated) {
                    return { color: 'black', backgroundColor: 'palegoldenrod' };
                }
            }
        }
    ];
    dataArray.forEach((element: any) => {
        const headerObj: any = {
            headerName: element.displayName,
            field: element.name,
            width: 200,
            minWidth: 200,
            resizable: false,
            editable: editable,
            cellEditor: element.is_matric ? 'numberCellEditor' : '',
            isMetricVariable: element.is_matric,
            cellStyle: params => {
                if (params.data.fault_columns
                    && params.data.fault_columns.length
                    && params.colDef.isMetricVariable
                    && params.data.fault_columns.indexOf(params.column.colId) > -1) {
                        // HIGHLIGHTING THE CELL IN CASE OF FAULTY
                        return { color: 'white', backgroundColor: '#ba5555' };
                } else if (params.data.outlier_columns
                    && params.data.outlier_columns.length
                    && params.data.outlier_columns.indexOf(params.column.colId) > -1) {
                    // HIGHLIGHTING THE CELL IN CASE OF OUTLIERS
                    return { color: 'black', backgroundColor: '#adadad' };
                } else if (params.data.exceptional_columns
                    && params.data.exceptional_columns.length
                    && params.data.exceptional_columns.indexOf(params.column.colId) > -1) {
                    // HIGHLIGHTING THE CELL IN CASE OF EXCEPTIONS
                    return { color: 'black', backgroundColor: 'pink' };
                }
                else if ((params.value == null || params.value == "") && (params.value != '0')) {
                    // HIGHLIGHTING THE CELL IN CASE OF EMPTY
                    return { color: 'white', backgroundColor: 'lightskyblue' };
                } else if (params.data.is_duplicated) {
                    // HIGHLIGHTING THE CELL IN CASE OF DUPLICATES
                    return { color: 'black', backgroundColor: 'palegoldenrod' };
                }
            }
        };
        gridColumnDefs.push(headerObj);
    });
    return gridColumnDefs;
}

export const generateSecondGridColumns = (dataArray: any[]) => {
    const gridColumnDefs = [
        {
            headerName: '',
            field: 'stat',
            resizable: false,
            pinned: 'left',
            width: 80
        }
    ];
    dataArray.forEach((element: any) => {
        const headerObj: any = {
            headerName: element.displayName,
            field: element.name,
            width: 200,
            minWidth: 200,
            resizable: false,
            editable: false,
            isMetricVariable: element.is_matric,
            cellRenderer: 'manageOutlierEditor'
        };
        gridColumnDefs.push(headerObj);
    });
    return gridColumnDefs;
}
