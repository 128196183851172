import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { CLIENT_INFO, USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { JourneyShareComponent } from '../journey-share/journey-share.component';
import { JourneyModel } from '../journey.model';
import { CreateJourneyComponent } from '../create-journey/create-journey.component';

import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { defaultColDef, getMyJourneyColDefs } from '../ag-grid/grid-options';
import { calculateAgeByDate } from 'src/common/util/util';

export interface PeriodicElement {
  name?: string;
  action?: string;
  createdBy?: number;
  createdOn?: Date;
  journeyId?: number;
  journeyName?: string;
  originalGroupId?: string;
  originalReportId?: string;
  originalReportName?: string;
}

@Component({
  selector: 'app-my-journey',
  templateUrl: './my-journey.component.html',
  styleUrls: ['./my-journey.component.scss'],
})
export class MyJourneyComponent implements OnInit {
  displayedColumns: string[] = ['journeyId', 'name', 'createdOn', 'instance', 'tenant', 'report', 'opportunityType', 'assignedValue', 'actionableBy', 'dueBy', 'age', 'action'];
  dataSource: any = [];
  journeys: any[];
  contextLogs: any[] = [];
  calculateAge = calculateAgeByDate;

  paginationPageSize = 50;
  cacheBlockSize = 50;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: GridOptions;
  public defaultColDef = defaultColDef;
  public columnDefs = getMyJourneyColDefs({}, this);
  gridData: any = [];
  frameworkComponents = {};

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: any,
    public dialogRef: MatDialogRef<any>,
    public userService: UsersService,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public get data(): any {
    return this._data;
  }
  public set data(value: any) {
    this._data = value;
  }

  ngOnInit() {
    this.fetchMyJourneys();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  private fetchMyJourneys() {
    this.configService
      .GetJourneyByUserId(this.storage.get(USER_ID))
      .subscribe((res: any[]) => {
        console.log('My Journey', res);
        this.journeys = res;
        res.map((item) => {
          item.createdOn = `${new Date(item?.createdOn).toLocaleDateString()} - ${new Date(item?.createdOn).toLocaleTimeString()}`;
          item.actionableBy = `${new Date(item?.actionableBy).toLocaleDateString()}`;
          item.dueBy = `${new Date(item?.dueBy).toLocaleDateString()}`;
        });
        this.dataSource = [...this.journeys];
      });
  }

  editJourney(element: any, index: number) {
    const dialogRef = this.dialog.open(CreateJourneyComponent, {
      restoreFocus: false,
      width: '600px',
      data: {
        isEdit: true,
        journey: element
      }
    });
    dialogRef.afterClosed().subscribe((journey: JourneyModel) => {
      if (journey) {
        // save journey to database and get journeyId in this.journeyId
        journey.clientConfigurationId = this.storage.get(CLIENT_INFO).clientConfigurationId;
        journey.instance = this.document.baseURI;
        journey.modifyBy = this.storage.get(USER_ID);

        this.configService.updateJourney(journey).subscribe((journeyId: number) => {
          this.toastr.success(`Journey Updated`, `${journey.journeyName}`);
          this.updateEditedRow(journey, element);
        });
      }
    });
  }

  updateEditedRow(updated: any, old: any) {
    const indexOfElement = this.journeys.indexOf(old);
    if (indexOfElement) {
      this.journeys[indexOfElement] = { ...old, ...updated };
      this.dataSource = [...this.journeys];
    }
  }

  viewJourney(element: any) {
    console.log('element', element);
    if (element?.metaData) {
      const data = JSON.parse(element.metaData);
      data.isPowerBiReport = true;
      data.createdBy = element.createdBy;
      data.createdOn = element.createdOn;
      data.journeyId = element.journeyId;
      data.journeyName = element.journeyName;
      data.originalGroupId = element.originalGroupId;
      data.originalReportId = element.originalReportId;
      data.originalReportName = element.originalReportName;

      this.configService.shareJourney.emit(data);
      this.dialogRef.close();
    }
  }

  deleteJourney(element) {
    console.log('delete-element', element);
    if (confirm(`Please confirm you want to delete ${element.journeyName}?`)) {
      this.configService
        .DeleteJourney(element?.journeyId)
        .subscribe((res: any[]) => {
          if (res) {
            const indexOfElement = this.journeys.indexOf(element);
            if (indexOfElement) {
              this.journeys.splice(indexOfElement, 1);
              this.dataSource = [...this.journeys];
              this.toastr.success(
                'Journey Removed',
                `${element.journeyName} removed successfully`
              );

              this.getContextLog(element);
            }
          }
        });
    }
  }

  shareJourney(element) {
    const dialogRef = this.dialog.open(JourneyShareComponent, {
      restoreFocus: false,
      width: '500px',
      data: { journeyId: element.journeyId, createdBy: element.createdBy, sharedBy: this.storage.get(USER_ID) }
    });
  }

  getContextLog(element: any) {
    this.configService
      .GetContextLog(element.journeyId)
      .subscribe((contexts: any[]) => {
        contexts.forEach((context) => {
          this.contextLogs.push({
            name: context.displayName,
            actualName: context.actualName,
            reportId: context.reportId,
            groupId: context.groupId,
            Id: context.id,
          });
        });

        this.contextLogs.forEach((tab: any) => {
          this.configService
            .DeleteReportByGroupId(tab.groupId, tab.reportId)
            .subscribe((res: any) => {
              this.configService
                .DeleteContextLog(tab.Id)
                .subscribe((res: any) => { });
            });
        });

        this.contextLogs = [];
      });
  }
}
