export const paginationPageSize = 50;
export const cacheBlockSize = 50;
export const defaultColDef = {
  filter: false,
  sortable: false,
  width: 200,
  resizable: false,
  suppressCellSelection: true,
};
export const itemColumns = [
  {
    id: 'itemId',
    title: 'ID',
    width: 80,
    pinned: 'left'

  },
  {
    id: 'itemName',
    title: 'Item Name',
    width: 200,
    hyperLink: true
  },
  {
    id: 'purchaseTypeDescription',
    title: 'Type',
    width: 200
  }
];
export const vendorColumns = [
  {
    id: 'vendorId',
    title: 'ID',
    width: 80,
    pinned: 'left'

  },
  {
    id: 'vendorName',
    title: 'Vendor Name',
    hyperLink: true
  },
  {
    id: 'vendorEmail',
    title: 'Email',
  },
  {
    id: 'vendorPhone',
    title: 'Phone',
  },
  {
    id: 'vendorMobile',
    title: 'Mobile',
  },
  {
    id: 'vendorDiscription',
    title: 'Description',
  },
  {
    id: 'ntnNumber',
    title: 'NTN#',
  },
  {
    id: 'cnicNumber',
    title: 'CNIC#',
  },
  {
    id: 'isTaxExempted',
    title: 'Tax Exempted'
  },
  {
    id: 'reason',
    title: 'Examption Reason',
    with: 250
  }
];
export const locationColumns = [
  {
    id: 'locationId',
    title: 'ID',
    width: 80,
    pinned: 'left'

  },
  {
    id: 'locationName',
    title: 'Address',
    width: 200,
    hyperLink: true

  },
  {
    id: 'currencyCode',
    title: 'Currency Code',
    width: 200
  }
];
export const purchaseRequestColumns = [
  {
    id: 'prId',
    title: 'ID',
    width: 80,
    // pinned: 'left',
    // hyperLink: true
  },
  {
    id: 'createdBy',
    title: 'Created By',
    width: 120,
  },
  {
    id: 'prTitle',
    title: 'Title',
    hyperLink: true,
    width: 150,
  },
  {
    id: 'prDate',
    title: 'PR Date',
    width: 120,
  },
  {
    id: 'estimatePrice',
    title: 'Total Price',
    width: 120,
  },
  {
    id: 'statusDescription',
    title: 'Status',
    width: 120,
  },
  {
    id: 'documentURL',
    title: 'File',
    width: 120,
    isImage: true,
  },
];
export const bidColumns = [
  {
    id: 'prId',
    title: 'ID',
    width: 80
  },

  {
    id: 'prTitle',
    title: 'Title',
    hyperLink: true
  },
  {
    id: 'totalPrice',
    title: 'Total Price',
  },
  {
    id: 'prDate',
    title: 'PR Date',
  },
  {
    id: 'statusDescription',
    title: 'Status',
    width: 120,
  }
];
export const purchaseOrderColumns = [
  {
    id: 'poId',
    title: 'ID',
    width: 80,
  },
  {
    id: 'prTitle',
    title: 'Title',
    hyperLink: true,
    cellRenderer: 'showGRNSummaryRenderer',
  },
  {
    id: 'createdBy',
    title: 'Created By',
  },
  {
    id: 'statusDescription',
    title: 'Status',
  },
  {
    id: 'poDate',
    title: 'PR Approve Date',
  },
  // {
  //   id: 'details',
  //   title: 'Details',
  // }
];
export const GRNAwaitingColumns = [
  {
    id: 'poId',
    title: 'PO Id',
  },
  {
    id: 'prTitle',
    title: 'Title',
    hyperLink: true,
    cellRenderer: 'showGRNSummaryRenderer',
  },
  {
    id: 'vendorName',
    title: 'Vendor Name',
  },

  {
    id: 'locationName',
    title: 'Location Name',
  },
  {
    id: 'grnStatusDescription',
    title: 'Status',
    width: 120,
  },
];
export const GRNcloumns = [
  {
    id: 'poId',
    title: 'PO Id',
  },
  {
    id: 'itemId',
    title: 'Item Id',
  },
  {
    id: 'goodsRecevingNoteId',
    title: 'Goods Receving Note Id',
    width: 220,
  },
  {
    id: 'itemReceivingDate',
    title: 'Item Receiving Date',
    width: 220,
  },
];

export const generateColumnDefsWithRowSelection = (items: Array<any>) => {
  let gridCols = [
    {
      headerName: '',
      field: '',
      checkboxSelection: true,
      headerCheckboxSelection: false,
      resizable: false,
      width: 80,
      pinned: 'left',
    },
  ];
  return gridCols.concat(generateColumnDefs(items));
};

export const generateColumnDefs = (items: Array<any>) => {
  const columns = [];

  items.forEach((item) => {
    const obj = {
      headerName: item.title,
      field: item.id,
      resizable: true,
      width: item?.width || 150,
      pinned: item?.pinned || false,
      hyperLink: item?.hyperLink || false,
      isImage: item?.isImage || false,

      cellRenderer: item.cellRenderer
        ? item.cellRenderer
        : (params) => {
          if(params.colDef.hyperLink){
            return '<u><b><a style="color: #70077e;">'+ params.value+'</a></b></u>';
          }
          else  if (params.colDef.isImage) {
              return `<a href=${params.value} target="_blank" > <img  style="height:40px;width:100px; object-fit:contain" src=${params.value} /> </a>`;
            } else {
              return params.value;
            }
          },
    };
    columns.push(obj);
  });
  return columns;
};
