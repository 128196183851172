<div class="container-fluid">

  <form [formGroup]="filtersForm" class="row product-grid-filter-row mt-10 mx-2">
    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <input formControlName="prTitle" matInput type="search" placeholder="Title">
        <mat-icon class="search-action-icon" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>

    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <mat-select formControlName="statusDescription" placeholder="Select statuses">
          <mat-option *ngFor="let status of statuses" (click)="selectionStatusChange(allS)"
            [value]="status.statusDescription">{{status.statusDescription}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-clear-btn">
      <button (click)="clearFilter()" mat-flat-button color="accent">Clear</button>
    </div>
  </form>

  <div class="grid-action-btn-bar">
    <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>

    <button [disabled]="isRowSelected" class="import-export-button" mat-flat-button color="accent"
      *ngIf="cClaim.PMSClaims.U_TYPE1 || cClaim.PMSClaims.U_TYPE3" [matMenuTriggerFor]="actionMenu">Actions</button>
    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="updatePurchaseRequestStatus('approved')">Approve</button>
      <button mat-menu-item (click)="updatePurchaseRequestStatus('rejected')">Reject</button>
    </mat-menu>

    <button *ngIf="cClaim.PMSClaims.U_TYPE2 || cClaim.PMSClaims.U_TYPE4" class="add-icon-btn ml-10"
      (click)="addEditPurchaseRequest()" matTooltip="Add Purchase Request" mat-icon-button color="primary"
      type="button">
      <i class="material-icons">
        add_circleg
      </i>
    </button>
  </div>

  <div class="all-show-grid mt-2">
    <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
      toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
      [columnDefs]="gridColumnDefs" [rowData]="dataSource" (cellClicked)="cellClicked($event)" [pagination]="true"
      [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize" (selectionChanged)="rowSelectionChanged($event)"
      (gridReady)="onGridReady($event)"  #prGrid>
    </ag-grid-angular>
  </div>


</div>
