import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { generateTitleDiv, NO_IMAGE, undefinedImagePath } from 'src/modules/empower-module/helper';

@Component({
  selector: 'empower-pca-rev-opper-vs-market',
  templateUrl: './pca-rev-opper-vs-market.component.html',
  styleUrls: ['./pca-rev-opper-vs-market.component.scss']
})
export class EmpowerPcaRevOppervsMarketComponent implements OnInit {
  @Input() data?: any;
  _unsubscribeAll = new Subject();
  undefinedImagePath = '';

  totalRevPartner: number;
  totalRevMarket: number;
  yearlyIncrease: number;
  xaxisCatgoryRevPartnerList: any = [];
  dataSeriesRevPartner: any = [];
  xaxisCatgoryRevMarketList: any = [];
  dataSeriesRevMarket: any = [];
  xaxisCatgoryDiviPartnerList: any = [];
  dataSeriesDiviPartner: any = [];
  xaxisCatgoryDiviMarketList: any = [];
  dataSeriesDiviMarket: any = [];
  innerHtml = '';
  pageComment: string;

  constructor(
    public empowerModernLifeService: EmpowerModernLifeService, private empowerExportService: EmpowerExportService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);

      this.innerHtml = generateTitleDiv(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const daxReponse = this.empowerModernLifeService.getPagesState(pageId);
    this.pageComment = this.empowerModernLifeService.getPageCommentState(pageId);
    if (daxReponse && daxReponse.length) {
      this.loadVisuals(daxReponse);
    } else {
      this.executePageDaxQuery();
    }
  }

  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.empowerModernLifeService.getDashboardState()
    };
    this.spinner.show();
    this.empowerModernLifeService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        this.emitExportEvent(true);
      }
      this.spinner.hide();
    },
      err => {
        this.undefinedImagePath = undefinedImagePath;
        this.spinner.hide();
      });
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(40, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

  setPageState(pageId, daxData) {
    this.empowerModernLifeService.setPagesState(pageId, daxData);
  }

  loadVisuals(daxData) {
    this.loadFirstVisual(daxData[0]);
    this.loadSecondVisual(daxData[2]);
    this.loadThirdVisual(daxData[1]);
    this.loadForthVisual(daxData[3]);
    this.loadFifthVisual(daxData[4]);
    this.loadSixthVisual(daxData[5]);
    this.loadSeventhVisual(daxData[6]);
    this.emitExportEvent(true);
  }

  loadFirstVisual(visualData) {
    this.totalRevPartner = _.get(visualData.daxResponse, ['0', '[Total_Rev__]'], 0);
  }

  loadSecondVisual(visualData) {
    this.yearlyIncrease = _.get(visualData.daxResponse, ['0', '[Total Yearly Revenue Opportunity if you close the gap with Market Growth]'], 0);
  }

  loadThirdVisual(visualData) {
    this.totalRevMarket = _.get(visualData.daxResponse, ['0', '[Market Growth %]'], 0);
  }

  loadForthVisual(visualData) {
    const dataList = _.orderBy(visualData.daxResponse, ['dIM_DATE[FISCAL_MONTH_NUMBER]'], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a['dIM_DATE[FISCAL_MONTH_NAME]'] } });
    const totalRevSeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue]'] } });
    const totalRevLYSeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue_LY]'] } });
    this.xaxisCatgoryRevPartnerList = categoryList;
    this.dataSeriesRevPartner = [
      { seriesname: 'Total Revenue', color: "#0b14a1", data: totalRevSeriesData },
      { seriesname: 'Total Revenue LY',  data: totalRevLYSeriesData },
    ];
  }

  loadFifthVisual(visualData) {
    const dataList = _.orderBy(visualData.daxResponse, ['dIM_DATE[FISCAL_MONTH_NUMBER]'], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a['dIM_DATE[FISCAL_MONTH_NAME]'] } });
    const totalRevSeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue___Comp]'] } });
    const totalRevLYSeriesData = _.map(dataList, a => { return { value: a['[Total_Revenue_LY___Comp]'] } });
    this.xaxisCatgoryRevMarketList = categoryList;
    this.dataSeriesRevMarket = [
      { seriesname: 'Total Revenue', color: "#0b14a1", data: totalRevSeriesData },
      { seriesname: 'Total Revenue LY', data: totalRevLYSeriesData },
    ];
  }

  loadSixthVisual(visualData) {
    const series: any = [];
    this.xaxisCatgoryDiviPartnerList = [{ label: '' }];
    visualData.daxResponse.forEach(el => {
      series.push({
        seriesname: el['dIM_PRODUCT_O2[REPORTING_PRODUCT_DIVISION]'],
        data: [{ value: _.get(el, ['[STLicsQty]'], 0) }]
      });
    });
    this.dataSeriesDiviPartner = series;
  }

  loadSeventhVisual(visualData) {
    const series: any = [];
    this.xaxisCatgoryDiviMarketList = [{ label: '' }];
    visualData.daxResponse.forEach(el => {
      series.push({
        seriesname: el['dIM_PRODUCT_O2[REPORTING_PRODUCT_DIVISION]'],
        data: [{ value: _.get(el, ['[STLicsQty___Comp]'], 0) }]
      });
    });
    this.dataSeriesDiviMarket = series;
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.empowerModernLifeService.setPageCommentState(pageId, comment);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
