<div class="card-item">
    <div class="labels">
        <div *ngIf="cardData.label" [ngClass]="newWeekData && cardData.label.color">
            <small>{{cardData.label.title}}</small>
        </div>
        <div (click)="changeLock()" *ngIf="!newWeekData">
            <mat-icon *ngIf="cardData.lock_tile" class="purple-clr">lock</mat-icon>
            <mat-icon *ngIf="!cardData.lock_tile" class="black-clr">lock_open</mat-icon>
        </div>
    </div>
    <label>{{cardData.title}}</label>
    <div class="fosner-stats">
        <p>{{cardData.weeks_old}}</p>
        <p>{{cardData.tw_forecast}}</p>
    </div>
</div>