<div class="mt-3">
  <div class="row m-0 mt-3">
    <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
    <ng-container *ngIf="!undefinedImagePath">
      <h4 class="mx-2 w-100"><strong>How is the partner performing vs. the market?</strong></h4>
      <div class="col-3 mb-3 empower-chart-section">
        <p class="font-18">Partner: | {{ empowerModernLifeService.getDashboardPartner() }}</p>
        <h4>Total Rev % - Partner</h4>
        <fusion-gage-chart [dialValue]="totalRevPartner" height="150"></fusion-gage-chart>
      </div>
      <div class="col-6 empower-chart-section m-auto">
        <div class="number-sectoion text-center">
          <h3><strong>{{ yearlyIncrease | currency }}</strong></h3>
          <p><small>Total Yearly Revenue Oppertunity if you close the gap with Market Growth</small></p>
        </div>
      </div>
      <div class="col-3 mb-3 empower-chart-section">
        <p class="font-18">Market: | {{ empowerModernLifeService.getDashboardLocation() }}</p>
        <h4>Total Rev % - Market</h4>
        <fusion-gage-chart [dialValue]="totalRevMarket" height="150"></fusion-gage-chart>
      </div>
      
      <div class="col-6 mb-3 empower-chart-section">
        <h4>TY vs. LY Total Revenue - Partner</h4>
        <fusion-ms-line-area-chart [xaxisCatgoryList]="xaxisCatgoryRevPartnerList" [dataSeries]="dataSeriesRevPartner"
          height="250" xAxisName="Month" numberprefix="$">
        </fusion-ms-line-area-chart>
      </div>
      <div class="col-6 mb-3 empower-chart-section">
        <h4>TY vs LY Revenue - Market</h4>
        <fusion-ms-line-area-chart [xaxisCatgoryList]="xaxisCatgoryRevMarketList" [dataSeries]="dataSeriesRevMarket"
          height="250" xAxisName="Month" numberprefix="$">
        </fusion-ms-line-area-chart>
      </div>

      <div class="col-6 mb-3 empower-chart-section">
        <h4>ST Qty by Product Division - Partner</h4>
        <fusion-percentage-horizental-bar-chart [xaxisCatgoryList]="xaxisCatgoryDiviPartnerList"
          [dataSeries]="dataSeriesDiviPartner" height="150">
        </fusion-percentage-horizental-bar-chart>
      </div>
      <div class="col-6 mb-3 empower-chart-section">
        <h4>ST Qty by Product Division - Market</h4>
        <fusion-percentage-horizental-bar-chart [xaxisCatgoryList]="xaxisCatgoryDiviMarketList"
          [dataSeries]="dataSeriesDiviMarket" height="150">
        </fusion-percentage-horizental-bar-chart>
      </div>
    </ng-container>
        <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
      <app-image-upload-viewer [imageUrl]="undefinedImagePath"
          orientation="square" (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12">
      <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
    </div>
    <div class="col-12">
      <app-report-page-footer></app-report-page-footer>
    </div>
  </div>
</div>