import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProcurementService } from 'src/services/procurement.service';

@Component({
  selector: 'app-pms-add-edit-vendor',
  templateUrl: './pms-add-edit-vendor.component.html',
  styleUrls: ['./pms-add-edit-vendor.component.scss']
})
export class PmsAddEditVendorComponent implements OnInit {

  addEditForm: FormGroup;
  editMode = false;

  constructor( public dialogRef: MatDialogRef<PmsAddEditVendorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private procurementService: ProcurementService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.initializeForm();
    if(this.data.id) {
      this.editMode = true;
      this.fetchDataById(this.data.id);
    }
  }

  fetchDataById(id) {
    this.spinner.show();
    this.procurementService.getVendorById(id)
    .subscribe((res: any) => {
      this.addEditForm.patchValue(res);
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    });
  }

  initializeForm() {
    this.addEditForm = this.formBuilder.group({
      vendorId: 0,
      vendorName: [null, Validators.required],
      vendorAddress: [null, Validators.required],
      vendorEmail: [null, [Validators.required, Validators.email]],
      vendorPhone: [null, Validators.required],
      vendorMobile: [null, Validators.required],
      vendorDiscription: [null, Validators.required],
      ntnNumber: [null, Validators.required],
      cnicNumber: [null, Validators.required],
      isTaxExempted: [false],
      reason: [null, Validators.required]
    });
  }

  onSaveClick() {
    this.spinner.show();
    if (this.editMode) {
      this.procurementService.updateVendor(this.addEditForm.value)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.toastrService.success('Vendor updated successfully.');
        this.dialogRef.close(true);
      },
      err => {
        this.toastrService.error('Failed to update the vendor.');
        this.spinner.hide();
      });
    } else {
      this.procurementService.addVendor(this.addEditForm.value)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.toastrService.success('Vendor added successfully.');
        this.dialogRef.close(true);
      },
      err => {
        this.toastrService.error('Failed to add vendor.');
        this.spinner.hide();
      });
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
