import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CLIENT_INFO, USER_ID } from 'src/common/keys';
import { UsersModel } from 'src/models/UsersModals';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { CollaborationVM, JourneyCollaborations } from '../collaboration.model';

export interface UserModel {
  email: string;
  userId?: number;
}

@Component({
  selector: 'app-journey-share',
  templateUrl: './journey-share.component.html',
  styleUrls: ['./journey-share.component.scss'],
})
export class JourneyShareComponent implements OnInit {
  public userList = [];
  username: FormControl = new FormControl();

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<any[]>;
  fruits: { userId: number; email: string }[] = [];
  allFruits: { userId: number; email: string }[] = [];

  displayedColumns = ['email', 'action'];
  dataSource = []; //this.ELEMENT_DATA;

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  isTableVisible: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { journeyId: number; sharedBy: number, createdBy: number },
    public userService: UsersService,
    private configService: ConfigurationService,
    public toastr: NgxToasterService,
    public storage: LocalstorageService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allFruits.slice()
      )
    );
  }

  ngOnInit() {
    this.getUserList();
  }

  public getUserList = () => {
    const params = {
      active: true,
    };
    this.userService.GetAllUsers({ userTypeId: 1 }).subscribe((res) => {
      this.userList = (res as any).data;
      console.log('userList:', this.userList);
      this.allFruits = this.userList;
      this.getCollaborations();
    });
    // make a call to get already shared people list and bind to this.datasource
  };

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      const availableItem = this.allFruits.find((item) => {
        item.email.toLowerCase() === value?.toLowerCase();
      });
      this.fruits.push(availableItem);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: { userId: number; email: string }): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const foundElement = this.fruits.find((item) => {
      return item?.email?.trim() === event?.option?.value?.email;
    });

    if (!foundElement) {
      this.fruits.push(event.option.value);
    }

    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(
    value: string | { email: string; userId: number }
  ): { email: string; userId: number }[] {
    const filterValue =
      typeof value === 'string'
        ? value.toLowerCase()
        : value.email.toLocaleLowerCase();

    return this.allFruits.filter(
      (fruit) => fruit.email.toLowerCase().indexOf(filterValue) === 0
    );
  }

  save() {
    const collaboration: CollaborationVM = new CollaborationVM();
    this.fruits.forEach((item: any) => {
      const _item: any = {};
      _item.email = item.email;
      _item.userId = item.userId;

      let errorMsg = 'Provided email already exists.';

      let existingItem = this.dataSource.find((_item) => {
        return _item.email.toLowerCase() === item?.email?.toLowerCase();
      });

      if(item.userId === this.storage.get(USER_ID)) {
        existingItem = true;
        errorMsg = 'Owner is already part of collaboration.';
      }

      if (!existingItem) {
        this.dataSource.push(_item);
        const shareVM: JourneyCollaborations = {
          JourneyId: this.data.journeyId,
          SharedBy: this.data.sharedBy,
          SharedWith: _item.userId,
          CreatedAt: new Date(),
          ClientConfigurationId: this.storage.get(CLIENT_INFO).clientConfigurationId,
          Instance: this.document.baseURI
        };
        collaboration.journeyCollaborations.push(shareVM);
      } else {
        alert(errorMsg);
      }
    });

    this.dataSource = [...this.dataSource];
    if(collaboration?.journeyCollaborations?.length > 0){
      this.configService
        .createJourneyCollaborations(collaboration)
        .subscribe((res) => {
          console.log(res);
          this.fruits = [];
          this.fruitInput.nativeElement.value = '';
          this.fruitCtrl.setValue(null);
        });
    }
  }

  delete(element: any) {
    if (confirm(`Please confirm, you want to remove ${element.email}?`)) {
      this.configService
        .DeleteJourneyCollaboration(this.data.journeyId, element.userId)
        .subscribe((res: any) => {
          const indexOfItem = this.dataSource.indexOf(element);
          this.dataSource.splice(indexOfItem, 1);
          this.dataSource = [...this.dataSource];
          this.toastr.success('Removed', 'Access Revoked Successfully.');
        });
    }
  }

  getCollaborations() {
    this.configService
      .getJourneyCollaboration(null, null, this.data.journeyId)
      .subscribe((users) => {
        console.log('users:', users);

        users.forEach((element) => {
          const existingItem = this.allFruits.find((_item) => {
            return _item.userId === element.sharedWith;
          });
          if (existingItem) {
            const _item: any = {};
            _item.email = existingItem.email;
            _item.userId = existingItem.userId;
            this.dataSource.push(_item);
            this.dataSource = [...this.dataSource];
          }
        });
      });
  }

  isOwner() {
    return this.storage.get(USER_ID) === this.data.createdBy;
  }
}
