import { Component, OnInit, OnDestroy } from '@angular/core';
import {  GridApi, ColumnApi, ColumnState } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import {
    promotionPlannerExportGridColDef,
    promotionPlannerColDefs,
    paginationPageSize,
    cacheBlockSize,
    rowSelection,
    defaultColDef
} from './ag-grid/gridOptions';
import { gridDataExportParams, downloadFromLink, overlayLoadingTemplate } from '../util/util';
import { PromotionPlannerDetailComponent } from './components/promotion-planner-detail/promotion-planner-detail.component';
import { PromotionPlannerEditRenderer } from './ag-grid/promotion-planner-edit-renderer.component';
import { INVALID_TEAM, PROMOTION_PLANNER_LIST, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ConfigurationService } from 'src/services/configuration.service';
import { ItemManagerService, PromotionPlannerService } from 'src/services/Item-Management-Services';
import { UsersService } from 'src/services/User-services/user-services';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';


@Component({
    selector: 'app-promotion-planner',
    templateUrl: './promotion-planner.component.html',
    styleUrls: ['./promotion-planner.component.css']
})
export class PromotionPlannerComponent implements OnInit, OnDestroy {

    INVALID_TEAM = INVALID_TEAM;

    paginationPageSize = paginationPageSize;
    cacheBlockSize = cacheBlockSize;
    rowSelection = rowSelection;
    public gridColumnDefs = promotionPlannerColDefs();
    public defaultColDef = defaultColDef;
    public gridApi: GridApi;
    public colApi: ColumnApi;
    public gridOptions: any;
    public gridColumnState: ColumnState[] = [];
    gridData: any = [];
    frameworkComponents = {
        promotionPlannerEditRenderer: PromotionPlannerEditRenderer
    };

    isExportingTemplate: boolean = false;
    exportGridColDef = promotionPlannerExportGridColDef();
    exportGridApi: GridApi;
    exportColApi: ColumnApi;
    exportGridData: any = [];

    public actionSubscription: Subscription;
    public actionListSubscription: Subscription;

    userTeam: any = '';
    isRetailerTeam: boolean = false;

    actionsList: any = [];
    screenPreference: any;
    filterAppliedSubscription: Subscription;

    overlayLoadingTemplate = overlayLoadingTemplate;

    constructor(
        public dialog: MatDialog,
        public toastr: NgxToasterService,
        public configurationService: ConfigurationService,
        private promotionPlannerService: PromotionPlannerService,
        private itemManagerService: ItemManagerService,
        private usersService: UsersService,
        private userPreferenceService: UserPreferenceService
    ) { }

    ngOnInit() {
        this.getUserSecreenPrefereence();
        this.actionSubscription = this.promotionPlannerService.getPromotionPlannerGridActionCellSubject().subscribe(res => {
            if (res) {
                this.performGridAction(res);
            }
        });
        this.getFormContorlsData();
        this.setRoleBaseAccess();
        this.updatePromotionPlannerList();
        this.initiateValues();

    }

    getUserSecreenPrefereence() {
        this.screenPreference = this.userPreferenceService.getPreferenceByKeys(PROMOTION_PLANNER_LIST, PROMOTION_PLANNER_LIST);
        this.gridColumnState = this.screenPreference.columnState ? JSON.parse(this.screenPreference.columnState) : [];
    }

    initiateValues() {
        this.actionListSubscription = this.itemManagerService.getActionsListSubject().subscribe((actionsList: any) => {
            if (actionsList && actionsList.length) {
                this.actionsList = actionsList;
            }
        });
    }

    updatePromotionPlannerList() {
        this.promotionPlannerService.getPromotionPlannerUpsertSubject()
            .subscribe(promotionItem => {
                if (promotionItem) {
                    const index = _.findIndex(this.gridData, a => a.promotionItemPlannerId === promotionItem.promotionItemPlannerId);
                    if (index < 0) {
                        this.gridData.unshift(promotionItem);
                    } else {
                        this.gridData[index] = {
                            ...this.gridData[index],
                            ...promotionItem
                        };
                    }
                    this.gridApi.setRowData(this.gridData);
                }
            });
    }

    getFormContorlsData() {
        this.itemManagerService.GetActionsList().toPromise();
        this.itemManagerService.GetFiscalYearList().toPromise();
        this.itemManagerService.GetPlanningMonthList().toPromise();
        this.itemManagerService.GetPlanningWeekList().toPromise();
        this.itemManagerService.GetAllAccounts().toPromise();
    }

    setRoleBaseAccess() {
        this.userTeam = this.usersService.checkLoginUserTeam();
        switch (this.userTeam) {
            case VENDOR_TEAM_CODE: {
                this.isRetailerTeam = false;
                break;
            }
            case RETAILER_TEAM_CODE: {
                this.isRetailerTeam = true;
                break;
            }
            case INVALID_TEAM: {
                console.log('Valid Team Not assigned');
                break;
            }
        }
    }

    applyFilters(event) {
        if (this.filterAppliedSubscription) this.filterAppliedSubscription.unsubscribe();
        this.gridApi.showLoadingOverlay();
        this.filterAppliedSubscription = this.promotionPlannerService.FilterPromotionPlannerItemList(event).subscribe((res: any) => {
            this.gridData = res;
            this.gridApi.hideOverlay();
        });
    }

    performGridAction(actionObject) {
        switch (actionObject.action) {
            case 'edit': {
                const data = { isEdit: true, formData: actionObject.actionData };
                this.openPromotionDetail(data);
                break;
            }
            case 'delete': {
                break;
            }
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.colApi = params.columnApi;
        this.gridApi.showLoadingOverlay();
        this.applyColumnState();
    }

    applyColumnState() {
        if (this.colApi && this.gridColumnState && this.gridColumnState.length) {
            setTimeout(() => {
                this.colApi.applyColumnState({ state: this.gridColumnState });
            }, 100);
        }
    }

    onGridStateChange(e) {
        this.gridColumnState = this.colApi.getColumnState();
        this.screenPreference.columnState = this.gridColumnState;
        this.updateScreenPreference();
    }

    updateScreenPreference() {
        this.userPreferenceService.upsertColumnStatePreference(this.screenPreference).subscribe(res => {
            console.log(res);
        });
    }

    exportGridReady(params) {
        this.exportGridApi = params.api;
        this.exportColApi = params.columnApi;
    }

    openColumnDialog() {
        this.dialog.open(AGColumnDialogComponent, {
            data: {
                colApi: this.colApi
            },
            width: '700px'
        });
    }

    onExportGridData() {
        const csvExportParams = gridDataExportParams({
            fileName: 'PromotionPlanner',
            colDefs: this.gridColumnDefs,
            allColumns: true
        });
        this.gridApi.exportDataAsCsv(csvExportParams);
    }

    exportDataTemplate(isGeneralView) {
        this.exportGridColDef = promotionPlannerExportGridColDef(isGeneralView);
        this.exportGridApi && this.exportGridApi.setColumnDefs(this.exportGridColDef);
        const promotionItemPlannerIds = _.join(_.map(this.gridData, a => a.promotionItemPlannerId), ',');
        if (promotionItemPlannerIds.length > 1500) {
            this.toastr.warning('Warning', 'Too many promotions specified, please filter the promotion data.');
        } else {
            const actionIds = _.join(_.map(_.filter(this.actionsList, a => a.actionName !== 'Deleted'), b => b.actionId), ',');
            this.promotionPlannerService.FilterPromotionPlannerDetailItemList({
                PromotionItemPlannerIds: promotionItemPlannerIds,
                ActionIds: actionIds
            }).subscribe((res: any) => {
                this.exportGridData = _.orderBy(res, ['promotionItemPlannerId'], ['asc']);
                this.isExportingTemplate = true;
                setTimeout(() => {
                    this.exportGridDataTemplate();
                }, 200);
            });
        }

    }

    exportGridDataTemplate() {
        const csvExportParams = gridDataExportParams({
            fileName: 'PromotionPlannerExportTemplate',
            colDefs: this.exportGridColDef,
            allColumns: true
        });
        this.exportGridApi.exportDataAsCsv(csvExportParams);
    }

    importGeneralDataByTemplate() {
        (document.getElementById('upload-general-promotion-planner-data') as any).value = '';
        document.getElementById('upload-general-promotion-planner-data').click();
    }

    importMerchantDataByTemplate() {
        (document.getElementById('upload-merchant-promotion-planner-data') as any).value = '';
        document.getElementById('upload-merchant-promotion-planner-data').click();
    }

    validateUploadedCSV(fileInput: any) {
        const reader = new FileReader();
        if (fileInput.target.files && fileInput.target.files.length) {
            const [file] = fileInput.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
                const importDataFile = reader.result as any;
                /*
                  Files Validations Will go here
                */
                this.uploadImportedCsvFile(importDataFile);
            }
        }
    }

    uploadImportedCsvFile(importDataFile) {
        this.promotionPlannerService.ImportPromotionPlannerTemplate({
            fileBase64: importDataFile
        }).subscribe(
            (res: any) => {
                if (Array.isArray(res)) {
                    const successMessages = [];
                    const errorMessages = [];
                    const warningMessages = [];
                    const infoMessages = [];
                    res.forEach(element => {

                        switch (element.messageType) {
                            case 'Success':
                                successMessages.push(element.messageText);
                                break;
                            case 'Warning':
                                warningMessages.push(element.messageText);
                                break;
                            case 'Info':
                                infoMessages.push(element.messageText);
                                break;
                            case 'Error':
                                errorMessages.push(element.messageText);
                                break;
                            default:
                                break;
                        }
                    });

                    if (errorMessages.length)
                        this.toastr.error('Error', `${errorMessages.map(a => a).join('<br />')}`);
                    if (successMessages.length)
                        this.toastr.success('Success', `${successMessages.map(a => a).join('<br />')}`);
                    if (warningMessages.length)
                        this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
                    if (infoMessages.length)
                        this.toastr.info('Info', `${infoMessages.map(a => a).join('<br />')}`);
                } else {
                    this.toastr.success('Success', 'Promotion Data Imported Successfully.');
                }
            },
            (err: any) => {
                this.toastr.error('Error', 'Promotion Data Import Failed.');
            });
    }

    addNewPromotion() {
        const data = {
            isEdit: false
        };
        this.openPromotionDetail(data);
    }

    openPromotionDetail(data) {
        const promotionDetailComponent = {
            menuId: '399',
            name: 'Promotion Planner',
            selector: 'app-promotion-planner-detail',
            displayName: 'Promotion Planner',
            module: 'ItemManagerModule',
            data: data,
            component: PromotionPlannerDetailComponent
        }
        this.configurationService.menuRendererSubject.next(promotionDetailComponent);
    }

    ngOnDestroy() {
        this.actionSubscription.unsubscribe();
        this.actionListSubscription.unsubscribe();
    }

}
