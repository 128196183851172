import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  bidColumns,
  cacheBlockSize,
  defaultColDef,
  generateColumnDefsWithRowSelection,
  paginationPageSize,
} from '../ag-grid/gridOptions';

import { NgxSpinnerService } from 'ngx-spinner';
import { ProcurementService } from 'src/services/procurement.service';
import { PmsAddEditBidComponent } from './pms-add-edit-bid/pms-add-edit-bid.component';
import { Subscription } from 'rxjs';
import { tooltipcellRenderer } from 'src/common/util/util';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PMSPermissionAccessService } from 'src/services/pms-permission-access.service';
import { CClaim } from 'src/common/claims';
@Component({
  selector: 'pms-bids',
  templateUrl: './pms-bids.component.html',
  styleUrls: ['./pms-bids.component.scss'],
})
export class PmsBidsComponent implements OnInit {
  columns = bidColumns;
  // Grid Variables
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  selectedStatuse = 'PendingBid';
  cClaim = CClaim;
  filtersForm: FormGroup;
  defaultColDef = defaultColDef;
  public gridColumnDefs = generateColumnDefsWithRowSelection(this.columns);

  public gridOptions: any;
  private gridApi;
  dataSource = [];
  rowSelection = 'single';
  public actionSubscription: Subscription;
  selectedRows: any = [];
  frameworkComponents = {};
  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public access: PMSPermissionAccessService,
    public toastr: NgxToasterService,
    private procurementService: ProcurementService,
    private formBuild: FormBuilder
  ) {}
  ngOnInit(): void {
    this.initializeFilterForm();
    this.fetchData();
  }
  initializeFilterForm() {
    this.filtersForm = this.formBuild.group({
      prTitle: [''],
      statusDescription: ['PendingBid'],
    });
    this.filtersForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((res: any) => {
        this.fetchData();
      });
  }
  fetchData() {
    this.spinner.show();
    this.dataSource = [];
    this.procurementService
      .getPurchaseRequestsApproveList(
        this.filtersForm.get('prTitle').value,
        this.filtersForm.get('statusDescription').value
      )
      .subscribe(
        (res: any) => {
          this.dataSource = res;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  addEditItem(value = null, viewDetails = false) {
    const confirmationDialogRef = this.dialog.open(PmsAddEditBidComponent, {
      maxWidth: '1100px',
      width: '95vw',
      data: {
        value: value ? value.data.prId : this.selectedRows[0].prId,
        viewDetails: viewDetails,
      },
    });
    confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchData();
      }
    });
  }
  onStatusSelection() {
    this.fetchData();
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  rowSelectionChanged(params) {
    this.selectedRows = params.api.getSelectedRows();
  }
  clearFilter() {
    this.initializeFilterForm();
    this.fetchData();
  }
  cellClicked(event) {
    let viewDetails = false;
    if (
      event &&
      event.value &&
      event.colDef &&
      event.colDef.field &&
      event.colDef.field == 'prTitle'
    ) {
      const status = this.gridApi.getSelectedRows()[0].statusDescription;
      if (status === 'Bids Called') viewDetails = true;
      this.addEditItem(event, viewDetails);
    }
  }
  get isRowSelected() {
    if (
      this.gridApi &&
      this.gridApi.getSelectedRows() &&
      this.gridApi.getSelectedRows().length > 0
    )
      return false;
    else return true;
  }
}
