import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { USER_INFO } from 'src/common/keys';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

interface IClusterCol {id: string, clusterName: string, data: any[], page: 1, total: number, search: '', hiddenItems: number};

@Component({
  selector: 'cluster-column',
  templateUrl: './cluster-column.component.html',
  styleUrls: ['./cluster-column.component.scss']
})

export class ClusterColumnComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() isOutputTab = false;
  @Input() fileName = '';
  @Input() runIndex = 'RUN_0';
  @Input() objectToCluster = '';
  @Input() clusteringLabels: [];
  @Input() isAdjusted?: boolean = false;
  @Input() versionNo: any;
  @Output() adjustmentSaved = new EventEmitter();
  @Output() disableSaveRun: EventEmitter<boolean> = new EventEmitter();

  metrices = [];

  searchSubject = new Subject();

  editableFields = [];
  reorderedData: any;

  draggedObj = {
    rowIndex: null,
    colIndex: null
  };

  userObj = {} as any;

  clusteringDataSource: Array<IClusterCol>;

  dragDropObj = {
    row_ids: [],
    cluster_ids: [],
    clusteringLabels: []
  };


  nameChanged = false;

  constructor(private spinner: NgxSpinnerService, private toastrService: ToastrService, private clusteringService: ClusteringForecastService, private storage: LocalstorageService) { }

  ngOnInit(): void {
    this.userObj = this.storage.get(USER_INFO);
    this.clusteringDataSource = [];

    // search filter
    this.searchSubject
    .pipe(
      debounceTime(300),
      distinctUntilChanged()
      )
    .subscribe((res: {col, value}) => {
      this.spinner.show();
      this.fetchClusterData(this.clusteringDataSource[res.col].id, res.col, 1, this.clusteringDataSource[res.col].search, this.clusteringDataSource[res.col].clusterName).finally(() => {
        this.spinner.hide();
      });
    });
  }

  ngAfterViewInit() {
    this.clusteringLabels && this.patchData();
    this.isOutputTab && this.addSummaryStatsListener();
  }

  addSummaryStatsListener() {
    this.clusteringService.summaryStatsChangeSubject
    .subscribe((res: Array<any>) => {
      this.metrices = res;
    });
  }

  ngOnChanges() {
  }

  patchData(clusteringLabels = this.clusteringLabels) {
    if (!clusteringLabels) return;
    this.spinner.show();
    this.clusteringDataSource = [];
    const promisesArray: Promise<any>[] = [];
    clusteringLabels.forEach((item: any, index) => {
      this.editableFields[item.label_id] = false;
      promisesArray.push(this.fetchClusterData(item.label_id, index, 1, '', item.label_name));
    });
    Promise.all(promisesArray)
    .finally(() => {
      this.spinner.hide();
    });
  }

  fetchClusterData(clusterId, index, page, search, clusterName): Promise<any> {
    return new Promise((resolve, reject) => {
      const obj = {
        file_name: this.fileName,
        user_id: this.userObj.userId,
        cluster_id: clusterId,
        run_tab_index: this.runIndex == 'RUN_0' ? "Cluster_Id_adj" : this.runIndex,
        search_field: this.objectToCluster,
        search_value: search,
        version_no: this.versionNo,
        page_no: page
      };
      this.clusteringService.fetchClusterColumnData(obj)
      .subscribe((res: any) => {
        if (page == 1 && this.clusteringDataSource[index] && this.clusteringDataSource[index].data) {
          const filteredData = this.clusteringDataSource[index].data.filter((item) => item.dragged);
          this.clusteringDataSource[index].data = [];
          let hiddenItems = 0;
          filteredData.forEach((e) => {
            if (this.showHideAdjustedData(e[this.objectToCluster], true, search)) e.hideItem = false;
            else {
              e.hideItem = true;
              hiddenItems++;
            }
            this.clusteringDataSource[index].data.push(e);
          });
          this.clusteringDataSource[index].hiddenItems = hiddenItems;
        }
        if (page == 1 && !this.clusteringDataSource[index]) {
          this.clusteringDataSource[index] = {
            id: clusterId,
            data: res.payload.results,
            page: page,
            total: res.payload.total_count,
            search: search,
            clusterName: clusterName,
            hiddenItems: 0
          };
        } else {
          this.clusteringDataSource[index].page = page;
          this.clusteringDataSource[index].total = res.payload.total_count;
          res.payload.results.forEach((element: any) => {
            if (this.dragDropObj.row_ids.indexOf(element[this.objectToCluster]) == -1)
              this.clusteringDataSource[index].data.push(element);
          });
        }

        resolve(true);
      },
      err => {
        reject();
      });
    });
  }

  onDragStart(rowIndex, colIndex) {
    this.draggedObj.rowIndex = rowIndex;
    this.draggedObj.colIndex = colIndex;
  }
  
  onDragOver(event) {
    event.preventDefault();
  }

  onDrop(rowIndex, colIndex, event) {
    if (this.runIndex != 'RUN_0' || this.draggedObj.colIndex == null || this.draggedObj.rowIndex == null) {
      this.onDragEnd(event);
      return;
    }
    if (colIndex == this.draggedObj.colIndex) {
      this.onDragEnd(event);
      return;
    } // in case item is moved within the same cluster
    // FIND DRAGGED ELEMENT, REMOVE IT FROM ARRAY AND PUSH IT AT DROPPED INDEX
    const draggedElement = this.clusteringDataSource[this.draggedObj.colIndex].data[this.draggedObj.rowIndex];
    draggedElement.dragged = true;
    this.clusteringDataSource[this.draggedObj.colIndex].data.splice(this.draggedObj.rowIndex, 1);
    this.clusteringDataSource[colIndex].data.splice(rowIndex, 0, draggedElement);
    if (this.dragDropObj.row_ids.indexOf(draggedElement[this.objectToCluster]) > -1) {
      const index = this.dragDropObj.row_ids.indexOf(draggedElement[this.objectToCluster]);
      this.dragDropObj.cluster_ids.splice(index, 1); 
      this.dragDropObj.row_ids.splice(index, 1);
    }
    this.dragDropObj.cluster_ids.push(this.clusteringDataSource[colIndex].id);
    this.dragDropObj.row_ids.push(draggedElement[this.objectToCluster]);
    this.disableSaveRun.emit(true);
    this.onDragEnd(event);
  }

  onDragEnd(event) {
    this.draggedObj = {
      rowIndex: null,
      colIndex: null
    };
    event && event.preventDefault();
    event && event.stopPropagation();
  }

  onDropColumn(colIndex) {
    this.onDrop(0, colIndex, null);
  }

  async onScroll(item: IClusterCol, index: number) {
    if (item.page*20 < item.total) { // 20 items per page
      this.spinner.show();
      item.page++;
      await this.fetchClusterData(item.id, index, item.page, item.search, item.clusterName);
      this.spinner.hide();
    }
  }

  downloadData() {
    const formData = new FormData();
    formData.append('user_id', this.userObj.userId);
    formData.append('file_name', this.fileName);
    formData.append('run_tab_index', this.runIndex);
    formData.append('version_no', this.versionNo);
    this.spinner.show();
    this.clusteringService.downloadClusterColumnsData(formData)
    .subscribe((res: any) => {
      if (res && res.payload) {
        window.open(res.payload, '_blank');
      }
      this.spinner.hide();
    },
    err => {
      this.toastrService.error('Something went wrong!', 'Error');
      this.spinner.hide();
    })
  }

  adjustMembership() {
    const clustersData = [];
    let invalidName = false;
    this.clusteringLabels.forEach((element: any) => {
      const clusterObj = {
        uu_id: element.label_id,
        name: element.label_name.trim()
      }
      !clusterObj.name && (invalidName = true);
      clustersData.push(clusterObj);
    });
    if (invalidName) {
      this.toastrService.warning('Names cannot be empty.');
      return;
    }

    // checking for duplicate headers
    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
    const lowerCasedArray = [];
    clustersData.forEach(headerElement => {
      lowerCasedArray.push(headerElement.name.toLowerCase());
    });
    const duplicates = [...new Set(findDuplicates(lowerCasedArray))];
    if (duplicates.length) {
      this.toastrService.warning('Duplicate names are not allowed: ' + duplicates, 'Warning');
      return;
    }
    
    const obj =  {
      file_name: this.fileName,
      user_id: this.userObj.userId,
      row_ids: this.dragDropObj.row_ids,
      cluster_ids: this.dragDropObj.cluster_ids,
      cluster_by: this.objectToCluster,
      metric_columns: this.metrices,
      update_cluster_label: clustersData,
      version_no: this.versionNo
    };
    const formData = new FormData();
    formData.append('adjustment_settings', JSON.stringify(obj));
    this.spinner.show();
    this.clusteringService.adjustMembership(formData)
    .subscribe((res: any) => {
      this.isAdjusted = true;
      this.dragDropObj.clusteringLabels = this.clusteringLabels;
      this.adjustmentSaved.next(this.dragDropObj);
      this.dragDropObj.cluster_ids = [];
      this.dragDropObj.row_ids = [];
      this.disableSaveRun.emit(false);
      res.payload.clusteringLabels = this.clusteringLabels;
      this.clusteringService.adjustMembershipSubject.next(res.payload);
      this.patchData();
      this.nameChanged = false;
      this.toastrService.success('Adjustment saved successfully!', 'Success');
    },
    err => {
      this.toastrService.error('Failed to save adjustment.', 'Error');
      this.spinner.hide();
    });
  }

  refetchAdjustedMembersData(clusterIds) {
    this.patchData(clusterIds);
  }

  resetToAlgo() {
    this.spinner.show();
    const obj = {
      file_name: this.fileName,
      user_id: this.userObj.userId,
      version_no: this.versionNo
    };
    this.clusteringService.resetToAlgo(obj)
    .subscribe((res: any) => {
      this.toastrService.success('Data has been reset successfully.');
      this.reset();
    },
    err => {
      this.toastrService.error('Failed to reset data.');
      this.spinner.hide();
    });
  }

  reset() {
    this.dragDropObj.cluster_ids = [];
    this.dragDropObj.row_ids = [];
    this.disableSaveRun.emit(false);
    this.isAdjusted = false;
    this.patchData();
    this.nameChanged = false;
    this.clusteringService.adjustMembershipSubject.next(false);
  }

  showHideAdjustedData(itemValue, isDragged = false, searchValue = ''): boolean {
    let response = true;
    searchValue = searchValue.trim();
    if (isDragged && !(itemValue as string).startsWith(searchValue)) {
      response = false;
    }
    return response;
  }

}
