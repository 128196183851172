import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { USER_TOKEN_INFO } from '../../common/keys';
import jwt_decode from 'jwt-decode';
import { CClaim } from '../../common/claims';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    login(obj) {
        return this.http.post<any>(`${environment.AppAccount}/login`, { Email: obj.Email, Password: obj.Password })
            .pipe(map(user => {
                return user;
            }));
    }

    forgotPassword(email: string) {
        return this.http.post(`${environment.AppAccount}/forgotPassword`, { email })
            .pipe(map(message => {

                return message;
            }));
    }

    resetPassword(obj) {

        return this.http.post<any>(`${environment.AppAccount}/resetpassword`, obj)
            .pipe(map(message => {
                return message;
            }));
    }
    changePassword(obj): Observable<any> {

        return this.http.post<any>(`${environment.AppAccount}/changepassword`, obj);
    }

    logoutUser(obj): Observable<any> {
        return this.http.put<any>(`${environment.AppAccount}/UserLogout`, obj);
    }

    getTokenData() {
        const token = localStorage.getItem(USER_TOKEN_INFO);
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(USER_TOKEN_INFO);
    }
    setClaimBasedAccess(claims: string[]) {

        // Centre Claims

        for (const key in CClaim.CentreClaims) {
            if (key) {
                CClaim.CentreClaims[key] = claims.includes(key);
            }
        }

        // Appointment Claims

        for (const key in CClaim.CustomerAppointmentClaims) {
            if (key) {
                CClaim.CustomerAppointmentClaims[key] = claims.includes(key);
            }
        }

        // Inspection Report Claims

        for (const key in CClaim.InspectionReportClaims) {
            if (key) {
                CClaim.InspectionReportClaims[key] = claims.includes(key);
            }
        }


        // User Claims

        for (const key in CClaim.UserClaims) {
            if (key) {
                CClaim.UserClaims[key] = claims.includes(key);
            }
        }

        // Customer Claims

        for (const key in CClaim.CustomerClaims) {
            if (key) {
                CClaim.CustomerClaims[key] = claims.includes(key);
            }
        }
        // Purchase Claims

        for (const key in CClaim.PurchaseClaims) {
            if (key) {
                CClaim.PurchaseClaims[key] = claims.includes(key);
            }
        }

        // Auction Claims

        for (const key in CClaim.AuctionClaims) {
            if (key) {
                CClaim.AuctionClaims[key] = claims.includes(key);
            }
        }


        // Checkpoint Claims

        for (const key in CClaim.CheckpointClaims) {
            if (key) {
                CClaim.CheckpointClaims[key] = claims.includes(key);
            }
        }


        // Warehouse Claims

        for (const key in CClaim.WarehouseClaims) {
            if (key) {
                CClaim.WarehouseClaims[key] = claims.includes(key);
            }
        }


        // Dealership Claims

        for (const key in CClaim.DealershipClaims) {
            if (key) {
                CClaim.DealershipClaims[key] = claims.includes(key);
            }
        }

        // Account Claims

        for (const key in CClaim.AccountClaims) {
            if (key) {
                CClaim.AccountClaims[key] = claims.includes(key);
            }
        }


        // Role Claims

        for (const key in CClaim.RoleClaims) {
            if (key) {
                CClaim.RoleClaims[key] = claims.includes(key);
            }
        }


        // Common Claims

        for (const key in CClaim.CommonClaims) {
            if (key) {
                CClaim.CommonClaims[key] = claims.includes(key);
            }
        }

        // Quotation Claims

        for (const key in CClaim.QuotationClaims) {
            if (key) {
                CClaim.QuotationClaims[key] = claims.includes(key);
            }
        }

        // Auction Bid Claims

        for (const key in CClaim.AuctionBidClaims) {
            if (key) {
                CClaim.AuctionBidClaims[key] = claims.includes(key);
            }
        }
        // Auction Document Claims

        for (const key in CClaim.AuctionDocumentClaims) {
            if (key) {
                CClaim.AuctionDocumentClaims[key] = claims.includes(key);
            }
        }
        // Auction Wishlist Claims

        for (const key in CClaim.AuctionWishlistClaims) {
            if (key) {
                CClaim.AuctionWishlistClaims[key] = claims.includes(key);
            }
        }
        // Dealer Vehicle Alert Claims

        for (const key in CClaim.DealerVehicleAlertClaims) {
            if (key) {
                CClaim.DealerVehicleAlertClaims[key] = claims.includes(key);
            }
        }
        // Warehouse Vehicle Purchase Claims

        for (const key in CClaim.WarehouseVehiclePurchaseClaims) {
            if (key) {
                CClaim.WarehouseVehiclePurchaseClaims[key] = claims.includes(key);
            }
        }
        // Warehouse Vehicle Purchase Claims

        for (const key in CClaim.WarehouseVehiclePurchaseClaims) {
            if (key) {
                CClaim.WarehouseVehiclePurchaseClaims[key] = claims.includes(key);
            }
        }
        // Document Claims

        for (const key in CClaim.DocumentClaims) {
            if (key) {
                CClaim.DocumentClaims[key] = claims.includes(key);
            }
        }
        // Checkpoint Claims

        for (const key in CClaim.CheckpointClaims) {
            if (key) {
                CClaim.CheckpointClaims[key] = claims.includes(key);
            }
        }
        // Inspection Checkpoint Detail Claims

        for (const key in CClaim.InspectionCheckpointDetailClaims) {
            if (key) {
                CClaim.InspectionCheckpointDetailClaims[key] = claims.includes(key);
            }
        }
        // Inspection Checkpoint Document Claims

        for (const key in CClaim.InspectionCheckpointDocumentClaims) {
            if (key) {
                CClaim.InspectionCheckpointDocumentClaims[key] = claims.includes(key);
            }
        }
        // Inspection Claims

        for (const key in CClaim.InspectionClaims) {
            if (key) {
                CClaim.InspectionClaims[key] = claims.includes(key);
            }
        }
        // Inspection Document Claims

        for (const key in CClaim.InspectionDocumentClaims) {
            if (key) {
                CClaim.InspectionDocumentClaims[key] = claims.includes(key);
            }
        }
        // Vehicle Purchase Claims

        for (const key in CClaim.VehiclePurchaseClaims) {
            if (key) {
                CClaim.VehiclePurchaseClaims[key] = claims.includes(key);
            }
        }
        // Warehous Claims
        for (const key in CClaim.WarehousClaims) {
            if (key) {
                CClaim.WarehousClaims[key] = claims.includes(key);
            }
        }
        // Appointment Claims
        for (const key in CClaim.AppointmentClaims) {
            if (key) {
                CClaim.AppointmentClaims[key] = claims.includes(key);
            }
        }
        // User Notification Claims
        for (const key in CClaim.UserNotificationClaims) {
            if (key) {
                CClaim.UserNotificationClaims[key] = claims.includes(key);
            }
        }
        // Indicata Claims
        for (const key in CClaim.IndicataClaims) {
            if (key) {
                CClaim.IndicataClaims[key] = claims.includes(key);
            }
        }
        // Make Claims
        for (const key in CClaim.MakeClaims) {
            if (key) {
                CClaim.MakeClaims[key] = claims.includes(key);
            }
        }
        // Model Claims
        for (const key in CClaim.ModelClaims) {
            if (key) {
                CClaim.ModelClaims[key] = claims.includes(key);
            }
        }
        // TrimLevel Claims
        for (const key in CClaim.TrimLevelClaims) {
            if (key) {
                CClaim.TrimLevelClaims[key] = claims.includes(key);
            }
        }
        // TrimLevel Claims
        for (const key in CClaim.TrimLevelClaims) {
            if (key) {
                CClaim.TrimLevelClaims[key] = claims.includes(key);
            }
        }
        // Vehicle Common Claims
        for (const key in CClaim.VehicleCommonClaims) {
            if (key) {
                CClaim.VehicleCommonClaims[key] = claims.includes(key);
            }
        }
        // DashBoard Claims
        for (const key in CClaim.DashBoardClaims) {
            if (key) {
                CClaim.DashBoardClaims[key] = claims.includes(key);
            }
        }
        // PMS claims
        for (const key in CClaim.PMSClaims) {
          if (key) {
              CClaim.PMSClaims[key] = claims.includes(key);
          }
      }

    }
}
