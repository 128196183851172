import { Injectable, } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  CLIENT_INFO,
  USER_ID,
  USER_INFO,
  USER_ROLES,
  USER_TEAMS
} from 'src/common/keys';
import { environment } from 'src/environments/environment';
import { LocalstorageService } from './localstorage.service';

@Injectable()
export class LoggingService {

  appInsights: ApplicationInsights;

  constructor(
    private storage: LocalstorageService,
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }

  standardLogEventProps() {
    const event: any = {
      clientId: this.storage.get(CLIENT_INFO).clientConfigurationId,
      clientName: this.storage.get(CLIENT_INFO).clientName,
      origin: window.location.origin,
      userId: this.storage.get(USER_ID),
      userName: this.storage.get(USER_INFO).userName,
      firstName: this.storage.get(USER_INFO).firstName,
      lastName: this.storage.get(USER_INFO).lastName,
      userStatus: this.storage.get(USER_INFO).active,
      userRoles: this.storage.get(USER_ROLES).map(a => a.roleName).join(','),
      userTeams: this.storage.get(USER_TEAMS).map(a => a.teamName).join(',')
    };
    return event;
  }

  logPageView(page, props = {}) { // option to call manually
    const event = this.standardLogEventProps();
    this.appInsights.trackPageView({
      name: page.name,
      uri: page.selector,
      pageType: page.module,
      properties: {
        ...props,
        ...event
      }
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    const event = this.standardLogEventProps();
    this.appInsights.trackEvent({ name: name }, {
      ...properties,
      ...event
    });
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}