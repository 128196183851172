import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { USER_TOKEN_INFO } from '../common/keys';

@Injectable({
  providedIn: 'root'
})
export class AppMasterGuard implements CanActivate {
  menuItem: any;
  constructor(private router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = localStorage.getItem(USER_TOKEN_INFO);
    //console.log(user);
    if (next.queryParams.Info) {
      const store = JSON.parse(atob(next.queryParams.Info));
      Object.keys(store).forEach(key => {
        localStorage.setItem(key, store[key]);
      });
      return true;
    } else {
      if (!user) {
        this.router.navigate(['auth/Login']);
        return false;
      }
      return true;
    }
  }
}
