import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxToasterService } from '../ngx-toaster.service';
import { BOT_TOKEN_INFO, MICROSOFT_TOKEN_INFO } from 'src/common/keys';
import { LocalstorageService } from '../localstorage.service';

@Injectable()
export class EmpowerService {

  // Subjects
  public locationTreeList = new BehaviorSubject<Array<any>>([]);
  public partnerTreeViewList = new BehaviorSubject<Array<any>>([]);
  public partnerGroupTreeViewList = new BehaviorSubject<Array<any>>([]);
  public timeframeTreeViewList = new BehaviorSubject<Array<any>>([]);

  // Set Subject Values
  setLocationTreeList = (value: Array<any>) => { this.locationTreeList.next(value); };
  setPartnerTreeViewList = (value: Array<any>) => { this.partnerTreeViewList.next(value); };
  setPartnerGroupTreeViewList = (value: Array<any>) => { this.partnerGroupTreeViewList.next(value); };
  setTimeframeTreeViewList = (value: Array<any>) => { this.timeframeTreeViewList.next(value); };

  constructor(private http: HttpClient, private toastr: NgxToasterService, private storage: LocalstorageService) {
  }

  GetEmpoweredDateLookup() {
    return this.http.get<any>(`${environment.Empower}/GetEmpoweredDate`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetEmpoweredGeoLookup() {
    return this.http.get<any>(`${environment.Empower}/GetEmpoweredGeo`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetEmpoweredRMIPartnerGroup() {
    return this.http.get<any>(`${environment.Empower}/GetEmpoweredRMIPartnerGroup`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetDIMPartnerList() {
    return this.http.get<any>(`${environment.Empower}/DIMPartnerGetAll`)
      .pipe(map(response => {
        return response;
      }));
  }

  ExecutePageDaxQuery(body) {
    const payload = {
      ...body,
      countryName: body.countryName && body.countryName.map(a => `"${a}"`).join(','),
      partnerName: body.partnerName && body.partnerName.map(a => `"${a}"`).join(','),
      consumerTopParent: body.consumerTopParent && body.consumerTopParent.map(a => `"${a}"`).join(','),
      calendarIds: body.calendarIds && body.calendarIds.map(a => moment(a).format('YYYYMMDD')).join(',')
    };
    return this.http.post<any>(`${environment.Empower}/ExecuteDaxQuery`, payload, {
      headers: {
        'x-microsoft-token': `${this.storage.get(MICROSOFT_TOKEN_INFO)}`
      }
    }).pipe(map(data => {
      // WE NEED TO REMOVE THIS CODE;;;; TO BE HANDLED AT BACKEND
      data.forEach(element => {
        if (element.daxResponse) {
          const daxResponse = JSON.parse(element.daxResponse);
          if (daxResponse.results && daxResponse.results[0]
            && daxResponse.results[0].tables && daxResponse.results[0].tables[0]
            && daxResponse.results[0].tables[0].rows && daxResponse.results[0].tables[0].rows.length) {
              for(let i = 0; i<daxResponse.results[0].tables[0].rows.length;i++) {
                  let a = daxResponse.results[0].tables[0].rows[i];
                  const keys = Object.keys(a);
                  const values = Object.values(a);
                  const obj = {} as any;
                  keys.forEach((k, index) => {
                    const newKey = k.charAt(0).toLowerCase() + k.slice(1);
                    obj[newKey] = values[index];
                  });
                  daxResponse.results[0].tables[0].rows[i] = obj;
              }
              element.daxResponse = JSON.stringify(daxResponse.results[0].tables[0].rows);
          }
        }
      });
      return data;
    }));
  }

  ExecuteInsightQuery(body) {
    return this.http.post<any>(`${environment.ApplicationInsights}/ApplicationInsightsGet`, body)
      .pipe(map(data => {
        return data;
      }));
  }

}

