import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ItemPromotionService } from 'src/services/Item-Management-Services';

@Component({
    selector: 'promotion-item-edit-cell-renderer',
    template: `<a class="product-title-link" (click)="btnEditClickHandler()">{{params.value}}</a>`,
})
export class PromotionItemEditRenderer implements ICellRendererAngularComp, OnDestroy {
    params: any;

    constructor(public itemPromotionService: ItemPromotionService) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    btnEditClickHandler() {
        this.itemPromotionService.setPromotionEditSubject(this.params.data);
    }

    refresh(params?: any): boolean {
        return true;
    }

    ngOnDestroy() {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}
