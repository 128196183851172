import { ColDef } from 'ag-grid-enterprise';
import * as _ from 'lodash';
import { dateRenderer } from 'src/ag-grid/renderers/common-renderers/common-renderers';
import { currencyRenderer, tooltipcellRenderer } from 'src/common/util/util';

export const paginationPageSize = 50;
export const cacheBlockSize = 50;
export const rowSelection = 'single';

export const defaultColDef = {
  minWidth: 50,
  filter: true,
  sortable: true,
  resizable: true,
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const masterGridColDefs = (isRetailerTeam = false) => {
  const masterGridColDefs: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      width: 150,
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false
    },
    {
      headerName: '',
      width: 100,
      field: 'cellAction',
      cellRenderer: 'masterItemActionCellRenderer',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false
    },
    {
      headerName: 'Product Title ID',
      field: 'productTitleId',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Product Title Name',
      field: 'productTitleName',
      cellRenderer: 'masterItemEditRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Account',
      field: 'accountName',
      filter: isRetailerTeam ? 'agTextColumnFilter' : false,
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Release Date',
      field: 'releaseDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    },
    {
      headerName: 'Pre-awareness Date',
      field: 'preAwarenessDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    },
    {
      headerName: 'Announce Date',
      field: 'announcementDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    },
    {
      headerName: 'Rating',
      field: 'ratingName',
      filter: 'agTextColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        debounceMs: 2000,
        filterOptions: ['contains', 'equals']
      }
    },
    {
      headerName: 'Theatrical Release Date',
      field: 'theatricalReleaseDate',
      type: 'date',
      cellRenderer: dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['clear'],
        filterOptions: ['equals']
      }
    }

  ];
  return masterGridColDefs;
}

export const childGridColDefs = (addSelectionBox = false, isRetailerTeam = false) => {
  const itemChildColDef: ColDef[] = [
    {
      headerName: '',
      width: 70,
      field: 'cellAction',
      cellRenderer: 'childItemActionCellRenderer',
      suppressColumnsToolPanel: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: 'Image',
      field: 'Image',
      width: 100,
      cellRenderer: (params) => {
        return `<img class="item-grid-thumbnail"
          src="${_.get(_.pickBy(_.get(params, 'data.document', {}), (value, key) => { return value !== '' }), 'documentThumbnailPath', 'assets/images/default-grid-image.png')}"
        />`;
      },
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: 'ePID',
      field: 'SKU',
      width: 140,
    },
    {
      headerName: 'Manufacture Part Number',
      field: 'ManufacturePartNumber',
      width: 235,
    },
    {
      headerName: 'UPC',
      field: 'UPC',
      width: 125,
    },
    {
      headerName: 'Title',
      field: 'Title',
      width: 245,
      cellRenderer: 'childItemEditRenderer',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Year',
      field: 'Year',
      width: 100,
    },
    {
      headerName: 'Make',
      field: 'Make',
      width: 130,
    },
    {
      headerName: 'Model',
      field: 'Model',
      width: 135,
    },
    {
      headerName: 'Brand',
      field: 'Brand',
      width: 150,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Type',
      field: 'Class',
      width: 180,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Subtype',
      field: 'SubClass',
      width: 200,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      },
    },
    {
      headerName: 'Cost',
      field: 'Cost',
      cellRenderer: currencyRenderer,
      width: 120,
    },
    {
      headerName: 'Retail',
      field: 'Retail',
      cellRenderer: currencyRenderer,
      width: 120,
    }
  ];
  return itemChildColDef;
};
