import { Component } from '@angular/core';


@Component({
  selector: 'app-empower',
  templateUrl: './empower.component.html',
  styleUrls: ['./empower.component.scss']
})
export class EmpowerComponent  {


  constructor() {
  }

}
