<cluster-membership-summary *ngIf="fileName" [versionNo]="versionNo" [summaryStats]="clusterStatistics" [clusteringLabels]="clusteringLabels" [runIndex]="runIndex" [fileName]="fileName" [metrices]="summaryStatsMetricVariables" [perClusterCount]="perClusterCount" [isOutputTab]="false"></cluster-membership-summary>

<!-- GRAPH -->
<div class="d-flex justify-content-between m-3">
    <h5>Scatter Plot</h5>
    <div class="d-flex align-items-center">
        <mat-form-field appearance="outline">
            <mat-label>x-Axis</mat-label>
            <mat-select (selectionChange)="axisChanged()" [(ngModel)]="xAxis">
                <mat-option *ngFor="let item of metrices" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="ml-2">
            <mat-label>y-Axis</mat-label>
            <mat-select (selectionChange)="axisChanged()" [(ngModel)]="yAxis">
                <mat-option *ngFor="let item of metrices" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="ml-2 mb-2">
            <button mat-raised-button color="accent" (click)="downloadChart()">Download Chart</button>
        </div>
    </div>
</div>

<div class="d-flex w-100 align-items-center justify-content-center">
    <div style="height: 70vh;" class="d-flex align-items-center justify-content-center w-75" #scatterPlotDiv>
    </div>
</div>

<hr class="theme-hr" />

<cluster-column *ngIf="clusteringLabels.length" [versionNo]="versionNo" [runIndex]="runIndex" [objectToCluster]="objectToCluster" [fileName]="fileName" [clusteringLabels]="clusteringLabels" [isOutputTab]="false"></cluster-column>

<hr class="theme-hr" />

<div class="justify-content-end">
    <button style="float: right;" mat-raised-button color="accent">COMMIT & PUBLISH</button>
</div>
