import { Component, OnInit } from '@angular/core';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-enterprise';
import * as _ from 'lodash';
import { defaultColDef, getColDefs } from './ag-grid/grid-options';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { OutlierLineChartCellRenderer } from './ag-grid/outlier-line-chart-cell-renderer.component';
import { Subscription } from 'rxjs';
import { OutlierService } from 'src/services/forecast-services/outlier-service';
import { MatDialog } from '@angular/material/dialog';
import { OutlierChartDialogComponent } from './dialogs/outlier-chart-dialog/outlier-chart-dialog.component';
import * as moment from 'moment';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { tap } from 'rxjs/operators';
import { getFilterModel, getSortModel } from 'src/common/util/util';
import { MappingService } from 'src/services/forecast-services/mapping-service';
import { environment } from 'src/environments/environment';
import * as signalR from '@aspnet/signalr';
import { AddEditMappingForecastItemComponent } from '../mapping-manager/add-edit-mapping-forecast-item/add-edit-mapping-forecast-item.component';
import { gridDataExportParams } from 'src/modules/item-manager/features/util/util';
import { OutlierCustomHeaderGroup } from './ag-grid/custom-groupheader.component';
import { setSyncButtonClass } from '../forecast-helper-functions';
import { OutlierCommitDialogComponent } from './dialogs/outlier-commit-dialog/outlier-commit-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';
import { SharedDataService } from 'src/services/shared-data.service';

@Component({
  selector: 'app-outlier-manager',
  templateUrl: './outlier-manager.component.html',
  styleUrls: ['./outlier-manager.component.scss']
})
export class OutlierManagerComponent implements OnInit {

  paginationPageSize = 50;
  cacheBlockSize = 50;
  rowFactor = 2;
  totalRows = 0;
  rowModelType = 'serverSide';
  serverSideStoreType:any = 'partial';
  isGridLoaded: boolean = false;
  weekList: any = [];
  uncommitedColumn: any = '';
  orderByValueList: any = ['Original', 'Adjusted'];

  public commitGridApi: GridApi;
  public commitColApi: ColumnApi;
  public commitGridColumnDefs = [];

  public gridApi: GridApi;
  public colApi: ColumnApi;
  public columnDefs = [];
  components = {
    numericCellEditor: getNumericCellEditor()
  }
  bottomDataGridOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: defaultColDef,
    rowHeight: 40,
    suppressRowHoverHighlight:true,
    isRowSelectable: function (rowNode) {
      return rowNode.data ? rowNode.data.saleValues === 'Adjusted' : false;
    },
    rowClassRules: {
      'adjusted-row': `data.saleValues === 'Adjusted'`
    },
    tooltipShowDelay: 100,
  };
  groupDisplayType = 'multipleColumns';
  outlierFrameworkComponents = {
    outlierLineChartCellRenderer: OutlierLineChartCellRenderer,
    customHeaderGroupComponent: OutlierCustomHeaderGroup
  }

  gridSelectedRows: any = [];
  isGridDataUpdated: boolean = false;
  gridUpdatedCells: any = [];

  public lineChartSubscription: Subscription;
  public commitSubscription: Subscription;

  isShowAnomalous: boolean = false;
  editedCells: any = [];
  commitWeekEditedCells: any = [];

  azpipeline: any;

  // for hidden grid
  bottomHiddenGridApi: GridApi;

  setSyncButtonClassFunction = setSyncButtonClass;

  numberOfWeeks: number = 104;

  constructor(
    public outlierService: OutlierService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public mappingService: MappingService,
    private configurationService: ConfigurationService,
    public forecastManagerService: ForecastManagerService,
    public _dataService: SharedDataService,
  ) {
  }

  ngOnInit() {
    this.getOutlierWeekList();
    this.subscriptions();
    this.setupHubConnection();
  }

  get isFilteredApplied(): boolean {
    return this.gridApi ? !_.isEmpty(this.gridApi.getFilterModel()) : false;
  }

  get isRowsSelected(): boolean {
    return this.gridSelectedRows.length ? true : false;
  }

  setupHubConnection() {
    const configUrl = `${environment.configurationUrl}`;
    const hubUrl = configUrl.substring(0, configUrl.length - 3) + "notify";
    this.azurePipelineTriggerGetList();
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(hubUrl)
      .build();
    connection
      .start()
      .then(function () {
        console.log('SignalR Connected!');
      })
      .catch(function (err) {
        return console.error();
      });
    connection.on('BroadcastMessage', (con) => {
      this.azurePipelineTriggerGetList();
    });
  }

  clearGridFilters() {
    this.gridApi.setFilterModel(null);
  }

  clearSelectedRows() {
    this.gridApi.deselectAll();
  }

  subscriptions() {
    this.lineChartSubscription = this.outlierService.getOutlierManagerGridActionSubject().subscribe(data => {
      if (data) {
        this.openLineChart(data);
      }
    });

    this.commitSubscription = this.outlierService.getWeekCommitActionSubject().subscribe(column => {
      if (column) {
        this.commitEarliestColumn(column);
      }
    })
  }

  accountLookup = () => {
    return new Promise((resolve, reject) => {
      this.outlierService.GetAccountList().subscribe((accountList: any) => {
        return resolve(accountList.map(a => a.accountName));
      });
    });
  }

  brandLookup = () => {
    return new Promise((resolve, reject) => {
      this.outlierService.GetBrandList().subscribe((brandList: any) => {
        return resolve(brandList.map(a => a.brand));
      });
    });
  }

  businessUnitLookup = () => {
    return new Promise((resolve, reject) => {
      this.outlierService.GetProductGroupList().subscribe((productgrouplist: any) => {
        return resolve(productgrouplist.map(a => a.productGroup));
      });
    });
  }

  productDescriptionLookup = () => {
    return new Promise((resolve, reject) => {
      this.outlierService.GetProductDescriptionList().subscribe((productdescriptionlist: any) => {
        return resolve(productdescriptionlist.map(a => a.productDescription));
      });
    });
  }

  onExportGridData() {
    if (this.gridSelectedRows.length) {
      const gridData = this.getGridData();
      let filteredData = [];
      this.gridSelectedRows.forEach(b => {
        const dataFiltered = _.filter(gridData, a => a.retailer_key == b.retailer_key && a.item_key == b.item_key);
        filteredData = [...filteredData, ...dataFiltered];
      });
      this.exportAsCSV(filteredData);
    } else {
      this.exportServerSideOutlierData();
    }
  }

  exportServerSideOutlierData() {
    if (!this.totalRows) return;
    this.spinner.show();
    const sortModel = this.colApi.getColumnState();
    const filterModel = this.gridApi.getFilterModel();
    const requestBody: any = {
      // 0 base index of page number
      offSet: 0,
      pageSize: this.totalRows
    };
    requestBody.numberOfWeeks = this.numberOfWeeks;
    requestBody.filterModel = getFilterModel(filterModel);
    requestBody.sortModel = getSortModel(sortModel);
    requestBody.isOutlier = this.isShowAnomalous ? 1 : 0;
    this.outlierService
      .GetOutlierWeeksData(requestBody)
      .subscribe((response: any) => {
        const gridData = this.createGridData(response);
        this.exportAsCSV(gridData);
        this.spinner.hide();
      },
        err => {
          this.spinner.hide();
        }
      );
  }

  exportAsCSV(gridData) {
    this.bottomHiddenGridApi.setRowData(gridData);
    const csvExportParams = gridDataExportParams({
      fileName: 'Outlier-Manager',
      colDefs: this.columnDefs,
      allColumns: false,
      columnKeys: _.map(this.colApi.getAllDisplayedColumns(), a => a.colId)
    });
    this.bottomHiddenGridApi.exportDataAsCsv(csvExportParams);
  }

  skuLookup = () => {
    return new Promise((resolve, reject) => {
      this.outlierService.GetSKUList().subscribe((skulist: any) => {
        return resolve(skulist.map(a => a.sku));
      });
    });
  }

  commitEarliestColumn(column) {
    const weekMetadata = column.split('#');
    /*
      0: Report Year
      1: Week Number
      2: Week Start Date
    */
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Commit Week`,
        confirmationMessage: `Are you sure you want to commit all the Rows in the Week ${weekMetadata[1]}?`
      }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.outlierService.CommitWeek({ commitWeek: new Date(weekMetadata[2]) }).subscribe(res => {
          this.spinner.hide();
          this.toastr.success('Success', `${weekMetadata[1]} commited successfully.`);
          this.gridUpdatedCells.length && this.saveUpdatedGridData();
          this.getOutlierWeekList();

        }, err => {
          this.toastr.error('Error', `System failed to commit week ${weekMetadata[1]}.`);
          this.spinner.hide();
        });
      }
    });
  }

  numberOfWeeksChange() {
    this.getOutlierWeekList();
  }

  getOutlierWeeksInfo() {
    this.spinner.show();
    this.forecastManagerService.GetLatestCommitedWeek().subscribe((response: any) => {
      if (response.latestPosWeek === response.committedDate) {
        this.toastr.info('Info', 'No week available to commit.');
      } else {
        this.openCommitDialog(response);
      }
      this.spinner.hide();
    });
  }

  openCommitDialog(data) {
    const dialogRef = this.dialog.open(OutlierCommitDialogComponent, {
      data: data,
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getOutlierWeekList();
      }
    });
  }

  getOutlierWeekList() {
    this.spinner.show();
    this.outlierService.GetOutlierWeeksMetadata(this.numberOfWeeks).subscribe(weeklist => {
      this.spinner.hide();
      this.weekList = weeklist;
      this.creatWeekColDefs();
    }, err => {
      this.spinner.hide();
      this.toastr.error('Error', `System failed to get weeks metadata.`);
    });
  }

  openLineChart(data) {
    this.dialog.open(OutlierChartDialogComponent, {
      disableClose: true,
      data: {
        chartData: data,
        weekList: this.weekList
      }
    });
  }

  getUncommitColField(weekList) {
    let uncommitColumn = _.filter(weekList, a => !a.isCommitted);
    uncommitColumn = uncommitColumn.length ? _.orderBy(uncommitColumn, ['week_Number'], ['asc']) : uncommitColumn;
    return uncommitColumn.length ? uncommitColumn[0].columnField : '';
  }

  creatWeekColDefs() {
    const filterLookupModel = {
      account: this.accountLookup,
      brand: this.brandLookup,
      product_group: this.businessUnitLookup,
      product_description: this.productDescriptionLookup,
      sku: this.skuLookup
    };
    this.commitGridColumnDefs = getColDefs(true, {}, this);
    this.columnDefs = getColDefs(false, filterLookupModel, this);
    this.uncommitedColumn = this.getUncommitColField(this.weekList);
    const weekColDefs: any = [];
    this.weekList.forEach(week => {
      weekColDefs.push({
        headerName: `W-${week.weekOfYear}`,
        field: `${week.columnField}`,
        width: 95,
        filter: false,
        sortable: false,
        isCommited: week.isCommitted,
        reportYear: week.reportYear,
        suppressMenu: true,
        suppressSort: true,
        showCommitButton: this.uncommitedColumn === week.columnField,
        editable: (params) => {
          if (params.data.saleValues === 'Adjusted') {
            return true;
          } else {
            return false;
          }
        },
        cellEditor: 'numericCellEditor',
        cellRenderer: (params) => {
          return `<span title="${(params.data.saleValues === 'Adjusted' && params.data[week.columnField + '_system']) ? `System Suggested: ${params.data[week.columnField + '_system']}` : ''}">${params.value ? Number(params.value).toFixed(0) : ''}</span>`;
        },
        // ${this.uncommitedColumn === week.columnField ?
        //   '<br><button mat-raised-button color="accent" onclick="agHandleCommitButtonClick(event)" ref="eCustomButton" class="ag-header-icon ag-header-cell-custom-button">Commit</button>' : ''}
        headerComponentParams: {
          template: `<div class="ag-cell-label-container" role="presentation">
          <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
          <div ref="eLabel" class="ag-header-cell-label ag-week-col" role="presentation">
              <span ref="eText" class="ag-header-cell-text"></span>
              <br>
              <span>${moment(week.weeKStartDate).format('MM/DD/YYYY')}</span>
              <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
              <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
              <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
              <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
          </div>
      </div>`
        },
        cellClassRules: {
          'mapped-value-cell': (params) => {
            if (params.data.saleValues === 'Original'
              && params.data[`${week.columnField}_mapped`]) {
              return true;
            }
          },
          'adj-background': (params) => params.data.saleValues === 'Adjusted',
          'adjusted-outlier': (params) => {
            if (params.data.saleValues === 'Original'
              && params.data[`${week.columnField}_outlier`]
              && week.isCommitted) {
              return true;
            }
          },
          'commited-org-data': (params) => {
            if (params.data.saleValues === 'Original'
              && week.isCommitted) {
              return true;
            }
          },
          'unadjusted-outlier': (params) => {
            if (params.data.saleValues === 'Original'
              && params.data[`${week.columnField}_outlier`]
              && !week.isCommitted) {
              return true;
            }
          },
          'edited-cell': (params) => {
            const editedCell = _.find(this.editedCells,
              a => a.row === params.data.saleValues
                && a.colId === params.colDef.field
                && a.item_account_key === params.data.item_account_key);
            if (editedCell) {
              return true;
            }
          }
        },
      })
    });
    const reportYears = _.orderBy(_.uniqBy(this.weekList, 'reportYear').map(a => a.reportYear), 'asc');
    const commitedWeeks = _.filter(weekColDefs, a => a.isCommited);
    const uncommitedWeeks = _.filter(weekColDefs, a => !a.isCommited);
    const selectedWeeksIndex = _.findIndex(this.columnDefs, a => a.field === 'selectedWeeks');
    this.columnDefs[selectedWeeksIndex] = {
      ...this.columnDefs[selectedWeeksIndex],
      weekList: this.weekList
    }
    if (commitedWeeks.length) {
      if (reportYears.length === 1) {
        this.columnDefs.push({
          headerName: 'TY Prior Weeks',
          headerClass: 'ty-column-group',
          children: commitedWeeks
        });
        this.commitGridColumnDefs.push({
          headerName: 'TY Prior Weeks',
          children: commitedWeeks
        });
      } else {
        const lyWeeks = _.filter(commitedWeeks, a => a.reportYear !== reportYears[reportYears.length - 1]);
        const tyWeeks = _.filter(commitedWeeks, a => a.reportYear === reportYears[reportYears.length - 1]);
        if (lyWeeks.length) {
          this.columnDefs.push({
            headerName: 'LY Prior Weeks',
            headerClass: 'ly-column-group',
            children: lyWeeks
          });
          this.commitGridColumnDefs.push({
            headerName: 'LY Prior Weeks',
            children: lyWeeks
          });
        }

        if (tyWeeks.length) {
          this.columnDefs.push({
            headerName: 'TY Prior Weeks',
            headerClass: 'ty-column-group',
            children: tyWeeks
          });
          this.commitGridColumnDefs.push({
            headerName: 'TY Prior Weeks',
            children: tyWeeks
          });
        }
      }

    }
    uncommitedWeeks.forEach(week => {
      this.columnDefs.push({
        headerName: 'Unadjusted',
        headerClass: 'unadjusted-column-group',
        headerGroupComponent: "customHeaderGroupComponent",
        children: [week]
      });
      this.commitGridColumnDefs.push({
        headerName: 'Unadjusted',
        children: [{
          ...week,
          cellRenderer: 'commitActionCellRenderer',
          cellStyle: { 'text-align': 'center' }
        }]
      })
    });
    if (uncommitedWeeks.length) {
      this.uncommitedColumn = uncommitedWeeks[0].field;
    }
    this.isGridLoaded = true;
    if (this.gridApi) {
      this.gridApi.setColumnDefs([]);
      setTimeout(() => {
        this.gridApi.setColumnDefs(this.columnDefs);
      }, 100);
    }
    if (this.bottomHiddenGridApi) {
      this.bottomHiddenGridApi.setColumnDefs([]);
      setTimeout(() => {
        this.bottomHiddenGridApi.setColumnDefs(this.columnDefs);
      }, 100);
    }
    this.GetEarliestUnadjustedWeekUpdatedValues();
    this.gridApi && this.createDataSource();
  }

  GetEarliestUnadjustedWeekUpdatedValues() {
    this.outlierService.GetEarliestUnadjustedWeekUpdatedValues().subscribe((res: any) => {
      if (res) {
        this.commitWeekEditedCells = res;
        this.editedCells = Object.assign([], this.commitWeekEditedCells);
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.createDataSource();
  }

  onBottomHiddenGridReady(params) {
    this.bottomHiddenGridApi = params.api;
  }

  openColumnDialog() {
    const dialogRef = this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select', 'cellAction', 'saleValues'],
        disabledColumns: [this.uncommitedColumn]
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.syncCommitGridCol()
    });
  }

  syncCommitGridCol() {
    const gridColumns = (this.colApi as any).columnController.gridColumns;
    gridColumns.forEach(col => {
      this.commitColApi.setColumnVisible(col.colId, col.visible);
    });
    this.gridApi.refreshHeader();
  }

  createDataSource() {
    this.resetEditedCells();
    const datasource = {
      getRows: (params) => {
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel;
        const requestBody: any = {
          // 0 base index of page number
          offSet: params.request.startRow ? (params.request.startRow / this.rowFactor) : 0,
          pageSize: (this.paginationPageSize / this.rowFactor),
        };

        requestBody.filterModel = getFilterModel(filterModel);
        requestBody.sortModel = getSortModel(sortModel);
        requestBody.isOutlier = this.isShowAnomalous ? 1 : 0;
        requestBody.numberOfWeeks = this.numberOfWeeks;

        requestBody.offSet == 0 && this.deselectNode();

        this.outlierService
          .GetOutlierWeeksData(requestBody)
          .pipe(
            tap(() => this.gridApi.hideOverlay())
          )
          .subscribe((response: any) => {
            const gridData = this.createGridData(response);
            const totalRecord = _.get(response, '0.PTotalRecord', 0);
            this.totalRows = totalRecord;
            params.successCallback(gridData, (totalRecord * this.rowFactor));
            response.length == 0 && this.gridApi.showNoRowsOverlay();
          });
      },
    };
    this.gridApi.setServerSideDatasource(datasource);
  }

  deselectNode() {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  createGridData(data) {
    const gridData: any = [];
    data.forEach(sku => {
      const adjustedSKU = Object.assign({}, sku);
      this.weekList.forEach(week => {
        /*
          0: original value
          1: system adjusted value
          2: lower bound
          3: upper bound
          4: user adjusted value
          5: is outlier
          6: is mapped
        */
        sku.saleValues = 'Original';
        adjustedSKU.saleValues = 'Adjusted';
        if (sku[week.columnField]) {
          const splitedValue = sku[week.columnField].split('_');
          sku[week.columnField] = splitedValue[0];
          sku[`${week.columnField}_adjusted`] = splitedValue[4];
          sku[`${week.columnField}_lower`] = splitedValue[2];
          sku[`${week.columnField}_upper`] = splitedValue[3];

          adjustedSKU[week.columnField] = splitedValue[4];
          adjustedSKU[`${week.columnField}_original`] = splitedValue[0];
          adjustedSKU[`${week.columnField}_system`] = splitedValue[1];
          sku[`${week.columnField}_outlier`] = splitedValue[5] == '0' ? false : true;
          sku[`${week.columnField}_mapped`] = splitedValue[6] == '1' ? true : false;
        }
      });

      gridData.push(sku);
      gridData.push(adjustedSKU);
    });
    return gridData;
  }

  gridRowSelectionChanged(params) {
    this.gridSelectedRows = params.api.getSelectedRows();
  }

  resetEditedCells() {
    this.GetEarliestUnadjustedWeekUpdatedValues();
  }

  getGridData() {
    const gridData = _.map(_.get(this.gridApi, 'rowModel.nodeManager.rowNodes', []), a => a && a.data);
    return gridData.filter(item => item);
  }

  gridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {

      const column = params.column.colDef.field;
      const gridData = this.getGridData();
      const originalDataRow = gridData.find(a => a.item_account_key === params.data.item_account_key && a.saleValues === 'Original');
      originalDataRow[`${column}_adjusted`] = params.newValue;
      const weekMetadat = column.split('#');
      /*
        0: Report Year
        1: Week Number
        2: Week Start Date
      */
      params.column.colDef.cellStyle = {
        'border-left': 'double',
        'border-right': 'double',
        'border-top': 'none',
        'border-bottom': 'none',
        'border-color': 'black'
      };

      this.editedCells.push({
        item_account_key: params.data.item_account_key,
        colId: params.column.colId,
        row: params.data.saleValues
      });

      if (column == this.uncommitedColumn) {
        this.commitWeekEditedCells.push({
          item_account_key: params.data.item_account_key,
          colId: params.column.colId,
          row: params.data.saleValues
        });
      }

      this.isGridDataUpdated = true;
      const prvUpdatedRowStateIndex = _.findIndex(this.gridUpdatedCells,
        a => a.item_account_key == params.data.item_account_key
          && a.week_number == weekMetadat[1]
      );
      if (prvUpdatedRowStateIndex < 0) {
        this.gridUpdatedCells.push({
          item_account_key: params.data.item_account_key,
          weekNumber: weekMetadat[1],
          itemKey: params.data.item_key,
          retailerKey: params.data.retailer_key,
          weekSellThroughUserAdjusted: params.newValue
        });
      } else {
        this.gridUpdatedCells[prvUpdatedRowStateIndex] = {
          item_account_key: params.data.item_account_key,
          weekNumber: weekMetadat[1],
          itemKey: params.data.item_key,
          retailerKey: params.data.retailer_key,
          weekSellThroughUserAdjusted: params.newValue
        };
      }
      this.gridApi.redrawRows();
    }
  }

  saveUpdatedGridData() {
    this.spinner.show();
    this.outlierService.UpdateOutlierGrid({ data: this.gridUpdatedCells }).subscribe(res => {
      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridUpdatedCells = [];
      this.isGridDataUpdated = false;
      this.resetEditedCells();
      this.gridApi.redrawRows();
      this.spinner.hide();
      this.toastr.success('Success', `Weeks data updated successfully.`);
    }, err => {
      this.toastr.error('Error', `Failed to update weeks data.`);
      this.spinner.hide();
    });
  }

  getSelectedSKUList() {
    const selectedSKUList = this.gridSelectedRows.map(sku => {
      return { itemKey: sku.item_key, retailerKey: sku.retailer_key };
    });
    return selectedSKUList;
  }

  createGridActionBody() {
    const selectedSKUList = this.getSelectedSKUList();
    return {
      selectedSKU: selectedSKUList
    };
  }

  resetToSuggestedEarlistUnadjustedWeek() {
    const weekMetadat = this.uncommitedColumn.split('#');
    /*
      0: Report Year
      1: Week Number
      2: Week Start Date
    */
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Reset to Suggested`,
        confirmationMessage: `Are you sure you want to reset to suggested of earliest unadjusted week ${weekMetadat[1]}?`
      }
    });
    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const body: any = this.createGridActionBody();
        const filterModel = this.gridApi.getFilterModel();
        body.filterModel = getFilterModel(filterModel);
        this.outlierService.UpdateOutlierResetSuggested(body).subscribe(res => {
          this.spinner.hide();
          this.toastr.success('Success', `Reset to suggested of earliest unadjusted week successfully.`);
          this.createDataSource();
        }, err => {
          this.spinner.hide();
          this.toastr.error('Error', `Failed to reset to suggested of earliest unadjusted week.`);
        });
      }
    });

  }

  toggleValueChange(event: any) {
    this.createDataSource();
    this.gridApi.paginationGoToFirstPage();
  }

  updateOutlierAboveOriginalEarlistUnadjustedWeek() {
    const weekMetadat = this.uncommitedColumn.split('#');
    /*
      0: Report Year
      1: Week Number
      2: Week Start Date
    */
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Accept Values Above Original`,
        confirmationMessage: `Are you sure you want to update above original values of earliest unadjusted week ${weekMetadat[1]}?`
      }
    });
    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const body: any = this.createGridActionBody();
        const filterModel = this.gridApi.getFilterModel();
        body.filterModel = getFilterModel(filterModel);
        this.outlierService.UpdateOutlierAboveOriginal(body).subscribe(res => {
          this.spinner.hide();
          this.toastr.success('Success', `Retain above original outlier values of earliest unadjusted week successfully.`);
          this.createDataSource();
        }, err => {
          this.spinner.hide();
          this.toastr.error('Error', `Failed to retain above original outlier values of earliest unadjusted week.`);
        });
      }
    });
  }

  updateOutlierBelowOriginalEarlistUnadjustedWeek() {
    const weekMetadat = this.uncommitedColumn.split('#');
    /*
      0: Report Year
      1: Week Number
      2: Week Start Date
    */
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Accept Values Below Original`,
        confirmationMessage: `Are you sure you want to update below original values of earliest unadjusted week ${weekMetadat[1]}?`
      }
    });
    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        const body: any = this.createGridActionBody();
        const filterModel = this.gridApi.getFilterModel();
        body.filterModel = getFilterModel(filterModel);
        this.outlierService.UpdateOutlierBelowOriginal(body).subscribe(res => {
          this.spinner.hide();
          this.toastr.success('Success', `Retain below original outlier values of earliest unadjusted week successfully.`);
          this.createDataSource();
        }, err => {
          this.spinner.hide();
          this.toastr.error('Error', `Failed to retain below original outlier values of earliest unadjusted week.`);
        });
      }
    });

  }

  triggerMappingManagerPipeline() {
    if (this.azpipeline && this.azpipeline.status && ['InProgress', 'Queued'].indexOf(this.azpipeline.status) > -1) {
      this.toastr.info('Info', 'Pipeline already in-progress.')
    } else {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          headerName: `Delete Mappings`,
          confirmationMessage: `Are you sure you want to trigger mapping manager pipeline?`
        }
      });

      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show()
          this.mappingService.TriggerMappingManagerPipeline().subscribe(response => {
            this.azpipeline = response;
            this.spinner.hide();
            if (this.azpipeline.status == "InProgress") {
              this.toastr.success('Success', 'Outlier mapping manager pipeline is triggered.');
            }
            else {
              this.azpipeline.status = "InProgress";
              this.toastr.info('info', 'pipeline already in-Progress.');
            }
          }, error => {
            this.toastr.error('Error', 'Outlier mapping manager pipeline is failed to triggered.');
          });
        }
      });
    }
  }
  azurePipelineTriggerGetList() {
    this.spinner.show();
    this.mappingService.AzurePipelineTriggerGetList().subscribe(azpipeline => {
      this.azpipeline = azpipeline;
      this.spinner.hide();
    });
  }

  openMappingSetupScreen() {
    if (this.gridSelectedRows.length <= 1) {
      let isOneSkuSelected = false;
      let skuModel = {};
      if (this.gridSelectedRows.length === 1) {
        isOneSkuSelected = true;
        skuModel = {
          retailer: this.gridSelectedRows[0].frl_account_name,
          retailerKey: this.gridSelectedRows[0].retailer_key,
          brand: this.gridSelectedRows[0].brand,
          productGroup: this.gridSelectedRows[0].product_group,
          productDescription: this.gridSelectedRows[0].PRODUCT_DESCRIPTION,
          sku: this.gridSelectedRows[0].item_key
        };
      }
      const data = {
        isEdit: false,
        isSKUSelected: isOneSkuSelected,
        ...isOneSkuSelected && { selectedSKU: skuModel }
      };
      this.openAddMappingScreen(data);

    } else {
      this.toastr.warning('Warning', 'Please select only one row for mapping.')
    }
  }

  openAddMappingScreen(data = {}) {
    const forecastSetupComponent = {
      menuId: '462',
      name: 'Item Mapping Setup',
      selector: 'app-add-edit-mapping-forecast-item',
      displayName: 'Item Mapping Setup',
      module: 'ForecastEnginModule',
      data: data,
      component: AddEditMappingForecastItemComponent
    }
    this.configurationService.menuRendererSubject.next(forecastSetupComponent);
  }

  ngOnDestroy() {
    this.lineChartSubscription.unsubscribe();
    this.commitSubscription.unsubscribe();
  }

}
