<div class="container-fluid mt-10 mb-2 modern-life-pitch">
  <mat-sidenav-container autosize>
    <mat-sidenav opened #sidenav mode="side" style="max-height: 790px;">

      <ng-container *ngFor="let item of reportPageMenus">
        <ng-container *ngIf="item.children && item.children.length > 0;">
          <ng-container [ngTemplateOutlet]="reportMenutemplate" [ngTemplateOutletContext]="{item:item, depth:1}">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="item && item.active && (!item.children || item.children.length === 0)">
          <mat-nav-list class="single-link nav-title"
            [ngClass]="selectedPage?.reportPageId==item.reportPageId ? 'active':''" [matTooltip]="item.navTitle">
            <a mat-list-item (click)="selectPage(item)">
              {{'#'+item.pageOrder +' '+item.navTitle}}
            </a>
          </mat-nav-list>
        </ng-container>
      </ng-container>
      <ng-template #reportMenutemplate let-item="item" let-depth="depth">
        <ng-container *ngIf="item && item?.children && item?.children.length > 0">
          <mat-expansion-panel [ngStyle]="{'padding-left': (depth * 15) + 'px'}">
            <mat-expansion-panel-header class="disable_ripple layout-exp-panel" collapsedHeight="45px"
              expandedHeight="45px">
              <mat-panel-title> {{item.pageCategory}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let child of item?.children">
              <ng-container [ngTemplateOutlet]="reportMenutemplate"
                [ngTemplateOutletContext]="{item:child, depth:depth+1}">
              </ng-container>
            </ng-container>
            <ng-container>
              <ng-container *ngFor="let child of item?.children">
                <mat-nav-list *ngIf="child && child.active && !child.children"
                  [ngStyle]="{'padding-left': (depth * 15) + 'px'}">
                  <a mat-list-item (click)="selectPage(child)" [matTooltip]="child.navTitle"
                    [ngClass]="selectedPage?.reportPageId==child.reportPageId ? 'active':''" class="nav-title">
                    <div class="arrow">
                      {{'#'+child.pageOrder +' '+child.navTitle}}
                    </div>
                  </a>
                </mat-nav-list>
              </ng-container>
            </ng-container>
          </mat-expansion-panel>
        </ng-container>
      </ng-template>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="title-bar p-2 d-flex position-relative align-items-center side-nav-bar">
        <mat-icon class="cursor-pointer" (click)="sidenav.toggle()">{{sidenav.opened ? 'arrow_back_ios' :
          'arrow_forward_ios'}}</mat-icon>
        <h4 class="m-0 ml-4">{{selectedPage?.pageCategory || ''}}</h4>
      </div>
      <div id="{{uniqueId}}">
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
