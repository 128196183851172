<div mat-dialog-content>
    <h5><strong>Transform Data</strong></h5>
    <div class="w-100 row">
        <div class="col-md-4" style="max-height: 60vh; overflow-y: auto;">
            <div class="row w-100">
                <div class="col-12">
                    <strong>Left Percentage</strong>
                    <mat-form-field appearance="fill">
                        <input matInput type="number" disabled readonly [value]="leftPercentageNewValue | number:'1.0-2'" />
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <strong>Middle Percentage</strong>
                    <mat-form-field appearance="fill">
                        <input matInput type="number" disabled readonly [value]="middleNewValue | number:'1.0-2'" />
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <strong>Right Percentage</strong>
                    <mat-form-field appearance="fill">
                        <input matInput type="number" disabled readonly [value]="rightPercentageNewValue | number:'1.0-2'" />
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <strong>Lower</strong>
                    <mat-form-field appearance="fill">
                        <input matInput type="number" [(ngModel)]="lowerNewValue" (change)="calculateLowerAndHigherOutlierForGraph()" />
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <strong>Higher</strong>
                    <mat-form-field appearance="fill">
                        <input matInput type="number" [(ngModel)]="higherNewValue" (change)="calculateLowerAndHigherOutlierForGraph()" />
                    </mat-form-field>
                </div>
                <div class="frequency-table m-2 col-12">
                    <table>
                        <tr>
                            <th>Interval</th>
                            <th>Frequency</th>
                        </tr>
                        <tr *ngFor="let item of data.outlierData.intervals; let i=index">
                            <td>{{item[0]}} >= {{item[1]}}</td>
                            <td>{{data.outlierData.counts[i]}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="d-flex w-100 align-items-center justify-content-center h-100" style="min-width: 30vw;">
                <div style="height: 100%; width: 90%;" class="d-flex align-items-center justify-content-center"
                    #histogramPlotDiv>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button class="mr-2" mat-stroked-button color="accent" (click)="onNoClick()">Close</button>
    <button mat-raised-button color="accent" (click)="onSaveClick()">Apply Changes</button>
</div>
