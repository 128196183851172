import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_INFO } from 'src/common/keys';
import { MessageModel } from 'src/models/channelMessaging.model';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ChannelMessagingService } from 'src/services/Messaging-Services/channel-messaging.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as signalR from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { find } from 'util';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
const nisPackage = require("package.json")

@Component({
  selector: 'app-chat-details',
  templateUrl: './chat-details.component.html',
  styleUrls: ['./chat-details.component.scss'],

})
export class ChatDetailsComponent implements OnInit {
  @ViewChild('scrollMe') scrollMe: ElementRef;
  panelOpenState = false;
  public editText = false;
  public messageModel = new MessageModel();
  public messageModelList: MessageModel[] = [];
  userText: any;
  @Input() data: any;
  menuData: any;
  channelDetails: any;
  public group: FormGroup;
  channelMessageId: any = '';
  hoverIndex: any = null;
  page: number = 1;
  userObj: any;
  throttle = 100;
  scrollDistance = .5;
  scrollUpDistance = .5;
  direction = '';
  nisVersion = nisPackage.dependencies["ngx-infinite-scroll"];
  public resultData = [];

  constructor(
    private formBuilder: FormBuilder,
    private channelMessagingService: ChannelMessagingService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public router: Router,
    public storage: LocalstorageService,
    public matDialogRef: MatDialogRef<any>,
    private dialog: MatDialog,
  ) {
    this.userObj = this.storage.get(USER_INFO);
  }

  localeTimeString(date): string {
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);

    var offset = utcDate.getTimezoneOffset() / 60;
    var hours = utcDate.getHours();

    localDate.setHours(hours - offset);

    return localDate.toString();
  }

  ngOnInit(): void {
    this.initializeForm();
    if (this.data) {
      const data = JSON.parse(this.data);
      this.getChannelByChannelId(data);
      this.getAllChannelMessageByUsers(1);

    }
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.messagingHubUrl)
      .build();
    connection
      .start()
      .then(function () {
        console.log('SignalR Connected!');
      })
      .catch(function (err) {
        return console.error();
      });
    connection.on('BroadcastMessage', (con) => {
      if (this.data) {
        const data = JSON.parse(this.data);
        this.page = 1;

        this.getAllChannelMessageByUsers(this.page);


      }
    });
  }

  initializeForm() {
    this.group = this.formBuilder.group({
      message: [''],
    });
  }

  expand(obj) {
    if (!obj.savesize) obj.savesize = obj.size;
    obj.size = Math.max(obj.savesize, obj.value.length);
  }

  getChannelByChannelId(data) {
    this.spinner.show();
    this.channelMessagingService
      .GetChannelByChannelId(data.channelId)
      .subscribe(
        (channelDetails: any) => {
          this.spinner.hide();
          this.channelDetails = channelDetails;

        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            'Error',
            'Something went wrong'
          );
        }
      );
  }

  getAllChannelMessageByUsers(currentPage: number) {
    const data = JSON.parse(this.data);
    this.spinner.show();
    this.editText = true;
    this.channelMessagingService.GetAllChannelMessage(data.channelId, currentPage).subscribe(
      (channelMessaging: any) => {
        this.spinner.hide();
        // console.log('channel Messaging', channelMessaging);
        this.messageModelList = channelMessaging;
        // this.resultData=[];
        let data = new Set(this.messageModelList.map((item) => item.createdDate.split('T')[0]));
        if (this.page === 1) {
          this.resultData = [];
          data.forEach((date) => {
            this.resultData.push({
              date: date,
              messageModelList: this.messageModelList.filter((i) => i.createdDate.split('T')[0] === date),
            });
          });
        } else {
          data.forEach((date) => {

            const res = this.resultData.find(item => item.date == date)
            if (res) {
              res.messageModelList.unshift(...this.messageModelList.filter((i) => i.createdDate.split('T')[0] === date))
            }
            else {
              this.resultData.unshift({
                date: date,
                messageModelList: this.messageModelList.filter((i) => i.createdDate.split('T')[0] === date),
              });
            }

          });

        }
        this.resultData = _.orderBy(this.resultData, ['date'], ['asc'])
        setTimeout(() => {
          if (currentPage == 1) {
            try {
              this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
            } catch (err) { }
          }
        }, 100);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Error',
          'Something went wrong'
        );
      }
    );
  }

  onKeydown(event) {
    event.preventDefault();
  }

  postMessage() {
    this.spinner.show();
    const data = JSON.parse(this.data);
    this.messageModel.channelId = data.channelId;
    this.messageModel.createdDate = data.createdDate;
    this.messageModel.modifiedDate = data.modifiedDate;
    this.messageModel.deletedDate = data.deletedDate;
    this.channelMessagingService.SendMessage(this.messageModel).pipe(finalize(()=> this.spinner.hide())).subscribe(
      (sendMsg: any) => {
        if (sendMsg.delete == true) {
          this.toastr.info(
            'information',
            'The channel ' + data.channelName + ' has been deleted by an administrator.'

          );
          // this.spinner.hide();
        }
        this.messageModel = new MessageModel();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Error',
          'Something went wrong chat service down'
        );
      }
    );
  }

  enter(i) {
    this.hoverIndex = i;
  }

  leave(msg) {
    this.hoverIndex = null;
  }

  editMessage(msg) {
    msg.isEdit = true;
    this.channelMessageId = msg.channelMessageId;
    msg.preMessage = msg.message;
  }

  updateMessage(messageModel) {
    this.spinner.show();
    messageModel.channelMessageReadByUser = null;
    this.channelMessagingService.UpdateMessage(messageModel).subscribe(
      (updateMsg: any) => {
        this.spinner.hide();
        messageModel.isEdit = false;

      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Error',
          'Something went wrong chat service down!'
        );
      }
    );
  }

  deleteMessage(channelMessageId: number) {
    this.matDialogRef.close();
    this.spinner.show();
    this.channelMessagingService.DeleteMessage(channelMessageId).subscribe(
      (deleteMsg: any) => {
        this.spinner.hide();

      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Error',
          'Something went wrong chat service down!'
        );
      }
    );
  }

  cancel(msg) {
    msg.isEdit = false;
    msg.message = msg.preMessage;
    msg.preMessage = null;
  }
  ////////////////Deleet Dialogue///
  public openDeleteDialog = (template: TemplateRef<any>) => {
    this.matDialogRef = this.dialog.open(template, { width: '500px' });
  };

  //////////////Pagination on scroll
  onScrollDown() {
    console.log("onScrollDown");
  }

  onUp() {
    console.log("onUp");
    this.page = this.page + 1;
    this.getAllChannelMessageByUsers(this.page);
  }

  //////////////Set message read////
  resetReadMessageCount() {
    const data = JSON.parse(this.data);
    this.channelMessagingService.GetUnreadChannelMessagesCount(data.channelId).subscribe(
      (count: number) => {
        if (count > 0) {

          this.markChannelMessagesRead(data.channelId);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Error',
          'Something went wrong'
        );
      }
    );
  }


  markChannelMessagesRead(channelId: number) {
    this.channelMessagingService.MarkChannelMessagesRead(channelId).subscribe(
      (count: number) => {
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error('Error', 'Something went wrong ');
      }
    );
  }
}
