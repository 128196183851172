import { dateRenderer, dateTimeRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import * as _ from 'lodash';
import { tooltipcellRenderer } from "src/common/util/util";

export const defaultColDef = {
  filter: true,
  sortable: false,
  minWidth: 50,
  resizable: true,
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getCalendarViewColDefs = (filterLookupModel: any = {}) => {
  const colDefs: any = [
    {
      headerName: 'Year',
      field: 'reportYear',
    },
    {
      headerName: 'Date',
      field: 'weekStartDate',
      type: 'date',
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Week Number',
      field: 'weekOfYear',
    },
    {
      headerName: 'Month',
      field: 'monthName',
    }
  ];

  return colDefs;
}
