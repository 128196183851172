import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { generateTitleDiv, NO_IMAGE, undefinedImagePath } from 'src/modules/empower-module/helper';

@Component({
  selector: 'empower-sur-one-accer-refr-opper',
  templateUrl: './sur-one-accer-refr-opper.component.html',
  styleUrls: ['./sur-one-accer-refr-opper.component.scss']
})
export class EmpowerSurOneAccerRefrOpperComponent implements OnInit {
  @Input() data?: any;
  _unsubscribeAll = new Subject();
  undefinedImagePath = '';

  // ../assets/empower/pitch-factory/images/30-screen-01.jpeg
  imageUrl = NO_IMAGE;
  revenueBaseline: number;
  innerHtml = '';
  pageComment: string;

  constructor(
    public empowerModernLifeService: EmpowerModernLifeService, private empowerExportService: EmpowerExportService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);

      this.innerHtml = generateTitleDiv(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const daxReponse = this.empowerModernLifeService.getPagesState(pageId);
    this.pageComment = this.empowerModernLifeService.getPageCommentState(pageId);
    if (daxReponse && daxReponse.length) {
      this.loadVisuals(daxReponse);
    } else {
      this.executePageDaxQuery();
    }
    const imageUrl = this.empowerModernLifeService.getPageImgState(pageId);
    if (imageUrl) {
      this.imageUrl = imageUrl;
    }
  }

  imageChange(imageUrl) {
    this.empowerModernLifeService.setPageImgState(this.data.pageId, imageUrl);
  }

  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.empowerModernLifeService.getDashboardState()
    };
    this.spinner.show();
    this.empowerModernLifeService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        this.emitExportEvent(true);
      }
      this.spinner.hide();
    },
      err => {
        this.undefinedImagePath = undefinedImagePath;
        this.spinner.hide();
      });
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(30, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }

  setPageState(pageId, daxData) {
    this.empowerModernLifeService.setPagesState(pageId, daxData);
  }

  loadVisuals(daxData) {
    this.loadFirstVisual(daxData[0]);
    this.emitExportEvent(true);
  }

  loadFirstVisual(visualData) {
    this.revenueBaseline = _.get(visualData.daxResponse, ['0', '[1 % Suface Refresh Rate]'], 0);
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.empowerModernLifeService.setPageCommentState(pageId, comment);
  }

}
