import { ForecastSetupComponent } from './../modules/forecast-engin/features/forecast-manager/forecast-setup/forecast-setup.component';
import { ForecastManagerComponent } from '../modules/forecast-engin/features/forecast-manager/forecast-manager.component';
import { AdminSettingsComponent } from "src/modules/auth/features/admin-settings/admin-settings.component";
import { FacebookAccountSettingsComponent } from "src/modules/auth/features/admin-settings/facebook-account-settings/facebook-account-settings.component";
import { FacebookProductSettingsComponent } from "src/modules/auth/features/admin-settings/facebook-product-settings/facebook-product-settings.component";
import { ResetPasswordComponent } from "src/modules/auth/features/login/reset-password/reset-password.component";
import { CreateUpdateRoleComponent } from "src/modules/auth/features/roles/create-update-role/create-update-role.component";
import { RolesComponent } from "src/modules/auth/features/roles/roles.component";
import { CreateUpdateTeamComponent } from "src/modules/auth/features/teams/create-update-team/create-update-team.component";
import { CurrencyComponent } from "src/modules/auth/features/teams/currency/currency.component";
import { FinanceComponent } from "src/modules/auth/features/teams/finance/finance.component";
import { TeamsComponent } from "src/modules/auth/features/teams/teams.component";
import { CreateUpdateUserComponent } from "src/modules/auth/features/users/create-update-user/create-update-user.component";
import { UsersComponent } from "src/modules/auth/features/users/users.component";
import { ClusteringForecastComponent } from "src/modules/clustering-forecast/clustering-forecast.component";
import { PlanogramOptimizerComponent } from "src/modules/item-manager/features/planogram-optimizer/planogram-optimizer.component";
import { ItemManagerComponent } from "src/modules/item-manager/item-manager.component";
import { ActivityComponent } from "src/modules/layout/features/activity/activity.component";
import { AlgoMenuComponent } from "src/modules/layout/features/algo-menu/algo-menu.component";
import { AnomolyDetectorFormComponent } from "src/modules/layout/features/anomoly-detector-form/anomoly-detector-form.component";
import { InsightListingV2Component } from "src/modules/layout/features/app-insight-v2/insight-listing-v2/insight-listing-v2.component";
import { InsightListingComponent } from "src/modules/layout/features/app-insight/insight-listing/insight-listing.component";
import { NotificationConfigurationComponent } from "src/modules/layout/features/notification-configuration/notification-configuration.component";
import { SuggestAdminComponent } from "src/modules/layout/features/suggest-admin/suggest-admin.component";
import { UserInterfaceComponent } from "src/modules/layout/features/user-interface/user-interface.component";
import { ChatDetailsComponent } from "src/modules/messaging/chat-details/chat-details.component";
import { DynamicReportComponent } from "src/modules/reporting/features/dynamic-report/dynamic-report.component";
import { QNAReportComponent } from "src/modules/reporting/features/qna-report/qna-report.component";
import { ReportingComponent } from "src/modules/reporting/reporting.component";
import {
  ItemApprovalDetailComponent,
  ItemListComponent,
  ItemPromotionDetailComponent,
  ItemPromotionListComponent,
  MasterItemListComponent,
  BBNewItemPlannerComponent,
  PromotionPlannerComponent,
  PromotionPlannerDetailComponent
} from '../modules/item-manager/features';
import { FileManagementComponent } from "src/modules/clustering-forecast/file-management/file-management.component";
import {
  AddEditMappingForecastItemComponent,
  EditSeasonalityComponent,
  ForecastDataManagerComponent,
  MappingManagerComponent,
  OutlierManagerComponent,
  PromoManagerComponent,
  PromoSetupDetailComponent,
  SeasonalityManagerComponent,
  SeasonalitySetupComponent
} from "src/modules/forecast-engin/features";
import {
  DemoBBNewItemPlannerComponent,
  DemoItemApprovalDetailComponent,
  DemoItemPromotionDetailComponent,
  DemoItemPromotionListComponent,
  DemoMasterChildItemListComponent,
  DemoMasterItemListComponent
} from 'src/modules/demo-manager/features';
import {
PmsDashboardComponent,
PmsItemsComponent,
PmsLocationsComponent,
PmsVendorsComponent,
PmsViewPurchaseRequestComponent,
PmsPurchaseOrdersComponent,
PmsBidsComponent,
PmsGrnComponent,
PmsEvaluationVendorComponent,
PmsAddEditBidComponent
} from "src/modules/procurement";
import { ClientsComponent } from 'src/modules/auth/features/clients/clients.component';
import { HierarchySetupWhiteLabelingComponent } from 'src/modules/auth/features/hierarchy-setup-white-labeling/hierarchy-setup-white-labeling.component';
import { HierarchySetupComponent } from 'src/modules/auth/features/hierarchy-setup/hierarchy-setup.component';
import { HierarchyManagerComponent } from 'src/modules/auth/features/hierarchy-manager/hierarchy-manager.component';
import {
  EmpowerBrandTrendsComponent,
  EmpowerComponent,
  EmpowerDashboardComponent,
  EmpowerOSMarketTrendsComponent,
  WindowsConsumerPrioritiesComponent,
  EmpowerPcaIncRevOpperComponent,
  EmpowerPcaRankingVsMarketComponent,
  EmpowerPcaRevOppervsMarketComponent,
  EmpowerPcPerformanceVsMarketComponent,
  GrowingShareAndDrivingRefreshInConsumerComponent,
  WindowsMarketOpportunityComponent,
  EmpowerSubFormFactorVsMarketComponent,
  EmpowerWindowsOemDevicesVsMarketComponent,
  EmpowerSurAttOpperMarkComponent,
  EmpowerSurRevOpperVsMarkComponent,
  EmpowerSurMixEvoPartVsMarkComponent,
  EmpowerSurSharePartVsMarkComponent,
  EmpowerSurShare800PlusMarketComponent,
  EmpowerM365OneAttIncOpperComponent,
  EmpowerM365MixVsMarketComponent,
  EmpowerM365VisionAndGoalsComponent,
  EmpowerM365RevOpperVsMarketComponent,
  EmpowerRetailDemoExperienceComponent,
  EmpowerM365ConsumerPlanComponent,
  EmpowerMicroIntegStoreExpComponent,
  EmpowerInStoreExperiencesComponent,
  EmpowerExpertZoneComponent,
  EmpowerHelpMeChooseComponent,
  EmpowerPcaConsumerPlanComponent,
  EmpowerPcaPrioritiesComponent,
  EmpowerPcaHybridWorkComponent,
  EmpowerPcaBuildingInnovationComponent,
  EmpowerWindowsLessThanMarketOpportunityComponent,
  EmpowerEmPcDensityWindowsMarketOpportunityComponent,
  EmpowerGrowInGamingWindowsMarketOpportunityComponent,
  EmpowerM365LifeEssentialServiceComponent,
  EmpowerM365ConsumerSecurityValueComponent,
  EmpowerM365EvolveFamiliarityCompaignComponent,
  EmpowerSurOneAccerRefrOpperComponent,
  EmpowerSurfConsumerPrioritiesComponent,
  EmpowerMicrosoftDesignedLaptopsComponent,
  EmpowerMicrosoftDesignedLaptopsTwoComponent,
  EmpowerSurfDeliveringCustomersComponent,
  EmpowerSurfInterStuOnlineModulesComponent,
  EmpowerSurfInterStuMediaTypesComponent,
  EmpowerSurfSearchAndDiscoverabilityComponent,
  M365InteractiveStudiosOnlineStudiosComponent,
  EmpowerM365ConsumerJourTouchpointsComponent,
  EmpowerM365PanaceaAttMotionsComponent,
  EmpowerM365InterStuMediaTypesComponent,
  XboxPitchFactoryComponent,
  EmpowerModernPitchFactoryComponent,
  EmpowerCenterOfGamingComponent,
  EmpowerOurMissionGamingComponent,
  EmpowerDigitalMixComponent,
  EmpowerXboxAudianceComponent,
  EmpowerOurAudiancePcGamersComponent,
  EmpowerPartnerBenefitsComponent,
  EmpowerXboxSeriesSComponent,
  EmpowerIndustryLeadingStudiosComponent,
  EmpowerLowMonthlyPriceComponent,
  EmpowerXboxAllAccessoriesComponent,
  EmpowerXboxAllAccessComponent,
  EmpowerXisOneComponent,
  EmpowerXisTwoComponent,
  EmpowerExpertsZoneComponent,
  EmpowerInStoredExperiencesComponent,
  EmpowerIntegratedStoreExperienceComponent,
  EmpowerXboxDashboardComponent,
  EmpowerPartnerPerformanceInCatUnitsComponent,
  EmpowerPartnerPerformanceInTotalUnitsComponent,
  EmpowerPartnerPerformanceInCatRevenueComponent,
  EmpowerPartnerPerformanceInTotalRevenueComponent,
  EmpowerXboxCustomerVsMarketComponent,
  EmpowerAccessoriesAttachComponent,
  EmpowerConsolePremiumMixComponent,
  EmpowerGamePassMixComponent,
  EmpowerRetailDemoExperiencedComponent,
  EmpowerInsightsDashboardComponent,
  EmpowerSurSharePartVsMarkWithinComponent,
  EmpowerProductPerformanceForecastComponent,
  EmpowerInStoreExpImprovementComponent,
  EmpowerFixturePerformanceByWeekComponent,
  EmpowerInventoryPerformanceAtProductLevelComponent,
  EmpowerOnlineExperienceImprovementComponent,
  EmowerProductPerformingWithAdSpendComponent,
  EmpowerProductExperienceImprovementComponent,
  EmpowerNewLaunchProductPerformanceComponent,
  EmpowerDynamicComponent,
} from 'src/modules/empower-module';
import { TabConfigurationComponent } from 'src/modules/auth/features/tab-configuration/tab-configuration.component';


export class Page {
  menuId: string | number;
  name: string;
  selector: string;
  module: string;
  [key: string]: any;
}
export const Pages: Page[] = [
  {
    menuId: '4',
    name: 'Change Password',
    selector: 'app-reset-password',
    module: 'Auth',
    component: ResetPasswordComponent,
  },
  {
    menuId: '5',
    name: 'activity',
    selector: 'app-activity',
    module: 'app',
    component: ActivityComponent,
  },
  {
    menuId: '6',
    name: 'roles',
    selector: 'app-roles',
    module: 'Auth',
    component: RolesComponent,
  },
  {
    menuId: '14',
    name: 'Menus',
    selector: 'app-algo-menu',
    module: 'app',
    component: AlgoMenuComponent,
  },
  {
    menuId: '39',
    name: 'Users',
    selector: 'app-users',
    module: 'Auth',
    component: UsersComponent,
  },
  {
    menuId: '40',
    name: 'Announcement Configuration',
    selector: 'app-notification-configuration',
    module: 'app',
    component: NotificationConfigurationComponent,
  },
  {
    menuId: '45',
    name: 'User Interface Activity',
    selector: 'app-user-interface',
    displayName: 'User Interface Activity',
    module: 'app',
    component: UserInterfaceComponent,
  },
  {
    menuId: '74',
    name: 'Anomoly Detector',
    selector: 'app-anomoly-detector-form',
    displayName: 'Anomoly Detector',
    module: 'app',
    component: AnomolyDetectorFormComponent,
  },
  {
    menuId: '74',
    name: 'Anomoly Detector',
    selector: 'app-dynamic-report',
    displayName: 'Anomoly Detector',
    module: 'ReportingModule',
    component: DynamicReportComponent,
  },
  {
    menuId: '75',
    name: 'QnA Report',
    selector: 'app-qna-report',
    displayName: 'QnA Report',
    module: 'ReportingModule',
    component: QNAReportComponent,
  },
  {
    menuId: '87',
    name: 'Application Insight',
    selector: 'app-insight-listing',
    displayName: 'Application Insight',
    module: 'app',
    component: InsightListingComponent,
  },
  {
    menuId: '91',
    name: 'Suggest Admin',
    selector: 'app-suggest-admin',
    displayName: 'Suggest Admin',
    module: 'app',
    component: SuggestAdminComponent,
  },
  {
    menuId: '89',
    name: 'Application Insight V2',
    selector: 'app-insight-listing-v2',
    displayName: 'Application Insight V2',
    module: 'app',
    component: InsightListingV2Component,
  },
  {
    menuId: '91',
    name: 'Admin Settings',
    selector: 'app-admin-settings',
    displayName: 'Admin Settings',
    module: 'app',
    component: AdminSettingsComponent,
  },
  {
    menuId: '92',
    name: 'Role Setting',
    selector: 'app-create-update-role',
    displayName: 'Role Setting',
    module: 'Auth',
    component: CreateUpdateRoleComponent,
  },
  {
    menuId: '93',
    name: 'User Setting',
    selector: 'app-create-update-user',
    displayName: 'User Setting',
    module: 'Auth',
    component: CreateUpdateUserComponent,
  },
  {
    menuId: '94',
    name: 'Team Setting',
    selector: 'app-create-update-team',
    displayName: 'User Setting',
    module: 'Auth',
    component: CreateUpdateTeamComponent,
  },
  {
    menuId: '95',
    name: 'Teams',
    selector: 'app-teams',
    displayName: 'Teams',
    module: 'Auth',
    component: TeamsComponent,
  },
  {
    menuId: '96',
    name: 'Currency Setting',
    selector: 'app-currency',
    displayName: 'Currency Setting',
    module: 'Auth',
    component: CurrencyComponent,
  },
  {
    menuId: '97',
    name: 'Finance Setting',
    selector: 'app-finance',
    displayName: 'Finance Setting',
    module: 'Auth',
    component: FinanceComponent,
  },
  {
    menuId: '20',
    name: 'Item Manager',
    selector: 'app-item-manager',
    displayName: 'Item Manager',
    module: 'ItemManagerModule',
    component: ItemManagerComponent
  },
  {
    menuId: '21',
    name: 'Master Item List',
    selector: 'app-master-item-list',
    displayName: 'Item Manager',
    module: 'ItemManagerModule',
    component: MasterItemListComponent
  },
  {
    menuId: '22',
    name: 'Item List',
    selector: 'app-item-list',
    displayName: 'Item List',
    module: 'ItemManagerModule',
    component: ItemListComponent
  },
  {
    menuId: '38',
    name: 'New Item Planner',
    selector: 'app-new-item-planner',
    displayName: 'New Item Planner',
    module: 'ItemManagerModule',
    component: BBNewItemPlannerComponent
  },
  {
    menuId: '39',
    name: 'New Item Promotions',
    selector: 'app-item-promotion-list',
    displayName: 'New Item Promotions',
    module: 'ItemManagerModule',
    component: ItemPromotionListComponent
  },
  {
    menuId: '399',
    name: 'New Item Promotion Detail',
    selector: 'app-item-promotion-detail',
    displayName: 'New Item Promotions',
    module: 'ItemManagerModule',
    component: ItemPromotionDetailComponent
  },
  {
    menuId: '40',
    name: 'New Item Approvals',
    selector: 'app-item-approval-detail',
    displayName: 'New Item Approvals',
    module: 'ItemManagerModule',
    component: ItemApprovalDetailComponent
  },
  {
    menuId: '104',
    name: 'Account Settings',
    selector: 'app-facebook-account-settings',
    displayName: 'Account Settings',
    module: 'Auth',
    component: FacebookAccountSettingsComponent,
  },
  {
    menuId: '104',
    name: 'Product Settings',
    selector: 'app-facebook-product-settings',
    displayName: 'Product Settings',
    module: 'Auth',
    component: FacebookProductSettingsComponent,
  },
  {
    menuId: '59',
    name: 'Promotion Planner',
    selector: 'app-promotion-planner',
    displayName: 'Promotion Planner',
    module: 'ItemManagerModule',
    component: PromotionPlannerComponent
  },
  {
    menuId: '399',
    name: 'Promotion Planner',
    selector: 'app-promotion-planner-detail',
    displayName: 'Promotion Planner',
    module: 'ItemManagerModule',
    component: PromotionPlannerDetailComponent
  },
  {
    menuId: '69',
    name: 'Planogram Optimizer',
    selector: 'app-planogram-optimizer',
    displayName: 'Planogram Optimizer',
    module: 'ItemManagerModule',
    component: PlanogramOptimizerComponent
  },
  {
    menuId: '70',
    name: 'Self-Service Clustering',
    selector: 'app-clustering-forecast',
    displayName: 'Self-Service Clustering',
    module: 'ClusteringForecastModule',
    component: ClusteringForecastComponent
  },
  {
    menuId: '71',
    name: 'Files & Data Management',
    selector: 'app-file-data-management',
    displayName: 'Files & Data Management',
    module: 'ClusteringForecastModule',
    component: FileManagementComponent
  },
  {
    menuId: '188',
    name: 'Chat Details',
    selector: 'app-chat-details',
    displayName: 'Chat Details',
    module: 'MessagingModule',
    component: ChatDetailsComponent
  },
  {
    menuId: '316',
    name: 'Outlier Manager',
    selector: 'app-outlier-manager',
    module: 'ForecastEnginModule',
    component: OutlierManagerComponent,
  },
  {
    menuId: '415',
    name: 'Mapping Manager',
    selector: 'app-mapping-manager',
    module: 'ForecastEnginModule',
    component: MappingManagerComponent,
  },
  {
    menuId: '446',
    name: 'Promotion Planner',
    selector: 'app-promo-manager',
    module: 'ForecastEnginModule',
    component: PromoManagerComponent,
  },
  {
    menuId: '449',
    name: 'Promo Details',
    selector: 'app-promo-setup-detail',
    displayName: 'Promo Details',
    module: 'ForecastEnginModule',
    component: PromoSetupDetailComponent
  },
  {
    menuId: '452',
    name: 'Seasonality Manager',
    selector: 'app-seasonality-manager',
    displayName: 'Seasonality Manager',
    module: 'ForecastEnginModule',
    component: SeasonalityManagerComponent
  },
  {
    menuId: '454',
    name: 'Seasonality Editor',
    selector: 'app-edit-seasonality',
    displayName: 'Seasonality Editor',
    module: 'ForecastEnginModule',
    component: EditSeasonalityComponent
  },
  {
    menuId: '455',
    name: 'Forecast Manager',
    selector: 'app-forecast-manager',
    displayName: 'Forecast Manager',
    module: 'ForecastEnginModule',
    component: ForecastManagerComponent
  },
  {
    menuId: '456',
    name: 'Forecast Data Manager',
    selector: 'app-forecast-data-manager',
    displayName: 'Forecast Data Manager',
    module: 'ForecastEnginModule',
    component: ForecastDataManagerComponent
  },
  {
    menuId: '459',
    name: 'Forecast setup ',
    selector: 'app-forecast-setup',
    displayName: 'Forecast setup ',
    module: 'ForecastEnginModule',
    component: ForecastSetupComponent
  },
  {
    menuId: '21',
    name: 'Master Item List',
    selector: 'app-demo-master-item-list',
    displayName: 'Item Manager',
    module: 'DemoManagerModule',
    component: DemoMasterItemListComponent
  },
  {
    menuId: '22',
    name: 'Item List',
    selector: 'app-demo-master-child-item-list',
    displayName: 'Item List',
    module: 'DemoManagerModule',
    component: DemoMasterChildItemListComponent
  },
  {
    menuId: '38',
    name: 'New Item Planner',
    selector: 'app-demo-new-item-planner',
    displayName: 'New Item Planner',
    module: 'DemoManagerModule',
    component: DemoBBNewItemPlannerComponent
  },
  {
    menuId: '39',
    name: 'New Item Promotions',
    selector: 'app-demo-item-promotion-list',
    displayName: 'New Item Promotions',
    module: 'DemoManagerModule',
    component: DemoItemPromotionListComponent
  },
  {
    menuId: '399',
    name: 'New Item Promotion Detail',
    selector: 'app-demo-item-promotion-detail',
    displayName: 'New Item Promotions',
    module: 'DemoManagerModule',
    component: DemoItemPromotionDetailComponent
  },
  {
    menuId: '40',
    name: 'New Item Approvals',
    selector: 'app-demo-item-approval-detail',
    displayName: 'New Item Approvals',
    module: 'DemoManagerModule',
    component: DemoItemApprovalDetailComponent
  },
  {
    menuId: '549',
    name: 'Empower',
    selector: 'app-empower',
    displayName: 'Empower',
    module: 'EmpowerModule',
    component: EmpowerComponent
  },
  {
    menuId: '549',
    name: 'Modern Life - Pitch Factory',
    selector: 'empower-modern-pitch-factory',
    displayName: 'Modern Life - Pitch Factory',
    module: 'EmpowerModule',
    component: EmpowerModernPitchFactoryComponent
  },
  {
    menuId: '462',
    name: 'Item Mapping Setup',
    selector: 'app-add-edit-mapping-forecast-item',
    displayName: 'Item Mapping Setup',
    module: 'ForecastEnginModule',
    component: AddEditMappingForecastItemComponent
  },
  {
    menuId: '463',
    name: 'Client Manager',
    selector: 'app-clients',
    module: 'Auth',
    component: ClientsComponent,
  },
  {
    menuId: '464',
    name: 'Forecast White Labeling',
    selector: 'app-hierarchy-setup-white-labeling',
    module: 'Auth',
    component: HierarchySetupWhiteLabelingComponent,
  },
  {
    menuId: '465',
    name: 'Hierarchy Setup',
    selector: 'app-hierarchy-setup',
    module: 'Auth',
    component: HierarchySetupComponent,
  },
  {
    menuId: '466',
    name: 'Tab Configuration',
    selector: 'app-tab-configuration',
    module: 'Auth',
    component: TabConfigurationComponent,
  },
  {
    menuId: '550',
    name: 'Empower Dashboard',
    selector: 'empower-dashboard',
    displayName: 'Empower Dashboard',
    module: 'EmpowerModule',
    component: EmpowerDashboardComponent
  },
  {
    menuId: '551',
    name: 'Brand Trends',
    selector: 'empower-brand-trends',
    displayName: 'Brand Trends',
    module: 'EmpowerModule',
    component: EmpowerBrandTrendsComponent
  },
  {
    menuId: '552',
    name: 'OS Market Trends',
    selector: 'empower-os-market-trends',
    displayName: 'OS Market Trends',
    module: 'EmpowerModule',
    component: EmpowerOSMarketTrendsComponent
  },
  {
    menuId: '553',
    name: 'PC Accessories',
    selector: 'empower-pca-inc-rev-opper',
    displayName: 'PC Accessories',
    module: 'EmpowerModule',
    component: EmpowerPcaIncRevOpperComponent
  },
  {
    menuId: '554',
    name: 'PC Accessories',
    selector: 'empower-pca-ranking-vs-market',
    displayName: 'PC Accessories',
    module: 'EmpowerModule',
    component: EmpowerPcaRankingVsMarketComponent
  },
  {
    menuId: '555',
    name: 'PC Accessories',
    selector: 'empower-pca-rev-opper-vs-market',
    displayName: 'PC Accessories',
    module: 'EmpowerModule',
    component: EmpowerPcaRevOppervsMarketComponent
  },
  {
    menuId: '558',
    name: 'PC Performance',
    selector: 'empower-pc-performance-vs-market',
    displayName: 'PC Performance',
    module: 'EmpowerModule',
    component: EmpowerPcPerformanceVsMarketComponent
  },
  {
    menuId: '559',
    name: 'Sub Form Factor vs. Market',
    selector: 'empower-sub-form-factor-vs-market',
    displayName: 'Sub Form Factor vs. Market',
    module: 'EmpowerModule',
    component: EmpowerSubFormFactorVsMarketComponent
  },
  {
    menuId: '560',
    name: 'Windows OEM Devices vs. Market',
    selector: 'empower-windows-oem-devices-vs-market',
    displayName: 'Windows OEM Devices vs. Market',
    module: 'EmpowerModule',
    component: EmpowerWindowsOemDevicesVsMarketComponent
  },
  {
    menuId: '555',
    name: 'Windows Consumer Priorities',
    selector: 'empower-windows-consumer-priorities',
    displayName: 'Windows Consumer Priorities',
    module: 'EmpowerModule',
    component: WindowsConsumerPrioritiesComponent
  },
  {
    menuId: '556',
    name: 'Growing Share and Driving Refresh in Consumer',
    selector: 'empower-growing-share-and-driving-refresh-in-consumer',
    displayName: 'Growing Share and Driving Refresh in Consumer',
    module: 'EmpowerModule',
    component: GrowingShareAndDrivingRefreshInConsumerComponent
  },
  {
    menuId: '557',
    name: '$500 Windows Market Opportunity',
    selector: 'empower-windows-market-opportunity',
    displayName: '$500 Windows Market Opportunity',
    module: 'EmpowerModule',
    component: WindowsMarketOpportunityComponent
  },
  {
    menuId: '558',
    name: 'Surface',
    selector: 'empower-sur-att-opper-mark',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurAttOpperMarkComponent
  },
  {
    menuId: '559',
    name: 'Surface',
    selector: 'empower-sur-rev-opper-vs-mark',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurRevOpperVsMarkComponent
  },
  {
    menuId: '560',
    name: 'Surface',
    selector: 'empower-sur-mix-evo-part-vs-mark',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurMixEvoPartVsMarkComponent
  },
  {
    menuId: '561',
    name: 'Surface',
    selector: 'empower-sur-share-part-vs-mark',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurSharePartVsMarkComponent
  },
  {
    menuId: '562',
    name: 'Surface',
    selector: 'empower-sur-share-800-plus-market',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurShare800PlusMarketComponent
  },
  {
    menuId: '563',
    name: 'M365 Consumer',
    selector: 'empower-m365-one-att-inc-opper',
    displayName: 'M365 Consumer',
    module: 'EmpowerModule',
    component: EmpowerM365OneAttIncOpperComponent
  },
  {
    menuId: '564',
    name: 'M365 Consumer',
    selector: 'empower-m365-mix-vs-market',
    displayName: 'M365 Consumer',
    module: 'EmpowerModule',
    component: EmpowerM365MixVsMarketComponent
  },
  {
    menuId: '565',
    name: 'M365 Vision and Goals',
    selector: 'empower-m365-vision-and-goals',
    displayName: 'M365 Vision and Goals',
    module: 'EmpowerModule',
    component: EmpowerM365VisionAndGoalsComponent
  },
  {
    menuId: '566',
    name: 'M365 Consumer',
    selector: 'empower-m365-rev-opper-vs-market',
    displayName: 'M365 Consumer',
    module: 'EmpowerModule',
    component: EmpowerM365RevOpperVsMarketComponent
  },
  {
    menuId: '567',
    name: 'Channel Marketing Execution',
    selector: 'empower-retail-demo-experience',
    displayName: 'Channel Marketing Execution',
    module: 'EmpowerModule',
    component: EmpowerRetailDemoExperienceComponent
  },
  {
    menuId: '568',
    name: 'M365 Consumer Plan',
    selector: 'empower-m365-consumer-plan',
    displayName: 'M365 Consumer Plan',
    module: 'EmpowerModule',
    component: EmpowerM365ConsumerPlanComponent
  },
  {
    menuId: '567',
    name: 'Channel Marketing Execution',
    selector: 'empower-micro-integ-store-exp',
    displayName: 'Channel Marketing Execution',
    module: 'EmpowerModule',
    component: EmpowerMicroIntegStoreExpComponent
  },
  {
    menuId: '568',
    name: 'Channel Marketing Execution',
    selector: 'empower-in-store-experiences',
    displayName: 'Channel Marketing Execution',
    module: 'EmpowerModule',
    component: EmpowerInStoreExperiencesComponent
  },
  {
    menuId: '569',
    name: 'Channel Marketing Execution',
    selector: 'empower-expert-zone',
    displayName: 'Channel Marketing Execution',
    module: 'EmpowerModule',
    component: EmpowerExpertZoneComponent
  },
  {
    menuId: '570',
    name: 'Channel Marketing Execution',
    selector: 'empower-help-me-choose',
    displayName: 'Channel Marketing Execution',
    module: 'EmpowerModule',
    component: EmpowerHelpMeChooseComponent
  },
  {
    menuId: '571',
    name: 'PC Accessories',
    selector: 'empower-pca-building-innovation',
    displayName: 'PC Accessories',
    module: 'EmpowerModule',
    component: EmpowerPcaBuildingInnovationComponent
  },
  {
    menuId: '572',
    name: 'PC Accessories',
    selector: 'empower-pca-hybrid-work',
    displayName: 'PC Accessories',
    module: 'EmpowerModule',
    component: EmpowerPcaHybridWorkComponent
  },
  {
    menuId: '573',
    name: 'PC Accessories',
    selector: 'empower-pca-priorities',
    displayName: 'PC Accessories',
    module: 'EmpowerModule',
    component: EmpowerPcaPrioritiesComponent
  },
  {
    menuId: '574',
    name: 'PC Accessories',
    selector: 'empower-pca-consumer-plan',
    displayName: 'PC Accessories',
    module: 'EmpowerModule',
    component: EmpowerPcaConsumerPlanComponent
  },
  {
    menuId: '575',
    name: 'Window Less Than Market Opportunity',
    selector: 'empower-windows-less-than-market-opportunity',
    displayName: 'Window Less Than Market Opportunity',
    module: 'EmpowerModule',
    component: EmpowerWindowsLessThanMarketOpportunityComponent
  },
  {
    menuId: '576',
    name: 'Em PC Density Windows Market Opportunity',
    selector: 'empower-em-pc-density-windows-market-opportunity',
    displayName: 'Em PC Density Windows Market Opportunity',
    module: 'EmpowerModule',
    component: EmpowerEmPcDensityWindowsMarketOpportunityComponent
  },
  {
    menuId: '578',
    name: 'Grow In Gaming Windows Market Opportunity',
    selector: 'empower-grow-in-gaming-windows-market-opportunity',
    displayName: 'Grow In Gaming Windows Market Opportunity',
    module: 'EmpowerModule',
    component: EmpowerGrowInGamingWindowsMarketOpportunityComponent
  },
  {
    menuId: '579',
    name: 'M365 Life Essential Service',
    selector: 'empower-m365-life-essential-service',
    displayName: 'M365 Life Essential Service',
    module: 'EmpowerModule',
    component: EmpowerM365LifeEssentialServiceComponent
  },
  {
    menuId: '580',
    name: 'M365 Consumer Security Value',
    selector: 'empower-m365-consumer-security-value',
    displayName: 'M365 Consumer Security Value',
    module: 'EmpowerModule',
    component: EmpowerM365ConsumerSecurityValueComponent
  },
  {
    menuId: '581',
    name: 'M365 Evolve Familiarity Compaign Value',
    selector: 'empower-m365-evolve-familiarity-compaign',
    displayName: 'M365 Evolve Familiarity Compaign Value',
    module: 'EmpowerModule',
    component: EmpowerM365EvolveFamiliarityCompaignComponent
  },
  {
    menuId: '582',
    name: 'Surface',
    selector: 'empower-sur-one-accer-refr-opper',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurOneAccerRefrOpperComponent
  },
  {
    menuId: '580',
    name: 'Surface',
    selector: 'empower-surf-consumer-priorities',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurfConsumerPrioritiesComponent
  },
  {
    menuId: '581',
    name: 'Surface',
    selector: 'empower-microsoft-designed-laptops',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerMicrosoftDesignedLaptopsComponent
  },
  {
    menuId: '582',
    name: 'Surface',
    selector: 'empower-microsoft-designed-laptops-two',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerMicrosoftDesignedLaptopsTwoComponent
  },
  {
    menuId: '583',
    name: 'Surface',
    selector: 'empower-surf-delivering-customers',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurfDeliveringCustomersComponent
  },
  {
    menuId: '584',
    name: 'Surface',
    selector: 'empower-surf-inter-stu-online-modules',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurfInterStuOnlineModulesComponent
  },
  {
    menuId: '585',
    name: 'Surface',
    selector: 'empower-surf-inter-stu-media-types',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurfInterStuMediaTypesComponent
  },
  {
    menuId: '586',
    name: 'Surface',
    selector: 'empower-surf-search-and-discoverability',
    displayName: 'Surface',
    module: 'EmpowerModule',
    component: EmpowerSurfSearchAndDiscoverabilityComponent
  },
  {
    menuId: '587',
    name: 'M365 Consumer',
    selector: 'empower-m365-inter-stu-media-types',
    displayName: 'M365 Consumer',
    module: 'EmpowerModule',
    component: EmpowerM365InterStuMediaTypesComponent
  },
  {
    menuId: '588',
    name: 'M365 Consumer',
    selector: 'empower-m365-panacea-att-motions',
    displayName: 'M365 Consumer',
    module: 'EmpowerModule',
    component: EmpowerM365PanaceaAttMotionsComponent
  },
  {
    menuId: '589',
    name: 'M365 Consumer',
    selector: 'empower-m365-consumer-jour-touchpoints',
    displayName: 'M365 Consumer',
    module: 'EmpowerModule',
    component: EmpowerM365ConsumerJourTouchpointsComponent
  },
  {
    menuId: '590',
    name: 'M365 Consumer',
    selector: 'empower-m365-interactive-studios-online-studios',
    displayName: 'M365 Consumer',
    module: 'EmpowerModule',
    component: M365InteractiveStudiosOnlineStudiosComponent
  },
  {
    menuId: '591',
    name: 'Empower Xbox',
    selector: 'empower-xbox-pitch-factory',
    displayName: 'Empower Xbox',
    module: 'EmpowerModule',
    component: XboxPitchFactoryComponent
  },
  {
    menuId: '592',
    name: 'Center Of Gaming',
    selector: 'empower-center-of-gaming',
    displayName: 'Center Of Gaming',
    module: 'EmpowerModule',
    component: EmpowerCenterOfGamingComponent
  }
  ,
  {
    menuId: '593',
    name: 'Our Mission Gaming',
    selector: 'empower-our-mission-gaming',
    displayName: 'Our Mission Gaming',
    module: 'EmpowerModule',
    component: EmpowerOurMissionGamingComponent
  }
  ,
  {
    menuId: '594',
    name: 'Digital Mix',
    selector: 'empower-digital-mix',
    displayName: 'Digital Mix',
    module: 'EmpowerModule',
    component: EmpowerDigitalMixComponent
  }
  ,
  {
    menuId: '595',
    name: 'Xbox Audience',
    selector: 'empower-xbox-audiance',
    displayName: 'Xbox Audiance',
    module: 'EmpowerModule',
    component: EmpowerXboxAudianceComponent
  }
  ,
  {
    menuId: '596',
    name: 'Our Audience Pc Gamers',
    selector: 'empower-our-audiance-pc-gamers',
    displayName: 'Our Audience Pc Gamers',
    module: 'EmpowerModule',
    component: EmpowerOurAudiancePcGamersComponent
  }
  ,
  {
    menuId: '597',
    name: 'Partner Benefits',
    selector: 'empower-partner-benefits',
    displayName: 'Partner Benefits',
    module: 'EmpowerModule',
    component: EmpowerPartnerBenefitsComponent
  }
  ,
  {
    menuId: '598',
    name: 'Xbox Series S',
    selector: 'empower-xbox-series-s',
    displayName: 'Xbox Series S',
    module: 'EmpowerModule',
    component: EmpowerXboxSeriesSComponent
  }
  ,
  {
    menuId: '599',
    name: 'Industry Leading Studios',
    selector: 'empower-industry-leading-studios',
    displayName: 'Industry Leading Studios',
    module: 'EmpowerModule',
    component: EmpowerIndustryLeadingStudiosComponent
  }
  ,
  {
    menuId: '600',
    name: 'Low Monthly Price',
    selector: 'empower-low-monthly-price',
    displayName: 'Low Monthly Price',
    module: 'EmpowerModule',
    component: EmpowerLowMonthlyPriceComponent
  }
  ,
  {
    menuId: '601',
    name: 'Xbox All Accessories',
    selector: 'empower-xbox-all-accessories',
    displayName: 'Xbox All Accessories',
    module: 'EmpowerModule',
    component: EmpowerXboxAllAccessoriesComponent
  }
  ,
  {
    menuId: '602',
    name: 'Xbox All Access',
    selector: 'empower-xbox-all-access',
    displayName: 'Xbox All Access',
    module: 'EmpowerModule',
    component: EmpowerXboxAllAccessComponent
  }
  ,
  {
    menuId: '603',
    name: 'Xis One',
    selector: 'empower-xis-one',
    displayName: 'Xis One',
    module: 'EmpowerModule',
    component: EmpowerXisOneComponent
  }
  ,
  {
    menuId: '604',
    name: 'Xis Two',
    selector: 'empower-xis-two',
    displayName: 'Xis Two',
    module: 'EmpowerModule',
    component: EmpowerXisTwoComponent
  }
  ,
  {
    menuId: '605',
    name: 'Experts Zone',
    selector: 'empower-experts-zone',
    displayName: 'Experts Zone',
    module: 'EmpowerModule',
    component: EmpowerExpertsZoneComponent
  }
  ,
  {
    menuId: '606',
    name: 'In Store Experiences',
    selector: 'empower-in-stored-experiences',
    displayName: 'In Store Experiences',
    module: 'EmpowerModule',
    component: EmpowerInStoredExperiencesComponent
  }
  ,
  {
    menuId: '607',
    name: 'Integrated Store Exerience',
    selector: 'empower-integrated-store-experience',
    displayName: 'Integrated Store Exerience',
    module: 'EmpowerModule',
    component: EmpowerIntegratedStoreExperienceComponent
  }
  ,
  {
    menuId: '608',
    name: 'Retail Demo Experienced',
    selector: 'empower-retail-demo-experienced',
    displayName: 'Retail Demo Experienced',
    module: 'EmpowerModule',
    component: EmpowerRetailDemoExperiencedComponent
  }
  ,
  {
    menuId: '609',
    name: 'Xbox Dashboard',
    selector: 'empower-xbox-dashboard',
    displayName: 'Xbox Dashboard',
    module: 'EmpowerModule',
    component: EmpowerXboxDashboardComponent
  }
  ,
  {
    menuId: '610',
    name: 'partner Performance In Cat Units',
    selector: 'empower-partner-performance-in-cat-units',
    displayName: 'partner Performance In Cat Units',
    module: 'EmpowerModule',
    component: EmpowerPartnerPerformanceInCatUnitsComponent
  }
  ,
  {
    menuId: '611',
    name: 'Partner Performance In Total Units',
    selector: 'empower-partner-performance-in-total-units',
    displayName: 'Partner Performance In Total Units',
    module: 'EmpowerModule',
    component: EmpowerPartnerPerformanceInTotalUnitsComponent
  }
  ,
  {
    menuId: '612',
    name: 'Partner Performance In Cat Revenue',
    selector: 'empower-partner-performance-in-cat-revenue',
    displayName: 'Partner Performance In Cat Revenue',
    module: 'EmpowerModule',
    component: EmpowerPartnerPerformanceInCatRevenueComponent
  }
  ,
  {
    menuId: '613',
    name: 'Partner Performance In Total Revenue',
    selector: 'empower-partner-performance-in-total-revenue',
    displayName: 'Partner Performance In Total Revenue',
    module: 'EmpowerModule',
    component: EmpowerPartnerPerformanceInTotalRevenueComponent
  }
  ,
  {
    menuId: '614',
    name: 'Xbox Customer Vs Market',
    selector: 'empower-xbox-customer-vs-market',
    displayName: 'Xbox Customer Vs Market',
    module: 'EmpowerModule',
    component: EmpowerXboxCustomerVsMarketComponent
  }
  ,
  {
    menuId: '615',
    name: 'Accessories Attach',
    selector: 'empower-accessories-attach',
    displayName: 'Accessories Attach',
    module: 'EmpowerModule',
    component: EmpowerAccessoriesAttachComponent
  }
  ,
  {
    menuId: '616',
    name: 'Console Premium Mix',
    selector: 'empower-console-premium-mix',
    displayName: 'Console Premium Mix',
    module: 'EmpowerModule',
    component: EmpowerConsolePremiumMixComponent
  }
  ,
  {
    menuId: '617',
    name: 'Game Pass Mix',
    selector: 'empower-game-pass-mix',
    displayName: 'Game Pass Mix',
    module: 'EmpowerModule',
    component: EmpowerGamePassMixComponent
  },
  {
    menuId: '618',
    name: 'Empower Insights Dashbaord',
    selector: 'empower-insights-dashboard',
    displayName: 'Empower Insights Dashbaord',
    module: 'EmpowerModule',
    component: EmpowerInsightsDashboardComponent
  },
  {
    menuId: '619',
    name: 'Laptop Share',
    selector: 'empower-sur-share-part-vs-mark-within',
    displayName: 'Laptop Share',
    module: 'EmpowerModule',
    component: EmpowerSurSharePartVsMarkWithinComponent
  },
  {
    menuId: '620',
    name: 'Computing',
    selector: 'empower-product-performance-forecast',
    displayName: 'Computing',
    module: 'EmpowerModule',
    component: EmpowerProductPerformanceForecastComponent
  },
  {
    menuId: '621',
    name: 'Computing',
    selector: 'empower-in-store-exp-improvement',
    displayName: 'Computing',
    module: 'EmpowerModule',
    component: EmpowerInStoreExpImprovementComponent
  },
  {
    menuId: '622',
    name: 'Computing',
    selector: 'empower-fixture-performance-by-week',
    displayName: 'Computing',
    module: 'EmpowerModule',
    component: EmpowerFixturePerformanceByWeekComponent
  },
  {
    menuId: '623',
    name: 'Computing',
    selector: 'empower-inventory-performance-at-product-level',
    displayName: 'Computing',
    module: 'EmpowerModule',
    component: EmpowerInventoryPerformanceAtProductLevelComponent
  },
  {
    menuId: '624',
    name: 'Computing',
    selector: 'empower-online-experience-improvement',
    displayName: 'Computing',
    module: 'EmpowerModule',
    component: EmpowerOnlineExperienceImprovementComponent
  },
  {
    menuId: '625',
    name: 'Computing',
    selector: 'emower-product-performing-with-ad-spend',
    displayName: 'Computing',
    module: 'EmpowerModule',
    component: EmowerProductPerformingWithAdSpendComponent
  },
  {
    menuId: '626',
    name: 'Computing',
    selector: 'empower-product-experience-improvement',
    displayName: 'Computing',
    module: 'EmpowerModule',
    component: EmpowerProductExperienceImprovementComponent
  },
  {
    menuId: '627',
    name: 'Computing',
    selector: 'empower-new-launch-product-performance',
    displayName: 'Computing',
    module: 'EmpowerModule',
    component: EmpowerNewLaunchProductPerformanceComponent
  },
  {
    menuId: '628',
    name: 'Empower Dynamic',
    selector: 'empower-dynamic-component',
    displayName: 'Empower Dynamic',
    module: 'EmpowerModule',
    component: EmpowerDynamicComponent
  },
  {
     menuId: '545',
    name: 'Dashboard',
    selector: 'pms-dashboard',
    displayName: 'Dashboard',
    module: 'ProcurementModule',
    component: PmsDashboardComponent
  },
  {
    menuId: '546',
    name: 'Items',
    selector: 'pms-items',
    displayName: 'Items',
    module: 'ProcurementModule',
    component: PmsItemsComponent
  },
  {
    menuId: '547',
    name: 'Locations',
    selector: 'pms-locations',
    displayName: 'Locations',
    module: 'ProcurementModule',
    component: PmsLocationsComponent
  },
  {
    menuId: '548',
    name: 'Vendors',
    selector: 'pms-vendors',
    displayName: 'Vendors',
    module: 'ProcurementModule',
    component: PmsVendorsComponent
  },

  {
    menuId: '550',
    name: 'View Purchase Requests',
    selector: 'pms-view-purchase-request',
    displayName: 'View Purchase Requests',
    module: 'ProcurementModule',
    component: PmsViewPurchaseRequestComponent
  },
  {
    menuId: '551',
    name: 'Bids',
    selector: 'pms-bids',
    displayName: 'Bids',
    module: 'ProcurementModule',
    component: PmsBidsComponent
  },
  {
    menuId: '552',
    name: 'Purchase Orders',
    selector: 'pms-purchase-orders',
    displayName: 'Purchase Orders',
    module: 'ProcurementModule',
    component: PmsPurchaseOrdersComponent
  },
  {
    menuId: '553',
    name: 'GRN',
    selector: 'pms-grn',
    displayName: 'GRN',
    module: 'ProcurementModule',
    component: PmsGrnComponent
  },
  {
    menuId: '603',
    name: 'Vendor Evaluation',
    selector: 'pms-evaluation-vendor',
    displayName: 'Vendor Evaluation',
    module: 'ProcurementModule',
    component: PmsEvaluationVendorComponent
  },
];
export const MenuList = Pages;
