import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-page-footer',
  templateUrl: './report-page-footer.component.html',
  styleUrls: ['./report-page-footer.component.scss']
})
export class ReportPageFooterComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit() { }


}
