import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { generateTitleDiv, NO_IMAGE, undefinedImagePath } from 'src/modules/empower-module/helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'empower-pca-ranking-vs-market',
  templateUrl: './pca-ranking-vs-market.component.html',
  styleUrls: ['./pca-ranking-vs-market.component.scss']
})
export class EmpowerPcaRankingVsMarketComponent implements OnInit {
  @Input() data?: any;
  _unsubscribeAll = new Subject();
  undefinedImagePath = '';

  partnerUnitList: any = [];
  marketUnitList: any = [];
  maxPartnerUnitValue: number = 0;
  maxMarketUnitValue: number = 0;
  innerHtml = '';
  pageComment: string;

  constructor(
    public empowerModernLifeService: EmpowerModernLifeService, private empowerExportService: EmpowerExportService,
    private spinner: NgxSpinnerService,
  ) {
  }

  get totalPartnerUnitList(): number {
    return _.sum(_.map(this.partnerUnitList, a => a['[STLicsQty]']));
  }

  get totalMarketUnitList(): number {
    return _.sum(_.map(this.marketUnitList, a => a['[STLicsQty___Comp]']));
  }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);

      this.innerHtml = generateTitleDiv(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const daxReponse = this.empowerModernLifeService.getPagesState(pageId);
    this.pageComment = this.empowerModernLifeService.getPageCommentState(pageId);
    if (daxReponse && daxReponse.length) {
      this.loadVisuals(daxReponse);
    } else {
      this.executePageDaxQuery();
    }
  }

  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.empowerModernLifeService.getDashboardState()
    };
    this.spinner.show();
    this.empowerModernLifeService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        this.emitExportEvent(true);
      }
      this.spinner.hide();
    },
      err => {
        this.undefinedImagePath = undefinedImagePath;
        this.spinner.hide();
      });
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }

  setPageState(pageId, daxData) {
    this.empowerModernLifeService.setPagesState(pageId, daxData);
  }

  loadVisuals(daxData) {
    this.loadFirstVisual(daxData[0]);
    this.loadSecondVisual(daxData[1]);
    this.emitExportEvent(true);
  }

  loadFirstVisual(visualData) {
    this.maxPartnerUnitValue = _.get(_.maxBy(visualData.daxResponse, a => a['[STLicsQty]']), ['[STLicsQty]'], 0);
    this.partnerUnitList = _.map(_.orderBy(visualData.daxResponse, ['[STLicsQty]'], ['desc']), a => {
      return { ...a, 'dIM_PRODUCT_O2[PRODUCT_FAMILY]': _.isObject(a['dIM_PRODUCT_O2[PRODUCT_FAMILY]']) ? '' : a['dIM_PRODUCT_O2[PRODUCT_FAMILY]'] }
    });
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(43, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

  loadSecondVisual(visualData) {
    this.maxMarketUnitValue = _.get(_.maxBy(visualData.daxResponse, a => a['[STLicsQty___Comp]']), ['[STLicsQty___Comp]'], 0);
    this.marketUnitList = _.map(_.orderBy(visualData.daxResponse, ['[STLicsQty___Comp]'], ['desc']), a => {
      return { ...a, 'dIM_PRODUCT_O2[PRODUCT_FAMILY]': _.isObject(a['dIM_PRODUCT_O2[PRODUCT_FAMILY]']) ? '' : a['dIM_PRODUCT_O2[PRODUCT_FAMILY]'] };
    });
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.empowerModernLifeService.setPageCommentState(pageId, comment);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
