import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from 'src/modules/layout/features/404/error404.component';
import { AppMasterGuard } from 'src/guards/app-master.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../modules/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    loadChildren: () => import('../modules/layout/layout.module').then(m => m.LayoutModule), canActivate: [AppMasterGuard]
  },
  { path: '404', component: Error404Component },
  { path: '**', component: Error404Component },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes,
    { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
