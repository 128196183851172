import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';

@Component({
  selector: 'seasonality-edit-cell-renderer',
  template: `<a class="product-title-link" [title]="params.value" (click)="btnEditClickHandler()">{{params.value}}</a>`,
})
export class SeasonalityEditRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private seasonalityService: SeasonalityService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnEditClickHandler() {
    this.seasonalityService.setSeasonalityGridActionCellSubject({ seasonalityData: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
