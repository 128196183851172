import { Component, ElementRef, ViewChild } from '@angular/core';
import { _ } from 'ag-grid-community';
import { OutlierService } from 'src/services/forecast-services/outlier-service';
//import {ILoadingOverlayComponentAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-outlier-custom-header-group',
  template: `
         <div class="unadjusted-column-group">
          <span class="ag-header-group-text">{{this.params.displayName}}</span>
          <button *ngIf="showCommitButton()" mat-raised-button color="accent" (click)="commitColumn($event)">Commit</button>
        </div>
   `

})

/*https://www.ag-grid.com/javascript-grid-header-rendering/#header-group-component */
export class OutlierCustomHeaderGroup {
  params: any;
  private expandState: string;

  constructor(public outlierService: OutlierService) {
  }

  agInit(params): void {
    this.params = params;

    this.params.columnGroup.getOriginalColumnGroup().addEventListener('expandedChanged', this.syncExpandButtons.bind(this));

    this.syncExpandButtons();
  }

  showCommitButton() {
    return _.get(this.params, 'columnGroup.children.0.colDef.showCommitButton', false);
  }

  expandOrCollapse() {
    let currentState = this.params.columnGroup.getOriginalColumnGroup().isExpanded();
    this.params.setExpanded(!currentState);
  }

  commitColumn(params) {
    const field = _.get(this.params, 'columnGroup.children.0.colDef.field', '');
    this.outlierService.setWeekCommitActionSubject(field);
  }

  syncExpandButtons() {
    if (this.params.columnGroup.getOriginalColumnGroup().isExpanded()) {
      this.expandState = 'expanded';
    } else {
      this.expandState = 'collapsed';
    }
  }
}
