import {
  Component,
  Inject,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TreeviewItem } from 'ngx-treeview';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/models/User';
import { ConfigurationService } from 'src/services/configuration.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { CLIENT_INFO, USER_INFO } from 'src/common/keys';
import { LocalstorageService } from 'src/services/localstorage.service';
@Component({
  selector: 'app-create-update-role',
  templateUrl: './create-update-role.component.html',
  styleUrls: ['./create-update-role.component.scss'],
})
export class CreateUpdateRoleComponent implements OnInit, OnChanges {
  public registerForm: FormGroup;
  public isPhoneNumberValid = true;
  public submitted = false;
  public phoneNumberRegex = /^([0-9]){9}$/;
  public emailMaxChars = 50;
  public id: number;
  public isEdit = false;
  public loading = false;
  public selectedRole: any;
  public roleList = [];
  public RoleClaims: any;
  public isRolsCalims: boolean;
  public oneAtATime: boolean = false;
  @Input() data;
  @Output() updateList = new EventEmitter();
  @Output() close = new EventEmitter();
  usersList = [];
  usersListUI = [];
  productFlatList = [];
  productList = [];
  showProductList: boolean;
  channelDropdownList: any[];
  channelSettingList: any;
  accountList: any;
  productSettingList: any;
  itemsList: any;
  itemIds: any = [];
  channelIds: any = [];
  claimGroupList: any = [];
  selectedClaimGroupList: any = [];
  seletedGroupIds: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public userService: UsersService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    public configurationService: ConfigurationService,
    public storage: LocalstorageService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {}
  public GetAccountSettingList = (forecast?: any) => {
    this.configurationService
      .AccountSettingMappingGetList({})
      .subscribe((res) => {
        this.channelSettingList = res;
        this.createChannelDropdownList(res, forecast);
      });
  };
  public createChannelDropdownList = (accoutSettingList, forecast) => {
    const accoutSettingListTemp = accoutSettingList;
    const channelIds = [];
    accoutSettingList.forEach((account) => {
      if (channelIds.includes(account.channelId)) {
      } else {
        channelIds.push(account.channelId);
      }
    });
    let treeList = [];
    channelIds.forEach((channel) => {
      let channelObject = {
        channelName: accoutSettingList.find((acc) => acc.channelId === channel)
          ? accoutSettingList.find((acc) => acc.channelId === channel)
              .channelName
          : '',
        name: accoutSettingList.find((acc) => acc.channelId === channel)
          ? accoutSettingList.find((acc) => acc.channelId === channel)
              .channelName
          : '',
        channelId: channel,
        children: [],
        isChannel: true,
        isDelete: true,
      };
      const regionIds = accoutSettingListTemp
        .filter((el) => el.channelId === channel)
        .map((el) => el.continentId)
        .filter((value, index, self) => self.indexOf(value) === index);
      regionIds.forEach((regionId) => {
        let regionObject = {
          continentName: accoutSettingList.find(
            (acc) => acc.continentId === regionId
          )
            ? accoutSettingList.find((acc) => acc.continentId === regionId)
                .continentName
            : '',
          name: accoutSettingList.find((acc) => acc.continentId === regionId)
            ? accoutSettingList.find((acc) => acc.continentId === regionId)
                .continentName
            : '',
          continentId: regionId,
          channelId: channel,
          children: [],
          isRegion: true,
          isDelete: true,
        };
        const countryIds = accoutSettingListTemp
          .filter(
            (el) => el.continentId === regionId && el.channelId === channel
          )
          .map((el) => el.countryId)
          .filter((value, index, self) => self.indexOf(value) === index);
        countryIds.forEach((countryId) => {
          let countryObject = {
            countryName: accoutSettingList.find(
              (acc) => acc.countryId === countryId
            )
              ? accoutSettingList.find((acc) => acc.countryId === countryId)
                  .countryName
              : '',
            name: accoutSettingList.find((acc) => acc.countryId === countryId)
              ? accoutSettingList.find((acc) => acc.countryId === countryId)
                  .countryName
              : '',
            countryId: countryId,
            continentId: regionId,
            channelId: channel,
            children: [],
            isCountry: true,
            isDelete: true,
          };
          const distributorIds = accoutSettingListTemp
            .filter(
              (el) =>
                el.continentId === regionId &&
                el.channelId === channel &&
                el.countryId === countryId
            )
            .map((el) => el.distributorId)
            .filter((value, index, self) => self.indexOf(value) === index);
          distributorIds.forEach((distributor) => {
            let accounts = this.accountList.filter(
              (account) =>
                account.countryId === countryId &&
                account.regionId === regionId &&
                account.channelId === channel &&
                account.distributorId === distributor
            );
            accounts = accounts.map((element) => ({
              name: element.accountName,
              isAccount: true,
              ...element,
            }));
            const distributorObject = {
              distributorName: accoutSettingList.find(
                (acc) => acc.distributorId === distributor
              )
                ? accoutSettingList.find(
                    (acc) => acc.distributorId === distributor
                  ).distributorName
                : '',
              name: accoutSettingList.find(
                (acc) => acc.distributorId === distributor
              )
                ? accoutSettingList.find(
                    (acc) => acc.distributorId === distributor
                  ).distributorName
                : '',
              countryId: countryId,
              continentId: regionId,
              channelId: channel,
              distributorId: distributor,
              children: accounts,
              isDistributor: true,
              isDelete: true,
            };
            if (distributorObject.distributorId) {
              countryObject.children.push(distributorObject);
            }
            if (distributorObject.children.length > 0) {
              channelObject.isDelete = false;
              regionObject.isDelete = false;
              countryObject.isDelete = false;
              distributorObject.isDelete = false;
            }
          });
          if (countryObject.countryId) {
            regionObject.children.push(countryObject);
          }
        });
        if (regionObject.continentId) {
          channelObject.children.push(regionObject);
        }
      });
      treeList.push(channelObject);
    });

    const tree = [];
    treeList.forEach((channel) => {
      let channelObject = new TreeviewItem({
        text: channel.name,
        value: channel.channelId + 'CH',
        checked:
          forecast && forecast.channelIds
            ? forecast.channelIds.includes(
                (channel.channelId + 'CH').toString()
              )
            : false,
        children: channel.children.map((region) => {
          return new TreeviewItem({
            text: region.name,
            value: region.continentId + 'R',
            checked:
              forecast && forecast.channelIds
                ? forecast.channelIds.includes(
                    (region.continentId + 'R').toString()
                  )
                : false,
            children: region.children.map((country) => {
              return new TreeviewItem({
                text: country.name,
                value: country.countryId + 'C',
                checked:
                  forecast && forecast.channelIds
                    ? forecast.channelIds.includes(
                        (country.countryId + 'C').toString()
                      )
                    : false,
                children: country.children.map((distributor) => {
                  return new TreeviewItem({
                    text: distributor.name,
                    value: distributor.distributorId,
                    checked:
                      forecast && forecast.channelIds
                        ? forecast.channelIds.includes(
                            (distributor.distributorId + 'D').toString()
                          )
                        : false,
                    children: distributor.children.map((account) => {
                      return new TreeviewItem({
                        text: account.name,
                        value: account.itemAccountId,
                        checked:
                          forecast && forecast.channelIds
                            ? forecast.channelIds.includes(
                                account.itemAccountId.toString()
                              )
                            : false,
                      });
                    }),
                  });
                }),
              });
            }),
          });
        }),
      });
      tree.push(channelObject);
    });
    this.channelDropdownList = tree;
  };
  public GetProductSettingList = (forecast?: any) => {
    this.configurationService
      .ProductSettingMappingGetList({})
      .subscribe((res) => {
        this.productSettingList = res;
        this.createProductDropdownList(res, forecast);
      });
  };
  public createProductDropdownList = (productSettingList, forecast) => {
    const brandIds = [];
    productSettingList.forEach((account) => {
      if (brandIds.includes(account.brandId)) {
      } else {
        brandIds.push(account.brandId);
      }
    });
    let treeList = [];
    brandIds.forEach((brand) => {
      let brandObject = {
        brandName: productSettingList.find((acc) => acc.brandId === brand)
          ? productSettingList.find((acc) => acc.brandId === brand).brandName
          : '',
        name: productSettingList.find((acc) => acc.brandId === brand)
          ? productSettingList.find((acc) => acc.brandId === brand).brandName
          : '',
        brandId: brand,
        children: [],
        isBrand: true,
      };
      const productGroupIds = productSettingList
        .filter((el) => el.brandId === brand)
        .map((el) => el.productGroupId)
        .filter((value, index, self) => self.indexOf(value) === index);
      productGroupIds.forEach((productGroupId) => {
        let productGroupObject = {
          productGroupName: productSettingList.find(
            (acc) => acc.productGroupId === productGroupId
          )
            ? productSettingList.find(
                (acc) => acc.productGroupId === productGroupId
              ).productGroupName
            : '',
          name: productSettingList.find(
            (acc) => acc.productGroupId === productGroupId
          )
            ? productSettingList.find(
                (acc) => acc.productGroupId === productGroupId
              ).productGroupName
            : '',
          productGroupId: productGroupId,
          brandId: brand,
          children: [],
          isProductGroup: true,
        };
        const productFamilyIds = productSettingList
          .filter(
            (el) => el.productGroupId === productGroupId && el.brandId === brand
          )
          .map((el) => el.productFamilyId)
          .filter((value, index, self) => self.indexOf(value) === index);
        productFamilyIds.forEach((productFamilyId) => {
          let productFamilyObject = {
            productFamilyName: productSettingList.find(
              (acc) => acc.productFamilyId === productFamilyId
            )
              ? productSettingList.find(
                  (acc) => acc.productFamilyId === productFamilyId
                ).productFamilyName
              : '',
            name: productSettingList.find(
              (acc) => acc.productFamilyId === productFamilyId
            )
              ? productSettingList.find(
                  (acc) => acc.productFamilyId === productFamilyId
                ).productFamilyName
              : '',
            productFamilyId: productFamilyId,
            productGroupId: productGroupId,
            brandId: brand,
            children: [],
            isProductFamily: true,
          };
          const parentProductIds = productSettingList
            .filter(
              (el) =>
                el.productFamilyId === productFamilyId &&
                el.productGroupId === productGroupId &&
                el.brandId === brand
            )
            .map((el) => el.parentProductId)
            .filter((value, index, self) => self.indexOf(value) === index);
          parentProductIds.forEach((parentProductId) => {
            let products = this.itemsList.filter(
              (row) =>
                row.parentProductId === parentProductId &&
                row.productFamilyId === productFamilyId &&
                row.productGroupId === productGroupId &&
                row.brandId === brand
            );
            products = products.map((row) => ({
              name: row.description,
              isProduct: true,
              ...row,
            }));
            let parentProductObject = {
              parentProductName: productSettingList.find(
                (acc) => acc.parentProductId === parentProductId
              )
                ? productSettingList.find(
                    (acc) => acc.parentProductId === parentProductId
                  ).parentProductName
                : '',
              name: productSettingList.find(
                (acc) => acc.parentProductId === parentProductId
              )
                ? productSettingList.find(
                    (acc) => acc.parentProductId === parentProductId
                  ).parentProductName
                : '',
              parentProductId: parentProductId,
              productGroupId: productGroupId,
              brandId: brand,
              children: products,
              isParentProduct: true,
            };
            if (parentProductObject.parentProductId) {
              productFamilyObject.children.push(parentProductObject);
            }
          });
          if (productFamilyObject.productFamilyId) {
            productGroupObject.children.push(productFamilyObject);
          }
        });
        if (productGroupObject.productGroupId) {
          brandObject.children.push(productGroupObject);
        }
      });
      treeList.push(brandObject);
    });
    const tree = [];
    treeList.forEach((brand) => {
      let brandObject = new TreeviewItem({
        text: brand.name,
        value: brand.brandId,
        children: brand.children.map((productGroup) => {
          return new TreeviewItem({
            text: productGroup.name,
            value: productGroup.productGroupId,
            children: productGroup.children.map((productFamily) => {
              return new TreeviewItem({
                text: productFamily.name,
                value: productFamily.productFamilyId,
                children: productFamily.children.map((parentProduct) => {
                  return new TreeviewItem({
                    text: parentProduct.name,
                    value: parentProduct.parentProductId,
                    children: parentProduct.children.map((product) => {
                      return new TreeviewItem({
                        text: product.name,
                        value: product.itemManagerId,
                        children: [],
                        checked:
                          forecast && forecast.itemIds
                            ? forecast.itemIds.includes(
                                product.itemManagerId.toString()
                              )
                            : false,
                      });
                    }),
                    checked:
                      forecast && forecast.itemIds
                        ? forecast.itemIds.includes(
                            parentProduct.parentProductId.toString()
                          )
                        : false,
                  });
                }),
                checked:
                  forecast && forecast.itemIds
                    ? forecast.itemIds.includes(
                        productFamily.productFamilyId.toString()
                      )
                    : false,
              });
            }),
            checked:
              forecast && forecast.itemIds
                ? forecast.itemIds.includes(
                    productGroup.productGroupId.toString()
                  )
                : false,
          });
        }),
        checked:
          forecast && forecast.itemIds
            ? forecast.itemIds.includes(brand.brandId.toString())
            : false,
      });

      tree.push(brandObject);
    });
    this.productList = tree;
  };
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm;
  }
  ngOnInit() {
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true,
    };

    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${
      params.pageSize ? params.pageSize : 10
    }&active=true`;
    this.configurationService.ItemAccountGetAll(url).subscribe((res) => {
      this.accountList = res.data || [];
      this.GetAccountSettingList();
    });
    this.configurationService.ItemManagerGetAll(url).subscribe((res) => {
      this.itemsList = res.data || [];
      this.GetProductSettingList();
    });
    this.initializeDropDowns();
    this.initializeForm();
    this.data = this.configurationService.roleModel;
    this.data = this.data ? JSON.parse(this.data) : null;
    if (this.data && this.data.rowData && this.data.rowData.roleId) {
      this.id = this.data.rowData.roleId;
      this.isEdit = true;
      this.isRolsCalims = true;
      this.getSelectedRole(this.id.toString());
    }
  }
  public getParams() {
    this.route.queryParams.subscribe((params) => {
      if (params && params.id) {
        this.id = Number(atob(params.id));
        if (this.id) {
          this.isEdit = true;
          this.isRolsCalims = true;
          this.getSelectedRole(this.id.toString());
        }
      }
    });
  }
  public getSelectedRole = (id: string) => {
    this.userService.GetRoleById(id).subscribe((res) => {
      if (res) {
        this.selectedRole = res;
        this.populateForm(this.selectedRole);
        this.GetUserClaims(id);
      }
    });
  };
  GetUserClaims(roleId) {
    this.userService.getClaimsByRole(roleId).subscribe(
      (data: any) => {
        this.RoleClaims = data;
        this.seletedGroupIds = data.claimGroups.map(
          (group) => group.claimGroupId
        );
      },
      (error) => {
        // console.log(error);
      }
    );
  }
  public populateForm = (user: User) => {
    Object.keys(this.f.controls).forEach((key) => {
      if (key) {
        this.f.controls[key].setValue(user[key]);
      }
    });
  };
  public initializeDropDowns = async () => {
    const userRespone = await this.userService
      .GetAllUsers({ userTypeId: 1 })
      .toPromise()
      .catch((error) => throwError(error));
    if (userRespone && userRespone.data) {
      this.usersList = userRespone.data;
      this.usersListUI = JSON.parse(JSON.stringify(this.usersList));
    }
    this.getProductList();
    this.userService.GetUserRolesList().subscribe((res: any) => {
      this.roleList = res;
    });
    this.userService.getAllClaimGroups().subscribe((res: any) => {
      this.claimGroupList = res.data;
    });
  };
  initializeForm() {
    const roleName = `${this.storage.get(CLIENT_INFO).clientName}-`;
    this.registerForm = this.formBuilder.group({
      roleId: [0],
      roleName: [roleName, Validators.required],
      active: [true],
      applicationUsers: [],
      users: [],
    });
  }
  onSelectionOfGroups(event) {
    this.selectedClaimGroupList = this.claimGroupList.filter(
      ({ claimGroupId }) => this.seletedGroupIds.includes(claimGroupId)
    );
  }
  setValue = (selectedDropdown) => {
    switch (selectedDropdown.type) {
      case 'Item': {
        this.itemIds = selectedDropdown.value;
        break;
      }
      case 'Channel': {
        this.channelIds = selectedDropdown.value;
        break;
      }
    }
    this.channelIds =
      this.channelIds.filter((el) => typeof el === 'number') || [];
    this.itemIds = this.itemIds.filter((el) => typeof el === 'number') || [];
  };
  removeUser = (user) => {
    let value = this.f.controls.applicationUsers.value;
    value = value.filter((u) => u.userId !== user.userId);
    this.f.controls.applicationUsers.setValue(value);
  };
  addUserToList = () => {
    if (this.f.controls.users.value) {
      const selectedUser = this.f.controls.users.value;
      const value = this.f.controls.applicationUsers.value
        ? this.f.controls.applicationUsers.value
        : [];
      value.push(selectedUser);
      const ids = value.map((el) => el.userId);
      this.f.controls.applicationUsers.setValue(value);
      this.usersList = this.usersListUI.filter(
        (row) => !ids.includes(row.userId)
      );
    }
  };
  Cancel_Click() {
    this.router.navigate(['/Users']);
  }
  onSubmit(errorTemplate, errorRoleCodeTemplate) {
    this.submitted = true;
    if (this.f.valid) {
      if (this.checkIfAlreadyExists(this.f.value)) {
        this.dialogRef = this.dialog.open(errorTemplate);
        return;
      }

      this.loading = true;

      let model = {
        ...this.f.value,
        claimGroups: this.selectedClaimGroupList,
        // claimGroups: this.RoleClaims ? this.RoleClaims.claimGroups : [],
      };
      this.spinner.show();
      if (this.isEdit) {
        model = {
          ...model,
          claimGroups: this.selectedClaimGroupList,
        };
        this.userService.UpdateRole(model).subscribe(
          (res) => {
            this.spinner.hide();
            this.initializeDropDowns();
            this.toastr.success('', 'Role updated successfully');
            // this.router.navigate(['/Roles']);
            this.loading = false;
            // this.isRolsCalims = false;
            // this.initializeForm();

            this.userService.listUpdate.next(true);
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              'Error',
              'Something went wrong role not updated successfully'
            );
            this.loading = false;
          }
        );
      } else {
        this.userService.CreateRole(model).subscribe(
          (res) => {
            this.spinner.hide();
            this.initializeDropDowns();
            this.toastr.success('', 'Role Created successfully');
            this.initializeForm();

            this.userService.listUpdate.next(true);
            this.loading = false;
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(
              'Error',
              'Something went wrong role not created successfully'
            );
            this.loading = false;
          }
        );
      }
    }
  }

  checkIfAlreadyExists(role) {
    const list = this.isEdit
      ? this.roleList.filter(
          (row) => row.roleName !== this.selectedRole.roleName
        )
      : this.roleList;
    return list.find(
      (row) => row.roleName.toLowerCase() === role.roleName.toLowerCase()
    );
  }
  public getProductList = (forecast?: any) => {
    let url = `offSet=${0}&pageSize=${99999}&active=true`;
    this.configurationService.ItemManagerGetAll(url).subscribe((res) => {
      this.productFlatList = res.data;
      this.productList = res;
      const productGroupIds = [];
      this.productFlatList.forEach((product) => {
        if (productGroupIds.includes(product.productGroupId)) {
        } else {
          productGroupIds.push(product.productGroupId);
        }
      });
      let treeList = [];
      // const uniqueFamilyIds = this.productFlatList.filter(el => productGroupIds.includes(el.productGroupId)).map(el => el.productFamilyId).filter((value, index, self) => self.indexOf(value) === index);
      productGroupIds.forEach((group) => {
        let treeObject = {
          text: 'Group',
          value: group,
          children: [],
        };
        const familyIds = this.productFlatList
          .filter((el) => el.productGroupId === group)
          .map((el) => el.productFamilyId)
          .filter((value, index, self) => self.indexOf(value) === index);
        familyIds.forEach((familyId) => {
          let familyObject = {
            text: 'Family',
            value: familyId,
            children: [],
          };
          const parentProductIds = this.productFlatList
            .filter(
              (el) =>
                el.productFamilyId === familyId && el.productGroupId === group
            )
            .map((el) => el.parentProductId)
            .filter((value, index, self) => self.indexOf(value) === index);
          parentProductIds.forEach((productId) => {
            let productObject = {
              text: 'ParentProduct',
              value: productId,
              children: [],
            };
            const actualItems = this.productFlatList.filter(
              (el) =>
                el.productFamilyId === familyId &&
                el.productGroupId === group &&
                el.parentProductId === productId
            );
            actualItems.forEach((item) => {
              let itemObject = {
                text: item.description,
                value: item.itemManagerId,
                children: [],
              };
              productObject.children.push(itemObject);
            });
            familyObject.children.push(productObject);
          });

          treeObject.children.push(familyObject);
        });
        treeList.push(treeObject);
      });
      const treeView = [];
      treeList.forEach((group) => {
        group.children.forEach((family) => {
          family.children.forEach((parent) => {
            parent.children.forEach((item) => {
              item = new TreeviewItem({
                text: item.text,
                value: item.value,
                children: item.children,
              });
            });
            parent = new TreeviewItem({
              text: parent.text,
              value: parent.value,
              children: parent.childrent,
            });
          });
          family = new TreeviewItem({
            text: family.text,
            value: family.value,
            children: family.children,
          });
        });
        group = new TreeviewItem({
          text: group.text,
          value: group.value,
          children: group.children,
        });
        treeView.push(group);
      });

      this.productList = treeView;
      this.showProductList = true;
    });
  };
  public mapProductList(data, forecast?: any) {
    const idMapping = data.reduce((acc, el, i) => {
      acc[el.itemId] = i;
      return acc;
    }, {});
    let root;
    data.forEach((el) => {
      // Handle the root element
      if (el.parentId === 0) {
        root = el;
        return;
      }
      // Use our mapping to locate the parent element in our data array
      const parentEl = data[idMapping[el.parentId]];
      // Add our current el to its parent's `children` array
      parentEl.children = [...(parentEl.children || []), el];
    });
    data = data.filter((row) => !row.parentId);
    this.productList = this.convertToTree(data, forecast);
    this.showProductList = true;
  }
  public convertToTree = (data, forecast?: any): TreeviewItem[] => {
    let list = [];
    data.forEach((row) => {
      let obj = new TreeviewItem({
        text: row.itemName,
        value: row.itemId,
        checked:
          forecast && forecast.itemIds
            ? forecast.itemIds.includes(row.itemId.toString())
            : false,
      });
      if (row.children && row.children.length > 0) {
        obj.children = this.convertToTree(row.children, forecast);
      }
      list.push(obj);
    });
    return list;
  };
}
