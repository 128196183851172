<div class="journey-modal-header">
  <h2>Journey</h2>
</div>
<mat-tab-group class="journey-view-dialog">
  <mat-tab label="My Journey">
    <app-my-journey></app-my-journey>
  </mat-tab>
  <mat-tab label="Shared with me">
    <app-journey-shared-with-me></app-journey-shared-with-me>
  </mat-tab>
  <mat-tab label="Logs">
    <journey-logs></journey-logs>
  </mat-tab>
</mat-tab-group>