import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  public columnDefs = [
    {
      headerName: 'User',
      field: 'userName',
      minWidth: 50,
      resizable: true
    },
    {
      headerName: 'User Activity',
      field: 'userActivityDescription',
      minWidth: 400,
      resizable: true
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      minWidth: 50,
      resizable: true
    }
  ];
  @Input() isMyActivity: any;
  public gridOptions: any;
  public info: string;
  private gridApi: any;
  public userList = [];

  public user: any;
  public allActivityList = [];
  public userActivityList = [];
  constructor(public router: Router, public configService: ConfigurationService, public storage: LocalstorageService) {
    this.gridOptions = {
      frameworkComponents: {
      },
      pagination: true,
      paginationAutoPageSize: true,
    };
  }
  ngOnInit() {
    this.getActivityList();
  }
  public getActivityList = () => {
    const userId = this.storage.get(USER_ID);
    this.configService.GetAllActivity({}).subscribe(res => {
      this.allActivityList = res;
    });
    this.configService.GetMyActivity({ userId }).subscribe(res => {
      this.userActivityList = res;
    });
  }
  public getRoleNames = (roleList: any) => {
    if (roleList && roleList.length > 0) {
      let names = '';
      roleList.forEach(element => {
        names = `${names}, ${element.roleName}`;
      });
      names = names.split(',').splice(1).join(',');
      return names || '';
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  onCellClicked($event) {
    this.router.navigate(['addUser'], {
      queryParams: {
        id: btoa($event.data.userId)
      }
    });
  }
  public navigateToRoles = () => {
    this.router.navigate(['Roles']);
  }
}
