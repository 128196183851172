import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ItemHierarchyManagerService } from 'src/services/setup-services/item-hierarchy-manager.service';
import * as _ from 'lodash';
import { of, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-item-hierarchy-manager-editor',
  templateUrl: './item-hierarchy-manager-editor.component.html',
  styleUrls: ['./item-hierarchy-manager-editor.component.scss']
})
export class ItemHierarchyManagerEditorComponent implements OnInit {

  isEdit: boolean = false;
  itemHierarchyManagerLevel: any = [];
  hierarchyValuesObject: any = {};

  savedHierarchyValueList: any = [];

  filteredLookupItems: Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ItemHierarchyManagerEditorComponent>,
    public spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public toastr: NgxToasterService,
    private itemHierarchyManagerService: ItemHierarchyManagerService,
  ) { }

  ngOnInit(): void {
    this.initControlValues();
  }

  initControlValues() {
    this.isEdit = this.data.isEdit;
    this.itemHierarchyManagerLevel = this.data.itemHierarchyManagerLevel;
    this.savedHierarchyValueList = this.createHierarchyValueList(this.data.gridData);
    if (this.isEdit) {
      this.initEditModeList(this.data.rowData);
    }
  }

  initEditModeList(rowData) {
    const updatedList: any = [];
    this.itemHierarchyManagerLevel.forEach((item: any) => {
      updatedList.push({
        ...item,
        productHierarchyId: rowData[`h${item.productHierarchyLevel}`],
        productParentId: rowData[`h${item.productHierarchyLevel}ParentId`],
        productHierarchyValue: rowData[`h${item.productHierarchyLevel}Value`],
        productHierarchyKey: rowData[`h${item.productHierarchyLevel}Value`],
        productHierarchyMetadataId: rowData[`h${item.productHierarchyLevel}MetadataId`],
        productHierarchyRelationId: rowData.productHierarchyRelationId,
      });
    });
    this.itemHierarchyManagerLevel = updatedList;
  }

  getMaxHierachyLevel() {
    return _.get(_.maxBy(this.itemHierarchyManagerLevel, a => a.productHierarchyLevel), 'productHierarchyLevel', 2);
  }

  createHierarchyValueList(data) {
    const hierarchyValueList: any = [];
    const maxLevel = this.getMaxHierachyLevel();
    data.forEach(row => {
      for (let i = 1; i <= maxLevel; i++) {
        hierarchyValueList.push({
          productHierarchyLevel: i,
          productHierarchyId: row[`h${i}`] || null,
          productParentId: row[`h${i}ParentId`] || null,
          productHierarchyValue: row[`h${i}Value`] || null,
          productHierarchyKey: row[`h${i}Value`] || null,
          productHierarchyMetadataId: row[`h${i}MetadataId`] || null,
          productHierarchyRelationId: row.productHierarchyRelationId || null,
        });
      }
    });
    return hierarchyValueList;
  }

  displayItemFn(item): string {
    return item && typeof item === 'object' ? (item.productHierarchyValue ? `${item.productHierarchyValue}` : '') : item;
  }

  _filterItemLabels(event, item) {
    let value = _.toLower(event.target.value);
    let itemList: any = _.filter(this.savedHierarchyValueList, a => a.productHierarchyLevel === item.productHierarchyLevel);
    itemList = _.uniqBy(itemList, a => a.productHierarchyValue);
    const filteredList = _.filter(itemList, a => { return _.toLower(a.productHierarchyValue).indexOf(value) > -1; })
    this.filteredLookupItems = of(filteredList);
    const validObject = _.find(itemList, a => _.toLower(a.productHierarchyValue) === value);
    if (!this.isEdit) {
      item.productHierarchyId = validObject ? validObject.productHierarchyId : null;
    }
    item.productHierarchyValue = event.target.value;
  }

  onItemControlFocus(event, item) {
    const maxLevel = this.getMaxHierachyLevel();
    if (item.productHierarchyLevel < maxLevel) {
      let itemList: any = _.filter(this.savedHierarchyValueList, a => a.productHierarchyLevel === item.productHierarchyLevel);
      itemList = _.uniqBy(itemList, a => a.productHierarchyValue);
      this.filteredLookupItems = of(itemList);
    } else {
      this.filteredLookupItems = of(_.get(item));
    }
  }

  itemValueChange(selectedItem, control) {
    if (selectedItem.productHierarchyId !== control.productHierarchyId) {
      this.autoPopulateHierarchyValues(selectedItem);
    }
    control.productHierarchyId = selectedItem.productHierarchyId;
    control.productHierarchyMetadataId = selectedItem.productHierarchyMetadataId;
    control.productHierarchyValue = selectedItem.productHierarchyValue;

  }

  autoPopulateHierarchyValues(selectedItem) {
    const selectedRowList = _.orderBy(
      _.filter(this.savedHierarchyValueList, a => a.productHierarchyRelationId === selectedItem.productHierarchyRelationId),
      ['productHierarchyLevel'], ['asc']);
    this.itemHierarchyManagerLevel.forEach(item => {
      if (item.productHierarchyLevel < selectedItem.productHierarchyLevel) {
        const selectedItem = _.find(selectedRowList, a => a.productHierarchyLevel === item.productHierarchyLevel);
        if (selectedItem) {
          item.productHierarchyId = selectedItem.productHierarchyId;
          item.productHierarchyMetadataId = selectedItem.productHierarchyMetadataId;
          item.productHierarchyValue = selectedItem.productHierarchyValue;
        }
      } else if (item.productHierarchyLevel > selectedItem.productHierarchyLevel) {
        item.productHierarchyId = null;
        item.productHierarchyValue = '';
      }
    });
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  isAllHierarcyValueAvailable(hierarchyValuesList) {
    const valuesListOnly = _.map(hierarchyValuesList, a => a.productHierarchyValue);
    return valuesListOnly.length === _.compact(valuesListOnly).length;
  }

  isLastNodeValuesValid(hierarchyValuesList, savedHierarchyList) {
    const maxLevel = this.getMaxHierachyLevel();
    const lastNodeValue = _.get(_.find(hierarchyValuesList, a => a.productHierarchyLevel === maxLevel), 'productHierarchyValue', '');
    let isInSavedList: any = [];
    if (!this.isEdit) {
      isInSavedList = _.filter(savedHierarchyList,
        a => a.productHierarchyLevel === maxLevel
          && a.productHierarchyValue === lastNodeValue);
    } else {
      const productHierarchyRelationId = _.get(this.data, 'rowData.productHierarchyRelationId', '');
      isInSavedList = _.filter(savedHierarchyList,
        a => a.productHierarchyLevel === maxLevel
          && a.productHierarchyValue === lastNodeValue
          && a.productHierarchyRelationId !== productHierarchyRelationId);
    }

    return isInSavedList.length ? false : true;
  }

  isAllCombinationUniq(hierarchyValuesList, savedHierarchyList) {
    let isValidCombination = true;
    const newHierarchyValues = _.map(_.orderBy(hierarchyValuesList, ['productHierarchyLevel'], ['asc']), a => a.productHierarchyValue);
    if (newHierarchyValues.length === _.uniq(newHierarchyValues).length) {
      const saveDataByRows = _.groupBy(savedHierarchyList, a => a.productHierarchyRelationId);
      _.keys(saveDataByRows).every(key => {
        const saveItemValues = _.map(_.orderBy(saveDataByRows[key], ['productHierarchyLevel'], ['asc']), a => a.productHierarchyValue);
        if (_.isEqual(newHierarchyValues, saveItemValues)) {
          isValidCombination = false;
          return false;
        } else {
          return true;
        }
      });
    } else {
      isValidCombination = false;
    }
    return isValidCombination;
  }

  isValuesAtRightLevel(hierarchyValuesList, savedHierarchyList) {
    let isAllValueAtRightLevel: boolean = true;
    hierarchyValuesList.every(newItem => {
      savedHierarchyList.every(savedItem => {
        if (newItem.productHierarchyLevel !== savedItem.productHierarchyLevel
          && newItem.productHierarchyValue === savedItem.productHierarchyValue) {
          isAllValueAtRightLevel = false;
          return false;
        }
        return true;
      });
      return isAllValueAtRightLevel;
    });
    return isAllValueAtRightLevel;
  }

  createSubmitPayload() {
    if (!this.isEdit) {
      this.itemHierarchyManagerLevel.forEach(item => {
        item = {
          ...item,
          productHierarchyId: null,
          productParentName: '',
          productParentId: null,
          productHierarchyValue: item.productHierarchyValue,
          productHierarchyKey: item.productHierarchyValue
        }
      });
    }
    return this.itemHierarchyManagerLevel;
  }


  onSubmit() {
    // Validation for NULL Values
    const isAllValuesAvailable = this.isAllHierarcyValueAvailable(this.itemHierarchyManagerLevel);
    // Validation for last node duplication
    const isLastNodeValuesValid = isAllValuesAvailable && this.isLastNodeValuesValid(this.itemHierarchyManagerLevel, this.savedHierarchyValueList);
    // Validation for uniq combination
    const isAllCombinationUniq = isLastNodeValuesValid && this.isAllCombinationUniq(this.itemHierarchyManagerLevel, this.savedHierarchyValueList);
    // Validation for value at right level
    const isValuesAtRightLevel = isAllCombinationUniq && this.isValuesAtRightLevel(this.itemHierarchyManagerLevel, this.savedHierarchyValueList);

    const hierarchyPayload = this.createSubmitPayload();

    if (
      isAllCombinationUniq
      && isAllValuesAvailable
      && isLastNodeValuesValid
      && isValuesAtRightLevel) {
      this.spinner.show();
      const payload = {
        productHierarchy: hierarchyPayload,
      };
      this.itemHierarchyManagerService.AddUpdateItemHierarchyList(payload).pipe(finalize(() => this.spinner.hide())).subscribe(res => {
        if (res) {
          const code = _.get(res, '0.messageCode', 400);
          const message = _.get(res, '0.messageText', 'Failed to create Item Hierarchy Manager.');
          if (code === 200) {
            this.dialogRef.close(true);
            this.toastr.success('Success', `${message}`);
          } else {
            res.forEach(element => {
              this.toastr.error('Error', `${element.messageText}`);
            });
          }
        }
      },
        err => {
          this.toastr.error('Error', 'System failed to Created Item Hierarchy Manager.');
        });
    } else {
      if (!isAllValuesAvailable || !isLastNodeValuesValid || !isAllCombinationUniq || !isValuesAtRightLevel) {
        this.toastr.error('Error', 'Please fill data in correct format.');
      }
    }

  }

}
