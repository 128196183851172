import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as FusionCharts from 'fusioncharts';

import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(environment.agGridLicenseKey);

if (environment.production) {
  enableProdMode();
}
FusionCharts.options['license']({
  key: environment.fusionChartsKey,
  creditLabel: false,
});
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
