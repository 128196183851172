import { dateRenderer, statusRenderer } from "src/ag-grid/renderers/common-renderers/common-renderers";
import { tooltipcellRenderer } from "src/common/util/util";

export const defaultColDef = {
  filter: true,
  sortable: true,
  minWidth: 50,
  resizable: true,
  filterParams: {
    excelMode: 'windows',
  },
  menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
};

export const getColDefs = (filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;
  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'retailer',
      width:115
    },
    {
      headerName: sharedDataService.getDataById(2),
      field: 'brand',
      width:105
    },
    {
      headerName: sharedDataService.getDataById(3),
      field: 'productGroup',
      width:150,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: sharedDataService.getDataById(5),
      field: 'productDescription',
      width:155,
      cellRenderer: tooltipcellRenderer,
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: sharedDataService.getDataById(4),
      field: 'sku',
      width:115
    },
    {
      headerName: 'Mapped Level At',
      field: 'levelType',
      width:155
    },
    {
      headerName: 'Start Date',
      field: 'effectiveStartDate',
      type: 'date',
      width:115,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'End Date',
      field: 'effectiveEndDate',
      type: 'date',
      width:115,
      cellRenderer: dateRenderer,
    },
    {
      headerName: 'Likeness %',
      field: 'likeness',
      width:115
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: statusRenderer,
      filter: false,
      width:105
    }
  ];

  return colDefs;
}



export const getUnmappedItemGridColDefs = (filterLookupModel: any = {}, componentSelf) => {
  const sharedDataService = componentSelf._dataService;

  const colDefs: any = [
    {
      headerName: '',
      field: 'select',
      width: 70,
      pinned: 'left',
      suppressSizeToFit: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: sharedDataService.getDataById(1),
      field: 'retailer',
    },
    {
      headerName: sharedDataService.getDataById(2),
      field: 'brand',
    },
    {
      headerName: sharedDataService.getDataById(3),
      field: 'productGroup',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: sharedDataService.getDataById(5),
      field: 'productDescription',
      filterParams: {
        cellRenderer: tooltipcellRenderer,
      }
    },
    {
      headerName: sharedDataService.getDataById(4),
      field: 'sku',
    }
  ];

  return colDefs;
}
