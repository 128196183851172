import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  fileDeleted:Subject<{fileName?: string, versionNo?: any, deleteAll?: boolean}> = new Subject();

  constructor(private httpClient: HttpClient) { }

  uploadFileForProcessing(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/user-data-upload/`, obj);
  }

  getSASToken() {
    return this.httpClient.get(`${environment.FileManagementURL}/fetch-sas-token/`)
      .pipe(map((response: any) => {
        return response.payload;
      }));
  }

  deleteFiles(obj = {}) {
    return this.httpClient.delete(`${environment.FileManagementURL}/user-data-upload/`, obj);
  }

  fetchAlreadyUploadedFiles(userId, page = 1) {
    return this.httpClient.get(`${environment.FileManagementURL}/fetch-user-files/?user_id=${userId}&page=${page}`);
  }

  fetchFilesProgress(userId) {
    return this.httpClient.get(`${environment.FileManagementURL}/check-data-module-progress/?user_id=${userId}`);
  }

  deleteFileVersion(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/delete-file-version/`, obj);
  }

  deleteFileAndAllVersions(obj = {}) {
    return this.httpClient.post(`${environment.FileManagementURL}/delete-file/`, obj);
  }

}
