
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from 'src/services/configuration.service';


@Component({
  selector: 'app-insight-popup',
  templateUrl: './insight-popup.component.html',
  styleUrls: ['./insight-popup.component.css']
})
export class InsightPOPUPComponent implements OnInit, OnDestroy {


  public rowData ;

    public img = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<InsightPOPUPComponent>,
  public configurationService: ConfigurationService,
  )
  {}

  ngOnInit() {
    this.rowData = this.data;
  }

  ngOnDestroy() {
    this.data = null;
  }

  addElementToDashboard = (selector: any) => {
    this.dialogRef.close();
    if (selector.isPowerBiReport) {
      selector.data = selector.report;
    }
    this.configurationService.menuRendererSubject.next(selector);
  }

}
