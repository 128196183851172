import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlotlyService } from 'angular-plotly.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataModuleService } from 'src/services/data-module.service';

@Component({
  selector: 'app-manage-outliers',
  templateUrl: './manage-outliers.component.html',
  styleUrls: ['./manage-outliers.component.scss']
})
export class ManageOutliersComponent implements OnInit, AfterViewInit {

  @ViewChild("histogramPlotDiv", { static: false }) histogramPlotDiv: ElementRef;

  histogramPlot = {
    data: [],
    layout: {
      autosize: true,
      xaxis: {
        title: {
          text: '',
        }
      },
      yaxis: {
        title: {
          text: 'Count',
        }
      }
    }
  };

  leftPercentageNewValue;
  rightPercentageNewValue;
  lowerNewValue;
  higherNewValue;
  middleNewValue;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService,
    private dataModuleService: DataModuleService, private toastrService: ToastrService,
    private plotlyRef: PlotlyService, public dialogRef: MatDialogRef<ManageOutliersComponent>) { }

  ngOnInit(): void {
    this.leftPercentageNewValue = this.data.outlierData.updated_left_percent;
    this.rightPercentageNewValue = this.data.outlierData.updated_right_percent;
    this.lowerNewValue = this.data.outlierData.lower_updated || this.data.outlierData.lower;
    this.higherNewValue = this.data.outlierData.higher_updated || this.data.outlierData.higher;;
    this.middleNewValue = this.data.outlierData.updated_middle_percent;
  }

  ngAfterViewInit(): void {
    this.plotHistogram();
  }

  plotHistogram() {

    const data = [];

    this.data.outlierData.intervals.forEach((element, index) => {
      const trace = {
        x: element,
        y: [this.data.outlierData.counts[index], this.data.outlierData.counts[index]],
        fill: 'tozeroy',
        type: 'scatter',
        name: element.toString()
      };
      data.push(trace);
    });

    const trace = {
      x: [this.lowerNewValue, this.lowerNewValue],
      y: [Math.max(...this.data.outlierData.counts), 0],
      fill: 'tozeroy',
      type: 'line',
      name: 'Lower Outlier',
      line: {
        dash: 'dot',
        color: '#7F7F7F',
        width: 4
      }
    };
    data.push(trace);

    const traceHigher = {
      x: [this.higherNewValue, this.higherNewValue],
      y: [Math.max(...this.data.outlierData.counts), 0],
      fill: 'tozeroy',
      type: 'line',
      name: 'Higher Outlier',
      line: {
        dash: 'dot',
        color: '#7F7F7F',
        width: 4
      }
    };
    data.push(traceHigher);

    this.plotlyRef.newPlot(
      this.histogramPlotDiv.nativeElement,
      data,
      this.histogramPlot.layout
    ).then((res) => {
    });
  }

  calculateLowerAndHigherOutlierForGraph() {
    const data = [];

    this.data.outlierData.intervals.forEach((element, index) => {
      const trace = {
        x: element,
        y: [this.data.outlierData.counts[index], this.data.outlierData.counts[index]],
        fill: 'tozeroy',
        type: 'scatter',
        name: element.toString()
      };
      data.push(trace);
    });

    const trace = {
      x: [this.lowerNewValue, this.lowerNewValue],
      y: [Math.max(...this.data.outlierData.counts), 0],
      fill: 'tozeroy',
      type: 'line',
      name: 'Lower Outlier',
      line: {
        dash: 'dot',
        color: '#7F7F7F',
        width: 4
      }
    };
    data.push(trace);

    const traceHigher = {
      x: [this.higherNewValue, this.higherNewValue],
      y: [Math.max(...this.data.outlierData.counts), 0],
      fill: 'tozeroy',
      type: 'line',
      name: 'Higher Outlier',
      line: {
        dash: 'dot',
        color: '#7F7F7F',
        width: 4
      }
    };
    data.push(traceHigher);
    this.plotlyRef.update(this.histogramPlotDiv.nativeElement, data, this.histogramPlot.layout);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    const obj = this.data.outlierData;
    obj.user_id = this.data.userId;
    obj.file_name = this.data.fileName;
    obj.version_no = this.data.versionNo;
    obj.column_name = this.data.columnName;
    obj.higher_updated = obj.higher_updated || null;
    obj.lower_updated = obj.lower_updated || null;

    if (obj.lower != this.lowerNewValue) {
      obj.left_value_change = this.lowerNewValue - obj.lower;
      obj.left_whisker = true;
      obj.lower_updated = this.lowerNewValue;
    }
    if (obj.higher != this.higherNewValue) {
      obj.right_value_change = this.higherNewValue - obj.higher;
      obj.right_whisker = true;
      obj.higher_updated = this.higherNewValue;
    }
    if (obj.updated_left_percent != this.leftPercentageNewValue) {
      obj.left_percent_change = this.leftPercentageNewValue - obj.updated_left_percent;
      obj.left_percentage = true;
    }
    if (obj.updated_right_percent != this.rightPercentageNewValue) {
      obj.right_percent_change = this.rightPercentageNewValue - obj.updated_right_percent;
      obj.right_percentage = true;
    }

    this.spinner.show();
    this.dataModuleService.updateOutlierData(obj)
      .subscribe((res: any) => {
        this.toastrService.success('Successfully updated the data.');
        this.spinner.hide();
        this.dialogRef.close(true);
      },
        err => {
          this.toastrService.error('Failed to update.');
          this.spinner.hide();
        });
  }

}
