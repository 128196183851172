import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ForecastMatrixMockData } from './mock-data';
import { ForecastSeasonalityModalComponent } from 'src/modules/forecast-engin/features/forecast-manager/forecast-seasonality-modal/forecast-seasonality-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ForecastManagerService {

  // Subjects
  public forecastGridActionCellSubject: Subject<any> = new Subject();
  public forecastGridActionDataCellSubject: Subject<any> = new Subject();
  public forecastSetupActionSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setForecastGridActionCellSubject = (value: any) => { this.forecastGridActionCellSubject.next(value); };
  setForecastSetupActionSubject = (value: any) => { this.forecastSetupActionSubject.next(value); };

  // Observables
  getForecastGridActionCellSubject = () => this.forecastGridActionCellSubject.asObservable();
  getForecastSetupActionSubject = () => this.forecastSetupActionSubject.asObservable();

  constructor(private http: HttpClient, private dialog: MatDialog) {
    this.listenSubscriptions();
  }

  ForecastManagerCreateUserSession(params) {
    return this.http.post(`${environment.ForecastManager}/forecastManagerCreateUserSession`, params)
      .pipe(map(response => {
        return response;
      }));
  }

  ForecastManagerDeleteUserSession() {
    return this.http.delete(`${environment.ForecastManager}/forecastManagerDeleteUserSession`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetForecastList(obj = {}) {
    return this.http.post(`${environment.ForecastManager}/forecastmanagergetlist`, obj)
      .pipe(map(response => {
        return response;
      }));
  }

  forecastManagerGetUserSession(params) {
    return this.http.get(`${environment.ForecastManager}/forecastManagerGetUserSession`,)
      .pipe(map(response => {
        return response;
      }));
  }

  GetForecastById(forecastId) {
    return this.http.get(`${environment.ForecastManager}/getforecastmanagerbyid?forecastId=${forecastId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  CreatForecast(body) {
    return this.http.post(`${environment.ForecastManager}/addforecastmanager`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetForecastHierarchyLevelWithSeasonality(body) {
    return this.http.post(`${environment.ForecastManager}/GetForecastHierarchyLevelWithSeasonality`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  DeleteForecast(body) {
    return this.http.post(`${environment.ForecastManager}/deleteForecast`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  CalendarGetList(forecastId) {
    return this.http.get(
      `${environment.ForecastManager}/getSelectedWeekTimeFrame?forecastId=${forecastId}`).pipe(map(response => {
        return response
      }));
  }

  ExportForecastMatrixTemplate() {
    return this.http.get(
      `${environment.ForecastManager}/exportforecastmatrixtemplate`).pipe(map(response => {
        return response
      }));
  }

  ImportForecastMatrixData(body,forecastId) {

    return this.http.post(`${environment.ForecastManager}/importforecastmatrix?forecastId=${forecastId}`, body)
      .pipe(map(data => {
        return data;
      }));
  }

  GetForecastMatrixDataById(forecastId) {
    return this.http.get(`${environment.ForecastManager}/forecastMatrixGetDataList?forecastId=${forecastId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  ForecastManagerMatrixDataReset(forecastId) {
    return this.http.delete(
      `${environment.ForecastManager}/forecastManagerMatrixDataReset?forecastId=${forecastId}`).pipe(map(response => {
        return response
      }));
  }

  ForecastMatrixDataUpdate(body) {
    return this.http.put(`${environment.ForecastManager}/forecastmatrixdataupdate`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  GetForecastManagerDataById(forecastId) {
    return this.http.get(
      `${environment.ForecastManager}/getforecastmanagerdatabyid?forecastId=${forecastId}`).pipe(map(response => {
        return response
      }));
  }

  GetLatestCommitedWeek() {
    return this.http.get(
      `${environment.ForecastManager}/LatestCommitedWeekGet`).pipe(map(response => {
        return response
      }));
  }

  // for grid data popup
  protected listenSubscriptions() {
    this.forecastGridActionDataCellSubject.subscribe(data => {
      if (data) {
        const forecastData = {
          forecastData: data.forecastData
        };
        this.openForecastDataManagerModel(forecastData);
      }
    });
  }

  protected openForecastDataManagerModel(forecastData) {
    const dialogRef = this.dialog.open(ForecastSeasonalityModalComponent, {
      disableClose: true,
      panelClass: 'mapping-manager-dialog',
      data: {
        chartData: forecastData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
