<mat-form-field appearance="fill">
  <mat-label>Select a column</mat-label>
  <mat-select [(ngModel)]="selectedColumns" multiple>
    <mat-option value="weeks_old">Age</mat-option>
    <mat-option value="tw_forecast">TW Forecast</mat-option>
    <mat-option value="on_hand">On Hand</mat-option>
    <mat-option value="on_order">On Order</mat-option>
    <mat-option value="base_ca">Base CA</mat-option>
    <mat-option value="calc_ca">Calc. CA</mat-option>
  </mat-select>
</mat-form-field>

<div (paste)="pasted($event)" class="paste-div">
	Select & paste your content (CTRL + V)
</div>

<div mat-dialog-actions style="justify-content: flex-end;">
    <button type="button" mat-button mat-flat-button color="accent" (click)="close(false)">Cancel</button>
</div>