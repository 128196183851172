import { ICellRendererAngularComp } from 'ag-grid-angular'
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PromoService } from 'src/services/forecast-services/promo-service';

@Component({
  selector: 'promo-items-edit-cell-renderer',
  template: `<a class="product-title-link" [title]="params.value" (click)="btnEditClickHandler()">{{params.value}}</a>`,
})
export class PromoItemsEditRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private promoService: PromoService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnEditClickHandler() {
    this.promoService.setPromoGridActionCellSubject({ promoData: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
