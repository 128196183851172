import { Router } from '@angular/router';
import { ChannelMessagingService } from '../../../services/Messaging-Services/channel-messaging.service';
import { Component, Inject, OnInit, TemplateRef } from '@angular/core';

import * as _ from 'lodash';
import { ChannelMessaging } from 'src/models/channelMessaging.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { USER_ID } from 'src/common/keys';
import { LocalstorageService } from 'src/services/localstorage.service';
import * as signalR from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
@Component({
  selector: 'app-channel-messaging',
  templateUrl: './channel-messaging.component.html',
  styleUrls: ['./channel-messaging.component.scss'],
})
export class ChannelMessagingComponent implements OnInit {
  public applicationUsersDetails: any;
  public applicationUsersDetailsListOrg: any;
  public selectedArrayData: any[] = [];
  public group: FormGroup;
  public isAdd: boolean = true;
  public channelMessagingModel = new ChannelMessaging();
  public userId = this.storage.get(USER_ID);
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChannelMessagingComponent>,
    private channelMessagingService: ChannelMessagingService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public router: Router,
    public storage: LocalstorageService,
    public matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getUserChannelInfo();
    if (this.data?.isEdit === true) {
      this.GetChannelByChannelId(this.data?.formData);
    }
  }

  onFilterTextBoxChanged(event) {
    if (event.target.value) {
      this.applicationUsersDetails = this.applicationUsersDetailsListOrg.filter(
        (res) => {
          return res.userFullName
            .toLocaleLowerCase()
            .match(event.target.value.toLocaleLowerCase());
        }
      );
    } else {
      this.getUserChannelInfo();
    }
  }
  getUserChannelInfo() {
    this.spinner.show();
    this.channelMessagingService.GetUsersDetails().subscribe(
      (userDetails: any) => {
        userDetails.forEach((element) => {
          if (typeof element === 'object') {
            if (element) {
              element['userFullName'] =
                element.firstName + ' ' + element.lastName;
            }
          }
        });
        this.applicationUsersDetails = userDetails;
        this.spinner.hide();
        _.remove(this.applicationUsersDetails, (currentUser) => {
          return currentUser.userId === this.userId;
        });
        this.applicationUsersDetails = _.sortBy(
          this.applicationUsersDetails,
          (userDetails) => userDetails.firstName.toLowerCase()
        );
        if (this.selectedArrayData.length > 0) {
          this.selectedArrayData.forEach((filterData) => {
            _.remove(this.applicationUsersDetails, (remove) => {
              return remove.userId === filterData.userId;
            });
          });
        }
        this.applicationUsersDetailsListOrg = Object.assign([], this.applicationUsersDetails);
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Error',
          'Something went wrong'
        );
      }
    );
  }

  initializeForm() {
    this.group = this.formBuilder.group({
      channelName: ['', [Validators.maxLength(80)]],
    });
  }

  selectionChange(option: MatListOption) {
    if (option.selected === true) {
      if (option.value.delete === true) {
        option.value.delete = false;
      }

      this.selectedArrayData.forEach(function (e) {
        if (e.userFullName) {
          const splitData = _.split(e.userFullName, ' ', 5);
          if (typeof e === 'object') {
            e['firstName'] = splitData[0];
            e['lastName'] = splitData[1];
          }
        }
      });
      this.applicationUsersDetails.forEach(function (element) {
        if (element.delete === true) {
          if (typeof option.value === 'object') {
            return (option.value['channelId'] = element.channelId);
          }
        }
      });
      this.selectedArrayData.push(option.value);

      this.selectedArrayData = _.sortBy(this.selectedArrayData, (o) =>
        o.firstName.toLowerCase()
      );
      _.remove(this.applicationUsersDetails, (remove) => {
        return remove.userId === option.value.userId;
      });
    } else {
      if (option.value.userFullName) {
        const splitData = _.split(option.value.userFullName, ' ', 5);
        option.value.firstName = splitData[0];
        if (splitData[2]) {
          option.value.lastName = splitData[1] + '' + splitData[2];
        } else {
          option.value.lastName = splitData[1];
        }
        option.value.delete = true;
      }
      this.applicationUsersDetails.push(option.value);
      this.applicationUsersDetails = _.sortBy(
        this.applicationUsersDetails,
        (o) => o.firstName.toLowerCase()
      );
      _.remove(this.selectedArrayData, (remove) => {
        return remove.userId === option.value.userId;
      });
    }
  }

  onSubmit() {
    this.spinner.show();
    const form = this.group.value;
    this.channelMessagingModel.channelName = form.channelName;
    this.selectedArrayData.forEach((user) => {
      this.channelMessagingModel.UsersInChannel.push({
        usersInChannelId: user.usersInChannelId,
        channelId: user.channelId,
        userId: user.userId,
        createdDate: null,
        createdbyId: user.createdbyId,
        modifiedById: user.modifiedById,
        modifiedDate: null,
        active: user.active,
        userFullName: user.userFullName,
        delete: false,
      });
    });
    this.channelMessagingService
      .CreateChannelMessaging(this.channelMessagingModel)
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.toastr.success('', 'Create Channel Successfully');
          this.matDialogRef.close();
          this.selectedArrayData = [];
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            'Error',
            'Something went wrong channel messaging service down'
          );
        }
      );
  }

  GetChannelByChannelId(data) {
    this.spinner.show();
    this.isAdd = false;
    this.channelMessagingService
      .GetChannelByChannelId(data.channelId)
      .subscribe(
        (channelDetails: any) => {
          this.getUserChannelInfo();
          this.spinner.hide();
          this.selectedArrayData = channelDetails.usersInChannel;
          this.channelMessagingModel.channelId = channelDetails.channelId;
          this.channelMessagingModel.createdDate = channelDetails.createdDate;
          this.channelMessagingModel.modifiedDate = channelDetails.modifiedDate;
          if (this.selectedArrayData.length > 0) {
            _.remove(this.selectedArrayData, (currentUser) => {
              return currentUser.userId === this.userId;
            });
            this.selectedArrayData.forEach((filterData) => {
              _.remove(this.applicationUsersDetails, (remove) => {
                return remove.userId === filterData.userId;
              });
            });
            this.selectedArrayData.forEach(function (e) {
              if (e.userFullName) {
                const splitData = _.split(e.userFullName, ' ', 5);
                if (typeof e === 'object') {
                  e['firstName'] = splitData[0];
                  e['lastName'] = splitData[1];
                }
              }
            });
            this.selectedArrayData = _.sortBy(this.selectedArrayData, (o) =>
              o.firstName.toLowerCase()
            );
          }
          this.group.controls['channelName'].setValue(
            channelDetails.channelName
          );
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            'Error',
            'Something went wrong messaging service down'
          );
        }
      );
  }

  updateDetails() {

    this.matDialogRef.close();
    const form = this.group.value;
    this.channelMessagingModel.channelName = form.channelName;
    let channelId = this.channelMessagingModel.channelId;
    _.find(this.applicationUsersDetails, (find) => {
      if (find.delete === true) {
        this.selectedArrayData.push(find);
      }
    });
    this.selectedArrayData.forEach(function (element) {
      if (element.active === true) {
        if (typeof element === 'object') {
          if (!element.channelId || !element.usersInChannelId) {
            element['channelId'] = channelId;
            element['usersInChannelId'] = 0;
          }
        }
      }
    });
    this.spinner.show();
    this.selectedArrayData.forEach((user) => {
      this.channelMessagingModel.UsersInChannel.push({
        usersInChannelId: user.usersInChannelId,
        channelId: user.channelId,
        userId: user.userId,
        createdDate: user.createdDate,
        createdbyId: user.createdbyId,
        modifiedById: user.modifiedById,
        modifiedDate: user.modifiedDate,
        active: user.active,
        userFullName: user.userFullName,
        delete: user.delete,
      });
    });
    this.channelMessagingService

      .UpdateChannelDetails(this.channelMessagingModel)
      .subscribe(
        (data) => {
          this.selectedArrayData = [];
          this.channelMessagingModel.UsersInChannel = [];
          this.GetChannelByChannelId(data);
          this.spinner.hide();
          this.toastr.success('', 'Update Channel Successfully');
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            'Error',
            'Something went wrong channel not update user information'
          );
        }
      );
  }

  onCancel() {
    this.dialogRef.close();
  }

  public openUpdateDialog = (template: TemplateRef<any>) => {
    this.matDialogRef = this.dialog.open(template, { width: '500px' });
  };
}
