import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'fusion-percentage-horizental-bar-chart',
  templateUrl: './percentage-horizental-bar-chart.component.html',
  styleUrls: ['./percentage-horizental-bar-chart.component.scss']
})
export class FusionPercentageHorizentalBarChartComponent implements OnInit, OnChanges {
  @Input() chartCaption?: string;
  @Input() xaxisCatgoryList: string[];
  @Input() dataSeries: any[];
  @Input() height?: string = '200px';
  @Input() yAxisName?: string = '';

  /*

    Category Sample Object 
    {
      label: 'abc'
    }

    Data Series Sample Object
    {
      seriesname: "Original",
      color: "#ff0000",
      data: []
    }

    Data Series Data Sample Object 
    {
      value: 123
    }
  */

  dataSource: any = {
    chart: {
      caption: "",
      theme: "fusion",
      yAxisName: "",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100",
      stack100Percent: "1",
      showPercentInTooltip: "0",
      showValues: "1",
      minPlotHeightForValue: "30",
      showPercentValues: "1",
      valueFontColor: "#ffffff",
      chartLeftMargin: "5",
      chartTopMargin: "0",
      chartRightMargin: "5",
      chartBottomMargin: "0",
      canvasPadding: "0",
      showBorder: "1",
      borderColor: "#8D009D",
      borderThickness: "2",
      borderAlpha: "20",
      plotHighlightEffect: "fadeout",
      legendPosition: "top-left",
      plottooltext: "<b>$seriesName</b> $dataValue ($percentValue)",
    },
    categories: [
      {
        category: []
      }
    ],
    dataset: []
  };

  chartConfig: Object = {
    type: 'stackedbar2d',
    dataFormat: 'json',
  };


  constructor() {

  }

  ngOnInit() {
    this.initChartDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initChartDataSource();
  }

  initChartDataSource() {
    this.dataSource.chart.yAxisName = this.yAxisName;
    this.dataSource.chart.caption = this.chartCaption;
    this.dataSource.categories[0].category = this.xaxisCatgoryList;
    this.dataSource.dataset = this.dataSeries;
  }

}
