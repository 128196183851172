import { Injectable } from '@angular/core';
import { TreeviewSelection, DefaultTreeviewI18n } from 'ngx-treeview';
import { SharedDataService } from 'src/services/shared-data.service';

@Injectable()
export class DropdownTreeViewAccountSelectI18n extends DefaultTreeviewI18n {

  /**
   *
   */
  constructor(public _dataService: SharedDataService ) {
    super();

  }
  getText(selection: TreeviewSelection): string {
    if (selection.uncheckedItems.length === 0) {
      return 'All';
    }
    switch (selection.checkedItems.length) {
      case 0:
        return `Select ${this._dataService.getDataById(1)}(s)` ;
      case 1:
        return selection.checkedItems[0].text;
      default:
        return `${selection.checkedItems.length} ${this._dataService.getDataById(1)}(s) Selected`;
    }
  }
  getFilterNoItemsFoundText(): string {
    return 'No Options Found';
  }
}
