<form mat-dialog-content [formGroup]="clientForm">
    <div class="form-title-bar">
        <strong>{{editMode ? 'Update' : 'Add New'}} Client</strong>
        <div class="active-btn">
            <mat-slide-toggle formControlName="active">Active
            </mat-slide-toggle>
        </div>
    </div>

    <hr />

    <div class="row">
        <div class="col-md-6">
            <strong>Name</strong>
            <mat-form-field appearance="fill">
                <input type="text" placeholder="Enter name" matInput formControlName="clientName">
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <strong>Description</strong>
            <mat-form-field appearance="fill">
                <textarea type="text" placeholder="Add notes" matInput formControlName="description"></textarea>
            </mat-form-field>
        </div>

        <div class="col-md-6" *ngIf="!editMode">
            <strong>First Name</strong>
            <mat-form-field appearance="fill">
                <input placeholder="First Name" matInput formControlName="firstName">
            </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="!editMode">
            <strong>Last Name</strong>
            <mat-form-field appearance="fill">
                <input placeholder="Last Name" matInput formControlName="lastName">
            </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="!editMode">
            <strong>Email</strong>
            <mat-form-field appearance="fill">
                <input type="email" placeholder="Enter email" matInput formControlName="email" autocomplete="off">
            </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="!editMode">
            <strong>Password</strong>
            <mat-form-field appearance="fill">
                <input type="password" placeholder="Enter password" matInput formControlName="password"
                    autocomplete="off">
            </mat-form-field>
        </div>

        <div class="col-md-12 d-flex align-items-center">
            <input class="hidden" type="file" id="{{'logoBtn'+uniqueId}}" accept=".jpg,.png,.jpeg"
                (change)="onFileSelect($event)">
            <div style="display: grid;">
                <button mat-raised-button class="ml-2" color="accent" (click)="uploadFileBtn()">Upload Logo
                </button>
                <small *ngIf="file">{{file.name}}</small>
            </div>
            <img class="logo-holder ml-2" *ngIf="fileDataUrl" src="{{fileDataUrl}}" />
        </div>

    </div>
</form>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="accent" [disabled]="clientForm.disabled" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="accent" [disabled]="clientForm.invalid || clientForm.disabled"
        (click)="onSaveClick()">{{editMode ? 'Update' : 'Save'}}</button>
</div>