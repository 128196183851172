<ngx-dropdown-treeview [itemTemplate]="itemTemplate" class="dropdown-text" [config]="config" [items]="items"
  [buttonClass]="buttonClass" (selectedChange)="setValues($event)" (filterChange)="onFilterChange($event)">
</ngx-dropdown-treeview>
<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange">
  <div class="form-inline row-item">
    <i *ngIf="item.internalChildren" class="mr-1" (click)="onCollapseExpand()" aria-hidden="true"
      [ngSwitch]="item.collapsed">
      <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
      </svg>
      <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
      </svg>
    </i>
    <div class="form-check">
      <input id="{{item.text}}" type="checkbox" class="form-check-input" [(ngModel)]="item.checked"
        (ngModelChange)="onCheckedChange()" [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
      <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
        {{item.text}}
      </label>
    </div>
  </div>
</ng-template>
