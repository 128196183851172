import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { USER_INFO } from 'src/common/keys';
import { ClusteringForecastService } from 'src/services/Item-Management-Services/clustering-forecast.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { UpdateNameDialogComponent } from '../../common/update-name-dialog/update-name-dialog.component';

@Component({
  selector: 'app-clustering-forecast',
  templateUrl: './clustering-forecast.component.html',
  styleUrls: ['./clustering-forecast.component.scss']
})
export class ClusteringForecastComponent implements OnInit, OnDestroy {

  constructor(private changeDetectionRef: ChangeDetectorRef, private dialog: MatDialog, private storage: LocalstorageService,
    private clusteringService: ClusteringForecastService, private spinner: NgxSpinnerService, private toastrService: ToastrService) { }

  outputTabData = null;
  runTabs = [];
  selectedTab = 0;

  resizeGraphSubject: Subject<boolean> = new Subject();

  userObj = {} as any;
  versionNo = null;
  setSettingsExpansionSettings: Subject<boolean> = new Subject();

  ngOnInit(): void {
    this.userObj = this.storage.get(USER_INFO);
  }

  runClusterHandler(event) {
    this.versionNo = null;
    this.runTabs = [];
    if (event && event.versionNo) this.versionNo = event.versionNo;
    this.outputTabData = event;
    this.createRunTabs(this.outputTabData);
    this.selectedTab = 0;
    setTimeout(() => {
      this.outputTabData && (this.selectedTab = 1);
      this.changeDetectionRef.detectChanges();
      this.onTabChanged();
    }, 200);
  }

  onTabChanged() {
    this.resizeGraphSubject.next(true);
    this.selectedTab == 0 && this.setSettingsExpansionSettings.next(true);
  }

  createRunTabs(data) {
    if (data && data.run_labels && data.run_labels.length) {
      this.spinner.show();
      data.run_labels.forEach((run, runArrayIndex) => {
        let runObject: any = {
          metrices: data.metrices,
          fileName: data.fileName,
          clusteringLabels: JSON.parse(JSON.stringify(run.cluster_labels)),
          perClusterCount: data.data.per_cluster_count,
          objectToCluster: data.data.objectToCluster,
          summaryStatsMetricVariables: [],
          clusterStatistics: data.data.cluster_statistics,
          spiderPlot: []
        };
        runObject.tabName = run.label_name;
        const summaryMetrices = run.metric_columns;
        const obj = {
          file_name: data.fileName,
          user_id: this.userObj.userId,
          run_tab_index: run.label_id,
          matrix_columns: summaryMetrices,
          version_no: this.versionNo
        };
        data.metrices.forEach(element => {
          run.metric_columns.forEach(metricItem => {
            if (element.id == metricItem)
              runObject.summaryStatsMetricVariables.push(element);
          });
        });
        this.clusteringService.fetchSpiderChartData(obj)
          .subscribe((res: any) => {
            runObject.spiderVariables = [];
            runObject.metrices.forEach((element, index) => {
              if (index < 12) {
                runObject.spiderVariables.push(element.id);
              }
            });
            runObject.spiderPlot = res.payload.data;

            runObject.runIndex = run.label_id;
            runObject = JSON.parse(JSON.stringify(runObject));
            this.runTabs[runArrayIndex] = runObject;
            setTimeout(() => {
              this.selectedTab = this.runTabs.length + 1;
              this.changeDetectionRef.detectChanges();
              this.onTabChanged();
            }, 200);
          },
            err => {
              this.spinner.hide();
            });
      });
    }
  }
  outputTabRunBtnHandler(data) {

    if (this.runTabs.length >= 5) {
      this.toastrService.warning('Max 5 run tabs are allowed.', 'Warning');
      return;
    }

    this.spinner.show();
    let runTabIndex = 1;
    if (this.runTabs.length) {
      const index = this.runTabs[this.runTabs.length - 1].runIndex.split('_').pop();
      runTabIndex = Number(index) + 1;
    }
    // data.runIndex = `RUN_${runTabIndex}`;
    // data.tabName = `RUN_${runTabIndex}`;
    const summaryMetrices = [];
    data.summaryStatsMetricVariables.forEach(metricElement => {
      summaryMetrices.push(metricElement.id);
    });
    const saveObj = {
      file_name: data.fileName,
      user_id: this.userObj.userId,
      // run_cluster_id: data.runIndex,
      cluster_by: data.objectToCluster,
      metric_columns: summaryMetrices,
      version_no: this.versionNo
    };
    this.clusteringService.saveRun(saveObj)
      .subscribe((res: any) => {
        if (res.payload && res.payload.params && res.payload.params.run_version_id) {
          data.tabName = res.payload.params.run_label_name;
          data.runIndex = res.payload.params.run_version_id;
          this.toastrService.success('Run saved successfully with name: ' + data.tabName);
          const obj = {
            file_name: data.fileName,
            user_id: this.userObj.userId,
            run_tab_index: data.runIndex,
            matrix_columns: summaryMetrices,
            version_no: this.versionNo
          };
          this.clusteringService.fetchSpiderChartData(obj)
            .subscribe((res: any) => {
              data.spiderVariables = [];
              data.metrices.forEach((element, index) => {
                if (index < 12) {
                  data.spiderVariables.push(element.id);
                }
              });
              data.spiderPlot = res.payload.data;
              data = JSON.parse(JSON.stringify(data));
              this.runTabs.push(data);
              setTimeout(() => {
                this.selectedTab = this.runTabs.length + 1;
                this.changeDetectionRef.detectChanges();
                this.onTabChanged();
              }, 200);
            },
              err => {
                this.spinner.hide();
              });
        } else {
          this.spinner.hide();
          this.toastrService.error('Something went wrong');
        }
      },
        err => {
          this.spinner.hide();
          if (err.error && err.error.code && err.error.code == 403 && err.error.description) {
            this.toastrService.error(err.error.description);
          }
        });
  }

  editTabName(tab, index: number, event) {
    event && event.preventDefault();
    event && event.stopPropagation();
    console.log(this.runTabs);
    const columnsDialogRef = this.dialog.open(UpdateNameDialogComponent, {
      width: '650px',
      data: {
        name: this.runTabs[index].tabName ? this.runTabs[index].tabName : `RUN ${index + 1}`
      }
    });

    columnsDialogRef.afterClosed().subscribe(result => {
      if (result) {
        const labelsList = this.runTabs.map((tab, i) => {
          return index === i ? { uu_id: tab.runIndex, name: result } : { uu_id: tab.runIndex, name: tab.tabName }
        });

        // checking for duplicate headers
        const labelNamesList = this.runTabs.map((tab, i) => {
          return index === i ? result.toLowerCase() : tab.tabName.toLowerCase();
        });
        const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
        const duplicates = [...new Set(findDuplicates(labelNamesList))];
        if (duplicates.length) {
          this.toastrService.warning('Duplicate names are not allowed.', result);
          return;
        }

        // check for empty name
        let emptyHeaders = 0;
        labelNamesList.forEach(header => {
          !header && emptyHeaders++;
        });
        if (emptyHeaders) {
          this.toastrService.warning(`Empty name detected!`, 'Warning');
          return;
        }

        this.spinner.show();
        this.clusteringService.updateRunTabMetadata({
          file_name: this.outputTabData.fileName,
          user_id: this.userObj.userId,
          update_cluster_label: labelsList,
          version_no: this.versionNo
        }).subscribe(res => {
          this.runTabs[index].tabName = result;
          console.log(res)
          this.spinner.hide();
          this.toastrService.success('Name updated successfully.');
        },
          err => {
            this.spinner.hide();
            this.toastrService.error('Failed to update the name.');
          });
      }
    });
  }

  removeTab(i: number, event) {
    event && event.preventDefault();
    event && event.stopPropagation();
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { confirmationMessage: 'Are you sure you want to remove this Run?' }
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clusteringService.deleteRun({
          file_name: this.outputTabData.fileName,
          version_no: this.versionNo,
          run_version: this.runTabs[i].runIndex
        }).subscribe(res => {
          this.spinner.hide();
          this.toastrService.success('Run tab deleted successfully.');
          this.runTabs.splice(i, 1);
        },
          err => {
            this.spinner.hide();
            this.toastrService.error('Failed to delete the tab.');
          });
      }
    });
  }

  ngOnDestroy() {
  }

}
