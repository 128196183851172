import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { generateTitleDiv, NO_IMAGE, undefinedImagePath } from 'src/modules/empower-module/helper';

@Component({
  selector: 'empower-pc-performance-vs-market',
  templateUrl: './pc-performance.component.html',
  styleUrls: ['./pc-performance.component.scss']
})
export class EmpowerPcPerformanceVsMarketComponent implements OnInit {
  @Input() data?: any;
  _unsubscribeAll = new Subject();
  undefinedImagePath = '';

  firstChartCaption: string;
  secondChartCaption: string;
  xaxisCatgoryList: any = [];
  dataSeriesFirst: any = [];
  dataSeriesSecond: any = [];
  innerHtml = '';
  pageComment: string;

  constructor(
    public empowerModernLifeService: EmpowerModernLifeService, private empowerExportService: EmpowerExportService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {
    this.initPageData();
  }

  initPageData() {
    if (this.data) {
      this.data = JSON.parse(this.data);

      this.innerHtml = generateTitleDiv(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const daxReponse = this.empowerModernLifeService.getPagesState(pageId);
    this.pageComment = this.empowerModernLifeService.getPageCommentState(pageId);
    if (daxReponse && daxReponse.length) {
      this.loadVisuals(daxReponse);
    } else {
      this.executePageDaxQuery();
    }
  }

  executePageDaxQuery() {
    const payload = {
      reportId: this.data.reportId,
      pageId: this.data.pageId,
      ...this.empowerModernLifeService.getDashboardState()
    };
    this.spinner.show();
    this.empowerModernLifeService.ExecutePageDaxQuery(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      if (response && response.length) {
        this.getDAXReponseData(response);
      } else {
        this.emitExportEvent(true);
      }
      this.spinner.hide();
    },
      err => {
        this.undefinedImagePath = undefinedImagePath;
        this.spinner.hide();
      });
  }

  getDAXReponseData(response) {
    const daxReponse = response.map(a => {
      return {
        visualId: a.visualId,
        daxResponse: a.daxResponse ? JSON.parse(a.daxResponse) : []
      }
    });
    console.log(daxReponse);
    this.setPageState(this.data.pageId, daxReponse);
    this.loadVisuals(daxReponse);
  }

  setPageState(pageId, daxData) {
    this.empowerModernLifeService.setPagesState(pageId, daxData);
  }

  loadVisuals(daxData) {
    this.loadFirstVisual(daxData[0]);
    this.loadSecondVisual(daxData[1]);
    this.emitExportEvent(true);
  }

  loadFirstVisual(visualData) {
    const dataList = _.orderBy(visualData.daxResponse, ['dIM_DATE_COMP[FISCAL_MONTH_NUMBER]'], ['asc']);
    const categoryList = _.map(dataList, a => { return { label: a['dIM_DATE_COMP[FISCAL_MONTH_NAME]'] } });
    const seriesData = _.map(dataList, a => { return { value: a['[RCR_Units___Comp_Date]'] } });
    this.xaxisCatgoryList = categoryList;
    this.dataSeriesFirst = [{ seriesname: '', data: seriesData }];
  }

  loadSecondVisual(visualData) {
    const dataList = _.orderBy(visualData.daxResponse, ['dIM_DATE_COMP[FISCAL_MONTH_NUMBER]'], ['asc']);
    // const categoryList = _.map(dataList, a => { return { label: a['dIM_DATE_COMP[FISCAL_MONTH_NAME]'] } });
    const seriesData = _.map(dataList, a => { return { value: a['[Retailer_PC_Units___Comp_Date]'] } });
    this.dataSeriesSecond = [{ seriesname: '', data: seriesData }];
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(4, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

  pageCommentChange(comment) {
    this.setPageCommentState(this.data.pageId, comment);
  }

  setPageCommentState(pageId, comment) {
    this.empowerModernLifeService.setPageCommentState(pageId, comment);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
