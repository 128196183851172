<div id="fb-forecast-new-planner" class="container-fluid">
  <div class="container-fluid item-wrapper fb-forecast-planner px-4">
    <!-- <div class="row method-planner-bottom my-3">
      <div class="col-md-4 fb-line align">
        <div>
          <h3>Forecast Selection</h3>
          <form class="form-element-position">
            <div class="form-group">
              <label>Accounts</label>
              <app-treeview-dropdown [isReadonly]="true" [type]="'Channel'" [items]="channelDropdownList"
                (valueChange)="setRestoredValue($event)">
              </app-treeview-dropdown>
            </div>
            <div class="form-group">
              <label>Product</label>
              <app-treeview-dropdown [isReadonly]="true" [type]="'Item'" style="z-index: 1"
                (valueChange)="setRestoredValue($event)" [items]="productList">
              </app-treeview-dropdown>
            </div>
            <div class="form-group">
              <label for="">Forecast Method</label>
              <input type="text" readonly [(ngModel)]="forecastMethod" [ngModelOptions]="{standalone: true}" />
            </div>
            <h6>Lookback Window</h6>
            <div class="row">
              <div class="form-group col-md-6">
                <label>From Week</label>
                <mat-form-field appearance="fill">
                  <input matInput readonly [matDatepicker]="lockBackFromDate" [formControl]="lookBackFromWeek">
                  <mat-datepicker-toggle matSuffix [for]="lockBackFromDate"></mat-datepicker-toggle>
                  <mat-datepicker disabled #lockBackFromDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-md-6">
                <label>To Week</label>
                <mat-form-field appearance="fill">
                  <input matInput readonly [matDatepicker]="lookBackToDate" [formControl]="lookBackToWeek">
                  <mat-datepicker-toggle matSuffix [for]="lookBackToDate"></mat-datepicker-toggle>
                  <mat-datepicker disabled #lookBackToDate></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <h6>Forecast Horizon</h6>
            <div class="row">
              <div class="form-group col-md-6">
                <label>From Week</label>
                <mat-form-field appearance="fill">
                  <input matInput readonly [matDatepicker]="fromDate" [formControl]="forecastFromWeek">
                  <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                  <mat-datepicker disabled #fromDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-md-6">
                <label>To Week</label>
                <mat-form-field appearance="fill">
                  <input matInput readonly [matDatepicker]="toWeek" [formControl]="forecastToWeek">
                  <mat-datepicker-toggle matSuffix [for]="toWeek"></mat-datepicker-toggle>
                  <mat-datepicker disabled #toWeek></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <h6>Filters</h6>
            <div class="form-group">
              <label>Select Measures</label>
              <mat-select  placeholder="" multiple
                [(ngModel)]="selectedFilterMeasures" [ngModelOptions]="{standalone: true}"
                [disableOptionCentering]="true">
                <mat-option *ngFor="let measures of forecastManagerMeasureList; let i = index"
                  [value]="measures.measureCode">
                  {{measures.measureDescription}}
                </mat-option>
              </mat-select>
            </div>
            <div class="row mb-3">
              <div class="col-md-12 btn-wrapper">
                <button class="btn btn-primary m-0" (click)="applyMeasureFilter()">
                  Apply
                </button>
              </div>
            </div>
            <div class="form-group">
              <label>Select Forecast Horizon</label>
              <app-treeview-dropdown (selectionChange)="forecastManagerCreateUserSession()" [type]="'FilterTimeFrame'"
                (valueChange)="setDataManagerValues($event)" style="z-index: 1" [items]="filterListDropdown">
              </app-treeview-dropdown>
            </div>
            <div class="row">
              <div class="col-md-12 btn-wrapper">
                <button class="btn btn-primary m-0" (click)="applyTimeframeFilter()">
                  Apply
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-4 fb-line align">
        <div>
          <h3>Refresh Measures</h3>
          <form>
            <div class="form-group">
              <label>Select Measure</label>
              <mat-select placeholder="" multiple [(ngModel)]="selectedRefreshMeasures"
                [ngModelOptions]="{standalone: true}" [disableOptionCentering]="true">
                <mat-option *ngFor="let measures of refreshMeasure; let i = index" [value]="measures.measureCode">
                  {{measures.measureDescription}}
                </mat-option>
              </mat-select>
            </div>
          </form>
          <div class="row">
            <div class="col-12 btn-wrapper">
              <button class="btn btn-primary m-0" (click)="calculateMatrixValue()">
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <h3>Forecast Update</h3>
          <form>
            <div class="form-group">
              <label>Forecast Measure</label>
              <mat-select (selectionChange)="forecastManagerCreateUserSession()" placeholder="" multiple
                [(ngModel)]="selectedForecastMeasures" [ngModelOptions]="{standalone: true}"
                [disableOptionCentering]="true">
                <mat-option *ngFor="let measures of forecastMeasure; let i = index" [value]="measures.measureCode">
                  {{measures.measureDescription}}
                </mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label>Multiplication Factor</label>
              <input type="number" [(ngModel)]="adjustment" [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="form-group">
              <label>Forecast Horizon</label>
              <app-treeview-dropdown [type]="'ForecastTimeFrame'" style="z-index: 1"
                (valueChange)="setDataManagerValues($event)" [items]="forecastListDropdown">
              </app-treeview-dropdown>
            </div>
          </form>
        </div>

        <div *ngIf="isAdjustmentAppliedAlready" class="alert alert-info mt-10">
          Info: Adjusment applied on matrix, to apply adjustment again please save the applied adjustment in matrix or reset
          the forecast to Last Saved.
        </div>

        <div class="row">
          <div class="col-md-12 btn-wrapper">
            <button class="btn btn-primary mx-1" (click)="applyAdjustment()">
              Apply
            </button>
            <button class="btn btn-primary mx-1" mat-flat-button color="accent"
              [matMenuTriggerFor]="resetMenu">Reset</button>
            <mat-menu #resetMenu="matMenu">
              <button mat-menu-item (click)="lastSavedMatrixData()">Last Saved</button>
              <button mat-menu-item (click)="resetMatrixDataToOriginal()">Original</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div> -->

    <!-- new Implementation layout start -->

    <div class="row">
      <div class="col-md-6">
        <h3>Forecast Selection</h3>
      </div>
      <div class="col-md-3">
        <h3>Lookback Window</h3>
      </div>
      <div class="col-md-3">
        <h3>Forecast Horizon</h3>
      </div>
    </div>
    <div class="row">
      <!-- <form class="form-element-position"> -->
      <div class="col-md-2 align">
        <label class="set-label">Accounts</label>
        <div class="form-group">
          <app-treeview-dropdown [isReadonly]="true" [type]="'Channel'" [items]="channelDropdownList"
            (valueChange)="setRestoredValue($event)">
          </app-treeview-dropdown>
        </div>
      </div>
      <div class="col-md-2 align">
        <label class="set-label">Product</label>
        <div class="form-group">
          <app-treeview-dropdown [isReadonly]="true" [type]="'Item'" style="z-index: 1"
            (valueChange)="setRestoredValue($event)" [items]="productList">
          </app-treeview-dropdown>
        </div>
      </div>
      <div class="col-md-2 align">
        <label class="set-label" for="">Forecast Method</label>
        <div class="form-group">
          <input type="text" readonly [(ngModel)]="forecastMethod" [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>

      <!-- <h6>Lookback Window</h6> -->
      <div class="col-md-1 align" style="max-width: 12.333333%">
        <!-- <div class="row"> -->
        <label class="set-label">From Week</label>
        <div class="form-group">
          <mat-form-field>
            <input matInput readonly [matDatepicker]="lockBackFromDate" [formControl]="lookBackFromWeek" />
            <mat-datepicker-toggle matSuffix [for]="lockBackFromDate"></mat-datepicker-toggle>
            <mat-datepicker disabled #lockBackFromDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-1 align" style="max-width: 12.333333%">
        <label class="set-label">To Week</label>
        <div class="form-group">
          <mat-form-field>
            <input matInput readonly [matDatepicker]="lookBackToDate" [formControl]="lookBackToWeek" />
            <mat-datepicker-toggle matSuffix [for]="lookBackToDate"></mat-datepicker-toggle>
            <mat-datepicker disabled #lookBackToDate></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- </div> -->
      </div>

      <!-- <h6>Forecast Horizon</h6> -->
      <div class="col-md-1 align" style="max-width: 12.333333%">
        <!-- <div class="row"> -->
        <label class="set-label">From Week</label>
        <div class="form-group">
          <mat-form-field>
            <input matInput readonly [matDatepicker]="fromDate" [formControl]="forecastFromWeek" />
            <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker disabled #fromDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-1 align" style="max-width: 12.333333%">
        <label class="set-label">To Week</label>
        <div class="form-group">
          <mat-form-field>
            <input matInput readonly [matDatepicker]="toWeek" [formControl]="forecastToWeek" />
            <mat-datepicker-toggle matSuffix [for]="toWeek"></mat-datepicker-toggle>
            <mat-datepicker disabled #toWeek></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- </div> -->
      </div>

      <!-- </form> -->
    </div>
  </div>

  <!-- GRAPH -->

  <h5 class="px-4 py-3" style="background-color: #f4f4f4">Graph View</h5>

  <div class="container-fluid m-3" id="forecast-data-graph">
    <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat"
      [dataSource]="chartDataSource">
    </fusioncharts>
  </div>

  <hr />

  <!-- new implementation -->
  <div class="container-fluid item-wrapper fb-forecast-planner px-4">
    <div class="row">
      <div class="col-md-5">
        <h3>Filters</h3>
      </div>
      <div class="col md-7">
        <h3>Forecast Update</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 align">
        <label>Select Measures</label>
        <div class="form-group">
          <mat-select placeholder="" multiple [(ngModel)]="selectedFilterMeasures"
            [ngModelOptions]="{ standalone: true }" [disableOptionCentering]="true">
            <mat-option *ngFor="let measures of forecastManagerMeasureList; let i = index"
              [value]="measures.measureCode">
              {{ measures.measureDescription }}
            </mat-option>
          </mat-select>
        </div>
        <div class="row mb-3">
          <div class="col-md-12 btn-wrapper">
            <button class="btn btn-primary m-0" (click)="applyMeasureFilter()">
              Apply
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3 fb-line align">
        <label>Select Forecast Horizon</label>
        <div class="form-group">
          <app-treeview-dropdown (selectionChange)="forecastManagerCreateUserSession()" [type]="'FilterTimeFrame'"
            (valueChange)="setDataManagerValues($event)" style="z-index: 1" [items]="filterListDropdown">
          </app-treeview-dropdown>
        </div>
        <div class="row">
          <div class="col-md-12 btn-wrapper">
            <button class="btn btn-primary m-0" (click)="applyTimeframeFilter()">
              Apply
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-2" style="margin-left: 10px">
        <label>Forecast Measure</label>
        <div class="form-group">
          <mat-select (selectionChange)="forecastManagerCreateUserSession()" placeholder="" multiple
            [(ngModel)]="selectedForecastMeasures" [ngModelOptions]="{ standalone: true }"
            [disableOptionCentering]="true">
            <mat-option *ngFor="let measures of forecastMeasure; let i = index" [value]="measures.measureCode">
              {{ measures.measureDescription }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="col-md-1" style="max-width: 15.666667%">
        <label>Multiplication Factor</label>
        <div class="form-group">
          <input type="number" [(ngModel)]="adjustment" [ngModelOptions]="{ standalone: true }" />
        </div>
        <div class="row">
          <div class="col-md-12 btn-wrapper">
            <button class="btn btn-primary mx-1" (click)="applyAdjustment()">
              Apply
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <label>Forecast Horizon</label>
        <div class="form-group">
          <app-treeview-dropdown [type]="'ForecastTimeFrame'" style="z-index: 1"
            (valueChange)="setDataManagerValues($event)" [items]="forecastListDropdown">
          </app-treeview-dropdown>
        </div>
        <div class="row">
          <div class="col-md-12 btn-wrapper">
            <button class="btn btn-primary mx-1" mat-flat-button color="accent" [matMenuTriggerFor]="resetMenu">
              Reset
            </button>
            <mat-menu #resetMenu="matMenu">
              <button mat-menu-item (click)="lastSavedMatrixData()">
                Last Saved
              </button>
              <button mat-menu-item (click)="resetMatrixDataToOriginal()">
                Original
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="isAdjustmentAppliedAlready" class="alert alert-info mt-10">
          Info: Adjusment applied on matrix, to apply adjustment again please save the applied adjustment in matrix or reset
          the forecast to Last Saved.
        </div>

        <div class="row">
          <div class="col-md-12 btn-wrapper">
            <button class="btn btn-primary mx-1" (click)="applyAdjustment()">
              Apply
            </button>
            <button class="btn btn-primary mx-1" mat-flat-button color="accent"
              [matMenuTriggerFor]="resetMenu">Reset</button>
            <mat-menu #resetMenu="matMenu">
              <button mat-menu-item (click)="lastSavedMatrixData()">Last Saved</button>
              <button mat-menu-item (click)="resetMatrixDataToOriginal()">Original</button>
            </mat-menu>
          </div>
        </div> -->
    </div>
  </div>

  <div class="row" style="background-color: #f4f4f4">
    <h5 class="px-4 py-3">{{ isItemView ? "Item View" : "Aggregate View" }}</h5>
  </div>
  <div class="row m-0 align-items-center justify-content-between mb-2 d-flex">
    <div class="d-flex">
      <div class="align-items-center mt-3">
        <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
          (click)="openColumnDialog()">
          <i class="material-icons"> ballot </i>
        </button>
      </div>
      <div class="align-items-center mx-1 my-1 w-20">
        <div class="form-group mb-0">
          <mat-form-field appearance="fill">
            <mat-label>Select Calendar Level</mat-label>
            <mat-select (selectionChange)="calendarLevelChange()" [(ngModel)]="selectedCalendarLevel"
              [ngModelOptions]="{ standalone: true }">
              <mat-option value="year">Year</mat-option>
              <mat-option value="halfyear">Half Year</mat-option>
              <mat-option value="quarter">Quarter</mat-option>
              <mat-option value="monthly">Monthly</mat-option>
              <mat-option value="weekly">Weekly</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group my-1 mx-1" style="width: 255px;">
        <!-- <label>Accounts</label> -->
        <app-treeview-dropdown [type]="'Channel'" [items]="channelFilterDropdownList"
          (valueChange)="setFilterValue($event)">
        </app-treeview-dropdown>
      </div>
      <div class="form-group my-1 mx-1" style="width: 255px;">
        <!-- <label>Product</label> -->
        <app-treeview-dropdown [type]="'Item'" style="z-index: 1" (valueChange)="setFilterValue($event)"
          [items]="productFilterList">
        </app-treeview-dropdown>
      </div>
    </div>

    <div class="d-flex align-items-center forecast-matrix-action-btns">
      <mat-slide-toggle (change)="matrixViewChange()" class="mx-1" name="status" [(ngModel)]="isItemView">
        {{ isItemView ? "Item View" : "Aggregate View" }}
      </mat-slide-toggle>

      <button mat-flat-button color="accent" (click)="refreshMatrixValue()" class="mx-1">
        Refresh
      </button>

      <button matTooltip="Export" class="grid-bar-icon mx-1" mat-icon-button color="primary"
        (click)="onExportMatrixData()">
        <i class="material-icons"> insert_drive_file </i>
      </button>

      <button class="import-export-button mx-1" mat-flat-button color="accent" [matMenuTriggerFor]="importExportMenu">
        Export/Import
      </button>
      <mat-menu #importExportMenu="matMenu">
        <button mat-menu-item (click)="exportMatrixDataTemplate()">
          Export
        </button>
        <button mat-menu-item (click)="importMatrixData()">
          Import
          <input class="hidden" type="file" id="upload-matrix-data" name="upload-matrix-data" accept=".csv"
            (change)="validateUploadedCSV($event)" />
        </button>
      </mat-menu>

      <button *ngIf="isGridDataUpdated" mat-flat-button color="accent" (click)="calculateMatrixValue()" class="mx-1">
        Apply
      </button>

      <button *ngIf="isGridDataUpdated" mat-flat-button color="warn" (click)="saveMatrixData()" class="mx-1">
        Save
      </button>
    </div>
  </div>

  <ag-grid-angular *ngIf="isGridLoaded" #forecastManagerMatrixGrid id="forecast-manager-matrix-grid"
    class="ag-theme-material" [rowHeight]="rowHeight"  [columnDefs]="columnDefs"
    [gridOptions]="gridOptions" [rowData]="gridData" [defaultColDef]="defaultColDef" [animateRows]="true"
    (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
    (cellValueChanged)="gridDataUpdated($event)">
  </ag-grid-angular>
</div>
