import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';


@Component({
  selector: 'app-page-comment',
  templateUrl: './page-comment.component.html',
  styleUrls: ['./page-comment.component.scss']
})
export class PageCommentComponent implements OnInit {

  @Output() emitPageComment?: EventEmitter<string> = new EventEmitter<string>();
  @Input() pageComment?: string;

  constructor(
  ) { }

  ngOnInit() { }

  focusOutFunction(event) {
    this.emitPageComment.emit(event.target.value);
  }

}
