import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { MasterTitleDialogComponent } from 'src/modules/item-manager/features';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class MasterProductService {

  //Variables
  selectedMasterItem: any;
  masterItemDialogRef: MatDialogRef<MasterTitleDialogComponent>;

  // Subjects
  public masterGridActionCellSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setMasterGridActionCellSubject = (value: any) => { this.masterGridActionCellSubject.next(value); };

  // Observables
  getMasterGridActionCellSubject = () => this.masterGridActionCellSubject.asObservable();


  constructor(private http: HttpClient) {
  }


  CreateMasterItem(masterItem: any) {
    return this.http.post<any>(`${environment.AppMasterItem}/add`, masterItem)
      .pipe(map(data => {
        return data;
      }));
  }

  UpdateMasterItem(masterItem: any) {
    return this.http.put(`${environment.AppMasterItem}/update`, masterItem)
      .pipe(map(data => {
        return data;
      }));
  }

  DeleteMasterItem(masterItemId: string) {
    return this.http.delete(`${environment.AppMasterItem}/delete?MasterItemId=${masterItemId}`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetAllMasterItems(requestBody) {
    return this.http.post(`${environment.AppMasterItem}/getall`, requestBody)
      .pipe(map(data => {
        return data;
      }));
  }

  GetFilterMasterItemList(body) {
    const query = Object.keys(body).map(key => {
      return `${key}=${body[key]}`;
    }).join('&');
    return this.http.get(`${environment.AppMasterItem}/getlist?${query}`)
      .pipe(map(data => {
        return data;
      }));
  }

  ExportMasterDataTemplate() {
    return this.http.get(`${environment.AppMasterItem}/getexporttemplate`)
      .pipe(map(data => {
        return data;
      }));
  }

  ImportMasterDataTemplate(body) {
    return this.http.post(`${environment.AppMasterItem}/import`, body)
      .pipe(map(data => {
        return data;
      }));
  }

}
