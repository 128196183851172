import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'checkbox-renderer',
  template:
    `<mat-checkbox [ngModel]="params.value" (ngModelChange)="checkedHandler($event)">
    </mat-checkbox>`
})
export class CheckboxRenderer implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params?: any): boolean {
    return true;
  }

  checkedHandler(event: boolean) {
      let checked = event;
      let colId = this.params.column.colId;
      this.params.node.setDataValue(colId, checked);
  }
}
