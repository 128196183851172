import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlanogramService } from 'src/services/Item-Management-Services/planogram.service';
@Component({
  selector: 'planogram-card',
  templateUrl: './planogram-card.component.html',
  styleUrls: ['./planogram-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlanogramCardComponent implements OnInit {

  @Input() cardData: {
    id: number,
    title: string,
    weeks_old: number,
    tw_forecast: number,
    lock_tile: boolean,
    label: any
  }

  @Input() index: number;

  @Input() newWeekData: boolean;

  lockUpdateSubscription: Subscription;

  constructor(private planogramService: PlanogramService) { }

  ngOnInit(): void {
  }

  changeLock() {
    if (this.cardData.lock_tile) this.cardData.lock_tile = false;
    else this.cardData.lock_tile = true;
    if (this.lockUpdateSubscription) this.lockUpdateSubscription.unsubscribe();
    this.lockUpdateSubscription = this.planogramService.updatePlanogramData(this.cardData.id, {lock_tile: this.cardData.lock_tile})
    .subscribe((res: any) => {
    },
    err => {
      this.cardData.lock_tile = !this.cardData.lock_tile;
    });
  }

}
