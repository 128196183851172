<div>
  <div class="dialog-close-btn">
    <h1 mat-dialog-title>Item Hierarchy Setup</h1>
    <button mat-icon-button (click)="onCloseClick()">
      <i class="material-icons">
        close
      </i>
    </button>
  </div>

  <div mat-dialog-content style="overflow: hidden;">

    <div class="item-wrapper">
      <form class="hierarchy-form">
        <div class="row">

          <ng-container *ngFor="let item of itemHierarchyManagerLevel">
            <div class="form-group col-6">
              <label [for]="item.productHierarchyDisplayName">{{ item.productHierarchyDisplayName + '
                (H'+item.productHierarchyLevel +')' }}</label>

              <input [name]="item.productHierarchyDisplayName" [matAutocomplete]="itemauto"
                [(ngModel)]="item.productHierarchyValue" [ngModelOptions]="{standalone: true}"
                (input)="_filterItemLabels($event, item)" (focus)="onItemControlFocus($event, item)"
                [title]="item.productHierarchyValue ? item.productHierarchyValue : ''" />

              <mat-autocomplete [displayWith]="displayItemFn" #itemauto="matAutocomplete"
                (optionSelected)="itemValueChange($event.option.value, item)">
                <mat-option *ngFor="let item of filteredLookupItems | async" [value]="item">
                  {{item.productHierarchyValue}}
                </mat-option>
              </mat-autocomplete>

            </div>
          </ng-container>

        </div>
      </form>
    </div>
  </div>

  <div mat-dialog-actions class="dialog-action-btn">
    <button mat-stroked-button color="accent" (click)="onCloseClick()">Cancel</button>
    <button mat-flat-button color="accent" (click)="onSubmit()">Save</button>
  </div>

</div>