import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { ConfigurationService } from 'src/services/configuration.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
@Component({
  selector: 'app-facebook-product-settings',
  templateUrl: './facebook-product-settings.component.html',
  styleUrls: ['./facebook-product-settings.component.scss']
})
export class FacebookProductSettingsComponent implements OnInit {
  @ViewChild('brandTemplate') public brandTemplate: TemplateRef<any>;
  @ViewChild('productGroupTemplate') public productGroupTemplate: TemplateRef<any>;
  @ViewChild('productFamilyTemplate') public productFamilyTemplate: TemplateRef<any>;
  @ViewChild('parentProductTemplate') public parentProductTemplate: TemplateRef<any>;
  public treeViewList = [];
  public itemsList = [];
  constructor(
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogAddRef: MatDialogRef<any>,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
  }

  ngOnInit() {
    this.populateTreeView();
  }
  public populateTreeView = () => {
    const params = {
      offset: 0,
      pageSize: 100000,
      active: true
    }
    let url = `offSet=${params.offset ? params.offset : 0}&pageSize=${params.pageSize ? params.pageSize : 10}&active=true`
    this.configurationService.ItemManagerGetAll(url).subscribe(res => {
      this.itemsList = (res.data || []);
      this.GetProductSettingList();
    });
  }
  public GetProductSettingList = (forecast?: any) => {
    this.spinner.show();
    this.configurationService.ProductSettingMappingGetList({}).subscribe((res) => {
      const productSettingList = res;
      const brandIds = [];
      productSettingList.forEach(account => {
        if (brandIds.includes(account.brandId)) {
        } else {
          brandIds.push(account.brandId);
        }
      });
      let treeList = [];
      brandIds.forEach(brand => {
        let brandObject = {
          brandName: productSettingList.find(acc => acc.brandId === brand) ? productSettingList.find(acc => acc.brandId === brand).brandName : '',
          name: productSettingList.find(acc => acc.brandId === brand) ? productSettingList.find(acc => acc.brandId === brand).brandName : '',
          brandId: brand,
          children: [],
          isBrand: true,
          isDelete: true
        }
        const productGroupIds = productSettingList.filter(el => el.brandId === brand).map(el => el.productGroupId).filter((value, index, self) => self.indexOf(value) === index);
        productGroupIds.forEach(productGroupId => {
          let productGroupObject = {
            productGroupName: productSettingList.find(acc => acc.productGroupId === productGroupId) ? productSettingList.find(acc => acc.productGroupId === productGroupId).productGroupName : '',
            name: productSettingList.find(acc => acc.productGroupId === productGroupId) ? productSettingList.find(acc => acc.productGroupId === productGroupId).productGroupName : '',
            productGroupId: productGroupId,
            brandId: brand,
            children: [],
            isProductGroup: true,
            isDelete: true
          }
          const productFamilyIds = productSettingList.filter(el => el.productGroupId === productGroupId && el.brandId === brand).map(el => el.productFamilyId).filter((value, index, self) => self.indexOf(value) === index);
          productFamilyIds.forEach(productFamilyId => {
            let productFamilyObject = {
              productFamilyName: productSettingList.find(acc => acc.productFamilyId === productFamilyId) ? productSettingList.find(acc => acc.productFamilyId === productFamilyId).productFamilyName : '',
              name: productSettingList.find(acc => acc.productFamilyId === productFamilyId) ? productSettingList.find(acc => acc.productFamilyId === productFamilyId).productFamilyName : '',
              productFamilyId: productFamilyId,
              productGroupId: productGroupId,
              brandId: brand,
              children: [],
              isProductFamily: true,
              isDelete: true
            }
            const parentProductIds = productSettingList.filter(el => el.productFamilyId === productFamilyId && el.productGroupId === productGroupId && el.brandId === brand).map(el => el.parentProductId).filter((value, index, self) => self.indexOf(value) === index);
            parentProductIds.forEach(parentProductId => {
              let products = this.itemsList.filter(row => row.parentProductId === parentProductId && row.productFamilyId === productFamilyId && row.productGroupId === productGroupId && row.brandId === brand)
              products = products.map(row => ({ name: row.description, isProduct: true, ...row }))
              let parentProductObject = {
                parentProductName: productSettingList.find(acc => acc.parentProductId === parentProductId) ? productSettingList.find(acc => acc.parentProductId === parentProductId).parentProductName : '',
                name: productSettingList.find(acc => acc.parentProductId === parentProductId) ? productSettingList.find(acc => acc.parentProductId === parentProductId).parentProductName : '',
                parentProductId: parentProductId,
                productGroupId: productGroupId,
                productFamilyId: productFamilyId,
                brandId: brand,
                children: products,
                isParentProduct: true,
                isDelete: true
              }
              if (parentProductObject.parentProductId && parentProductObject.children.length > 0) {
                brandObject.isDelete = false;
                productGroupObject.isDelete = false;
                productFamilyObject.isDelete = false;
                parentProductObject.isDelete = false;
              }
              if (parentProductObject.parentProductId) {
                productFamilyObject.children.push(parentProductObject)
              }
            });
            if (productFamilyObject.productFamilyId) {
              productGroupObject.children.push(productFamilyObject)
            }
          });
          if (productGroupObject.productGroupId) {
            brandObject.children.push(productGroupObject)
          }
        });
        treeList.push(brandObject);
      });
      this.treeViewList = treeList;
      this.spinner.hide();
    });
  }
  public openDialog = (selectedItem) => {
    selectedItem.isEdit = false;
    if (selectedItem.isBrand) {
      selectedItem.productGroupName = '';
      this.dialogRef = this.dialog.open(this.productGroupTemplate, { data: selectedItem });
    }
    else if (selectedItem.isProductGroup) {
      selectedItem.productFamilyName = '';
      this.dialogRef = this.dialog.open(this.productFamilyTemplate, { data: selectedItem });
    }
    else if (selectedItem.isProductFamily) {
      selectedItem.parentProductName = '';
      this.dialogRef = this.dialog.open(this.parentProductTemplate, { data: selectedItem });
    } else if (selectedItem.isProductFamily) {
      return;
    } else {
      selectedItem.brandName = '';
      this.dialogRef = this.dialog.open(this.brandTemplate, { data: selectedItem });
    }
  }
  public openEditDialog = (selectedItem) => {
    selectedItem.isEdit = true;
    if (selectedItem.isBrand) {
      this.dialogRef = this.dialog.open(this.brandTemplate, { data: selectedItem });
    }
    else if (selectedItem.isProductGroup) {
      this.dialogRef = this.dialog.open(this.productGroupTemplate, { data: selectedItem });
    }
    else if (selectedItem.isProductFamily) {
      this.dialogRef = this.dialog.open(this.productFamilyTemplate, { data: selectedItem });
    }
    else if (selectedItem.isParentProduct) {
      this.dialogRef = this.dialog.open(this.parentProductTemplate, { data: selectedItem });
    }
  }
  public onSubmitBrand = (brandObject) => {
    const model = {
      ...brandObject
    }
    this.spinner.show();
    if (brandObject.isEdit) {
      this.configurationService.ProductSettingMappingUpdateBrand(model).subscribe(res => {
        this.dialogRef.close();
        this.treeViewList.forEach(brand => {
          if (
            brand.brandId === model.brandId
          ) {
            brand.brandName = model.brandName;
            brand.name = model.brandName;
          }
        });
        this.spinner.hide();
      })
    } else {
      this.configurationService.ProductSettingMappingAddBrand(model).subscribe(res => {
        this.dialogRef.close();
        model.brandId = res;
        model.name = model.brandName;
        model.isDelete = true;
        model.isBrand = true;
        model.children = [];
        this.treeViewList.push(model);
      });
      this.spinner.hide();
    }

  }
  public onSubmitProductGroup = (ContinentObject) => {
    const model = {
      ...ContinentObject
    }
    this.spinner.show();
    if (ContinentObject.isEdit) {
      this.configurationService.ProductSettingMappingUpdateProductGroup(model).subscribe(res => {
        this.dialogRef.close();
        model.name = model.productGroupName;
        model.isDelete = true;
        model.isProductGroup = true;
        model.children = [],
          this.treeViewList.forEach(brand => {
            brand.children.forEach(productGroup => {
              if (
                brand.brandId === model.brandId &&
                model.productGroupId === productGroup.productGroupId
              ) {
                productGroup.productGroupName = model.productGroupName;
                productGroup.name = model.productGroupName;
              }
            });
          });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.configurationService.ProductSettingMappingAddProductGroup(model).subscribe(res => {
        this.dialogRef.close();
        model.name = model.productGroupName;
        let productGroupObject = {
          productGroupName: model.productGroupName,
          name: model.productGroupName,
          productGroupId: res,
          brandId: model.brandId,
          children: [],
          isProductGroup: true,
          isDelete: true
        }
        this.treeViewList.forEach(brand => {
          if (brand.brandId === model.brandId) {
            brand.children.push(productGroupObject);
          }
        });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    }

  }
  public onSubmitProductFamily = (productFamilyObject) => {
    const model = {
      ...productFamilyObject
    }
    this.spinner.show();
    if (productFamilyObject.isEdit) {
      this.configurationService.ProductSettingMappingUpdateProductFamily(model).subscribe(res => {
        this.dialogRef.close();
        this.treeViewList.forEach(brand => {
          brand.children.forEach(productGroup => {
            productGroup.children.forEach(productFamily => {
              if (
                brand.brandId === model.brandId &&
                model.productGroupId === productGroup.productGroupId &&
                model.productFamilyId === productFamily.productFamilyId
              ) {
                productFamily.productFamilyName = model.productFamilyName;
                productFamily.name = model.productFamilyName;
              }
            });
          });
        });
        this.spinner.hide();

      }, error => {
        this.spinner.hide();
      })
    } else {
      this.configurationService.ProductSettingMappingAddProductFamily(model).subscribe(res => {
        this.dialogRef.close();
        let productFamilyObject = {
          productFamilyName: model.productFamilyName,
          name: model.productFamilyName,
          productFamilyId: res,
          productGroupId: model.productGroupId,
          brandId: model.brandId,
          children: [],
          isProductFamily: true,
          isDelete: true
        }
        this.treeViewList.forEach(brand => {
          brand.children.forEach(productGroup => {
            if (brand.brandId === model.brandId && model.productGroupId === productGroup.productGroupId) {
              productGroup.children.push(productFamilyObject);
            }
          });
          this.spinner.hide();
        });
      }, error => {
        this.spinner.hide();
      })
    }

  }
  public onSubmitParentProduct = (productFamilyObject) => {
    const model = {
      ...productFamilyObject
    }
    this.spinner.show();
    if (productFamilyObject.isEdit) {
      this.configurationService.ProductSettingMappingUpdateParentProduct(model).subscribe(res => {
        this.dialogRef.close();
        this.treeViewList.forEach(brand => {
          brand.children.forEach(productGroup => {
            productGroup.children.forEach(productFamily => {
              productFamily.children.forEach(parentProduct => {
                if (
                  brand.brandId === model.brandId &&
                  model.productGroupId === productGroup.productGroupId &&
                  model.productFamilyId === productFamily.productFamilyId &&
                  model.parentProductId === parentProduct.parentProductId
                ) {
                  parentProduct.parentProductName = model.parentProductName;
                  parentProduct.name = model.parentProductName;
                }
              });
            });
          });
        });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.configurationService.ProductSettingMappingAddParentProduct(model).subscribe(res => {
        this.dialogRef.close();
        let parentProductObject = {
          parentProductName: model.parentProductName,
          name: model.parentProductName,
          parentProductId: res,
          productGroupId: model.productGroupId,
          productFamilyId: model.productFamilyId,
          brandId: model.brandId,
          children: [],
          isParentProduct: true,
          isDelete: true
        }
        this.treeViewList.forEach(brand => {
          brand.children.forEach(productGroup => {
            productGroup.children.forEach(productFamily => {
              if (brand.brandId === model.brandId && model.productGroupId === productGroup.productGroupId && model.productFamilyId === productFamily.productFamilyId) {
                productFamily.children.push(parentProductObject);
              }
            });
          });
        });
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      })
    }
  }
  openConfirmationDialog(nodeData: any) {
    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { ...nodeData, headerName: nodeData.name }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeNode(result);
      }
    });
  }
  public removeNode = (nodeData: any) => {
    const model = {
      ...nodeData,
      active: false
    };
    if (nodeData.isDelete || nodeData.isProduct) {
      this.spinner.show();
      if (nodeData.isBrand) {
        this.configurationService.ProductSettingMappingActivateBrand(model).subscribe(flag => {
          this.treeViewList = this.treeViewList.filter(brand => brand.brandId !== model.brandId);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else if (nodeData.isProductGroup) {
        this.configurationService.ProductSettingMappingActivateProductGroup(model).subscribe(flag => {
          this.treeViewList.forEach(brand => {
            brand.children = brand.children.filter(productGroup => productGroup.productGroupId !== model.productGroupId)
          });
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else if (nodeData.isProductFamily) {
        this.configurationService.ProductSettingMappingActivateProductFamily(model).subscribe(flag => {
          this.treeViewList.forEach(brand => {
            brand.children.forEach(productGroup => {
              productGroup.children = productGroup.children.filter(productFamily => productFamily.productFamilyId !== model.productFamilyId)
            });
          });
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else if (nodeData.isParentProduct) {
        this.configurationService.ProductSettingMappingActivateParentProduct(model).subscribe(flag => {
          this.treeViewList.forEach(brand => {
            brand.children.forEach(productGroup => {
              productGroup.children.forEach(productFamily => {
                productFamily.children = productFamily.children.filter(parentProduct => parentProduct.parentProductId !== model.parentProductId)
              });
            });
          });
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else if (nodeData.isProduct) {
        this.configurationService.ItemManagerActivate(model).subscribe(flag => {
          this.treeViewList.forEach(brand => {
            brand.children.forEach(productGroup => {
              productGroup.children.forEach(productFamily => {
                productFamily.children.forEach(parentProduct => {
                  parentProduct.children = parentProduct.children.filter(itemManager => itemManager.itemManagerId !== model.itemManagerId)
                  if (parentProduct.children.length < 1) {
                    brand.isDelete = true;
                    productGroup.isDelete = true;
                    productFamily.isDelete = true;
                    parentProduct.isDelete = true;
                  }
                });
              });
            });
          });
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    } else {
      this.toastr.info('', `${nodeData.name} cannot be deleted because it is associated to an item.`)
    }
  }
  public getHeading = (item) => {
    if (item.isBrand) {
      return 'Brand:'
    }
    if (item.isProductGroup) {
      return 'Product Group:'
    }
    if (item.isProductFamily) {
      return 'Product Family:'
    }
    if (item.isParentProduct) {
      return 'Parent Product:'
    }
    if (item.isProduct) {
      return 'Product:'
    }
  }
}
