import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  public isEdit = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public location: Location,
    public route: ActivatedRoute) { }

  ngOnInit() {
    console.log(this.data);
  }

  get confirmationMessage(): string {
    if(this.data.payload){
      return this.data.confirmationMessage || `Are you sure want to save Data ?`;
    }else {
      return this.data.confirmationMessage || `Are you sure want to delete ${this.data.headerName}?`;
    }
  }

  public saveAndClose = () => {
    this.dialogRef.close(this.data);
  }

  public onNoClick = () => {
    this.dialogRef.close();
  }
}
