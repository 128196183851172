<div mat-dialog-content>
    <h5><strong>Manage Exceptions</strong></h5>
    <div class="row">
        <div class="col-md-6">
            <strong>Select Column</strong>
            <mat-form-field appearance="fill">
                <mat-select [(ngModel)]="selectedColumn" (selectionChange)="selectedColumnChanged()">
                    <mat-option *ngFor="let item of columns" [value]="item.field">
                        {{item.headerName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <hr class="theme-hr" />

    <form [formGroup]="exceptionsForm">
        <div [formGroupName]="selectedColumn">
            <div formArrayName="rules" style="overflow-x: auto;">
                <div class="row m-0 flex-nowrap" *ngFor="let item of selectedFormArray.controls; let i=index" [formGroupName]="i">
                    <div class="d-flex align-items-center">
                        <small class="mr-2"><strong>Where value</strong></small>
                        <mat-form-field class="mr-2" appearance="fill">
                            <mat-select formControlName="filter_left">
                                <mat-option *ngFor="let item of conditionsDropdownData" [value]="item.id">
                                    {{item.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="mr-2" appearance="fill">
                            <input matInput type="number" formControlName="value_left" />
                        </mat-form-field>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                        <mat-radio-group formControlName="gate">
                            <mat-radio-button class="mx-1" value="&">And</mat-radio-button>
                            <mat-radio-button class="mx-1" value="|">Or</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="d-flex align-items-center">
                        <mat-form-field class="mr-2" appearance="fill">
                            <mat-select formControlName="filter_right">
                                <mat-option *ngFor="let item of conditionsDropdownData" [value]="item.id">
                                    {{item.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="mr-2" appearance="fill">
                            <input matInput type="number" formControlName="value_right"/>
                        </mat-form-field>
                        <small class="mr-2"><strong>Mark as exception</strong></small>
                        <mat-icon color="warn" (click)="removeException(i)" class="cursor-pointer" matTooltip="Remove Rule">delete</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <h6 *ngIf="selectedFormArray.controls.length == 0" class="text-center p-4">
        No rule added for this column
    </h6>
    <hr class="theme-hr" />
</div>
<div mat-dialog-actions class="justify-content-between">
    <button mat-mini-fab color="accent" matTooltip="Add New Rule" (click)="addNewExceptionHandler()">
        <mat-icon>add</mat-icon>
    </button>
    <div class="d-flex align-items-center">
        <button class="mr-2" mat-stroked-button color="accent" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="accent" (click)="onSaveClick()" [disabled]="exceptionsForm.invalid">Save</button>
    </div>
</div>
