<div class="container-fluid">

  <form [formGroup]="filtersForm" class="row product-grid-filter-row mt-10 mx-2">
    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <input formControlName="prTitle" matInput type="search" placeholder="Title">
        <mat-icon class="search-action-icon" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>

    <div class="product-filter-field mx-1">
      <mat-form-field appearance="fill">
        <mat-select formControlName="statusDescription" placeholder="Select statuses">
          <mat-option value="PendingBid">Pending Bids</mat-option>
          <mat-option value="BidsCalled">Bids Called</mat-option>
          <mat-option value="Closed">Closed</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="product-filter-clear-btn">
      <button (click)="clearFilter()" mat-flat-button color="accent">Clear</button>
    </div>
  </form>

  <div class="grid-action-btn-bar">
    <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>
    <button class="ml-10" *ngIf="cClaim.PMSClaims.U_TYPE2" [disabled]="isRowSelected" (click)="addEditItem()"
      mat-flat-button color="accent">Create Bid</button>
  </div>

  <div class="all-show-grid">
    <ag-grid-angular class="ag-theme-material" [suppressMenuHide]="true" groupHeaders toolPanelSuppressGroups
      toolPanelSuppressValues [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
      [columnDefs]="gridColumnDefs" [rowData]="dataSource" (cellClicked)="cellClicked($event)" [pagination]="true"
      [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" (selectionChanged)="rowSelectionChanged($event)"
      [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" (gridReady)="onGridReady($event)"
       #bidsGrid>
    </ag-grid-angular>
  </div>

</div>
