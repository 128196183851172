import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlanogramService } from 'src/services/Item-Management-Services/planogram.service';

@Component({
  selector: 'planogram-item-action-cell-renderer',
  template: `<div class="grid-action-cell p-1">
        <span class="material-icons" (click)="actionClickHandler('delete')">delete</span>
        <span class="material-icons ml-1" (click)="actionClickHandler('edit')">edit_note</span>
    </div>`,
})
export class PlanogramItemActionCellRenderer implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor(private planogramService: PlanogramService) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  actionClickHandler(action) {
    this.planogramService.planogramItemActionHandler.next({ action: action, actionData: this.params.data });
  }

  refresh(params?: any): boolean {
    return true;
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}
