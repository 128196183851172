import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { PlotlyHTMLElement } from 'plotly.js';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { colorCodes } from 'src/modules/clustering-forecast/constants/graphColors';

@Component({
  selector: 'comparison-tab',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() charts: Array<any>;
  @Input() runs: Number;
  @Input() resizeGraphSubject: Subject<boolean>;
  @Input() versionNo: any;

  @ViewChildren('radarChart') containerDivs: QueryList<ElementRef>;

  radarPlot = {
    data: [
      {
        r: [39, 28, 8, 7, 28, 39],
        theta: ['A', 'B', 'C', 'D', 'E', 'A'],
        type: 'scatterpolar',
        fill: 'toself',
        name: 'Group A'
      },
      {
        r: [5, 14, 30, 17, 25],
        theta: ['A', 'B', 'C', 'D', 'F'],
        type: 'scatterpolar',
        fill: 'toself',
        name: 'Group B'
      }
    ],
    layout: {
      autosize: true,
      legend: {"orientation": "h"}
    }
  };

  colorCodes = colorCodes;

  constructor(private plotlyRef: PlotlyService) { }

  ngOnInit(): void {
    // for window resize handling (graph responsiveness)
    fromEvent(window, 'resize').pipe(
      debounceTime(250))
      .subscribe(() => this.refreshAll());

    this.resizeGraphSubject
      .pipe(debounceTime(250))
      .subscribe(() => this.refreshAll());

  }

  ngAfterViewInit() {
    this.plotGraphs();
  }

  ngOnChanges() {
    this.plotGraphs();
  }

  variablesSelectionChanged(index: number) {
     
    const spiderChartData = [];
    const theta = [];

    this.charts[index].spiderPlot.columns.forEach(element => {
      if (this.charts[index].spiderVariables.indexOf(element) > -1) {
        this.charts[index].metrices.forEach((metricVariable) => {
          metricVariable.id == element && theta.push(metricVariable.name);
        });
      }
    });

    for (let i = 0; i < this.charts[index].spiderPlot.cluster_ids.length; i++) {
      let clusterName = '';
      this.charts[index].clusteringLabels.forEach(element => {
        (element.label_id == this.charts[index].spiderPlot.cluster_ids[i]) ? clusterName = element.label_name : null;
      });
      const r = this.charts[index].spiderPlot.spider_plot[i];

      const spiderChartDataObj = {
        r: r,
        theta: theta,
        type: 'scatterpolar',
        fill: 'toself',
        name: clusterName,
        marker: {color: this.colorCodes[(i)%20]}
      } as any;
      this.charts[index].dataPlot[i].visible && (spiderChartDataObj.visible = this.charts[index].dataPlot[i].visible);
      spiderChartData.push(spiderChartDataObj);
    }
    this.charts[index].dataPlot = spiderChartData;

    setTimeout(() => {
      this.refreshGraph(this.charts[index].dataPlot, this.charts[index].layout, (this.containerDivs as any)._results[index].nativeElement);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 50);
    }, 100);
  }

  plotGraphs() {
    setTimeout(() => {
      this.containerDivs && this.containerDivs.forEach((e, index) => {
        
        const spiderChartData = [];
        const theta = [];

        this.charts[index].spiderPlot.columns.forEach(element => {
          if (this.charts[index].spiderVariables.indexOf(element) > -1) {
            this.charts[index].metrices.forEach((metricVariable) => {
              metricVariable.id == element && theta.push(metricVariable.name);
            });
          }
        });

        for (let i = 0; i < this.charts[index].spiderPlot.cluster_ids.length; i++) {
          let clusterName = '';
          this.charts[index].clusteringLabels.forEach(element => {
            (element.label_id == this.charts[index].spiderPlot.cluster_ids[i]) ? clusterName = element.label_name : null;
          });
          const r = this.charts[index].spiderPlot.spider_plot[i];

          const spiderChartDataObj = {
            r: r,
            theta: theta,
            type: 'scatterpolar',
            fill: 'toself',
            name: clusterName,
            marker: {color: this.colorCodes[(i)%20]}
          } as any;
          spiderChartData.push(spiderChartDataObj);
        }
        this.charts[index].dataPlot = spiderChartData;
        this.charts[index].layout = JSON.parse(JSON.stringify(this.radarPlot.layout));
      });
      this.appendGraphs();
    }, 100);
  }

  appendGraphs() {
    setTimeout(() => {
      this.containerDivs && this.containerDivs.forEach((e, index) => {
        this.plotGraph(this.charts[index].dataPlot, this.charts[index].layout, e.nativeElement);
      });
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 50);
    }, 20);
  }

  plotGraph(data, layout, htmlDivElement: HTMLDivElement) {
    this.plotlyRef.newPlot(
      htmlDivElement,
      data,
      layout
    ).then((res: PlotlyHTMLElement) => {
    });
  }

  refreshAll() {
    this.containerDivs && this.containerDivs.forEach((e, index) => {
      this.refreshGraph(this.charts[index].dataPlot, this.charts[index].layout, e.nativeElement);
    });
  }

  refreshGraph(data, layout, htmlDivElement) {
    this.plotlyRef.update(htmlDivElement, data, layout);
  }

}
