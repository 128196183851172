

export const getItemHierarchyColDefs = (filterHierarchyManagerLevelModel: any = [], componentSelf) => {
  const colDefs: any = [];
  filterHierarchyManagerLevelModel.forEach(item => {
    colDefs.push({
      headerName: `${item.productHierarchyDisplayName} (H${item.productHierarchyLevel})`,
      field: `h${item.productHierarchyLevel}Value`,
      ...item.productHierarchyLevel === 1 && {
        cellRenderer: 'nameRenderer',
        cellRendererParams: {
          onClick: componentSelf.navigateToHierarchySetup.bind(componentSelf),
        }
      }
    });
  });

  colDefs.push({
    headerName: '',
    show: true,
    field: 'delete',
    resizable: true,
    filter: false,
    pinned: 'right',
    cellRenderer: 'deleteButtonRenderer',
    cellRendererParams: {
      onClick: componentSelf.deleteItemHierarchyManager.bind(componentSelf),
    },
    width: 80
  });
  return colDefs;
}

export const getLocationHierarchyColDefs = (filterHierarchyManagerLevelModel: any = [], componentSelf) => {
  const colDefs: any = [];
  filterHierarchyManagerLevelModel.forEach(item => {
    colDefs.push({
      headerName: `${item.locationHierarchyDisplayName} (L${item.locationHierarchyLevel})`,
      field: `l${item.locationHierarchyLevel}Value`,
      ...item.locationHierarchyLevel === 1 && {
        cellRenderer: 'nameRenderer',
        cellRendererParams: {
          onClick: componentSelf.navigateToHierarchySetup.bind(componentSelf),
        }
      }
    });
  });

  colDefs.push({
    headerName: '',
    show: true,
    field: 'delete',
    resizable: true,
    filter: false,
    pinned: 'right',
    cellRenderer: 'deleteButtonRenderer',
    cellRendererParams: {
      onClick: componentSelf.deleteLocationHierarchyManager.bind(componentSelf),
    },
    width: 80
  });
  return colDefs;
}


export const getItemHierarchyHiddenColDefs = (filterHierarchyManagerLevelModel: any = {}) => {
  filterHierarchyManagerLevelModel = Object.values(filterHierarchyManagerLevelModel);
  let colDefs: any[] = [];
  for (let i = 0; i < filterHierarchyManagerLevelModel.length; i++) {
    colDefs.push({
      headerName: 'H' + filterHierarchyManagerLevelModel[i].productHierarchyLevel,
      field: 'H' + filterHierarchyManagerLevelModel[i].productHierarchyLevel
    })
  }
  return colDefs;
}

export const getLocationHierarchyHiddenColDefs = (filterHierarchyManagerLevelModel: any = {}) => {
  filterHierarchyManagerLevelModel = Object.values(filterHierarchyManagerLevelModel);
  let colDefs: any[] = [];
  for (let i = 0; i < filterHierarchyManagerLevelModel.length; i++) {
    colDefs.push({
      headerName: 'L' + filterHierarchyManagerLevelModel[i].locationHierarchyLevel,
      field: 'L' + filterHierarchyManagerLevelModel[i].locationHierarchyLevel
    })
  }
  return colDefs;
}
