<!-- no-data-found  should be applied for no data -->
<!-- [ngClass]="'no-data-found'"   -->
<main id='mainContent' class='main-content'>
  <div class="notification-alert" *ngIf="show">
    <div class="notifications-alerts">
      <div class="notification-head">
        <div class="notification-title">
          <h2>Notifications</h2>
          <button mat-flat-button class="btn-primary">{{notificationList.length}}</button>
        </div>
        <div class="notification-cancel" [style.cursor]="'pointer'">
          <span class="material-icons" matTooltip="Mark all Read" matTooltipPosition="above">
            done_all
          </span>
          <span (click)="show = false" class="material-icons cross" matTooltip="Remove" matTooltipPosition="above">
            close
          </span>
        </div>
      </div>
      <div class="notification-new">
        <div *ngFor="let item of notificationList; let i=index" class="notification-info" [class.notify-read]="i%2==0">
          <div class="notification-details">
            <span class="material-icons notify">
              notifications_none
            </span>
            <div style="cursor: pointer;" (click)="openMenu(item)">
              <p>{{item.user?.firstName}} {{item.user?.lastName}} shared {{item.menu}} dashboard with you</p>
              <div class="notification-time">
                <span class="material-icons">
                  access_time
                </span>
                <p>{{date | date: 'hh:mm a'}}</p>
              </div>
            </div>
          </div>
          <div class="actions">
            <span class="material-icons cross" matTooltip="Remove" matTooltipPosition="above">
              close
            </span>
            <span class="material-icons check" matTooltip="Mark As Read" matTooltipPosition="above">
              check_circle
            </span>
          </div>
        </div>
        <a href="#" class="all-notify text-center">All Notifications</a>
      </div>
    </div>
  </div>

  <div class="right-sidenav">
    <mat-drawer-container id="rightSidenav">
      <mat-drawer-content>
        <!-- <button mat-icon-button (click)="onOpenedChange(sidenav.opened); sidenav.toggle()" class="toggleBtn">
          <span class="material-icons">
            keyboard_arrow_left
          </span>
        </button> -->
        <div class="journey-notification" >

        <button mat-icon-button (click)="onJourneyClick($event)"
          class="toggleBtn">
          <img src="assets/images/line-segment.png" matTooltip="Journey" matTooltipPosition="left">
          <div class="journey-notification-icon">{{unreadJourneyCount}}</div>
        </button>
      </div>
        <div class="notification-alert">
          <div class="alert-num">
            <span class="material-icons bell" (click)="toggleAlert()">
              notifications
            </span>
            <p>{{notificationList.length}}</p>
          </div>
        </div>
        <!-- <button mat-icon-button (click)="takeScreenshot()" [class.spinner]="loading" class="toggleBtn ">
          <span class="material-icons">
            camera_alt
          </span>
        </button> -->
        <!-- <hr> -->
        <button mat-icon-button (click)="onOpenedChange(sidenav.opened); sidenav.toggle()"
          class="toggleBtn button-notes">
          <span class="material-icons">
            note_add
          </span>
        </button>
      </mat-drawer-content>
      <mat-drawer #sidenav style="color:black" mode="side" position="end">
        <mat-card class="notes-head">
          <mat-card-header (appClickOutside)="search = false">
            <mat-card-title-group *ngIf="!search; else searchInput">
              <mat-card-title>
                Create
              </mat-card-title>
              <mat-card-subtitle>
                Notes
              </mat-card-subtitle>
            </mat-card-title-group>
            <div fxFlex></div>
            <div class="notes-actions">
              <span class="material-icons" (click)="search = true">
                search
              </span>
              <span (click)="onOpenedChange(sidenav.opened); sidenav.toggle()" class="material-icons">
                clear
              </span>
            </div>
          </mat-card-header>
        </mat-card>
        <div class="notes-inner-body">
          <div *ngIf="!takeNote" class="notes-action-btn">
            <button (click)="addNewNote()" mat-raised-button>
              <i class="icon-add"></i>
              Take a note...

            </button>
          </div>
          <div *ngIf="takeNote" (appClickOutside)="showButton()" class="notes-final-view">
            <mat-card style="width: 100%;">
              <mat-card-header>
                <mat-form-field floatLabel="never">
                  <input [(ngModel)]="noteName" [ngModelOptions]="{standalone:true}" matInput placeholder="Title">
                </mat-form-field>
                <div fxFlex></div>
                <div class="right-user">
                  <div class="btn-wrapper">
                    <button mat-button [matMenuTriggerFor]="menu">
                      <span class="material-icons">
                        more_vert
                      </span>
                    </button>
                    <mat-menu #menu="matMenu">
                      <a class="dropdown-item" href="#"
                        style="display: flex; align-items: center; font-size: 14px;">Delete</a>
                    </mat-menu>
                  </div>
                </div>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field floatLabel="never">
                  <input [(ngModel)]="content" [ngModelOptions]="{standalone:true}" matInput placeholder="Take a note">
                </mat-form-field>
              </mat-card-content>
              <div class="action-panel text-right">
                <button (click)="addNotes()" mat-raised-button>
                  Done
                </button>
              </div>
            </mat-card>
          </div>
          <br>
          <div *ngIf="noteList.length > 0" class="notes-list">
            <ng-container *ngFor="let item of noteList">
              <mat-card style="width: 100%;    margin-bottom: 5px;">
                <div>
                  <mat-card-header>
                    <span>{{item.noteName}}</span>
                  </mat-card-header>
                  <mat-card-content>
                    <span>{{item.content}}</span>
                  </mat-card-content>
                </div>

                <div class="right-user">
                  <div class="btn-wrapper">
                    <button mat-button [matMenuTriggerFor]="menu">
                      <span class="material-icons">
                        more_vert
                      </span>
                    </button>
                    <mat-menu #menu="matMenu">
                      <a class="dropdown-item" (click)="deleteNote(item)"
                        style="display: flex; align-items: center; font-size: 14px;">Delete</a>
                      <a class="dropdown-item" (click)="editNote(item)"
                        style="display: flex; align-items: center; font-size: 14px;">Edit</a>
                    </mat-menu>
                  </div>
                </div>
              </mat-card>

            </ng-container>
          </div>
        </div>

      </mat-drawer>
    </mat-drawer-container>
  </div>
  <div #mainContent>
    <router-outlet></router-outlet>
  </div>
</main>
<ng-template #searchInput>
  <mat-form-field>
    <input matInput placeholder="Enter text to search" (input)="findNotes($event.target.value)">
  </mat-form-field>
</ng-template>
