import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { getChildItems } from './mock-data/child-item';

@Injectable()
export class DemoService {

  // Subjects
  public childGridActionSubject: Subject<any> = new Subject();

  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setChildGridActionSubject = (value: any) => { this.childGridActionSubject.next(value); };

  // Observables
  getChildGridActionSubject = () => this.childGridActionSubject.asObservable();


  constructor(private http: HttpClient) {
  }

  GetItemsImportTemplate() {
    return this.http.get<any>(`${environment.AppCommonCode}/exportdemoitemstemplate`)
      .pipe(map(data => {
        return data;
      }));
  }

  GetChildItems(filterBody: any) {
    let childItems: any = getChildItems();
    if (!_.isEmpty(filterBody, true)) {
      childItems = childItems.filter((a) => {
        let isFiltered: boolean = true;
        if (filterBody.Title) {
          isFiltered = _.lowerCase(a.Title).includes(_.lowerCase(filterBody.Title));
        }
        if (filterBody.Makes) {
          isFiltered = isFiltered && filterBody.Makes.includes(a.Make);
        }
        if (filterBody.Models) {
          isFiltered = isFiltered && filterBody.Models.includes(a.Model);
        }
        if (filterBody.Years) {
          isFiltered = isFiltered && filterBody.Years.includes(a.Year);
        }
        if (filterBody.Brands) {
          isFiltered = isFiltered && filterBody.Brands.includes(a.Brand);
        }
        return isFiltered;
      });
    }

    return of(childItems);
  }

  GetItemMakeList() {
    const childItems = getChildItems();
    const makeList = _.sortBy(_.uniq(_.map(childItems, a => a.Make)), a => a);
    return of(makeList);
  }

  GetItemModelList() {
    const childItems = getChildItems();
    const modelList = _.sortBy(_.uniq(_.map(childItems, a => a.Model)), a => a);
    return of(modelList);
  }

  GetItemYearList() {
    const childItems = getChildItems();
    const yearList = _.sortBy(_.uniq(_.map(childItems, a => a.Year)), a => a);
    return of(yearList);
  }

  GetItemBrandList() {
    const childItems = getChildItems();
    const brandList = _.sortBy(_.uniq(_.map(childItems, a => a.Brand)), a => a);
    return of(brandList);
  }

  GetItemClassList() {
    const childItems = getChildItems();
    const classList = _.sortBy(_.uniq(_.map(childItems, a => a.Class)), a => a);
    return of(classList);
  }

  GetPromoNameList() {
    const childItems = getChildItems();
    const promoNameList = _.sortBy(_.uniq(_.map(childItems, a => a.Promo_Name)), a => a);
    return of(promoNameList);
  }

  GetItemSubClassList() {
    const childItems = getChildItems();
    const subClassList = _.sortBy(_.uniq(_.map(childItems, a => a.SubClass)), a => a);
    return of(subClassList);
  }

  GetMonthList() {
    const childItems = getChildItems();
    const monthList = _.sortBy(_.uniq(_.map(childItems, a => a.Month)), a => a);
    return of(monthList);
  }

  GetSubmittedByList() {
    const childItems = getChildItems();
    const submittedByList = _.sortBy(_.uniq(_.map(childItems, a => a.Submitted_By)), a => a);
    return of(submittedByList);
  }

  GetFulfillmentTypeList() {
    const childItems = getChildItems();
    const fulfillmentTypeList = _.sortBy(_.uniq(_.map(childItems, a => a.Fulfillment_Type)), a => a);
    return of(fulfillmentTypeList);
  }

  GetActionsList() {
    const actionList = [
      'Pitched',
      'Accepted',
      'Rejected',
      'Deleted',
      'Modified',
      'EOL'
    ];
    return of(actionList);
  }


  GetNewItemPromotionDetail(filterBody: any) {
    let childItems: any = getChildItems();
    if (!_.isEmpty(filterBody, true)) {
      childItems = childItems.filter((a) => {
        let isFiltered: boolean = true;
        if (filterBody.Month) {
          isFiltered = isFiltered && filterBody.Month === a.Month;
        }
        if (filterBody.Actions) {
          isFiltered = isFiltered && filterBody.Actions.includes(a.Status);
        }
        if (filterBody.Makes) {
          isFiltered = isFiltered && filterBody.Makes.includes(a.Make);
        }
        if (filterBody.Models) {
          isFiltered = isFiltered && filterBody.Models.includes(a.Model);
        }
        if (filterBody.Years) {
          isFiltered = isFiltered && filterBody.Years.includes(a.Year);
        }
        if (filterBody.Brands) {
          isFiltered = isFiltered && filterBody.Brands.includes(a.Brand);
        }
        if (filterBody.PromoName) {
          isFiltered = isFiltered && filterBody.PromoName === a.Promo_Name;
        }
        if (filterBody.SubmittedBy) {
          isFiltered = isFiltered && filterBody.SubmittedBy === a.Submitted_By;
        }
        if(filterBody.FulfillmentType) {
          isFiltered = isFiltered && filterBody.FulfillmentType === a.Fulfillment_Type;
        }
        return isFiltered;
      });
    }
    return of(childItems);
  }

  GetNewItemPromotionForecast(filterBody: any) {
    let childItems: any = getChildItems();
    if (!_.isEmpty(filterBody, true)) {
      childItems = childItems.filter((a) => {
        let isFiltered: boolean = true;
        if (filterBody.Month) {
          isFiltered = isFiltered && filterBody.Month === a.Month;
        }
        if (filterBody.Actions) {
          isFiltered = isFiltered && filterBody.Actions.includes(a.Status);
        }
        if (filterBody.Brands) {
          isFiltered = isFiltered && filterBody.Brands.includes(a.Brand);
        }
        return isFiltered;
      });
    }

    return of(childItems);
  }

}
