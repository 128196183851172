<!-- Form Expanstion Panel -->
<mat-accordion class="inpage-expansion-panel" *ngIf="planogramPromotionItems.length">

    <mat-expansion-panel [(expanded)]="planogramPanelOpenState" (opened)="planogramPanelOpenState = true"
        (closed)="planogramPanelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Planogram View
            </mat-panel-title>
            <mat-panel-description>
                <div class="row panel-header-action-button">
                    <div class="col-md-12 d-none d-sm-none d-md-block tablet-icon">
                        <div class="btn-show">
                            <button matTooltip="Export" mat-icon-button color="primary" type="button"
                                (click)="exportPlanogramPDF($event)">
                                <i class="material-icons" style="font-size: 30px; margin-left: 0px;">
                                    picture_as_pdf
                                </i>
                            </button>
                            <button *ngIf="isRetailerTeam" mat-button mat-flat-button color="accent"
                                (click)="savePlanogram($event)">Save</button>
                        </div>
                    </div>
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- START Export Planogram PDF HTML -->
        <div id="hidden-export-pdf-div">
            <div #exportHtml id="exportHtml" style="position: absolute; z-index: -9999; padding: 10px 15px;">
                <div [id]="gridId" style="padding: 10px;">
                    <div style="overflow: auto;
                        width: 100%;
                        display: flex;" *ngIf="planogramPromotionItems.length">
                        <div style="border: 1px solid lightgray;
                           border-radius: 5px;
                           height: 100%;
                           border-top-right-radius: 15px;
                           border-top-left-radius: 15px;">
                            <div style="background: #80048f;
                              padding: 10px 15px;
                              color: #fff;
                              font-weight: 500;
                              font-size: large;
                              text-align: center;">{{getFixtureName}}</div>
                            <div style="display: inline-block; padding: 10px;">
                                <div style="display: flex;
                                 align-items: center;
                                 flex-direction: row;"
                                    *ngFor="let rowCounter of counter(rows); let rowNumber = index;">
                                    <div style="width: 95px;
                                    min-width: 95px;
                                    height: 162px;
                                    display: flex;
                                    flex-direction: column;
                                    position: relative;
                                    border: 1px lightgray;
                                    border-style: dotted;"
                                        *ngFor="let columnCounter of counter(columns); let columnNumber = index;">
                                        <div *ngIf="planogramPromotionItems[columnNumber + (rowNumber*columns)]" style="width: 95px;
                                       min-width: 95px;
                                       height: 162px;
                                       display: flex;
                                       flex-direction: column;
                                       position: relative;
                                       border: 1px lightgray;
                                       border-style: dotted;
                                       padding: 5px;">
                                            <img style="width: 100%;
                                       height: 162px;"
                                                *ngIf="getImagePath(planogramPromotionItems[columnNumber + (rowNumber*columns)])"
                                                [src]="getImagePath(planogramPromotionItems[columnNumber + (rowNumber*columns)])" />
                                            <div
                                                *ngIf="!getImagePath(planogramPromotionItems[columnNumber + (rowNumber*columns)])"
                                                style="text-align: center;
                                          font-weight: 500;
                                          margin: 2px 0;
                                          font-size: 0.5vw;
                                          max-height: 97px;
                                          line-height: 1.1em;
                                          overflow: hidden;
                                          text-transform: uppercase;
                                          word-break: break-word;
                                          position: absolute;
                                          bottom: 5px;
                                          padding: 0 5px;">{{getItemDescription(planogramPromotionItems[columnNumber+(rowNumber*columns)])}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 50px; height: 1px; width: 100%;"></div>
                </div>

                <table [id]="tableId" class="table table-bordered" style="height: 0px; width: 100%;">
                    <thead>
                        <tr style="font-weight: 500;">
                            {{getFixtureName + '
                            Movie Collection ' +
                            (promotionDetail.startDate | date:'MM/dd/yy')
                            +'-'+(promotionDetail.endDate | date:'MM/dd/yy')}}
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th>Position</th>
                            <th>Internal SKU ID</th>
                            <th>Item Description</th>
                            <th>UPC</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of exportPlannogramItemDetails">
                            <tr>
                                <td>{{item.position}}</td>
                                <td>{{item.internalSKUId}}</td>
                                <td>{{item.itemDescription}}</td>
                                <td>{{item.upc}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- END Export Planogram PDF HTML -->

        <div class="row" cdkDropListGroup>
            <div class="container-fluid" style="margin-top: 15px;" cdkDropListGroup>

                <div class="fosner-section" *ngIf="planogramPromotionItems.length">
                    <div class="fosner-main-section">
                        <div class="fosner-title-bar">{{getFixtureName}}</div>
                        <div class="fosner-item-section">
                            <div class="fosner-row" *ngFor="let rowCounter of counter(rows); let rowNumber = index;">
                                <div class="fosner-card"
                                    *ngFor="let columnCounter of counter(columns); let columnNumber = index;"
                                    cdkDropList
                                    [ngClass]="isPositionDisabled(columnNumber + (rowNumber*columns)) ? 'bg-gray' : ''"
                                    [cdkDropListData]="planogramPromotionItems"
                                    (cdkDropListDropped)="drop(columnNumber + (rowNumber*columns), draggableArrayTypes.SWAP_POSITION)">

                                    <div class="fosner-card" cdkDrag [matTooltipClass]="'planogram-tooltip'"
                                        [matTooltip]="getTooltip(planogramPromotionItems[columnNumber + (rowNumber*columns)], columnNumber + (rowNumber*columns))"
                                        (cdkDragStarted)="cdkDragStarted(columnNumber + (rowNumber*columns), draggableArrayTypes.SWAP_POSITION)">
                                        <app-promotion-planogram-card
                                            *ngIf="planogramPromotionItems[columnNumber + (rowNumber*columns)]"
                                            [cardData]="planogramPromotionItems[columnNumber + (rowNumber*columns)]"
                                            [index]="columnNumber + (rowNumber*columns)">
                                        </app-promotion-planogram-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- LEFT OVERS -->
                <div *ngIf="unassignedPromotionItems.length" class="left-overs fosner-section">
                    <div class="fosner-main-section">
                        <div class="fosner-title-bar">Unassigned Items</div>
                        <div class="fosner-item-section">
                            <div class="fosner-row">
                                <div [ngClass]="(columnNumber)%2==0?'':'bg-gray'" class="fosner-card"
                                    *ngFor="let columnCounter of unassignedPromotionItems; let columnNumber = index;"
                                    cdkDropList [cdkDropListData]="unassignedPromotionItems"
                                    (cdkDropListDropped)="drop(columnNumber, draggableArrayTypes.UNASSIGNED_POSITION)">
                                    <div class="fosner-card" cdkDrag [matTooltipClass]="'planogram-tooltip'"
                                        [matTooltip]="getTooltip(unassignedPromotionItems[columnNumber], columnNumber)"
                                        (cdkDragStarted)="cdkDragStarted(columnNumber, draggableArrayTypes.UNASSIGNED_POSITION)">
                                        <app-promotion-planogram-card *ngIf="unassignedPromotionItems[columnNumber]"
                                            [cardData]="unassignedPromotionItems[columnNumber]" [index]="columnNumber">
                                        </app-promotion-planogram-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    </mat-expansion-panel>
</mat-accordion>
<!-- End Form Expansion Panel -->

<hr *ngIf="planogramPromotionItems.length" class="theme-hr" />