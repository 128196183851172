<div  id="item-manager">
  <div class="item-search-wrapper">
    <form action="" class="form-fields show-search">
      <div class="form-group">
        <mat-form-field floatLabel='never'>
          <input matInput id="filter-text-box" placeholder="Search" (keyup)="onFilterTextBoxChanged($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </form>
    <div class="item-search-btn">
      <img (click)="exportExcel()" src="assets/images/add-icon.svg" alt="">
      <button class="btn btn-primary" (click)="openForm({})">Add Role</button>
    </div>
  </div>

  <div class="all-show-grid items-wrapper-grid">
    <ag-grid-angular class="ag-theme-material" [defaultColDef]="defaultColDef" [gridOptions]="gridOptions"
      [columnDefs]="columnDefs" [headerHeight]="headerHeight" [rowHeight]="rowHeight" [rowData]="roleList" (gridReady)="onGridReady($event)"  #grid>
    </ag-grid-angular>
  </div>
  <app-create-update-role (close)="closePanel()" (updateList)="updateList()" *ngIf="showForm" [data]="roleData">
  </app-create-update-role>

</div>
<ng-template #cloneDialogTemplate let-data>
  <h1 mat-dialog-title>Replicate Role</h1>
  <div mat-dialog-content>
    <p>Do you want to replicate this role?</p>
    <mat-form-field>
      <input [(ngModel)]="data.cloneObject.roleName" matInput placeholder="Enter Role Name">
    </mat-form-field>
    <mat-form-field>
      <input [(ngModel)]="data.cloneObject.roleNameCode" matInput placeholder="Enter Role Name Code">
    </mat-form-field>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary"
      (click)="saveAndClose(data, errorTemplate,errorRoleCodeTemplate)">Yes</button>
    <button mat-raised-button class="btn m-left btn-light" color="warn"
      (click)="confirmationDialogRef.close()">No</button>
  </div>

</ng-template>
<ng-template #errorTemplate>
  <h1 mat-dialog-title>Error</h1>
  <div mat-dialog-content>
    <p>The name of role already exists. Please update the name.</p>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="dialogRef.close()">Ok</button>
  </div>
</ng-template>
<ng-template #errorRoleCodeTemplate>
  <h1 mat-dialog-title>Error</h1>
  <div mat-dialog-content>
    <p>This role code already exists. Please update the role code.</p>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="dialogRef.close()">Ok</button>
  </div>
</ng-template>

<ng-template #associateUserRole let-data>
  <h1 mat-dialog-title>Associate User with Team</h1>
  <div mat-dialog-content>
    <div class="row item-wrapper">
      <div class="col-md-12 form-group">
        <label>Team</label>
        <input [(ngModel)]="data.roleName" [ngModelOptions]="{standalone: true}" readonly>
      </div>
      <div class="col-md-12 form-group">
        <label>Users</label>
        <mat-select [(ngModel)]="data.applicationUser" [ngModelOptions]="{standalone: true}" [multiple]="true">
          <mat-option *ngFor="let user of (userList|| []); let i = index" [value]="user">{{
            user.firstName + ' '+ user.lastName }}</mat-option>
        </mat-select>
      </div>
    </div>
  </div>
  <div class="action-panel text-right">
    <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="dialogRef.close(data)">Save</button>
  </div>
</ng-template>
