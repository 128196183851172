<div class="mt-3">
  <div class="row m-0 pb-4 mt-3">
    <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
    <ng-container *ngIf="!undefinedImagePath">
      <h4 class="mx-2 w-100"><strong>What is the Surface premium mix evolution of partner vs. market?</strong></h4>
      <div class="inline-flex">
        <p class="m-2 font-18">Partner: | {{ empowerModernLifeService.getDashboardPartner() }}</p>
        <p class="m-2 font-18">Market: | {{ empowerModernLifeService.getDashboardLocation() }}</p>
      </div>
      <div class="col-12 empower-chart-section">
        <h4>Surface Premium Mix Evolution Partner vs Market</h4>
        <fusion-ms-column-chart height="450" [xaxisCatgoryList]="xaxisCatgoryList" [dataSeries]="dataSeries"
          xAxisName="Month" numberSuffix="%">
        </fusion-ms-column-chart>
      </div>
    </ng-container>
        <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
      <app-image-upload-viewer [imageUrl]="undefinedImagePath"
          orientation="square" (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12">
      <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
    </div>
    <div class="col-12">
      <app-report-page-footer></app-report-page-footer>
    </div>
  </div>
</div>