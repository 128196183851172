import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {  GridApi, ColumnApi, ColumnState } from 'ag-grid-enterprise';
import * as moment from 'moment';
import * as _ from 'lodash';
import {
  paginationPageSize,
  cacheBlockSize,
  promotionDetailGridColDefs,
  defaultColDef
} from '../../ag-grid/gridOptions';
import { MasterGridDialogComponent } from '../../../dialogs/master-grid-dialog/master-grid-dialog.component';
import { ItemGridDialogComponent } from '../../../dialogs/item-grid-dialog/item-grid-dialog.component';
import { getDatePicker } from 'src/common/ag-date-picker';
import { getNumericCellEditor } from 'src/common/ag-numeric-editor';
import { gridDataExportParams, onCellKeyDown, overlayLoadingTemplate } from '../../../util/util';
import { ConfigurationService } from 'src/services/configuration.service';
import { ItemManagerService, ItemPromotionService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { INVALID_TEAM, NEW_ITEM_PROMOTION_DETAIL, NEW_ITEM_PROMOTION_LIST, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-item-promotion-detail',
  templateUrl: './item-promotion-detail.component.html',
  styleUrls: ['./item-promotion-detail.component.css']
})
export class ItemPromotionDetailComponent implements OnInit, OnDestroy {

  @Input() data?: any;
  formData: any;
  isEditMode: boolean = false;
  isPromotionSaved: boolean = false;
  isGridDataUpdated: boolean = false;

  detailPanelOpenState: boolean = true;
  itemDetailGridPanelOpenState: boolean = true;

  public actionSubscription: Subscription;

  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = 'multiple';
  editType = 'fullRow';
  public gridColumnDefs = promotionDetailGridColDefs();
  public defaultColDef = defaultColDef;

  public gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: any;
  gridData: any = [];
  public gridColumnState: ColumnState[] = [];

  components = {
    numericCellEditor: getNumericCellEditor(),
    datePicker: getDatePicker()
  }

  selectedMasterRow: any;
  itemsDetailsSelectedRows: any = [];
  itemDetailGridUpdatedRows: any = [];

  actionsList: any = [];

  userTeam: any = '';
  isRetailerTeam: boolean = false;

  screenPreference: any;

  onCellKeyDown = onCellKeyDown;
  overlayLoadingTemplate = overlayLoadingTemplate;

  promotionDetailForm = new FormGroup({
    promotionItemName: new FormControl('', Validators.required),
    productTitleId: new FormControl('', Validators.required),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    status: new FormControl(true),
    releaseDate: new FormControl(''),
    preAwarenessDate: new FormControl(''),
    theatricalReleaseDate: new FormControl(''),
    accountName: new FormControl(''),
    ratingName: new FormControl(''),
    comments: new FormControl('')
  });

  constructor(
    public dialog: MatDialog,
    public configurationService: ConfigurationService,
    public itemPromotionService: ItemPromotionService,
    public toastr: NgxToasterService,
    private itemManagerService: ItemManagerService,
    private usersService: UsersService,
    private userPreferenceService: UserPreferenceService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) { }

  ngOnInit() {
    this.getUserSecreenPrefereence();
    this.setRoleBaseAccess();
    this.addCustomValidators();
    this.initiateEditModeData();
    this.initiateValues();
  }


  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(NEW_ITEM_PROMOTION_LIST, NEW_ITEM_PROMOTION_DETAIL);
    this.gridColumnState = this.screenPreference.columnState ? JSON.parse(this.screenPreference.columnState) : [];
  }

  initiateValues() {
    this.actionSubscription = this.itemManagerService.getActionsListSubject().subscribe((actionsList: any) => {
      if (actionsList && actionsList.length) {
        this.actionsList = actionsList;
      }
    });
  }

  get endDateControl() {
    return this.promotionDetailForm.get('endDate');
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        this.gridColumnDefs = promotionDetailGridColDefs(false, this.isRetailerTeam);
        this.gridApi && this.gridApi.setColumnDefs(this.gridColumnDefs);
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  initiateEditModeData() {
    const data = JSON.parse(this.data ? this.data : {});
    this.isEditMode = data.isEdit;
    this.formData = data.formData;
    if (this.isEditMode) {
      this.selectedMasterRow = data.formData;
      this.promotionDetailForm.patchValue(this.formData);
      this.getPromotionDetailItemsById();
    }
  }

  getPromotionDetailItemsById() {
    const filterEvent = {
      PromotionItemId: this.formData.promotionItemId,
      ...!this.isRetailerTeam && { AccountId: this.usersService.getTeamRoleAccount() }
    };
    this.gridApi && this.gridApi.showLoadingOverlay();
    this.itemPromotionService.FilterPromotionDetailItemList(filterEvent).subscribe(res => {
      this.gridData = res;
      this.gridApi.setRowData(this.gridData);
      this.applyColumnState();
      this.gridApi && this.gridApi.hideOverlay();
    },
    err => {
      this.gridApi && this.gridApi.hideOverlay();
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
    this.applyColumnState();
  }

  addCustomValidators() {
    setTimeout(() => {
      this.promotionDetailForm.controls['endDate'].setValidators([Validators.required, this.endDateValidator]);
    }, 0);
  }

  openMasterGrid() {
    const dialogRef = this.dialog.open(MasterGridDialogComponent, {
      data: {
        isDialoag: true,
        isMasterPage: false
      },
      width: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.selectedMasterRow) {
          this.selectedMasterRow = result.selectedMasterRow;
          this.setFormValues();
        }
      }
    }, err => {
      console.log(err);
    });
  }

  setFormValues() {
    this.setPromotionItemName();
    this.promotionDetailForm.patchValue(this.selectedMasterRow);
    this.promotionDetailForm.get('startDate').setValue(this.selectedMasterRow.releaseDate);
    this.startDateChanged();
  }

  startDateChanged() {
    const startDate = this.getWeekStartDate(new Date(this.promotionDetailForm.get('startDate').value));
    const endDate = this.addWeeks(startDate, 7);
    this.promotionDetailForm.get('endDate').setValue(endDate.toDateString());
  }

  endDateValidator = (control: FormControl) => {
    let startDate = control.parent.controls['startDate'].value;
    const endDate = control.value;
    if (startDate) {
      if (endDate < startDate) {
        return {
          endDate: 'Start Date should be less then End Date.'
        };
      }
      startDate = this.getWeekStartDate(new Date(startDate));
      // No# of weeks difference
      const weeks = (endDate - startDate) / (7 * 24 * 60 * 60 * 1000);
      if (weeks >= 8) {
        return {
          endDate: 'End Date should be in 8 week range from Start Date.'
        };
      }
      return null;
    } else {
      return null;
    }
  }

  getWeekStartDate = (date) => {
    const day = date.getDay(),
      diff = date.getDate() - day;
    return new Date(date.setDate(diff));
  }

  weeksBetweenDates(date1, date2) {
    return Math.round((date2 - date1) / (7 * 24 * 60 * 60 * 1000));
  }

  addWeeks(date, noWeeks) {
    return new Date(date.setDate(date.getDate() + (noWeeks * 7) + 6));
  }

  setPromotionItemName() {
    const productTitleName = this.selectedMasterRow.productTitleName;
    let promotionItemName = `${productTitleName} - New Release - ${moment(new Date(this.selectedMasterRow.releaseDate)).format('MMM YYYY')}`;
    this.promotionDetailForm.get('promotionItemName').setValue(promotionItemName);
  }

  openPromotionItemView(event) {
    event.stopPropagation();
    const promotionDetailComponent = {
      menuId: '399',
      name: 'New Item Promotion Detail',
      selector: 'app-item-promotion-detail',
      displayName: 'New Item Promotions',
      module: 'ItemManagerModule',
      data: {
        isEdit: false
      },
      component: ItemPromotionDetailComponent
    }
    this.configurationService.menuRendererSubject.next(promotionDetailComponent);
  }

  onGridStateChange(e) {
    this.gridColumnState = this.colApi.getColumnState();
    this.screenPreference.columnState = this.gridColumnState;
    this.updateScreenPreference();
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertColumnStatePreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  applyColumnState() {
    if (this.colApi && this.gridColumnState && this.gridColumnState.length) {
      setTimeout(() => {
        this.colApi.applyColumnState({ state: this.gridColumnState });
      }, 100);
    }
  }

  openColumnDialog(event) {
    event.stopPropagation();
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select']
      },
      width: '850px'
    });
  }

  onExportGridData(event) {
    event.stopPropagation();
    const csvExportParams = gridDataExportParams({
      fileName: 'NewItemsPromotionsList',
      colDefs: this.gridColumnDefs,
      allColumns: false,
      columnKeys: _.map(this.gridColumnDefs, a => a.field)
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  handleExport(event) {
    event.stopPropagation();
  }

  gridRowSelectionChanged(params) {
    this.itemsDetailsSelectedRows = params.api.getSelectedRows();
  }

  checkDuplicatePromotionItem() {
    this.detailPanelOpenState = true;
    if (this.promotionDetailForm.valid) {
      const body = {
        MasterItemId: this.selectedMasterRow.masterItemId,
        StartDate: moment(_.get(this.promotionDetailForm.value, 'startDate')).format('YYYY-MM-DD'),
        EndDate: moment(_.get(this.promotionDetailForm.value, 'endDate')).format('YYYY-MM-DD'),
        ...(this.isEditMode || this.isPromotionSaved) && { PromotionItemId: _.get(this.formData, 'promotionItemId') }
      };
      this.itemPromotionService.CheckDuplicatePromotionItem(body).subscribe((res: any) => {
        if (!res) {
          this.upsertNewItemPromotion();
        } else {
          this.toastr.error('Error', 'Promotion already exist for given Product Title and Start Date.');
        }
      });
    }
  }

  upsertNewItemPromotion() {
    if (this.isEditMode || this.isPromotionSaved) {
      const updatedItem = {
        ...this.formData,
        ...this.promotionDetailForm.value,
        ...{
          startDate: moment(_.get(this.promotionDetailForm.value, 'startDate')).format('YYYY-MM-DD'),
          endDate: moment(_.get(this.promotionDetailForm.value, 'endDate')).format('YYYY-MM-DD'),
        }
      };
      this.itemPromotionService.UpdatePromotionItem(updatedItem).subscribe((res: any) => {
        this.toastr.success('Success', 'Promotion Details Updated Successfully.');
        this.itemPromotionService.setPromotionUpsertSubject(updatedItem);
        this.detailPanelOpenState = false;
      }, err => {
        this.toastr.error('Error', 'Promotion Details Update Failed.');
        console.log(err);
      });
    } else {
      this.itemPromotionService.CreatePromotion({
        ...this.selectedMasterRow,
        ...this.promotionDetailForm.value,
        ...{
          startDate: moment(_.get(this.promotionDetailForm.value, 'startDate')).format('YYYY-MM-DD'),
          endDate: moment(_.get(this.promotionDetailForm.value, 'endDate')).format('YYYY-MM-DD'),
        }
      }).subscribe((res: any) => {
        this.formData = res;
        this.itemPromotionService.setPromotionUpsertSubject(this.formData);
        this.isPromotionSaved = true;
        this.toastr.success('Success', 'Promotion Created Successfully.');
      }, err => {
        this.toastr.error('Error', 'Promotion Creation Failed.');
        console.log(err);
      });
    }
  }

  addNewItems() {
    const dialogRef = this.dialog.open(ItemGridDialogComponent, {
      data: {
        isMultiRowSelection: true,
        currentChildItems: _.map(this.gridData, a => {
          return { childItemId: a.childItemId, actionName: a.actionName };
        }),
        selectedMasterRow: this.selectedMasterRow,
        promotionItemId: this.formData.promotionItemId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.isUnchecked) {
          // const index = this.gridData.findIndex(a => a.childItemId === result.record.childItemId);
          // this.gridData.splice(index, 1);
        } else if (result.selectedItems && result.selectedItems.length) {
          const newPromotionItems = _.map(result.selectedItems, item => {
            item['promotionItemId'] = this.formData.promotionItemId;
            return item;
          });
          this.gridApi && this.gridApi.showLoadingOverlay();
          this.itemPromotionService.BulkAddPromotionDetailItems({ Data: newPromotionItems }).subscribe((res: any) => {
            this.toastr.success('Success', 'Promotion Details Updated Successfully.');
            this.getPromotionDetailItemsById();
          }, err => {
            this.gridApi && this.gridApi.hideOverlay();
            this.toastr.error('Error', 'Promotion Details Update Failed.');
            console.log(err);
          });
        }
        this.gridApi.setRowData(this.gridData);
        this.applyColumnState();
      }
    });
  }

  deleteSelectedItems() {
    const deleteAction = _.find(this.actionsList, a => a.actionName === 'Deleted');
    if (deleteAction && this.itemsDetailsSelectedRows.length) {
      this.itemsDetailsSelectedRows.forEach(item => {
        item.actionId = deleteAction.actionId;
        item.actionName = deleteAction.actionName;
      });
      this.gridApi && this.gridApi.showLoadingOverlay();
      this.itemPromotionService.BulkUpdatePromotionDetailItems({ Data: this.itemsDetailsSelectedRows }).subscribe((res: any) => {
        if (res && res.length) {
          let successMessagesCount = 0;
          const warningMessages = [];
          res.forEach(element => {
            if (element.messageRecords.length) {
              switch (element.messageRecords[0].messageType) {
                case 'Warning':
                  warningMessages.push(element.messageRecords[0].messageText);
                  break;
                default:
                  break;
              }
            } else {
              successMessagesCount++;
            }
          });
          if (warningMessages.length)
            this.toastr.warning('Warning', `${warningMessages.map(a => a).join('<br />')}`);
          if (successMessagesCount > 0) {
            const successMessage = `${successMessagesCount} item${successMessagesCount > 1 ? 's' : ''} updated successfully!`;
            this.toastr.success('Success', `${successMessage}`);
            this.itemsDetailsSelectedRows = [];
            this.getPromotionDetailItemsById();
          }
        } else {
          this.toastr.success('Success', 'Promotion Details Updated Successfully.');
          this.itemsDetailsSelectedRows = [];
          this.getPromotionDetailItemsById();
        }

      }, err => {
        this.gridApi && this.gridApi.hideOverlay();
        this.toastr.error('Error', 'Promotion Details Update Failed.');
        console.log(err);
      });
    }
  }

  gridDataUpdated(params) {
    if ((params.newValue || params.oldValue) && (params.newValue != params.oldValue)) {
      this.isGridDataUpdated = true;
      const prvUpdatedRowStateIndex = _.findIndex(this.itemDetailGridUpdatedRows, a => a.promotionItemDetailId === params.data.promotionItemDetailId);
      if (prvUpdatedRowStateIndex < 0) {
        this.itemDetailGridUpdatedRows.push(params.data);
      } else {
        this.itemDetailGridUpdatedRows[prvUpdatedRowStateIndex] = params.data;
      }
    }
  }

  openActionsDialog(): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        headerName: `Change Status to 'Delete'`,
        confirmationMessage: `Are you sure you want to change the status to Delete?`
      }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteSelectedItems();
      }
    });
  }

  saveUpdatedGridData(event: any) {
    event.stopPropagation();
    this.gridApi.stopEditing();
    setTimeout(() => {
      if (this.itemDetailGridUpdatedRows.length) {
        this.gridApi && this.gridApi.showLoadingOverlay();
        this.itemPromotionService.BulkUpdatePromotionDetailItems({ Data: this.itemDetailGridUpdatedRows }).subscribe((res: any) => {
          this.toastr.success('Success', 'Promotion Details Updated Successfully.');
          this.gridData = _.map(this.gridData, (item) => {
            return _.merge(item, _.find(res, { 'promotionItemDetailId': item.promotionItemDetailId }));
          });
          this.gridApi.setRowData(this.gridData);
          this.itemDetailGridUpdatedRows = [];
          this.isGridDataUpdated = false;
          this.applyColumnState();
          this.gridApi && this.gridApi.hideOverlay();
        }, err => {
          this.gridApi && this.gridApi.hideOverlay();
          this.toastr.error('Error', 'Promotion Details Update Failed.');
          console.log(err);
        });
      }
    }, 100);
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
  }

}
