<div class="row m-0 my-3 pb-3">
  <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
  <ng-container *ngIf="!undefinedImagePath">
    <h4 class="mx-2 w-100"><strong>What is Console Premium Mix?</strong></h4>
    <div class="inline-flex w-100">
      <p class="m-2 font-18">Partner: | {{ empowerGamingService.getDashboardPartner() }}</p>
      <p class="m-2 font-18">Market: | {{ empowerGamingService.getDashboardLocation() }}</p>
    </div>
    <div class="col-12 empower-chart-section">
      <h4>Console Premium Mix</h4>
      <fusion-ms-column-chart height="350" [xaxisCatgoryList]="xaxisCatgoryList" [dataSeries]="dataSeries"
        xAxisName="Month" numberSuffix="%">
      </fusion-ms-column-chart>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
    <app-image-upload-viewer [imageUrl]="undefinedImagePath"
        orientation="square" (imageLoaded)="emitExportEvent($event)">
    </app-image-upload-viewer>
  </div>
  <div class="col-12">
    <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
  </div>
  <div class="col-12">
    <app-report-page-footer></app-report-page-footer>
  </div>
</div>
