import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeviewConfig, TreeviewI18n, TreeviewItem } from 'ngx-treeview';
import { DropdownTreeViewHierarchySelectI18n } from './dropdown-treeview-hierarchy-selectI18n';

@Component({
  selector: 'app-treeview-item-hierarchy-dropdown',
  templateUrl: './treeview-product-hierarchy-dropdown.component.html',
  styleUrls: ['./treeview-product-hierarchy-dropdown.component.scss'],
  providers: [
    { provide: TreeviewI18n, useClass: DropdownTreeViewHierarchySelectI18n }
  ]
})
export class TreeViewItemHierarchyDropdownComponent implements OnInit {
  @Input() items: TreeviewItem[];
  @Input() isCheckBoxRequired;
  @Input() isReadonly = false;
  @Output() valueChange = new EventEmitter();
  @Input() type = '';
  @Input() disabled;
  values: number[];
  scrollData;

  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark',
  ];
  buttonClass = this.buttonClasses[0];
  config: TreeviewConfig;

  constructor() {}

  ngOnInit(): void {
    this.config = TreeviewConfig.create({
      hasAllCheckBox: this.isCheckBoxRequired ? true : false,
      hasFilter: true,
      hasCollapseExpand: true,
      decoupleChildFromParent: false,
      maxHeight: 400,
    });
  }
  setValues = (values) => {
    const outputObject = {
      value: values,
      type: this.type,
    };
    this.valueChange.emit(outputObject);
  };

  onFilterChange(value: string): void {
    console.log('filter:', value);
  }
}
