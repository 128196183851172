import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ItemManagerService } from 'src/services/Item-Management-Services';
import { UsersService } from 'src/services/User-services/user-services';
import { INVALID_TEAM, NEW_ITEM_PROMOTION_DETAIL_APROVAL, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-item-approval-filter',
  templateUrl: './item-approval-filter.component.html',
  styleUrls: ['./item-approval-filter.component.css']
})
export class ItemApprovalFilterComponent implements OnInit, OnDestroy {

  @Output() filterEvent = new EventEmitter<any>();

  public accountSubscription: Subscription;
  public actionSubscription: Subscription;
  public planningMonthSubscription: Subscription;

  accountList: any = [];
  actionsList: any = [];
  planningMonthList: any = [];

  accountIds: any;
  actionIds: any;
  monthId: any;

  userTeam: any = '';
  isRetailerTeam: boolean = false;
  disabledAccountSelection: boolean = false;

  screenPreference: any;
  screenFilters: any;

  constructor(
    private itemManagerService: ItemManagerService,
    private usersService: UsersService,
    private userPreferenceService: UserPreferenceService) { }

  ngOnInit() {
    this.getUserSecreenPrefereence();
    this.setRoleBaseAccess();
    this.initiateValues();
  }

  getUserSecreenPrefereence() {
    this.screenPreference = this.userPreferenceService.getPreferenceByKeys(NEW_ITEM_PROMOTION_DETAIL_APROVAL, NEW_ITEM_PROMOTION_DETAIL_APROVAL);
    this.screenFilters = this.screenPreference.screenFilters ? JSON.parse(this.screenPreference.screenFilters) : null;
    if (this.screenFilters) {
      this.monthId = this.screenFilters.MonthId;
      this.actionIds = this.screenFilters.ActionIds;
      this.accountIds = this.screenFilters.AccountIds;
    }
  }

  setPlanningMonth() {
    if (!this.monthId && this.planningMonthList.length) {
      let today = moment();
      const currentPlanningMonth = moment(today.add(2, 'M').calendar()).format('MMM YY');
      this.monthId = _.get(_.find(this.planningMonthList, a => a.monthName.includes(currentPlanningMonth)), 'monthId')
    }
    this.emitFilterEvent();
  }

  initiateValues() {
    this.accountSubscription = this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
      if (accountList && accountList.length) {
        this.accountList = accountList;
      }
    });
    this.actionSubscription = this.itemManagerService.getActionsListSubject().subscribe((actionsList: any) => {
      if (actionsList && actionsList.length) {
        this.actionsList = this.itemManagerService.FilterActionByScreen(actionsList, 'Filter');
      }
    });
    this.planningMonthSubscription = this.itemManagerService.getPlanningMonthListSubject().subscribe((planningMonthList: any) => {
      if (planningMonthList && planningMonthList.length) {
        this.planningMonthList = planningMonthList;
        this.setPlanningMonth();
      }
    });
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        this.accountIds = [this.usersService.getTeamRoleAccount()];
        this.disabledAccountSelection = true;
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  emitFilterEvent() {
    const eventBody = {
      ...this.monthId && { MonthId: this.monthId },
      ...this.actionIds && { ActionIds: this.actionIds },
      ...this.accountIds && { AccountIds: this.accountIds },
    };
    this.screenPreference.screenFilters = eventBody;
    this.updateScreenPreference();
    this.filterEvent.emit(eventBody);
  }

  updateScreenPreference() {
    this.userPreferenceService.upsertFilterPreference(this.screenPreference).subscribe(res => {
      console.log(res);
    });
  }

  clearValues() {
    this.monthId = '';
    this.actionIds = '';
    this.accountIds = this.disabledAccountSelection ? this.accountIds : '';
    this.setPlanningMonth();
  }

  ngOnDestroy() {
    this.accountSubscription.unsubscribe();
    this.actionSubscription.unsubscribe();
    this.planningMonthSubscription.unsubscribe();
  }

}

