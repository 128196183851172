import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ProcurementService } from 'src/services/procurement.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { CClaim } from 'src/common/claims';
@Component({
  selector: 'app-pms-add-edit-bid',
  templateUrl: './pms-add-edit-bid.component.html',
  styleUrls: ['./pms-add-edit-bid.component.scss'],
})
export class PmsAddEditBidComponent implements OnInit {
  bidForm: FormGroup;
  bidsArray: FormArray;
  isTaxRequired = false;
  bidsCreated = false;
  prItems = [];
  bidsItem = [];
  statuses = [];
  cClaim = CClaim;
  vendorsList = [];
  itemsList = [];
  totalPrice: number;
  prId: number;
  constructor(
    public dialogRef: MatDialogRef<PmsAddEditBidComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private procurementService: ProcurementService,
    private dialog: MatDialog,
    public toastr: NgxToasterService,
    private spinner: NgxSpinnerService
  ) {
    if (this.data.value) {
      this.prId = this.data.value;
    }
  }
  ngOnInit(): void {
    this.procurementService.getStatusLookup().subscribe((res: any) => {
      this.statuses = res;
    });
    this.fetchLookups();
    this.bidGetList();
  }
  fetchLookups() {
    this.procurementService.getItemsListing().subscribe((res: any) => {
      this.itemsList = res;
    });
    this.procurementService
      .getVendorsListing('', '', '')
      .subscribe((res: any) => {
        this.vendorsList = res;
      });
  }
  bidGetList() {
    this.spinner.show();
    this.procurementService
      .getBidListById(this.prId)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res: any) => {
        const bids = res.bids;
        if (res.bidDetails.length != 0) {
          res.bidDetails.forEach((bidDetailObj) => {
            bids.forEach((bid) => {
              if (bid.bidId == bidDetailObj.bidId) {
                if (!bid.bidDetails || !bid.bidDetails.length)
                  bid.bidDetails = [];
                bid.bidDetails.push(bidDetailObj);
              }
            });
          });
          console.log('bids array:', bids);
        }
        this.fetchPRitems(bids);
      });
  }
  fetchPRitems(bidsList) {
    this.procurementService.getPRItemGetListbyId(this.prId).subscribe(
      (res: any) => {
        this.prItems = res;
        this.initializeForm(bidsList);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  initializeForm(bidsList = []) {
    this.bidForm = this.formBuilder.group({
      bids: new FormArray([]),
    });
    this.bidsArray = this.bidForm.get('bids') as FormArray;
    if (!bidsList.length) {
      this.addBid();
    } else {
      bidsList.forEach((bid, index) => {
        bid.taxRate && (bid.taxRate = bid.taxRate.toString());
        this.addBid(bid);
      });
    }
    if (this.data.viewDetails) {
      console.log('Bid length' + bidsList.length);
      this.bidForm.disable();
      if (bidsList.length > 0) {
        this.bidsCreated = true;
      }
    }
  }
  addBid(bidObj = null) {
    const formGroupObj = new FormGroup({
      prId: new FormControl(this.prId),
      bidId: new FormControl(bidObj && bidObj.bidId ? bidObj.bidId : 0),
      vendorId: new FormControl(bidObj && bidObj.vendorId, Validators.required),
      ntnNumber: new FormControl({
        value: bidObj && bidObj.ntnNumber,
        disabled: true,
      }),
      isTaxable: new FormControl(
        bidObj && bidObj.isTaxable ? bidObj.isTaxable : false
      ),
      taxRate: new FormControl(bidObj && bidObj.taxRate ? bidObj.taxRate : 0),
      bidDetails: new FormArray([]),
    });
    this.prItems.forEach((prItem, index) => {
      const prItemFormGroup = new FormGroup({
        bidDetailId: new FormControl(0),
        bidId: new FormControl(0),
        PRItemId: new FormControl({ value: prItem.itemId, disabled: true }),
        reason: new FormControl(''),
        quantity: new FormControl({ value: null, disabled: true }),
        unitPrice: new FormControl({
          value: prItem.estimatedAmount,
          disabled: true,
        }),
        priceQuoted: new FormControl(null, Validators.required),
        totalPrice: new FormControl({
          value: prItem.estimatedAmount * prItem.quantity,
          disabled: true,
        }),
        itemSpecifications: new FormControl({ value: null, disabled: true }),
      });
      prItemFormGroup.patchValue(prItem);
      bidObj && prItemFormGroup.patchValue(bidObj.bidDetails[index]);
      (formGroupObj.get('bidDetails') as FormArray).push(prItemFormGroup);
    });
    // bidObj && formGroupObj.patchValue(bidObj);
    this.bidsArray.push(formGroupObj);
    console.log(bidObj);
    console.log(this.bidsArray.length);
  }
  removeItem(index: number) {
    this.bidsArray.removeAt(index);
    this.bidsArray.length == 0 && this.addBid();
  }
  vendorChanged(index: number) {
    const vendorId = this.bidsArray.controls[index].get('vendorId').value;
    this.vendorsList.forEach((v) => {
      if (v.vendorId == vendorId) {
        this.bidsArray.controls[index].get('ntnNumber').setValue(v.ntnNumber);
      }
    });
  }
  updateBidStatus(status, index) {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '500px',
        data: {
          headerName: `Status Change`,
          confirmationMessage: `Are you sure you want to ${
            status === 'approved' ? 'Approve' : 'Reject'
          } Bid?`,
          status: status,
        },
      }
    );
    const selectedBid = this.bidsArray.controls[index].value;
    confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinner.show();
        let filteredStatus = this.statuses.find((status) => {
          return status.statusDescription.toLowerCase() === result.status;
        });
        //result.status === 'approve' ? (status = 2) : (status = 6);
        const payload = {
          prId: selectedBid.prId,
          bidId: selectedBid.bidId,
          vendorId: selectedBid.vendorId,
          prStatus: filteredStatus.statusId,
        };
        this.procurementService.UpdateBidUpdateStatus(payload).subscribe(
          (res) => {
            if (res) {
              //  this.fetchData();
              this.spinner.hide();
              const payload = {
                prId: selectedBid.prId,
                status: 1,
                taxable: selectedBid.taxable,
              };
              this.procurementService
                .addPurchaseOrder(payload)
                .subscribe((res) => {
                  if (res > 0) {
                    this.toastr.success(
                      'Success',
                      `Bid status change successfully.`
                    );
                  }
                });
            }
          },
          (err) => {
            this.toastr.error('Error', `System failed to update status.`);
            this.spinner.hide();
          }
        );
      }
    });
  }
  onSaveClick() {
    console.log(this.bidsArray.getRawValue());
    const bids = this.bidsArray.getRawValue();
    const bidsPayload = [] as any;
    bids.forEach((bid) => {
      const obj = {
        bid: {
          prId: bid.prId,
          bidId: bid.bidId,
          vendorId: bid.vendorId,
          isTaxable: bid.isTaxable,
          taxRate: bid.taxRate,
          ntnNumber: bid.ntnNumber,
        },
        bidDetails: bid.bidDetails,
      };
      bidsPayload.push({ bid: obj });
    });
    console.log(bidsPayload);
    this.spinner.show();
    this.procurementService
      .addBidAll(bidsPayload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((data) => {
        this.toastrService.success('Bids saved successfully.');
        this.dialogRef.close(true);
      });
  }
  onUpdateClick() {
    this.spinner.show();
    let payload = [];
    let bidPayload = this.bidForm.value.bids[0];
    bidPayload.items.forEach((element) => {
      payload.push({
        prId: this.data.prId,
        bidsId: element.bidsId,
        prItemId: element.itemId,
        vendorId: bidPayload.vendorName,
        isTaxable: bidPayload.isTaxable,
        status: 1,
        reason: element.reason,
        approvedby: 0,
        createdby: 0,
        quantity: element.quantity,
        priceQuoted: element.estimatedAmount,
        taxRate: bidPayload.taxPercentage,
      });
    });
    this.procurementService
      .updateBidAll(payload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((data) => {
        this.spinner.hide();
        this.dialogRef.close(true);
      });
  }
  onFocus(estimatedAmount, quantity) {
    this.totalPrice = estimatedAmount * quantity;
  }
  onNoClick() {
    this.dialogRef.close(false);
  }
}
