import { Injector, NgModule, ErrorHandler } from '@angular/core';
import { ThreeStateCustomToggleButtonComponent } from './three-state-custom-toggle-button/three-state-custom-toggle-button.component';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatTreeModule } from '@angular/material/tree';


import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxCaptureModule, NgxCaptureService } from 'ngx-capture';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';

import { NgxPowerBiModule, NgxPowerBiService } from 'ngx-powerbi';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxLoadingModule } from 'ngx-loading';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {
  ChildItemActionCellRenderer,
  ChildItemEditRenderer,
  MasterItemActionCellRenderer,
  MasterItemEditRenderer,
  PromotionItemEditRenderer,
  PromotionPlannerEditRenderer,
} from '../modules/item-manager/features';
import { FusionChartsModule } from 'angular-fusioncharts';
// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as charts from 'fusioncharts/fusioncharts.charts';
import * as widgets from 'fusioncharts/fusioncharts.widgets';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
FusionChartsModule.fcRoot(FusionCharts, charts, widgets, FusionTheme);
import { TreeviewModule } from 'ngx-treeview';
import { AGNumberEditorComponent } from 'src/common/ag-number-cell-editor';
import { PrivilegeErrorComponent } from 'src/common/privilege-error/privilege-error.component';

import { environment } from 'src/environments/environment';
import { MaterialModule } from './material.module';
import { AuthenticationService } from 'src/services/Authentication-Services/authentication-services';
import { UsersService } from 'src/services/User-services/user-services';
import { AddButtonRendererComponent } from 'src/common/add-button-renderer';
import { EditButtonRendererComponent } from 'src/common/edit-button-renderer';
import { ImageRendererComponent } from 'src/common/image-renderer';
import { NameRendererComponent } from 'src/common/name-renderer';
import { HttpInterceptorService } from 'src/services/http-interceptor.service';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { LineChartComponent } from 'src/modules/layout/features/charts/line-chart/line-chart.component';
import { CloneRendererComponent } from 'src/common/clone-renderer';
import { ToggleButtonRendererComponent } from 'src/common/toggle-button-renderer';
import { CdkTreeModule } from '@angular/cdk/tree';
import {
  ChildItemService,
  ItemManagerService,
  ItemPromotionService,
  MasterProductService,
  PromotionPlannerService,
} from 'src/services/Item-Management-Services';
import { AGColumnDialogComponent } from '../common/ag-column-dialog/ag-column-dialog.component';
import { CheckboxRenderer } from 'src/common/ag-checkbox-renderer';
import { DropdownRendererComponent } from 'src/common/dropdown-renderer';
import { UserPreferenceService } from 'src/services/User-services/user-preference.service';
import { BlobStorageService } from 'src/services/azure-storage/blob-storage.service';
import {
  BLOB_STORAGE_TOKEN,
  IAzureStorage,
} from 'src/services/azure-storage/azure-storage';
import { MomentUtcDateAdapter } from './moment-adapter';
import { UpdateNameDialogComponent } from 'src/common/update-name-dialog/update-name-dialog.component';
import {
  CommitActionCellRenderer,
  DepromoWeekCellEditor,
  ForecastManagerEditRenderComponent,
  ForecastDataManagerEditRenderComponent,
  OutlierCustomHeaderGroup,
  PromoItemsEditRenderer,
  SeasonalityEditRenderer,
  SeasonalityLineChartCellRenderer,
} from 'src/modules/forecast-engin/features';
import { OutlierService } from 'src/services/forecast-services/outlier-service';
import { OutlierLineChartCellRenderer } from 'src/modules/forecast-engin/features/outlier-manager/ag-grid/outlier-line-chart-cell-renderer.component';
import { MappingService } from 'src/services/forecast-services/mapping-service';
import { PromoService } from 'src/services/forecast-services/promo-service';
import { ForecastLookupService } from 'src/services/forecast-services/forecast-lookup-service';
import { SeasonalityService } from 'src/services/seasonality-services/seasonality.service';
import { ForecastManagerService } from 'src/services/forecast-services/forecast-manager.service';
import { TreeViewAccountDropdownComponent } from './select-account/treeview-account-dropdown.component';
import { TreeViewItemHierarchyDropdownComponent } from './select-product-hierarchy/treeview-product-hierarchy-dropdown.component';
import { DemoService } from 'src/services/demo-services/demo-service';
import { ClientPreferenceService } from 'src/services/client-services/client-preference.service';
import { MultiDatepickerModule } from 'src/common/multidatepicker/multidatepicker.module';
import { ImageUploadViewerComponent } from 'src/common/image-upload-viewer/image-upload-viewer.component';
import {
  FusionGageChartComponent,
  FusionMSBarChartComponent,
  FusionMSLineChartComponent,
  FusionPercentageHorizentalBarChartComponent,
  FusionMSCombinationChartComponent,
  FusionMSLineAreaChartComponent,
  FusionMSCombinationChart2YComponent,
  FusionMSColumnChartComponent,
  FusionDoughnutChartComponent,
} from 'src/common/fusion-graph-components';
import { EmpowerService } from 'src/services/empower-services/empower.service';
import { LoggingService } from 'src/services/logging.service';
import { ErrorHandlerService } from 'src/services/error-handler.service';
import { PageCommentComponent } from 'src/common/page-comment/page-comment.component';
import { ReportPageFooterComponent } from 'src/common/report-page-footer/report-page-footer.component';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EmpowerGamingService } from 'src/services/empower-services/empower-gaming.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FusionMsLineScrollableChartComponent } from 'src/common/fusion-graph-components/ms-line-scrollable-chart/ms-line-scrollable-chart.component';
import { FusionMsCombinationScrollableChartComponent } from 'src/common/fusion-graph-components/ms-combination-scrollable-chart/ms-combination-scrollable-chart.component';
import { EmpowerFactoryService } from 'src/services/empower-services/factory.serivce';
import { TreeviewDropdownComponent } from 'src/common/treeview-dropdown/treeview-dropdown.component';
import { ShowGRNSummaryRenderer } from 'src/modules/procurement/ag-grid/show-grn-summary-renderer.component';
import { MentionModule } from 'angular-mentions';

declare var AzureStorage: IAzureStorage;
export let AppInjector: Injector;

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    // BsDropdownModule.forRoot(),
    // AccordionModule.forRoot(),
    // ButtonsModule.forRoot(),
    // CarouselModule.forRoot(),
    // CollapseModule.forRoot(),
    // DatepickerModule.forRoot(),
    // ModalModule.forRoot(),
    // PaginationModule.forRoot(),
    // PopoverModule.forRoot(),
    // ProgressbarModule.forRoot(),
    // RatingModule.forRoot(),
    // SortableModule.forRoot(),
    // TabsModule.forRoot(),
    // TimepickerModule.forRoot(),
    // TooltipModule.forRoot(),
    // TypeaheadModule.forRoot(),
    ToastrModule.forRoot(),
    // NgxLoadingModule.forRoot({}),
    AgGridModule,
    TreeViewModule,
    TreeGridModule,
    CKEditorModule,
    NgxCaptureModule,
    NgxDropzoneModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    MaterialModule,
    NgxPowerBiModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxLoadingModule,
    FusionChartsModule,
    TreeviewModule.forRoot(),
    HttpClientModule,
    CdkTreeModule,
    MultiDatepickerModule,
    MentionModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    AuthenticationService,
    UsersService,
    UserPreferenceService,
    DatePipe,
    NgxCaptureService,
    NgxPowerBiService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    CurrencyPipe,
    ItemManagerService,
    ChildItemService,
    MasterProductService,
    ItemPromotionService,
    PromotionPlannerService,
    BlobStorageService,
    {
      provide: BLOB_STORAGE_TOKEN,
      useValue: AzureStorage.Blob,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    OutlierService,
    MappingService,
    PromoService,
    SeasonalityService,
    ForecastLookupService,
    ForecastManagerService,
    DemoService,
    ClientPreferenceService,
    EmpowerService,
    LoggingService,
    EmpowerExportService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    EmpowerModernLifeService,
    EmpowerGamingService,
    EmpowerFactoryService,
  ],
  declarations: [
    ButtonRendererComponent,
    ConfirmationDialogComponent,
    EditButtonRendererComponent,
    NameRendererComponent,
    MasterItemEditRenderer,
    ChildItemEditRenderer,
    PromotionItemEditRenderer,
    AGNumberEditorComponent,
    ImageRendererComponent,
    AddButtonRendererComponent,
    TreeviewDropdownComponent,
    ChildItemActionCellRenderer,
    MasterItemActionCellRenderer,
    PromotionPlannerEditRenderer,
    PrivilegeErrorComponent,
    LineChartComponent,
    CloneRendererComponent,
    ToggleButtonRendererComponent,
    AGColumnDialogComponent,
    CheckboxRenderer,
    DropdownRendererComponent,
    UpdateNameDialogComponent,
    CommitActionCellRenderer,
    OutlierLineChartCellRenderer,
    PromoItemsEditRenderer,
    DepromoWeekCellEditor,
    SeasonalityEditRenderer,
    TreeViewAccountDropdownComponent,
    TreeViewItemHierarchyDropdownComponent,
    ForecastManagerEditRenderComponent,
    ForecastDataManagerEditRenderComponent,
    SeasonalityLineChartCellRenderer,
    OutlierCustomHeaderGroup,
    ShowGRNSummaryRenderer,
    ThreeStateCustomToggleButtonComponent,
    ImageUploadViewerComponent,
    FusionMSLineChartComponent,
    FusionMSBarChartComponent,
    FusionPercentageHorizentalBarChartComponent,
    FusionGageChartComponent,
    FusionMSCombinationChartComponent,
    FusionMSLineAreaChartComponent,
    PageCommentComponent,
    ReportPageFooterComponent,
    FusionMSCombinationChart2YComponent,
    FusionMSColumnChartComponent,
    FusionDoughnutChartComponent,
    FusionMsLineScrollableChartComponent,
    FusionMsCombinationScrollableChartComponent,
  ],
  exports: [
    CommonModule,
    ToastrModule,
    AgGridModule,
    TreeviewModule,
    TreeGridModule,
    CKEditorModule,
    NgxCaptureModule,
    NgxDropzoneModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    MaterialModule,
    NgxPowerBiModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    ConfirmationDialogComponent,
    EditButtonRendererComponent,
    NameRendererComponent,
    NgMultiSelectDropDownModule,
    TreeViewModule,
    MasterItemEditRenderer,
    ChildItemEditRenderer,
    FusionChartsModule,
    PromotionItemEditRenderer,
    AGNumberEditorComponent,
    TreeviewDropdownComponent,
    ChildItemActionCellRenderer,
    MasterItemActionCellRenderer,
    PromotionPlannerEditRenderer,
    PrivilegeErrorComponent,
    HttpClientModule,
    ButtonRendererComponent,
    EditButtonRendererComponent,
    NameRendererComponent,
    ImageRendererComponent,
    AddButtonRendererComponent,
    LineChartComponent,
    CloneRendererComponent,
    ToggleButtonRendererComponent,
    CdkTreeModule,
    AGColumnDialogComponent,
    CheckboxRenderer,
    DropdownRendererComponent,
    UpdateNameDialogComponent,
    CommitActionCellRenderer,
    OutlierLineChartCellRenderer,
    PromoItemsEditRenderer,
    DepromoWeekCellEditor,
    SeasonalityEditRenderer,
    TreeViewAccountDropdownComponent,
    TreeViewItemHierarchyDropdownComponent,
    ForecastManagerEditRenderComponent,
    ForecastDataManagerEditRenderComponent,
    SeasonalityLineChartCellRenderer,
    MultiDatepickerModule,
    OutlierCustomHeaderGroup,
    ImageUploadViewerComponent,
    FusionMSLineChartComponent,
    FusionMSBarChartComponent,
    FusionPercentageHorizentalBarChartComponent,
    FusionGageChartComponent,
    FusionMSCombinationChartComponent,
    FusionMSLineAreaChartComponent,
    PageCommentComponent,
    ReportPageFooterComponent,
    FusionMSCombinationChart2YComponent,
    NgxDaterangepickerMd,
    FusionMSColumnChartComponent,
    FusionDoughnutChartComponent,
    FusionMsLineScrollableChartComponent,
    FusionMsCombinationScrollableChartComponent,
    ThreeStateCustomToggleButtonComponent,
    ShowGRNSummaryRenderer,
    ThreeStateCustomToggleButtonComponent,
    MentionModule,
  ],
  entryComponents: [
    ButtonRendererComponent,
    ConfirmationDialogComponent,
    EditButtonRendererComponent,
    NameRendererComponent,
    MasterItemEditRenderer,
    ChildItemEditRenderer,
    PromotionItemEditRenderer,
    AGNumberEditorComponent,
    ImageRendererComponent,
    AddButtonRendererComponent,
    TreeviewDropdownComponent,
    ChildItemActionCellRenderer,
    MasterItemActionCellRenderer,
    PromotionPlannerEditRenderer,
    PrivilegeErrorComponent,
    LineChartComponent,
    CloneRendererComponent,
    ToggleButtonRendererComponent,
    AGColumnDialogComponent,
    DropdownRendererComponent,
    UpdateNameDialogComponent,
    CommitActionCellRenderer,
    OutlierLineChartCellRenderer,
    PromoItemsEditRenderer,
    DepromoWeekCellEditor,
    SeasonalityEditRenderer,
    TreeViewAccountDropdownComponent,
    TreeViewItemHierarchyDropdownComponent,
    ForecastManagerEditRenderComponent,
    ForecastDataManagerEditRenderComponent,
    SeasonalityLineChartCellRenderer,
    OutlierCustomHeaderGroup,
    ImageUploadViewerComponent,
    FusionMSLineChartComponent,
    FusionMSBarChartComponent,
    FusionPercentageHorizentalBarChartComponent,
    FusionGageChartComponent,
    FusionMSCombinationChartComponent,
    FusionMSLineAreaChartComponent,
    PageCommentComponent,
    ReportPageFooterComponent,
    FusionMSCombinationChart2YComponent,
    FusionMSColumnChartComponent,
    FusionDoughnutChartComponent,
    FusionMsLineScrollableChartComponent,
    FusionMsCombinationScrollableChartComponent,
    ShowGRNSummaryRenderer,
  ],
})
export class SharedModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
