<div class="mt-3">
  <div class="row m-0 pb-4">
    <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
    <ng-container *ngIf="!undefinedImagePath">
      <h4 class="mx-2 w-100"><strong>What are the market trends?</strong></h4>
      <div class="col-12 empower-chart-section">
        <p class="font-18">Market: | {{ empowerModernLifeService.getDashboardLocation() }}</p>
        <h4>Country Units by Sub From Factor</h4>
        <fusion-percentage-horizental-bar-chart height="250" [xaxisCatgoryList]="xaxisCatgoryList"
          [dataSeries]="dataSeriesFirst" yAxisName="Units">
        </fusion-percentage-horizental-bar-chart>
      </div>
      <div class="col-12 empower-chart-section mt-3">
        <h4>Country Units by CPU Brand</h4>
        <fusion-percentage-horizental-bar-chart height="250" [xaxisCatgoryList]="xaxisCatgoryList"
          [dataSeries]="dataSeriesSecond" yAxisName="Units">
        </fusion-percentage-horizental-bar-chart>
      </div>
    </ng-container>
    <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
      <app-image-upload-viewer [imageUrl]="undefinedImagePath"
          orientation="square" (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12">
      <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
    </div>
    <div class="col-12">
      <app-report-page-footer></app-report-page-footer>
    </div>
  </div>
</div>