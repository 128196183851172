import { Component, Injector, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChannelMessagingService } from 'src/services/Messaging-Services/channel-messaging.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as _ from 'lodash';
import { LocalstorageService } from 'src/services/localstorage.service';
import {
  ChannelMessaging,
  UsersInChannel,
} from 'src/models/channelMessaging.model';
import * as signalR from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChannelMessagingComponent } from 'src/modules/messaging/channel-messaging/channel-messaging.component';
import { USER_ID } from 'src/common/keys';
import { ConfigurationService } from 'src/services/configuration.service';

@Component({
  selector: 'app-channels-menu',
  templateUrl: './channels-menu.component.html',
  styleUrls: ['./channels-menu.component.scss'],
})
export class ChannelsMenuComponent implements OnInit {
  public channelMessagingModel = new ChannelMessaging();
  public userLeaveChannel = new UsersInChannel();
  public userId = this.storage.get(USER_ID);
  selectedId = null;
  public loadedComponent;
  public unreadMessagesCount: number;
  constructor(
    private channelMessagingService: ChannelMessagingService,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public router: Router,
    private dialog: MatDialog,
    public matDialogRef: MatDialogRef<any>,
    public storage: LocalstorageService,
    private injector: Injector,
    public configurationService: ConfigurationService
  ) { }

  ngOnInit(): void {
    this.getChannelList();
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.messagingHubUrl)
      .build();
    connection
      .start()
      .then(function () {
        console.log('SignalR Connected!');
      })
      .catch(function (err) {
        return console.error();
      });
    connection.on('BroadcastMessage', (con) => {
      this.getChannelList((con = true));
    });
  }

  getChannelList(disabledFlag?) {
    if (disabledFlag === true) {
      this.spinner.hide();
    } else {
      this.spinner.show();
    }
    this.channelMessagingService.GetChannelList().subscribe(
      (channelList: any) => {
        this.spinner.hide();

        this.channelMessagingModel.UsersInChannel = _.sortBy(channelList, (o) =>
          o.channelName.toLowerCase()
        );
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error('Error', 'Something went wrong');
      }
    );
  }

  markChannelMessagesRead(channelId: number) {
    this.channelMessagingService.MarkChannelMessagesRead(channelId).subscribe(
      (count: number) => {
        this.spinner.hide();

      },
      (error) => {
        this.spinner.hide();
        this.toastr.error('Error', 'Something went wrong ');
      }
    );
  }

  deleteChannel(item: any) {
    item.delete = true;
    this.spinner.show();
    this.matDialogRef.close();
    this.channelMessagingService.DeleteChannelRecord(item.channelId).subscribe(
      (deleteChannel: any) => {
        this.selectedId = deleteChannel;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          'Error',
          'Something went wrong Admin Not Delete Channel'
        );
      }
    );
  }

  leaveChannel(item: any) {
    this.spinner.show();
    this.userLeaveChannel = item.usersInChannel.find(
      (id) => id.userId === this.userId
    );
    this.userLeaveChannel.delete = true;
    this.channelMessagingService
      .LeaveChannelUser(this.userLeaveChannel)
      .subscribe(
        (leaveChannel: any) => {
          this.selectedId = leaveChannel;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(
            'Error',
            'Something went wrong User Cant leave the Channel'
          );
        }
      );
  }

  openChannelDialog(isEdit = false, data = {}): void {
    const dialogRef = this.dialog.open(ChannelMessagingComponent, {
      width: '700px',
      height: '700px',
      disableClose:true,
      data: {
        isEdit: isEdit,
        formData: {
          ...data,
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(' close', result);
      }
    });
  }
  public openDeleteDialog = (template: TemplateRef<any>) => {
    this.matDialogRef = this.dialog.open(template, { width: '500px' });
  };
  activeList(item) {
    this.selectedId = item.channelId;
  }
  showChatDetailsElement(item) {
    if (item.messageCount > 0) {
      this.markChannelMessagesRead(item.channelId)
    }

    let selector = {
      module: 'MessagingModule',
      data: item,
      active: true,
      id: 187,
      isPowerBiReport: true,
      menuId: 188,
      offset: 0,
      orderNumber: 178,
      pageSize: 0,
      screenId: 530,
      searchText: null,
      selector: 'app-chat-details',
      sortAscending: false,
      sortColumn: null,
      chineseName: 'Chat Details',
      content: 'Chat Details',
      description: 'Chat Details',
      name: 'Chat Details',
      germanName: 'Chat Details',
      orignalName: 'Chat Details',
    };
    this.getModule(selector);
    setTimeout(() => {
      this.configurationService.menuRendererSubject.next(selector);
    }, 100);
  }

  public getModule = (selector) => {
    switch (selector.module) {
      case 'MessagingModule': {
        if (!this.configurationService.isMessagingLoaded) {
          import('../../../../modules/messaging/messaging.module').then((m) => {
            this.loadedComponent = new m.MessagingModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      }
      default:
        break;
    }
  };
}
