<div class="container-fluid promo-manager-page">

  <div class="row product-grid-filter-row mt-10">
      <div class="product-filter-field-forecast">
        <div class="form-group multi-select d-flex w-100" style="height:47px !important">
          <ng-multiselect-dropdown class="h-100" [placeholder]="_dataService.getDataById(4)" [settings]="SKUDropdownSettings" [data]="SKUList"
            [(ngModel)]="selectedSKU" (onSelect)="selectSKUChange()" (onDeSelect)="selectSKUChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="product-filter-field-forecast">
        <div class="form-group multi-select d-flex w-100" style="height:47px !important">
          <ng-multiselect-dropdown class="h-100" [placeholder]="_dataService.getDataById(5)" [settings]="SKUDescrDropdownSettings" [data]="SKUDescrList"
            [(ngModel)]="selectedSKUDescr" (onSelect)="selectSKUDescrChange()" (onDeSelect)="selectSKUDescrChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="product-filter-field-forecast">
        <div class="form-group multi-select d-flex w-100" style="height:47px !important">
          <ng-multiselect-dropdown class="h-100" [placeholder]="_dataService.getDataById(1)" [settings]="accountDropdownSettings" [data]="accountList"
            [(ngModel)]="selectedAccount" (onSelect)="selectAccountChange()" (onDeSelect)="selectAccountChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="product-filter-field-forecast">
        <div class="form-group multi-select d-flex w-100" style="height:47px !important">
          <ng-multiselect-dropdown class="h-100" [placeholder]="'Seasonality'" [settings]="seasonalityDropdownSettings" [data]="seasonalityList"
            [(ngModel)]="selectedSeasonality" (onSelect)="selectSeasonalityChange()" (onDeSelect)="selectSeasonalityChange()">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="product-filter-clear-btn mr-2">
        <button mat-flat-button color="accent" (click)="clearValues()">Clear</button>
      </div>
  </div>

  <div class="grid-action-btn-bar">
    <button matTooltip="Show/Hide Columns" class="grid-bar-icon" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>

    <div class="grid-actions inline-flex align-items-center">
      <div *ngIf="selectedRows.length" class="row-info mx-2">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{getGridRowsCount}}</strong>
        </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info mx-2">
        <span><strong>Filtered Rows:</strong> {{getGridRowsCount}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>
    <button matTooltip="Export" class="grid-bar-icon ml-10" mat-icon-button color="primary"
      (click)="onExportGridData()">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>

    <button mat-flat-button color="accent" class="ml-10" (click)="getForecastList()">
      <mat-icon>refresh</mat-icon>Refresh
    </button>
    <button mat-flat-button color="accent" class="ml-10" *ngIf="forecastManagerSession"
      (click)="restoreSession()">Restore Session</button>
    <button class="import-export-button" mat-flat-button color="accent"
      [matMenuTriggerFor]="actionMenu">Actions</button>
    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteForecast()">Delete</button>
      <button mat-menu-item (click)="copyForecast()">Copy</button>
    </mat-menu>
    <button class="add-icon-btn ml-10" (click)="openForecastSetupDetail()" matTooltip="Add Forecast" mat-icon-button
      color="primary" type="button">
      <i class="material-icons">
        add_circle
      </i>
    </button>
  </div>

  <div class="all-show-grid forecast-engine-ag-grid">
    <ag-grid-angular #foreCastManagerGrid id="seasonality-data-grid" class="ag-theme-material forecast-manager-grid"
       [columnDefs]="columnDefs" [gridOptions]="gridOptions" [rowData]="gridData"
      [defaultColDef]="defaultColDef" [defaultCsvExportParams]="defaultExportParams" [suppressRowClickSelection]="true"
      [frameworkComponents]="frameworkComponents" [rowSelection]="rowSelection" [animateRows]="true" [pagination]="true"
      [paginationPageSize]="paginationPageSize" [cacheBlockSize]="cacheBlockSize" (filterChanged)="clearSelectedRows()"
      (selectionChanged)="rowSelectionChanged($event)" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
