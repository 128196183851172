import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ProcurementService {
  public pmsActionCellSubject: Subject<any> = new Subject();
  public promoSetupActionSubject: Subject<any> = new Subject();
  /* Set Subjects for HEADER COLUMN EDIT/REMOVE */
  setPMSGridActionCellSubject = (value: any) => {
    this.pmsActionCellSubject.next(value);
  };
  //setPromoGridActionCellSubject = (value: any) => { this.promoGridActionCellSubject.next(value); };
  // Observables
  getPMSGridActionCellSubject = () => this.pmsActionCellSubject.asObservable();
  constructor(private httpClient: HttpClient) {}
  addItem(obj = {}) {
    return this.httpClient.post(`${environment.PMS}/Item/ItemAdd`, obj);
  }
  updateItem(obj = {}) {
    return this.httpClient.put(`${environment.PMS}/Item/ItemUpdate`, obj);
  }
  getItemById(id) {
    return this.httpClient.get(`${environment.PMS}/Item/ItemGet?ItemId=${id}`);
  }
  getItemsListing(ItemName = '', purchaseTypeDescription = '') {
    // PurchaseTypeId = "",
    // if (PurchaseTypeId === "") {
    //   purchaseTypeDescription = PurchaseTypeId;
    // }
    return this.httpClient.get(
      `${environment.PMS}/Item/ItemGetList?ItemName=${ItemName}&PurchaseTypeDescription=${purchaseTypeDescription}`
    );
  }
  addLocation(obj = {}) {
    return this.httpClient.post(`${environment.PMS}/Location/LocationAdd`, obj);
  }
  updateLocation(obj = {}) {
    return this.httpClient.put(
      `${environment.PMS}/Location/LocationUpdate`,
      obj
    );
  }
  getLocationById(id) {
    return this.httpClient.get(
      `${environment.PMS}/Location/LocationGet?LocationId=${id}`
    );
  }
  getLocationsListing(LocationName = '', CurrencyCode = '') {
    return this.httpClient.get(
      `${environment.PMS}/Location/LocationGetList?LocationName=${LocationName}&CurrencyCode=${CurrencyCode}`
    );
  }
  addVendor(obj = {}) {
    return this.httpClient.post(`${environment.PMS}/Vendor/VendorAdd`, obj);
  }
  addVendorEvalution(obj = {}) {
    return this.httpClient.post(
      `${environment.PMS}/Vendor/VendorEvalutionAdd`,
      obj
    );
  }
  updateVendor(obj = {}) {
    return this.httpClient.put(`${environment.PMS}/Vendor/VendorUpdate`, obj);
  }
  getVendorById(id) {
    return this.httpClient.get(
      `${environment.PMS}/Vendor/VendorGet?VendorId=${id}`
    );
  }
  getVendorByPRId(prId) {
    return this.httpClient.get(
      `${environment.PMS}/Vendor/VendorGetByPrId?PRId=${prId}`
    );
  }
  getVendorEvaluation(id) {
    return this.httpClient.get(
      `${environment.PMS}/Vendor/VendorEvaluationGet?VendorId=${id}`
    );
  }
  getVendorsListing(VendorName = null, VendorEmail = null, NTNNumber = null) {
    return this.httpClient.get(
      `${environment.PMS}/Vendor/VendorGetList?VendorName=${VendorName}&NTNNumber=${NTNNumber}&VendorEmail=${VendorEmail}`
    );
  }
  getPurchaseRequestsListing(prTitle, statusDescription) {
    return this.httpClient.get(
      `${environment.PMS}/PurchaseRequest/PurchaseRequestGetList?PRTitle=${prTitle}&StatusDescription=${statusDescription}`
    );
  }
  getPurchaseRequestsApproveList(prTitle, statusDescription) {
    return this.httpClient.get(
      `${environment.PMS}/PurchaseRequest/PurchaseRequestApproveGetList?PRTitle=${prTitle}&StatusDescription=${statusDescription}`
    );
  }
  addPurchaseRequest(body) {
    return this.httpClient.post(
      `${environment.PMS}/PurchaseRequest/PurchaseRequestAdd`,
      body
    );
  }
  addPrItem(body) {
    return this.httpClient.post(`${environment.PMS}/PRItem/PRItemAdd`, body);
  }
  getPRItemGetListbyId(id) {
    return this.httpClient.get(
      `${environment.PMS}/PRItem/PRItemGetList?PRId=${id}`
    );
  }
  getBidListById(id) {
    return this.httpClient.get(
      `${environment.PMS}/Bid/BidGetByPRId?PRId=${id}`
    );
  }
  getRequirementsLookup() {
    return this.httpClient.get(`${environment.PMS}/Lookup/RequirementTypeGet`);
  }
  getStatusLookup() {
    return this.httpClient.get(`${environment.PMS}/Lookup/StatusGet`);
  }
  getPurchaseTypeList() {
    return this.httpClient.get(`${environment.PMS}/Lookup/PurchaseTypeGet`);
  }
  uploadDocument(data) {
    return this.httpClient.post(
      `${environment.PMS}/PurchaseRequest/uploaddoc`,
      data
    );
  }
  UpdatePurchaseRequestUpdateStatus(data) {
    return this.httpClient.put(
      `${environment.PMS}/PurchaseRequest/PurchaseRequestStatusUpdate`,
      data
    );
  }
  getGRNList() {
    return this.httpClient.get(`${environment.PMS}/GRN/GRNGetList`);
  }
  getGRNItems(prId) {
    return this.httpClient.get(
      `${environment.PMS}/GRN/GetGRNItems?PRId=${prId}`
    );
  }
  getGRNAwaitingList(prTitle, statusDescription) {
    return this.httpClient.get(
      `${environment.PMS}/GRN/GetAwaitingGRN?PRTitle=${prTitle}&GRNStatus=${statusDescription}`
    );
  }
  addGRN(body) {
    return this.httpClient.post(`${environment.PMS}/GRN/GRNAdd`, body);
  }
  uploadGRNDocument(data) {
    return this.httpClient.post(`${environment.PMS}/GRN/uploadimage`, data);
  }
  getPurchaseOrdersListing(prTitle, statusDescription) {
    return this.httpClient.get(
      `${environment.PMS}/PurchaseOrder/PurchaseOrderGetList?PRTitle=${prTitle}&StatusDescription=${statusDescription}`
    );
  }
  addPurchaseOrder(body) {
    return this.httpClient.post(
      `${environment.PMS}/PurchaseOrder/PurchaseOrderAdd`,
      body
    );
  }
  updatePurchaseOrder(body) {
    return this.httpClient.put(
      `${environment.PMS}/PurchaseOrder/PurchaseOrderUpdate`,
      body
    );
  }
  getPurchaseOrderSummary(prId) {
    return this.httpClient.get(
      `${environment.PMS}/PurchaseOrder/PurchaseOrderSummaryGet?PRId=${prId}`
    );
  }
  addBidAll(body) {
    return this.httpClient.post(`${environment.PMS}/Bid/BidAdd`, body);
  }
  updateBidAll(body) {
    return this.httpClient.post(`${environment.PMS}/Bid/BidUpdateAll`, body);
  }
  UpdateBidUpdateStatus(data) {
    return this.httpClient.put(`${environment.PMS}/Bid/BidActivate`, data);
  }
}
export const typesArray = [
  {
    id: 1,
    title: 'Goods',
  },
  {
    id: 2,
    title: 'Services',
  },
];
