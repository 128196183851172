import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-name-dialog',
  templateUrl: './update-name-dialog.component.html',
  styleUrls: ['./update-name-dialog.component.scss']
})
export class UpdateNameDialogComponent implements OnInit {

  name = '';
  ignoreValidations = false;

  constructor(public dialogRef: MatDialogRef<UpdateNameDialogComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.name = JSON.parse(JSON.stringify(data.name));
    this.ignoreValidations = this.data.ignoreValidations || false;
  }

  ngOnInit(): void {
  }

  onSaveClick() {
    // dunction to check for special characters :: if the string contains completely special characters
    const checkForSpecialCharacters = (headerName: string) => {
      const format = /[a-zA-Z0-9]/;
      return !format.test(headerName);
    }

    if (!checkForSpecialCharacters(this.name.trim()) || this.ignoreValidations)
      this.dialogRef.close(this.name.trim());
    else {
      this.toastrService.warning('Name cannot be special characters solely.');
    }
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}
