import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject, of } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxToasterService } from '../ngx-toaster.service';
import { EmpowerService } from './empower.service';

@Injectable()
export class EmpowerGamingService {

  // Subjects
  public xboxSideNavList = new BehaviorSubject<Array<any>>([]);
  public exportComponentSubject: Subject<{ screen: number, resolved: boolean, pageIndex: number }> = new Subject();

  // Set Subject Values
  xboxSetSideNavList = (value: Array<any>) => { this.xboxSideNavList.next(value); };
  setExportComponentSubject = (screen: number, resolved: boolean, pageIndex: number) => { this.exportComponentSubject.next({ screen, resolved, pageIndex }); };

  dashboardState: any = {
    countryName: [],
    consumerTopParent: [],
    calendarIds: []
  };

  pagesState: any = {};
  pageImgState: any = {};
  pageCommentState: any = {};

  constructor(private http: HttpClient,
    private toastr: NgxToasterService,
    private empowerService: EmpowerService) {
  }

  setPageCommentState(pageId, comment) {
    this.pageCommentState[pageId] = comment;
  }

  getPageCommentState(pageId): any {
    return this.pageCommentState[pageId];
  }

  setDashboardState(state: any) {
    this.dashboardState = state;
  }

  getDashboardState(): any {
    return this.dashboardState;
  }

  setPagesState(pageId, daxData) {
    this.pagesState[pageId] = daxData;
  }

  getPagesState(pageId) {
    return this.pagesState[pageId];
  }

  setPageImgState(pageId, imgFile) {
    this.pageImgState[pageId] = imgFile;
  }

  getPageImgState(pageId) {
    return this.pageImgState[pageId];
  }

  clearPagesState() {
    this.pagesState = {};
  }

  isDashboardStateValid(): boolean {
    return (this.dashboardState.countryName.length === 1
      && this.dashboardState.consumerTopParent.length === 1
      && this.dashboardState.calendarIds.length) ? true : false;
  }

  GetDashboardFilterPayload() {
    const body = this.dashboardState;
    const payload = {
      countryName: body.countryName && body.countryName.map(a => `"${a}"`).join(','),
      partnerName: body.partnerName && body.partnerName.map(a => `"${a}"`).join(','),
      consumerTopParent: body.consumerTopParent && body.consumerTopParent.map(a => `"${a}"`).join(','),
      calendarIds: body.calendarIds && body.calendarIds.map(a => moment(a).format('YYYYMMDD')).join(',')
    }
    return payload;
  }

  GetDashboardFilterPayloadForLogging() {
    const body = this.dashboardState;
    const payload = {
      countryName: body.countryName && body.countryName.join(','),
      partnerName: body.partnerName && body.partnerName.join(','),
      consumerTopParent: body.consumerTopParent && body.consumerTopParent.join(','),
      calendarIds: body.calendarIds && body.calendarIds.map(a => moment(a).format('YYYYMMDD')).join(',')
    }
    return payload;
  }

  getDashboardLocation(): any {
    return _.get(this.dashboardState, 'countryName.0', '');
  }

  getDashboardPartner(): any {
    return _.get(this.dashboardState, 'consumerTopParent.0', '');
  }

  GetSideNavMenu(reportId) {
    return this.http.get<any>(`${environment.EmpowerReport}/ReportPageGetAll?reportId=${reportId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  ExecutePageDaxQuery(body) {
    if (this.isDashboardStateValid()) {
      return this.empowerService.ExecutePageDaxQuery(body).pipe(map(response => {
        return response;
      }));
    } else {
      this.toastr.error('Error', 'Please set dashboard filters correctly.');
      of(false);
    }
  }

}

