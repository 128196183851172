<div class="dialog-close-btn">
</div>
<div class="container-fluid promo-manager-detail-page blog-height">
  <form class="promo-form m-2" [formGroup]="forecastForm">
    <div id="fb-forecast-new-planner" class="container-fluid">
      <div class="btn-wrapper">
        <div class="form-group">
          <app-treeview-account-dropdown [type]="'Channel'" [items]="channelDropdownList"
            (valueChange)="setValue($event)">
          </app-treeview-account-dropdown>
        </div>
        <div class="form-group">
          <app-treeview-item-hierarchy-dropdown [type]="'Item'" [items]="productList" (valueChange)="setValue($event)">
          </app-treeview-item-hierarchy-dropdown>
        </div>

      </div>
    </div>
    <div class="item-wrapper">
      <div class="row">
        <div class="col-6">
          <div class="form-group align-items-start">
            <label for="forecastMethod">Forecast Method*</label>
            <mat-radio-group class="d-flex flex-column radio-btn-grp w-100" name="forecastMethod"
              formControlName="forecastMethod">
              <mat-radio-button value="SMA" (change)="radioChange($event)">Simple Moving Average</mat-radio-button>
              <mat-radio-button class="mt-5px" value="WMA" (change)="radioChange($event)">Weighted Moving Average
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-6" *ngIf="forecastMethod.value==='SMA'">
          <div class="d-flex align-items-center mb-4">
            <div class="form-group mb-0">
              <label for="lockLookBackWeek">Fix Lookback Weeks*</label>
            </div>
            <div class="toggle-btn">
              <mat-slide-toggle formControlName="lockLookBackWeek"> </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group-section">
            <label>Lookback Weeks*</label>
            <div class="form-group">
              <label for="lookbackFromWeek">From Week*</label>
              <mat-form-field>
                <input readonly matInput [matDatepickerFilter]="weekDayFilter" [matDatepicker]="lookbackFromWeek"
                  name="lookbackFromWeek" formControlName="lookbackFromWeek" [max]="lookBackMaxDate"
                  (dateChange)="lookbackFromWeekChange($event)">
                <mat-datepicker-toggle matSuffix [for]="lookbackFromWeek"></mat-datepicker-toggle>
                <mat-datepicker #lookbackFromWeek></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="form-group">
              <label for="lookbackToWeek">To Week*</label>
              <mat-form-field>
                <input readonly matInput [matDatepickerFilter]="weekDayFilter" [matDatepicker]="lookbackToWeek"
                  name="lookbackToWeek" formControlName="lookbackToWeek" [max]="lookBackMaxDate" [min]="lookBackMinDate"
                  (dateChange)="lookBackToWeekChange($event)">
                <mat-datepicker-toggle matSuffix [for]="lookbackToWeek"></mat-datepicker-toggle>
                <mat-datepicker #lookbackToWeek [startAt]="startToDate"></mat-datepicker>
              </mat-form-field>
            </div>

            <div *ngIf="lookbackToWeek.invalid && (lookbackToWeek.dirty || lookbackToWeek.touched)"
              class="alert alert-danger">
              From week should be less then to week.
            </div>

            <div *ngIf="!isValidWeeks && forecastMethod.value==='WMA'" class="alert alert-danger">
              Sum of weightage weeks percentage should be 100 and each week value is required.
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group-section">
            <label>Forecast Time*</label>
            <div class="form-group">
              <label for="forecastFromWeek">From Week*</label>
              <mat-form-field>
                <input readonly matInput [matDatepickerFilter]="weekDayFilter" [matDatepicker]="forecastFromWeek"
                  name="forecastFromWeek" formControlName="forecastFromWeek" [min]="forecastMinDate">
                <mat-datepicker-toggle matSuffix [for]="forecastFromWeek"></mat-datepicker-toggle>
                <mat-datepicker #forecastFromWeek></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label for="forecastWeekPeriodId">Time Period*</label>
              <mat-select name="forecastWeekPeriodId" formControlName="forecastWeekPeriodId">
                <mat-option *ngFor="let forecastWeek of foreCastWeekList" [value]="forecastWeek.forecastWeekPeriodId">
                  {{forecastWeek.forecastWeek}}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" *ngIf="forecastMethod.value==='WMA'">
          <label>Weights %</label>
          <div class="weeks-section" id="style-3">
            <ng-container *ngFor="let week of weightageWeeks; let i =index">
              <div class="week-values">
                <div class="week-index">{{'W-'+week.weekIndex}}</div>
                <input class="week-input" type="number" [(ngModel)]="week.weight"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </ng-container>
          </div>
          <div *ngIf="forecastMethod.value==='WMA'" class="alert alert-info">
            Sum of Weightage: {{ weightageWeeksSum }}
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row form-action-btn">
    <button mat-raised-button color="accent" [disabled]="isForecastGenerated"
      (click)="fetchItemAccountSeasonalities()">Next</button>
  </div>
</div>


<div *ngIf="forecastHierarchyLevelWithSeasonality.length > 0;">
  <div class="row forecast-action-bar">
    <button matTooltip="Export" class="mr-10" class="grid-bar-icon" mat-icon-button color="primary"
      (click)="onExportData()">
      <i class="material-icons">
        insert_drive_file
      </i>
    </button>
    <button mat-raised-button color="accent" [disabled]="isForecastGenerated" (click)="generateForecast()"> Generate
      Forecast</button>
  </div>

  <div class="row item-account-tbl" [id]="seasonalityTableId">
    <div class="col-md-1" id="focusId" >
    </div>
    <div class="col-md-10" class="block-style"  (scroll)="removeAutocompleteFocus()" >
      <table class="table-style" >
        <thead>
        <tr>
          <th><strong>Account</strong></th>
          <th><strong>Item</strong></th>
          <th><strong>Seasonality Key</strong></th>
          <th><strong>Seasonality Name</strong></th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let hierarchyLevel of forecastHierarchyLevelWithSeasonality">

          <td class="text-align account-width">{{hierarchyLevel.retailerName}}</td>
          <td class="text-align item-width">{{hierarchyLevel.itemDescription}}</td>

          <td><input class="seasonalityKey-width" name="seasonalityKey" [(ngModel)]="hierarchyLevel.seasonalityKey"
              [ngModelOptions]="{standalone: true}" [ngModelOptions]="{standalone: true}"
              [matAutocomplete]="seasonalityKeyAuto" (input)="_filterSeasonalityByKey($event,hierarchyLevel)"
              (focus)="onSeasonalityFocus($event)" />

            <mat-autocomplete [displayWith]="displaySeasonalityKeyFn" #seasonalityKeyAuto="matAutocomplete"
              (optionSelected)="selectItemAccountSeasonality($event.option.value, hierarchyLevel)">
              <mat-option *ngFor="let seasonality of filteredSeasonalityList | async"
                [matTooltip]="seasonality.seasonalityKey" [value]="seasonality" matTooltipClass="tooltips">
                {{seasonality.seasonalityKey}}
              </mat-option>
            </mat-autocomplete>
          </td>

          <td> <input name="seasonalityName" #seasonalityName class="seasonalityName-width table-input-cell" [(ngModel)]="hierarchyLevel.seasonalityName"
            [ngModelOptions]="{standalone: true}"
            [matAutocomplete]="seasonalityNameAuto" (input)="_filterSeasonalityByName($event, hierarchyLevel)"
            (focus)="onSeasonalityFocus($event)" />
          <mat-autocomplete [displayWith]="displaySeasonalityNameFn" #seasonalityNameAuto="matAutocomplete"
            (optionSelected)="selectItemAccountSeasonality($event.option.value, hierarchyLevel)">
            <mat-option *ngFor="let seasonality of filteredSeasonalityList | async"
              [matTooltip]="seasonality.seasonalityName" [value]="seasonality" matTooltipClass="tooltips">
              {{seasonality.seasonalityName}}
            </mat-option>
          </mat-autocomplete>
        </td>
        </tr>

      </tbody>
      <tfoot>
        <tr></tr>
      </tfoot>
      </table>
    </div>
    <div class="col-md-1"></div>
  </div>

  <div style="width: 0; height: 0;">
    <ag-grid-angular #forecastHierarchyExportGridHidden class="ag-theme-material hide-grid" [gridOptions]="gridOptions"
      [columnDefs]="columnHiddenDefs" [defaultColDef]="defaultColDef" [rowData]="gridData"
      (gridReady)="onHiddenGridReady($event)" >
    </ag-grid-angular>
  </div>

</div>
