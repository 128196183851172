import { TreeviewItem } from 'ngx-treeview';
import * as _ from 'lodash';
import { Calendar } from 'src/modules/forecast-engin/features/forecast-manager/models/Calendar';

export default class TreeControlParams {
  dataList: any = [];
  mappedValueList: any = [];
  disableValueList: any = [];
  isAllChecked: boolean = false;
  isAllDisabled: boolean = false;
};

export const CreateForecastAccountTree = ({
  dataList = [],
  mappedValueList = [],
  disableValueList = [],
  isAllChecked = false,
  isAllDisabled = false
}: TreeControlParams) => {
  const channels = _.uniqBy(dataList, a => a.channel);

  let treeList = [];
  channels.forEach((channel) => {
    let channelObject = {
      channels: channel.channel,
      name: channel.channel,
      channel: channel.channel,
      retailerKey: channel.retailerKey,
      children: [],
      isChannel: true,
      isDelete: true,
    };
    const regions = _.uniqBy(_.filter(dataList, a =>
      a.channel === channel.channel
    ), b => b.region);
    regions.forEach((region) => {
      let regionObject = {
        regionName: region.region,
        name: region.region,
        region: region.region,
        channel: channel.channel,
        retailerKey: region.retailerKey,
        children: [],
        isCountry: true,
        isDelete: true,
      };
      const countries = _.uniqBy(_.filter(dataList, a => a.channel === channel.channel && a.region === region.region), b => b.country);
      countries.forEach((country) => {
        let countryObj = {
          countries: country.country,
          name: country.country,
          country: country.country,
          region: region.region,
          channel: channel.channel,
          retailerKey: country.retailerKey,
          children: [],
          isRegion: true,
          isDelete: true,
        };

        let retailers = _.uniqBy(_.filter(dataList, a =>
          a.country === country.country &&
          a.region === region.region &&
          a.channel === channel.channel), b => b.retailer);
        retailers = retailers.map((row) => ({
          name: row.retailer,
          isProduct: true,
          ...row,
        }));
        countryObj.children = retailers;
        if (regionObject) {
          regionObject.children.push(countryObj);
        }
      });
      if (regionObject) {
        channelObject.children.push(regionObject);
      }
    });
    treeList.push(channelObject);
  });
  const tree = [];
  treeList.forEach((channel) => {
    let channelObject = new TreeviewItem({
      text: channel.name,
      collapsed: true,
      value: channel.channel,
      checked: isAllChecked || mappedValueList.includes(channel.retailerKey),
      disabled: isAllDisabled,
      children: channel.children.map((region) => {
        return new TreeviewItem({
          text: region.name,
          collapsed: true,
          value: region.region,
          checked: isAllChecked || mappedValueList.includes(region.retailerKey),
          disabled: isAllDisabled,
          children: region.children.map((country) => {
            return new TreeviewItem({
              text: country.name,
              collapsed: true,
              value: country.country,
              checked: isAllChecked || mappedValueList.includes(country.retailerKey),
              disabled: isAllDisabled,
              children: country.children.map((retailer) => {
                return new TreeviewItem({
                  text: retailer.name,
                  collapsed: true,
                  value: retailer.retailerKey,
                  checked: isAllChecked || mappedValueList.includes(retailer.retailerKey),
                  disabled: isAllDisabled || disableValueList.includes(retailer.retailerKey),
                });
              }),
            });
          }),
        });
      }),
    });
    tree.push(channelObject);
  });
  return tree;
};

export const CreateForecastItemTree = ({
  dataList = [],
  mappedValueList = [],
  disableValueList = [],
  isAllChecked = false,
  isAllDisabled = false
}: TreeControlParams) => {

  const brands = _.uniqBy(dataList, a => a.brand);

  let treeList = [];
  brands.forEach((brand) => {
    let brandsObject = {
      brandsName: brand.brand,
      name: brand.brand,
      brand: brand.brand,
      itemKey: brand.itemKey,
      children: [],
      isBrand: true,
    };

    const productGroups = _.uniqBy(_.filter(dataList, a =>
      a.brand === brand.brand
    ), b => b.productGroup);

    productGroups.forEach((productGroup) => {
      let productGroupObject = {
        productGroupName: productGroup.productGroup,
        name: productGroup.productGroup,
        productGroup: productGroup.productGroup,
        brand: brand.brand,
        itemKey: productGroup.itemKey,
        children: [],
        isProductFamily: true,
      };

      const parentProducts = _.uniqBy(_.filter(dataList, a => a.brand === brand.brand && a.productGroup === productGroup.productGroup), b => b.parentProduct);

      parentProducts.forEach((parentProduct) => {
        let parentProductObject = {
          parentProductName: parentProduct.parentProduct,
          name: parentProduct.parentProduct,
          parentProduct: parentProduct.parentProduct,
          productGroup: productGroup.productGroup,
          brand: brand.brand,
          itemKey: parentProduct.itemKey,
          children: [],
          isProductGroup: true,
        };

        const productDescriptions = _.uniqBy(_.filter(dataList, a =>
          a.brand === brand.brand
          && a.productGroup === productGroup.productGroup
          && a.parentProduct === parentProduct.parentProduct
        ), b => b.productDescription);

        productDescriptions.forEach((productDescription) => {

          let productDescriptionObject = {
            productDescriptionName: productDescription.productDescription,
            name: productDescription.productDescription,
            itemKey: productDescription.itemKey,
            productDescription: productDescription.productDescription,
            parentProduct: parentProduct.parentProduct,
            productGroup: productGroup.productGroup,
            brand: brand.brand,
            isParentProduct: true,
          };

          if (productDescriptionObject.productDescription) {
            parentProductObject.children.push(productDescriptionObject);
          }
        });
        if (parentProductObject.parentProduct) {
          productGroupObject.children.push(parentProductObject);
        }
      });
      if (productGroupObject.productGroup) {
        brandsObject.children.push(productGroupObject);
      }
    });
    treeList.push(brandsObject);
  });
  const tree = [];
  treeList.forEach((brand) => {
    let brandObject = new TreeviewItem({
      text: brand.name,
      collapsed: true,
      checked: isAllChecked || mappedValueList.includes(brand.itemKey),
      value: brand.brand,
      children: brand.children.map((productGroup) => {
        return new TreeviewItem({
          text: productGroup.name,
          collapsed: true,
          checked: isAllChecked || mappedValueList.includes(productGroup.itemKey),
          value: productGroup.productGroup,
          children: productGroup.children.map((parentProduct) => {
            return new TreeviewItem({
              text: parentProduct.name,
              collapsed: true,
              checked: isAllChecked || mappedValueList.includes(parentProduct.itemKey),
              value: parentProduct.parentProduct,
              children: parentProduct.children.map((productDescription) => {
                return new TreeviewItem({
                  text: productDescription.name,
                  collapsed: true,
                  value: productDescription.itemKey,
                  checked: isAllChecked || mappedValueList.includes(productDescription.itemKey),
                  disabled: isAllDisabled || disableValueList.includes(productDescription.itemKey),
                });
              }),
            });
          }),
          // });
          // }),
        });
      }),
    });
    tree.push(brandObject);
  });
  return tree;
}
export const CreateForecastItemTree2 = ({
  dataList = [],
  mappedValueList = [],
  disableValueList = [],
  isAllChecked = false,
  isAllDisabled = false
}: TreeControlParams) => {
  const brands = _.uniqBy(dataList, a => a.brand);

  let treeList = [];
  brands.forEach((brand) => {
    let brandsObject = {
      brandsName: brand.brand,
      name: brand.brand,
      brand: brand.brand,
      itemKey: brand.itemKey,
      children: [],
      isBrand: true,
    };

    const productGroups = _.uniqBy(_.filter(dataList, a =>
      a.brand === brand.brand
    ), b => b.productGroup);

    productGroups.forEach((productGroup) => {
      let productGroupObject = {
        productGroupName: productGroup.productGroup,
        name: productGroup.productGroup,
        productGroup: productGroup.productGroup,
        brand: brand.brand,
        itemKey: productGroup.itemKey,
        children: [],
        isProductFamily: true,
      };

      const parentProducts = _.uniqBy(_.filter(dataList, a => a.brand === brand.brand && a.productGroup === productGroup.productGroup), b => b.parentProduct);

      parentProducts.forEach((parentProduct) => {
        let parentProductObject = {
          parentProductName: parentProduct.parentProduct,
          name: parentProduct.parentProduct,
          parentProduct: parentProduct.parentProduct,
          productGroup: productGroup.productGroup,
          brand: brand.brand,
          itemKey: parentProduct.itemKey,
          children: [],
          isProductGroup: true,
        };
        const productDescriptions = _.uniqBy(_.filter(dataList, a =>
          a.brand === brand.brand
          && a.productGroup === productGroup.productGroup
          && a.parentProduct === parentProduct.parentProduct
        ), b => b.productDescription);

        productDescriptions.forEach((productDescription) => {

          let productDescriptionObject = {
            productDescriptionName: productDescription.productDescription,
            name: productDescription.productDescription,
            itemKey: productDescription.itemKey,
            productDescription: productDescription.productDescription,
            parentProduct: parentProduct.parentProduct,
            productGroup: productGroup.productGroup,
            brand: brand.brand,
            isParentProduct: true,
          };

          if (productDescriptionObject.productDescription) {
            parentProductObject.children.push(productDescriptionObject);
          }
        });
        if (parentProductObject.parentProduct) {
          productGroupObject.children.push(parentProductObject);
        }
      });
      if (productGroupObject.productGroup) {
        brandsObject.children.push(productGroupObject);
      }
    });
    treeList.push(brandsObject);
  });
  const tree = [];
  treeList.forEach((brand) => {
    let brandObject = new TreeviewItem({
      text: brand.name,
      collapsed: true,
      checked: isAllChecked || mappedValueList.includes(brand.itemKey),
      disabled: isAllDisabled,
      value: brand.brand,
      children: brand.children.map((productGroup) => {
        return new TreeviewItem({
          text: productGroup.name,
          collapsed: true,
          checked: isAllChecked || mappedValueList.includes(productGroup.itemKey),
          disabled: isAllDisabled,
          value: productGroup.productGroup,
          children: productGroup.children.map((parentProduct) => {
            return new TreeviewItem({
              text: parentProduct.name,
              collapsed: true,
              checked: isAllChecked || mappedValueList.includes(parentProduct.itemKey),
              disabled: isAllDisabled,
              value: parentProduct.parentProduct,
              children: parentProduct.children.map((productDescription) => {
                return new TreeviewItem({
                  text: `${productDescription.itemKey}-${productDescription.name}`,
                  collapsed: true,
                  value: productDescription.itemKey,
                  checked: isAllChecked || mappedValueList.includes(productDescription.itemKey),
                  disabled: isAllDisabled || disableValueList.includes(productDescription.itemKey),
                });
              }),
            });
          }),
          // });
          // }),
        });
      }),
    });
    tree.push(brandObject);
  });
  return tree;
}

export const CreateCalendarList = (
  calendarListDropdown,
  timeframeList,
  mappedTimeFrame,
) => {
  calendarListDropdown = [];
  const uniqueYears = [
    ...new Set(timeframeList.map((item: Calendar) => item.reportYear)),
  ];
  uniqueYears.forEach((year: string, index) => {
    const yearList = timeframeList.filter((row) => row.reportYear === year);
    const uniqueHalfYears = [
      ...new Set(yearList.map((item: Calendar) => item.reportHalf)),
    ];
    const uniqueQuarters = [];
    const uniqueMonths = [];
    const uniqueWeeks = [];
    yearList.forEach((y) => {
      if (
        uniqueQuarters.findIndex(
          (x) => x.quater === y.quater && x.reportHalf === y.reportHalf
        ) < 0
      ) {
        uniqueQuarters.push({ quater: y.quater, reportHalf: y.reportHalf });
      }
      if (
        uniqueMonths.findIndex(
          (x) => x.quater === y.quater && x.reportMonth === y.reportMonth
        ) < 0
      ) {
        uniqueMonths.push({ quater: y.quater, reportMonth: y.reportMonth });
      }
      if (
        uniqueWeeks.findIndex(
          (x) => x.quater === y.quater && x.reportMonth === y.reportMonth && x.weekStartDate === y.weekStartDate
        ) < 0
      ) {
        uniqueWeeks.push({ quater: y.quater, reportMonth: y.reportMonth, weekStartDate: y.weekStartDate });
      }
      y.checked =
        mappedTimeFrame && mappedTimeFrame.weekNumber
          ? mappedTimeFrame.weekNumber
            .split(',')
            .find((val) => val === y.weekNumber.toString())
          : false;
    });
    const obj = new TreeviewItem({
      text: year,
      value: year,
      checked: mappedTimeFrame.length > 0 && mappedTimeFrame.includes(yearList.weekNumber),
      collapsed: true,
      children: uniqueHalfYears.map(
        (h: any) =>
          new TreeviewItem({
            text: h,
            value: h,
            checked: mappedTimeFrame.length > 0 && mappedTimeFrame.includes(h.weekNumber),
            collapsed: true,
            children: uniqueQuarters
              .filter((e) => e.reportHalf === h)
              .map(
                (q) =>
                  new TreeviewItem({
                    text: q.quater,
                    value: q.quater,
                    checked: mappedTimeFrame.length > 0 && mappedTimeFrame.includes(q.weekNumber),
                    collapsed: true,
                    children: uniqueMonths
                      .filter((el) => el.quater === q.quater)
                      .map((m) => ({
                        children: yearList
                          .filter(
                            (el) =>
                              el.reportMonth === m.reportMonth &&
                              el.quater === q.quater &&
                              el.reportHalf === h
                          )
                          .map(
                            (row) =>
                              new TreeviewItem({
                                text: new Date(
                                  row.weekStartDate
                                ).toLocaleDateString(),
                                value: row.weekNumber,
                                checked: mappedTimeFrame.length > 0 && mappedTimeFrame.includes(row.weekNumber),
                                collapsed: true,
                              })
                          ),
                        text: m.reportMonth,
                        checked: mappedTimeFrame.length > 0 && mappedTimeFrame.includes(m.weekNumber),
                        value: yearList
                          .filter(
                            (el) =>
                              el.weekNumber === m.weekNumber &&
                              el.reportMonth === m.reportMonth &&
                              el.quater === q.quater &&
                              el.reportHalf === h
                          )
                          .map((cal) => cal.weekNumber),
                      })),
                  })
              ),
          })
      ),
    });
    calendarListDropdown.push(obj);
  });
  return calendarListDropdown;
};

// end Asad
