<div class="dialog-close-btn">
  <h1 mat-dialog-title>Un-Mapped Items</h1>
  <button mat-icon-button (click)="onCloseClick()">
    <i class="material-icons">
      close
    </i>
  </button>
</div>

<div mat-dialog-content>

  <div class="grid-action-btn-bar">

    <div class="grid-actions inline-flex">
      <div *ngIf="selectedRows.length" class="row-info">
        <spnan><strong>{{selectedRows.length}}</strong> rows selected out of <strong>{{gridData.length}}</strong> </spnan>
        <button mat-button color="accent" (click)="clearSelectedRows()">Clear Selection</button>
      </div>
      <div *ngIf="isFilteredApplied" class="row-info filtered-info">
        <span><strong>Filtered Rows:</strong> {{getGridRowsCount}} </span>
        <button mat-button color="accent" (click)="clearGridFilters()">Clear All Filters</button>
      </div>
    </div>

    <button matTooltip="Show/Hide Columns" class="grid-bar-icon ml-10" mat-icon-button color="accent"
      (click)="openColumnDialog()">
      <i class="material-icons">
        ballot
      </i>
    </button>

    <button mat-flat-button color="accent" class="ml-10" (click)="addMapping()">Add Mapping</button>

  </div>

  <div class="all-show-grid">

    <ag-grid-angular #mappedSKUsGrid id="outlier-mapped-sku-data-grid" class="ag-theme-material unmapped-skus-grid"

      [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      [rowData]="gridData"
      [rowSelection]="rowSelection"
      [animateRows]="true"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize"
      (selectionChanged)="rowSelectionChanged($event)"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

</div>


