<div class="mt-3">
  <div class="row m-0 pb-4 mt-3">
    <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
    <ng-container *ngIf="!undefinedImagePath">
      <h4 class="mx-2 w-100"><strong>What is the ranking of best selling accessories in the market vs. the
          partner?</strong></h4>
      <div class="col-6 empower-chart-section">
        <p class="font-18">Partner: | {{ empowerModernLifeService.getDashboardPartner() }}</p>
        <h4>Partner View</h4>
        <table class="comparison-tbl table">
          <thead>
            <tr>
              <th>Product Family</th>
              <th>Total Units - Partner</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of partnerUnitList; let i = index">
              <td>{{ item['dIM_PRODUCT_O2[PRODUCT_FAMILY]'] }}</td>
              <td>
                <div class="progress">
                  <div class="progress-bar" role="progressbar"
                    [ngStyle]="{ 'width.%': (item['[STLicsQty]']/maxPartnerUnitValue)*100}" aria-valuemin="0"
                    aria-valuemax="100">
                    {{item['[STLicsQty]'] | number }}</div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td>{{totalPartnerUnitList | number}}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="col-6 empower-chart-section">
        <p class="font-18">Market: | {{ empowerModernLifeService.getDashboardLocation() }}</p>
        <h4>Market View</h4>
        <table class="comparison-tbl table">
          <thead>
            <tr>
              <th>Product Family</th>
              <th>Total Units - Market</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of marketUnitList; let i = index">
              <td>{{ item['dIM_PRODUCT_O2[PRODUCT_FAMILY]'] }}</td>
              <td>
                <div class="progress">
                  <div class="progress-bar" role="progressbar"
                    [ngStyle]="{ 'width.%': (item['[STLicsQty___Comp]']/maxPartnerUnitValue)*100}" aria-valuemin="0"
                    aria-valuemax="100">
                    {{item['[STLicsQty___Comp]'] | number }}</div>
                </div>
              </td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td>{{totalMarketUnitList | number}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
        <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
      <app-image-upload-viewer [imageUrl]="undefinedImagePath"
          orientation="square" (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12">
      <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
    </div>
    <div class="col-12">
      <app-report-page-footer></app-report-page-footer>
    </div>
  </div>
</div>