import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {  ColumnApi, ColumnState, GridApi } from 'ag-grid-enterprise';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import {
  childGridColDefs,
  paginationPageSize,
  cacheBlockSize,
  rowSelection,
  defaultColDef
} from '../ag-grid/gridOptions';
import { ChildItemDemoEditRenderer } from '../ag-grid/item-edit-renderer.component';
import { DemoItemDialogComponent } from '../../dialogs/item-dialog/item-dialog.component';
import { ChildItemActionCellRenderer } from '../ag-grid/child-item-action-cell-renderer.component';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { AGColumnDialogComponent } from 'src/common/ag-column-dialog/ag-column-dialog.component';

import { DemoService } from 'src/services/demo-services/demo-service';
import { downloadFromLink, gridDataExportParams } from '../../util/util';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-demo-master-child-item-list',
  templateUrl: './master-child-item-list.component.html',
  styleUrls: ['./master-child-item-list.component.css']
})
export class DemoMasterChildItemListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectedMasterRow?: any;
  @Input() isMasterPage?: boolean = false;
  @Input() isRetailerTeam?: any;


  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  rowSelection = rowSelection;

  public gridColumnDefs = childGridColDefs();
  public defaultColDef = defaultColDef;
  private gridApi: GridApi;
  public colApi: ColumnApi;
  public gridOptions: any;
  public gridColumnState: ColumnState[] = [];
  gridData: any = [];
  frameworkComponents = {
    childItemEditRenderer: ChildItemDemoEditRenderer,
    childItemActionCellRenderer: ChildItemActionCellRenderer
  }

  public actionSubscription: Subscription;

  userTeam: any = '';
  filterEvent: any = {};

  screenPreference: any;

  constructor(
    public dialog: MatDialog,
    private demoService: DemoService,
    public toastr: NgxToasterService,
    public spinner: NgxSpinnerService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) { }

  ngOnInit() {
    this.actionSubscription = this.demoService.getChildGridActionSubject().subscribe(res => {
      if (res) {
        this.performGridAction(res);
      }
    });
    this.getFormContorlsData();
    this.getChildItems();
  }

  ngOnChanges() {
  }

  performGridAction(actionObject) {
    switch (actionObject.action) {
      case 'edit': {
        this.openItemDialog(true, actionObject.actionData);
        break;
      }
      case 'delete': {
        this.openDeleteDialog(actionObject.actionData);
        break;
      }
    }
  }

  getFormContorlsData() {
  }

  getChildItems(filterBody={}) {
    this.demoService.GetChildItems(filterBody).subscribe(response => {
      this.gridData= response;
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

  onExportGridData() {
    const csvExportParams = gridDataExportParams({
      fileName: 'ItemsData',
      colDefs: this.gridColumnDefs,
      isExportTemplate: true,
      allColumns: true
    });
    this.gridApi.exportDataAsCsv(csvExportParams);
  }

  exportGridTemplate() {
    this.spinner.show();
    this.demoService.GetItemsImportTemplate().subscribe((res: any) => {
      downloadFromLink(res, 'ChildItemsTemplate');
      this.spinner.hide();
    });
  }

  importData() {
    (document.getElementById('upload-child-data') as any).value = '';
    document.getElementById('upload-child-data').click();
  }

  validateUploadedCSV(fileInput: any) {
    const reader = new FileReader();
    if (fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const childItemImportDataFile = reader.result as any;
        /*
          Files Validations Will go here
        */
        this.uploadImportedCsvFile(childItemImportDataFile);
      }
    }
  }

  uploadImportedCsvFile(childItemImportDataFile) {
    // this.childItemService.ImportChildDataTemplate({
    //   fileBase64: childItemImportDataFile
    // }).subscribe(
    //   (res: any) => {
    //       this.applyFilters(this.filterEvent);
    //     this.toastr.success('Success', 'Child Items Imported Successfully.');
    //   },
    //   (err: any) => {
    //     this.toastr.error('Error', 'Child Item Import Failed.');
    //   });
  }


  openItemDialog(isEdit = false, data = {}): void {
    const dialogRef = this.dialog.open(DemoItemDialogComponent, {
      data: {
        isEdit: isEdit,
        isMasterPage: this.isMasterPage,
        formData: {
          ...data
        },
        selectedMasterRow: this.selectedMasterRow
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  openColumnDialog() {
    this.dialog.open(AGColumnDialogComponent, {
      data: {
        colApi: this.colApi,
        skipHeaders: ['select', 'cellAction']
      },
      width: '700px'
    });
  }

  openDeleteDialog(actionData) {
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: actionData.itemDescription }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteChildItem(actionData);
      }
    });
  }

  deleteChildItem(actionData) {
  }

  applyFilters(event) {
    this.filterEvent = event;
    this.getChildItems(this.filterEvent);
  }

  ngOnDestroy() {
    this.actionSubscription.unsubscribe();
  }

}
