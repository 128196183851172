<h3 mat-dialog-value>{{!isEdit ? 'Create' : 'Update'}} Insights</h3>
<div mat-dialog-content>
  <form [formGroup]="fg">
    <div class="row">
      <div class="col-md-3 form-group">
        <mat-form-field class="example-full-width">
          <input (click)="picker1.open()" formControlName="dateGenerated" matInput [matDatepicker]="picker1"
            placeholder="Date Generated">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error *ngIf="submitted && fg.controls['dateGenerated'].hasError('required')">
            *Required Field
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <mat-form-field class="example-full-width">
          <input (focus)="picker2.open()" and (click)="picker2.open()" (dateChange)="setExpiredFlag($event)"
            formControlName="expiryDate" matInput [matDatepicker]="picker2" placeholder="Expiry Date">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error *ngIf="submitted && fg.controls['expiryDate'].hasError('required')">
            *Required Field
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <mat-form-field>
          <input matInput formControlName="expiryCondition" placeholder="Expiry Condition">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-slide-toggle [style.padding]="'25px'" formControlName="isExpired">Is Expired
        </mat-slide-toggle>
      </div>
      <div class="col-md-3 form-group">
        <mat-form-field>
          <mat-select placeholder="Select Country" formControlName="appInsightsCountryId">
            <mat-option *ngFor="let res of countryList; let i = index" [value]="res.commonCodeValueId">
              {{res.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <mat-form-field>
          <mat-select placeholder="Select Retailer" formControlName="appInsightsRetailerId">
            <mat-option *ngFor="let res of retailerList; let i = index" [value]="res.commonCodeValueId">
              {{res.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <mat-form-field>
          <mat-select placeholder="Select Category" formControlName="appInsightsCategoryId">
            <mat-option *ngFor="let res of categoryList; let i = index" [value]="res.commonCodeValueId">
              {{res.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <mat-form-field>
          <mat-select placeholder="Select Division" formControlName="appInsightsDivisionId">
            <mat-option *ngFor="let res of divisionList; let i = index" [value]="res.commonCodeValueId">
              {{res.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <ngx-dropzone [style.height]="'60px'" (change)="onSelect($event, 'leftImage')">
          <ngx-dropzone-label>Left Image!</ngx-dropzone-label>
          <img *ngIf="fg.controls['leftImage'].value" height="50" width="50" [src]="fg.controls['leftImage'].value">
        </ngx-dropzone>
      </div>
      <div class="col-md-3 form-group">
        <ngx-dropzone [style.height]="'60px'" (change)="onSelect($event, 'rightImage')">
          <ngx-dropzone-label>Right Image!</ngx-dropzone-label>
          <img *ngIf="fg.controls['rightImage'].value" height="50" width="50" [src]="fg.controls['rightImage'].value">
        </ngx-dropzone>
      </div>
      <div class="col-md-3 form-group">
        <textarea class="form-control" formControlName="description" placeholder="Description"></textarea>
      </div>
      <div class="col-md-3 form-group">
        <textarea class="form-control" formControlName="suggestedAction" placeholder="Suggested Action"></textarea>
      </div>
      <div class="col-md-3 form-group">
        <mat-form-field>
          <input matInput formControlName="value" placeholder="Value$">
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <mat-slide-toggle [style.padding]="'25px'" formControlName="isPublish">Publish
        </mat-slide-toggle>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 form-group">
        <mat-form-field>
          <mat-select placeholder="Select Metric" formControlName="metrics">
            <mat-option *ngFor="let res of metricDropdownList; let i = index" [value]="res">
              {{res.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <mat-form-field>
          <input matInput formControlName="metricValue" placeholder="Value">
        </mat-form-field>
      </div>
      <div class="col-md-3 form-group">
        <button mat-raised-button class="btn btn-primary m-left" color="primary" (click)="addMetric()">Add
          Metric</button>
      </div>
    </div>
    <div class="all-show-grid">
      <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
        [rowData]="selectedMetricList" (gridReady)="onGridReady($event)"  #grid>
      </ag-grid-angular>
    </div>
    <div class="action-btns text-right">
      <button mat-raised-button class="btn btn-secondary m-left" (click)="dialogRef.close()">Cancel</button>
      <button mat-raised-button class="btn btn-primary m-left" (click)="onSubmit(false)">Save</button>
      <button mat-raised-button class="btn btn-primary m-left" (click)="onSubmit(true)">Save & New</button>
    </div>
  </form>
</div>
