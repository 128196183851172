import { AfterViewInit, Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { generateUniqueId } from '../helper';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { LoggingService } from 'src/services/logging.service';
import { EMPOWER_MODULE, EMPOWER_REPORT_PAGE, EMPOWER_REPORT_PAGE_MODERN_LIFE } from 'src/common/logging-keys';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EMPOWER_REPORT_MODERN_LIFE } from 'src/common/keys';

@Component({
  selector: 'empower-modern-pitch-factory',
  templateUrl: './modern-pitch-factory.component.html',
  styleUrls: ['./modern-pitch-factory.component.scss']
})
export class EmpowerModernPitchFactoryComponent implements AfterViewInit {

  _unsubscribeAll = new Subject();
  pagesList = [];
  reportPageMenus: any = [];
  selectedPage = null;
  uniqueId = generateUniqueId();
  landingPageLoaded = false;
  reportId = 1;
  sessionId = uuidv4();

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: NgxToasterService,
    private empowerExportService: EmpowerExportService,
    private loggingService: LoggingService,
    private empowerModernLifeService: EmpowerModernLifeService,) {
  }

  ngAfterViewInit(): void {
    this.empowerModernLifeService.modernSideNavList
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.pagesList = res;
        this.createReportPageMenus(this.pagesList);
      });
    this.fetchSideNavData();
  }

  fetchSideNavData() {
    this.pagesList = this.empowerModernLifeService.modernSideNavList.value;
    if (this.pagesList.length) {
      this.checkForLandingPage();
      this.createReportPageMenus(this.pagesList);
    } else {
      this.spinner.show();
      this.empowerModernLifeService.GetSideNavMenu(this.reportId)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.pagesList = res;
          this.empowerModernLifeService.modernSetSideNavList(this.pagesList);
          this.checkForLandingPage();
          this.createReportPageMenus(this.pagesList);
          this.spinner.hide();
        },
          err => {
            this.spinner.hide();
          });
    }
  }

  checkForLandingPage() {
    if (this.landingPageLoaded) return;

    this.pagesList.forEach(page => {
      if (page.landingPage) {
        this.selectPage(page);
        this.landingPageLoaded = true;
      }
    });
  }

  createReportPageMenus(pageList) {
    const menuList: any = [];
    const groupedByCategory = _.groupBy(_.filter(pageList, a => a.active), a => a.pageCategory);
    _.keys(groupedByCategory).forEach(key => {
      if (groupedByCategory[key].length === 1 && groupedByCategory[key][0].landingPage) {
        menuList.push(groupedByCategory[key][0]);
      } else {
        menuList.push({
          pageCategory: key,
          children: groupedByCategory[key]
        });
      }

    });
    this.reportPageMenus = menuList;
  }

  selectPage(page) {
    const isDashboardFiltersValid = this.empowerModernLifeService.isDashboardStateValid();
    if (isDashboardFiltersValid || page.landingPage) {
      this.selectedPage = page;
      const elementRef = document.getElementById(this.uniqueId);
      this.empowerExportService.removeChildren(elementRef);
      const newComponent = document.createElement(page.pageSelector);
      page.data = {
        sessionId: this.sessionId,
        reportId: page.reportKey,
        reportTitle: page.reportTitle,
        pageId: page.pageId,
        pageType: page.pageType,
        reportPageVisuals: page.reportPageVisuals,
        pageTitle: page.pageTitle,
        navTitle: page.navTitle,
        pageCategory: page.pageCategory,
        pageOrder: page.pageOrder,
        pageSelector: page.pageSelector,
        serviceName: EMPOWER_REPORT_MODERN_LIFE
      };
      newComponent.setAttribute('data', JSON.stringify(page.data));
      elementRef.append(newComponent);
      this.loggingService.logEvent(EMPOWER_MODULE, {
        sessionId: this.sessionId,
        eventName: EMPOWER_REPORT_PAGE,
        eventType: EMPOWER_REPORT_PAGE_MODERN_LIFE,
        reportId: page.reportKey,
        reportTitle: page.reportTitle,
        pageId: page.pageId,
        pageType: page.pageType,
        pageTitle: page.pageTitle,
        navTitle: page.navTitle,
        pageCategory: page.pageCategory,
        pageOrder: page.pageOrder,
        pageSelector: page.pageSelector,
        ...this.empowerModernLifeService.GetDashboardFilterPayloadForLogging()
      });
      // setTimeout(() => {
      //   newComponent.scrollIntoView({ behavior: 'smooth' });
      // }, 1000);
    } else {
      this.toastr.error('Error', 'Please selector dashboard filters correctly.');
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
