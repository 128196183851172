<div class="mt-3">
  <div class="m-0 pb-4 mt-3">
    <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
    <ng-container *ngIf="!undefinedImagePath">
      <h4 class="m-2"><strong>How to close the gap by optimizing your mix?</strong></h4>
      <p class="m-2 font-18">Partner: | {{ empowerModernLifeService.getDashboardPartner() }}</p>
      <div class="row m-0 p-0">
        <div class="col-4 empower-chart-section">
          <h4>M365 Mix %</h4>
          <fusion-percentage-horizental-bar-chart [xaxisCatgoryList]="xaxisCategroyM365MixFirst"
            [dataSeries]="dataSeriesM365MixFirst" yAxisName="STLicsQty" height="250">
          </fusion-percentage-horizental-bar-chart>
        </div>
        <div class="col-4 empower-chart-section">
          <h4>Family Mix %</h4>
          <fusion-percentage-horizental-bar-chart [xaxisCatgoryList]="xaxisCategroyFamilyMixFirst"
            [dataSeries]="dataSeriesFamilyMixFirst" yAxisName="STLicsQty" height="250">
          </fusion-percentage-horizental-bar-chart>
        </div>
        <div class="col-4 empower-chart-section">
          <h4>Digital Mix %</h4>
          <fusion-percentage-horizental-bar-chart [xaxisCatgoryList]="xaxisCategroyDigitalMixFirst"
            [dataSeries]="dataSeriesDigitalMixFirst" yAxisName="STLicsQty" height="250">
          </fusion-percentage-horizental-bar-chart>
        </div>
      </div>

      <p class="m-2 font-18">Market: | {{ empowerModernLifeService.getDashboardLocation() }}</p>
      <div class="row m-0 p-0">
        <div class="col-4 empower-chart-section">
          <h4>M365 Mix %</h4>
          <fusion-percentage-horizental-bar-chart [xaxisCatgoryList]="xaxisCategroyM365MixSecond"
            [dataSeries]="dataSeriesM365MixSecond" yAxisName="STLicsQty" height="250">
          </fusion-percentage-horizental-bar-chart>
        </div>
        <div class="col-4 empower-chart-section">
          <h4>Family Mix %</h4>
          <fusion-percentage-horizental-bar-chart [xaxisCatgoryList]="xaxisCategroyFamilyMixSecond"
            [dataSeries]="dataSeriesFamilyMixSecond" yAxisName="STLicsQty" height="250">
          </fusion-percentage-horizental-bar-chart>
        </div>
        <div class="col-4 empower-chart-section">
          <h4>Digital Mix %</h4>
          <fusion-percentage-horizental-bar-chart [xaxisCatgoryList]="xaxisCategroyDigitalMixSecond"
            [dataSeries]="dataSeriesDigitalMixSecond" yAxisName="STLicsQty" height="250">
          </fusion-percentage-horizental-bar-chart>
        </div>
      </div>
    </ng-container>
    <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
      <app-image-upload-viewer [imageUrl]="undefinedImagePath" orientation="square"
        (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12">
      <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
    </div>
    <div class="col-12">
      <app-report-page-footer></app-report-page-footer>
    </div>
  </div>
</div>