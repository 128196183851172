import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manage-table-columns',
  templateUrl: './manage-table-columns.component.html',
  styleUrls: ['./manage-table-columns.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageTableColumnsComponent implements OnInit {

  metrices = []; // holds metric variables
  matricesArray = []; // for returning output
  isSettingsTab = false; // for metric variables in SETTINGS TAB

  constructor(
    public dialogRef: MatDialogRef<ManageTableColumnsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService: ToastrService) {
      this.data.isSettingsTab && (this.isSettingsTab = this.data.isSettingsTab);
      this.metrices = this.data.metrices;
    }

  ngOnInit(): void {
    this.metrices.forEach(element => {
      this.matricesArray.push(
        {
          id: element.id,
          name: element.name,
          checked: this.data.checkedValues.indexOf(element.id) > -1
        }
        );
    });
  }
  
  onSaveClick = () => {
    const newGridCols = [];
    this.matricesArray.forEach(e => {
      if (e.checked) newGridCols.push(e.id);
    });
    if (newGridCols.length == 0)
      this.toastrService.warning('Please select at least 1 Measure');
    else
      this.dialogRef.close(newGridCols);
  }
  
  onNoClick = () => {
    this.dialogRef.close();
  }

  clearAll() {
    this.matricesArray.forEach(e => {
      e.checked = false;
    });
  }

  selectAll() {
    this.matricesArray.forEach(e => {
      e.checked = true;
    });
  }
}
