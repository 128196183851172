import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  AfterViewInit,
  Injector,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { startWith, map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { REPORT_LIST } from '../../../../common/LookupkeyValue';
import { USER_INFO, USER_ID, BOT_TOKEN_INFO, BOT_CONVERSATION_INFO, SELECTED_LANGUAGE, USER_ROLES } from '../../../../common/keys';
import { NgxCaptureService } from 'ngx-capture';
import { DOWN_ARROW } from '@angular/cdk/keycodes';
import * as _ from 'lodash';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { LocalstorageService } from 'src/services/localstorage.service';
import { ConfigurationService } from 'src/services/configuration.service';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { BotService } from 'src/services/bot.service';
import { UsersService } from 'src/services/User-services/user-services';
import { environment } from 'src/environments/environment';
import { LoggingService } from 'src/services/logging.service';
import { PMSPermissionAccessService } from 'src/services/pms-permission-access.service';
import { AuthenticationService } from 'src/services/Authentication-Services/authentication-services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  public dictionary = [
    'Channel',
    'channel',
    'Calender',
    'calender',
    'Distributor',
    'distributor',
    'source',
    'Source',
    'password',
    'Password',
    'roles',
    'Roles',
  ];
  public searchString = '';
  public resultViewList = [];
  status = false;
  public shadesEl = [];
  public resultViewListCV = [];
  @ViewChild('channel') channel: TemplateRef<any>;
  @ViewChild('source') source: TemplateRef<any>;
  @ViewChild('distributor') distributor: TemplateRef<any>;
  @ViewChild('category') category: TemplateRef<any>;

  @ViewChild('roles') roles: TemplateRef<any>;
  @ViewChild('resetPassword') resetPassword: TemplateRef<any>;
  @ViewChild('simulation', { static: true }) simulation: TemplateRef<any>;
  public pageList = [];
  public orginalPageList = [];

  searchCtrl = new FormControl();
  filteredPages: Observable<any[]>;
  public reportList = REPORT_LIST;
  public selectedReport = null;
  public componentSelectorList = [
    {
      key: 'channel',
      selector: '<app-channel></app-channel>',
    },
  ];
  page = {
    menuId: '1',
    name: 'Ask Algo',
    displayName: 'Ask Algo',
    selector: '',
    component: null,
  };
  addClass: any;
  toggleClass: any;
  activeClass: any;
  id: any;
  addEventListener: any;
  public simulationSubcription: Subscription;
  public favoriteSubcription: Subscription;
  public favoriteListSubcription: Subscription;
  public favouriteList = [];
  public filterList = [];
  public leftMenuList = [];
  public selectedUsers = [];
  public captureScreen = false;
  public menuSubscription: Subscription;
  user;
  cred;
  @ViewChild('mainContent', { static: true }) screen: any;
  public loading = false;
  selectedLanguage = 'de';
  userList = [];
  askedQueryList = [];
  historyIndex = 0;
  selectedMenu;
  public loadedComponent;
  ROLE_BASE_REPORTS = ['Best Buy', 'BBY', 'SDS', 'Sony', 'Disney', 'Lionsgate', 'Paramount', 'Mill Creek'];

  expandedItems = [];

  userRoles = this.storage.get(USER_ROLES);

  constructor(
    public location: Location,
    public spinner: NgxSpinnerService,
    public toastr: NgxToasterService,
    public dialog: MatDialog,
    public storage: LocalstorageService,
    public configurationService: ConfigurationService,
    public dialogAddRef: MatDialogRef<any>,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public captureService: NgxCaptureService,
    public botService: BotService,
    public userService: UsersService,
    private injector: Injector,
    private loggingService: LoggingService,
    private authService: AuthenticationService
  ) {
    this.selectedLanguage = this.storage.get(SELECTED_LANGUAGE) || 'en';
    this.configurationService.languageSubject$.subscribe((res) => {
      if (res) {
        this.selectedLanguage = res;
      }
    });
  }

  async ngOnInit() {
    let claimKeys =Object.keys(this.authService.getTokenData());
    this.authService.setClaimBasedAccess(claimKeys);
    this.cred = this.storage.get('USERCRED');
    this.user = this.storage.get(USER_INFO);
    // this.addGreetingMessage();
    this.menuGetlist();
    this.simulationSubcription = this.configurationService.simulationSubject$.subscribe(res => {
      if (res) {
        this.selectedReport = res;
        setTimeout(() => {
          if (this.simulation) {
          }
          this.submitSearch('simulation');
        }, 100);
      }
    });
    this.favoriteListSubcription = this.configurationService.favouriteRemoveSubject$.subscribe(res => {
      if (res) {
        this.getFavouriteList();
      }
    });
    this.filteredPages = this.searchCtrl.valueChanges
      .pipe(
        startWith(''),
        map((page: any) => {
          if (page && page.length > 1) {
            return page ? this._filterPage(page) : this.pageList.slice();
          }
        }));
    this.favoriteSubcription =
      this.configurationService.openFavouriteSubject$.subscribe((res) => {
        if (res) {
          res.menuId = res.menuId ? res.menuId : res.favouriteId;
          setTimeout(() => {
            this.selectedReport = this.reportList.find(
              (report) => report.value === res
            );
            if (this.selectedReport) {
              this.submitSearch('simulation');
            } else {
              this.submitSearch(res);
            }
          }, 100);
        }
      });
    this.favoriteListSubcription =
      this.configurationService.favouriteRemoveSubject$.subscribe((res) => {
        if (res) {
          this.getFavouriteList();
        }
      });
    this.filteredPages = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((page: any) => {
        if (page && page.length > 1) {
          return page ? this._filterPage(page) : this.pageList.slice();
        }
      })
    );
    this.getFavouriteList();
    this.configurationService.takeScreenshot$.subscribe((res) =>
      res ? this.getSnapshot(res) : null
    );
    this.menuSubscription =
      this.configurationService.menuRendererSubject$.subscribe((res) => {
        if (res) {
          this.getModule(res);
          setTimeout(() => {
            this.addElementToDashboard(res);
          }, 1);
        }
      });
    this.configurationService.removeMenu$.subscribe((res) => {
      if (res) {
        this.resultViewList = this.resultViewList.filter(
          (menu) => menu.guid !== res.guid
        );
      }
    });

    this.configurationService.savedPlannerOpened$.subscribe((res) => {
      if (res) {
        let menu = this.resultViewList.find((menu) => menu.guid === res.guid);
        if (menu) {
          menu.displayName = res.version.name;
        }
      }
    });
    this.configurationService.savedItemPlannerOpened.subscribe((res) => {
      let menu = this.resultViewList.find(
        (menu) => menu.selector === 'app-item-planner'
      );
      if (menu) {
        menu.displayName = res.name;
      }
    });
    this.getUsers();
  }

  getUsers = () => {
    this.userService.GetAllUsers({ userTypeId: 1 }).subscribe((res) => {
      this.userList = (res as any).data;
    });
  };
  public addGreetingMessage = () => {
    const para = document.createElement('P');
    const userInfo = this.storage.get(USER_INFO);
    const day = new Date();
    let greeting = '';
    const hr = day.getHours();
    if (hr >= 0 && hr < 12) {
      greeting = 'Good Morning';
    } else if (hr === 12) {
      greeting = 'Good Noon';
    } else if (hr >= 12 && hr <= 17) {
      greeting = 'Good Afternoon';
    } else {
      greeting = 'Good Evening';
    }
    // Create a <p> element
    const msg = `<p>Welcome <b>${userInfo.firstName}</b>. How can I help you today?<p>`;
    para.innerHTML = msg;
    this.resultViewList.push({
      element: para,
      timestamp: new Date(),
      menuId: Math.random(),
    });
  };
  public getFavouriteList = async () => {
    const userId = this.storage.get(USER_INFO).userId || 1;
    this.favouriteList = await this.configurationService
      .favouriteGetlist({ userId })
      .toPromise();
  };
  ngAfterViewInit() { }
  addElementToDashboard = (page: any) => {
    if (page.selector === 'app-fb-forecast') {
      page.displayName = 'Facebook Forecast (save file to title)';
    }
    if (page.selector === 'app-master-item-list' || page.selector === 'app-item-list') {
      page.displayName = 'Item Manager'
    }
    if (page.selector === 'app-global-demand-planner') {
      page.displayName = 'Global Demand Planning (save file to title)';
    }
    if (page.selector === 'app-collabrorative-demand-planner') {
      page.displayName = 'Collaborative Planning (save file to title)';
    }
    if (page.selector === 'app-regional-demand-planner') {
      page.displayName = 'Regional Demand Planning (save file to title)';
    }
    if (page.selector === 'app-global-demand-planner-new') {
      page.displayName = 'Global Demand Planning New (save file to title)';
    }
    if (page.selector === 'app-regional-demand-planner-new') {
      page.displayName = 'Regional Demand Planning New (save file to title)';
    }
    if (page.selector === 'app-clustering-forecast') {
      page.displayName = 'Self-Service Clustering'
    }

    if (page.name !== 'Ask Algo') {
      const newElement = document.createElement(page.selector);
      page.element = newElement;
      const obj = { menuId: page.menuId,selector: page.selector,name:page.name,displayName:page.name,module: page.module }
      if (page.data) {
        page.data.guid = `${page.menuId}${this.resultViewList.length}`;
        page.guid = `${page.menuId}${this.resultViewList.length}`;
        newElement.setAttribute('data', JSON.stringify(page.data));
        newElement.setAttribute('pageobject', JSON.stringify(obj));
      }
      if(page.journeyId) {
        newElement.setAttribute('pageobject', JSON.stringify(page));
      }
      const model = {
        menuId: page.menuId,
        userId: this.storage.get(USER_ID),
        clientId: environment.ClientId,
      };
      const props = {
        menuId: page.menuId,
        menuName: page.name,
        screenId: page.screenId,
        screenName: page.orignalName

      };
      this.loggingService.logPageView(page, props);
      this.configurationService.AddUserInterface(model).toPromise();
      this.askedQueryList.push(page.name);
      this.resultViewList.push({ ...page });
      this.historyIndex = 0;
      this.searchCtrl.setValue('');
    } else {
      this.askBot();
    }
  };
  public appendChild = (menu, index) => {
    if (menu.name !== 'Ask Algo') {
      if (menu.menuId) {
        if (
          document.getElementById(`${menu.menuId}${index}`).children.length > 0
        ) {
        } else {
          document
            .getElementById(`${menu.menuId}${index}`)
            .appendChild(menu.element);
          if (menu.selector !== 'app-forcast') {
            this.scrollToTop(`${menu.menuId}${index}`);
          }
        }
      }
    }
  };
  ngOnDestroy() {
    this.configurationService.simulationSubject.next(null);
    this.configurationService.openFavouriteSubject.next(null);
    this.configurationService.favouriteListSubject.next(null);
    this.configurationService.menuRendererSubject.next(null);
    this.favoriteSubcription.unsubscribe();
    this.simulationSubcription.unsubscribe();
    this.favoriteListSubcription.unsubscribe();
  }
  public likeMenuItem = (menuItem: string, flag?: boolean) => {
    if (menuItem) {
      const model = {
        UserId: 1,
        MenuItemName: menuItem,
        Value: flag ? 1 : 0,
      };
      this.configurationService.likeDislikeAdd(model).subscribe((res) => {
        console.log('Liked ==> ', menuItem);
      });
    }
  };
  public addToFavourites = (menuItem: any) => {
    if (menuItem) {
      const model = {
        favouriteId: null,
        userId: 1,
        menuItemName: menuItem.name,
        displayName: menuItem.name,
        selector: menuItem.selector,
        active: 1,
        menuId: menuItem.menuId,
      };
      this.configurationService.favouriteAdd(model).subscribe((res) => {
        this.favouriteList.push(model);
        this.configurationService.favouriteListSubject.next(null);
        this.toastr.success(
          'Favorites',
          `${this.capitalizeFirstLetter(
            model.menuItemName
          )} added to favorites successfully.`
        );
      });
    }
  };
  ifInFavourite = (menu) => {
    return (
      menu &&
      this.favouriteList.find(
        (favourite) =>
          favourite.menuItemName === menu.key ||
          favourite.menuItemName === menu.description
      )
    );
  };
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
  public menuGetlist = () => {
    this.configurationService.getMenuList({ clientId: environment.ClientId }).subscribe(res => {
      this.pageList = res;
      this.orginalPageList = res;
      this.pageList = this.pageList.sort((a, b) => a.orderNumber - b.orderNumber);
      this.pageList = this.pageList.map(menu => ({ ...menu, id: menu.menuId, children: this.getChildren(menu, res) }));
      this.pageList = this.pageList.filter(menu => !menu.parentId);
      const list = [];
      this.pageList.forEach(child => {
        list.push(child, ...child.children);
      });
      this.pageList = list;
    });
  }

  public getChildren = (menu, list) => {
    const filterd = list.filter((row) => row.parentId === menu.menuId);
    if (filterd && filterd.length > 0) {
      return filterd;
    } else {
      return [];
    }
  };
  public deleteMenuItem = (menuItem: any, index) => {
    this.resultViewList = this.resultViewList.filter(
      (menu, i) => menu.guid !== menuItem.guid
    );
  };
  private _filterPage(value: string) {
    const filterValue: string = value.toLowerCase();
    const pageList = this.pageList.filter(page => {
      const list = filterValue.toLocaleLowerCase().split(' ');
      return (
        ((page.name.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) > -1)
          || (filterValue.toLocaleLowerCase().indexOf(page.name.toLocaleLowerCase()) > -1)
          || (list.find(str => page.name.toLowerCase().indexOf(str) > -1) ? true : false))
        && !page.skipSearch);
    });
    // pageList.push(this.page);
    return pageList;
  }
  public submitSearch = (value: any, botSummary: any = null) => {
    if (value) {
      value.displayName = value.displayName ? value.displayName : value.name;
      if (value.isPowerBiReport) {
        value.data = value.report;
        value.data.reportFilters = botSummary;
      }
      this.getModule(value);
      setTimeout(() => {
        this.addElementToDashboard(value);
      }, 1);
    }
  };
  public getModule = (selector) => {
    switch (selector.module) {
      case 'Auth':
        if (!this.configurationService.isAuthLoaded) {
          import('../../../auth/auth.module').then((m) => {
            this.loadedComponent = new m.AuthModule(
              this.injector,
              this.configurationService
            );
          });
        }
      case 'ReportingModule':
        if (!this.configurationService.isReportingLoaded) {
          import('../../../reporting/reporting.module').then((m) => {
            this.loadedComponent = new m.ReportingModule(
              this.injector,
              this.configurationService
            );
          });
        }
        break;
      case 'ItemManagerModule':
        {
          if (!this.configurationService.isIMLoaded) {
            import('../../../item-manager/item-manager.module').then((m) => {
              this.loadedComponent = new m.ItemManagerModule(this.injector, this.configurationService);
            });
          }
          break;
        }
        case 'MessagingModule':
          {
            if (!this.configurationService.isMessagingLoaded) {
              import('../../../messaging/messaging.module').then((m) => {
                this.loadedComponent = new m.MessagingModule(this.injector, this.configurationService);
              });
            }
            break;
          }
        case 'ForecastEnginModule': {
          if (!this.configurationService.isForecastManagerLoaded) {
            import('../../../forecast-engin/forecast-engin.module').then((m) => {
              this.loadedComponent = new m.ForecastEnginModule(
                this.injector,
                this.configurationService
              );
            });
          }
          break;
        }
        case 'DemoManagerModule': {
          if (!this.configurationService.isDemoModuleLoaded) {
            import('../../../demo-manager/demo-manager.module').then((m) => {
              this.loadedComponent = new m.DemoManagerModule(
                this.injector,
                this.configurationService
              );
            });
          }
          break;
        }
        case 'EmpowerModule': {
          if (!this.configurationService.isEmpowerModuleLoaded) {
            import('../../../empower-module/empower.module').then((m) => {
              this.loadedComponent = new m.EmpowerModule(
                this.injector,
                this.configurationService
              );
            });
          }
          break;
        }
      default:
        break;
    }
  };
  public scrollToTop = (id: string) => {
    setTimeout(() => {
      const component = document.getElementById(id.toLowerCase());
      if (component) {
        component.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
  };
  public getPath = (searchedComponetRoute: string) => {
    if (searchedComponetRoute) {
      const componentToLoad = this.componentSelectorList.find(
        (comp) => comp.key === searchedComponetRoute
      );
      return componentToLoad.selector;
    }
  };
  clickEvent() {
    this.status = !this.status;
    const shadesEl = document.querySelector('.chat-wrapper');
    if (shadesEl.classList.contains('bounce_in')) {
      shadesEl.classList.remove('bounce_in');
    } else {
      shadesEl.classList.add('bounce_in');
    }
  }
  getSnapshot = (screen) => {
    this.captureService.getImage(screen.nativeElement, true).pipe(
      tap(img => {
        const image = new Image();
        image.src = img;
        const w = window.open('');
        w.document.write(image.outerHTML);
      })
    ).subscribe();
  };
  saveImage = (string) => {
    console.log(string);
  };
  getTime = (iss) => {
    if (iss) {
      if (iss && iss.timestamp) {
        return new Date(iss.timestamp);
      } else {
        return new Date(iss.date);
      }
    }
  }

  filterPagesOnBotResponse(text) {
    const reportName = _.toLower((text.split(';'))[0]);
    const filteredPages = _.filter(this.orginalPageList, a => _.toLower(a.name) === reportName);
    return filteredPages;
  }

  askBot = () => {
    if (!this.searchCtrl.value) {
      return;
    }
    this.askedQueryList.push(this.searchCtrl.value);
    if (this.isBotQuery()) {
      let newString = this.searchCtrl.value;
      if (this.searchCtrl.value.startsWith('@')) {
        let message: string = this.searchCtrl.value;
        let stringArray = message.split(' ');
        stringArray[0] = '';
        newString = stringArray.join(' ');
      }
      this.loading = true;
      const model = {
        type: 'message',
        from: {
          // id: this.user.userId,
          id: (this.userRoles && this.userRoles.length) ? this.userRoles[0].roleName : this.user.userId,
          name: this.user.email
        },
        text: newString,
      };
      const searchedText = JSON.parse(JSON.stringify(newString));
      this.searchCtrl.setValue('');
      const para = document.createElement('P');
      para.innerText = JSON.parse(JSON.stringify(newString));
      // Create a <p> element
      const id = Math.random();
      this.resultViewList.push({
        name: 'Ask Algo',
        date: new Date(),
        searchText: searchedText,
        menuId: id,
      });
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
      this.botService.GetConversationActivity(model).subscribe(res => {
        if (res && res.id) {
          const botRes = res.id.split('|')[1];
          this.botService.GetConversationActivityResponse(botRes).subscribe(bot => {
            if (bot && bot.activities && bot.activities.length > 0) {
              const filteredPages = this.filterPagesOnBotResponse(bot.activities[0].summary);
              const el = document.createElement('P');
              // Create a <p> element
              el.innerText = JSON.parse(JSON.stringify(bot.activities[0].text));
              // el.appendChild(timestamp);
              this.resultViewList.push({ element: el, timestamp: bot.activities[0].timestamp, menuId: Math.random() });
              // this.searchCtrl.setValue('');
              if (filteredPages.length) {
                this.resultViewList.push({ isMenuLink: true, menues: filteredPages, menuId: Math.random() });
                setTimeout(() => {
                  this.submitSearch(_.first(filteredPages), bot.activities[0].summary);
                }, 100);
              }

              this.historyIndex = 0;
              this.loading = false;
            }
          }, error => {
            this.loading = false;
          });
        }
      }, error => {
        this.getBotToken();
        this.loading = false;
      });
    } else {
      this.sendMessageFromUser();
    }
  };
  public getBotToken = () => {
    this.botService.GenerateToken().subscribe((res) => {
      if (res && res.token) {
        this.storage.set(BOT_TOKEN_INFO, res.token);
        this.botService.GetConversations().subscribe((coversation) => {
          if (coversation) {
            this.storage.set(BOT_CONVERSATION_INFO, coversation.conversationId);
            this.storage.set(BOT_TOKEN_INFO, coversation.token);
            this.askBot();
          }
        });
      }
    });
  };
  public getName = (item) => {
    if (item) {
      switch (this.selectedLanguage) {
        case 'de':
          return item.germanName ? item.germanName : item.name;
        case 'zh':
          return item.chineseName ? item.chineseName : item.name;
        case 'en':
          return item.name;
        default:
          return item.name;
      }
    }
  };
  openFullScreenMode(id) {
    this.expandedItems[id] = false;
    setTimeout(() => {
      this.expandedItems[id] = true;
      const domGrid = document.getElementById(id).children[0].children[0] as any;
      if (domGrid) {
        domGrid.webkitRequestFullscreen();
      }
    }, 100);
  }

  openTelemetry = () => {
    const menu = {
      menuId: '45',
      name: 'Telemetry',
      selector: 'app-user-interface',
      displayName: 'Telemetry',
    };
    this.addElementToDashboard(menu);
  };
  public isBotQuery = () => {
    let message: string = this.searchCtrl.value;
    message = message.toLocaleLowerCase();
    if (message.startsWith('@') && message.startsWith('@algo')) {
      return true;
    } else if (!message.startsWith('@')) {
      return true;
    }
    return false;
  };
  public sendMessageFromUser = () => {
    this.loading = true;
    const searchedText = JSON.parse(JSON.stringify(this.searchCtrl.value));
    this.searchCtrl.setValue('');
    const para = document.createElement('P');
    para.innerText = JSON.parse(JSON.stringify(this.searchCtrl.value));
    // Create a <p> element
    const id = Math.random();
    this.resultViewList.push({
      name: 'Ask Algo',
      date: new Date(),
      searchText: searchedText,
      menuId: id,
    });
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
    setTimeout(() => {
      const el = document.createElement('P'); // Create a <p> element
      el.innerHTML = JSON.parse(
        JSON.stringify(
          `Your message will be sent to <b>${searchedText
            .split(' ')[0]
            .replace(
              '@',
              ''
            )}</b> soon. This functionality is under construction.`
        )
      );
      // el.appendChild(timestamp);
      this.resultViewList.push({
        element: el,
        timestamp: new Date(),
        menuId: Math.random(),
      });
      // this.searchCtrl.setValue('');
      this.loading = false;
    }, 100);
  };
  public openDialog = (menu, template) => {
    this.selectedMenu = menu;
    this.dialogRef = this.dialog.open(template, { width: '600px' });
    this.dialogRef.afterClosed().subscribe((res) => {
      this.selectedMenu = null;
    });
  };
  saveAndClose = () => {
    if (this.selectedMenu) {
      const model = {
        selector: this.selectedMenu.selector,
        description: '',
        userId: this.user.userId,
        email: this.user.email,
        menu: this.selectedMenu.name || this.selectedMenu.displayName,
        name: this.selectedMenu.name || this.selectedMenu.displayName,
        menuId: this.selectedMenu.menuId,
      };
      this.botService.SendBroadCast(model).subscribe((res) => {
        if (res) {
          this.dialogRef.close();
        }
      });
    }
  };
  share = () => {
    if (this.selectedMenu) {
      const model = {
        userId: this.user.userId,
        users: this.selectedUsers.map((user) => ({ email: user.email })),
      };
      this.botService.ShareMenu(model).subscribe((res) => {
        if (res) {
          this.dialogRef.close();
        }
      });
    }
  };
  public checkKey(e) {
    e = e || window.event;

    if (e.keyCode == 38) {
      if (this.historyIndex === 0) {
        this.historyIndex = this.askedQueryList.length;
      }
      this.searchCtrl.setValue(this.askedQueryList[this.historyIndex - 1]);
      this.historyIndex = this.historyIndex - 1;
    }
    if (e.keyCode == DOWN_ARROW) {
      this.searchCtrl.setValue(this.askedQueryList[this.historyIndex + 1]);
      this.historyIndex = this.historyIndex + 1;
    }
  }

  isExpanded(id) {
    if (this.expandedItems[id] == 'undefined' || this.expandedItems[id] == null)
      this.expandedItems[id] = true;
    return this.expandedItems[id];
  }
}
