import {
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { LocalstorageService } from '../services/localstorage.service';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Angular';
  isParentPortal = false;
  userActivity;
  userInactive: Subject<any> = new Subject();
  timeOutDuration;
  isUpdatedVersion = false;
  @ViewChild('confirmTemp') confirmTemp: TemplateRef<any>;
  constructor(
    private swUpdate: SwUpdate,
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    public storage: LocalstorageService
  ) {
    // swUpdate.available.subscribe((event) => {
    //   console.log('Event Triggered');
    //   this.dialog.open(this.confirmTemp, {
    //     hasBackdrop: true,
    //     disableClose: true,
    //   });
    // });
    this.checkForUpdates();
    if (swUpdate.isEnabled) {
      interval(60*60).subscribe(() =>
        swUpdate
          .checkForUpdate()
          .then(() => console.log('checking for updates'))
      );
    }
    this.timeOutDuration = environment.idleTime * 60 * 3600;
    this.setTimeout();
    this.userInactive.subscribe(() =>
      console.log('user has been inactive for 3s')
    );
  }

  public checkForUpdates(): void {
    this.swUpdate.available.subscribe((event) => {
      if (!this.isUpdatedVersion) {
        this.promptUser();
        this.isUpdatedVersion = true;
      }
    });
  }

  private promptUser(): void {
    console.log('Event Triggered');
    this.dialog.open(this.confirmTemp, {
      hasBackdrop: true,
      disableClose: true,
    });
    // console.log('updating to new version');
    // this.swUpdate.activateUpdate().then(() => document.location.reload());
  }
  checkVersion() {
    navigator.serviceWorker
      .register('/ngsw-worker.js')
      .then((reg: ServiceWorkerRegistration) => {
        // this.dialog.open(this.confirmTemp, {
        //   hasBackdrop: true,
        //   disableClose: true,
        // }).afterClosed().subscribe(res => {
        console.log('ngsw-worker:s', reg);
        reg.update();
        // });
      });
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params && params.isParentPortal) {
        this.isParentPortal = true;
      }
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      this.timeOutDuration
    );
  }

  @HostListener('window:mousemove') refreshUserStateWhenMouseMoves() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener('window:click') refreshStateWhenClicked() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  reload() {
    this.isUpdatedVersion = false;
    window.location.reload();
  }
}
