import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { LocalstorageService } from '../localstorage.service';
import { environment } from '../../environments/environment';
import { CLIENT_PREFERNCE_KEY, CLIENT_WEEK_START_DAY } from 'src/common/keys';


@Injectable()
export class ClientPreferenceService {

  constructor(private http: HttpClient, public storage: LocalstorageService) {
  }

  getClientPreferencebyId() {
    return this.http.get<any>(`${environment.AppAccount}/getclientpreference`)
      .pipe(map(clientPreference => {
        this.storage.set(CLIENT_PREFERNCE_KEY, JSON.stringify(clientPreference));
        return clientPreference;
      }));
  }

  getSamplePreferenceObject(preferenceKey) {
    return {
      preferenceKey: preferenceKey,
      preferenceState: null,
    };
  }

  getPreferenceByKey(preferenceKey) {
    const clientPreferences = JSON.parse(this.storage.get(CLIENT_PREFERNCE_KEY));
    const preference = _.find(clientPreferences, a => a.preferenceKey == preferenceKey);
    return preference ? preference : this.getSamplePreferenceObject(preferenceKey);
  }

  getClientWeekStartDay(): number {
    const clientPreferences = JSON.parse(this.storage.get(CLIENT_PREFERNCE_KEY));
    const preference = _.find(clientPreferences, a => a.preferenceKey == CLIENT_WEEK_START_DAY);
    return preference ? Number(preference.preferenceState) : 0;
  }

}

