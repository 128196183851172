<div class="row mx-0 mt-3">
    <div *ngIf="data?.export" class="col-12 mb-1 p-0" [innerHtml]="innerHtml"></div>
    <ng-container *ngIf="!undefinedImagePath">
      <h4 class="mx-2 w-100">
        <strong>How is the Partner Performing in 4 categories?</strong>
      </h4>
      <div class="inline-flex w-100">
        <p class="m-2 font-18">Partner: | {{ empowerGamingService.getDashboardPartner() }}</p>
        <p class="m-2 font-18">Market: | {{ empowerGamingService.getDashboardLocation() }}</p>
      </div>
      <div class="col-md-6 empower-chart-section">
        <h4>Sell Thru Monthly Volume-Games</h4>
        <fusion-ms-combination-chart height="250" [xaxisCatgoryList]="xaxisCatgoryList" [dataSeries]="dataSeriesFirst"
          xAxisName="Month">
        </fusion-ms-combination-chart>
      </div>
      <div class="col-md-6 empower-chart-section">
        <h4>Sell Thru Monthly Volume-Accessories</h4>
        <fusion-ms-combination-chart height="250" [xaxisCatgoryList]="xaxisCatgoryList" [dataSeries]="dataSeriesSecond"
          xAxisName="Month">
        </fusion-ms-combination-chart>
      </div>
      <div class="col-md-6 empower-chart-section mt-3">
        <h4>Sell Thru Monthly Volume-Console</h4>
        <fusion-ms-combination-chart height="250" [xaxisCatgoryList]="xaxisCatgoryList" [dataSeries]="dataSeriesThird"
          xAxisName="Month">
        </fusion-ms-combination-chart>
      </div>
      <div class="col-md-6 empower-chart-section mt-3">
        <h4>Sell Thru Monthly Volume-Subscriptions</h4>
        <fusion-ms-combination-chart height="250" [xaxisCatgoryList]="xaxisCatgoryList" [dataSeries]="dataSeriesForth"
          xAxisName="Month">
        </fusion-ms-combination-chart>
      </div>
    </ng-container>
    <div class="d-flex justify-content-center w-100" *ngIf="undefinedImagePath">
      <app-image-upload-viewer [imageUrl]="undefinedImagePath"
          orientation="square" (imageLoaded)="emitExportEvent($event)">
      </app-image-upload-viewer>
    </div>
    <div class="col-12">
      <app-page-comment [pageComment]="pageComment" (emitPageComment)="pageCommentChange($event)"></app-page-comment>
    </div>
    <div class="col-12">
      <app-report-page-footer></app-report-page-footer>
    </div>
</div>
