import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
;
import { NgxSpinnerService } from 'ngx-spinner';
import { USER_ID, USER_INFO } from 'src/common/keys';
import { AuthenticationService } from 'src/services/Authentication-Services/authentication-services';
import { LocalstorageService } from 'src/services/localstorage.service';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    public email = '';
    public messageToShow = '';
    public submitted = false;
    public successBool = false;
    public failureBool = false;



    public UserId;
    public code;

    constructor(private router: Router,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private userService: UsersService,
        private spinner: NgxSpinnerService,
        public storage: LocalstorageService,
        public toastr: NgxToasterService
        ) {
    }


    ngOnInit(): void {
        this.InitalizeForm();
        //this.getParamValues();
        // this.getUserEmailFromId(this.EmailId);

    }

    InitalizeForm() {
        this.resetPasswordForm = this.formBuilder.group({
           // UserId: [this.storage.get(USER_ID)],
            Email: [this.storage.get(USER_INFO).userName],
            Password: ['', [Validators.required]],
            ConfirmPassword: ['', Validators.required],
            NewPassword: ['', Validators.required],
            Code: ['']
        });
    }

    onSubmit() {
        this.submitted = true;
        this.resetPasswordForm.controls['NewPassword'].setValue(this.resetPasswordForm.controls['ConfirmPassword'].value);
        if (!this.resetPasswordForm.invalid ) {
            this.spinner.show();
            this.authenticationService.changePassword(this.resetPasswordForm.value)
                .subscribe(
                    data => {
                        this.spinner.hide();
                        this.toastr.success('', 'Your password has been reset successfully');
                       // this.router.navigate(['/Login']);

                    },
                    error => {
                        this.spinner.hide();
                        if (error.error) {
                            this.toastr.error('Error', error.error.toString());
                        }
                    }
                );

        } else {
            ///this.toastr.error('Error', this.getErrorMessagePassword());
        }
    }
    resetSubmit() {
        this.submitted = false;
        this.successBool = false;
        this.failureBool = false;
    }
    CheckPasswordAndConfirmPasswordMatch(): boolean{
        if(this.resetPasswordForm.controls['ConfirmPassword'].value !=
        this.resetPasswordForm.controls['Password'].value){
          this.toastr.error('Error', 'Password and Confirm password Mismatch');
            return false;
        } else {
            return true;
        }

    }
    getErrorMessagePassword() {

        if (this.resetPasswordForm.controls['Password'].hasError('required')) {
            return '*Password is Required';
        } else if (this.resetPasswordForm.controls['Password'].hasError('lengthAppropriate')) {
            return 'Minimum password length is 8';
        } else if (this.resetPasswordForm.controls['Password'].hasError('strong')) {
            return 'Password not Strong must contain one caps, one special and one number';
        } else if (this.resetPasswordForm.controls['ConfirmPassword'].hasError('required')) {
            return 'Confirm Password is Required';
        }else if (this.resetPasswordForm.controls['ConfirmPassword'].value != this.resetPasswordForm.controls['Password'].value) {
            return 'Password and Confirm Password Mismatch';
        }

    }

    GetErrorMessageConfirmPassword() {
        return 'Password and Confirm password Mismatch';
    }

    hideAlert() {

    }
}
