import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-ag-column-dialog',
  templateUrl: './ag-column-dialog.component.html',
  styleUrls: ['./ag-column-dialog.component.css']
})
export class AGColumnDialogComponent implements OnInit {

  colApi: any;
  skipHeaders: any = [];
  columnOrder: any = [];
  gridColumns: any = [];
  disabledColumns: any = [];
  columnCheckBox = {};
  constructor(public dialogRef: MatDialogRef<AGColumnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.colApi = this.data.colApi;
    this.skipHeaders = _.get(this.data, 'skipHeaders', []);
    this.columnOrder = _.get(this.data, 'columnOrder', []);
    this.disabledColumns = _.get(this.data, 'disabledColumns', []);
    this.gridColumns = this.colApi.columnController.gridColumns;
    this.orderColumns();
    this.filterGridColumns();
    this.initiateColumnBoxes();
  }

  orderColumns() {
    this.gridColumns = _.sortBy(this.gridColumns, (col) => this.columnOrder.indexOf(col.colId));
  }

  filterGridColumns() {
    this.gridColumns = _.filter(this.gridColumns, a => !this.skipHeaders.includes(a.colId));
  }

  initiateColumnBoxes() {
    for (let column of this.gridColumns) {
      this.columnCheckBox[column.colId] = column.visible;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    Object.keys(this.columnCheckBox).forEach((key: any) => {
      this.colApi.setColumnVisible(key, this.columnCheckBox[key]);
    });
    this.dialogRef.close();
  }

}
