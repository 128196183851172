import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'fusion-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class FusionDoughnutChartComponent implements OnInit, OnChanges {
  @Input() chartCaption?: string;
  @Input() xaxisCatgoryList: string[];
  @Input() dataSeries: any[];
  @Input() height?: string = '200px';
  @Input() showvalues?: string = '1';
  @Input() xAxisName?: string = '';
  @Input() yAxisName?: string = '';
  @Input() numberSuffix?: string = '';

  /*

    Data Series Data Sample Object 
    {
      label: "Label value",
      value: 123
    }
  */

  dataSource: any = {
    chart: {
      caption: "",
      theme: "fusion",
      labelDisplay: "rotate",
      slantLabel: "1",
      maxLabelHeight: "100",
      chartLeftMargin: "5",
      chartRightMargin: "5",
      chartBottomMargin: "0",
      canvasPadding: "0",
      showBorder: "1",
      borderColor: "#8D009D",
      borderThickness: "2",
      borderAlpha: "20",
      plotHighlightEffect: "fadeout",
      legendPosition: "top-left",
      showvalues: this.showvalues,
    },
    data: []
  };

  chartConfig: Object = {
    type: 'doughnut2d',
    dataFormat: 'json',
  };


  constructor() {

  }

  ngOnInit() {
    this.initChartDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initChartDataSource();
  }

  initChartDataSource() {
    this.dataSource.chart.caption = this.chartCaption;
    this.dataSource.chart.showvalues = this.showvalues;
    this.dataSource.chart.xAxisName = this.xAxisName;
    this.dataSource.chart.yAxisName = this.yAxisName;
    this.dataSource.chart.numberSuffix = this.numberSuffix;
    this.dataSource.data = this.dataSeries;
  }

}
