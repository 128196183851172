import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ItemManagerService, MasterProductService } from 'src/services/Item-Management-Services';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import { UsersService } from 'src/services/User-services/user-services';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { INVALID_TEAM, RETAILER_TEAM_CODE, VENDOR_TEAM_CODE } from 'src/common/keys';


@Component({
  selector: 'app-master-title-dialog',
  templateUrl: './master-title-dialog.component.html',
  styleUrls: ['./master-title-dialog.component.css']
})
export class MasterTitleDialogComponent implements OnInit {

  accountList = [];
  ratingList = [];

  userTeam: any = '';
  isRetailerTeam: boolean = false;
  disabledAccountSelection: boolean = false;

  productForm = new FormGroup({
    productTitleName: new FormControl('', Validators.required),
    preAwarenessDate: new FormControl(''),
    productTitleId: new FormControl(''),
    announcementDate: new FormControl(''),
    accountId: new FormControl('', Validators.required),
    ratingId: new FormControl(''),
    releaseDate: new FormControl('', Validators.required),
    theatricalReleaseDate: new FormControl(''),
  });

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MasterTitleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private masterItemService: MasterProductService,
    public toastr: NgxToasterService,
    private itemManagerService: ItemManagerService,
    private usersService: UsersService,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit() {
    this.getFormContorlsData();
    this.addCustomValidators();
    this.initiateValues();
    this.setRoleBaseAccess();
  }

  get relDate() {
    return this.productForm.get('releaseDate');
  }

  addCustomValidators() {
    setTimeout(() => {
      this.productForm.controls['releaseDate'].setValidators([Validators.required, this.releaseDateValidator]);
    }, 0);
  }

  releaseDateValidator(control: FormControl) {
    let preAwarenessDate = control.parent.controls['preAwarenessDate'].value;
    let announcementDate = control.parent.controls['announcementDate'].value;
    let theatricalReleaseDate = control.parent.controls['theatricalReleaseDate'].value;
    let releaseDate = control.value;
    if(releaseDate) {
      releaseDate = new Date(releaseDate);
      if (preAwarenessDate) {
        preAwarenessDate = new Date(preAwarenessDate);
        if (releaseDate < preAwarenessDate) {
          return {
            releaseDate: 'Release Date should be after the Pre-awareness Date.'
          };
        }
      }
      if (announcementDate) {
        announcementDate = new Date(announcementDate);
        if (releaseDate < announcementDate) {
          return {
            releaseDate: 'Release Date should be after the Announce Date.'
          };
        }
      }
      if (theatricalReleaseDate) {
        theatricalReleaseDate = new Date(theatricalReleaseDate);
        if (releaseDate < theatricalReleaseDate) {
          return {
            releaseDate: 'Release Date should be after the Theatrical Release Date.'
          };
        }
      }
    }
    return null;
  }

  checkValidity() {
    this.productForm.controls['releaseDate'].updateValueAndValidity();
  }

  getFormContorlsData() {
    this.itemManagerService.GetAllRatings().toPromise();
    this.itemManagerService.GetAllAccounts().toPromise();
  }

  initiateValues() {
    this.itemManagerService.getAccountListSubject().subscribe((accountList: any) => {
      this.accountList = accountList;
    });
    this.itemManagerService.getRatingListSubject().subscribe((ratingList: any) => {
      this.ratingList = ratingList;
    });
    if (this.data.isEdit) {
      this.productForm.patchValue(this.data.formData);
    }
  }

  setRoleBaseAccess() {
    this.userTeam = this.usersService.checkLoginUserTeam();
    switch (this.userTeam) {
      case VENDOR_TEAM_CODE: {
        const accountId = this.usersService.getTeamRoleAccount();
        this.productForm.get('accountId').setValue(accountId.toString());
        this.disabledAccountSelection = true;
        this.isRetailerTeam = false;
        break;
      }
      case RETAILER_TEAM_CODE: {
        this.isRetailerTeam = true;
        break;
      }
      case INVALID_TEAM: {
        console.log('Valid Team Not assigned');
        break;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openRemoveDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: this.productForm.value.productTitleName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeProduct();
      }
    });
  }

  removeProduct() {
    this.masterItemService.DeleteMasterItem(this.data.formData.masterItemId).subscribe((res: any) => {
      this.dialogRef.close({ isDeleted: true, record: { masterItemId: this.data.formData.masterItemId } });
      this.toastr.success('Success', 'Master Item Deleted Successfully.');
    }, err => {
      this.toastr.error('Error', 'Master Item Deletion Failed.');
      console.log(err);
    });
  }

  onSubmit() {
    const accountId = this.productForm.get('accountId').value;
    const ratingId = this.productForm.get('ratingId').value;
    if (this.data.isEdit) {
      const updatedMasterItem = {
        ...this.data.formData,
        ...this.productForm.value,
        accountName: accountId ? this.accountList.find(a => a.accountId === accountId).accountName : '',
        ratingName: ratingId ? this.ratingList.find(a => a.ratingId === ratingId).ratingName : ''
      };
      this.masterItemService.UpdateMasterItem(updatedMasterItem).subscribe((res: any) => {
        this.dialogRef.close({ isEdit: this.data.isEdit, record: updatedMasterItem });
        this.toastr.success('Success', 'Master Item Updated Successfully.');
      }, err => {
        this.toastr.error('Error', 'Master Item Update Failed.');
        console.log(err);
      });
    } else {
      this.masterItemService.CreateMasterItem(this.productForm.value).subscribe((res: any) => {
        const newMasterItem = {
          ...this.productForm.value,
          ...res,
          accountName: accountId ? this.accountList.find(a => a.accountId === accountId).accountName : '',
          ratingName: ratingId ? this.ratingList.find(a => a.ratingId === ratingId).ratingName : ''
        };
        this.dialogRef.close({ isEdit: this.data.isEdit, record: newMasterItem });
        this.toastr.success('Success', 'Master Item Added Successfully.');
      }, err => {
        this.toastr.error('Error', 'Master Item Insert Failed.');
        console.log(err);
      });
    }
  }

}
