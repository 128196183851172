<div class="container-fluid mb-2">

  <div class="row mx-2 mt-10 align-items-center justify-content-end">
      <mat-form-field appearance="outline" style="width: 20% !important; height: 60px;">
        <mat-label>Export As</mat-label>
        <mat-select placeholder="Export as" [(ngModel)]="exportType">
          <mat-option value="pdf">PDF</mat-option>
          <mat-option value="ppt">PPT</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="mx-2" mat-raised-button color="accent" (click)="exportEvent()">
        Export
      </button>
      <button class="ml-5" mat-raised-button color="accent" (click)="clearFilters()">Clear Filters</button>
      <button class="m-2" mat-raised-button color="accent" (click)="applyPageFilters()">Apply</button>
  </div>

  <div class="row m-0">
    <div class="col-md-4 mt-3">
      <header class="py-3 px-2 first-box">#1. Drill down to your country</header>
      <div class="content-box">
        <mat-icon class="cursor-pointer erase-filter" (click)="clearLocationTree()">remove_done</mat-icon>
        <ngx-treeview #locationTree [config]="config" [items]="locationTreeView"
          (selectedChange)="locationChange($event)">
        </ngx-treeview>
      </div>
    </div>

    <div class="col-md-8 mt-3">
      <header class="py-3 px-2 second-box">#2. Select your customer in both slicers</header>
      <div class="d-flex w-100">
        <div class="content-box w-50" style="margin-right: 2px;">
          <mat-icon class="cursor-pointer eraser-filter-2" (click)="clearPartnerTree()">remove_done</mat-icon>
          <ngx-treeview #partnerTree [config]="config" [items]="partnerTreeView"
            (selectedChange)="partnerChange($event)">
          </ngx-treeview>
        </div>
        <div class="content-box w-50" style="margin-left: 2px;">
          <mat-icon class="cursor-pointer erase-filter" (click)="clearPartnerGroupTree()">remove_done</mat-icon>
          <ngx-treeview #partnerGroupTree [config]="config" [items]="partnerGroupTreeView"
            (selectedChange)="partnerGroupChange($event)">
          </ngx-treeview>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <header class="py-3 px-2 third-box">#3. Select timeline</header>
      <div class="content-box">
        <mat-icon class="cursor-pointer erase-filter" (click)="clearCalendarTree()">remove_done</mat-icon>
        <ngx-treeview #calendarTree [config]="config" [items]="timeframeTreeView"
          (selectedChange)="timelineChange($event)">
        </ngx-treeview>
      </div>
    </div>

    <div class="col-md-4 mt-3">
      <header class="py-3 px-2 forth-box">#4. Select your category</header>
      <div class="content-box">
        <mat-icon class="cursor-pointer erase-filter" (click)="setPagesTree(false)">remove_done</mat-icon>
        <ngx-treeview #pagesTree [config]="config" [items]="sideNavMenuTreeView"
          (selectedChange)="sideNavMenuChange($event)">
        </ngx-treeview>
      </div>
    </div>
  </div>
</div>