import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxToasterService } from 'src/services/ngx-toaster.service';
import * as _ from 'lodash';
import moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EmpowerService } from 'src/services/empower-services/empower.service';
import {
  INSIGHT_QUERY_COUNT_CODE,
  INSIGHT_QUERY_DATA_CODE,
  INSIGHT_QUERY_DOMAIN_CODE,
  INSIGHT_QUERY_PARTNER_CODE,
  INSIGHT_QUERY_PAGE_CATEGORY_CODE,
  INSIGHT_QUERY_REPORT_CODE,
  INSIGHT_QUERY_ROLES_CODE,
  INSIGHT_QUERY_TEAM_CODE,
  INSIGHT_QUERY_USER_CODE,
  INSIGHT_QUERY_NAME_CODE,
  INSIGHT_QUERY_COUNTRY_CODE
} from 'src/common/keys';
import { GetDropdownSettingsObject, ReduceListByProps, ReduceListByPropsForGrid } from '../helper';
import { EMPOWER_REPORT_EXPORT, EMPOWER_REPORT_PAGE } from 'src/common/logging-keys';
import { cacheBlockSize, defaultColDef, generateGridColumns, paginationPageSize } from './ag-grid/gridOptions';
import {  ColumnApi, GridApi } from 'ag-grid-enterprise';

@Component({
  selector: 'empower-insights-dashboard',
  templateUrl: './insights-dashboard.component.html',
  styleUrls: ['./insights-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmpowerInsightsDashboardComponent implements OnInit {


  domainControlSettings: IDropdownSettings = GetDropdownSettingsObject('origin', 'origin');
  teamControlSettings: IDropdownSettings = GetDropdownSettingsObject('userTeam', 'userTeam');
  roleControlSettings: IDropdownSettings = GetDropdownSettingsObject('userRoles', 'userRoles');
  emailControlSettings: IDropdownSettings = GetDropdownSettingsObject('userName', 'userName');
  nameControlSettings: IDropdownSettings = GetDropdownSettingsObject('fullName', 'fullName');
  countryControlSettings: IDropdownSettings = GetDropdownSettingsObject('countryName', 'countryName');
  partnerControlSettings: IDropdownSettings = GetDropdownSettingsObject('partner', 'partner');
  reportGroupingControlSettings: IDropdownSettings = GetDropdownSettingsObject('pageCategory', 'pageCategory');
  reportControlSettings: IDropdownSettings = GetDropdownSettingsObject('reportTitle', 'reportTitle');

  dateRanges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  lastRefreshTimestamp: Date = new Date();

  domainList: any = [];
  teamList: any = [];
  roleList: any = [];
  emailList: any = [];
  nameList: any = [];
  countryList: any = [];
  partnerList: any = [];
  reportGroupingList: any = [];
  reportList: any = [];
  countsData: any = {};

  selectedDates: any = {
    startDate: moment().subtract(6, 'days'),
    endDate: moment()
  };
  selectedDomains: any[] = [];
  selectedTeams: any[] = [];
  selectedRoles: any[] = [];
  selectedEmails: any[] = [];
  selectedNames: any[] = [];
  selectedCountries: any[] = [];
  selectedPartners: any[] = [];
  selectedReportGroups: any[] = [];
  selectedReports: any[] = [];


  menuCountxAxis: any = [];
  teamCountxAxis: any = [];
  userCountxAxis: any = [];
  activityxAxisLables: any = [];

  menuCountSeries: any = [];
  teamCountSeries: any = [];
  userCountSeries: any = [];
  reportCountSeries: any = [];
  userActivitySeries: any = [];
  menuActivitySeries: any = [];
  reportExportTrendSeries: any = {};

  dashboardData: any = [];
  filterParams: any = {};

  // Ag-grid variables: START
  paginationPageSize = paginationPageSize;
  cacheBlockSize = cacheBlockSize;
  public gridColumnDefs: any[] = [];
  public defaultColDef = defaultColDef;
  public gridApi: GridApi;
  public colApi: ColumnApi;
  gridData = [];
  // Ag-grid variables: END

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: NgxToasterService,
    private empowerService: EmpowerService) {
      console.log('Insights Dashboard Constructor');
  }

  ngOnInit(): void {
    console.log('Insights Dashboard OnInit');
    this.lastRefreshTimestamp = new Date(this.lastRefreshTimestamp.getTime() - 5*60000);
    this.initLookupData();
  }

  initLookupData() {
    this.getDomainList();
    this.getTeamList();
    this.getUserList();
    this.getReportList();
    this.getCountsData();
    this.getDesignationList();
    this.getPartnersList();
    this.getPageCategoryList();
    this.getNamesList();
    this.getCountryList();
  }

  getNamesList() {
    const payload = {
      queryCode: INSIGHT_QUERY_NAME_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        this.nameList = res;
      }
    });
  }

  getCountryList() {
    const payload = {
      queryCode: INSIGHT_QUERY_COUNTRY_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        this.countryList = res;
      }
    });
  }

  getPageCategoryList() {
    const payload = {
      queryCode: INSIGHT_QUERY_PAGE_CATEGORY_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        this.reportGroupingList = res;
      }
    });
  }

  getDesignationList() {
    const payload = {
      queryCode: INSIGHT_QUERY_ROLES_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        let roles = '';
        res.forEach((element, index) => {
          roles = roles + element.userRoles;
          if (index != res.length-1) roles = roles + ',';
        });
        let rolesTempList = roles.split(',');
        rolesTempList = _.uniq(rolesTempList);
        const rolesList = [];
        rolesTempList.forEach(r => {
          rolesList.push({userRoles: r});
        });
        this.roleList = rolesList;
        console.log(this.roleList);
        // this.roleList = res;
        // console.log(this.roleList);
      }
    });
  }

  getPartnersList() {
    const payload = {
      queryCode: INSIGHT_QUERY_PARTNER_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        this.partnerList = res;
      }
    });
  }

  getDomainList() {
    const payload = {
      queryCode: INSIGHT_QUERY_DOMAIN_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        this.domainList = res;
      }
    });
  }

  getCountsData() {
    const payload = {
      queryCode: INSIGHT_QUERY_COUNT_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        res.forEach(element => {
          this.countsData[element.TableName] = element.Count;
        });
      }
    });
  }

  getTeamList() {
    const payload = {
      queryCode: INSIGHT_QUERY_TEAM_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        let teams = '';
        res.forEach((element, index) => {
          teams = teams + element.userTeam;
          if (index != res.length-1) teams = teams + ',';
        });
        let teamsTempList = teams.split(',');
        teamsTempList = _.uniq(teamsTempList);
        teamsTempList.forEach(r => {
          this.teamList.push({userTeam: r});
        });
      }
    });
  }

  getUserList() {
    const payload = {
      queryCode: INSIGHT_QUERY_USER_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        this.emailList = res;
      }
    });
  }

  getReportList() {
    this.spinner.show();
    const payload = {
      queryCode: INSIGHT_QUERY_REPORT_CODE
    };
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        this.reportList = res;
      }
      this.spinner.hide();
    });
  }

  dateRangeChange() {
    const params = {
      startTime: _.get(this.selectedDates, 'startDate', moment()).format('YYYY-MM-DD'),
      endTime: _.get(this.selectedDates, 'endDate', moment()).format('YYYY-MM-DD')
    };
    this.getDashboardData(params);
    this.createActivityXAxisLables();
  }

  createActivityXAxisLables() {
    const categoryLabelList: any = [];
    const currentDate = _.get(this.selectedDates, 'startDate', moment());
    const endDate = _.get(this.selectedDates, 'endDate', moment());
    for (var d = new Date(currentDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
      categoryLabelList.push({
        label: d.toLocaleDateString() // MM/DD/YYYY format
      });
    }
    this.activityxAxisLables = categoryLabelList;
  }

  filterChange() {
    this.spinner.show();
    const filteredData = this.filterDashboardData(this.dashboardData);
    this.loadDashbaord(filteredData);
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }

  clearFilters() {
    this.selectedDomains = [];
    this.selectedEmails = [];
    this.selectedReports = [];
    this.selectedCountries = [];
    this.selectedPartners = [];
    this.selectedNames = [];
    this.selectedReportGroups = [];
    this.selectedTeams = [];
    this.selectedRoles = [];
    this.filterChange();
  }

  getDashboardData(params: any = {}) {
    const payload = {
      queryCode: INSIGHT_QUERY_DATA_CODE,
      ...params
    };
    this.spinner.show();
    this.empowerService.ExecuteInsightQuery(payload).subscribe(res => {
      if (res && res.length) {
        this.dashboardData = res;
        const filteredData = this.filterDashboardData(this.dashboardData);
        this.loadDashbaord(filteredData);
      } else {
        this.dashboardData = [];
        this.loadDashbaord([]);
      }
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    });
  }

  filterDashboardData(dashboardData:any = []) {
    const filteredData = [];
    // domain : origin
    // team: userTeams (comma separated)
    // designation: userRoles (comma separated)
    // email: (userName)
    // name: (firstName + lastName)
    // country: (countryName)
    // parter: (consumerTopParent)
    // reports group: (pageCategory)
    // reports: (reportTitle)
    dashboardData.forEach(rowData => {
      if (!this.selectedDomains.length
        && !this.selectedEmails.length
        && !this.selectedReports.length
        && !this.selectedCountries.length
        && !this.selectedPartners.length
        && !this.selectedNames.length
        && !this.selectedReportGroups.length
        && !this.selectedRoles.length
        && !this.selectedTeams.length) {
        // no filter applied
        filteredData.push(rowData);
      }
      else if ((this.selectedDomains.length ? this.selectedDomains[0].origin == rowData.origin : true)
        && (this.selectedEmails.length ? this.selectedEmails[0].userName == rowData.userName : true)
        && (this.selectedReports.length ? this.selectedReports[0].reportTitle == rowData.reportTitle : true)
        && (this.selectedCountries.length ? this.selectedCountries[0].countryName == rowData.countryName : true)
        && (this.selectedPartners.length ? this.selectedPartners[0].consumerTopParent == rowData.consumerTopParent : true)
        && (this.selectedNames.length ? this.selectedNames[0].fullName == rowData.fullName : true)
        && (this.selectedReportGroups.length ? this.selectedReportGroups[0].pageCategory == rowData.pageCategory : true)
        && (this.selectedRoles.length ? rowData.userRoles.split(',').indexOf(this.selectedRoles[0].userRoles) > -1 : true)
        && (this.selectedTeams.length ? rowData.userTeams.split(',').indexOf(this.selectedTeams[0].userTeams) > -1 : true)
        ) {
        // filter exists
        filteredData.push(rowData);
      }
    });
    return filteredData;
  }

  loadDashbaord(dashboardData: any = []) {
    const pagesData = dashboardData.filter(a => a.eventName === EMPOWER_REPORT_PAGE);
    const exportEventData = dashboardData.filter(a => a.eventName === EMPOWER_REPORT_EXPORT);
    this.loadVisualMenuCount(pagesData);
    this.loadVisualUserCount(pagesData);
    this.loadVisualReportCount(pagesData);
    this.loadVisualUserActivity(pagesData);
    this.loadVisualMenuActivity(pagesData);
    this.loadVisualReportExportTrend(pagesData, exportEventData);
    this.loadGridData(pagesData);
  }

  loadVisualMenuCount(dashboardData) {
    const menuCount = _.countBy(dashboardData, a => a.navTitle);
    const xaxisLables: any = [];
    const seriesData: any = [];
    _.keys(menuCount).forEach(key => {
      xaxisLables.push({
        label: key
      });
      seriesData.push({
        value: menuCount[key]
      });
    });
    this.menuCountxAxis = xaxisLables;
    this.menuCountSeries = [{ data: seriesData }];
  }

  loadVisualUserCount(dashboardData) {
    const userCount = _.countBy(dashboardData, a => a.userName);
    const xaxisLables: any = [];
    const seriesData: any = [];
    _.keys(userCount).forEach(key => {
      xaxisLables.push({
        label: key
      });
      seriesData.push({
        value: userCount[key]
      });
    });
    this.userCountxAxis = xaxisLables;
    this.userCountSeries = [{ data: seriesData }];
  }

  loadVisualReportCount(dashboardData) {
    const reportCount = _.countBy(dashboardData, a => a.reportTitle);
    const seriesData: any = [];
    _.keys(reportCount).forEach(key => {
      key != 'undefined' && seriesData.push({
        label: key,
        value: reportCount[key]
      });
    });
    this.reportCountSeries = seriesData;
  }

  loadVisualUserActivity(dashboardData) {
    const meta = {
      groupBy: 'logDate',
      countBy: 'userName'
    };
    const result = ReduceListByProps(dashboardData, meta);
    const seriesData: any = [];
    this.activityxAxisLables.forEach(label => {
      seriesData.push({
        value: result[label.label] ? _.keys(result[label.label]).length : 0
      });
    });
    this.userActivitySeries = [{ data: seriesData }];;
  }

  loadVisualMenuActivity(dashboardData) {
    const meta = {
      groupBy: 'logDate',
      countBy: 'navTitle'
    };
    const result = ReduceListByProps(dashboardData, meta);
    const seriesData: any = [];
    this.activityxAxisLables.forEach(label => {
      seriesData.push({
        value: result[label.label] ? _.keys(result[label.label]).length : 0
      });
    });
    this.menuActivitySeries = [{ data: seriesData }];;
  }

  loadVisualReportExportTrend(pagesData, exportEventData) {
    const pageResult = ReduceListByProps(pagesData, {
      groupBy: 'navTitle',
      countBy: 'logDate'
    });
    const seriesDate = {
      dataset: [],
      lineset: []
    } as any;
    _.keys(pageResult).forEach(key => {
      const data: any = [];
      this.activityxAxisLables.forEach(label => {
        data.push({ value: pageResult[key][label.label] });
      });
      seriesDate.dataset.push({
        seriesname: key,
        data: data
      });
    });
    const exportCount = _.countBy(exportEventData, a => a.logDate);
    const exportSeriesData: any = [];
    this.activityxAxisLables.forEach(label => {
      exportSeriesData.push({ value: exportCount[label.label] });
    });
    seriesDate.lineset.push({
      data: exportSeriesData
    })
    this.reportExportTrendSeries = seriesDate;
  }

  loadGridData(dashboardData) {
    this.gridColumnDefs = generateGridColumns(this.activityxAxisLables);
    const meta = {
      groupBy: 'userName',
      groupBySecondLevel: 'logDate',
      countByParam: 'navTitle'
    };
    const result = ReduceListByPropsForGrid(dashboardData, meta);
    const keys = Object.keys(result);
    const values = Object.values(result) as any[];
    const gridData = [];
    keys.forEach((userName, index) => {
      const datesResponeKeys = Object.keys(values[index]);
      const datesResponeValues = Object.values(values[index]) as any[];
      const datesObj = {};
      datesResponeKeys.forEach((dateLabel: any, dateIndex) => {
        const navTitleKeys = Object.keys(datesResponeValues[dateIndex]);
        const navTitleValues = Object.values(datesResponeValues[dateIndex]) as any[];
        const greatestIndex = navTitleValues.indexOf(Math.max(...navTitleValues));
        const mostOpenedMenuTitle = navTitleKeys[greatestIndex];
        const numberOfTimesOpened =  navTitleValues[greatestIndex];
        datesObj['MostVisitedMenu_' + dateLabel] = mostOpenedMenuTitle;
        datesObj['CountOfMenuVisited_' + dateLabel] = numberOfTimesOpened;
      });
      gridData.push({userName: userName, ...datesObj});
    });
    this.gridData = gridData;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.colApi = params.columnApi;
  }

}


// import { Component, OnInit, ViewEncapsulation } from '@angular/core';


// @Component({
//   selector: 'empower-insights-dashboard',
//   templateUrl: './insights-dashboard.component.html',
//   styleUrls: ['./insights-dashboard.component.scss'],
//   encapsulation: ViewEncapsulation.None
// })
// export class EmpowerInsightsDashboardComponent implements OnInit {


//   constructor() {
//     console.log('Constructor Method Call');
//   }

//   ngOnInit(): void {
//     console.log('Init Method Call');
//   }

// }
