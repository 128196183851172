import { Component, Input, OnInit } from '@angular/core';

import { EmpowerModernLifeService } from 'src/services/empower-services/empower-modern-life.service';
import { EmpowerExportService } from 'src/services/empower-services/empower-export.service';
import { generateTitleDiv, NO_IMAGE, undefinedImagePath } from 'src/modules/empower-module/helper';

@Component({
  selector: 'empower-retail-demo-experience',
  templateUrl: './retail-demo-experience.component.html',
  styleUrls: ['./retail-demo-experience.component.scss']
})
export class EmpowerRetailDemoExperienceComponent implements OnInit {

  @Input() data: any;

  imageUrl = "../assets/empower/pitch-factory/images/58-screen-01.png";
  innerHtml = '';

  constructor(public empowerModernLifeService: EmpowerModernLifeService, private empowerExportService: EmpowerExportService) { }

  ngOnInit(): void {
    if (this.data) {
      this.data = JSON.parse(this.data);
      this.checkPageState(this.data.pageId);
    }
  }

  checkPageState(pageId) {
    const imageUrl = this.empowerModernLifeService.getPageImgState(pageId);
    if (imageUrl) {
      this.imageUrl = imageUrl;
    }
    if (this.imageUrl == NO_IMAGE) this.emitExportEvent(null);

    this.innerHtml = generateTitleDiv(this.data);
  }

  imageChange(imageUrl) {
    this.empowerModernLifeService.setPageImgState(this.data.pageId, imageUrl);
  }

  emitExportEvent(resolvedState: boolean) {
    setTimeout(() => {
      this.data && this.data.export && this.empowerExportService.setExportComponentSubject(58, resolvedState, this.data.pageIndex, this.data.pageTitle);
    }, 1000);
  }

}
