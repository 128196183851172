import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {  GridApi } from 'ag-grid-community';
import { CreateUpdateUserComponent } from './create-update-user/create-update-user.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonRendererComponent } from 'src/common/button-renderer.component';
import { ConfirmationDialogComponent } from 'src/common/confirmation-dialog/confirmation-dialog.component';
import { NameRendererComponent } from 'src/common/name-renderer';
import { ConfigurationService } from 'src/services/configuration.service';
import { UsersService } from 'src/services/User-services/user-services';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public columnDefs = [
    {
      headerName: 'Picture',
      field: 'documentPath',
      minWidth: 50,
      resizable: false,
      cellRenderer: params => {
        return `<img style="    width: 40px;
        height: 40px; border-radius: 50%;" src=${params.data.documentPath ? params.data.documentPath:'assets/images/thumbnails.png'}>`
      },
    },
    {
      headerName: 'Username/Email',
      field: 'email',
      minWidth: 50,
      resizable: true,
      cellRenderer: 'nameRenderer',
      cellRendererParams: {
        onClick: this.navigateToUser.bind(this),
      },
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      minWidth: 50,
      resizable: true
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      minWidth: 50,
      resizable: true
    },
    {
      headerName: 'Role',
      field: 'roleNames',
      minWidth: 50,
      resizable: true
    },
    {
      headerName: 'Team',
      field: 'teamNames',
      minWidth: 50,
      resizable: true
    },

    {
      headerName: '',
      show: true,
      field: 'delete',
      resizable: true,
      filter: false,
      pinned: 'right',
      cellRenderer: 'deleteButtonRenderer',
      cellRendererParams: {
        onClick: this.openRemoveUserDialog.bind(this),
      },
      width: 80
    }
  ];;
  public gridOptions: any;
  public info: string;
  private gridApi: GridApi;
  public userList = [];

  public user: any;
  headerHeight = 45;
  public rowHeight;
  public defaultColDef;
  constructor(
    public router: Router,
    public userService: UsersService,
    public configurationService: ConfigurationService,
    public dialog: MatDialog,
    public confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public spinner: NgxSpinnerService) {
    this.gridOptions = {
      frameworkComponents: {
        nameRenderer: NameRendererComponent,
        deleteButtonRenderer: ButtonRendererComponent
      },
      pagination: true,
      paginationPageSize: 15
    };
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true
    }
  }
  ngOnInit() {
    this.getUserList();
  }
  public getUserList = () => {
    const params = {
      active: true
    };
    this.userService.GetAllUsers({ userTypeId: 1 }).subscribe(res => {
      this.userList = (res as any).data;
      this.userList = this.userList.map(user => ({
        ...user,
        roleNames: this.getRoleNames(user.userRoles),
        teamNames: this.getTeamNames(user.userTeams)
      }));
    });
  }
  public getRoleNames = (roleList: any) => {
    if (roleList && roleList.length > 0) {
      let names = '';
      roleList.forEach(element => {
        names = `${names}, ${element.roleName}`;
      });
      names = names.split(',').splice(1).join(',');
      return names || '';
    }
  }
  public getTeamNames = (teamList: any) => {
    if (teamList && teamList.length > 0) {
      let names = '';
      teamList.forEach(element => {
        names = `${names}, ${element.teamName}`;
      });
      names = names.split(',').splice(1).join(',');
      return names || '';
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.rowHeight = 50;
    // params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(event.target.value);
  }
  // onCellClicked($event) {

  //   this.router.navigate(['addUser'], {
  //     queryParams: {
  //       id: btoa($event.data.userId)
  //     }
  //   });
  // }
  public navigateToUser(data?) {
    const userComponent = {
      menuId: `840`,
      name: 'User Setting',
      selector: 'app-create-update-user',
      displayName: 'User Setting',
      module: 'Auth',
      data: data,
      component: CreateUpdateUserComponent
    }
    this.configurationService.menuRendererSubject.next(userComponent);
  }
  public exportExcel = () => {
    try {
      this.gridApi.exportDataAsExcel();
    }
    catch {
      this.gridApi.exportDataAsCsv();
    }
  }
  openRemoveUserDialog(row: any): void {
    // tslint:disable-next-line: no-use-before-declare
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { headerName: row.rowData.firstName + row.rowData.lastName }
    });

    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeUser(row.rowData);
      }
    });
  }
  public removeUser(selectedItem: any) {
    if (selectedItem) {
      const model = {
        ...selectedItem,
        active: false
      };
      this.spinner.show();
      this.userService.ActivateUser(model).subscribe(res => {
          this.spinner.hide();
          this.userList = this.userList.filter(row => row.userId !== model.userId);
          this.gridOptions.api.setRowData(this.userList);
      }, error => {
        this.spinner.hide();
      });
    }
  }
}
