import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarSetupService {

  constructor(private http: HttpClient) { }

  GetCalendarSetupDetail() {
    return this.http.get(`${environment.HierarchyManager}/getcalendarsetup`)
      .pipe(map(response => {
        return response;
      }));
  }

  GetCalendarView(body) {
    return this.http.post(`${environment.HierarchyManager}/getcalendarview`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  UpdateCalendar(body) {
    return this.http.post(`${environment.HierarchyManager}/updatecalendar`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  AddCalendar(body) {
    return this.http.post(`${environment.HierarchyManager}/addcalendar`, body)
      .pipe(map(response => {
        return response;
      }));
  }

}
